import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IInquiryObject } from '@shared/interfaces/inquiry-object.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UnsubscribeOnDestroy } from '@core/classes/UnsubscribeOnDestroy';
import { IInquiryQuestionType } from '@shared/interfaces/inquiry-question-type.interface';
import { IInquiryLink } from '@shared/interfaces/inquiry-link.interface';
import { IInquiryType } from '@shared/interfaces/inquiry-type.interface';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { TaskDirectionNames } from '@shared/enums/task-direction-names.enum';
import { TargetNames } from '@shared/enums/target-names.enum';
import { Store } from '@ngrx/store';
import { get24FromAmpmTime, getAdvancedEmailValidator, getISOString } from '@shared/helpers/utils';
import { filter } from 'rxjs/operators';
import { GeneralInquiryService } from '@shared/services/general-inquiry.service';
import { IInquiryEntityType } from '@shared/interfaces/inquiry-entity-type.interface';
import { InquiryEntityTypesNames } from '@shared/enums/inquiry-entity-type.enum';

@Component({
  selector: 'app-general-inquiry-dialog',
  templateUrl: './general-inquiry-dialog.component.html',
  styleUrls: ['./general-inquiry-dialog.component.scss'],
})
export class GeneralInquiryDialogComponent extends UnsubscribeOnDestroy implements OnInit {
  inquiryFormGroup: FormGroup;

  questionTypes: IInquiryQuestionType[] = [];
  inquiryLinks: IInquiryLink[] = [];
  inquiryEntityTypes: IInquiryEntityType[] = [];

  generalInquirySaved: boolean;
  // TODO: endpoint isn't provided yet
  callScript: string[] = [];

  today = new Date();

  TargetNames = TargetNames;
  ContactMethod = ContactMethod;
  TaskDirectionNames = TaskDirectionNames;

  isOnlyOneInquiryType = false;

  InquiryEntityTypesNames = InquiryEntityTypesNames;

  @ViewChild('firstNameInput') firstNameInput;
  @ViewChild('lastNameInput') lastNameInput;
  @ViewChild('phoneInput') phoneInput;
  @ViewChild('emailInput') emailInput;
  @ViewChild('notesInput') notesInput;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: IInquiryType[],
    public dialog: MatDialogRef<GeneralInquiryDialogComponent>,
    private generalInquiryService: GeneralInquiryService,
    private fb: FormBuilder,
    private store: Store<any>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.inquiryFormGroup = this.fb.group({
      inquiryTypeId: [null, []],
      firstName: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z'.-]+( [a-zA-Z'.-]+)*$/),
      ]],
      lastName: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z'.-]+( [a-zA-Z'.-]+)*$/),
      ]],
      phone: ['', [Validators.required]],
      email: ['', [
        Validators.required,
        Validators.email,
        getAdvancedEmailValidator,
      ]],
      entity: ['', [Validators.required]],
      generalInquiryQuestionTypeId: ['', [Validators.required]],
      direction: ['', [Validators.required]],
      method: ['', [Validators.required]],
      contactDate: ['', [Validators.required]],
      contactTime: ['', [Validators.required]],
      createDateTime: ['', []],
      otherQuestionTypeName: ['', []],
      notes: ['', [Validators.required]],
    });

    // Skip the first step if we have only one Inquiry Type
    if (this.dialogData && this.dialogData.length === 1) {
      const inquiryTypeId = this.dialogData[0].id;
      this.isOnlyOneInquiryType = true;

      this.inquiryFormGroup.get('inquiryTypeId').patchValue(inquiryTypeId);

      this.getQuestionTypes(inquiryTypeId);
      this.subscribeInquiryLinks(inquiryTypeId);
      this.getEntityTypes(inquiryTypeId);
    }

    this.inquiryFormGroup.get('inquiryTypeId').valueChanges
      .pipe(
        untilDestroyed(this),
        filter(value => value !== null),
      )
      .subscribe((value: number) => {
        this.getQuestionTypes(value);
        this.subscribeInquiryLinks(value);
        this.getEntityTypes(value);
        this.focusInput('firstNameInput');
      });
  }

  openFAQ(): void {
    if (window && window.open) {
      window.open(this.inquiryLinks[0]?.url, '_blank');
    }
  }

  backToInquiryType(): void {
    this.inquiryFormGroup.reset();
  }

  createInquiry(): void {
    if (this.inquiryFormGroup.valid) {
      this.setCreateDate();
      const inquiryFormData: IInquiryObject = this.inquiryFormGroup.getRawValue();

      this.generalInquiryService.createGeneralInquiry(inquiryFormData)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => this.generalInquirySaved = true,
          () => this.generalInquirySaved = false,
        );
    }
  }

  getTypeById(id: number): string {
    return this.dialogData.find(el => el.id === id)?.name || '';
  }

  focusInput(inputName: string): void {
    setTimeout(() => this[inputName]?.nativeElement?.focus(), 200);
  }

  private getQuestionTypes(id: number): void {
    this.generalInquiryService.getInquiryQuestionTypes(id)
      .pipe(untilDestroyed(this))
      .subscribe((questionTypes) => {
        this.questionTypes = questionTypes;
      });
  }

  private subscribeInquiryLinks(id: number): void {
    this.generalInquiryService.getInquiryLinks(id)
      .pipe(untilDestroyed(this))
      .subscribe((inquiryLinks) => {
        this.inquiryLinks = inquiryLinks;
      });
  }

  private setCreateDate(): void {
    const date: Date = this.inquiryFormGroup.get('contactDate').value;
    const time: string[] = get24FromAmpmTime(this.inquiryFormGroup.get('contactTime').value)?.split(':');

    date.setHours(+time[0]);
    date.setMinutes(+time[1]);

    this.inquiryFormGroup.get('createDateTime').patchValue(getISOString(date));
  }

  private getEntityTypes(id: number): void {
    const programId = this.dialogData.find(x => x.id == id).programId;
    this.generalInquiryService.getEntityTypes(programId)
      .pipe(untilDestroyed(this))
      .subscribe((entityTypes) => {
        this.inquiryEntityTypes = entityTypes;
      });
  }
}
