import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITaskField } from 'src/app/features/manager/components/case/interfaces/task-field.interface';
import { TaskMessageEnum } from '../../../../../../interfaces/TaskMessageEnum';

@Component({
  selector: 'app-task-message',
  templateUrl: './task-message.component.html',
  styleUrls: ['./task-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskMessageComponent {
  private _field: ITaskField;

  @Input() set field(value: ITaskField){
    this._field = value;

    if(value.messageType === TaskMessageEnum.Success){
      this.styleClass = 'content-wrapper success-message';
      this.icon = 'assets/icons/icon-check.svg';
    }
    else{
      this.styleClass = 'content-wrapper error-message';
      this.icon = 'assets/icons/icon-invalid.svg';
    }
  }

  get field(){ return this._field; };

  styleClass: string;
  icon: string;
}
