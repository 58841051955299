import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { InterpolatedString } from '@shared/utilities/string-interpolation/interpolated-string';
import { Matcher } from '@shared/utilities/string-interpolation/matcher';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { ITask } from '../../../interfaces/task.interface';
import { CaseService } from '../../../services/case.service';
import { TasksFormService } from '../../dynamic-tasks-renderer/services/task-forms.service';
import { FieldType } from '../../../interfaces/FieldType';
import { isBlank } from '@shared/utilities/empty-checks';
import { ConfigsContract } from '../../dynamic-tasks-renderer/contract/configs.contract';
import { TaskOutcome } from '@shared/enums/task-outcome.enum';
import { QuestionType } from '../../dynamic-tasks-renderer/enums/question-type.enum';
import { first } from 'rxjs/operators';
import { NameResolver } from '@shared/utilities/name-resolver';

@Component({
  selector: 'app-history-details-dialog',
  templateUrl: './history-details-dialog.component.html',
  styleUrls: ['./history-details-dialog.component.scss'],
  providers: [DatePipe],
})
export class HistoryDetailsDialogComponent implements OnInit {
  readonly TaskOutcome = TaskOutcome;
  
  completedBy: any;
  description: string;

  taskConfigurations: ITask;
  taskFormData: FormGroup;

  private flatFields;
  private flatControls;

  private readonly fieldsToHide = [
    FieldType.ContactItem,
  ]

  private readonly reservedField = {
    [ConfigsContract.OutcomeNoteId]: 'outcomeNote',
    [ConfigsContract.CoversheetNoteId]: 'coversheetNote',
    [ConfigsContract.NoteForAgent]: 'noteForAgent',
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { task, case },
    private caseService: CaseService,
    private tasksFormService: TasksFormService,
  ) {
  }

  ngOnInit(): void {
    const interpolatedString = new InterpolatedString();

    interpolatedString
      .setInitialString(this.data.task.description)
      .setMatcher(this.getMatcher())
      .setModel(this.data.case);

    this.getConfigurations();

    this.description = interpolatedString.toString();
    if (this.data.task.updatedBy) this.completedBy = NameResolver.getNameString(this.data.task.updatedBy);
  }

  private getMatcher(): Matcher {
    return {
      $PatientPhone: this.data.case?.patient?.contactInfos?.find(e => e.contactMethod === ContactMethod.phone)?.contactString || ' ',
      $EscalatedByFullName: this.data.task.updatedBy ? NameResolver.getNameString(this.data.task.updatedBy) : ' ',
      $EscalatedTaskName: this.data.task.task || ' ',
    };
  }

  private getConfigurations(): void {
    if (!this.data?.task) return;

    this.caseService.getTasksConfigurations(this.data.task.fileName)
    .pipe(first())
    .subscribe(
      (configData: ITask) => {
        this.taskFormData = this.tasksFormService.getForm(configData.body);
        this.taskConfigurations = this.setupConfig(configData, this.data.task);
        this.hideEmptyBlocks();
      }
    );
  }

  private hideEmptyBlocks(): void {
    this.taskConfigurations.body.groups.forEach(group => {
      group.sections.forEach(section => {
        section.blocks.forEach(block => {
          block.fields.forEach(field => {
            const ctrl = this.flatControls.find(el => el.questionId === field.id);

            if(ctrl) {
              field.isHidden = isBlank(ctrl.value);
            }
          });

          block.isHidden = block.fields.every(el => el.isHidden);
        });

        section.isHidden = section.blocks.every(el => el.isHidden);
      });
    });
  }

  private setupConfig(configData: ITask, taskData: any): ITask {
    // Get flatten fields and controls
    this.flatFields = this.tasksFormService.getFlatFields(configData.body.groups);
    this.flatControls = this.tasksFormService.getFlatControls(this.taskFormData);

    // init all controls
    this.flatFields.forEach(field => {
      // there is some fields like contact card or some else
      // that we want to hide on task details dialog
      if(this.fieldsToHide.includes(field.type)) {
        field.isHidden = true;
        return;
      }

      // get question
      const questionObject = taskData.questions.find(e => e.question.id === field.id);

      // If it`s answered question - let`s init field and show on screen
      this.initField(field, questionObject);
    });

    return configData;
  }

  private initField(field, questionObject) {
    // find control that assigned to field
    const control = this.flatControls.find(e => e.questionId === field.id);

    control.disable();

    // if it`s reserved field - just enter value end return
    if(this.reservedField[field.id]) {
      control.patchValue(this.data.task[this.reservedField[field.id]] || '--');
      return;
    }

    // if not found - return
    if(!questionObject) return;
    
    // if need overwrite field name
    field.name ??= questionObject.question.text;

    // set options if need
    switch (field.type) {
      case FieldType.ToggleGroup:
      case FieldType.CheckboxesAdvanced: {
        field.options = questionObject.question.options;
      }
      break;
    }

    this.applyUserAnswer(field, control, questionObject);
  }

  private applyUserAnswer(field, control, question) {
    switch(question.question.type) {
      case QuestionType.binary : {
        control.patchValue(question.answer ? !!JSON.parse(question.answer) : undefined);
      }
      break;

      case QuestionType.option : {
        if(field.type === FieldType.CheckboxesAdvanced) {
          this.setChosenOptions(question, control);
        } 
        else {
          control.patchValue(question.answer ? JSON.parse(question.answer) : undefined);
        }
      }
      break;

      case QuestionType.text : {
        control.patchValue(question.answer);
      }
      break;

      case QuestionType.number : {
        control.patchValue(question.answer ? JSON.parse(question.answer) : undefined);
      }
      break;

      case QuestionType.date : {
        const dateRange = field.value;

        dateRange.dateStart.value = new Date(this.data.task.questions.find(e => e.question.id === dateRange.dateStart.id).answer);
        dateRange.dateEnd.value = new Date(this.data.task.questions.find(e => e.question.id === dateRange.dateEnd.id).answer);

        control.patchValue(dateRange);
      }
      break;

      case QuestionType.nestedOption : {
        this.setChosenOptions(question, control);
      }
      break;
    }
  }

  private setChosenOptions(question, control) {
    if(question.chosenOptions?.length) {
      this.setSelectedOptions(question.question.options, question.chosenOptions);
      control.patchValue(question.chosenOptions.length);
    }
    else {
      control.patchValue(undefined);
    }
  }

  private setSelectedOptions(options: any[], chosenOptions: any[]): void {
    options.forEach((option) => {
      const chosenOption = chosenOptions.find(el => el.optionId === option.id);

      if (chosenOption) {
        option.isSelected = true;
        option.note = chosenOption.note;

        if (option.options?.length) {
          this.setSelectedOptions(option.options, chosenOption.options);
        }
      }
    });
  }
}
