import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../core/classes/UnsubscribeOnDestroy';

@Component({
  selector: 'app-page-stepper',
  templateUrl: './page-stepper.component.html',
  styleUrls: ['./page-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageStepperComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() breadcrumbs = [];
  @Input() showSkipButton = false;
  @Input() disableNext = false;
  @Input() showComplete = false;
  @Input() showPreview = false;
  @Input() disableComplete = false;
  @Input() previewLoading = false;

  @Output() nextStep: EventEmitter<number> = new EventEmitter<number>();
  @Output() preview: EventEmitter<void> = new EventEmitter<void>();
  @Output() routerLinkChanged: EventEmitter<number> = new EventEmitter<number>();

  currentStepName$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  stepsItems: string[] = [];
  currentPosition = 0;

  get currentStep(): number {
    return this.currentPosition;
  }

  @Input() set currentStep(step: number) {
    this.currentPosition = step;
    this.currentStepName$.next(this.stepsItems[this.currentPosition + 1]);
  }

  get steps(): string[] {
    return this.stepsItems;
  }

  @Input() set steps(steps: string[]) {
    this.stepsItems = steps;
    this.currentStepName$.next(steps[this.currentPosition + 1]);
  }

  @Input() lastStepName = this.steps[this.steps.length - 1];

  @Input() showNextButton = this.steps.length > 0;

  @ViewChild('nextBtn') nextBtn: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    // change page stepper currentStep if user moved through pages with browser button Back
    this.router.events.pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.routerLinkChanged.emit(this.route.snapshot.firstChild?.data?.currentStep);
        }
      });
  }

  back(): void {
    this.currentStepName$.next(this.steps[this.currentPosition]);
    this.currentPosition -= 1;
    this.nextStep.emit(this.currentPosition);
  }

  next(): void {
    this.currentPosition += 1;
    this.nextStep.emit(this.currentPosition);
    this.currentStepName$.next(this.steps[this.currentPosition + 1]);
  }

  previewSteps(): void {
    this.preview.emit();
  }

  complete(): void {
    this.nextStep.emit(this.steps.length);
  }

  // TODO: handle focus when input is filled
  focusNextBtn(): void {
    this.nextBtn.nativeElement.focus();
  }
}
