import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
// @ts-ignore
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';
import { ITaskField } from 'src/app/features/manager/components/case/interfaces/task-field.interface';

@Component({
  selector: 'app-task-input',
  templateUrl: './task-input.component.html',
  styleUrls: ['./task-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskInputComponent),
      multi: true,
    }
  ],
})
export class TaskInputComponent extends InputControlAccessorDirective {
  private _field: ITaskField;

  get field(){ return this._field; };

  @Input() set field(value: ITaskField){
    this._field = value;

    this.pattern = undefined;
    this.placeholder = value.placeholder || '';

    if(value.pattern === 'email') this.pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  pattern: RegExp;
  placeholder: string;
}
