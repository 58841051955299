import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ManagerQueueIdentifyColumns } from '@shared/enums/manager-queue-identify-columns.enum';
import { setAge, setAgeColor } from '@shared/helpers/utils';
import { enumToArray, toSearchString, deepCopy } from '@shared/helpers/utils';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IManagerQueueIdentify } from '../../interfaces/manager-queue-identify.interface';
import { QueueService } from '../../services/queue.service';
import { throwError } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { QueueItemsMonitoringService } from '@core/signalR/interface/queue-items-monitoring.service';
import { UsersService } from '@shared/services/users.service';
import { QueueStatus } from '@shared/enums/queue-status.enum';

@Component({
  selector: 'app-manager-queue-identify',
  templateUrl: './identify.component.html',
  styleUrls: ['../../queue.component.scss', './identify.component.scss'],
  providers: [QueueItemsMonitoringService]
})
export class ManagerQueueIdentifyComponent implements OnInit {
  QueueStatus = QueueStatus;

  setAge = setAge;
  setAgeColor = setAgeColor;
  columnsToDisplay = enumToArray(ManagerQueueIdentifyColumns);
  data: IManagerQueueIdentify[] = [];
  dataSource;
  isLoaded = false;
  maxPageCount;
  maxHoursCount;
  minutesInAgeType;
  isFiltersNotUsed = true;
  userRole: string;
  previousValue = null;
  queueId: number;

  settings = {
    itemPerPage: 20,
    paginationPageCount: null,
    activePage: 0,
    ageType: 'hours',
    filter: {
      show: {
        source: false,
        pageCount: false,
        receivedDate: false,
        age: false,
      },
      value: {
        source: null,
        pageCount: [0, 1],
        receivedDate: {start: '', end: ''},
        age: [0, 1],
      },
      initialValue: {
        source: null,
        pageCount: [0, 1],
        receivedDate: {start: '', end: ''},
        age: [0, 1],
      },
    },
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('pageCountSlider') pageCountSlider;
  @ViewChild('ageSlider') ageSlider;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      let shownFilter;
      for (const [key, value] of Object.entries(this.settings.filter.show)) {
        if (this.settings.filter.show[key]) {
          shownFilter = key;
        }
      }
      if (shownFilter) {
        this.applyFilter(shownFilter);
      }
    }
  }

  constructor(
    private queueItemsMonitoringService: QueueItemsMonitoringService,
    public queueService: QueueService,
    public usersService: UsersService,
    private router: Router,
    private store: Store<any>,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    this.store.select('user').subscribe((state) => {
      this.userRole = state.role;
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queueId = parseInt(params.id);
      this.getAllItemsInQueue();
      this.initListeners();
    });
  }

  private initListeners() {
    this.queueItemsMonitoringService.onLockQueueItem(this.queueId).subscribe(
      res => {
        this.updateItemStatus(res, QueueStatus.locked);
      },
      err => {
        console.error(err);
      }
    );

    this.queueItemsMonitoringService.onUnlockQueueItem(this.queueId).subscribe(
      res => {
        this.updateItemStatus(res, QueueStatus.ready);
      },
      err => {
        console.error(err);
      }
    );

    this.queueItemsMonitoringService.onQueueItemCompleted(this.queueId).subscribe(
      res => {
        const index = this.data.findIndex(e => e.id === res.itemId);

        if(index > 0) {
          this.data.splice(index, 1);

          this.dataSource = new MatTableDataSource(this.data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.setPagination();

          this.ref.detectChanges();
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  private updateItemStatus(data, status) {
    if(!data.itemId || !this.data) return;

    const lockedItem = this.data.find(e => e.id === data.itemId);

    if(!lockedItem) return;

    lockedItem.queueStatus = status;

    this.loadUserImage(lockedItem, data.userId); // Load image async

    this.ref.detectChanges();
  }

  private loadUserImage(item, userId) {
    item.userImageS3Url = undefined; // Mark as no photo while image is loading

    if(!userId) return;

    this.usersService.getPhotoById(userId).subscribe(
      async photo => {
        item.userImageS3Url = await photo;

        this.ref.detectChanges();
      }
    )
  }

  onChangeRange(rangeSlider): void {
    const rangeType = rangeSlider.replace('Slider', '');
    this[rangeSlider].slider.set(this.settings.filter.value[rangeType]);
  }

  openFilter(filter): void {
    this.previousValue = deepCopy(this.settings.filter.value);
    this.closeAllFilters();
    this.settings.filter.show[filter] = true;
  }

  closeFilter(filter): void {
    this.settings.filter.show[filter] = false;
    this.settings.filter.value = this.previousValue;
  }

  closeAllFilters(): void {
    const shownFilters = this.settings.filter.show;
    Object.keys(shownFilters).forEach((k) => {
      shownFilters[k] = false;
    });
    this.settings.filter.value = deepCopy(this.previousValue);
  }

  clearFilter(filter): void {
    switch (filter) {
      case 'receivedDate':
        this.settings.filter.value[filter] = {start: '', end: ''};
        break;
      default:
        this.settings.filter.value[filter] = this.settings.filter.initialValue[filter];
    }
    this.applyFilter(filter);
  }

  clearAllFilters(): void {
    this.settings.filter.value = {
      ...this.settings.filter.initialValue,
      receivedDate: {start: '', end: ''},
    };
    this.applyFilter('');
  }

  checkIsFilterUsed(): void {
    this.isFiltersNotUsed =
      !!this.settings.filter.value.source === !!this.settings.filter.initialValue.source &&
      this.settings.filter.value.pageCount[0] === this.settings.filter.initialValue.pageCount[0] &&
      this.settings.filter.value.pageCount[1] === this.settings.filter.initialValue.pageCount[1] &&
      !this.settings.filter.value.receivedDate.start &&
      !this.settings.filter.value.receivedDate.end &&
      this.settings.filter.value.age[0] === this.settings.filter.initialValue.age[0] &&
      this.settings.filter.value.age[1] === this.settings.filter.initialValue.age[1];
  }

  setPagination(): void {
    this.settings.paginationPageCount = Math.ceil(this.dataSource.filteredData.length / this.settings.itemPerPage);
  }

  applyFilter(filter): void {
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = this.settings.filter.value;
    this.settings.filter.show[filter] = false;
    this.checkIsFilterUsed();
    this.setPagination();
  }

  setActivePage(index): void {
    this.settings.activePage = index;
    this.paginator.pageIndex = index;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  countAgeDuration(): void {
    this.minutesInAgeType = this.settings.ageType === 'days' ? 60 * 24 : 60;
    const maxAgeValue = Math.max(...this.data.map((a) => Math.floor(Number(a.age) / this.minutesInAgeType)));
    this.maxHoursCount = maxAgeValue;
    this.settings.filter.value.age = [0, maxAgeValue];
    this.settings.filter.initialValue.age = [0, maxAgeValue];
  }

  getAllItemsInQueue(): void {
    const data = {queueId: this.queueId, take: 10000};
    this.queueService.getAllItemsInQueue(data).subscribe(
      (response) => {
        this.data = response.map((queue) => {
          return {
            id: queue.id,
            sourceType: queue.sourceType,
            source: String(queue.id),
            contact: String(queue.sourceString),
            contactName: queue.sourceName,
            pageCount: queue.pageCount,
            receivedDate: queue.createDate,
            age: queue.age.totalMinutes,
            queueStatus: queue.queueStatus,
            lockedByUserId: queue.lockedByUserId
          };
        });
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.setPagination();
        const maxPage = Math.max(...this.data.map((a) => a.pageCount));
        this.maxPageCount = maxPage;
        this.settings.filter.value.pageCount = [0, maxPage];
        this.countAgeDuration();
        this.settings.filter.initialValue = {...this.settings.filter.value};
        this.isLoaded = true;

        // Load images of user profiles at locked items
        this.data.filter(e => e.queueStatus === 1).forEach(e => {
          this.loadUserImage(e, e.lockedByUserId);
        });
      },
      (error) => {
        const userIsNotAuthorized = error?.statusCode === 403;

        if (userIsNotAuthorized) {
          this.router.navigate(['/']);
        }// TODO: Some message to user that they are not authorized?
        else {
          throwError(error);
        }
      }
    );
  }

  openItem(queueItem): void {
    if (queueItem.queueStatus !== QueueStatus.locked) {
      this.router.navigate(['/document-identify'], { queryParams: { id: queueItem.id, queueId: this.queueId }});  //TODO: Append queue id?
    }
  }

  customFilterPredicate(): (data, filter) => boolean {
    const myFilterPredicate = (data, filter): boolean => {
      const sourceFilter = filter.source
        ? toSearchString(data.source).includes(toSearchString(filter.source))
        : true;

      const pageFilter = filter.pageCount
        ? data.pageCount >= filter.pageCount[0] && data.pageCount <= filter.pageCount[1]
        : true;

      const fullEndDate = new Date(filter.receivedDate.end);

      let receivedDateFilter;
      if (filter.receivedDate.start && filter.receivedDate.end) {
        receivedDateFilter =
          new Date(data.receivedDate) >= new Date(filter.receivedDate.start) &&
          new Date(data.receivedDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
      } else if (filter.receivedDate.start && !filter.receivedDate.end) {
        receivedDateFilter = new Date(data.receivedDate) >= new Date(filter.receivedDate.start);
      } else if (!filter.receivedDate.start && filter.receivedDate.end) {
        receivedDateFilter =
          new Date(data.receivedDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
      } else {
        receivedDateFilter = true;
      }

      const ageFilter = filter.age
        ? data.age / this.minutesInAgeType >= filter.age[0] &&
        data.age / this.minutesInAgeType <= filter.age[1] + 1
        : true;

      return sourceFilter && pageFilter && receivedDateFilter && ageFilter;
    };

    return myFilterPredicate;
  }
}
