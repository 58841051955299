import { MapsAPILoader } from '@agm/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';
import { ContactMethod } from '../../../../../../../shared/enums/contact-method.enum';
import { ContactType } from '../../../../../../../shared/enums/contact-type.enum';
import { getContactInfos, getFacilityFormObject } from '../../../../../../../shared/helpers/utils';
import { IFacility } from '../../../../../../../shared/interfaces/facility.interface';
import { IPhysician } from '../../../../../../../shared/interfaces/physician.interface';
import { IResponse } from '../../../../../../../shared/interfaces/response.interface';
import { DataEntryFormService } from '../../../../../../intake/components/document/components/data/components/form/services/form.service';

@Component({
  selector: 'app-physician-edit-modal',
  templateUrl: './physician-edit-modal.component.html',
  styleUrls: ['./physician-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhysicianEditModalComponent implements OnInit, OnDestroy {
  physicianSearchControl = new FormControl(`${this.data.case?.physician?.firstName} ${this.data.case?.physician?.lastName}`);
  facilitySearchControl = new FormControl(`${this.data.case?.facility?.name}`);
  physicians$: BehaviorSubject<IPhysician[]> = new BehaviorSubject<IPhysician[]>([]);
  facilities$: BehaviorSubject<IFacility[]> = new BehaviorSubject<IFacility[]>([]);

  focusedSearch: number;

  physicianOptions = {
    newPhysician: false,
    selectedPhysician: null,
    newFacility: false,
    selectedFacility: null,
    isEditedFacility: false,
  };

  isFacilityListOpen = true;
  isFacilitySearchFocused = false;

  facilityForm: FormGroup = new FormGroup({
    facilityName: new FormControl('', Validators.required),
    tax: new FormControl(''),
    address: new FormControl('', Validators.required),
    addressExtension: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    zipCode: new FormControl('', Validators.required),
    officePhone: new FormControl('', Validators.required),
    officeFax: new FormControl('', Validators.required),
    officeName: new FormControl(''),
    officeEmail: new FormControl('', [Validators.email]),
  });

  city = '';
  state = '';

  isPhysicianSearchActive = true;
  isFacilitySearchActive = true;

  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild('searchFacility') searchFacilityElRef: ElementRef;
  @ViewChild('physicianSearchList') physicianSearchList: ElementRef;
  @ViewChild('facilitySearchList') facilitySearchList: ElementRef;
  @ViewChild('physicianFacility') physicianFacility: ElementRef;

  autocomplete: any;
  @ViewChild('addressField') addressField: ElementRef;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (this.physicianFacility && (event.key === 'Tab' || event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter')) {
      event.preventDefault();
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        this.focusedSearch === this.physicianFacility.nativeElement.children.length - 1
          ? (this.focusedSearch = 0)
          : (this.focusedSearch = this.focusedSearch + 1);
      }
      if (event.key === 'ArrowUp') {
        this.focusedSearch === 0
          ? (this.focusedSearch = this.physicianFacility.nativeElement.children.length - 1)
          : (this.focusedSearch = this.focusedSearch - 1);
      }
      if (event.key === 'Enter') {
        this.selectFacility(this.physicianOptions.selectedPhysician.addresses[this.focusedSearch], true);
      }
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialogRef<PhysicianEditModalComponent>,
    private dataEntryService: DataEntryFormService,
    private cdr: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
  ) {
  }

  ngOnInit(): void {
    this.physicianSearchControl.valueChanges
      .pipe(
        startWith(`${this.data.case?.physician?.firstName} ${this.data.case?.physician?.lastName}`),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$),
        filter(v => v.length >= 3),
      )
      .subscribe(v => this.searchPhysicians(v));

    this.facilitySearchControl.valueChanges
      .pipe(
        startWith(`${this.data.case?.facility?.name || ''}`),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$),
        filter(v => v.length >= 3),
      )
      .subscribe(v => this.searchFacilities(v));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getPlaceAutocomplete(): void {
    if (!this.autocomplete) {
      this.mapsAPILoader.load().then(() => {
        this.autocomplete = new google.maps.places.Autocomplete(
          this.addressField.nativeElement,
          {
            componentRestrictions: {country: 'US'},
            fields: ['address_components', 'formatted_address'],
          }
        );

        this.autocomplete.addListener('place_changed', () => {
          const place = this.autocomplete.getPlace();
          const city = place.address_components ? place.address_components.find(x => x.types.includes('locality')) : null;
          const state = place.address_components ?
            place.address_components.find(x => x.types.includes('administrative_area_level_1')) :
            null;
          const zipCode = place.address_components ? place.address_components.find(x => x.types.includes('postal_code')) : null;
          const splittedAddress = place.formatted_address ? place.formatted_address.split(', ') : [];
          const address = splittedAddress.length > 0 ? splittedAddress[0] : '';
          this.city = city ? city.long_name : '';
          this.state = state ? state.short_name : '';
          this.facilityForm.get('address').setValue(address);
          this.facilityForm.get('city').setValue(this.city);
          this.facilityForm.get('state').setValue(this.state);
          this.facilityForm.get('zipCode').setValue(zipCode ? zipCode.long_name : '');
        });
      });
    }
  }

  facilitySearchClickOutside(): void {
    if (!this.isFacilitySearchFocused && (this.physicianOptions.newFacility || this.physicianOptions.selectedFacility)) {
      this.isFacilityListOpen = false;
    }
  }

  searchPhysicians(search: string): void {
    this.dataEntryService.searchPhysicians({search, take: 10}).subscribe(
      (response) => this.physicians$.next(response), (error) => throwError(error));
  }

  searchFacilities(search: string): void {
    this.isFacilityListOpen = true;
    this.dataEntryService.searchFacilities({search, take: 10}).subscribe(
      (response) => {
        this.facilities$.next(response);
      }, (error) => throwError(error));
  }

  searchNavigate(event, search): void {
    const searchList = this.data.step === 'physician' ? this.physicianSearchList : this.facilitySearchList;

    if (this.physicianSearchControl.value.length || this.facilitySearchControl.value.length) {
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        event.preventDefault();
        this.focusedSearch === searchList.nativeElement.children.length - 1
          ? (this.focusedSearch = 0)
          : (this.focusedSearch = this.focusedSearch + 1);
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.focusedSearch === 0
          ? (this.focusedSearch = searchList.nativeElement.children.length - 1)
          : (this.focusedSearch = this.focusedSearch - 1);
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        if (search === 'physician') {
          this.selectPhysician(this.physicians$.getValue()[this.focusedSearch]);
        } else {
          this.selectFacility(this.facilities$.getValue()[this.focusedSearch], false);
        }
      }
    }
  }

  selectPhysician(physician): void {
    this.physicianOptions.selectedPhysician = physician;
    this.physicianOptions.selectedFacility = null;
    this.physicianOptions.newFacility = false;

    setTimeout(() => {
      this.focusedSearch = 0;
    }, 0);
  }

  addNewPhysician(): void {
    this.physicianOptions.newPhysician = true;
  }

  addNewFacility(): void {
    this.physicianOptions.newFacility = true;
    this.physicianOptions.selectedFacility = false;
    this.isFacilityListOpen = false;
  }

  editFacility(): void {
    this.physicianOptions.isEditedFacility = !this.physicianOptions.isEditedFacility;

    if (!this.physicianOptions.isEditedFacility) {
      this.physicianOptions.selectedFacility = this.getFacilityCaseObject();
    }

    this.cdr.detectChanges();

    if (this.addressField) {
      this.getPlaceAutocomplete();
    }
  }

  exitEditFacility(): void {
    this.physicianOptions.isEditedFacility = !this.physicianOptions.isEditedFacility;
    this.facilityForm.patchValue(this.getFacilityFormObject(this.physicianOptions.selectedFacility));
  }

  selectFacility(facility, isAddress): void {
    this.physicianOptions.selectedFacility = isAddress ? this.getFacilityFromNpiAddress(facility) : facility;
    this.facilityForm.patchValue(this.getFacilityFormObject(this.physicianOptions.selectedFacility));
    this.physicianOptions.newFacility = false;
    this.physicianOptions.isEditedFacility = false;
    this.isFacilityListOpen = false;

    // setTimeout(() => {
    //   if (this.searchFacilityElRef && this.searchFacilityElRef.nativeElement) {
    //     this.searchFacilityElRef.nativeElement.focus();
    //   }
    // }, 0);
  }

  hasPhysicianFacilities(physician): boolean {
    return physician && physician.addresses && physician.addresses.length > 0;
  }

  cancelNewFacility(): void {
    this.physicianOptions.newFacility = false;
  }

  createNewFacility(form: FormGroup): void {
    this.facilityForm = form;

    const data = {
      id: 0,
      name: form.get('facilityName').value,
      address: {
        streetAddress: form.get('address').value,
        addressExtension: null,
        zipCode: form.get('zipCode').value,
        state: null,
        city: null,
      },
      groupTaxId: form.get('tax').value,
      contactInfos: getContactInfos(form),
    };

    this.dataEntryService.createFacility({facility: data}).subscribe(
      (response: IResponse) => {
        setTimeout(() => {
          // get created facility from the search and select
          this.dataEntryService.searchFacilities({search: data.name})
            .subscribe((res) => {
              const current = res.find(v => +v.id === +response.value);
              current ? this.physicianOptions.selectedFacility = current : this.facilitySearchControl.patchValue(data.name);
              this.physicianOptions.newFacility = false;
              this.cdr.detectChanges();
            }, (error) => throwError(error));
        });
      }, (error) => throwError(error));
  }

  saveFacility(): Promise<any> {
    if (this.physicianOptions.selectedFacility || this.physicianOptions.newFacility) {
      const data = {
        facility: this.getFacilityCaseObject(),
      };

      if (this.physicianOptions.selectedFacility && this.physicianOptions.selectedFacility.id) {
        this.dataEntryService.updateFacility(data).subscribe(
          (response) => {
          },
          (error) => throwError(error)
        );

        return Promise.resolve();
      } else {
        return new Promise((resolve) =>
          this.dataEntryService.createFacility(data).subscribe(
            (response) => {
              if (!this.physicianOptions.selectedFacility) {
                this.physicianOptions.selectedFacility = this.getFacilityCaseObject();
              }
              this.physicianOptions.selectedFacility.id = response.value;
              resolve(response);
            },
            (error) => throwError(error)
          )
        );
      }
    }

    return Promise.resolve();
  }

  save(): void {
    this.saveFacility()
      .then(this.savePhysician.bind(this))
      .then(this.saveCase.bind(this))
      .then((res) => {
        this.dialog.close(this.data.case.id);
      });
  }

  clearSearchPhysician(): void {
    this.physicianSearchControl.patchValue('');
  }

  clearSearchFacility(): void {
    this.facilitySearchControl.patchValue('');
  }

  cancelNewPhysician(): void {
    this.physicianOptions.newPhysician = false;
  }

  createNewPhysician(form: FormGroup): void {
    const data = {
      id: 0,
      firstName: form.get('firstName').value,
      lastName: form.get('lastName').value,
      npi: form.get('npi').value,
      physicianSpecialityId: +form.get('specialty').value,
      physicianSpeciality: null,
      physicianFacilities: [],
    };

    this.dataEntryService.createPhysician({physician: data}).subscribe(
      (response: IResponse) => {
        // get created physician from the search and select
        const search = data.firstName + ' ' + data.lastName;

        this.dataEntryService.searchPhysicians({search})
          .subscribe((res) => {
            const current = res.find(v => +v.id === +response.value);
            current ? this.physicianOptions.selectedPhysician = current : this.physicianSearchControl.patchValue(search);
            this.physicianOptions.newPhysician = false;
            this.cdr.detectChanges();
          }, (error) => throwError(error));
      }, (error) => throwError(error));
  }

  setStep(step: string): void {
    this.data.step = step;
    if (step === 'physician' && this.physicianOptions.newFacility) {
      this.physicianOptions.newFacility = false;
    }
  }

  savePhysician(): Promise<any> {
    if (this.physicianOptions.selectedPhysician) {
      const data = {
        physician: this.getPhysicianCaseObject(),
      };
      if (!this.physicianOptions.selectedPhysician.id) {
        return new Promise((resolve) =>
          this.dataEntryService.createPhysician(data).subscribe(
            (response) => {
              this.physicianOptions.selectedPhysician.id = response.value;
              resolve(response);
            },
            (error) => throwError(error)
          )
        );
      } else {
        this.dataEntryService.updatePhysician(data).subscribe(
          (response) => {
          },
          (error) => throwError(error)
        );
        return Promise.resolve();
      }
    }

    return Promise.resolve();
  }

  saveCase(): Promise<any> {
    if (this.physicianOptions.selectedPhysician) {
      this.data.case.physician = this.physicianOptions.selectedPhysician;
      this.data.case.physicianId = this.physicianOptions.selectedPhysician.id;
    }

    if (this.physicianOptions.selectedFacility) {
      this.data.case.facility = this.getFacilityCaseObject();
      this.data.case.facilityId = this.physicianOptions.selectedFacility.id;
    }

    if (this.data.case.facility && this.data.case.physician && this.data.case.physician.addresses) {
      const index = this.data.case.physician.addresses.findIndex((x) => x.id === this.data.case.facilityId);
      const facilityAddress = this.getFacilityAddressObject(this.data.case.facility);
      if (index > -1) {
        this.data.case.physician.addresses[index] = facilityAddress;
      } else {
        this.data.case.physician.addresses.push(facilityAddress);
      }
    }

    const data = {
      caseManagementQueueItem: {
        id: this.data.case.id,
        patientId: this.data.case.patientId,
        caseInsurances: this.data.case.caseInsurances,
        physicianId: this.data.case.physicianId,
        facilityId: this.data.case.facilityId,
        diagnosisId: this.data.case.diagnosisId,
        prescriptionId: this.data.case.prescriptionId,
      },
    };

    return new Promise((resolve) =>
      this.dataEntryService.updateCase(data)
        .subscribe((response) => resolve(response), (error) => throwError(error)));
  }

  getFacilityFormObject(facility): object {
    const phone = facility ? facility.contactInfos.find((x) => x.contactMethod === ContactMethod.phone) : null;
    const fax = facility ? facility.contactInfos.find((x) => x.contactMethod === ContactMethod.fax) : null;
    const email = facility ? facility.contactInfos.find((x) => x.contactMethod === ContactMethod.email) : null;

    if (this.physicianOptions.selectedFacility) {
      this.physicianOptions.selectedFacility.city = facility ? facility.address.city : '';
      this.physicianOptions.selectedFacility.state = facility ? facility.address.state : '';
    }

    return {
      facilityName: facility ? facility.name : '',
      address: facility ? facility.address.streetAddress : '',
      addressExtension: facility ? facility.address.addressExtension : '',
      tax: facility ? facility.groupTaxId : '',
      city: facility ? facility.address.city : '',
      state: facility ? facility.address.state : '',
      zipCode: facility ? facility.address.zipCode : '',
      officePhone: phone ? phone.contactString : '',
      officeFax: fax ? fax.contactString : '',
      officeName: email ? email.name : '',
      officeEmail: email ? email.contactString : '',
    };
  }

  getFacilityFromNpiAddress(address): object {
    const contactInfos = [];

    if (address.telephoneNumber) {
      contactInfos.push({
        name: '',
        contactString: ('' + address.telephoneNumber).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.phone,
        contactType: ContactType.work,
        externalSourceContactId: address.telephoneExternalSourceContactId
      });
    }

    if (address.faxNumber) {
      contactInfos.push({
        name: '',
        contactString: ('' + address.faxNumber).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.fax,
        contactType: ContactType.work,
        externalSourceContactId: address.faxNumberExternalSourceContactId
      });
    }

    if (address.email) {
      contactInfos.push({
        name: address.contactName,
        contactString: address.email,
        primary: true,
        contactMethod: ContactMethod.email,
        contactType: ContactType.work,
        externalSourceContactId: address.emailExternalSourceContactId
      });
    }

    return {
      id: address.id,
      name: address.name,
      address: {
        streetAddress: address.address1,
        addressExtension: address.address2,
        zipCode: address.postalCode,
        state: address.state,
        city: address.city,
      },
      groupTaxId: address.groupTaxId,
      contactInfos,
    };
  }

  getFacilityCaseObject(): object {
    const contactInfos = getContactInfos(this.facilityForm);

    contactInfos.forEach(contactInfo => {
      contactInfo.externalSourceContactId = this.physicianOptions
        ?.selectedFacility
        ?.contactInfos
        ?.find(x => x.contactMethod === contactInfo.contactMethod && x.contactType === contactInfo.contactType)
        ?.externalSourceContactId
    }); 

    return {
      id: this.physicianOptions.selectedFacility ? this.physicianOptions.selectedFacility.id : 0,
      name: this.facilityForm.value.facilityName,
      address: {
        streetAddress: this.facilityForm.value.address,
        addressExtension: this.physicianOptions.selectedFacility
          ? this.physicianOptions.selectedFacility.address.addressExtension
          : null,
        zipCode: this.facilityForm.value.zipCode,
        state: this.physicianOptions.selectedFacility
          ? this.physicianOptions.selectedFacility.address.state
          : null,
        city: this.physicianOptions.selectedFacility
          ? this.physicianOptions.selectedFacility.address.city
          : null,
      },
      groupTaxId: this.facilityForm.value.tax,
      contactInfos
    };
  }

  getFacilityAddressObject(facility): object {
    const phone = facility.contactInfos.find((x) => x.contactMethod === ContactMethod.phone);
    const fax = facility.contactInfos.find((x) => x.contactMethod === ContactMethod.fax);
    const email = facility.contactInfos.find((x) => x.contactMethod === ContactMethod.email);

    return {
      id: facility.id,
      telephoneNumber: phone ? phone.contactString : '',
      faxNumber: fax ? fax.contactString : '',
      email: email ? email.contactString : '',
      contactName: email ? email.name : '',
      name: facility.name,
      address1: facility.address.streetAddress,
      address2: facility.address.addressExtension,
      postalCode: facility.address.zipCode,
      city: facility.address.city,
      state: facility.address.state,
      groupTaxId: facility.groupTaxId,
    };
  }

  getPhysicianCaseObject(): object {
    let physicianFacilities = [];

    const specialityId =
      this.physicianOptions.selectedPhysician && this.physicianOptions.selectedPhysician.id
        ? this.physicianOptions.selectedPhysician.physicianSpecialityId
        : this.physicianOptions.selectedPhysician
        ? 0
        : null;

    const speciality = specialityId !== null ? {id: specialityId, name: null} : null;

    if (this.physicianOptions.selectedPhysician) {
      physicianFacilities = this.physicianOptions.selectedPhysician.addresses
        .filter((x) => x.id)
        .map((x) => x.id);
      if (
        this.physicianOptions.selectedFacility &&
        !physicianFacilities.includes(this.physicianOptions.selectedFacility.id)
      ) {
        physicianFacilities.push(this.physicianOptions.selectedFacility.id);
      }
      if (
        speciality &&
        this.physicianOptions.selectedPhysician.taxonomies.length &&
        this.physicianOptions.selectedPhysician.taxonomies[0].desc
      ) {
        speciality.name = this.physicianOptions.selectedPhysician.taxonomies[0].desc;
      }
    }

    return {
      id: this.physicianOptions.selectedPhysician ? this.physicianOptions.selectedPhysician.id : 0,
      firstName: this.physicianOptions.selectedPhysician
        ? this.physicianOptions.selectedPhysician.firstName
        : null,
      lastName: this.physicianOptions.selectedPhysician ? this.physicianOptions.selectedPhysician.lastName : null,
      npi: this.physicianOptions.selectedPhysician ? this.physicianOptions.selectedPhysician.npi : null,
      physicianSpecialityId: specialityId,
      physicianSpeciality: speciality,
      physicianFacilities,
    };
  }
}
