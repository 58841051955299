import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactMethod } from '../../../../../../../shared/enums/contact-method.enum';

@Component({
  selector: 'app-confirm-caregiver-dialog',
  templateUrl: './confirm-caregiver-dialog.component.html',
  styleUrls: ['./confirm-caregiver-dialog.component.scss', '../confirm-dialog.scss'],
})
export class ConfirmCaregiverDialogComponent {
  ContactMethod = ContactMethod;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialogRef<ConfirmCaregiverDialogComponent>,
  ) {
  }
}
