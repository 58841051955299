import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DeleteReason } from '../../../../shared/enums/delete-reason.enum';
import { DocumentService } from '../../services/document.service';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import * as DocumentAction from '../../../../store/document/document.actions';
import { IDocument } from '../../interfaces/document.interface';

@Component({
  selector: 'app-delete-document',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteDocumentModalComponent {
  deleteForm: FormGroup;
  deleteReason = DeleteReason;
  imageID: number;
  documentList: IDocument[];
  selectedDocument: number;

  constructor(
    private store: Store<any>,
    public documentService: DocumentService,
  ) {
    this.deleteForm = new FormGroup({
      blankPage: new FormControl(false),
      other: new FormControl(false),
      otherText: new FormControl(''),
    });

    this.store.select('document').subscribe((state) => {
      if (state.documentList && state.documentList.length) {
        this.imageID = state.documentList[state.selectedDocument].id;
        this.documentList = state.documentList;
        this.selectedDocument = state.selectedDocument;
      }
    });
  }

  deleteImage(): void {
    const formValue = this.deleteForm.getRawValue();

    const reasons = [];
    let otherText = null;

    if(formValue.blankPage) reasons.push(DeleteReason.blankPage);
    if(formValue.other) {
      reasons.push(DeleteReason.other);
      otherText = formValue.otherText;
    }

    const deleteData = {
      deleteReasons: reasons,
      deleteReasonString: otherText,
    };

    this.documentService.deleteImage(this.imageID, deleteData).subscribe(
      (response) => {
        const newDocumentList = this.documentList.filter((document) => document.id !== this.imageID);
        const newSelectedDocument =
          this.selectedDocument + 1 === this.documentList.length ? 0 : this.selectedDocument++;

        this.store.dispatch(new DocumentAction.SetSelectedDocument(newSelectedDocument));
        this.store.dispatch(new DocumentAction.SetDocumentsList(newDocumentList));
      },
      (error) => throwError(error)
    );
  }
}
