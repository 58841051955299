import { Component, OnInit } from '@angular/core';
import { CaseManagementService } from '../../../../shared/services/case-management.service';
import { IUserQueue } from './interfaces/dashboard.interface';
import { throwError } from 'rxjs';
import { QueueService } from './services/dashboard.service';
import { isIntakeQueue } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-supervisor-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class SupervisorDashboardComponent implements OnInit {
  queueList: IUserQueue[];
  intakeQueueList: IUserQueue[];
  supervisorQueueList: IUserQueue[];

  constructor(
    public queueService: QueueService,
    private caseManagementService: CaseManagementService,
  ) { }

  ngOnInit(): void {
    this.queueService.getSupervisorQueues()
    .subscribe(
      (response) => {
        this.supervisorQueueList = response;
      },
      error => {
        throwError(error)
      });

    this.caseManagementService.getIntakeQueues()
    .subscribe(
      (response) => {
        this.intakeQueueList = response;
      },
      error => {
        throwError(error)
      });

    this.caseManagementService.getCaseManagementQueues()
    .subscribe(
      (response) => {
        this.queueList = response;
      },
      error => {
        throwError(error)
      });
  }

  isIntakeQueue(queue): boolean {
    return isIntakeQueue(queue.queueType);
  }
}
