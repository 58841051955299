export class ConfigsContract {
  // All negative ids are reserved.
  // Don`t use negative id for questions when adding to database!!

  public static readonly NewDocumentData = -4;
  public static readonly OutcomeNoteId = -10;
  public static readonly CoversheetNoteId = -20;
  public static readonly AttachmentsControlId = -30;
  public static readonly NoteForAgent = -40;

  public static readonly ReservedFields = [
    ConfigsContract.OutcomeNoteId,
    ConfigsContract.CoversheetNoteId,
    ConfigsContract.AttachmentsControlId,
    ConfigsContract.NoteForAgent,
  ]

  public static readonly SupervisorTaskId = -1;

  // Reserved ranges
  public static readonly ContactCards = [-100, -150];
  public static readonly UIComponents = [-200, -400];

  public static readonly GroupPrefix = 'group_';
  public static readonly SectionPrefix = 'section_';
  public static readonly BlockPrefix = 'block_';
  public static readonly ControlPrefix = 'control_';

  public static readonly UnlimitedAttempts = -1;
  public static readonly DefaultRescheduleTiming = 1;
}
