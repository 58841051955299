<h2>Patient</h2>
<div class="details-wrapper">
  <ng-scrollbar class="custom-scrollbar case-scrollbar">
    <div class="sub-section">
      <div class="header">
        Demographics
      </div>
      <div class="non-editable-field bottom-margin">
        <div class="detail-item">
          <h3>Patient Id</h3>
          <p>{{ patient?.id | empty }}</p>
        </div>
      </div>
      <app-editable-aside-field [fieldId]="'firstName'" [isEdit]="isEditName"
                                (editOutside)="editPatientName(patientFirstName)" (editClosed)="isEditName = false"
                                (saveData)="savePatientData($event, ['firstName', 'lastName'])"
      >
        <div text class="detail-item">
          <h3>Name</h3>
          <p>{{ patient?.lastName | empty }}, {{ patient?.firstName | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <div class="input-row input-item">
            <label for="firstName">First Name</label>
            <input #patientFirstName (keydown.enter)="patientLastName.focus()" [(ngModel)]="patientCopy.firstName"
                   name="firstName" id="firstName" maxlength="50"/>
          </div>
          <div class="input-row input-item">
            <label for="lastName">Last Name</label>
            <input (keydown.enter)="savePatientData(true, ['firstName', 'lastName'])" #patientLastName
                   [(ngModel)]="patientCopy.lastName" id="lastName" type="text" maxlength="50"/>
          </div>
        </div>
      </app-editable-aside-field>
      <app-editable-aside-field [fieldId]="'dateOfBirth'" [isEdit]="isEditBD" (editOutside)="isEditBD = true"
                                (editClosed)="isEditBD = false" (saveData)="savePatientData($event, ['dateOfBirth'])">
        <div text class="detail-item">
          <h3>Date of birth</h3>
          <p>{{ getDateFromISO(patient?.dateOfBirth) | date: 'M/d/yyyy' | empty }} &#183;
            <span *ngIf="patient?.dateOfBirth">{{calculateAge(patient?.dateOfBirth)}} years old</span>
          </p>
        </div>
        <div inputs class="detail-item">
          <div class="input-row date-input input-item">
            <label for="birthDate">Date of birth</label>
            <input matInput (keydown.enter)="savePatientData(true, ['dateOfBirth'])"
                   [(ngModel)]="patientCopy.dateOfBirth" [matDatepicker]="birthDatePicker" id="birthDate"
                   placeholder="MM/DD/YY" [max]="maxDate" (focus)="birthDatePicker.open()"
                   (click)="birthDatePicker.open()"/>
            <mat-datepicker-toggle matSuffix [for]="birthDatePicker" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #birthDatePicker></mat-datepicker>
          </div>
        </div>
      </app-editable-aside-field>
      <div class="non-editable-field upper-margin bottom-margin">
        <div class="detail-item">
          <h3>SSN Last 4 Digits</h3>
          <p>{{ ssn | empty }}</p>
        </div>
      </div>
      <app-editable-aside-field [fieldId]="'gender'" [isEdit]="isEditGender"
                                (editOutside)="isEditGender = true;genderSelect.focus()"
                                (editClosed)="isEditGender = false"
                                (saveData)="savePatientData($event, ['gender'])">
        <div text class="detail-item">
          <h3>Gender</h3>
          <p>{{ genderTypes[patient?.gender] | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <div class="input-row input-item select">
            <label for="genderType">Gender</label>
            <mat-form-field appearance="fill">
              <mat-select name="genderType" #genderSelect
                          (keydown.enter)="$event.stopPropagation();genderSelect.close();savePatientData(true, ['gender'])"
                          [(ngModel)]="patientCopy.gender" placeholder="Gender" id="genderType" disableOptionCentering
                          panelClass="dropdown-item">
                <mat-option [value]="0">Female</mat-option>
                <mat-option [value]="1">Male</mat-option>
                <mat-option [value]="2">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </app-editable-aside-field>
    </div>
    <div class="divider"></div>
    <div class="sub-section consent">
      <div class="header">
        Consent
      </div>
      <app-editable-aside-field [fieldId]="'consent'" (editOutside)="openConsentManagement()">
        <div text class="detail-item">
          <h3>HIPAA Consent</h3>
          <p>{{ currentConsents?.hipaaConsent ?
            (expiredFile(currentConsents?.hipaaConsent?.effectiveEndDate) ?
              'Expired' : (currentConsents?.hipaaConsent?.consentIsOnFile ? 'Yes' : 'No')) : '--'}}
          </p>
        </div>
        <div text class="detail-item">
          <h3>HIPAA Consent Date</h3>
          <p>{{ getDateFromISO(currentConsents?.hipaaConsent?.dateReceived) | date: 'M/d/yyyy' | empty }}</p>
        </div>
      </app-editable-aside-field>
    </div>
    <div class="divider"></div>
    <div class="sub-section">
      <div class="header">
        Contact
      </div>
      <app-editable-aside-field [fieldId]="'contact'" (editOutside)="openAddressManagement()">
        <div text class="detail-item">
          <h3>Address</h3>
          <p *ngIf="patient?.currentMailingAddress?.address?.streetAddress ||
          patient?.currentMailingAddress?.address?.city ||
          patient?.currentMailingAddress?.address?.zipCode; else noAddress">
            {{ patient?.currentMailingAddress?.address?.streetAddress }}
            <ng-container *ngIf="patient?.currentMailingAddress?.address?.city">,
            </ng-container>
            {{ patient?.currentMailingAddress?.address?.city }}
            <ng-container *ngIf="patient?.currentMailingAddress?.address?.zipCode">,
            </ng-container>
            {{ patient?.currentMailingAddress?.address?.zipCode }}
          </p>
          <ng-template #noAddress>
            <p>--</p>
          </ng-template>
        </div>
      </app-editable-aside-field>
      <app-editable-aside-field [fieldId]="'phone'" [disableSave]="phoneForm.invalid" [editMode]="true"
                                (saveData)="savePatientData($event, ['contactInfos', 'phone', ContactMethod.phone])">
        <div text class="detail-item">
          <h3>Patient
            Phone {{phoneForm.value.phoneType ? '(' + ContactTypeNames[phoneForm.value.phoneType] + ')' : ''}}</h3>
          <p>{{ phoneForm.value.phone | mask: '(000) 000 - 0000' | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <form [formGroup]="phoneForm">
            <div class="input-row input-item select">
              <label for="phoneType">Patient Phone Type</label>
              <mat-form-field appearance="fill">
                <mat-select formControlName="phoneType" name="phoneType" placeholder="Select" id="phoneType"
                            disableOptionCentering panelClass="dropdown-item">
                  <mat-option [value]="0">Home</mat-option>
                  <mat-option [value]="1">Mobile</mat-option>
                  <mat-option [value]="2">Work</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-row input-item" [class]="
          phoneForm.controls['phone'].invalid &&
            (phoneForm.controls['phone'].touched)
                ? 'input-error'
                : ''
          ">
              <label for="phone">Patient Phone</label>
              <input mask="(000) 000-0000" placeholder="(_ _ _) _ _ _ - _ _ _ _" formControlName="phone" matInput
                     id="phone" maxlength="50"/>
            </div>
          </form>
        </div>
      </app-editable-aside-field>
      <div class="non-editable-field bottom-margin">
        <div class="detail-item">
          <h3>Best Time to Contact</h3>
          <p>{{ BestTimeToContact[patient?.bestTimeToContact] | empty }}</p>
        </div>
      </div>
      <div class="non-editable-field bottom-margin">
        <div class="detail-item">
          <h3>Best Method to Contact</h3>
          <p>{{ BestMethodToContact[patient?.bestMethodToContact] | empty }}</p>
        </div>
      </div>
      <app-editable-aside-field [fieldId]="'email'" [disableSave]="emailForm.invalid" [editMode]="true"
                                (saveData)="savePatientData($event, ['contactInfos', 'email', ContactMethod.email, ContactType.work])">
        <div text class="detail-item">
          <h3>Email</h3>
          <p>{{ emailForm.value.email | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <form [formGroup]="emailForm">
            <div class="input-row input-item" [class]="
            emailForm.controls['email'].invalid &&
            (emailForm.controls['email'].touched)
                ? 'input-error'
                : ''
          ">
              <label for="email">Email</label>
              <input id="email" maxlength="50" formControlName="email" matInput/>
            </div>
          </form>
        </div>
      </app-editable-aside-field>
    </div>
    <div class="divider"></div>
    <div class="sub-section">
      <div class="header">Medical Info</div>
      <div class="non-editable-field bottom-margin">
        <div class="detail-item">
          <h3>Primary Diagnosis</h3>
          <p>{{ diagnosis?.description | empty }}</p>
        </div>
      </div>
      <div class="non-editable-field bottom-margin">
        <div class="detail-item">
          <h3>Previous Medical History</h3>
          <p>{{ patient?.previousMedicalHistory | empty }}</p>
        </div>
      </div>
      <div class="non-editable-field bottom-margin">
        <div class="detail-item">
          <h3>Weight</h3>
          <p>{{ (patient?.weight ? patient?.weight + ' lbs' : '') | empty }}</p>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>
