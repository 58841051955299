<div class="login-wrapper">
  <div class="logo-wrapper">
    <img srcset="assets/logo/p3_logo.webp 228w,
                 assets/logo/p3_logo@2x.webp 456w,
                 assets/logo/p3_logo@3x.webp 684w"
         sizes="(max-width: 650px) 228px,
               (max-width: 1280px) 228px,
               (min-width: 1281px) 228px"
         src="assets/logo/p3_logo@3x.webp" alt="P3 Technology"/>

    <span class="line"> </span>

    <img [src]="AppConfig.Logo"
         [ngStyle]="{'visibility' : AppConfig.Logo ? 'visible': 'hidden'}"
         width="228px"
         height="47px"
         alt="Logo" />
  </div>

  <h1>Welcome back!</h1>

  <form class="form" [formGroup]="loginForm">
    <div class="field-wrapper">
      <label for="email" class="field-caption">Email</label>
      <input #email
             type="email"
             formControlName="email"
             matInput
             id="email"
             class="field-input"
             (keydown.tab)="focusInput('password')"
             (keydown.enter)="$event.preventDefault();focusInput('password')"
             [tabindex]="-1"
             [ngClass]="{'field-input-invalid': loginForm.controls.email.dirty && loginForm.controls.email.invalid}"
      />
    </div>

    <div class="field-wrapper">
      <label for="password" class="field-caption">Password</label>
      <input #password
             [type]="passwordType"
             formControlName="password"
             matInput
             id="password"
             class="field-input"
             [tabindex]="-1"
             (keydown.enter)="$event.preventDefault()"
             [ngClass]="{'field-input-invalid': loginForm.controls.password.dirty && loginForm.controls.password.invalid}"
      />
      <button class="hide-password-button"
              mat-icon-button
              matSuffix
              (click)="passwordType = passwordType === 'password' ? 'text' : 'password'"
      >
        <mat-icon>{{password.type === 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </div>

    <div class="footer-field">
      <div class="checkbox">
        <input id="rememberMe" type="checkbox" formControlName="rememberMe" />
        <label for="rememberMe">Remember Me</label>
      </div>

      <a routerLink="/login/forgot-password">Forgot Password</a>
    </div>
  </form>
  <button (keydown.tab)="focusInput('email')"
          [disabled]="loginForm.dirty && loginForm.invalid"
          class="submit-button"
          (click)="login()"
  >
    Login
  </button>
</div>

<div *ngIf="errorMessage" class="error-container">{{errorMessage}}</div>
