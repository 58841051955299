// TODO dashboard: charts, chartJS lib
export const queueDataMock = {
  new: {
    tasks: 118,
    assigned: 13,
    data: [-10, -2, 20, 25, 5, -15, -2, 15, 7],
  },
  intake: {
    tasks: 44,
    assigned: 10,
    data: [10, -2, 5, 10, 10, 2, -3, -7, -2],
  },
  stable: {
    tasks: 21,
    assigned: 5,
    data: [10, 5, -5, -2, 3, -1, -3, 2, 4],
  },
};
