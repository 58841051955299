import { Component, OnInit, Output, EventEmitter, ViewChild, Input, AfterViewInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { throwError } from 'rxjs';
import { DataEntryFormService } from '../../services/form.service';
import * as moment from 'moment';
import { numberValidator } from 'src/app/shared/helpers/utils';
import { TabState } from '../../../../configuration/tab.state';
import { BaseFormComponent } from '../base-form.component';
import { AbstractWarnControl } from 'src/app/shared/models/abstract-warning.control';
import { isNotBlank } from '@shared/utilities/empty-checks';
import { DataType } from '@shared/enums/data-types.enum';
import { DataEntryCustomFields } from '../../../../configuration/custom-fields';

@Component({
  selector: 'app-intake-document-prescription-form',
  templateUrl: './prescription.component.html',
  styleUrls: ['../../form.component.scss', './prescription.component.scss'],
})
export class PrescriptionComponent extends BaseFormComponent implements OnInit, AfterViewInit {
  @Input('state') public state: TabState;
  @Input('isLoading') public isFormLoading: boolean;
  @Input() get case(): any { return this._case; }
           set case(newCase: any) { this.setNewCase(newCase); }

  @Output() updateCase = new EventEmitter();
  @Output() submitForm = new EventEmitter();

  @ViewChild('medication') medication: MatSelect;

  private _case;

  public products = [];
  public disabledDate = new Date();
  public prescriptionForm: FormGroup;

  constructor( private dataEntryService: DataEntryFormService ) { super() }

  ngOnInit(): void {
    this.initProducts();

    this.state.SaveState = () => this.saveState();
    this.prescriptionForm = this.state.forms.prescriptionForm;

    if(this.prescriptionForm.controls.physicianSignatureDate.value) this.disabledDate = this.prescriptionForm.controls.physicianSignatureDate.value;
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.medication?.focus(); }, 0);
  }

  public static initForms(state: TabState, caseData: any) {
    state.forms.prescriptionForm = PrescriptionComponent.getPrescriptionForm(state.config.fieldsConfig);

    PrescriptionComponent.populatePrescriptionForm(state.forms.prescriptionForm, caseData, state.autofillData, state);
  }

  public static getProduct(state: TabState) {
    return state.forms.prescriptionForm.getRawValue().medication;
  }

  private static getPrescriptionForm(field) {
    return new FormGroup({
      medication: this.getControl({field: field.medication, disabled: field.medication.isReadOnly}),
      dosage: this.getControl({field: field.dosage}),
      quantity: this.getControl({field: field.quantity, validators: [numberValidator, Validators.min(0)]}),
      signature: this.getControl({field: field.signature, disabled: field.signature.isReadOnly}),
      physicianSignatureDate: this.getControl({ field: field.physicianSignatureDate, skipValidation: true }),
      refillsNumber: this.getControl({ field: field.refillsNumber, validators: [numberValidator] }),
      dispenseAsWritten: this.getControl({field: field.dispenseAsWritten, value: false }),
      isOptionChecked: this.getControl({ field: field.isOptionChecked }),
    });
  }

  private static populatePrescriptionForm(form: FormGroup, caseData, jsonData, state: TabState) {
    if(!caseData && !jsonData) return;
    
    const controls = form.controls;
    const casePrescription = caseData?.prescription || {};
    const jsonPrescription = jsonData?.prescription || {};

    let signature = casePrescription.physicianSignature;

    if (signature === null || typeof signature === 'undefined') //If it hasn't been set by the user yet then pull from imported json data
      signature = jsonPrescription.signatureIncluded;

    const signatureDate = casePrescription.physicianSignatureDate || jsonPrescription.signatureDate;
    const isOptionChecked = casePrescription.customProperties?.find(e => e.name === DataEntryCustomFields.isRelieVRxChecked);

    controls.medication.patchValue(casePrescription.product?.id || jsonPrescription.productId || state.config.fieldsConfig.preselectedProductId);
    controls.dosage.patchValue(casePrescription.dosage || jsonPrescription.dosage);
    controls.quantity.patchValue(casePrescription.quantity || jsonPrescription.quantity);
    controls.signature.patchValue(signature === false ? 1 : signature === true ? 0 : undefined);
    if(signatureDate) controls.physicianSignatureDate.patchValue(new Date(signatureDate));
    controls.refillsNumber.patchValue(casePrescription.refillsNumber || jsonPrescription.numberOfRefills);
    controls.dispenseAsWritten.patchValue(casePrescription.dispenseAsWritten);
    controls.isOptionChecked.patchValue(isOptionChecked?.value ? JSON.parse(isOptionChecked?.value) : undefined);

    (controls.physicianSignatureDate as AbstractWarnControl).SkipValidation = !signature;

    form.updateValueAndValidity();
  }

  checkValidations() {
    const formValue = this.prescriptionForm.getRawValue();

    const signature = formValue.signature;
    const control: AbstractWarnControl = this.prescriptionForm.controls.physicianSignatureDate;

    control.SkipValidation = signature === 0;

    BaseFormComponent.validateForms(this.state);
  }

  private initProducts(){
    // {take: 0} - means that we got all products
    this.dataEntryService.getProducts({take: 0}).subscribe(
      (response) => { 
        this.products = response; 
      },
      (error) => throwError(error)
    );
  }

  private setNewCase(newCase: any) {
    if (this._case || !newCase) return;

    this._case = newCase;
  }

  submitHandler(): void {
    this.saveState();

    if(this.state.isValid) this.goNext();
  }

  private saveState(){
    BaseFormComponent.validateForms(this.state);

    if(this.state.isValid) {
      this.savePrescription();

      this.state.isSaved = true;
    }
  }

  private goNext() {
    this.submitForm.emit();
  }

  savePrescription(): void {
    this._case.prescription = this.getPrescriptionCaseObject();

    const data = { prescription: this._case.prescription };

    this.updateCase.emit(this._case);
    this.updatePrescription(data);
  }

  updatePrescription(data): void {
    this.dataEntryService.updatePrescription(data).subscribe(
      (response) => {
        if (!response.success) return;

        this._case.prescription = response.value;

        this.updateCase.emit(this._case);
      },
      (error) => throwError(error)
    );
  }

  getPrescriptionCaseObject() {
    const formValue = this.prescriptionForm.getRawValue();

    const signature = formValue.signature;
    const medication = formValue.medication 
      ? this.products.find(x => x.id === formValue.medication) 
      : null;
    const signatureDate = formValue.physicianSignatureDate
      ? moment(formValue.physicianSignatureDate).format() 
      : null;

    const customProperties = this.getCustomProperties(this._case.prescription, formValue);
    
    return {
      id: this._case.prescriptionId,
      dosage: formValue.dosage,
      product: medication,
      dispenseAsWritten: formValue.dispenseAsWritten,
      quantity: formValue.quantity ? Number(formValue.quantity) : null,
      physicianSignature: signature === 0 ? true : (signature === 1 ? false : null),
      physicianSignatureDate: signatureDate,
      refillsNumber: formValue.refillsNumber ? Number(formValue.refillsNumber) : null,
      customProperties: customProperties,
    };
  }

  private getCustomProperties(prescription, formValue) { 
    const properties = prescription?.customProperties || [];

    if (this.state.config.fieldsConfig.isOptionChecked.isVisible) { 
      const property = properties.find(e => e.name === DataEntryCustomFields.isRelieVRxChecked) || {
        id: 0,
        name: DataEntryCustomFields.isRelieVRxChecked,
        dataType: DataType.Boolean,
      };

      property.value = isNotBlank(formValue.isOptionChecked) ? JSON.stringify(formValue.isOptionChecked) : null;

      if (!property.id) properties.push(property);
    }

    return properties;
  }
}
