import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-splash',
  templateUrl: './empty-splash.component.html',
  styleUrls: ['./empty-splash.component.scss'],
})
export class EmptySplashComponent {
  @Input() show: boolean;
  @Input() searchValue: string;

  constructor() {
  }

}
