export class InterpolationConfig {
  // Allowed values like:
  // {{$custom}} - {{prop.prop}} - {{prop.pr_op.Prop}}
  // {{$custom|attribute}} - {{prop.prop|attribute}} - {{prop.pr_op.Prop|attribute}}
  // Other values is not-allowed!
  //
  // Example string: "Welcome Call To {{patient.firstName}} {{patient.lastName|bold}} by {{$PatientPhone|bold highlighted}}";
  // Also you can use https://regex101.com/ for debug this in the future
  public static readonly SelectorRegex = /{{([$]?[a-zA-Z_]+[\.]?[a-zA-Z]+)+([\|]([a-zA-Z]+[\s]?[a-zA-Z]+)+)*}}/g;
  public static readonly HighlightRegex = /{{\|[^\|]+[\|]{1}[a-zA-Z\s]+}}/g;
  public static readonly BracketsSelectorRegex = /{{|}}/g;
  public static readonly HighlightBracketsSelectorRegex = /{{\||}}/g;

  public static readonly StyleSplitter = ' ';
  public static readonly GeneralSplitter = '|';
  public static readonly PropertySplitter = '.';

  public static readonly CustomSelectorPrefix = '$';


  // if you need to extend appearance just add some classes here
  // custom styled text should be rendered by using [innerHtml] attribute
  public static readonly StyleClassMatcher = {
    'bold': 'bold',
    'highlighted': 'highlighted',
  }
}
