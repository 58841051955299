import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() path = '/assets/images/3d_cube.json';

  options: AnimationOptions;

  constructor() {
  }

  ngOnInit(): void {
    this.options = {
      path: this.path
    };
  }

  animationCreated(animationItem: AnimationItem): void {
  }
}
