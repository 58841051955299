<div class="pharmacy-insurance" [formGroup]="insuranceForm">
  <div class="form-row"
       *ngIf="state.config.fieldsConfig.externalSourcePharmacyBenefitPlanId.isVisible">
    <div *ngIf="state.config.fieldsConfig.externalSourcePharmacyBenefitPlanId.isVisible"
         style="width: 267px"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['externalSourcePharmacyBenefitPlanId']),
            'input-success' : isValid(insuranceForm.controls['externalSourcePharmacyBenefitPlanId'])
          }">
      <label
             for="externalSourcePharmacyBenefitPlanId">{{ state.config.fieldsConfig.externalSourcePharmacyBenefitPlanId.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.externalSourcePharmacyBenefitPlanId.isReadOnly"
             name="externalSourcePharmacyBenefitPlanId"
             id="externalSourcePharmacyBenefitPlanId"
             [formControl]="insuranceForm.controls['externalSourcePharmacyBenefitPlanId']" />
    </div>
  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.pharmacyInsuranceName.isVisible">
    <div style="width: 267px"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['pharmacyInsuranceName']),
            'input-success' : isValid(insuranceForm.controls['pharmacyInsuranceName'])
          }">
      <label for="pharmacyInsuranceName">{{ state.config.fieldsConfig.pharmacyInsuranceName.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.pharmacyInsuranceName.isReadOnly"
             name="pharmacyInsuranceName"
             id="pharmacyInsuranceName"
             formControlName="pharmacyInsuranceName" />
    </div>
  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.phone.isVisible">
    <div style="width: 267px"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['phone']),
            'input-success' : isValid(insuranceForm.controls['phone'])
          }">
      <label for="phone">{{ state.config.fieldsConfig.phone.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.phone.isReadOnly"
             name="phone"
             id="phone"
             formControlName="phone"
             mask="(000) 000-0000"
             placeholder="(_ _ _) _ _ _ - _ _ _ _" />
    </div>
  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.pharmacyId.isVisible">
    <div style="width: 267px"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['pharmacyId']),
            'input-success' : isValid(insuranceForm.controls['pharmacyId'])
          }">
      <label for="pharmacyId">{{ state.config.fieldsConfig.pharmacyId.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.pharmacyId.isReadOnly"
             name="pharmacyId"
             id="pharmacyId"
             formControlName="pharmacyId" />
    </div>
  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.BIN.isVisible">
    <div style="width: 267px"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['BIN']),
            'input-success' : isValid(insuranceForm.controls['BIN'])
          }">
      <label for="BIN">{{ state.config.fieldsConfig.BIN.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.BIN.isReadOnly"
             name="BIN"
             id="BIN"
             formControlName="BIN" />
    </div>
  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.PCN.isVisible">
    <div style="width: 267px"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['PCN']),
            'input-success' : isValid(insuranceForm.controls['PCN'])
          }">
      <label for="PCN">{{ state.config.fieldsConfig.PCN.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.PCN.isReadOnly"
             name="PCN"
             id="PCN"
             formControlName="PCN" />
    </div>
  </div>
</div>
