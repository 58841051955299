<div mat-dialog-title>
  <h1>
    <mat-icon>email</mat-icon>
    Email Sent
  </h1>
  <button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p>The following was sent to <b>{{patientFirstName}} {{patientLastName}}</b>:
  </p>
  <div class="dialog-body">
    <h5>Sent</h5>
    <p>{{createDate | date: "EEE, LLL dd, yyyy h:mmaaaaa\'m\'"}}</p>
    <h5>Subject</h5>
    <p>{{subject}}</p>
    <h5>Message</h5>
    <p [innerHtml]="body"></p>
  </div>
</div>
