import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PublicGuard } from './core/guards/public.guard';
import { RoleGuard } from './core/guards/role.guard';
import { HomeComponent } from './features/home/components/home/home.component';
import { OktaCallbackComponent } from './features/login/components/callback.component';
import { SearchComponent } from './shared/components/search/search.component';
import { Roles } from './shared/enums/roles.enum';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin, Roles.AgentRestricted, Roles.AgentAll]},
  },
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin, Roles.AgentRestricted, Roles.AgentAll]},
  },
  {
    path: 'login',
    canActivate: [PublicGuard],
    loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    canActivate: [PublicGuard],
    loadChildren: () => import('./features/user-registration/user-registration.module').then((m) => m.UserRegistrationModule),
  },
  {
    path: 'profile',
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin, Roles.AgentRestricted, Roles.AgentAll]},
    loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: '',
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin, Roles.AgentRestricted, Roles.AgentAll]},
    loadChildren: () => import('./features/intake/intake.module').then((m) => m.IntakeModule),
  },
  {
    path: '',
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin, Roles.AgentRestricted, Roles.AgentAll]},
    loadChildren: () => import('./features/manager/manager.module').then((m) => m.ManagerModule),
  },
  {
    path: '',
    canActivate: [RoleGuard],
    data: {roles: [Roles.ProgramAdmin, Roles.SystemAdmin]},
    loadChildren: () => import('./features/supervisor/supervisor.module').then((m) => m.SupervisorModule),
  },
  {
    path: 'reports',
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin]},
    loadChildren: () => import('./features/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'account-management',
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin]},
    loadChildren: () => import('./features/management/account-management/account-management.module').then((m) => m.AccountManagement),
  },
  {
    path: 'payer-management',
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin]},
    loadChildren: () => import('./features/management/payer-management/payer-management.module').then((m) => m.PayerManagementModule),
  },
  {
    path: 'pharmacy-plan-management',
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin]},
    loadChildren: () =>
      import('./features/management/pharmacy-plan-management/pharmacy-plan-management.module').then((m) => m.PharmacyPlanManagementModule),
  },
  {path: '**', redirectTo: '/error/404'},
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'top',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
