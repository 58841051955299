<div mat-dialog-title>
  <h1><img alt="warning" src="assets/icons/icon-warning.svg"/>Are you sure you want to close this case?</h1>
  <button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="description">
  <span>Closing the case will cancel all open Tasks and end all automated workflow actions.</span>
</div>

<div class="content">
  <span>Select Reason</span>
  <div class="input-item select">
    <mat-form-field appearance="fill">
      <mat-select [(ngModel)]="reason" name="reason" placeholder="Select Reason" id="reason" disableOptionCentering panelClass="dropdown-item">
        <mat-option *ngFor="let closingReason of reasons" [value]="closingReason.id">{{closingReason.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions class="actions">
  <button class="cancel" mat-button (click)="close()">Cancel</button>
  <button class="submit" mat-button (click)="submit()" [disabled]="!reason">Close Case</button>
</div>
