<div class="document-sidebar-form">
  <div class="aside-wrapper">
    <h2>{{state.config.tabDisplayName}}</h2>

    <div class="input-item checkbox"
         *ngIf="state.config.fieldsConfig.useCheckBox">
      <mat-checkbox name="hideFields"
                    [formControl]="patientHasInsurance">
        {{ state.config.fieldsConfig.patientHasInsurance.displayName }}
      </mat-checkbox>
    </div>

    <div class="form-row"
         style="margin-bottom: 20px;"
         *ngIf="!state.config.fieldsConfig.useCheckBox">
      <div class="input-item select"
           [ngClass]="{
          'input-error' : patientHasInsurance.invalid && patientHasInsurance.touched,
          'input-success' : patientHasInsurance.valid && patientHasInsurance.touched
        }">
        <label>{{ state.config.fieldsConfig.patientHasInsurance.displayName }}</label>
        <mat-form-field appearance="fill"
                        class="input-medical">
          <mat-select [formControl]="patientHasInsurance"
                      name="medicalInsurance"
                      placeholder="Select"
                      id="medicalInsurance"
                      disableOptionCentering
                      panelClass="dropdown-item"
                      autofocus>
            <mat-option [value]="undefined">--</mat-option>
            <mat-option *ngFor="let option of extendedBinaryOptions"
                        [value]="option.value">{{option.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <ng-container *ngIf="hasInsurance">
      <ng-container *ngIf="!!getForm(CoverageType.medical, PlanOrder.primary)">
        <app-medical-insurance [state]="state"
                               [insuranceForm]="getForm(CoverageType.medical, PlanOrder.primary)"
                               (onInsuranceRemoved)="insuranceRemovedHandler($event)"
                               style="margin-bottom: 20px;">
        </app-medical-insurance>
      </ng-container>

      <div class="input-item checkbox" *ngIf="canHaveSecondaryInsurance">
        <mat-checkbox [formControl]="addSecondaryMedicalInsurance">
          See secondary insurance information attached
        </mat-checkbox>
      </div>

      <ng-container *ngIf="!!getForm(CoverageType.medical, PlanOrder.secondary)">
        <app-medical-insurance [state]="state"
                               [insuranceForm]="getForm(CoverageType.medical, PlanOrder.secondary)"
                               (onInsuranceRemoved)="insuranceRemovedHandler($event)"
                               style="margin-bottom: 20px;">
        </app-medical-insurance>
      </ng-container>

      <ng-container *ngIf="!!getForm(CoverageType.pharmacy, PlanOrder.primary)">
        <app-pharmacy-insurance [state]="state"
                                [insuranceForm]="getForm(CoverageType.pharmacy, PlanOrder.primary)"
                                style="margin-bottom: 20px;">
        </app-pharmacy-insurance>
      </ng-container>
    </ng-container>
  </div>

  <button *ngIf="state.config.nextTab"
          type="submit"
          class="next-button next-stack"
          (click)="submitHandler()"
          [disabled]="isFormLoading || formInvalid">
    <b>Next:</b> {{ state.config.nextTab.config.tabDisplayName }}
    <mat-icon>east</mat-icon>
  </button>

  <button *ngIf="!state.config.nextTab"
          type="submit"
          class="next-button next-stack"
          (click)="submitHandler()"
          [disabled]="isFormLoading || formInvalid">
    <b>Complete</b>
  </button>
</div>
