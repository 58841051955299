<div mat-dialog-title>
  <h1><img alt="age" src="assets/icons/icon-warning.svg"/>Escalate Task</h1>
  <button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="description">
  <span>Why are you escalating this task?</span>
</div>

<div class="content">
  <span>Reason</span>
  <textarea [(ngModel)]="reason" type="text"></textarea>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="escalate()" [disabled]="!reason">Escalate to Supervisor
    <mat-icon>east</mat-icon>
  </button>
</div>
