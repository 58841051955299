// TODO: http://localhost:4200/queue-manager-task?id=5 table columns filters
export const caseTaskFilterData = {
  phase: {
    enrollment: false,
    fulfillment: false,
  },
  task: {
    welcomeCall: false,
    missingInfo: false,
    pharmacyTriage: false,
  },
  patient: null,
  physician: null,
  payer: null,
  pharmacy: null,
  dueDate: {start: '', end: ''},
};
