export class Method {
  public static readonly LockItem: string = "LockItem";
  public static readonly UnlockItem: string = "UnlockItem";
  public static readonly NotifyCompleted: string = "NotifyCompleted";
}

export class Listener {
  public static readonly LockQueueItem: string = "LockQueueItem";
  public static readonly UnlockQueueItem: string = "UnlockQueueItem";
  public static readonly CompletedQueueItem: string = "CompletedQueueItem";
}
