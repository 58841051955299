import { ActionReducerMap } from '@ngrx/store';
import { ICase } from '../shared/interfaces/case.interface';
import { IUser } from '../shared/interfaces/user.interface';

import * as user from './user/user.reducers';
import * as selectedCase from './case/case.reducers';
import * as document from './document/document.reducers';

import { IDocument } from '../features/intake/interfaces/document.interface';

export interface State {
  user: IUser;
  case: ICase;
  document: { documentList: [IDocument] };
}

export const reducers: ActionReducerMap<State> = {
  user: user.reducer,
  case: selectedCase.reducer as any,
  document: document.reducer as any,
};
