import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-selected-physician',
  templateUrl: './selected-physician.component.html',
  styleUrls: ['./selected-physician.component.scss'],
})
export class SelectedPhysicianComponent {
  @Input() showEditButton = false;
  @Input() headerOnly = true;

  @Output() editForm: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeForm: EventEmitter<void> = new EventEmitter<void>();

  @Output() exitForm: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveForm: EventEmitter<void> = new EventEmitter<void>();

  editMode = false;

  constructor() { }

  edit(): void {
    this.editMode = true;
    this.editForm.emit();
  }

  exit(): void {
    this.editMode = false;
    this.saveForm.emit();
  }

  save(): void {
    this.editMode = false;
    this.saveForm.emit();
  }
}
