export enum FieldType {
  Dropdown = 1,
  Input = 2,
  InputsRow = 3,
  ToggleGroup = 4,
  Datepicker = 5,
  DatepickerRange = 6,
  AddButton = 7,
  InputDropdownRow = 8,
  Textarea = 9,
  StaticTemplate = 10,
  ContactItem = 11,
  ContactsList = 12,
  PreviewDocument = 13,
  SelectAttachments = 14,
  Message = 15,
  CheckboxesAdvanced = 16,
  CreateActivity = 17,
}
