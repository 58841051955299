import { Component, OnChanges, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { CaseColumns } from '../../../../../../shared/enums/case-columns.enum';
import { ICase } from '../../../../../../shared/interfaces/case.interface';
import * as CaseAction from '../../../../../../store/case/case.actions';
import { enumToArray } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-manager-dashboard-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss'],
})
export class ManagerDashboardCasesComponent implements OnChanges {
  @Input() cases: ICase[];

  columnsToDisplay = enumToArray(CaseColumns);
  dataSource;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private store: Store<any>) {
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.cases);
    this.dataSource.sort = this.sort;
  }

  selectCase(selectedCase): void {
    this.store.dispatch(new CaseAction.SetCase(selectedCase));
  }
}
