<mat-expansion-panel id="MedicalBBPanel" [expanded]="expanded" (afterExpand)="openPanel(1)"
                     (afterCollapse)="closePanel(1)">
  <mat-expansion-panel-header>
    <mat-panel-title
    >
      <div class="task-indicator" *ngIf="selectedTask">
        <mat-progress-spinner
          [diameter]="32"
          [class]="setIndicatorColor(settings.value)"
          [value]="settings.value"
        >
        </mat-progress-spinner>
        <mat-icon *ngIf="settings.value === 100">check</mat-icon>
        <span *ngIf="settings.value !== 100">{{ settings.value }}%</span>
      </div>
      Medical (Buy & Bill)
    </mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="collapse-section" [formGroup]="medicalBBForm">
    <div class="collapse-item" formGroupName="coverage">
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isCoverage">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is there coverage?</span>
        </div>
      </div>
    </div>
    <div id="MedicalPolicyLimits"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('MedicalPolicyLimits')"
         class="collapse-item" formGroupName="policyLimits">
      <h3>Policy Limits</h3>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isSameAsPlanInfo">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Are limitations same as plan info?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item select">
          <label>Individual or Family Plan, or Both?</label>
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="type"
              name="type"
              placeholder="Select"
              id="type"
              disableOptionCentering
              panelClass="dropdown-item"
            >
              <mat-option>--</mat-option>
              <mat-option [value]="0">Individual</mat-option>
              <mat-option [value]="1">Family</mat-option>
              <mat-option [value]="2">Both</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <h5>Deductible</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualDeductibleAmountBB">Individual Deductible Amount</label>
          <input
            name="individualDeductibleAmount"
            id="individualDeductibleAmountBB"
            formControlName="individualDeductibleAmount"
            mask="0*"
          />
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualDeductibleMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has individual deductible been met?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualDeductibleOOP">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is deductible included in OOP?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isCaryNextYear">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does deductible carry over to next plan year?</span>
        </div>
      </div>
      <h5>Out of Pocket</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualOOPBB">Individual Out of Pocket</label>
          <input name="individualOOP" id="individualOOPBB" formControlName="individualOOP" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualOOPMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has individual OOP been met?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualOOPAmountBB">Individual OOP Amount Met</label>
          <input
            name="individualOOPAmount"
            id="individualOOPAmountBB"
            formControlName="individualOOPAmount"
            mask="0*"
          />
          <span>$</span>
        </div>
      </div>
      <h5>Lifetime Max</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualLifetimeUnlimited">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is the individual lifetime maximum unlimited?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualLifetimeMaximumBB">Individual Lifetime Maximum</label>
          <input
            name="individualLifetimeMaximum"
            id="individualLifetimeMaximumBB"
            formControlName="individualLifetimeMaximum"
            mask="0*"
          />
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualLifetimeMaximumMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has the individual lifetime maximum been met?</span>
        </div>
      </div>
      <h5>Annual Cap</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualAnnualCapBB">Individual Annual Cap</label>
          <input
            name="individualAnnualCap"
            id="individualAnnualCapBB"
            formControlName="individualAnnualCap"
            mask="0*"
          />
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualAnnualCapMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has individual annual cap been met?</span>
        </div>
      </div>
    </div>
    <div class="collapse-item" id="SpecialtyPharmaciesBB"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('SpecialtyPharmaciesBB')"
         formGroupName="networkSpecialtyPharmacies">
      <h3>In-Network Specialty Pharmacies</h3>
      <div class="form-row">
        <div class="input-item select">
          <label>Specialty Pharmacy</label>
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="specialty"
              name="specialty"
              placeholder="Select"
              id="specialty"
              disableOptionCentering
              panelClass="dropdown-item"
            >
              <mat-option>--</mat-option>
              <mat-option [value]="0">Walgreen's Specialty</mat-option>
              <mat-option [value]="1">Specialty 2</mat-option>
              <mat-option [value]="2">Specialty 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="phone">SP Phone</label>
          <input
            name="phone"
            id="phone"
            formControlName="phone"
            mask="(000) 000-0000"
            placeholder="(_ _ _) _ _ _ - _ _ _ _"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="fax">SP Fax</label>
          <input
            name="fax"
            id="fax"
            formControlName="fax"
            mask="(000) 000-0000"
            placeholder="(_ _ _) _ _ _ - _ _ _ _"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPreferred">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is this SP preferred by the MD?</span>
        </div>
      </div>
      <button class="primary-btn" type="button">
        <mat-icon>add_circle</mat-icon>
        Add In-Network SP
      </button>
    </div>
    <div id="PayerLimits"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PayerLimits')"
         class="collapse-item" formGroupName="payerLimits">
      <h3>Payer Limits</h3>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isAdministrationIncludes">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is administration of the drug included in the cost of the drug?</span>
        </div>
      </div>
      <h5>Before Deductible Met</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="drugCopayBefore">Drug Copay</label>
          <input name="drugCopayBefore" id="drugCopayBefore" formControlName="drugCopayBefore" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="drugCoInsuranceBefore">Drug Co-Insurance</label>
          <input
            name="drugCoInsuranceBefore"
            id="drugCoInsuranceBefore"
            formControlName="drugCoInsuranceBefore"
            mask="0*"
            maxLength="3"
            suffix="%"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPatientResponsibleBefore">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is patient responsible for the greater of co-pay or co-insurance?</span>
        </div>
      </div>
      <h5>After Deductible Met</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="drugCopayAfter">Drug Copay</label>
          <input name="drugCopayAfter" id="drugCopayAfter" formControlName="drugCopayAfter" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="drugCoInsuranceAfter">Drug Co-Insurance</label>
          <input
            name="drugCoInsuranceAfter"
            id="drugCoInsuranceAfter"
            formControlName="drugCoInsuranceAfter"
            mask="0*"
            maxLength="3"
            suffix="%"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPatientResponsibleAfter">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is patient responsible for the greater of co-pay or co-insurance?</span>
        </div>
      </div>
      <h5>Coverage After OOP</h5>
      <div class="form-row">
        <div class="input-item">
          <label for="drugCoInsuranceAfterOOP">Drug Co-Insurance</label>
          <input
            name="drugCoInsuranceAfterOOP"
            id="drugCoInsuranceAfterOOP"
            formControlName="drugCoInsuranceAfterOOP"
            mask="0*"
            maxLength="3"
            suffix="%"
          />
        </div>
      </div>
      <h5>Coverage After Annual Cap Met</h5>
      <div class="form-row">
        <div class="input-item">
          <label for="drugCoInsuranceAfterAnnualCap">Drug Co-Insurance</label>
          <input
            name="drugCoInsuranceAfterAnnualCap"
            id="drugCoInsuranceAfterAnnualCap"
            formControlName="drugCoInsuranceAfterAnnualCap"
            mask="0*"
            maxLength="3"
            suffix="%"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="administrationsCoInsuranceAfterAnnualCap">Administration Co-Insurance</label>
          <input
            name="administrationsCoInsuranceAfterAnnualCap"
            id="administrationsCoInsuranceAfterAnnualCap"
            formControlName="administrationsCoInsuranceAfterAnnualCap"
            mask="0*"
            maxLength="3"
            suffix="%"
          />
        </div>
      </div>
    </div>
    <div id="OfficeVisit"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('OfficeVisit')"
         class="collapse-item" formGroupName="officeVisit">
      <h3>Office Visit</h3>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isNeedShipped">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does drug need to be shipped to MD to be covered?</span>
        </div>
      </div>
      <h5>Office Visit</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="officeVisitCopay">Office Visit Copay</label>
          <input name="officeVisitCopay" id="officeVisitCopay" formControlName="officeVisitCopay" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="officeVisitCoInsurance">Office Visit Co-Insurance</label>
          <input
            name="officeVisitCoInsurance"
            id="officeVisitCoInsurance"
            formControlName="officeVisitCoInsurance"
            mask="0*"
            maxLength="3"
            suffix="%"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isOfficeVisitCoInsuranceBilled">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Was there a benefits change / is there coverage if OV not billed?</span>
        </div>
      </div>
      <h5>Specialist OV</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="specialistCopay">Specialist OV Copay</label>
          <input name="specialistCopay" id="specialistCopay" formControlName="specialistCopay" mask="0*"/>
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="specialistCoInsurance">Specialist OV Co-Insurance</label>
          <input
            name="specialistCoInsurance"
            id="specialistCoInsurance"
            formControlName="specialistCoInsurance"
            mask="0*"
            maxLength="3"
            suffix="%"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isSpecialistCoInsuranceBilled">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Was there a benefits change / is there coverage if OV not billed?</span>
        </div>
      </div>
    </div>
    <div id="MedicalAuthorizationDeterminations"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('MedicalAuthorizationDeterminations')"
         class="collapse-item" formGroupName="authorization">
      <h3>Authorization & Determination</h3>
      <h5>Prior Authorization (PA)</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPARequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is PA required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="paPhone">Entity Phone</label>
          <input
            name="paPhone"
            id="paPhone"
            formControlName="paPhone"
            mask="(000) 000-0000"
            placeholder="(_ _ _) _ _ _ - _ _ _ _"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="needInitiateCall">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does CM need to initiate call?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="paFax">Entity Fax</label>
          <input
            name="paFax"
            id="paFax"
            formControlName="paFax"
            mask="(000) 000-0000"
            placeholder="(_ _ _) _ _ _ - _ _ _ _"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="needInitiateFax">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does CM need to initiate fax?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isStepEditRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is a step edit required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="stepDrugs">Step Drug(s)</label>
          <input name="stepDrugs" id="stepDrugs" formControlName="stepDrugs"/>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPAonFile">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is there a PA on file?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="paReference">PA Reference #</label>
          <input name="paReference" id="paReference" formControlName="paReference"/>
        </div>
      </div>
      <div class="form-row date-row">
        <div class="input-item">
          <label for="paValidDateStart">PA Valid Through</label>
          <div class="date-input">
            <input
              matInput
              [matDatepicker]="paValidDateStart"
              id="paValidDateStart"
              formControlName="paValidDateStart"
              placeholder="MM/DD/YY"
              [max]="medicalBBForm.controls.authorization.value.paValidDateEnd"
              (focus)="paValidDateStart.open()"
              (click)="paValidDateStart.open()"
            />
            <mat-datepicker-toggle matSuffix [for]="paValidDateStart" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #paValidDateStart></mat-datepicker>
          </div>
        </div>
        <div class="devider">&mdash;</div>
        <div class="input-item">
          <div class="date-input">
            <input
              matInput
              [matDatepicker]="paValidDateEnd"
              id="paValidDateEnd"
              formControlName="paValidDateEnd"
              placeholder="MM/DD/YY"
              [min]="medicalBBForm.controls.authorization.value.paValidDateStart"
              (focus)="paValidDateEnd.open()"
              (click)="paValidDateEnd.open()"
            />
            <mat-datepicker-toggle matSuffix [for]="paValidDateEnd" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #paValidDateEnd></mat-datepicker>
          </div>
        </div>
      </div>
      <h5>Pre-Determination (Pre-D)</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPreDRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is Pre-D required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="paFax">Entity Phone</label>
          <input
            name="preDPhone"
            id="preDPhone"
            formControlName="preDPhone"
            mask="(000) 000-0000"
            placeholder="(_ _ _) _ _ _ - _ _ _ _"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="preDFax">Entity Fax</label>
          <input
            name="preDFax"
            id="preDFax"
            formControlName="preDFax"
            mask="(000) 000-0000"
            placeholder="(_ _ _) _ _ _ - _ _ _ _"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="needCMInitiateFax">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does CM need to initiate fax?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isStepPreDEditRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is a step edit required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="stepPreDDrugs">Step Drug(s)</label>
          <input name="stepPreDDrugs" id="stepPreDDrugs" formControlName="stepPreDDrugs"/>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isLMNRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is LMN required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isLMNOnFile">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is LMN on file?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPreDFile">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is Pre-D on file?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="preDReference">Pre-D Reference #</label>
          <input name="preDReference" id="preDReference" formControlName="preDReference"/>
        </div>
      </div>
      <div class="form-row date-row">
        <div class="input-item">
          <label for="preDValidDateStart">Pre-D Valid Through</label>
          <div class="date-input">
            <input
              matInput
              [matDatepicker]="preDValidDateStart"
              id="preDValidDateStart"
              formControlName="preDValidDateStart"
              placeholder="MM/DD/YY"
              [max]="medicalBBForm.controls.authorization.value.preDValidDateEnd"
              (focus)="preDValidDateStart.open()"
              (click)="preDValidDateStart.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="preDValidDateStart"
              tabindex="-1"
            ></mat-datepicker-toggle>
            <mat-datepicker #preDValidDateStart></mat-datepicker>
          </div>
        </div>
        <div class="devider">&mdash;</div>
        <div class="input-item">
          <div class="date-input">
            <input
              matInput
              [matDatepicker]="preDValidDateEnd"
              id="preDValidDateEnd"
              formControlName="preDValidDateEnd"
              placeholder="MM/DD/YY"
              [min]="medicalBBForm.controls.authorization.value.preDValidDateStart"
              (focus)="preDValidDateEnd.open()"
              (click)="preDValidDateEnd.open()"
            />
            <mat-datepicker-toggle matSuffix [for]="preDValidDateEnd" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #preDValidDateEnd></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="preDDoses">Doses</label>
          <input name="preDDoses" id="preDDoses" formControlName="preDDoses"/>
        </div>
        <div class="input-item select">
          <label>Per</label>
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="preDPer"
              name="preDPer"
              placeholder="Select Timeframe"
              id="preDPer"
              disableOptionCentering
              panelClass="dropdown-item"
            >
              <mat-option>--</mat-option>
              <mat-option [value]="0">Day</mat-option>
              <mat-option [value]="1">Week</mat-option>
              <mat-option [value]="2">Month</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div id="MedicalExclusions"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('MedicalExclusions')"
         class="collapse-item" formGroupName="exclusions">
      <h3>Exclusions</h3>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isExclusions">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Are there exclusions?</span>
        </div>
      </div>
      <h3 style="margin-bottom: 16px">
        <mat-icon>assignment</mat-icon>
        Exclusion(s) Notes
      </h3>
      <div class="form-row">
        <div class="input-item">
          <textarea matInput formControlName="exclusionsNote"></textarea>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
