import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root',
})

export class PublicGuard implements CanActivate {
  constructor(
    public oktaAuth: OktaAuthService,
    private router: Router,
  ) {
  }

  async canActivate(): Promise<any> {
    let result = true;

    const isAuthenticated = await this.oktaAuth.isAuthenticated();

    if (isAuthenticated) {
      result = false;
      this.router.navigate(['']);
    }

    return result;
  }
}
