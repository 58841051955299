import { IInsurance } from '@shared/interfaces/insurance.interface';
import { InsurancePriorityTypesEnum } from '@shared/enums/insurance-priority-types.enum';
import { InsuranceOutcomeTypesEnum } from '@shared/enums/insurance-outcome-types.enum';
import { CoverageType } from '@shared/enums/insurance/coverage-type.enum';

export const insurancesListDataMock: IInsurance[] = [
  {
    id: 0,
    payerName: 'Humana',
    planName: 'National POS - Open Access',
    priorityId: InsurancePriorityTypesEnum.Primary,
    outcome: InsuranceOutcomeTypesEnum.Covered,
    completed: '2021-03-10T02:00:00Z',
    copay: 23,
    coinsurance: 89,
    isRequired: true,
    coverageType: CoverageType.medical,
    effectiveFrom: '2021-03-10T02:00:00Z',
    effectiveTo: '2023-03-10T02:00:00Z',
  },
  {
    id: 1,
    payerName: 'Aetna',
    planName: 'Gold Star HMO',
    priorityId: InsurancePriorityTypesEnum.Secondary,
    outcome: InsuranceOutcomeTypesEnum.NoCoverage,
    completed: '2022-03-10T02:00:00Z',
    copay: 848,
    coinsurance: 39,
    isRequired: false,
    coverageType: CoverageType.medical,
    effectiveFrom: '2021-03-10T02:00:00Z',
    effectiveTo: '2023-03-10T02:00:00Z',
  },
  {
    id: 2,
    payerName: 'Humana',
    planName: 'National POS - Open Access',
    priorityId: InsurancePriorityTypesEnum.Tertiary,
    outcome: InsuranceOutcomeTypesEnum.NotCovered,
    completed: '2023-03-10T02:00:00Z',
    copay: 58498,
    coinsurance: 99,
    isRequired: true,
    coverageType: CoverageType.pharmacy,
    effectiveFrom: '2021-03-10T02:00:00Z',
    effectiveTo: '2021-03-10T02:00:00Z',
  },
];
