import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { AbstractWarnControl } from 'src/app/shared/models/abstract-warning.control';
import { FieldsConfig } from '../configs/fields-default.config';
import { FieldsRequirements } from '../parameters/field-requirements';

export function CustomRequirementsValidator(field: FieldsConfig): ValidatorFn {
  return (control: AbstractWarnControl): ValidationErrors | null => {
    control.warnings = {};
    control.withWarnings = false;

    if (control.SkipValidation || control.disabled) return;

    const emptyValues = field.isMissedWhen?.length
      ? [undefined, null, '', ...field.isMissedWhen]
      : [undefined, null, ''];

    const isInvalid = field.isVisible && emptyValues.includes(control.value);

    switch(field.requirements)
    {
      case FieldsRequirements.mandatory : {
        // Required to move to the missing information step or complete Intake.  
        // User cannot advance/complete the Intake process for this queueItem without filling in these fields.
        return isInvalid ? { error: 'Mandatory field is empty!' } : null;
      }

      case FieldsRequirements.required : { 
        // Required to be collected, but does not prevent user from going to the missing information step 
        // (For example, it’s required to collect the physician NPI #, but the doctor may not have filled that in 
        // so we need to allow the user to go to the missing information step, where this “required” field will be listed)
        if(isInvalid) {
          control.warnings.required = {
            name: (field.fieldNameInMissingList || field.displayName),
            followUps: field.followUps,
          };
          control.withWarnings = true;
        }
        return null; 
      }

      case FieldsRequirements.desired : { 
        // Optional but desired.  Does not have to be collected, but in the Missing information step 
        // this information will show as “Desired” information and we’ll still ask the doctor for it.
        if(isInvalid) {
          control.warnings.desired = {
            name: (field.fieldNameInMissingList || field.displayName),
            followUps: field.followUps,
          }; 
          control.withWarnings = true;
        }
        return null; 
      }

      case FieldsRequirements.apiRequirement : {
        // Fields marked “API Requirement” means we will always get the data in the API and we can assume we’ll have it.
        return null;
      }
      
      case FieldsRequirements.optional : {
        // Entirely Optional.  User can move through flow without it 
        return null;
      }

      default : {
        return { error: 'FieldsRequirements is wrong!' };
      }
    }
  };
}
