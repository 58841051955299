import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { IResponse } from '../../../../shared/interfaces/response.interface';
import * as DocumentAction from '../../../../store/document/document.actions';
import { CaseService } from '../../../manager/components/case/services/case.service';
import { Document } from '../../models/document.model';
import { DocumentService } from '../../services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError, Subscription, Observable, ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EmptyDocumentModalComponent } from '../../modals/empty/empty.component';
import { ImageService } from 'src/app/shared/services/image.service';
import { takeUntil } from 'rxjs/operators';
import { NameResolver } from '@shared/utilities/name-resolver';
import { SupervisorDocumentService } from '../../services/supervisor-document.service';

@Component({
  selector: 'app-intake-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit, OnDestroy {
  documents = [];
  queueID: number;
  queueItemId: string;

  private isSupervisorView = false;
  private subscription: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private supervisorDocumentService: SupervisorDocumentService,
    public dialog: MatDialog,
    private store: Store<any>,
    public documentService: DocumentService,
    private caseService: CaseService,
    private imageService: ImageService,
    public router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(true));

    this.route.queryParams.subscribe((params) => {
      this.queueItemId = params.id;
      this.queueID = parseInt(params.queueId);

      if (!this.subscription) {
        this.subscription = this.store.select('document').subscribe((state) => {
          if (state.updateDocumentBatch) {
            this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(false));
            this.documents = [];
            if (this.queueItemId) {
              this.getQueueItemObservable()
              .pipe(takeUntil(this.destroyed$))
              .subscribe(
                (response) => this.postLoadItem(response.value),
                this.errorHandler.bind(this)
              );
            } else {
              this.documentService
                .getQueue(this.queueID)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                  (response) => this.postLoadItem(response), 
                  this.errorHandler.bind(this)
                );
            }
          }
        });
      }
    });
  }

  updateDocumentThumbnail(result: string | ArrayBuffer, id: number, type: string): void {
    const newDocuments = this.documents.map((document) => {
      const obj = {
        ...document,
      };
      if (document.id === id) {
        obj[type] = result;
      }
      return obj;
    });
    this.documents = newDocuments;
    if (type === 'raw') {
      this.store.dispatch(new DocumentAction.SetDocumentsList(this.documents));
    }
  }

  getImages(image): void {
    this.imageService.getImageByS3Key(image.thumbnailPNGKey).subscribe(
      (thumbnail) => {
        this.imageService.createImageFromBlob(thumbnail, ev =>
          this.updateDocumentThumbnail(ev.target.result, image.id, 'thumbnail'));
        this.imageService.getImageByS3Key(image.rawPNGKey).subscribe(
          (raw) => {
            this.imageService.createImageFromBlob(raw, ev => this.updateDocumentThumbnail(ev.target.result, image.id, 'raw'));
          },
          (error) => throwError(error)
        );
      },
      (error) => throwError(error)
    );
  }

  getQueueItemObservable(): Observable<IResponse> | null {
    if (window.location.pathname === '/document-identify') {
      return this.documentService.getIdentificationQueueItem(this.queueItemId);
    }

    if (window.location.pathname === '/document-data-entry') {
      return this.documentService.getDataEntryQueueItem(this.queueItemId);
    }

    if (window.location.pathname === '/document-supervisor-identify') {
      this.isSupervisorView = true;

      return this.supervisorDocumentService.getQueueItem(this.queueItemId);
    }

    return null;
  }

  postLoadItem(batch): void {
    if (!batch || !batch.documentImages.length) {
      this.queueItemId
        ? this.router.navigate(['/'])
        : this.dialog.open(EmptyDocumentModalComponent, {disableClose: true});
    } else {
      this.caseService.getQueueCount(this.queueID).subscribe(
        (response) => {
          this.store.dispatch(new DocumentAction.SetDocumentsCount(response));
        },
        (error) => throwError(error)
      );

      this.store.dispatch(new DocumentAction.SetDocumentsBatchData(batch));
      this.store.dispatch(new DocumentAction.SetSupervisorState({
        queueItemId: this.queueItemId,
        isSupervisorView: this.isSupervisorView,
        reason: batch.reason
      }));

      batch.documentImages.forEach((image) => {
        const imageIsDeleted = !!image.deleteReasons?.length;
        const imageIsUncertain = !!image.uncertainReasons?.length;

        if (!this.isSupervisorView && (imageIsDeleted || imageIsUncertain)) {
          // Hide undefined or deleted images for non-supervisors
          return;
        }

        const document = new Document();

        document.deleteReasons = image.deleteReasons;
        document.uncertainReasons = image.uncertainReasons;
        document.otherDeleteReasonName = image.otherDeleteReasonName;
        document.otherUncertainReasonName = image.otherUncertainReasonName;

        document.id = image.id;
        document.documentBatchId = image.documentBatchId;
        document.isDeleted = imageIsDeleted;
        document.isUncertain = imageIsUncertain;
        document.newPatientName = image.newPatientName;
        document.patient = image.patient?.id 
          ? {...image.patient, name: NameResolver.getNameString(image.patient)} 
          : image.newPatientName ? { id: null, name: image.newPatientName } : null;
        document.documentType = image.documentType;
        document.otherText = image.otherDocumentTypeName;
        document.source = batch.source;
        document.createDate = batch.createDate;
        this.documents.push(document);
        this.getImages(image);
      });

      if (this.isSupervisorView) {
        const index = this.documents.findIndex(e => e.isDeleted || e.isUncertain);

        this.store.dispatch(new DocumentAction.SetSelectedDocument(index >= 0 ? index : 0));
      }
    }
    this.queueItemId = null;
  }

  errorHandler(error): Promise<any> {
    throwError(error);
    return this.router.navigate(['/']);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.subscription.unsubscribe();
    this.store.dispatch(new DocumentAction.SetDocumentsCount(null));
  }
}
