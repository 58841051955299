import { Component, Input, Output, EventEmitter } from '@angular/core';
import { throwError } from 'rxjs';
import { downloadFile } from 'src/app/shared/helpers/utils';
import { ImageService } from 'src/app/shared/services/image.service';
import { DocumentTypeNames } from '../../../../../../../../shared/enums/document-type-names.enum';
import { DocumentTypes } from '../../../../../../../../shared/enums/document-types.enum';
import { CaseService } from '../../../../services/case.service';

@Component({
  selector: 'app-section-attachments',
  templateUrl: './section-attachments.component.html',
  styleUrls: ['./section-attachments.component.scss', '../../aside-sections.scss']
})
export class SectionAttachmentsComponent {
  @Input() patient;

  @Input()
  get attachments(): any[] {
    return this._attachments;
  }

  set attachments(items: any[]) {
    if (this._attachments.length !== items?.length || items?.find(x => !this._attachments.some(y => y.id === x.id))) {
      this._attachments = items;

      this._attachments.forEach((key) => {
        const documentName = key.s3Key.split('/');
        key.s3Key = documentName[documentName.length - 1].replace(/\.[^/.]+$/, "");
      });

      this._attachments.forEach(item => {
        if (!item.thumbnail) {
          this.getThumbnail(item);
        }
      });
    }
  }

  @Output() thumbnailUpdated = new EventEmitter();

  _attachments = [];
  documentTypes = DocumentTypes;
  documentTypeNames = DocumentTypeNames;

  constructor(
    private caseService: CaseService,
    private imageService: ImageService,
  ) {
  }

  downloadAttachment(attachment): void {
    this.caseService.downloadAttachment(attachment.id, 'response')
      .subscribe((result: any) => {
        downloadFile(result.body, attachment.s3Key);
      }, err => throwError(err));
  }

  updateAttachmentThumbnail(result: string | ArrayBuffer, image: Blob, id: number, type: string): void {
    const newAttachments = this.attachments.map((document) => {
      const obj = {
        ...document,
      };
      if (document.id === id) {
        obj[type] = result;
      }
      return obj;
    });
    const data = { id, thumbnail: result };
    this.thumbnailUpdated.emit(data);
    this._attachments = newAttachments;
  }

  getThumbnail(attachment): void {
    this.imageService.getImageByS3Key(attachment.s3ThumbnailKey).subscribe(
      (thumbnail) => {
        this.imageService.createImageFromBlob(thumbnail, ev => this.updateAttachmentThumbnail(ev.target.result, thumbnail, attachment.id, 'thumbnail'));
      },
      (error) => throwError(error)
    );
  }
}
