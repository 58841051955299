import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()
export abstract class EncapsulatedRestService {
  private api: string = environment.resourceServer.apiUrl;

  protected constructor(protected http: HttpClient) { }

  protected get<T>(path: string, params?: any): Observable<T> {
    return this.http.get<T>(`${this.api}/${path}`, { params })
    .pipe(
      catchError((err) => { throw this.handleError(err); })
    );
  }

  protected delete<T>(path: string, id): Observable<T> {
    return this.http.delete<T>(`${this.api}/${path}/${id}`)
    .pipe(
      catchError((err) => { throw this.handleError(err); })
    );
  }

  protected postItem<T, TK>(path: string, payload?: T): Observable<TK> {
    return this.http.post<TK>(`${this.api}/${path}`,  payload)
    .pipe(
      catchError((err) => { throw this.handleError(err); })
    );
  }

  protected putItem<T, TK>(path: string, payload: T): Observable<TK> {
    return this.http.put<TK>(`${this.api}/${path}`,  payload)
    .pipe(
      catchError((err) => { throw this.handleError(err); })
    );
  }

  private handleError(error: any) : any {
    // TODO: Add logging of network errors if need

    return error;
  }
}
