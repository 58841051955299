<div class="task-wrapper">
  <div class="top-block" *ngIf="selectedTask">
    <b>DUE {{ selectedTask.due | date: "EEE, M/d" }}</b>
    <h2>{{selectedTask.task}}</h2>
    <div class="description">
      <span class="attempt" *ngIf="selectedTask.attemptNumber">attempt {{ selectedTask.attemptNumber + 1 }}</span>
      <p [innerHtml]="interpolatedDescription">
      </p>
    </div>
    <div class="buttons-block">
      <button class="collapse" (click)="minimizeTask(true)">
        <mat-icon>remove</mat-icon>
      </button>
      <button class="close" (click)="setSelectedTask(null)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="benefit-task-wrapper">
    <div class="task-block benefit-task-block">
      <mat-accordion>
        <app-benefit-plan [selectedTask]="selectedTask" [isDisabled]="isDisabled" [expanded]="indexExpanded === 0"
                          (currentSectionHandler)="setCurrentAnchor($event, 0)"
                          [settings]="benefitTasks.planInformation"
                          (validateTaskHandler)="validateTask($event)" (openPanelHandler)="openPanel($event)"
                          (closePanelHandler)="closePanel($event)" #benefitPlan>
        </app-benefit-plan>
        <app-benefit-medical-bb [selectedTask]="selectedTask" [isDisabled]="isDisabled" [expanded]="indexExpanded === 1"
                                (currentSectionHandler)="setCurrentAnchor($event, 1)"
                                [settings]="benefitTasks.medicalBB"
                                (validateTaskHandler)="validateTask($event)" (openPanelHandler)="openPanel($event)"
                                (closePanelHandler)="closePanel($event)" #benefitMedicalBB>
        </app-benefit-medical-bb>
        <app-benefit-medical-aob [selectedTask]="selectedTask" [isDisabled]="isDisabled"
                                 [expanded]="indexExpanded === 2" (currentSectionHandler)="setCurrentAnchor($event, 2)"
                                 [settings]="benefitTasks.medicalAOB" (validateTaskHandler)="validateTask($event)"
                                 (openPanelHandler)="openPanel($event)" (closePanelHandler)="closePanel($event)"
                                 #benefitMedicalAOB>
        </app-benefit-medical-aob>
        <app-benefit-pharmacy *ngIf="isPharmacyShown" [selectedTask]="selectedTask" [expanded]="indexExpanded === 3"
                              [isDisabled]="isDisabled" (currentSectionHandler)="setCurrentAnchor($event, 3)"
                              [settings]="benefitTasks.pharmacy" (validateTaskHandler)="validateTask($event)"
                              (openPanelHandler)="openPanel($event)" (closePanelHandler)="closePanel($event)"
                              #benefitPharmacy>
        </app-benefit-pharmacy>
        <app-benefit-completion *ngIf="isCompletionShown" [selectedTask]="selectedTask" [expanded]="indexExpanded === 4"
                                (currentSectionHandler)="setCurrentAnchor($event, 4)" [isDisabled]="isDisabled"
                                [settings]="benefitTasks.completion" [case]="case"
                                (validateTaskHandler)="validateTask($event)"
                                (openPanelHandler)="openPanel($event)" (closePanelHandler)="closePanel($event)"
                                #benefitCompletion>
        </app-benefit-completion>
      </mat-accordion>
    </div>
    <div class="benefit-indicators-wrapper">
      <div class="benefit-indicators" [class.phase]="!selectedTask">
        <mat-accordion>
          <h5>CALL PAYER</h5>
          <mat-expansion-panel [expanded]="indexExpanded === 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="task-indicator" *ngIf="selectedTask">
                  <mat-progress-spinner [diameter]="26" [class]="setIndicatorColor(benefitTasks.planInformation.value)"
                                        [value]="benefitTasks.planInformation.value">
                  </mat-progress-spinner>
                  <mat-icon *ngIf="benefitTasks.planInformation.value === 100">check</mat-icon>
                </div>
                Plan Information
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
              <li (click)="scrollToAnchor('PayerInfo', 0)"
                  [ngClass]="{'done': benefitTasks.planInformation.payerInfo, 'active': currentSection === 'PayerInfo'}">
                Payer Info
                <ng-container *ngIf="currentSection === 'PayerInfo'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('PlanInfo', 0)"
                  [ngClass]="{'done': benefitTasks.planInformation.planInfo, 'active': currentSection === 'PlanInfo'}">
                Plan Info
                <ng-container *ngIf="currentSection === 'PlanInfo'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('Coverage', 0)"
                  [ngClass]="{'done': benefitTasks.planInformation.coverage, 'active': currentSection === 'Coverage'}">
                Coverage
                <ng-container *ngIf="currentSection === 'Coverage'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('MedicareAndMedicaid', 0)"
                  [ngClass]="{'done': benefitTasks.planInformation.medicare, 'active': currentSection === 'MedicareAndMedicaid'}">
                Medicare and Medicaid
                <ng-container *ngIf="currentSection === 'MedicareAndMedicaid'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('PlanPolicyLimits', 0)"
                  [ngClass]="{'done': benefitTasks.planInformation.policyLimits, 'active': currentSection === 'PlanPolicyLimits'}">
                Policy Limits
                <ng-container *ngIf="currentSection === 'PlanPolicyLimits'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('PlanExclusions', 0)"
                  [ngClass]="{'done': benefitTasks.planInformation.exclusions, 'active': currentSection === 'PlanExclusions'}">
                Exclusions
                <ng-container *ngIf="currentSection === 'PlanExclusions'"> (Current)</ng-container>
              </li>
            </ul>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="indexExpanded === 1">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="task-indicator" *ngIf="selectedTask">
                  <mat-progress-spinner [diameter]="26" [class]="setIndicatorColor(benefitTasks.medicalBB.value)"
                                        [value]="benefitTasks.medicalBB.value">
                  </mat-progress-spinner>
                  <mat-icon *ngIf="benefitTasks.medicalBB.value === 100">check</mat-icon>
                </div>
                Medical (Buy & Bill)
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
              <li (click)="scrollToAnchor('MedicalPolicyLimits', 1)"
                  [ngClass]="{'done': benefitTasks.medicalBB.policyLimits, 'active': currentSection === 'MedicalPolicyLimits'}">
                Policy Limits
                <ng-container *ngIf="currentSection === 'MedicalPolicyLimits'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('SpecialtyPharmaciesBB', 1)"
                  [ngClass]="{'done': benefitTasks.medicalBB.networkSpecialtyPharmacies, 'active': currentSection === 'SpecialtyPharmaciesBB'}">
                In-Network Specialty Pharmacies
                <ng-container *ngIf="currentSection === 'SpecialtyPharmaciesBB'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('PayerLimits', 1)"
                  [ngClass]="{'done': benefitTasks.medicalBB.payerLimits, 'active': currentSection === 'PayerLimits'}">
                Payer Limits
                <ng-container *ngIf="currentSection === 'PayerLimits'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('OfficeVisit', 1)"
                  [ngClass]="{'done': benefitTasks.medicalBB.officeVisit, 'active': currentSection === 'OfficeVisit'}">
                Office Visit
                <ng-container *ngIf="currentSection === 'OfficeVisit'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('MedicalAuthorizationDeterminations', 1)"
                  [ngClass]="{'done': benefitTasks.medicalBB.authorization, 'active': currentSection === 'MedicalAuthorizationDeterminations'}">
                Authorization & Determinations
                <ng-container *ngIf="currentSection === 'MedicalAuthorizationDeterminations'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('MedicalExclusions', 1)"
                  [ngClass]="{'done': benefitTasks.medicalBB.exclusions, 'active': currentSection === 'MedicalExclusions'}">
                Exclusions
                <ng-container *ngIf="currentSection === 'MedicalExclusions'"> (Current)</ng-container>
              </li>
            </ul>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="indexExpanded === 2">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="task-indicator" *ngIf="selectedTask">
                  <mat-progress-spinner [diameter]="26" [class]="setIndicatorColor(benefitTasks.medicalAOB.value)"
                                        [value]="benefitTasks.medicalAOB.value">
                  </mat-progress-spinner>
                  <mat-icon *ngIf="benefitTasks.medicalAOB.value === 100">check</mat-icon>
                </div>
                Medical (AOB)
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
              <li (click)="scrollToAnchor('MedicalAOBInNetworkSPs', 2)"
                  [ngClass]="{'done': benefitTasks.medicalBB.networkSpecialtyPharmacies, 'active': currentSection === 'MedicalAOBInNetworkSPs'}">
                In-Network SPs
                <ng-container *ngIf="currentSection === 'MedicalAOBInNetworkSPs'"> (Current)</ng-container>
              </li>
              <li (click)="scrollToAnchor('MedicalAOBAuthorizationDeterminations', 2)"
                  [ngClass]="{'done': benefitTasks.medicalBB.authorization, 'active': currentSection === 'MedicalAOBAuthorizationDeterminations'}">
                Authorization & Determinations
                <ng-container *ngIf="currentSection === 'MedicalAOBAuthorizationDeterminations'"> (Current)
                </ng-container>
              </li>
            </ul>
          </mat-expansion-panel>
          <ng-container *ngIf="!selectedTask || isPharmacyShown">
            <h5>CALL PBM</h5>
            <mat-expansion-panel [expanded]="indexExpanded === 3">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="task-indicator" *ngIf="selectedTask">
                    <mat-progress-spinner [diameter]="26" [class]="setIndicatorColor(benefitTasks.pharmacy.value)"
                                          [value]="benefitTasks.pharmacy.value">
                    </mat-progress-spinner>
                    <mat-icon *ngIf="benefitTasks.pharmacy.value === 100">check</mat-icon>
                  </div>
                  Pharmacy
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul>
                <li (click)="scrollToAnchor('PBMPlanInfo', 3)"
                    [ngClass]="{'done': benefitTasks.pharmacy.planInfo, 'active': currentSection === 'PBMPlanInfo'}">
                  PBM Plan Info
                  <ng-container *ngIf="currentSection === 'PBMPlanInfo'"> (Current)</ng-container>
                </li>
                <li (click)="scrollToAnchor('PharmacyPolicyLimits', 3)"
                    [ngClass]="{'done': benefitTasks.pharmacy.policyLimits, 'active': currentSection === 'PharmacyPolicyLimits'}">
                  Policy Limits
                  <ng-container *ngIf="currentSection === 'PharmacyPolicyLimits'"> (Current)</ng-container>
                </li>
                <li (click)="scrollToAnchor('PharmacyInNetworkSPs', 3)"
                    [ngClass]="{'done': benefitTasks.pharmacy.networkSpecialtyPharmacies, 'active': currentSection === 'PharmacyInNetworkSPs'}">
                  In-Network SPs
                  <ng-container *ngIf="currentSection === 'PharmacyInNetworkSPs'"> (Current)</ng-container>
                </li>
                <li (click)="scrollToAnchor('DrugStatusPharmacy', 3)"
                    [ngClass]="{'done': benefitTasks.pharmacy.drugStatus, 'active': currentSection === 'DrugStatusPharmacy'}">
                  Drug Status
                  <ng-container *ngIf="currentSection === 'DrugStatusPharmacy'"> (Current)</ng-container>
                </li>
                <li (click)="scrollToAnchor('PharmacyAuthorizationDetermination', 3)"
                    [ngClass]="{ 'done': benefitTasks.pharmacy.authorization, 'active': currentSection === 'PharmacyAuthorizationDetermination' }">
                  Authorization & Determination
                  <ng-container *ngIf="currentSection === 'PharmacyAuthorizationDetermination'"> (Current)
                  </ng-container>
                </li>
                <li (click)="scrollToAnchor('PharmacyExclusions', 3)"
                    [ngClass]="{'done': benefitTasks.pharmacy.exclusions, 'active': currentSection === 'PharmacyExclusions'}">
                  Exclusions
                  <ng-container *ngIf="currentSection === 'PharmacyExclusions'"> (Current)</ng-container>
                </li>
              </ul>
            </mat-expansion-panel>
          </ng-container>
          <ng-container *ngIf="!selectedTask || isCompletionShown">
            <h5>Contact phys.</h5>
            <mat-expansion-panel [expanded]="indexExpanded === 4">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="task-indicator" *ngIf="selectedTask">
                    <mat-progress-spinner [diameter]="26" [class]="setIndicatorColor(benefitTasks.completion.value)"
                                          [value]="benefitTasks.completion.value">
                    </mat-progress-spinner>
                    <mat-icon *ngIf="benefitTasks.completion.value === 100">check</mat-icon>
                  </div>
                  Completion of Benefits
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul>
                <li (click)="scrollToAnchor('ReviewSOBPacket', 4)"
                    [ngClass]="{'active': currentSection === 'ReviewSOBPacket'}">
                  Review SOB Packet
                  <ng-container *ngIf="currentSection === 'ReviewSOBPacket'"> (Current)</ng-container>
                </li>
                <li (click)="scrollToAnchor('CommunicateSOB', 4)"
                    [ngClass]="{'active': currentSection === 'CommunicateSOB'}">
                  Communicate SOB
                  <ng-container *ngIf="currentSection === 'CommunicateSOB'"> (Current)</ng-container>
                </li>
                <li (click)="scrollToAnchor('CoversheetNote', 4)" [class.done]="benefitTasks.completion.coversheet"
                    [ngClass]="{'active': currentSection === 'CoversheetNote'}">
                  Coversheet Note(s)
                  <ng-container *ngIf="currentSection === 'CoversheetNote'"> (Current)</ng-container>
                </li>
              </ul>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
      <div *ngIf="selectedTask" class="outcome-note-wrapper" [formGroup]="bvTaskForm">
        <h3>
          <mat-icon>assignment</mat-icon>
          Outcome Notes
        </h3>
        <div class="form-row">
          <div class="input-item">
            <textarea matInput formControlName="note"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="task-controls" *ngIf="selectedTask">
    <button mat-button class="primary-btn invert reschedule" (click)="rescheduleTask()">
      <mat-icon>schedule</mat-icon>
      Reschedule
    </button>
    <button mat-button class="primary-btn invert done" (click)="saveBenefitTask()" [disabled]="
                benefitTasks.planInformation.value !== 100 ||
                benefitTasks.medicalBB.value !== 100 ||
                benefitTasks.medicalAOB.value !== 100 ||
                (this.isPharmacyShown && benefitTasks.pharmacy.value !== 100) ||
                (this.isCompletionShown && benefitTasks.completion.value !== 100)
            ">
      <mat-icon>done</mat-icon>
      Submit
    </button>
  </div>
</div>
