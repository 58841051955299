import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { CaseService } from '../../../../../services/case.service';
import { PayerManagementService } from '../../../../../../../../management/payer-management/services/payer-management.service';
import { IPSP } from '../../../../../../../../management/pharmacy-plan-management/interfaces/psp.interface';
import { PharmacyPlanManagementService } from '../../../../../../../../management/pharmacy-plan-management/services/pharmacy-plan-management.service';
import { IPlanObject } from '@shared/interfaces/plan-object.interface';
import { IPlan } from '@shared/interfaces/plan.interface';

@Component({
  selector: 'app-edit-medical-plan-dialog',
  templateUrl: './edit-medical-plan-dialog.component.html',
  styleUrls: ['./edit-medical-plan-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMedicalPlanDialogComponent implements OnInit, OnDestroy {
  planSearchControl = new FormControl(``);
  plans$: BehaviorSubject<IPlan[]> = new BehaviorSubject<IPlan[]>([]);
  payers$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  planOptions = {
    selectedPlan: null as IPlan,
    newPlan: false,
    selectedPayer: null
  };

  isPlanSearchActive: boolean;
  focusedSearch: number;

  newPlanForm: FormGroup;

  planTypes = [];
  planSubTypes = [];

  states = [];

  canAddNewMedicalPlansOnCaseSideTab = false;
  canAddNewPharmacyPlansOnCaseSideTab = false;

  states$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  types$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  subTypes$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  preferredSpecialtyPharmacyList$: BehaviorSubject<IPSP[]> = new BehaviorSubject<IPSP[]>([]);

  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('planSearchList') planSearchList: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<EditMedicalPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private caseService: CaseService,
    private fb: FormBuilder,
    private payerManagementService: PayerManagementService,
    private pharmacyPlanManagementService: PharmacyPlanManagementService,
  ) {
  }

  ngOnInit(): void {
    this.initPlanForm();

    this.planSearchControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        takeUntil(this.onDestroy$),
        filter(v => v.length >= 3),
      )
      .subscribe(v => {
        this.searchPlans(v);
        if (!this.data?.isPharmacy && this.canAddNewMedicalPlansOnCaseSideTab) {
          this.searchPayers(v);
        }
      });
        
    this.canAddNewMedicalPlansOnCaseSideTab = this.data.case.status.program.canAddNewMedicalPlansOnCaseSideTab;
    this.canAddNewPharmacyPlansOnCaseSideTab = this.data.case.status.program.canAddNewPharmacyPlansOnCaseSideTab;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  searchNavigate(event: any): void {
    if (this.planSearchControl.value.length) {
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        event.preventDefault();
        this.focusedSearch === this.planSearchList.nativeElement.children.length - 1
          ? (this.focusedSearch = 0)
          : (this.focusedSearch = this.focusedSearch + 1);
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.focusedSearch === 0
          ? (this.focusedSearch = this.planSearchList.nativeElement.children.length - 1)
          : (this.focusedSearch = this.focusedSearch - 1);
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        this.selectPlan(this.plans$.getValue()[this.focusedSearch]);
      }
    }
  }

  addNewPlan(payer: any = undefined): void {
    this.planOptions.newPlan = true;
    this.planOptions.selectedPayer = payer;
    this.newPlanForm.get('name').setValue(this.planSearchControl.value);

    if (this.data?.isPharmacy) {
      this.getPreferredSpecialtyPharmacyList();
    } else {
      this.getStatesList();
      this.getTypesList();
      this.getSubTypesList();
    }
  }

  selectPlan(plan: IPlan): void {
    this.planOptions.selectedPlan = plan;
    this.planSearchControl.setValue('');
  }

  unselectNewPlan(): void {
    this.planOptions.newPlan = false;
    this.planOptions.selectedPayer = null;
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  submit(): void {
    const planObject = this.getPlanObject(this.planOptions.newPlan, this.data?.isPharmacy, this.newPlanForm.getRawValue());

    if (this.data?.isPharmacy) {
      this.caseService.savePharmacyPlan(this.planOptions.newPlan, planObject)
        .pipe(take(1))
        .subscribe(() => {
          this.planOptions.selectedPlan = planObject.plan;
          this.updateCurrentInsurance();
        });
    } else {
      if (!planObject.plan.payerId) {
        planObject.plan.payerId = this.planOptions.selectedPayer.id;
      }
      this.caseService.saveMedicalPlan(this.planOptions.newPlan, planObject)
        .pipe(take(1))
        .subscribe(() => {
          planObject.plan.payerName = this.planOptions.selectedPayer.name;
          planObject.plan.typeName = this.types$.getValue().find((el) => el.id === planObject.plan.typeId)?.name;
          planObject.plan.subTypeName =  this.subTypes$.getValue().find((el) => el.id === planObject.plan.subTypeId)?.name;
          this.planOptions.selectedPlan = planObject.plan;
          this.updateCurrentInsurance();
        });
    }
  }

  updateCurrentInsurance(): void {
    this.dialogRef.close(this.planOptions.selectedPlan);
  }

  canSave(): boolean {
    return (this.planOptions.newPlan && this.newPlanForm.valid) || !!this.planOptions.selectedPlan;
  }

  private initPlanForm(): void {
    let currentControls = {
      name: ['', [Validators.required]],
      street: ['', []],
      addressExtension: ['', []],
      city: ['', []],
      state: ['', []],
      zip: ['', [Validators.minLength(5)]],
      payerPhone: ['', [Validators.minLength(10)]],
      payerFax: ['', [Validators.minLength(10)]],
    };

    if (this.data?.isPharmacy) {
      currentControls = {
        ...currentControls,
        ...{
          preferredSpecialtyPharmacy: ['', [Validators.required]],
          bin: ['', [Validators.required]],
          pcn: ['', [Validators.required]],
          isPBM: ['', [Validators.required]],
        },
      };
    } else {
      currentControls = {
        ...currentControls,
        ...{
          typeId: ['', [Validators.required]],
          subTypeId: ['', [Validators.required]],
        },
      };
    }

    this.newPlanForm = this.fb.group(currentControls);
  }

  private searchPlans(search: string): void {
    this.planOptions.selectedPlan = null;

    if (this.data?.isPharmacy) {
      this.caseService.searchPharmacyPlans({searchNamePhoneBinPcn: search, take: 1000})
        .pipe(take(1))
        .subscribe(
          (response) => this.plans$.next(response),
          (error) => throwError(error),
        );
    } else {
      this.caseService.searchMedicalPlans({searchNamePhone: search, take: 1000})
        .pipe(take(1))
        .subscribe(
          (response: IPlan[]) => this.plans$.next(response),
          (error) => throwError(error),
        );
    }
  }

  private searchPayers(search: string): void {
    this.planOptions.selectedPayer = null;
    
    this.caseService.searchPayers({name: search, take: 1000})
    .pipe(take(1))
    .subscribe(
      (response: any) => this.payers$.next(response),
      (error) => throwError(error),
    );
  }

  private getStatesList(): void {
    // TODO: get states
    this.states$.next([
      'KY',
      'State 1',
      'State 2',
    ]);
  }

  private getTypesList(): void {
    this.payerManagementService.getPlanTypes()
      .pipe(take(1))
      .subscribe(
        (types: any) => {
          this.types$.next(types);
        },
        (error) => {
          throw error;
        }
      );
  }

  private getSubTypesList(): void {
    this.payerManagementService.getPlanSubTypes()
      .pipe(take(1))
      .subscribe(
        (types: any) => {
          this.subTypes$.next(types);
        },
        (error) => {
          throw error;
        }
      );
  }

  private getPreferredSpecialtyPharmacyList(): void {
    this.pharmacyPlanManagementService.getPSPList()
      .pipe(take(1))
      .subscribe((pspList: IPSP[]) => {
        this.preferredSpecialtyPharmacyList$.next(pspList);
      });
  }

  private getPlanObject(isNew: boolean, isPharmacy: boolean, formValue: any): IPlanObject {
    const planObject: any = {
      id: this.data?.patientInsuranceId,
      planId: this.planOptions.newPlan ? null : this.planOptions.selectedPlan.id,
      plan: isNew ? formValue : this.planOptions.selectedPlan,
    };

    if (!isNew) {
      return planObject;
    }

    planObject.plan.address = {
      streetAddress: formValue.streetAddress,
      addressExtension: formValue.addressExtension,
      zipCode: formValue.zip,
      city: formValue.city,
      state: formValue.state,
    };

    if (isPharmacy) {
      planObject.plan.isPBM = formValue.isPBM;
      planObject.plan.bin = formValue.bin;
      planObject.plan.pcn = formValue.pcn;
      planObject.plan.specialtyPharmacyId = formValue.preferredSpecialtyPharmacy;
    } else {
      planObject.plan.typeId = formValue.typeId;
      planObject.plan.subTypeId = formValue.subTypeId;
      planObject.payerId = this.payerManagementService.currentPayer$.getValue()?.id;
    }

    return planObject;
  }
}
