import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../core/services/abstract-rest.service';
import { Observable, } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getImageByS3Key(s3Key: string): Observable<Blob> {
    this.url = this.apiUrl;
    return this.getImage(`Document/${s3Key.replace(/\//g, '%2F')}`);
  }

  public createImageFromBlob(image: Blob, callback: (ev: ProgressEvent<FileReader>) => any): void {
    const reader = new FileReader();
    reader.addEventListener('load', callback, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
