import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICase } from '@shared/interfaces/case.interface';
import { isNotBlank } from '@shared/utilities/empty-checks';
import { ITaskBody } from '../../../../interfaces/task-body.interface';
import { ITaskField } from '../../../../interfaces/task-field.interface';
import { ConfigsContract } from '../../contract/configs.contract';
import { TasksFormService } from '../../services/task-forms.service';

@Component({
  selector: 'app-task-body',
  templateUrl: './task-body.component.html',
  styleUrls: ['./task-body.component.scss'],
})
export class TaskBodyComponent implements OnInit {
  @Input() case: ICase;
  @Input() taskForm: FormGroup;
  @Input() taskConfig: ITaskBody;
  @Input() selectedTask: any;
  @Input() isHistory: boolean;

  @Output() previewDocument = new EventEmitter<ITaskField>();
  @Output() downloadDocument = new EventEmitter<ITaskField>();

  currentGroupIndex = -1;
  currentSectionIndex = -1;
  areNamedGroupsIncluded: boolean;

  allowAssignSection = true;

  flattenedControls: any;

  constructor(private readonly tasksFormService: TasksFormService) {
  }

  ngOnInit(): void {
    this.areNamedGroupsIncluded = this.taskConfig.groups?.length > 1 && this.taskConfig.groups.some(el => !!el.groupId);

    if (this.areNamedGroupsIncluded) {
      this.flattenedControls = this.getFlattenedControls();
    }
  }

  getFlattenedControls(): any {
    const controls = this.taskForm.controls;

    const flattenedControls = Object.keys(controls)
      .filter(key => controls[key] instanceof FormGroup)
      .reduce((acc, key, index) => {
        acc[index] = this.tasksFormService.getFlatControls(controls[key] as FormGroup);

        return acc;
      }, {} as any);

    return flattenedControls;
  }

  openPanel(groupIndex: number): void {
    this.currentGroupIndex = groupIndex;
  }

  closePanel(groupIndex: number): void {
    if (groupIndex === this.currentGroupIndex) {
      this.currentGroupIndex = -1;
    }
  }

  getProgress(index: number): number {
    const allFields = this.flattenedControls[index].length;

    const fieldsWithValue = this.flattenedControls[index].filter(e => isNotBlank(e.value)).length;

    if (!allFields) {
      return 0;
    } // it avoids divide by zero

    return fieldsWithValue / allFields;
  }

  isDone(groupIndex: number, controlIndex: number): boolean {
    return isNotBlank(this.flattenedControls[groupIndex][controlIndex].value);
  }

  scrollToAnchor(groupIndex: number, sectionIndex: number): void {
    if (!this.allowAssignSection) {
      return;
    }

    this.allowAssignSection = false;

    const timeout = this.currentGroupIndex === groupIndex ? 0 : 2500;

    this.currentGroupIndex = groupIndex;
    this.currentSectionIndex = sectionIndex;

    document.getElementById(`${ConfigsContract.GroupPrefix}${groupIndex}`)
      .scrollIntoView({behavior: 'smooth'});

    setTimeout(() => {
      this.allowAssignSection = true;
    }, timeout);
  }
}
