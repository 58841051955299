import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { setIndicatorColor } from '@shared/helpers/utils';

@Component({
  selector: 'app-benefit-plan',
  templateUrl: './plan.component.html',
  styleUrls: [
    '../../../../task.component.scss',
    '../../benefit.component.scss',
    './plan.component.scss',
  ],
})
export class BenefitPlanComponent implements OnInit, OnChanges {
  @Input() settings;
  @Input() selectedTask;
  @Input() isDisabled;
  @Input() expanded;

  @Output() validateTaskHandler = new EventEmitter();
  @Output() openPanelHandler = new EventEmitter();
  @Output() closePanelHandler = new EventEmitter();
  @Output() currentSectionHandler = new EventEmitter();

  planInformationForm: FormGroup;
  setIndicatorColor = setIndicatorColor;

  constructor() {
    this.planInformationForm = new FormGroup({
      payerInfo: new FormGroup({
        insuranceStatus: new FormControl(null, [Validators.required]),
        payerType: new FormControl(null, [Validators.required]),
      }),
      planInfo: new FormGroup({
        planType: new FormControl(null, [Validators.required]),
        policyId: new FormControl(null, [Validators.required]),
        groupId: new FormControl(null, [Validators.required]),
        isPolicyHolderSame: new FormControl(null, [Validators.required]),
      }),
      coverage: new FormGroup({
        priority: new FormControl(null, [Validators.required]),
        isInNetwork: new FormControl(null, [Validators.required]),
        isRequired: new FormControl(null, [Validators.required]),
        originalEffectiveDate: new FormControl(null, [Validators.required]),
        planEffectiveDateStart: new FormControl(null, [Validators.required]),
        planEffectiveDateEnd: new FormControl(null, [Validators.required]),
        isPreExistingConditionRequired: new FormControl(null, [Validators.required]),
        preExistingDateDateStart: new FormControl(null, [Validators.required]),
        preExistingDateDateEnd: new FormControl(null, [Validators.required]),
      }),
      medicare: new FormGroup({
        isMedicareReplacement: new FormControl(null, [Validators.required]),
        isMedicareSupplement: new FormControl(null, [Validators.required]),
        isMedicaId: new FormControl(null, [Validators.required]),
        state: new FormControl(null, [Validators.required]),
        isSpendDown: new FormControl(null, [Validators.required]),
        satisfyAmount: new FormControl(null, [Validators.required]),
        metAmount: new FormControl(null, [Validators.required]),
      }),
      policyLimits: new FormGroup({
        type: new FormControl(null, [Validators.required]),
        individualDeductibleAmount: new FormControl(null, [Validators.required]),
        isIndividualDeductibleMet: new FormControl(null, [Validators.required]),
        isIndividualDeductibleOOP: new FormControl(null, [Validators.required]),
        isCaryNextYear: new FormControl(null, [Validators.required]),
        individualOOP: new FormControl(null, [Validators.required]),
        isIndividualOOPMet: new FormControl(null, [Validators.required]),
        individualOOPAmount: new FormControl(null, [Validators.required]),
        isIndividualLifetimeUnlimited: new FormControl(null, [Validators.required]),
        individualLifetimeMaximum: new FormControl(null, [Validators.required]),
        isIndividualLifetimeMaximumMet: new FormControl(null, [Validators.required]),
        individualAnnualCap: new FormControl(null, [Validators.required]),
        isIndividualAnnualCapMet: new FormControl(null, [Validators.required]),
      }),
      exclusions: new FormGroup({
        isExclusions: new FormControl(null, [Validators.required]),
        exclusionsNote: new FormControl(null, [Validators.required]),
      }),
    });
  }

  ngOnInit(): void {
    this.planInformationForm.valueChanges.subscribe(() => {
      this.validateTask(this.planInformationForm);
    });
  }

  ngOnChanges(changes): void {
    if (changes.isDisabled && changes.isDisabled.currentValue) {
      this.planInformationForm.disable();
    } else if (changes.isDisabled && !changes.isDisabled.currentValue) {
      this.planInformationForm.enable();
    }
  }

  openPanel(index): void {
    if (!this.expanded) {
      this.openPanelHandler.emit({index, elementId: 'PlanInformationPanel', sectionIndex: 0});
    }
  }

  closePanel(index): void {
    this.closePanelHandler.emit(index);
  }

  currentSection(name: string): void {
    this.currentSectionHandler.emit(name);
  }

  validateTask(form): void {
    const data = {
      form,
      formName: 'planInformation',
    };
    this.validateTaskHandler.emit(data);
  }
}
