import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SupervisorAddTerritoryColumns } from '../../../../../../../../shared/enums/supervisor-add-territory-columns.enum';
import { enumToArray } from '../../../../../../../../shared/helpers/utils';
import { ITerritory } from '../../../../../../../../shared/interfaces/territory.interface';
import { ReportsService } from '../../../../../../../reports/services/reports.service';
import { QueueCreateService } from '../../../../../creation/services/creation.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-supervisor-task-add',
  templateUrl: './add.component.html',
  styleUrls: ['../../../users/users.component.scss', '../../../users/components/add/add.component.scss', './add.component.scss'],
})
export class SupervisorAddTerritoryComponent implements OnInit {
  @Output() territoriesSaved = new EventEmitter<ITerritory[]>();

  searchValue = '';
  columnsToDisplay = enumToArray(SupervisorAddTerritoryColumns);
  addedTerritories: ITerritory[] = [];
  data = [];
  dataSource;
  isLoading = false;
  isAllSelected = false;

  constructor(
    public queueCreateService: QueueCreateService,
    private reportsService: ReportsService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
  ) {
    this.addedTerritories = [...dialogData.addedTerritories];
  }

  ngOnInit(): void {
    this.getTerritories();
  }

  checkTerritory(task): void {
    if (!this.isSelected(task.id)) {
      this.addedTerritories.push(task);
    } else {
      this.addedTerritories = this.addedTerritories.filter(item => item.id !== task.id);
    }
  }

  isSelected(id): boolean {
    return this.addedTerritories.some(x => x.id === id);
  }

  selectAll(): void {
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      this.addedTerritories = this.data;
    } else {
      this.addedTerritories = [];
    }
  }

  saveTerritories(): void {
    this.territoriesSaved.emit(this.addedTerritories);
  }

  clearSearch(): void {
    this.searchValue = '';
    this.getTerritories();
  }

  getTerritories(): void {
    const data = {take: 0, search: this.searchValue};
    this.isLoading = true;
    this.reportsService.getTerritories(data).subscribe(
      (response) => {
        this.data = response.value;
        this.dataSource = new MatTableDataSource(this.data);
      },
      (error) => throwError(error),
      () => (this.isLoading = false)
    );
  }
}
