import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export abstract class AbstractRestService {
  protected url: string;

  protected constructor(protected http: HttpClient) {
  }

  protected get apiUrl(): string {
    return environment.resourceServer.apiUrl;
  }

  public getItem<T>(id?: number | string): Observable<T> {
    return this.http.get<T>(`${this.url}/${id}`);
  }

  public getItems<T>(id?: number | string): Observable<T> {
    if (this.url.startsWith(environment.resourceServer.apiUrl)) {
      return this.http.get<T>(`${this.url}/${id}`);
    }
    return EMPTY;
  }

  public get<T>(): Observable<T> {
    return this.http.get<T>(`${this.url}`);
  }

  public delete<T>(): Observable<T> {
    return this.http.delete<T>(`${this.url}`);
  }

  public getPicture(): Observable<Blob> {
    return this.http.get(`${this.url}`, {responseType: 'blob'});
  }

  public postItem<T, TK>(entity?: T): Observable<TK> {
    return this.http.post<TK>(`${this.url}`, entity);
  }

  public postDownloadFile<T>(entity?: T): Observable<Blob> {
    return this.http.post(`${this.url}`, entity, {responseType: 'blob'});
  }

  public updateItem<T, TK>(entity: T): Observable<TK> {
    return this.http.patch<TK>(`${this.url}`, entity);
  }

  public putItem<T, TK>(entity: T): Observable<TK> {
    return this.http.put<TK>(`${this.url}`, entity);
  }

  public put<TK>(): Observable<TK> {
    return this.http.put<TK>(`${this.url}`, undefined);
  }

  public patchItem<T, TK>(entity: T): Observable<TK> {
    return this.http.patch<TK>(`${this.url}`, entity);
  }

  public getImage(imgUrl: string, observe?): Observable<Blob> {
    if (this.url.startsWith(environment.resourceServer.apiUrl)) {
      return this.http.get(`${this.url}/${imgUrl}`, {
        observe,
        responseType: 'blob',
      });
    }
    return EMPTY;
  }

  public deleteItem<T, TK>(entity: T): Observable<TK> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {...entity}
    };
    return this.http.delete<TK>(`${this.url}`, options);
  }
}
