import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(input: any): any {
    return input.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase());
  }
}
