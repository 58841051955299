export enum UserInviteStatus {
  inactive,
  active,
  pendingInvite,
  deleted
}

export function StatusCaption(status: UserInviteStatus): string {
  if (status === UserInviteStatus.active) {
    return 'Active';
  }
  if (status === UserInviteStatus.inactive) {
    return 'Inactive';
  }
  if (status === UserInviteStatus.pendingInvite) {
    return 'Pending Invite';
  }
  if (status === UserInviteStatus.deleted) {
    return 'Deleted';
  }
  return 'Undefined';
}
