<div class="history-wrapper">
  <div class="timeline">
    <ul>
      <li *ngFor="let item of historyTimeline"
          [class.active]="item.status === HistoryTimelineStatus.Current"
          [class.complete]="item.status === HistoryTimelineStatus.Complete"
      >
        <h5>{{ item.phase }}</h5>
        <div [title]="'Spent: ' + item.spent + ', ET: ' + item.estimate">
          <img *ngIf="item.spentTime > item.estimateTime"
               src="assets/icons/icon-time-solid.svg"
               alt="clock-icon"
          />
          <b>{{ item.spent }}</b> SLA: {{ item.estimate }}
        </div>
      </li>
    </ul>
  </div>

  <h2 *ngIf="dataSource?.data?.length">History</h2>

  <div class="filters" *ngIf="dataSource?.data?.length">
    <mat-form-field appearance="fill">
      <mat-label>
        <mat-icon *ngIf="historyType.value && historyType.value.length">check_box</mat-icon>
        Type
        {{ historyType.value && historyType.value.length ? "(" + historyType.value.length + ")" : null }}
      </mat-label>
      <mat-select [formControl]="historyType"
                  multiple
                  disableOptionCentering
                  panelClass="dropdown-item filters"
                  (selectionChange)="applyFilter('type')"
      >
        <mat-select-trigger></mat-select-trigger>
        <mat-option *ngFor="let item of historyTypes" [value]="item.type">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="historyPhaseList?.length">
      <mat-label>
        <mat-icon *ngIf="historyPhase.value && historyPhase.value.length">check_box</mat-icon>
        Phases
        {{ historyPhase.value && historyPhase.value.length ? "(" + historyPhase.value.length + ")" : null }}
      </mat-label>
      <mat-select [formControl]="historyPhase"
                  multiple
                  disableOptionCentering
                  #phaseFilter
                  panelClass="dropdown-item phase-filters filters lg-select-panel"
                  (selectionChange)="applyFilter('phase')"
      >
        <mat-select-trigger></mat-select-trigger>
        <mat-option *ngFor="let phase of historyPhaseList" [value]="phase">{{ phase }}</mat-option>
        <div class="filter-controls">
          <button class="clear" (click)="clearFilter()" [disabled]="historyPhase.value?.length==0">Clear</button>
          <button class="primary-btn" (click)="applyAllFilter()"
                  [disabled]="historyPhase.value?.length === historyPhaseList.length"
          >
            Select All
          </button>
        </div>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="history-tasks">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           [@.disabled]="true"
           [hidden]="!dataSource?.filteredData?.length"
           matSortDirection="desc"
           *ngIf="dataSource?.filteredData?.length"
    >
      <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity</th>
        <td mat-cell *matCellDef="let element">
          <div class="activity-content" [ngClass]="{'pending-supervisor': element.outcome === TaskOutcome.escalated}">
            <ng-container [ngSwitch]="element.contactMethod">
              <mat-icon *ngSwitchCase="ContactMethod.phone">call</mat-icon>
              <mat-icon *ngSwitchCase="ContactMethod.fax">print</mat-icon>
              <mat-icon *ngSwitchCase="ContactMethod.email">email</mat-icon>
              <mat-icon *ngSwitchCase="ContactMethod.text">textsms</mat-icon>
              <img *ngSwitchCase="ContactMethod.mail" src="assets/icons/icon-snail-mail.svg" alt="mail"/>
              <img *ngSwitchCase="ContactMethod.supervisor" src="assets/icons/icon-escalade.svg" alt="escalade"/>
            </ng-container>

            <span *ngIf="element.contactMethod === ContactMethod.supervisor" style="margin-right: 5px;">Escalated:</span>

            {{ element.task }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="createDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.createdDate">
            {{ element.createdDate | date: "M/d/yy" }} at {{element.createdDate | date: "h:mma"}}
          </ng-container>
          <div class="empty" *ngIf="!element.createdDate">——</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="due">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Due</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.due">
            {{ element.due | date: "M/d/yy" }} at {{element.due | date: "h:mma"}}
          </ng-container>
          <div class="empty" *ngIf="!element.due">——</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="completed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.completed">
            {{ element.completed | date: "M/d/yy" }} at {{element.completed | date: "h:mma"}}
          </ng-container>
          <div class="empty" *ngIf="!element.completed">——</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="outcome">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Success</th>
        <td mat-cell *matCellDef="let element">
          <div [ngSwitch]="element.outcome" [ngClass]="{'pending-supervisor': element.outcome === TaskOutcome.escalated}">
            <mat-icon *ngSwitchCase="TaskOutcome.repeat">query_builder</mat-icon>
            <img *ngSwitchCase="TaskOutcome.success" src="assets/icons/icon-check.svg" alt="success"/>
            <img *ngSwitchCase="TaskOutcome.failure" src="assets/icons/icon-warning.svg" alt="failure"/>
            <img *ngSwitchCase="TaskOutcome.escalated" src="assets/icons/icon-escalade-red.svg" alt="escalade"/>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon class="icon-arrow-up" *ngIf="isExpanded(element.id)">keyboard_arrow_up</mat-icon>
          <mat-icon class="icon-arrow-down" *ngIf="!isExpanded(element.id)">keyboard_arrow_down</mat-icon>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail">
            <div class="example-element-body">

              <div class="notes-container">
                <div *ngIf="element.outcomeNote" class="notes">
                  <b>Notes</b>
                  <p>
                    {{ element.outcomeNote }}
                  </p>
                </div>

                <button [disabled]="element.outcome === TaskOutcome.inProgress"
                        class="primary-btn" (click)="openViewDetailsDialog(element)">
                  View Details <img src="assets/icons/icon-arrow-long-right-white.svg" alt="view"/>
                </button>
              </div>

              <div class="info-container">
                <div class="info-item">
                  <b>{{element.outcome === TaskOutcome.escalated ? 'Escalated by' : 'Completed by'}}</b>
                  <span *ngIf="(element.updatedBy && element.outcome !== TaskOutcome.inProgress); else notCompleted">{{ element.updatedBy | fullName }}</span>
                  <ng-template #notCompleted><span>--</span></ng-template>
                </div>
                <div class="info-item">
                  <b>Method</b>
                  <span *ngIf="contactMethodNames[element.contactMethod]">{{ contactMethodNames[element.contactMethod] }}</span>
                  <span *ngIf="!contactMethodNames[element.contactMethod]">--</span>
                </div>
                <div class="info-item">
                  <b>Direction</b>
                  <span *ngIf="directionNames[element.direction]">{{ directionNames[element.direction] }}</span>
                  <span *ngIf="!directionNames[element.direction]">--</span>
                </div>
                <div class="info-item">
                  <b>Entity</b>
                  <span *ngIf="targetNames[element.target]">{{ targetNames[element.target] }}</span>
                  <span *ngIf="!targetNames[element.target]">--</span>
                </div>
              </div>

            </div>

            <div *ngIf="element.selectedAttachments?.length" class="attachments-container">
              <div class="attachments-header">Attachments</div>
              <div class="attachment-buttons">
                <button *ngFor="let attachment of element.selectedAttachments" (click)="previewAttachment(attachment)">
                  {{ attachmentName(attachment) }}
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>
            </div>

          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="statusChange">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="change-status">
            <div class="time-item">
              <mat-icon class="image-item">error</mat-icon>

              <div>
                <h6>Status Change</h6>
                <p>{{ element.createdDate | date: "M/d/yyyy 'at' hh:mmaaaaa" | empty }}</p>
              </div>
            </div>

            <div class="phase-item">
              <h6>Phase</h6>
              <p>{{ element.phase }}</p>
            </div>

            <div class="status-item">
              <h6>Case Status</h6>
              <p>{{ element.status | empty }}</p>
            </div>

            <div class="substatus-item">
              <h6>SubStatus</h6>
              <p>{{ element.subStatus | empty }}</p>
            </div>

            <div class="reason-item">
              <h6>Reason</h6>
              <p>{{ element.subStatusReason | empty }}</p>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: ['statusChange']; when: isStatusChange" class="status-change-row"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay; when: isActivity" class="example-element-row"
          [class.example-expanded-row]="isExpanded(row.id)" (click)="toggleRow(row.id)"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isActivity"
          [class.hidden]="!isExpanded(row.id)" class="example-detail-row"></tr>
    </table>

    <div class="empty-history-row" *ngIf="!dataSource?.filteredData?.length">
      <div class="empty-history-container">
        <img src="assets/images/empty-history.svg"/>
        <div class="empty-history-header">No History</div>
        <div class="empty-history-description-container">
          <div class="empty-history-description">This case doesn't have any tasks yet.</div>
          <div class="empty-history-description">Tasks and status changes will show here once completed.</div>
        </div>
      </div>
    </div>
  </div>
</div>
