import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SupervisorAddTaskColumns } from '../../../../../../../../shared/enums/supervisor-add-task-columns.enum';
import { enumToArray } from '../../../../../../../../shared/helpers/utils';
import { ISupervisorTask } from '../../../../../../../../shared/interfaces/supervisor-task.interface';
import { QueueCreateService } from '../../../../../creation/services/creation.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { InterpolatedString } from '@shared/utilities/string-interpolation/interpolated-string';
import { MockSelector } from '@shared/utilities/string-interpolation/mock-selector';

@Component({
  selector: 'app-supervisor-task-add',
  templateUrl: './add.component.html',
  styleUrls: ['../../../users/users.component.scss', '../../../users/components/add/add.component.scss', './add.component.scss'],
})
export class SupervisorAddTaskComponent implements OnInit {
  @Output() tasksSaved = new EventEmitter<ISupervisorTask[]>();

  searchValue = '';
  columnsToDisplay = enumToArray(SupervisorAddTaskColumns);
  addedTasks: ISupervisorTask[] = [];
  data = [];
  dataSource;
  isLoading = false;
  isAllSelected = false;

  constructor(
    public queueCreateService: QueueCreateService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
  ) {
    this.addedTasks = [...dialogData.addedTasks];
  }

  ngOnInit(): void {
    this.getTasks();
  }

  checkTask(task): void {
    if (!this.isSelected(task.id)) {
      this.addedTasks.push(task);
    } else {
      this.addedTasks = this.addedTasks.filter(item => item.id !== task.id);
    }
  }

  isSelected(id): boolean {
    return this.addedTasks.some((x) => x.id === id);
  }

  selectAll(): void {
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      this.addedTasks = this.data;
    } else {
      this.addedTasks = [];
    }
  }

  saveTasks(): void {
    this.tasksSaved.emit(this.addedTasks);
  }

  clearSearch(): void {
    this.searchValue = '';
    this.getTasks();
  }

  getTasks(): void {
    const data = {take: 0, search: this.searchValue};
    this.isLoading = true;
    this.queueCreateService.getTasks(data).subscribe(
      (response) => {
        this.data = response.value;

        this.interpolateTasksText(response.value);

        this.dataSource = new MatTableDataSource(this.data);
        this.isAllSelected = this.data.length === this.addedTasks.length;
      },
      (error) => throwError(error),
      () => (this.isLoading = false)
    );
  }

  private interpolateTasksText(tasks: any[]) {
    const interpolatedString = new InterpolatedString();

    interpolatedString.setSelector(new MockSelector());

    tasks.forEach(task => {
      task.name = interpolatedString.setInitialString(task.name).toString();
      task.description = interpolatedString.setInitialString(task.description).toString();
    });
  }
}
