<div class="dialog">
  <div class="dialog-header">
    <span class="header-text">Task Needs Assigned</span>
    <mat-icon class="header-close-btn" mat-dialog-close>close</mat-icon>
  </div>

  <div class="dialog-content">
    <span class="description">
      You must adjust queue settings so that this task is assigned to a queue.
    </span>

    <div class="divider"></div>

    <div class="input-item select">
      <label>Select Queue to Modify</label>

      <mat-form-field appearance="fill">
        <mat-select placeholder="Select queue"
                    [formControl]="selectedQueue"
                    panelClass="dropdown-item">
          <mat-option *ngFor="let queue of queues"
                      [value]="queue.id">
            {{queue.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="dialog-footer stick-bottom">
    <button class="cancel-btn"
            mat-dialog-close>
            Cancel
    </button>
    <button [disabled]="selectedQueue.invalid"
            (click)="submit()"
            class="submit-btn">
      <span>Go to Queue</span>
      <mat-icon class="icon">arrow_forward</mat-icon>
    </button>
  </div>
</div>
