import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { Store } from '@ngrx/store';
import { LoginService } from '../../../features/login/services/login.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CaseService } from '../../../features/manager/components/case/services/case.service';
import { Roles } from '../../enums/roles.enum';
import { SearchModalComponent } from '../search/components/modal/modal.component';
import { skip, take, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '@core/classes/UnsubscribeOnDestroy';
import { IUser } from '../../interfaces/user.interface';
import { ICase } from '../../interfaces/case.interface';
import { GeneralInquiryDialogComponent } from '@shared/components/aside/modals/general-inquiry-dialog/general-inquiry-dialog.component';
import { IInquiryType } from '@shared/interfaces/inquiry-type.interface';
import { GeneralInquiryService } from '@shared/services/general-inquiry.service';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideComponent extends UnsubscribeOnDestroy implements OnInit {
  case$: BehaviorSubject<ICase> = new BehaviorSubject<ICase>({} as ICase);
  user$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>({});
  isSearchModalOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  reportHovered = false;
  usersHovered = false;
  Roles = Roles;
  caseDetails$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  showCaseDetailsTabs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  generalInquiryActive: boolean;
  isInquiryTypesModalOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  inquiryTypes$: BehaviorSubject<IInquiryType[]> = new BehaviorSubject([]);

  constructor(
    private router: Router,
    public oktaAuth: OktaAuthService,
    private store: Store<any>,
    public loginService: LoginService,
    public dialog: MatDialog,
    public caseService: CaseService,
    private generalInquiryService: GeneralInquiryService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select('user')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state) => {
        this.user$.next(state);
      });

    this.store.select('case')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state) => (this.case$.next(state)));

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.showCaseDetailsTabs$.next(val.url.includes('case?'));
      }
    });

    this.caseService.caseDetails$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.caseDetails$.next(data.caseDetails);

        if (data.activeTab) {
          this.setDetails(data.activeTab);
        } else if (this.caseService.details$.getValue()?.id) {
          this.setDetails(this.caseService.details$.getValue().id);
        }
      });

    // TODO: get authorized state instead (this.oktaAuth.$authenticationState doesn't work)
    this.store.select('user')
      .pipe(skip(1), take(1))
      .subscribe((user: IUser) => {
        if (user.id) {
          this.generalInquiryService.getInquiryTypes()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((inquiryTypes: IInquiryType[]) => {
                this.inquiryTypes$.next(inquiryTypes);
            });
        }
      });
  }

  setDetails(detail): void {
    this.caseService.drawerHandler.emit(true);
    this.caseService.details$.next(detail ? this.caseDetails$.getValue().find((item) => item.id === detail) : null);
  }

  logout(): void {
    this.loginService.logout().subscribe(
      () => {
      },
      (error) => throwError(error),
    );

    this.loginService.oktaLogout();
  }

  openSearchDialog(): void {
    this.isSearchModalOpen$.next(true);

    this.dialog.open(SearchModalComponent, {
      data: {searchValue: ''},
      panelClass: 'searchDialog',
    }).afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.isSearchModalOpen$.next(false));
  }

  openGeneralInquiryDialog(): void {
    this.isInquiryTypesModalOpen$.next(true);

    this.dialog.open(GeneralInquiryDialogComponent, {
      data: this.inquiryTypes$.getValue(),
      panelClass: ['selectTypeFormDialog', 'generalInquiryDialog'],
    }).afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.isInquiryTypesModalOpen$.next(false));
  }
}
