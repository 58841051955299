import { ContactMethod } from '@shared/enums/contact-method.enum';

export class ContactMethodCaptions {
  public static ForContactCard(contactMethod: ContactMethod){
    switch(contactMethod){
      case ContactMethod.phone: return 'Call';
      case ContactMethod.fax: return 'Fax';
      case ContactMethod.email: return 'Email';
      case ContactMethod.text: return 'Text';
      case ContactMethod.mail: return 'Mail';
      default: return '';
    }
  }

  public static Icon(contactMethod: ContactMethod){
    switch(contactMethod){
      case ContactMethod.phone: return 'phone';
      case ContactMethod.fax: return 'print';
      case ContactMethod.email: return 'email';
      case ContactMethod.text: return 'textsms';
      case ContactMethod.mail: return 'email';
      default: return '';
    }
  }
}