<h1 mat-dialog-title>Search Cases</h1>

<div mat-dialog-content class="content">
  <div class="input-item search">
    <img src="assets/icons/icon-search.svg" alt="search" />

    <input matInput
           type="text"
           placeholder="Search by Patient, Case ID, Payer, etc."
           [(ngModel)]="searchValue"
           (input)="onSearchCases.emit()"
           (keyup.enter)="searchCases()" />

    <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue = ''">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <app-suggested-cases></app-suggested-cases>
  <h2 *ngIf="isLoaded">Quick Results</h2>
  <div *ngIf="!isLoaded" class="modal-loader-container">
    <app-loader *ngIf="isLoaded === false"></app-loader>
  </div>
  <table mat-table [dataSource]="dataSource" [hidden]="!isLoaded">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Case ID</th>
      <td mat-cell *matCellDef="let case">{{ case.id }}</td>
    </ng-container>
    <ng-container matColumnDef="patient">
      <th mat-header-cell *matHeaderCellDef>Patient</th>
      <td mat-cell *matCellDef="let case">{{ case.patient }}</td>
    </ng-container>
<!--    <ng-container matColumnDef="payer">-->
<!--      <th mat-header-cell *matHeaderCellDef>Payer</th>-->
<!--      <td mat-cell *matCellDef="let case">{{ case.payer }}</td>-->
<!--    </ng-container>-->
    <ng-container matColumnDef="physician">
      <th mat-header-cell *matHeaderCellDef>Physician</th>
      <td mat-cell *matCellDef="let case">{{ case.physician }}</td>
    </ng-container>
    <ng-container matColumnDef="territory">
      <th mat-header-cell *matHeaderCellDef>Territory</th>
      <td mat-cell *matCellDef="let case">{{ case.territory }}</td>
    </ng-container>
    <ng-container matColumnDef="phase">
      <th mat-header-cell *matHeaderCellDef>Phase</th>
      <td mat-cell *matCellDef="let case">{{ case.phase }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let case">{{ case.status.name }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row mat-dialog-close *matRowDef="let case; columns: columnsToDisplay" (click)="openCase(case.id)"></tr>
    <tr class="mat-row no-data" *matNoDataRow>
      <td class="mat-cell" colspan="8">No data found</td>
    </tr>
  </table>
</div>
<div mat-dialog-actions
     *ngIf="cases && caseCount > showItems">
  <div class="more">
    <b>{{ caseCount }}</b> More Results
  </div>
  <button mat-button mat-dialog-close (click)="goToSearchPage()">
    View All <img src="../../../../../../assets/icons/icon-arrow-long-right-blue.svg" alt="view all"/>
  </button>
</div>
