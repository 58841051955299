<div mat-dialog-title>
  <h1><img src="assets/icons/icon-warning.svg"
         alt="warning"> Delete Page</h1>
  <button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content
     [formGroup]="deleteForm"
     class="dialog-content">
  <h2>Why are you deleting this page?</h2>
  <div class="checkbox-item">
    <mat-checkbox formControlName="blankPage">Blank Page</mat-checkbox>
  </div>
  <div class="checkbox-item">
    <mat-checkbox formControlName="other">Other</mat-checkbox>
  </div>
  <div class="input-item"
       *ngIf="deleteForm.value.other">
    <input name="otherText"
           id="otherText"
           maxlength="255"
           formControlName="otherText" />
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button
          mat-dialog-close
          (click)="deleteImage()"
          [disabled]="
            deleteForm.value.blankPage || (deleteForm.value.other && deleteForm.value.otherText.trim().length)
                ? false
                : true
        ">
    Escalate to Supervisor
    <mat-icon>east</mat-icon>
  </button>
</div>
