import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../../../core/services/abstract-rest.service';
import { Observable } from 'rxjs';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { IGetItems } from '../interfaces/get-items.interface';
import { ISaveData } from '../interfaces/save-data.interface';

@Injectable({
  providedIn: 'root',
})
export class QueueCreateService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  saveQueue(data: ISaveData): Observable<IResponse> {
    this.url = this.apiUrl + `/QueueConfiguration`;
    return this.putItem<ISaveData, IResponse>(data);
  }

  getTasks(data: IGetItems): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseTasks`;
    return this.postItem<IGetItems, IResponse>(data);
  }

  getUsers(data: IGetItems): Observable<IResponse> {
    this.url = this.apiUrl + `/Users`;
    return this.postItem<IGetItems, IResponse>(data);
  }
}
