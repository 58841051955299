<div class="container">
  <div class="top-block">
    <div class="top-information">
      <a [routerLink]="['/']">
        <img src="assets/icons/icon-arrow-long-right-blue.svg" alt="begin"/> WORK QUEUES
      </a>

      <h2 *ngIf="!isEdit">
        {{ name }}
        <button *ngIf="!isIntakeQueue()">
          <mat-icon (click)="toggleEdit()">create</mat-icon>
        </button>
      </h2>

      <p *ngIf="!isEdit">{{ description }}</p>

      <div class="setup-wrapper" [formGroup]="queueForm" *ngIf="isEdit">
        <div class="setup-item">
          <h3>Name</h3>
          <div class="input-item">
            <input name="name" id="name" formControlName="name"
                   placeholder="Ex., Northwest Region, Louisville Metro, Reimbursement…"/>
          </div>
        </div>
        <div class="setup-item">
          <h3>Description</h3>
          <div class="input-item">
            <textarea name="description" id="description" formControlName="description"
                      placeholder="Ex., Case Management Tasks, Reimbursement, Welcome Calls, Follow-Ups…"
                      matInput
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
            ></textarea>
          </div>
        </div>
        <div class="buttons-block confirm-block">
          <button class="primary-btn invert green" (click)="saveData()">Save</button>
          <button class="primary-btn invert" (click)="toggleEdit()">Cancel</button>
        </div>
      </div>

      <button class="primary-btn" (click)="viewList()">
        View List <img src="assets/icons/icon-arrow-long-right-white.svg" alt="view"/>
      </button>
    </div>
    <div class="top-chart">
      <canvas baseChart
              [datasets]="data"
              [colors]="chartColor"
              [labels]="lineChartLabels"
              [chartType]="lineChartType"
              [options]="lineChartOptions"
              #queueChart
      >
      </canvas>
    </div>
  </div>
</div>
<div class="navigation-block">
  <div class="container">
    <nav [class]="isIntakeQueue() ? 'intake-queue':''">
      <div [class]="activePage === 'Users' ? 'nav-item active' : 'nav-item'">
        <button (click)="setActivePage('Users')">Users</button>
      </div>
      <div [class]="activePage === 'Tasks' ? 'nav-item active' : 'nav-item'" *ngIf="!isIntakeQueue()">
        <button (click)="setActivePage('Tasks')">Tasks</button>
      </div>
      <div [class]="activePage === 'Territories' ? 'nav-item active' : 'nav-item'" *ngIf="!isIntakeQueue()">
        <button (click)="setActivePage('Territories')">Territories</button>
      </div>
      <div [class]="activePage === 'Settings' ? 'nav-item active' : 'nav-item'" *ngIf="!isIntakeQueue()">
        <button (click)="setActivePage('Settings')">Settings</button>
      </div>
    </nav>
  </div>
</div>
