import { Component, Input, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { HistoryTaskType } from '@shared/enums/history-task-type.enum';
import { TargetNames } from '@shared/enums/target-names.enum';
import { TaskContactMethodNames } from '@shared/enums/task-contact-method-names.enum';
import { TaskDirectionFullNames } from '@shared/enums/task-direction-full-names.enum';
import { TaskDirectionNames } from '@shared/enums/task-direction-names.enum';
import { MatDialog } from '@angular/material/dialog';
import { interpolateTemplate } from '@shared/helpers/utils';
import { MatSelect } from '@angular/material/select';
import { HistoryDetailsDialogComponent } from '../modals/history-details-dialog/history-details-dialog.component';
import { QueueStatus } from '@shared/enums/queue-status.enum';
import { TaskOutcome } from '@shared/enums/task-outcome.enum';
import { attachmentName } from '@shared/utilities/attachments.utils';
import { CaseService } from '../../services/case.service';
import { throwError } from 'rxjs';
import { HistoryTimelineStatus } from '../../enums/history-timeline-status.enum';

@Component({
  selector: 'app-case-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements AfterViewInit, OnInit {
  attachmentName = attachmentName;
  HistoryTimelineStatus = HistoryTimelineStatus;

  QueueStatus = QueueStatus;
  ContactMethod = ContactMethod;
  TaskOutcome = TaskOutcome;

  private _case;

  @Input() set case(newCase) {
    const needRefreshHistory = !!this._case;

    this._case = newCase;

    if(needRefreshHistory) this.initHistory();
  }

  get case() {
    return this._case;
  }

  @Input()
  get historyTimeline(): any[] {
    return this._historyTimeline;
  }

  set historyTimeline(historyTimeline) {
    this.initTimeline(historyTimeline);
  }

  @Input()
  get completedTasks(): any[] {
    return this._completedTasks;
  }

  set completedTasks(completedTasks) {
    if (this._completedTasks === completedTasks) return; 

    this._completedTasks = completedTasks;
  }

  historyType = new FormControl();
  historyTypes = [
    {
      type: HistoryTaskType.task,
      name: 'Activity',
    },
    {
      type: HistoryTaskType.statusChange,
      name: 'Status Changes',
    }
  ];

  historyPhase = new FormControl();
  historyPhaseList: string[];

  dataSource;
  data;

  columnsToDisplay = [
    'task',
    'createDate',
    'due',
    'completed',
    'outcome',
    'action',
  ];

  expandedElements = [];
  targetNames = TargetNames;
  contactMethodNames = TaskContactMethodNames;
  directionNames = TaskDirectionNames;
  contactMethod;

  _completedTasks = [];
  _historyTimeline = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('phaseFilter') phaseFilter: MatSelect;

  constructor(
    private caseService: CaseService,
    public dialog: MatDialog,
  ) {
    this.historyType.setValue(this.historyTypes.map(x => x.type));
  }

  ngOnInit(): void {
    this.initHistory();
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = this.sortingAccessor;
    }
  }

  private sortingAccessor(item, property) {
    switch(property) {
      case 'task': return item.type === HistoryTaskType.task ? item.task : 'Status Change';
      case 'createDate': return item.createdDate;
      case 'due': return item.type === HistoryTaskType.task ? item.due : undefined;
      case 'completed': return item.type === HistoryTaskType.task ? item.completed : undefined;
      case 'outcome': return item.type === HistoryTaskType.task ? item.outcome : -1;
    }
  }

  private initTimeline(historyTimeline) {
    if (this._historyTimeline === historyTimeline) return;

    this._historyTimeline = historyTimeline
      .filter(e => e.weight < 0 || !(historyTimeline.some(h => e.weight < h.weight && h.status !== HistoryTimelineStatus.Upcoming) && e.status === HistoryTimelineStatus.Upcoming))
      .sort((a, b) => a.weight - b.weight);

    this.historyPhaseList = this.data?.length
    ?  historyTimeline.filter(e => this.data.some(d => d.phase === e.phase)).map(e => e.phase)
    : [];

    this.historyPhase.setValue(this.historyPhaseList);
  }

  private initHistory() {
    this.data = this.getHistory();
    this.dataSource = new MatTableDataSource(this.data);

    if (this.sort) this.dataSource.sort = this.sort;
  }

  private getHistory() {
    const tasksHistory = this._completedTasks?.map(this.mapTask) || [];
    const caseHistory = this.case.history?.map(this.mapStatusChange) || [];

    const result = [...tasksHistory, ...caseHistory]
      .sort((e1, e2) => e2.createdDate - e1.createdDate)

    return result;
  }

  private mapTask(item) {
    if (item.attachment && !item.attachment.subject) {
      item.attachment.subject = item.contactMethod === ContactMethod.text ? `${item.task} Text` : '';
    }

    return {
      ...item,
      createdDate: new Date(item.createDate),
      task: interpolateTemplate(item.task, {
        reason: item.followUpReason,
        contactMethod: TaskContactMethodNames[item.contactMethod] || '',
        entity: TargetNames[item.target] || '',
        direction: TaskDirectionFullNames[item.direction] || ''
      }),
      type: HistoryTaskType.task,
    }
  }

  private mapStatusChange(item) {
    return {
      ...item,
      createdDate: new Date(item.createdDate),
      type: HistoryTaskType.statusChange,
    };
  }

  clearFilter(): void {
    this.historyPhase.setValue([]);
    this.applyFilter('phases');
    this.phaseFilter.close();
  }

  applyAllFilter(): void {
    this.historyPhase.setValue(this.historyPhaseList);
    this.applyFilter('phases');
    this.phaseFilter.close();
  }

  toggleRow(id): void {
    if (this.expandedElements.includes(id)) {
      this.expandedElements = this.expandedElements.filter((item) => item !== id);
    } else {
      this.expandedElements.push(id);
    }
  }

  isTypeInList(type): boolean {
    return this.historyType.value.includes(type);
  }

  isPhaseInList(phase): boolean {
    return this.historyPhase.value.includes(phase);
  }

  isExpanded(id): boolean {
    return this.expandedElements.includes(id);
  }

  applyFilter(filter): void {
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = filter;
  }

  customFilterPredicate(): (data: any, filter: any) => boolean {
    const myFilterPredicate = (data, filter): boolean => {
      return this.isTypeInList(data.type) && this.isPhaseInList(data.phase);
    };

    return myFilterPredicate;
  }

  isStatusChange(index, item): boolean {
    return item.type === HistoryTaskType.statusChange;
  }

  isActivity(index, item): boolean {
    return item.type === HistoryTaskType.task;
  }

  previewAttachment(attachment): void {
    this.caseService.downloadAttachment(attachment.id).subscribe(result => {
      const name = attachmentName(attachment);

      window.open(window.URL.createObjectURL(result), '_blank').focus();
    },
    err => throwError(err));
  }

  openViewDetailsDialog(task): void {
    this.dialog.open(HistoryDetailsDialogComponent, {
      panelClass: ['selectTypeFormDialog', 'history-details-dialog'],
      data: {task, case: this.case},
    })
      .afterClosed()
      .subscribe(() => {
      });
  }
}
