import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './escalate-task.component.html',
  styleUrls: ['./escalate-task.component.scss']
})
export class EscalateTaskComponent {
  public reason: string;

  constructor(private dialogRef: MatDialogRef<EscalateTaskComponent>) { }

  escalate(): void {
    this.dialogRef.close({escalateReason: this.reason});
  }

  close(): void {
    this.dialogRef.close();
  }
}
