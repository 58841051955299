<div class="breadcrumbs">
  <ul>
    <li>
      <a routerLink="/">
        <img src="assets/icons/icon-arrow-long-right-breadcrumbs.svg" alt="back"/>
        WORK QUEUES
      </a>
    </li>
    <li>{{currentPage}}</li>
  </ul>
</div>
