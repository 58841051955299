import { Action } from '@ngrx/store';
import { IUser } from '../../shared/interfaces/user.interface';

export enum UserTypes {
  SET_USER = 'SET_USER',
  SET_USER_INFO = 'SET_USER_INFO',
}

export class SetUser implements Action {
  readonly type = UserTypes.SET_USER;

  constructor(public payload: IUser) {
  }
}

export class SetUserInfo implements Action {
  readonly type = UserTypes.SET_USER_INFO;

  constructor(public payload: any) {
  }
}

export type UserActions = SetUser | SetUserInfo;
