export enum UncertainReason {
  blurryPage = 0,
  illegibleWriting = 1,
  other = 2,
}

export class UncertainReasonNames {
  static readonly [UncertainReason.blurryPage] = 'Blurry Page';
  static readonly [UncertainReason.illegibleWriting] = 'Illegible Writing';
  static readonly [UncertainReason.other] = 'Other';
}
