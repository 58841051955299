import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IInsurance } from '@shared/interfaces/insurance.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { InsuranceOutcomeTypesEnum } from '@shared/enums/insurance-outcome-types.enum';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { InsuranceDetailsDialogComponent } from '../../../modals/insurance-details-dialog/insurance-details-dialog.component';

@Component({
  selector: 'app-insurances-table',
  templateUrl: './insurances-table.component.html',
  styleUrls: ['./insurances-table.component.scss'],
})
export class InsurancesTableComponent implements OnInit, AfterViewInit {
  @Input() isMedical: boolean;
  @Input() set insurances(value: IInsurance[]) {
    this.dataSource.data = value;
  }

  columnsToDisplay: string[] = [
    'planName',
    'priorityId',
    'outcome',
    'completed',
    'copay',
    'coinsurance',
    'isRequired',
    'details',
  ];

  dataSource: MatTableDataSource<IInsurance> = new MatTableDataSource<IInsurance>([]);

  InsuranceOutcomeTypesEnum = InsuranceOutcomeTypesEnum;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    if (this.isMedical) {
      this.columnsToDisplay.unshift('payerName');
    }

    this.columnsToDisplay.unshift('paddingLeft');
    this.columnsToDisplay.push('paddingRight');
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  openDetailsDialog(insurance: IInsurance): void {
    this.dialog.open(InsuranceDetailsDialogComponent, {})
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
      });
  }
}
