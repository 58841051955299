/// Please use interpolation.configurator.ts file for configure behavior of interpolation
/// Make changes to this file only in exceptional cases

import { Matcher } from './matcher';

export abstract class BaseSelector {
  /**
   * selects value from model 
   */
  public abstract select(selector: string): string;

  /**
   * sets model for selection 
   */
  public abstract setModel(model: any): BaseSelector;

  /**
   * sets for custom selects if need 
   */
  public abstract setMatcher(matcher: Matcher): BaseSelector;
}
