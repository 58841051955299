import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from './components/callback.component';
import { LoginComponent } from './components/login/login.component';
import { WidgetsWrapperComponent } from './components/widgets-wrapper/widgets-wrapper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material/material.module';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot.password.component';
import { ResetPasswordComponent } from './components/reset-password/reset.password.component';

export const routes: Routes = [
  {
    path: '',
    component: WidgetsWrapperComponent,

    children:[
      {
        path: '',
        component: LoginComponent,
      },
      
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'email-verification',
        component: EmailVerificationComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      }
    ]
  },
];

const components = [
  OktaCallbackComponent,
  LoginComponent,
  WidgetsWrapperComponent,
  EmailVerificationComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatIconModule,
    CommonModule,
  ],
  exports: [
    ...components,
  ],
})
export class LoginModule {
}
