import { Component, Input } from '@angular/core';
import { IBreadcrumb } from '../../interfaces/breadcrumb.interface';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() items: IBreadcrumb[] = [];

  constructor() {
  }

}
