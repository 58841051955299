import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IInsurancePlan, IPharmacyBenefitPlan } from '../../../../interfaces/insurance-plan.interface';
import { CoverageType } from '@shared/enums/insurance/coverage-type.enum';
import { enumToArray, enumToObjectReverse, isIncludedInTimeFrame } from '@shared/helpers/utils';
import { InsurancePriorityTypesEnum } from '@shared/enums/insurance-priority-types.enum';
import { InsurancePlanTypesEnum } from '@shared/enums/insurance-plan-types.enum';
import { InsurancePlanSubTypesEnum } from '@shared/enums/insurance-plan-sub-types.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { EditMedicalPlanDialogComponent } from '../dialogs/edit-medical-plan-dialog/edit-medical-plan-dialog.component';
import { CaseService } from '../../../../services/case.service';
import { IPlan } from '@shared/interfaces/plan.interface';

@Component({
  selector: 'app-section-insurances',
  templateUrl: './section-insurances.component.html',
  styleUrls: ['./section-insurances.component.scss'],
})
export class SectionInsurancesComponent implements OnInit {
  @Input() case: any;

  @Input() set caseInsurances(insurances: IInsurancePlan[]) {
    this.allInsurances = [...insurances];
    this.setInsurances(insurances);
  }

  allInsurances: IInsurancePlan[] = [];
  medicalInsurances: IInsurancePlan[] = [];
  pharmacyInsurances: IInsurancePlan[] = [];
  inactiveInsurances: IInsurancePlan[] = [];

  currentInsurance: IInsurancePlan;

  inactivePlansOpened: boolean;

  insurancePriorityTypes = enumToObjectReverse(InsurancePriorityTypesEnum);
  planTypes = enumToArray(InsurancePlanTypesEnum);
  planSubTypes = enumToArray(InsurancePlanSubTypesEnum);

  CoverageType = CoverageType;

  editPatient: boolean;
  editPolicy: boolean;

  policyFormGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private caseService: CaseService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.policyFormGroup = this.fb.group({
      memberId: ['', [Validators.required]],
      planNumber: ['', [Validators.required]],
      groupNumber: ['', [Validators.required]],
      policyHolderName: ['', [Validators.required]],
      policyHolderDOB: ['', [Validators.required]],
      effectiveFrom: ['', [Validators.required]],
      effectiveTo: ['', [Validators.required]],
    });
  }

  choosePlan(plan: IInsurancePlan): void {
    this.currentInsurance = plan;
    this.policyFormGroup.patchValue(plan.patientInsurance);
  }

  backToInsuranceLists(): void {
    this.setInsurances(this.allInsurances);
    this.currentInsurance = null;
  }

  clearPolicyControls(controlNames: string[]): void {
    controlNames.forEach(name => this.policyFormGroup.get(name)?.setValue(''));
  }

  savePolicyData(controlNames: string[]): void {
    if (controlNames.every(name => this.policyFormGroup.get(name).valid)) {
      controlNames.forEach((name: string) => {
        this.currentInsurance.patientInsurance[name] = this.policyFormGroup.get(name).value;
        this.cdr.detectChanges();
      });

      this.caseService.updatePolicyData(this.currentInsurance)
        .pipe(take(1))
        .subscribe((success: boolean) => {});
    }
  }

  openMedicalPlanDialog(isPharmacy: boolean): void {
    this.dialog.open(EditMedicalPlanDialogComponent, {
      data: {case: this.case, isPharmacy, patientInsuranceId: this.currentInsurance.patientInsuranceId},
      panelClass: 'editMedicalPlanDialog',
    })
      .afterClosed()
      .pipe(take(1))
      .subscribe((plan: any) => {
        // TODO: refactor the model
        if (plan) {
          if (isPharmacy) {
            this.currentInsurance.patientInsurance.pharmacyBenefitPlan = plan;
          } else {
            this.currentInsurance.patientInsurance.payerName = plan?.payerName;
            this.currentInsurance.patientInsurance.planName = plan?.name;
            this.currentInsurance.patientInsurance.planTypeName = plan?.typeName;
            this.currentInsurance.patientInsurance.planSubTypeName = plan?.subTypeName;
            this.currentInsurance.patientInsurance.planPhone = plan?.phone;
          }
        }
      });
  }

  private setInsurances(insurances: IInsurancePlan[]): void {
    this.medicalInsurances = [];
    this.pharmacyInsurances = [];
    this.inactiveInsurances = [];

    insurances.forEach((el: IInsurancePlan) => {
      const active = isIncludedInTimeFrame(el.patientInsurance?.effectiveFrom, el.patientInsurance?.effectiveTo);

      if (active) {
        switch (el.patientInsurance?.coverageTypeId) {
          case CoverageType.medical:
            this.medicalInsurances.push(el);
            break;
          case CoverageType.pharmacy:
            this.pharmacyInsurances.push(el);
            break;
        }
      } else {
        this.inactiveInsurances.push(el);
      }
    });
  }
}
