<div mat-dialog-title>
  <h1><img alt="age" src="assets/icons/icon-age-red.svg"/>Reschedule</h1>
  <button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p>You're creating a <b>Follow-up</b> for this <b>{{data.task}}</b></p>

  <div class="dialog-body" [formGroup]="rescheduleForm">
    <div class="input-item toggle">
      <label>Follow-up Creation</label>
      <mat-button-toggle-group formControlName="creation">
        <mat-button-toggle [value]="FollowUpType.auto"> Automatic</mat-button-toggle>
        <mat-button-toggle [value]="FollowUpType.manual"> Manual</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="attempt-time">
      <div class="input-item">
        <label for="attemptDate">Next Attempt Date</label>
        <div class="date-input">
          <input matInput [matDatepicker]="attemptDate" id="attemptDate" formControlName="attemptDate"
                 [min]="currentDate"
                 placeholder="MM/DD/YY" (focus)="attemptDate.open()" (click)="attemptDate.open()"/>
          <mat-datepicker-toggle matSuffix [for]="attemptDate" tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker #attemptDate></mat-datepicker>
        </div>
      </div>

      <div class="input-item">
        <label for="attemptTime">Next Attempt Time</label>
        <div class="date-input">
          <input [ngxTimepicker]="attemptTime" id="attemptTime" formControlName="attemptTime"/>
          <ngx-material-timepicker #attemptTime></ngx-material-timepicker>
        </div>
      </div>
    </div>

    <div class="input-item toggle attempt-type" *ngIf="contactMethods && contactMethods?.length > 0 && data.contactMethod !== ContactMethod.manual">
      <label>Next Attempt Type</label>
      <mat-button-toggle-group formControlName="contactMethod">
        <mat-button-toggle *ngFor="let method of contactMethods" [value]="method">
          <mat-icon>{{contactIcon(method)}}</mat-icon>
          {{contactName(method)}}
        </mat-button-toggle>
      </mat-button-toggle-group>  
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button class="back" [disabled]="rescheduleForm.invalid" (click)="rescheduleTask()">
    <img alt="back" src="assets/icons/icon-age-blue.svg"/>Schedule Follow-Up
  </button>
</div>
