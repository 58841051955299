<div mat-dialog-title>
    <h1><img src="assets/icons/icon-warning.svg" alt="warning" />Missing Info</h1>
    <button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<div mat-dialog-content>
    <p>You left the following fields blank:</p>
    <div class="dialog-body">
        <ul>
            <li *ngFor="let field of fields"><mat-icon>error</mat-icon>{{ field.name }}</li>
        </ul>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button class="back" mat-dialog-close>
        <img src="assets/icons/icon-arrow-back.svg" alt="Back" />Go Back
    </button>
    <button mat-button class="back" mat-dialog-close (click)="close(true)">
        Proceed <img style="transform: rotate(0deg); margin-right: 0; margin-left: 12px;" src="assets/icons/icon-arrow-back.svg" alt="Back" />
    </button>
</div>
