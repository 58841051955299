export function isBlank(value) {
  return [undefined, null, ''].includes(value);
}

export function isNotBlank(value) {
  return !isBlank(value);
}

export class Emptiness {
  constructor(private defaultValue = undefined) {
  }

  public setDefault(value) {
    this.defaultValue = value;
    return this;
  }

  public getValueOrDefault(...values: any[]) {
    const value = values.find(val => isNotBlank(val));

    return value === undefined ? this.defaultValue : value;
  }

  public getValue(...values: any[]) {
    const value = values.find(val => isNotBlank(val));

    if (value) {
      return value;
    }

    throw new Error('Value Not Found!');
  }
}

export function getWithoutEmptyValues(obj: any): any {
  const newObj = Object
    .keys(obj)
    .reduce(
      (newObj, key) => { 
        if (![undefined, null].includes(obj[key])) newObj[key] = obj[key];

        return newObj;
      }
    , { } as any);

  return newObj;
}