import { Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { LoginTransactionModel } from 'src/app/features/profile/models/login-transaction.model';
import { OktaService } from 'src/app/shared/services/okta.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit {
  isError: boolean;

  isEmpty = true;

  loginTransactionInfo: LoginTransactionModel;

  isVerificationCodeInvalid = false;

  inputs = Array(6).fill('');

  @ViewChild('verifyButton') verifyButton;
  @ViewChildren('codeInputs') codeInputs: QueryList<any>;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.verify();
    }
  }

  constructor(
    private oktaService: OktaService,
    private loginService: LoginService,
  ) {
  }

  ngOnInit(): void {
    this.loginTransactionInfo = history.state;
    setTimeout(() => this.codeInputs.toArray()[0]?.nativeElement.focus());
  }

  verify(): void {
    const inputs = this.codeInputs.toArray()?.map(el => el.nativeElement.value) || [];
    const passCode = inputs?.length === 6 ? inputs.join('') : '';

    if (!passCode) {
      this.isEmpty = true;
      this.isError = true;
      return;
    }

    this.oktaService.verifyEmailFactor(this.loginTransactionInfo.factorId, {
      stateToken: this.loginTransactionInfo.stateToken,
      passCode,
    })
      .subscribe((response) => {
          this.loginService.proceedTransaction({sessionToken: response.sessionToken});
        },
        (error) => {
          this.isError = true;

          switch (error.statusCode) {
            case 403:
              this.isVerificationCodeInvalid = true;
              break;
            case 401:
            case 400:
              this.isVerificationCodeInvalid = false;
              break;
          }
        }
      );
  }

  setValue(event, index: number): void {
    this.isError = false;
    const inputs = this.codeInputs.toArray();

    if (event.key === 'ArrowLeft' || event.key === 'Backspace') {
      if (!inputs[index]?.nativeElement.value) {
        setTimeout(() => inputs[index - 1]?.nativeElement.focus());
      }
    } else if (event.key === 'ArrowRight') {
      if (!inputs[index]?.nativeElement.value) {
        setTimeout(() => inputs[index + 1]?.nativeElement.focus());
      }
    } else if (!isNaN(event.key)) {
      if (inputs[index].nativeElement?.value) {
        inputs[index].nativeElement.value = event.key;
      }

      setTimeout(() => inputs[index + 1]?.nativeElement.focus());

      if (index === inputs.length - 1) {
        setTimeout(() => this.verifyButton.nativeElement.focus());
      }
    }

    this.isEmpty = inputs.some(el => !el.nativeElement?.value);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  pasteCode(event: ClipboardEvent): void {
    event.preventDefault();
    // tslint:disable-next-line
    const clipboardString = (event.clipboardData || window['clipboardData'])?.getData('text');

    if (clipboardString.length === 6 && Number(clipboardString)) {
      this.isEmpty = false;
      const inputs = this.codeInputs.toArray();
      inputs.forEach((el, i) => el.nativeElement.value = clipboardString[i]);
      inputs[inputs.length - 1].nativeElement.focus();
    }
  }
}
