import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from '../material/material.module';
import { AsideComponent } from './components/aside/aside.component';
import { HeaderComponent } from './components/header/header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { QueueItemComponent } from './components/queue/queue.component';
import { SearchFieldComponent } from './components/search/components/field/field.component';
import { SearchModalComponent } from './components/search/components/modal/modal.component';
import { SearchComponent } from './components/search/search.component';
import { DocumentViewerComponent } from './components/viewer/viewer.component';
import { DetectScrollTopDirective } from './directives/detect-scroll-top.directive';
import { MouseWheelDirective } from './directives/mousewheel.directive';
import { FullAddressPipe } from './pipes/fulladdress.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { EmptyPipe } from './pipes/empty.pipe';
import { NewPhysicianFormComponent } from './components/new-physician-form/new-physician-form.component';
import { NewFacilityFormComponent } from './components/new-facility-form/new-facility-form.component';
import { ContactStringPipe } from './pipes/contact-string.pipe';
import { RouterModule } from '@angular/router';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { PageStepperComponent } from './components/page-stepper/page-stepper.component';
import { OptionChipComponent } from './components/option-chip/option-chip.component';
import { LottieModule } from 'ngx-lottie';
import { LoaderComponent } from './components/loader/loader.component';
import { FullNamePipe } from './pipes/fullName.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { PluckPipe } from './pipes/pluck.pipe';
import { SsnPipe } from './pipes/ssn.pipe';
import { PageBreadcrumbsComponent } from './components/page-breadcrumbs/page-breadcrumbs.component';
import { SelectedPhysicianComponent } from './components/selected-physician/selected-physician.component';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { ToggleMenuComponent } from './components/toggle-menu/toggle-menu.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DropFileDirective } from './directives/drop-file.directive';
import { MessageBarComponent } from './components/message-bar/message-bar.component';
import { ExpandableListComponent } from './components/expandable-list/expandable-list.component';
import { InputControlAccessorDirective } from './directives/input-control-accessor.directive';
import { NestedFormFieldsPipe } from './pipes/nested-form-fields.pipe';
import { GeneralInquiryDialogComponent } from './components/aside/modals/general-inquiry-dialog/general-inquiry-dialog.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { LoaderSpinnerComponent } from './components/loader-spinner/loader-spinner.component';
import { SubmitButtonComponent } from './components/submit-button/submit-button.component';
import { SuggestedCaseComponent } from './components/search/components/suggested-case/suggested-case.component';
import { SuggestedCasesComponent } from './components/search/components/suggested-cases/suggested-cases.component';
import { EmptySplashComponent } from './components/empty-splash/empty-splash.component';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';
import { MouseWheelModule } from './directives/mouse-wheel/mouse-wheel.module';

const components = [
  NewPhysicianFormComponent,
  NewFacilityFormComponent,
  BreadcrumbsComponent,
  PageStepperComponent,
  OptionChipComponent,
  LoaderComponent,
  DocumentViewerComponent,
  AsideComponent,
  HeaderComponent,
  PaginationComponent,
  QueueItemComponent,
  SearchComponent,
  SearchModalComponent,
  SearchFieldComponent,
  SuggestedCaseComponent,
  SuggestedCasesComponent,
  PageBreadcrumbsComponent,
  SelectedPhysicianComponent,
  ToggleMenuComponent,
  MessageBarComponent,
  ExpandableListComponent,
  GeneralInquiryDialogComponent,
  LoaderSpinnerComponent,
  SubmitButtonComponent,
  EmptySplashComponent,
  DropdownSearchComponent,
];

const pipes = [
  EmptyPipe,
  CapitalizePipe,
  FullAddressPipe,
  PhonePipe,
  ContactStringPipe,
  PluckPipe,
  JoinPipe,
  PhoneNumberPipe,
  SsnPipe,
  FullNamePipe,
  NameInitialsPipe,
  NestedFormFieldsPipe,
];

const directives = [
  MouseWheelDirective,
  DetectScrollTopDirective,
  DropFileDirective,
  InputControlAccessorDirective,
];

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory(): any {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    ...components,
    ...pipes,
    ...directives,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    LottieModule.forRoot({player: playerFactory, useCache: true}),
    ChartsModule,
    NgScrollbarModule,
    DragDropModule,
    ClickOutsideModule,
    NgxMaterialTimepickerModule,
    MouseWheelModule,
  ],
  exports: [
    ...components,
    ...pipes,
    ...directives,
    CommonModule,
    RouterModule,
  ],
  providers: []
})
export class SharedModule {
}
