import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { enumToArray } from 'src/app/shared/helpers/utils';
import { SupervisorTerritoryColumns } from '../../../../../../shared/enums/supervisor-territory-columns.enum';
import { ITerritory } from '../../../../../../shared/interfaces/territory.interface';
import { SupervisorAddTerritoryComponent } from './components/add/add.component';

@Component({
  selector: 'app-supervisor-territories',
  templateUrl: './territories.component.html',
  styleUrls: ['../users/users.component.scss', './territories.component.scss'],
})
export class SupervisorTerritoriesComponent implements AfterViewInit {
  @Output() territoryRemoved = new EventEmitter<number>();
  @Output() territoriesSaved = new EventEmitter<ITerritory[]>();

  _territories: ITerritory[] = [];
  columnsToDisplay = enumToArray(SupervisorTerritoryColumns);
  dataSource;

  @Input()
  get territories(): ITerritory[] {
    return this._territories;
  }

  set territories(territories) {
    if (this._territories.length !== 0 || territories.length !== 0) {
      this._territories = territories;
      this.dataSource = new MatTableDataSource(this._territories);
      if (this.sort) {
        this.dataSource.sort = this.sort;
      }
      if (this.paginator) {
        this.dataSource.paginator = this.paginator;
      }
    }
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialog) {
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  removeTerritory(id): void {
    this.territoryRemoved.emit(id);
  }

  addTerritory(): void {
    const dialogRef = this.dialog.open(SupervisorAddTerritoryComponent, {
      data: {
        addedTerritories: this.territories
      }, panelClass: 'addDialog'
    });
    const sub = dialogRef.componentInstance.territoriesSaved.subscribe((users) => {
      this.territoriesSaved.emit(users);
    });
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }
}
