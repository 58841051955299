import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConsentMethod } from '@shared/enums/consent-method.enum';
import { ConsentTypeId } from '@shared/enums/consent-type-id.enum';
import { except, groupBy, maxBy } from '@shared/utilities/arrays.utils';
import * as moment from 'moment';
import { CaseService } from 'src/app/features/manager/components/case/services/case.service';
import { TabState } from '../../../../configuration/tab.state';
import { DataEntryFormService } from '../../services/form.service';
import { BaseFormComponent } from '../base-form.component';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['../../form.component.scss', './consent.component.scss', '../physician/physician.component.scss']
})
export class ConsentComponent extends BaseFormComponent implements OnInit, AfterViewInit {
  // Effective end date as five years later after Effective start date
  // Note: Maybe this field will be configurable in future
  private readonly EffectiveEndDate = 5;

  @Input('case') case;
  @Input('state') public state: TabState;
  @Input('isLoading') public isFormLoading: boolean;
  
  @Output() submitForm = new EventEmitter();

  consentForm: FormGroup;
  
  // currently selecting most recent consent;
  private static ruleToSelectConsent = e => new Date(e.dateReceived);

  public readonly disabledDate = new Date();
  public readonly binaryOptions = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    }
  ];

  constructor(
    private dataEntryService: DataEntryFormService,
    private caseService: CaseService,
  ) { super(); }

  ngOnInit(): void {
    this.state.SaveState = () => this.saveState();

    this.consentForm = this.state.forms.consentForm;

    this.consentForm.controls.patientSignature.valueChanges.subscribe(newVal => {
      this.state.config.fieldsConfig.patientSignatureDate.isVisible = newVal;
    });
  }

  ngAfterViewInit() { 
    setTimeout(() => { 
      this.state.config.fieldsConfig.patientSignatureDate.isVisible = this.consentForm.controls.patientSignature.value;
    }, 0);
  }

  public static initForms(state: TabState, caseData: any) {
    state.forms.consentForm = ConsentComponent.getConsentForm(state.config.fieldsConfig);

    ConsentComponent.populateConsentForm(state.forms.consentForm, caseData, state.autofillData);
  }

  private static getConsentForm(field) {
    return new FormGroup({
      patientSignature: this.getControl({ field: field.patientSignature, value: false }),
      patientSignatureDate: this.getControl({ field: field.patientSignatureDate, disabled: field.patientSignatureDate.isReadOnly }),
    });
  }

  private static populateConsentForm(form, caseData, json) {
    const casePatient: any = caseData?.patient || {};

    const controls = form.controls;

    const consent = ConsentComponent.getHipaaConsent(casePatient);

    controls.patientSignature.patchValue(!!consent);
    controls.patientSignatureDate.patchValue(consent?.dateReceived);
  }

  private static getHipaaConsent(casePatient) {
    const consent = maxBy(casePatient.currentConsents, ConsentComponent.ruleToSelectConsent);

    return consent;
  }

  private static getLastConsents(casePatient) {
    const consentGroups = groupBy<any, any>(casePatient.currentConsents, e => e.consentTypeId);

    const consents = Object
      .keys(consentGroups)
      .map(key => consentGroups[key])
      .reduce((acc, cur) => {
        acc.push(maxBy(cur, ConsentComponent.ruleToSelectConsent));
        return acc;
      }, []);

    return consents;
  }

  submitHandler(): void {
    this.saveState();

    BaseFormComponent.validateForms(this.state);

    if (this.state.isValid) this.submitForm.emit();
  }

  private async saveState() {
    BaseFormComponent.validateForms(this.state);

    if (!this.state.isValid) return;

    const isSaved = await this.saveConsent();

    this.state.isSaved = isSaved;
  }

  saveConsent(): Promise<any> {
    const formValue = this.consentForm.getRawValue();

    if (!formValue.patientSignature) { 
      return Promise.resolve();
    }

    const consentTypes = this.state.config.fieldsConfig.generateConsents;

    const date = formValue.patientSignatureDate;
    const effectiveEndDate = moment(date).add(this.EffectiveEndDate, 'year').toDate();

    const consentsToUpdate = ConsentComponent
      .getLastConsents(this.case.patient)
      .filter(e => consentTypes.indexOf(e.consentTypeId) !== -1)
      .map(e => {
        e.dateReceived = date;
        e.effectiveEndDate = effectiveEndDate;
        return e;
      });

    const consentsToAdd = except(consentTypes, consentsToUpdate.map(e => e.consentTypeId))
      .map(type => this.getNewConsentObj(formValue, this.case.patient.id, type));

    return new Promise<any>(
      resolve => {
        const command = {
          consents: [...consentsToUpdate, ...consentsToAdd]
        };

        this.dataEntryService.addOrUpdateConsents(command)
          .subscribe(
            res => {
              if (!res.success) { 
                resolve(res.success);
                return;
              }

              this.case.patient.currentConsents = res.value;

              resolve(res.success);
            },
            err => { 
              console.error(err);
              resolve(false);
            }
          );
      }
    );
  }

  private getNewConsentObj(formValue, patientId, type: ConsentTypeId) { 
    const date = formValue.patientSignatureDate;
    const effectiveEndDate = moment(date).add(this.EffectiveEndDate, 'year').toDate();

    return {
      id: 0,
      consentTypeId: type,
      consentMethod: ConsentMethod.written,
      consentIsOnFile: true,
      dateReceived: date,
      effectiveEndDate: effectiveEndDate,
      patientId: patientId
    };
  }
}
