<div class="input-wrapper">
  <label *ngIf="field.name">{{field.name}}</label>

  <input [classList]="field.additionalStyles"
         [(ngModel)]="value"
         [mask]="field.mask"
         [placeholder]="placeholder"
         [maxLength]="field.maxLength || 9999"
         [pattern]="pattern"
         [disabled]="disabled"
  />

  <span *ngIf="field.prefix">{{field.prefix}}</span>
</div>
