import { TabId } from '../parameters/tab-identifier';
import { TabState } from '../tab.state';

export abstract class BaseConfig {
  constructor(
    public readonly tabId: TabId,
    public readonly tabOrder: number,
    public readonly tabDisplayName: string,

    public nextTab: TabState,
  ){}

  public fieldsConfig : any;
}