import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './components/profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadPhotoDialogComponent } from './components/dialogs/upload-photo-dialog/upload-photo-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ResetPasswordDialogComponent } from './components/dialogs/reset-password-dialog/reset-password-dialog.component';

export const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
  },
];

const components = [
  ProfileComponent,
];

@NgModule({
  declarations: [
    ...components,
    UploadPhotoDialogComponent,
    ResetPasswordDialogComponent,
  ],
  imports: [
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    ImageCropperModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    ...components,
  ],
})
export class ProfileModule {
}
