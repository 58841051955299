import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BestMethodToContact } from '../../../../../../../../shared/enums/best-method-to-contact.enum';
import { BestTimeToContact } from '../../../../../../../../shared/enums/best-time-to-contact.enum';
import { ConsentMethod } from '../../../../../../../../shared/enums/consent-method.enum';
import { ConsentTypeId } from '../../../../../../../../shared/enums/consent-type-id.enum';
import { ContactMethod } from '../../../../../../../../shared/enums/contact-method.enum';
import { ContactType } from '../../../../../../../../shared/enums/contact-type.enum';
import { PatientContactType } from '../../../../../../../../shared/enums/patient-contact-type.enum';
import { deepCopy, expiredFile, getConsentValue, getDateString, phoneValidator } from '../../../../../../../../shared/helpers/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPatient } from '../../../../../../../../shared/interfaces/patient.interface';

@Component({
  selector: 'app-section-patient',
  templateUrl: './section-patient.component.html',
  styleUrls: ['./section-patient.component.scss', '../../aside-sections.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionPatientComponent implements OnInit {
  @Input() patient: IPatient;
  @Input() diagnosis;
  @Input() currentConsents;

  @Output() update: EventEmitter<IPatient> = new EventEmitter<IPatient>();
  @Output() openManagement: EventEmitter<string> = new EventEmitter<string>();

  maxDate: Date;

  patientCopy: IPatient;

  genderTypes = ['Female', 'Male', 'Other'];
  hipaaConsent = ['Yes', 'No', 'Unknown'];
  hipaaConsentObj;
  hipaaConsentValue;

  consentTypes = {
    1: 'hipaa',
    2: 'program',
    3: 'marketing',
    4: 'texting',
    5: 'voicemail',
  };

  ContactMethod = ContactMethod;
  ContactType = ContactType;
  ConsentMethod = ConsentMethod;
  ContactTypeNames = PatientContactType;
  BestTimeToContact = BestTimeToContact;
  BestMethodToContact = BestMethodToContact;

  expiredFile = expiredFile;

  emailForm: FormGroup;
  phoneForm: FormGroup;
  ssn;

  isEditName = false;
  isEditGender = false;
  isEditBD = false;

  constructor(private cdr: ChangeDetectorRef) {
    this.emailForm = new FormGroup({
      email: new FormControl(null, [Validators.email]),
      emailType: new FormControl(ContactType.work)
    });
    this.phoneForm = new FormGroup({
      phone: new FormControl(null, [phoneValidator]),
      phoneType: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.patientCopy = deepCopy(this.patient);
    this.hipaaConsentObj = this.patientCopy.currentConsents?.find(x => x.consentTypeId === ConsentTypeId.hipaa);
    this.hipaaConsentValue = getConsentValue(this.hipaaConsentObj);
    this.ssn = this.patientCopy.socialSecurityNumber?.length >= 4
      ? this.patientCopy.socialSecurityNumber.substring(this.patientCopy.socialSecurityNumber.length - 4)
      : (this.patientCopy.socialSecurityNumber || '');
    this.updateContactInfo();
  }

  getDateFromISO(val: string): Date {
    return val ? new Date(val) : undefined;
  }

  calculateAge(birthday: string): number {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  openAddressManagement(): void {
    this.openManagement.emit('address');
  }

  openConsentManagement(): void {
    this.openManagement.emit('consent');
  }

  savePatientData(save: boolean, inputs: any[]): void {
    switch (inputs[0]) {
      case 'contactInfos':
        this.setContactInfo(inputs, save);
        break;
      case 'dateOfBirth':
        save
          ? this.patient.dateOfBirth = getDateString(this.patientCopy.dateOfBirth, '')
          : this.patientCopy.dateOfBirth = this.patient.dateOfBirth.toString().slice();
        this.isEditBD = false;
        break;
      case 'gender':
        save
          ? this.patient.gender = +this.patientCopy.gender
          : this.patientCopy.gender = +this.patient.gender;
        this.isEditGender = false;
        break;
      case 'firstName':
        save
          ? inputs.forEach(item => this.patient[item] = this.patientCopy[item].toString().slice())
          : inputs.forEach(item => this.patientCopy[item] = this.patient[item].toString().slice());
        this.isEditName = false;
    }

    if (save) {
      this.update.emit(this.patient);
      this.cdr.detectChanges();
    }
  }

  editPatientName(patientFirstName): void {
    this.isEditName = true;
    setTimeout(() => {
      patientFirstName.focus();
    });
  }

  private setContactInfo(inputs, save): void {
    let exists = false;

    this.patient.contactInfos.forEach((info) => {
      if (info.contactMethod === inputs[2] && (!inputs[3] || info.contactType === inputs[3])) {
        const obj = {};
        obj[inputs[1]] = info.contactString?.slice();
        obj[inputs[1] + 'Type'] = info.contactType;
        if (save) {
          info.contactString = this[inputs[1] + 'Form'].value[inputs[1]]?.slice();
          info.contactType = +this[inputs[1] + 'Form'].value[inputs[1] + 'Type'];
        } else {
          this[inputs[1] + 'Form'].patchValue(obj);
        }
        exists = true;
      }
    });

    if (!exists) {
      if (save) {
        const obj = {
          name: null,
          primary: true,
          contactString: this[inputs[1] + 'Form'].value[inputs[1]],
          contactType: +this[inputs[1] + 'Form'].value[inputs[1] + 'Type'],
          contactMethod: inputs[2],
        };
        this.patient.contactInfos.push(obj);
        this.updateContactInfo();
      } else {
        const obj = {};
        obj[inputs[1]] = '';
        obj[inputs[1] + 'Type'] = inputs[3] || null;
        this[inputs[1] + 'Form'].patchValue(obj);
      }
    }
  }

  private updateContactInfo(): void {
    this.patient.contactInfos.forEach((info, index) => {
      if (info.contactMethod === ContactMethod.email && info.contactType === ContactType.work) {
        this.emailForm.patchValue({email: info.contactString?.slice(), emailType: info.contactType});
      }
      if (info.contactMethod === ContactMethod.phone) {
        this.phoneForm.patchValue({phone: info.contactString?.slice(), phoneType: info.contactType});
      }
    });
  }
}
