import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhysicianFormFields } from '../../enums/physician-form-fields.enum';

@Component({
  selector: 'app-new-facility-form',
  templateUrl: './new-facility-form.component.html',
  styleUrls: ['./new-facility-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewFacilityFormComponent {
  newFacilityForm: FormGroup = new FormGroup({
    facilityName: new FormControl('', Validators.required),
    tax: new FormControl(''),
    address: new FormControl(''),
    zipCode: new FormControl('', Validators.required),
    officePhone: new FormControl('', Validators.required),
    officeFax: new FormControl('', Validators.required),
    officeName: new FormControl(''),
    officeEmail: new FormControl('', [Validators.email]),
  });

  @Input() set initName(value: string) {
    this.newFacilityForm.get('facilityName').patchValue(value);
  }

  @Input() set getFocus(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.newFacilityName.nativeElement.select();
      });
    }
  }

  @Output() create: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('newFacilityName') newFacilityName: ElementRef;

  physicianFormFields = PhysicianFormFields;

  constructor() {
  }

  cancelEvent(): void {
    this.newFacilityForm.reset();
    this.cancel.emit();
  }
}
