<div class="content-wrapper">
  <button *ngIf="footerData?.escalateButton?.isVisible"
          mat-button class="primary-btn invert escalate"
          (click)="escalateTask.emit()"
          [disabled]="footerData.escalateButton.isDisabled">
    <mat-icon>warning</mat-icon>{{footerData?.escalateButton.caption}}
  </button>

  <button *ngIf="footerData?.rescheduleButton?.isVisible"
          mat-button class="primary-btn invert reschedule"
          (click)="rescheduleTask.emit()"
          [disabled]="footerData.rescheduleButton.isDisabled">
    <mat-icon>schedule</mat-icon>{{footerData?.rescheduleButton.caption}}
  </button>

  <button *ngIf="footerData?.submitButton?.isVisible"
          mat-button class="primary-btn invert done"
          (click)="submitTask.emit()"
          [disabled]="footerData.submitButton.isDisabled || formData.invalid">
    <mat-icon>done</mat-icon>{{footerData?.submitButton.caption}}
  </button>
</div>
