import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { IResponse } from '../interfaces/response.interface';
import { AbstractRestService } from 'src/app/core/services/abstract-rest.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OktaService extends AbstractRestService {
  oktaConfig: any;

  constructor(protected http: HttpClient) {
    super(http);

    this.oktaConfig = {
      baseUrl: environment.oidc.issuer.split('/oauth2')[0],
      clientId: environment.oidc.clientId,
      redirectUri: environment.oidc.redirectUri,
    }
  }

  forgotPassword(data: any): Observable<any> {
    this.url = this.oktaConfig.baseUrl + `/api/v1/authn/recovery/password`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  verifyEmailFactor(factorId: string, data: any): Observable<any> {
    this.url = this.oktaConfig.baseUrl + `/api/v1/authn/factors/${factorId}/verify`;
    
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  checkActivationToken(data: any): Observable<any> {
    this.url = this.oktaConfig.baseUrl + `/api/v1/authn`;
    
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  resetPassword(data: any): Observable<any> {
    this.url = this.oktaConfig.baseUrl + `/api/v1/authn/credentials/reset_password`;
    
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  checkRecoveryToken(data: any): Observable<any> {
    this.url = this.oktaConfig.baseUrl + `/api/v1/authn/recovery/token`;
    
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
