import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { SupervisorQueueTaskColumns } from '@shared/enums/supervisor-queue-task-columns.enum';
import { enumToArray, setAge, setAgeColor, deepCopy, getMinutesBetweenDates } from '@shared/helpers/utils';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SuperVisorQueueItemModel } from 'src/app/features/supervisor/models/supervisor-queue-item.model';
import { SupervisorQueueService } from 'src/app/features/supervisor/services/supervisor-queue.service';
import { first } from 'rxjs/operators';
import { EscalationType } from 'src/app/features/supervisor/enums/escalation-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { QueueSelectionComponent } from 'src/app/features/supervisor/dialogs/queue-selection/queue-selection.component';
import { ConfigurationService } from '@core/services/configuration.service';

@Component({
  selector: 'app-supervisor-queue-task',
  templateUrl: './task.component.html',
  styleUrls: [
    '../../../../../manager/components/queue/queue.component.scss',
    './task.component.scss',
  ],
})
export class SupervisorQueueTaskComponent implements OnInit {
  setAge = setAge;
  setAgeColor = setAgeColor;

  columnsToDisplay = enumToArray(SupervisorQueueTaskColumns);

  supervisorTasks: SuperVisorQueueItemModel[];
  supervisorTaskDataSource: MatTableDataSource<SuperVisorQueueItemModel>;

  queueId: number;
  isLoading = false;
  maxHoursCount: number;
  minutesInAgeType: number;

  view = {
    showReason: [],
    previousFilterValue: null,
    filterSettings: {
      itemPerPage: 20,
      paginationPageCount: null,
      activePage: 0,
      ageType: 'hours',
      filter: {
        show: {
          age: false,
        },
        value: {
          age: [0, 1],
        },
        initialValue: {
          age: [0, 1],
        },
      },
    },
  };

  @ViewChild('ageSlider') ageSlider;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();

    let shownFilter;

    // 💩
    for (const [key, value] of Object.entries(this.view.filterSettings.filter.show)) {
      if (this.view.filterSettings.filter.show[key]) {
        shownFilter = key;
      }
    }

    if (shownFilter) {
      this.applyFilter(shownFilter);
    }
  }

  constructor(
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private supervisorQueueService: SupervisorQueueService,
  ) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(first())
      .subscribe(params => {
        this.queueId = +params?.id;
        this.getAllItemsInQueue(this.queueId);
      });
  }

  setActivePage(index): void {
    this.view.filterSettings.activePage = index;
    this.paginator.pageIndex = index;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  onChangeRange(rangeSlider): void {
    const rangeType = rangeSlider.replace('Slider', '');

    this[rangeSlider].slider.set(this.view.filterSettings.filter.value[rangeType]);
  }

  openFilter(filter): void {
    this.view.previousFilterValue = deepCopy(this.view.filterSettings.filter.value);

    this.closeAllFilters();

    this.view.filterSettings.filter.show[filter] = true;
  }

  closeFilter(filter): void {
    this.view.filterSettings.filter.show[filter] = false;

    this.view.filterSettings.filter.value = this.view.previousFilterValue;
  }

  closeAllFilters(): void {
    const shownFilters = this.view.filterSettings.filter.show;

    Object.keys(shownFilters).forEach((k) => {
      shownFilters[k] = false;
    });

    this.view.filterSettings.filter.value = deepCopy(this.view.previousFilterValue);
  }

  setPagination(): void {
    this.view.filterSettings.paginationPageCount =
      Math.ceil(this.supervisorTaskDataSource.filteredData.length / this.view.filterSettings.itemPerPage);
  }

  clearFilter(filter): void {
    this.view.filterSettings.filter.value[filter] = this.view.filterSettings.filter.initialValue[filter];

    this.applyFilter(filter);
  }

  applyFilter(filter): void {
    this.supervisorTaskDataSource.filterPredicate = this.customFilterPredicate();

    this.supervisorTaskDataSource.filter = this.view.filterSettings.filter.value as any;

    this.view.filterSettings.filter.show[filter] = false;

    this.setPagination();
  }

  clearAllFilters(): void {
    this.view.filterSettings.filter.value = {
      ...this.view.filterSettings.filter.initialValue,
    };

    this.applyFilter('');
  }

  countAgeDuration(): void {
    this.minutesInAgeType = this.view.filterSettings.ageType === 'days' ? 60 * 24 : 60;

    const maxAgeValue = Math.max(...this.supervisorTaskDataSource.data.map((a) => {
      return Math.floor(getMinutesBetweenDates(a.createDate as any) / this.minutesInAgeType);
    }));

    this.maxHoursCount = maxAgeValue;
    this.view.filterSettings.filter.value.age = [0, maxAgeValue];
    this.view.filterSettings.filter.initialValue.age = [0, maxAgeValue];
  }

  openItem(item): void {
    switch (item.escalationType) {
      case EscalationType.unknown :
        this.changeQueueFor(item);
        break;
      case EscalationType.documentIdentification :
        this.identifyDocument(item);
        break;
      case EscalationType.caseTask :
        this.gotoCase(item);
        break;
    }
  }

  private changeQueueFor(item): void {
    this.showDialogToChangeQueue(item);
  }

  private identifyDocument(item): void {
    const queueId = this.configurationService.config.supervisorQueueId;
    
    this.router.navigateByUrl(`/document-supervisor-identify?id=${item.id}&queueId=${queueId}`);
  }

  private gotoCase(item): void {
    this.router.navigateByUrl('/case?id=' + item.caseId);
  }

  getAllItemsInQueue(queueId: number): void {
    this.isLoading = true;

    this.supervisorQueueService
      .getQueueItems(queueId)
      .subscribe(
        res => {
          this.isLoading = false;

          const items = res.value;

          if (!items?.length) {
            return;
          }

          this.setQueueItems(items);
        },
        error => {
          console.error(error);
          this.isLoading = false;
        });
  }

  private showDialogToChangeQueue(item) {
    this.dialog
      .open(QueueSelectionComponent)
      .afterClosed()
      .subscribe(queueId => {
        if (queueId) { 
          this.router.navigateByUrl(`/manage-queue?id=${queueId}&itemId=${item.id}&supervisorQueueId=${this.queueId}`);
        }
      });
  }

  private setQueueItems(items: SuperVisorQueueItemModel[]): void {
    this.view.showReason = Array(items.length).fill(false);

    this.supervisorTaskDataSource = new MatTableDataSource(items);
    this.supervisorTaskDataSource.sort = this.sort;
    this.supervisorTaskDataSource.paginator = this.paginator;

    this.setPagination();
    this.countAgeDuration();

    this.view.filterSettings.filter.initialValue = {...this.view.filterSettings.filter.value};
  }

  customFilterPredicate(): (data, filter) => boolean {
    const myFilterPredicate = (data, filter): boolean => {
      const ageFilter = filter.age ?
        getMinutesBetweenDates(data.receivedDate) / this.minutesInAgeType >= filter.age[0] &&
        getMinutesBetweenDates(data.receivedDate) / this.minutesInAgeType <= filter.age[1] + 1
        : true;

      return ageFilter;
    };

    return myFilterPredicate;
  }
}
