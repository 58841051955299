import { ICase } from '../../src/app/shared/interfaces/case.interface';

export const casesDataMock: ICase[] = [
  {
    id: 1,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'Open',
    reason: 'Benefits Investigation',
    due: 1604254062780,
    redirect: false
  },
  {
    id: 2,
    task: 'PAP: Follow-up with HCP',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'MetLife',
    status: 'Open',
    reason: 'Pending Payer',
    due: 1604454062780,
    redirect: false
  },
  {
    id: 3,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'Closed',
    reason: 'Benefits Investigation',
    due: 1604654062780,
    redirect: false
  },
  {
    id: 4,
    task: 'PAP: Follow-up with HCP',
    physician: 'Yaa Trengove',
    pharmacy: 'Kroger Pharmacy',
    payer: 'Humana',
    status: 'In Process',
    reason: 'Pending Payer',
    due: 1604854062780,
    redirect: false
  },
  {
    id: 5,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'CVS',
    payer: 'MetLife',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1605054062780,
    redirect: false
  },
  {
    id: 6,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'Humana',
    status: 'In Process',
    reason: 'Pending Payer',
    due: 1605254062780,
    redirect: false
  },
  {
    id: 7,
    task: 'PA: Follow-up with Payer',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'Aetna',
    status: 'Open',
    reason: 'Benefits Investigation',
    due: 1605454062780,
    redirect: false
  },
  {
    id: 9,
    task: 'PAP: Follow-up with HCP',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'MetLife',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1605654062780,
    redirect: false
  },
  {
    id: 10,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'Open',
    reason: 'Benefits Investigation',
    due: 1605854062780,
    redirect: false
  },
  {
    id: 11,
    task: 'PAP: Follow-up with HCP',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'Humana',
    status: 'Open',
    reason: 'Pending Payer',
    due: 1606054062780,
    redirect: false
  },
  {
    id: 12,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'MetLife',
    status: 'Closed',
    reason: 'Pending Patient',
    due: 1606254062780,
    redirect: false
  },
  {
    id: 13,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'Humana',
    status: 'In Process',
    reason: 'Pending Payer',
    due: 1606454062780,
    redirect: false
  },
  {
    id: 14,
    task: 'PA: Follow-up with Payer',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'Aetna',
    status: 'Open',
    reason: 'Benefits Investigation',
    due: 1606654062780,
    redirect: false
  },
  {
    id: 15,
    task: 'PAP: Follow-up with HCP',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'MetLife',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1606854062780,
    redirect: false
  },
  {
    id: 16,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'Closed',
    reason: 'Benefits Investigation',
    due: 1607054062780,
    redirect: false
  },
  {
    id: 17,
    task: 'Welcome Call to the Patient - 1st attempt',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'Humana',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1607254062780,
    redirect: false
  },
  {
    id: 18,
    task: 'PAP: Follow-up with HCP',
    physician: 'Kenji Speziale',
    pharmacy: 'Kroger Pharmacy',
    payer: 'MetLife',
    status: 'Closed',
    reason: 'Pending Payer',
    due: 1607454062780,
    redirect: false
  },
  {
    id: 19,
    task: 'PA: Follow-up with Payer',
    physician: 'Carola Guttuso',
    pharmacy: 'Walgreens',
    payer: 'Aetna',
    status: 'In Process',
    reason: 'Benefits Investigation',
    due: 1607654062780,
    redirect: false
  },
  {
    id: 20,
    task: 'PAP: Follow-up with HCP',
    physician: 'Yaa Trengove',
    pharmacy: 'CVS',
    payer: 'MetLife',
    status: 'In Process',
    reason: 'Pending Patient',
    due: 1607854062780,
    redirect: false
  },
];
