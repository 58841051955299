<div class="select-attachments-container" [ngClass]="{'no-items': value?.length === 0}">
  <div *ngIf="!value?.length && !isHistory" class="no-items-splash">
    <div class="heading">No Attachments Yet</div>

    <div class="description">{{field.name}}</div>
  </div>

  <div *ngIf="isHistory" class="history no-attachments">--</div>

  <div *ngIf="value?.length" class="documents-wrapper">
    <div class="documents-heading">Attachments</div>

    <div class="item" *ngFor="let document of value" (click)="openAttachmentsListDialog()">
      <div class="document-image-container">
        <img [src]="document.image" alt="document_img" />
      </div>
      
      <div class="description">
        <div class="type">{{document.documentType}}</div>
        <div class="name">{{document.name}}</div>
      </div>
    </div>
  </div>

  <button *ngIf="!isHistory" class="primary-btn select-all" (click)="openAttachmentsListDialog()">
    <mat-icon>{{value?.length ? 'edit' : 'add_circle'}}</mat-icon>
    {{value?.length ? 'Edit Selection' : 'Select Attachments'}}
  </button>
</div>
