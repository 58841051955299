<div class="task-wrapper">
  <div class="top-block">
    <b>DUE {{ selectedTask.due | date: "EEE, M/d" }}</b>
    <h2>{{selectedTask.task}}</h2>
    <div class="description">
      <span class="attempt" *ngIf="selectedTask.attemptNumber">attempt {{ selectedTask.attemptNumber + 1 }}</span>
      <p [innerHtml]="interpolatedDescription"></p>
    </div>
    <div class="buttons-block">
      <button class="collapse" (click)="minimizeTask(true)">
        <mat-icon>remove</mat-icon>
      </button>
      <button class="close" (click)="setSelectedTask(null)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="welcome-task-wrapper">
    <div class="task-block" [formGroup]="welcomeForm">
      <!-- <div class="task-item contact-method">
          <h3>Contact Method</h3>
          <div class="call-info">
              <button><mat-icon>call</mat-icon>Call</button>
              <span>555-239-3984</span>
          </div>
      </div> -->
      <div class="task-item contact-outcome">
        <h3>Contact Outcome</h3>
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="contact">
              <mat-button-toggle value="0"> Yes</mat-button-toggle>
              <mat-button-toggle value="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Were you able to contact patient?</span>
        </div>
        <div class="outcome-item">
          <div class="input-item toggle">
<!--            -->
            <mat-button-toggle-group formControlName="successful" [disabled]="welcomeForm.value.contact !== '0'">
              <mat-button-toggle value="0"> Yes</mat-button-toggle>
              <mat-button-toggle value="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Was contact successful?</span>
        </div>
        <div class="outcome-item segmentation">
          <div class="input-item toggle">
<!--            -->
            <mat-button-toggle-group formControlName="segmentation" [disabled]="welcomeForm.value.contact !== '0'">
              <mat-button-toggle value="0"> Naive</mat-button-toggle>
              <mat-button-toggle value="1"> Experienced</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Patient Segmentation</span>
        </div>
      </div>
      <div class="task-item contact-outcome">
        <h3>Welcome Kit</h3>
        <div class="outcome-item">
          <div class="input-item toggle">
<!--            -->
            <mat-button-toggle-group formControlName="kit" [disabled]="welcomeForm.value.contact !== '0'">
              <mat-button-toggle value="0"> Yes</mat-button-toggle>
              <mat-button-toggle value="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Send the patient a Welcome Kit?</span>
        </div>
      </div>
      <div class="task-item outcome-note">
        <h3>
          <mat-icon>assignment</mat-icon>
          Outcome Note
        </h3>
        <textarea matInput formControlName="note"></textarea>
      </div>
    </div>
    <div class="welcome-script" *ngIf="interpolatedWelcomeScript">
      <mat-expansion-panel #script>
        <mat-expansion-panel-header>
          <mat-panel-title> Welcome Call Script</mat-panel-title>
          <mat-panel-description>
            <mat-icon *ngIf="!script.expanded">open_in_full</mat-icon>
            <mat-icon *ngIf="script.expanded">close</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-scrollbar #scrollbar class="custom-scrollbar">
          <p [innerHtml]="interpolatedWelcomeScript">
          </p>
        </ng-scrollbar>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="task-controls">
    <button mat-button class="primary-btn invert reschedule" (click)="rescheduleTask()"
            [disabled]="welcomeForm.value.contact !== '1'">
      <mat-icon>schedule</mat-icon>
      Reschedule
    </button>
    <button mat-button class="primary-btn invert done" (click)="submitTask()" [disabled]="
                +welcomeForm.value.contact === 1 || welcomeForm.value.successful === null || welcomeForm.value.kit === null || welcomeForm.value.segmentation === null
            ">
      <mat-icon>done</mat-icon>
      Submit
    </button>
  </div>
</div>
