<form [formGroup]="prescriptionForm"
      novalidate
      (ngSubmit)="submitHandler()"
      (keydown.enter)="$event.preventDefault()"
      class="document-sidebar-form"
>
  <div class="aside-wrapper">
    <h2>{{state.config.tabDisplayName}}</h2>

    <div *ngIf="state.config.fieldsConfig.isOptionChecked.isVisible"
         class="form-row">
      <div class="input-item select"
           [ngClass]="{
          'input-error' : isInvalid(prescriptionForm.controls['isOptionChecked']),
          'input-success' : isValid(prescriptionForm.controls['isOptionChecked'])
        }">
        <label>{{ state.config.fieldsConfig.isOptionChecked.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="isOptionChecked"
                      name="isOptionChecked"
                      placeholder="Select"
                      id="isOptionChecked"
                      disableOptionCentering
                      panelClass="dropdown-item">
            <mat-option [value]="undefined">--</mat-option>
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row full-row"
         *ngIf="state.config.fieldsConfig.dispenseAsWritten.isVisible">
      <div class="input-item checkbox">
        <mat-checkbox name="dispenseAsWritten"
                      formControlName="dispenseAsWritten">
          {{ state.config.fieldsConfig.dispenseAsWritten.displayName }}
        </mat-checkbox>
      </div>
    </div>

    <div class="form-row full-row"
         *ngIf="state.config.fieldsConfig.medication.isVisible">
      <div class="input-item select" [ngClass]="{
          'input-error' : isInvalid(prescriptionForm.controls['medication']),
          'input-success' : isValid(prescriptionForm.controls['medication'])
        }">
        <label>{{ state.config.fieldsConfig.medication.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="medication" name="medication" placeholder="Select" id="medication"
                      disableOptionCentering panelClass="dropdown-item" #medication>
            <mat-option>--</mat-option>
            <mat-option *ngFor="let product of products" [value]="product.id">
              <b>{{product.name}}</b>&nbsp;<span>{{product.ndc}} </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.dosage.isVisible" class="form-row">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(prescriptionForm.controls['dosage']),
          'input-success' : isValid(prescriptionForm.controls['dosage'])
        }">
        <label>{{ state.config.fieldsConfig.dosage.displayName }}</label>
        <input [readonly]="state.config.fieldsConfig.dosage.isReadOnly" name="dosage" id="dosage" formControlName="dosage"/>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.quantity.isVisible" class="form-row">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(prescriptionForm.controls['quantity']),
          'input-success' : isValid(prescriptionForm.controls['quantity'])
        }">
        <label for="quantity">{{ state.config.fieldsConfig.quantity.displayName }}</label>
        <input [readonly]="state.config.fieldsConfig.quantity.isReadOnly" name="quantity" id="quantity" formControlName="quantity"/>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.signature.isVisible" class="form-row">
      <div class="input-item select" [ngClass]="{
          'input-error' : isInvalid(prescriptionForm.controls['signature']),
          'input-success' : isValid(prescriptionForm.controls['signature'])
        }">
        <label>{{ state.config.fieldsConfig.signature.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="signature" name="signature" placeholder="Select" id="signature"
                      (valueChange)="checkValidations()"
                      disableOptionCentering panelClass="dropdown-item">
            <mat-option>--</mat-option>
            <mat-option [value]="0">Yes</mat-option>
            <mat-option [value]="1">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.physicianSignatureDate.isVisible && prescriptionForm.value.signature === 0" class="form-row date-row">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(prescriptionForm.controls['physicianSignatureDate']),
          'input-success' : isValid(prescriptionForm.controls['physicianSignatureDate'])
        }">
        <label for="physicianSignatureDate">{{ state.config.fieldsConfig.physicianSignatureDate.displayName }}</label>
        <div class="date-input">
          <input matInput
                 [matDatepicker]="physicianSignatureDate"
                 id="physicianSignatureDate"
                 [readonly]="state.config.fieldsConfig.physicianSignatureDate.isReadOnly"
                 formControlName="physicianSignatureDate"
                 placeholder="MM/DD/YY"
                 [max]="disabledDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="physicianSignatureDate" tabindex="-1"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #physicianSignatureDate></mat-datepicker>
        </div>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.refillsNumber.isVisible" class="form-row">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(prescriptionForm.controls['refillsNumber']),
          'input-success' : isValid(prescriptionForm.controls['refillsNumber'])
        }">
        <label for="refillsNumber">{{ state.config.fieldsConfig.refillsNumber.displayName }}</label>
        <input [readonly]="state.config.fieldsConfig.refillsNumber.isReadOnly" name="refillsNumber" id="refillsNumber" formControlName="refillsNumber"/>
      </div>
    </div>
  </div>

  <button *ngIf="state.config.nextTab" type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()" [disabled]="isFormLoading || prescriptionForm.invalid">
    <b>Next:</b> {{ state.config.nextTab.config.tabDisplayName }}
    <mat-icon>east</mat-icon>
  </button>

  <button *ngIf="!state.config.nextTab" type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()" [disabled]="isFormLoading || prescriptionForm.invalid">
    <b>Complete</b>
  </button>
</form>
