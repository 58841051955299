<div class="document-form document-sidebar-form">
  <div class="aside-wrapper">
    <h2>{{state.config.tabDisplayName}}</h2>
    <form class="consent-form" novalidate [formGroup]="consentForm">
      <div class="form-row">
        <div class="input-item select"
             [ngClass]="{
          'input-error' : isInvalid(consentForm.controls['patientSignature']),
          'input-success' : isValid(consentForm.controls['patientSignature'])
        }">
          <label>{{ state.config.fieldsConfig.patientSignature.displayName }}</label>
          <mat-form-field appearance="fill"
                          class="input-medical">
            <mat-select formControlName="patientSignature"
                        name="patientSignature"
                        placeholder="Select"
                        id="patientSignature"
                        disableOptionCentering
                        panelClass="dropdown-item"
                        autofocus>
              <mat-option [value]="undefined">--</mat-option>
              <mat-option *ngFor="let option of binaryOptions"
                          [value]="option.value">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-row"
           *ngIf="state.config.fieldsConfig.patientSignatureDate.isVisible">
        <div class="input-item"
             [ngClass]="{
                'input-error' : isInvalid(consentForm.controls['patientSignatureDate']),
                'input-success' : isValid(consentForm.controls['patientSignatureDate'])
              }">
          <label for="patientSignatureDate">{{ state.config.fieldsConfig.patientSignatureDate.displayName }}</label>
          <div class="date-input">
            <input matInput
                   [matDatepicker]="patientSignatureDate"
                   id="patientSignatureDate"
                   formControlName="patientSignatureDate"
                   placeholder="MM/DD/YY"
                   [max]="disabledDate"
            />
            <mat-datepicker-toggle matSuffix
                                   [for]="patientSignatureDate"
                                   tabindex="-1"
            ></mat-datepicker-toggle>
            <mat-datepicker #patientSignatureDate></mat-datepicker>
          </div>
        </div>
      </div>
    </form>
  </div>

  <button *ngIf="state.config.nextTab"
          type="submit"
          class="next-button next-stack"
          (click)="submitHandler()"
          [disabled]="isFormLoading || consentForm.invalid">
    <b>Next:</b> {{ state.config.nextTab.config.tabDisplayName }}
    <mat-icon>east</mat-icon>
  </button>

  <button *ngIf="!state.config.nextTab"
          type="submit"
          class="next-button next-stack"
          (click)="submitHandler()"
          [disabled]="isFormLoading || consentForm.invalid">
    <b>Complete</b>
  </button>
</div>
