<div class="queue-title-wrap">
  <app-page-breadcrumbs currentPage="Supervisor Tasks"></app-page-breadcrumbs>

  <div class="queue-title">
    <h2>Supervisor</h2>
  </div>
</div>

<table [hidden]="!supervisorTaskDataSource?.data.length"
       mat-table matSort
       [@.disabled]="true"
       [dataSource]="supervisorTaskDataSource"
       matSortActive="age"
       matSortDirection="desc"
       class="supervisor-table"
>
  <ng-container matColumnDef="taskName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Origination</th>
    <td mat-cell *matCellDef="let queue">
      {{ queue.taskName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="escalatedUserName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>From user</th>
    <td mat-cell *matCellDef="let queue">
      {{ queue.escalatedUserName }}
      <span *ngIf="!queue.escalatedUserName" class="empty-value">Auto</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="reason">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Reason</th>
    <td mat-cell *matCellDef="let queue; let i = index" (click)="$event.stopPropagation()">
      <div #textContainer class="reason-text" [ngClass]="{'view-less': !view.showReason[i]}">
        <span #text>{{queue.reason}}</span>
      </div>
      <div *ngIf="text.offsetHeight > textContainer.offsetHeight || view.showReason[i]" class="read-more-label" (click)="view.showReason[i] = !view.showReason[i]">
        {{view.showReason[i] ? 'Read less' : 'Read more'}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="patientName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient</th>
    <td mat-cell *matCellDef="let queue">
      {{ queue.patientName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Case Id</th>
    <td mat-cell *matCellDef="let queue">
      {{ queue.caseId }}
    </td>
  </ng-container>
  <ng-container matColumnDef="createDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Received</th>
    <td mat-cell *matCellDef="let queue">
      <div [style]="queue.queueStatus === 1 ? 'display: flex;align-items: center;' : ''">
        <div *ngIf="queue.queueStatus === 1" class="lock-container">
          <mat-icon class="lock">lock</mat-icon>
          <mat-icon class="default-user">account_circle</mat-icon>
        </div>
        <div class="lock-text">
          <i *ngIf="queue.queueStatus === 1">In Progress</i>
          {{ queue.createDate | date: "E, M/d – hh:mmaaaaa" }}
        </div>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row
      *matRowDef="let queue; columns: columnsToDisplay"
      (click)="openItem(queue)"
      [class.locked]="queue.queueStatus === 1"
  ></tr>
  <tr class="mat-row no-data" *matNoDataRow>
    <td class="mat-cell" colspan="4">No data matching the filter</td>
  </tr>
</table>

<mat-paginator style="display: none" [pageSize]="view.filterSettings.itemPerPage"></mat-paginator>

<app-pagination mat-paginator
                *ngIf="supervisorTaskDataSource?.data.length && !isLoading"
                [style]="view.filterSettings.paginationPageCount > 1 ? 'display: block' : 'display: none'"
                [settings]="view.filterSettings"
                (setActivePageHandler)="setActivePage($event)"
></app-pagination>

<app-empty-queue *ngIf="!supervisorTaskDataSource?.data.length && !isLoading" type="tasks"></app-empty-queue>
