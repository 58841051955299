import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../core/services/abstract-rest.service';
import { Observable, throwError } from 'rxjs';
import { IPatient } from '../../../shared/interfaces/patient.interface';
import { IResponse } from '../../../shared/interfaces/response.interface';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { IBatch } from '../interfaces/batch.interface';
import { IDeletedImage } from '../interfaces/deleted-image.interface';
import { IDocumentUpdateRequest } from '../interfaces/document-update-request.interface';
import { IPatientsRequestData } from '../interfaces/patients-request-data.interface';
import { IUncertainImage } from '../interfaces/uncertain-image.interface';
import { IUnlockDocument } from '../interfaces/unlock-document.interface';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  getQueue(id: number): Observable<IBatch> {
    this.url = this.apiUrl;
    return this.getItems<IBatch>(`Queue/GetNextItemInQueue/${id}`);
  }

  getIdentificationQueueItem(id: string): Observable<IResponse> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`IdentificationQueue/GetDocumentBatch/${id}`);
  }

  getDataEntryQueueItem(id: string): Observable<IResponse> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`DataEntryQueue/GetDocumentBatch/${id}`);
  }

  getPatients(data: IPatientsRequestData): Observable<IPatient[]> {
    this.url = this.apiUrl + `/Patients`;
    return this.postItem<IPatientsRequestData, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  deleteImage(id: number, data: IDeletedImage): Observable<IResponse> {
    this.url = this.apiUrl + `/IdentificationQueue/DeleteDocumentImage/${id}`;
    return this.deleteItem<IDeletedImage, IResponse>(data);
  }

  uncertainImage(id: number, data: IUncertainImage): Observable<IResponse> {
    this.url = this.apiUrl + `/IdentificationQueue/UncertainDocumentImage/${id}`;
    return this.postItem<IUncertainImage, IResponse>(data);
  }

  updateDocument(id: number, data: IDocumentUpdateRequest): Observable<IResponse> {
    this.url = this.apiUrl + `/IdentificationQueue/UpdateDocumentImage/${id}`;
    return this.updateItem<IDocumentUpdateRequest, IResponse>(data);
  }

  setDocumentBatchCompleted(id: number): Observable<IResponse> {
    this.url = this.apiUrl + `/IdentificationQueue/CompleteDocumentBatch/${id}`;

    return this.postItem<any, IResponse>();
  }

  unlockBatch(data: IUnlockDocument): Observable<IResponse> {
    this.url = this.apiUrl + `/Queue/UnlockQueueItems`;
    return this.postItem<IUnlockDocument, IResponse>(data);
  }

  public getDocumentTypes(programId: number) {
    this.url = this.apiUrl + `/IdentificationQueue/DocumentTypes/${programId}`;

    return this.get();
  }
}
