<div class="task-wrapper">
  <div class="top-block">
    <b>DUE {{ selectedTask.due | date: "EEE, M/d" }}</b>
    <h2>{{ selectedTask.task }}</h2>
    <div class="description">
      <span class="attempt" *ngIf="selectedTask.attemptNumber">attempt {{ selectedTask.attemptNumber + 1 }}</span>
      <p>Select the applicable pharmacy, and click submit to triage prescription.</p>
    </div>
    <div class="buttons-block">
      <button class="collapse" (click)="minimizeTask(true)">
        <mat-icon>remove</mat-icon>
      </button>
      <button class="close" (click)="setSelectedTask(null)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task-block" [formGroup]="triageForm">
    <div class="task-item">
      <h3>Specialty Pharmacy</h3>
      <ul *ngIf="!selectedPharmacy">
        <li *ngFor="let pharmacy of pharmacies" (click)="selectPharmacy(pharmacy)">
          <div class="title">
            <h5>
              {{ pharmacy.name }}
            </h5>
            <span *ngIf="pharmacy.recommended"><mat-icon>grade</mat-icon>Recommended</span>
          </div>
          <div class="wrapper">
            <div class="item">
              <h6>Phone</h6>
              <p>{{ pharmacy.phone | phoneNumber | empty }}</p>
            </div>
            <div class="item">
              <h6>Fax</h6>
              <p>{{ pharmacy.fax | phoneNumber | empty }}</p>
            </div>
          </div>
        </li>
      </ul>
      <ul *ngIf="selectedPharmacy" class="selected">
        <li>
          <div class="title">
            <img src="assets/icons/icon-check.svg" alt="check"/>
            <h5>
              {{ selectedPharmacy.name }}
            </h5>
            <button (click)="selectPharmacy(null)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="wrapper">
            <div class="item">
              <h6>Phone</h6>
              <p>{{ selectedPharmacy.phone | phoneNumber | empty }}</p>
            </div>
            <div class="item">
              <h6>Fax</h6>
              <p>{{ selectedPharmacy.fax | phoneNumber | empty }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="task-item outcome-note">
      <h3>
        <mat-icon>assignment</mat-icon>
        Outcome Note
      </h3>
      <textarea matInput formControlName="note"></textarea>
    </div>
  </div>
  <div class="task-controls">
    <button mat-button class="primary-btn invert done" (click)="submitTask()" [disabled]="!selectedPharmacy">
      <mat-icon>done</mat-icon>
      Submit
    </button>
  </div>
</div>
