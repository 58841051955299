import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SupervisorUserColumns } from '../../../../../../shared/enums/supervisor-user-columns.enum';
import { enumToArray } from '../../../../../../shared/helpers/utils';
import { MatDialog } from '@angular/material/dialog';
import { IManagerUser } from '../../../../../../shared/interfaces/manager-user.interface';
import { SupervisorAddUserComponent } from './components/add/add.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-supervisor-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class SupervisorUsersComponent {
  @Input() queueId;

  @Input()
  get users(): IManagerUser[] {
    return this._users;
  }

  set users(users: IManagerUser[]) {
    if (this._users.length !== 0 || users.length !== 0) {
      this._users = users;
      this.dataSource = new MatTableDataSource(this._users);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  @Output() userRemoved = new EventEmitter<number>();
  @Output() usersSaved = new EventEmitter<IManagerUser[]>();

  _users: IManagerUser[] = [];
  columnsToDisplay = enumToArray(SupervisorUserColumns);
  dataSource;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialog) {
  }

  removeUser(id): void {
    this.userRemoved.emit(id);
  }

  addUser(): void {
    const dialogRef = this.dialog.open(SupervisorAddUserComponent, {
      data: {
        queueId: this.queueId,
        addedUsers: this.users,
      },
      panelClass: 'addDialog',
    });
    const sub = dialogRef.componentInstance.usersSaved.subscribe((users) => {
      this.usersSaved.emit(users);
    });
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }
}
