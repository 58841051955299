<div mat-dialog-title>
  <div *ngIf="data.task.due || data.task.updatedBy" class="author-container">
    <div *ngIf="data.task.due" class="author-header">
      <div class="label">{{data.task.outcome === TaskOutcome.repeat ? 'Rescheduled' : 'Completed'}}</div>
      <div class="value">
        {{data.task.due | date: 'MM/dd/yyyy'}} at {{data.task.due | date: 'hh:mma'}}
      </div>
    </div>
    <div *ngIf="completedBy" class="author-header">
      <div class="label">By</div>
      <div class="value">{{completedBy}}</div>
    </div>
  </div>

  <div class="header">
    <h1>{{data.task.task}}</h1>
  </div>

  <div [innerHtml]="description" class="description"></div>

  <button class="close-btn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="dialog-body custom-scroll-default">
    <app-task-body *ngIf="taskFormData"
                   [taskConfig]="taskConfigurations.body"
                   [case]="data.case"
                   [taskForm]="taskFormData"
                   [selectedTask]="data.task"
                   [isHistory]="true"
    ></app-task-body>
  </div>
</div>
