import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormState } from '../../../../configuration/form.state';

@Component({
  selector: 'app-case-missed',
  templateUrl: './missed.component.html',
  styleUrls: ['./missed.component.scss', '../physician/physician.component.scss'],
})
export class MissedComponent implements OnInit {
  @Input() state: FormState;
  @Input() isLoading: boolean;

  @Input() get case(): any {
    return this._case;
  }

  set case(newCase: any) {
    if (!this._case && newCase) {
      this._case = newCase;
    }
  }

  @Output() submitMissedInfo = new EventEmitter();
  @Output() cancelMissedInfo = new EventEmitter();

  private _case;

  public missedInfo;
  public faxForm: FormGroup;


  public ngOnInit(): void {
    this.missedInfo = this.state.allWarnings;

    this.initForm();
  }

  public submit(): void {
    if (this.faxForm.invalid) {
      return;
    }

    this.submitMissedInfo.emit(this.faxForm.controls.faxNumber.value);
  }

  public cancel(): void {
    this.cancelMissedInfo.emit();
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  private initForm(): void {
    this.faxForm = new FormGroup({
      faxNumber: new FormControl('', [Validators.required, Validators.minLength(10)])
    });
  }
}
