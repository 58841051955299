export function except(arr1: any[], arr2: any[], selector?: (e: any) => any) : any[] {
  selector ??= e => e;

  const a = arr1;
  const b = arr2.map(selector);

  return a.filter(el => b.indexOf(selector(el)) === -1);
}

export function intersect(arr1: any[], arr2: any[], selector?: (e: any) => any) : any[] {
  selector ??= e => e;

  const a = arr1;
  const b = arr2.map(selector);

  return a.filter(el => b.indexOf(selector(el)) !== -1);
}

export function groupBy<TKey, TValue>(arr1: TValue[], selector?: (key: TValue) => TKey) : { [key in string | number] : TValue[]; } {
  return arr1?.length ? arr1.reduce((acc, cur) => {
    const key = selector(cur);

    acc[key] ??= [];

    acc[key].push(cur);

    return acc;
  }, {} as any) : {};
}

export function maxBy(arr: any[], selector?: (e: any) => any) : any {
  if(!arr?.length) return undefined;

  selector ??= e => e;

  return arr.reduce((acc, cur) => { return selector(acc) > selector(cur) ? acc : cur; }, arr[0]);
}

export function minBy(arr: any[], selector?: (e: any) => any) : any {
  if(!arr?.length) return undefined;

  selector ??= e => e;

  return arr.reduce((acc, cur) => { return selector(acc) < selector(cur) ? acc : cur; }, arr[0]);
}

export function uniqueBy(arr: any[], selector?: (e: any) => any): any {
  if (!arr?.length) return undefined;

  selector ??= e => e;

  const items = new Set<any>();

  return arr.filter(e => { 
    const item = selector(e);

    if (items.has(item)) return false;

    items.add(item);

    return true;
  });
}

// Can consider to use extension methods : 
//
// Array.prototype['intersect'] = function(other: [], selector?: (e: any) => any) : any[] {  
//  return intersect(this, other, selector)
// } 

// Array.prototype['except'] = function(other: [], selector?: (e: any) => any) : any[] {  
//  return except(this, other, selector)
// }
