import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ConsentMethodNames } from '@shared/enums/consent-method.enum';
import { ConsentTypes, ConsentTypeNames } from '@shared/enums/consent-types.enum';
import { getDateString } from '../../../../../../../../shared/helpers/utils';

@Component({
  selector: 'app-opt-out-form',
  templateUrl: './opt-out-form.component.html',
  styleUrls: ['./opt-out-form.component.scss', '../../enrollment-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptOutFormComponent implements AfterViewInit {
  @Input() type: ConsentTypes;
  @Input() consentMethods: any[];

  @Output() submitted: EventEmitter<object> = new EventEmitter<object>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  renewOptInControl: FormControl = new FormControl('', [Validators.required]);

  ConsentTypes = ConsentTypes;
  ConsentTypeNames = ConsentTypeNames;
  ConsentMethodNames = ConsentMethodNames;

  @ViewChild('consentMethod') consentMethod;

  constructor() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.consentMethod.focus();
    });
  }

  save(): void {
    const consent = {
      consentMethod: +this.renewOptInControl.value,
      effectiveFrom: getDateString(new Date(), ''),
      effectiveTo: null,
    };

    this.submitted.emit(consent);
  }
}
