import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '@core/classes/UnsubscribeOnDestroy';
import { AddressType } from '@shared/enums/address-type.enum';
import { ConsentTypeNames, ConsentTypes } from '@shared/enums/consent-types.enum';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { deepCopy, expiredFile, getContactInfoString, getDateObject } from '@shared/helpers/utils';
import { IAddress } from '../../../../interfaces/address.interface';
import { ICaregiver } from '../../../../interfaces/caregiver.interface';
import { CaseService } from '../../../../services/case.service';
import { AddressHistoryDialogComponent } from '../../../modals/address-history-dialog/address-history-dialog.component';
import { ConfirmAddressDialogComponent } from '../../../modals/confirm-address-dialog/confirm-address-dialog.component';
import { ConfirmCaregiverDialogComponent } from '../../../modals/confirm-caregiver-dialog/confirm-caregiver-dialog.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ConsentMethod } from '@shared/enums/consent-method.enum';

@Component({
  selector: 'app-case-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: [
    '../../../timeline/timeline.component.scss',
    '../benefit/benefit.component.scss',
    '../../../task/components/benefit/benefit.component.scss',
    '../../../task/task.component.scss',
    './enrollment.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrollmentComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
  @Input() case;
  @Input() panel;

  @Output() updateCase: EventEmitter<void> = new EventEmitter<void>();
  @Output() expandPanels: EventEmitter<string> = new EventEmitter<string>();

  AddressType = AddressType;

  consents: any[] = [];
  ConsentTypes = ConsentTypes;
  ConsentTypeNames = ConsentTypeNames;
  consentMethods: any[] = [];

  showAddressForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showCaregiverForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  addresses: IAddress[] = [];
  currentAddresses$: BehaviorSubject<IAddress[]> = new BehaviorSubject<IAddress[]>([]);
  otherAddresses$: BehaviorSubject<IAddress[]> = new BehaviorSubject<IAddress[]>([]);

  caregivers: ICaregiver[] = [];
  activeCaregivers$: BehaviorSubject<ICaregiver[]> = new BehaviorSubject<ICaregiver[]>([]);
  inactiveCaregivers$: BehaviorSubject<ICaregiver[]> = new BehaviorSubject<ICaregiver[]>([]);

  editAddressMode: boolean[] = [];
  editCaregiverMode: boolean[] = [];

  getContactInfoString = getContactInfoString;
  ContactMethod = ContactMethod;

  today = new Date();
  getDate = getDateObject;
  expiredFile = expiredFile;

  constructor(
    private caseService: CaseService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.case?.patient) {
      this.initConsent();
      this.getAddresses(false);
      this.getCaregivers(false);
    }
  }

  ngOnDestroy(): void {
    this.expandPanels.emit('');
  }

  handleAfterExpand(section: string): void {
    this.expandPanels.emit(section);
  }

  clearPanel(section: string): void {
    if (section === this.panel) {
      this.panel = '';
      this.expandPanels.emit('');
    }
  }

  toggleAddressForm(): void {
    this.showAddressForm$.next(!this.showAddressForm$.getValue());
  }

  toggleCaregiverForm(): void {
    this.showCaregiverForm$.next(!this.showCaregiverForm$.getValue());
  }

  cancelEditAddress(address: IAddress, i: number, isCurrent: boolean): void {
    const absIndex = this.addresses.findIndex(el => el.id === address.id);

    if (absIndex >= 0) {
      this[isCurrent ? 'currentAddresses' : 'otherAddresses'][i] = this.addresses[absIndex];
      this.editAddressMode[i + (isCurrent ? 0 : this.currentAddresses$.getValue()?.length)] = false;
    }
  }

  cancelEditCaregiver(address: ICaregiver, i: number, isCurrent: boolean): void {
    const absIndex = this.caregivers.findIndex(el => el.id === address.id);

    if (absIndex >= 0) {
      this[isCurrent ? 'activeCaregivers' : 'inactiveCaregivers'][i] = this.caregivers[absIndex];
      this.editCaregiverMode[i + (isCurrent ? 0 : this.activeCaregivers$.getValue()?.length)] = false;
    }
  }

  saveAddress(data, isInlineForm = true, isEdit = false, editIndex = null): void {
    const address = {
      patientAddress: {
        id: isEdit ? data.id : 0,
        address: {
          streetAddress: isEdit && !isInlineForm ? data.address.streetAddress : data.address,
          addressExtension: '',
          zipCode: isEdit && !isInlineForm ? data.address.zipCode : data.zipCode,
          city: isEdit && !isInlineForm ? data.address.city : data.city,
          state: isEdit && !isInlineForm ? data.address.state : data.state,
        },
        addressType: +data.addressType,
        current: data.current,
        patientId: this.case?.patient?.id,
      }
    };

    forkJoin([
      isEdit ?
        this.caseService.updateAddress(address) :
        this.caseService.createAddress(address)
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getAddresses(isEdit);

        if (!isEdit && isInlineForm) {
          this.toggleAddressForm();
        }

        if (isEdit) {
          this.editAddressMode[editIndex] = false;
        }

        if (+data.addressType === AddressType.mailing) {
          this.updateCase.emit();
        }
      });
  }

  setAddressCurrent(address: IAddress): void {
    this.dialog.open(ConfirmAddressDialogComponent, {
      data: {
        address,
        patient: this.case.patient,
      },
      panelClass: ['selectTypeFormDialog', 'confirm'],
    })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((confirm: boolean) => {
        if (confirm) {
          address.current = !address.current;
          this.saveAddress(address, false, true);
        }
      });
  }

  saveCaregiver(data, isInlineForm = true, isEdit = false, editIndex = null): void {
    const splitName = data.name ? data.name.split(' ') : [];

    const caregiver = {
      id: isEdit ? data.id : 0,
      relationshipId: data.relationshipId,
      patientId: this.case?.patient?.id,
      current: data.current,
      firstName: data.name ? splitName[0] : data.firstName,
      middleName: data.name ? (splitName[2] ? splitName[1] : '') : data.middleName,
      lastName: data.name ? (splitName[2] ? splitName[2] : splitName[1]) : data.lastName,
      contactInfos: data.contactInfos,
    };

    forkJoin([
      isEdit ?
        this.caseService.updateEnrollmentCaregiver({caregiver}) :
        this.caseService.createEnrollmentCaregiver({caregiver})
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (isEdit) {
          this.editCaregiverMode[editIndex] = false;
        }

        const opened = [];
        const merged = this.activeCaregivers$.getValue().concat(this.inactiveCaregivers$.getValue());
        for (let i = 0; i < this.editCaregiverMode.length; i++) {
          if (this.editCaregiverMode[i] && merged[i]) {
            opened.push(merged[i].id);
          }
        }

        this.getCaregivers(isEdit, opened);

        if (!isEdit && isInlineForm) {
          this.toggleCaregiverForm();
        }
      });
  }

  setCaregiverCurrent(caregiver: ICaregiver): void {
    this.dialog.open(ConfirmCaregiverDialogComponent, {
      data: {
        caregiver,
        patient: this.case.patient,
      },
      panelClass: ['selectTypeFormDialog', 'confirm', 'caregiver'],
    })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((confirm: boolean) => {
        if (confirm) {
          caregiver.current = !caregiver.current;
          this.saveCaregiver(caregiver, false, true);
        }
      });
  }

  viewAddressHistory(address: IAddress): void {
    this.dialog.open(AddressHistoryDialogComponent, {
      data: address,
      panelClass: ['selectTypeFormDialog', 'address-history'],
    });
  }

  trackByIndex(index, item): number {
    return index;
  }

  private getConsents(consentTypeIds): void {
    this.caseService.getConsentsByTypes({patientId: this.case.patient.id, consentTypeIds})
      .subscribe((consents) => {
        const consentsGrouped = consentTypeIds.map((cId) => {
          return {
            type: cId,
            consents: []
          };
        });
        consents.forEach((consent) => {
          const existedGroup = consentsGrouped.find(cg => cg.type === consent.consentTypeId);
          if (existedGroup) {
            existedGroup.consents.push(consent);
          } else {
            consentsGrouped.push({
              type: consent.consentTypeId,
              consents: [consent]
            });
          }
        });

        this.consents = consentsGrouped.map((cg) => {
          return {
            type: cg.type,
            dataSource: new MatTableDataSource(cg.consents)
          };
        });
      });
  }

  private initConsent(): void {
    this.caseService.getConsentTypes(this.case.programId)
      .subscribe((consentTypes) => {
        this.getConsents(consentTypes);
      });
    this.caseService.getConsentMethods(this.case.programId)
      .subscribe((consentMethods) => {        
        console.log(consentMethods);
        this.consentMethods = consentMethods;
      });    
  }

  private getAddresses(isAfterEdit: boolean): void {
    this.caseService.getEnrollmentAddresses({take: -1, patientId: this.case.patient.id})
      .subscribe((addresses) => {
        this.addresses = deepCopy(addresses);
        this.currentAddresses$.next(addresses.filter(el => el.current));
        this.otherAddresses$.next(addresses.filter(el => !el.current));

        if (!isAfterEdit) {
          this.editAddressMode = Array(addresses.length).fill(false);
        }
      });
  }

  private getCaregivers(isAfterEdit: boolean, opened?: Array<number>): void {
    this.caseService.getEnrollmentCaregivers({take: -1, patientId: this.case.patient.id})
      .subscribe((caregivers) => {
        this.caregivers = deepCopy(caregivers);

        this.activeCaregivers$.next(caregivers.filter(el => el.current));
        this.inactiveCaregivers$.next(caregivers.filter(el => !el.current));

        if (!isAfterEdit) {
          this.editCaregiverMode = Array(caregivers.length).fill(false);
        }

        if (opened?.length) {
          const merged = this.activeCaregivers$.getValue().concat(this.inactiveCaregivers$.getValue());
          for (let i = 0; i < this.editCaregiverMode.length; i++) {
            if (merged[i]) {
              this.editCaregiverMode[i] = opened.includes(merged[i].id);
            }
          }
        }
      });
  }
}
