<div mat-dialog-title class="mat-dialog-title">
  <h1><img src="../../../../../../../../../assets/icons/icon-warning.svg" alt="delete"/>
    Delete Task
  </h1>
  <button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <h2>
    Are you sure you want to delete the following task from this queue?
  </h2>
  <div class="dialog-body">
    <div class="delete-content-item">
      <h5>Phase</h5>
      <p>{{ data.task.phase }}</p>
    </div>
    <div class="delete-content-item">
      <h5>Task</h5>
      <p>{{ data.task.name }}</p>
    </div>
    <div class="delete-content-item">
      <h5>Description</h5>
      <p [innerHtml]="data.task.description"></p>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="deleteTask(data.task.id)">
    Delete
  </button>
</div>
