<div mat-dialog-title>
  <h1><img src="assets/icons/icon-warning.svg" alt="warning"/> No Next Stack</h1>
  <button mat-dialog-close routerLink="/">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="dialog-content">
  <p>
    Whoops! Looks like there's <b>no next stack</b> in your queue. However, <b>this could change quickly</b>. You
    can either:
  </p>
  <div class="dialog-body">
    <ul>
      <li><span>1.</span>Try again to see if the queue has refreshed.</li>
      <li><span>2.</span>Go back to your dashboard and work a different queue.</li>
    </ul>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button class="back" mat-dialog-close routerLink="/">
    <img src="assets/icons/icon-arrow-back.svg" alt="Back"/>Back to Dashboard
  </button>
  <button mat-button class="next" mat-dialog-close (click)="updateBatch()">
    <mat-icon>refresh</mat-icon>
    Try Next Stack Again
  </button>
</div>
