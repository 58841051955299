<div mat-dialog-title>
  <div class="header">
    <h1>Address History</h1>
  </div>
  <button class="close-btn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="dialog-body">
    <table mat-table [dataSource]="addressHistoryDataSource">
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let item; let i = index">
          {{item.address.streetAddress | empty}}
        </td>
      </ng-container>
      <ng-container matColumnDef="cityState">
        <th mat-header-cell *matHeaderCellDef>City, Sate</th>
        <td mat-cell *matCellDef="let item; let i = index">
          {{ item.address.city | empty }}, {{ item.address.state | empty }}
        </td>
      </ng-container>
      <ng-container matColumnDef="zipCode">
        <th mat-header-cell *matHeaderCellDef>Zip</th>
        <td mat-cell *matCellDef="let item; let i = index">
          {{ item.address.zipCode | empty }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createDate">
        <th mat-header-cell *matHeaderCellDef>Date Added</th>
        <td mat-cell *matCellDef="let item; let i = index">
          {{ item.createDate ? (getDate(item.createDate, '') | date: 'M/d/yyyy') : 'N/A' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let item; let i = index; columns: columnsToDisplay"></tr>
    </table>
  </div>
</div>
