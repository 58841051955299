<div class="task-wrapper">
  <div class="task-auth-title">
    <h2>Authorization</h2>
  </div>
  <div *ngIf="authorizationsExists === true" class="labels-wrapper">
    <div class="status-label" *ngIf="copayProgress"
         [class.pending]="copayProgress === AuthorizationStatus.pending"
         [class.denied]="copayProgress === AuthorizationStatus.expired"
         [class.ready]="copayProgress === AuthorizationStatus.approved"
         [class.denied]="copayProgress === AuthorizationStatus.denied">
      Copay Authorizations
    </div>

    <div class="status-label" *ngIf="priorProgress"
         [class.pending]="priorProgress === AuthorizationStatus.pending"
         [class.denied]="priorProgress === AuthorizationStatus.expired"
         [class.ready]="priorProgress === AuthorizationStatus.approved"
         [class.denied]="priorProgress === AuthorizationStatus.denied">
      Prior Authorizations
    </div>

    <div class="status-label" *ngIf="papProgress"
         [class.pending]="papProgress === AuthorizationStatus.pending"
         [class.denied]="papProgress === AuthorizationStatus.expired"
         [class.ready]="papProgress === AuthorizationStatus.approved"
         [class.denied]="papProgress === AuthorizationStatus.denied">
      Patient Assistance Program
    </div>
  </div>
</div>

<div *ngIf="authorizationsExists === false" class="no-data-splash">
  <div class="splash-container">
    <img src="assets/icons/icon-scheduler.svg" alt="no-authorization" />

    <div class="splash-header">
      No Authorizations
    </div>

    <div class="splash-description">
      This case doesn't have any authorizations yet. <br>
      Copay, Prior Authorization, and Patient Assistance Program statuses will show here once completed.
    </div>
  </div>
</div>

<div *ngIf="authorizationsExists === true" class="case-expansion-panel-wrapper">

  <!-- COPAY -->

  <mat-expansion-panel *ngIf="copayDataSource.data?.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="header">
          Copay
        </div>
        <div class="status-label"
             [class.pending]="copayProgress === AuthorizationStatus.pending"
             [class.denied]="copayProgress === AuthorizationStatus.expired"
             [class.ready]="copayProgress === AuthorizationStatus.approved"
             [class.denied]="copayProgress === AuthorizationStatus.denied"
        >
          <ng-container *ngIf="copayProgress === AuthorizationStatus.pending">Pending</ng-container>
          <ng-container *ngIf="copayProgress === AuthorizationStatus.approved">Approved</ng-container>
          <ng-container *ngIf="copayProgress === AuthorizationStatus.denied">Denied</ng-container>
          <ng-container *ngIf="copayProgress === AuthorizationStatus.expired">Expired</ng-container>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="collapse-section">
      <div class="collapse-item">
        <div class="empty-state" *ngIf="!copayDataSource.data.length">
          No copay yet.
        </div>

        <table class="copay-table" *ngIf="copayDataSource?.data.length" mat-table [dataSource]="copayDataSource">
          <ng-container matColumnDef="entity">
            <th mat-header-cell *matHeaderCellDef>Entity</th>
            <td mat-cell *matCellDef="let item; let i = index">
              {{item.copayEntity.name}}
            </td>
          </ng-container>
          <ng-container matColumnDef="outcome">
            <th mat-header-cell *matHeaderCellDef>Outcome</th>
            <td class="text-left" mat-cell *matCellDef="let item; let i = index">
              <span *ngIf="item.parentAuthorizationId" class="appeal">Appeal:</span>

              <ng-container *ngIf="item.status === AuthorizationStatus.pending && isPeriodInRange(item)">
                Pending
              </ng-container>
              <ng-container *ngIf="item.status === AuthorizationStatus.approved && isPeriodInRange(item)">
                Approved
              </ng-container>
              <ng-container *ngIf="item.status === AuthorizationStatus.denied && isPeriodInRange(item)">
                Denied
              </ng-container>
              <ng-container *ngIf="!isPeriodInRange(item)">
                Expired
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="dateReceived">
            <th mat-header-cell *matHeaderCellDef>Effective Start</th>
            <td mat-cell *matCellDef="let item; let i = index">
              <span *ngIf="item.startDate">{{item.startDate | date: 'M/d/yyyy'}}</span>
              <span class="empty" *ngIf="!item.startDate">N/A</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="effectiveEndDate">
            <th mat-header-cell *matHeaderCellDef>Effective End</th>
            <td mat-cell *matCellDef="let item; let i = index">
              <div (click)="editEffectiveEndCopay(i)" *ngIf="!copayEditEffectiveEnd[i]">
                <span *ngIf="item.endDate">{{item.endDate | date: 'M/d/yyyy'}}</span>
                <span class="empty" *ngIf="!item.endDate">N/A</span>
                <mat-icon *ngIf="!copayEditEffectiveEnd[i]" class="edit-cell-icon">edit</mat-icon>
              </div>
              <div class="controls default-form" *ngIf="copayEditEffectiveEnd[i]">
                <div class="input-item" [ngClass]="{'input-success': copayEditEffectiveEndInput[i]}">
                  <div class="date-input">
                    <input matInput
                           [(ngModel)]="copayEditEffectiveEndInput[i]"
                           [matDatepicker]="copayEffectiveEndDatepicker"
                           [min]="item.startDate"
                           placeholder="MM/DD/YY"
                           (focus)="copayEffectiveEndDatepicker.open()"
                           (click)="copayEffectiveEndDatepicker.open()"
                           (keydown.enter)="saveCopayEffectiveEndDate(i)"
                           (keydown.escape)="copayEditEffectiveEnd[i] = false"
                           id="copayEffectiveEndInput_{{i}}"
                    />
                    <mat-datepicker-toggle matSuffix [for]="copayEffectiveEndDatepicker" tabindex="-1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #copayEffectiveEndDatepicker></mat-datepicker>
                  </div>
                </div>
                <button class="save-btn" (click)="saveCopayEffectiveEndDate(i)">Save</button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="referenceNumber">
            <th mat-header-cell *matHeaderCellDef>Number</th>
            <td mat-cell *matCellDef="let item; let i = index">
              {{item.number}}
            </td>
          </ng-container>
          <ng-container matColumnDef="copayType">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let item; let i = index"> {{item.copayType.name}} </td>
          </ng-container>
          <ng-container matColumnDef="balanceStart">
            <th mat-header-cell *matHeaderCellDef>Beginning Bal.</th>
            <td class="text-right" mat-cell *matCellDef="let item; let i = index">
              {{item.beginningBalance | currency}}
            </td>
          </ng-container>
          <ng-container matColumnDef="balanceUsed">
            <th mat-header-cell *matHeaderCellDef>Amt. Used</th>
            <td class="text-right" mat-cell *matCellDef="let item; let i = index">
              <div (click)="editBalanceUsed(i)" *ngIf="!copayBalanceUsed[i]">
                {{item.amountUsed | currency}}
                <mat-icon *ngIf="!copayBalanceUsed[i]" class="edit-cell-icon">edit</mat-icon>
              </div>
              <div class="controls default-form" *ngIf="copayBalanceUsed[i]">
                <div class="input-item" [ngClass]="{'input-success': copayBalanceUsedInput[i]}">
                  <input matInput
                         [(ngModel)]="copayBalanceUsedInput[i]"
                         type="number"
                         (keydown.enter)="saveBalanceUsed(i)"
                         (keydown.escape)="copayBalanceUsed[i] = false"
                         name="copayBalanceUsed"
                         id="balanceUsedInput_{{i}}"
                  />
                </div>
                <button class="save-btn" (click)="saveBalanceUsed(i)">Save</button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="balanceRemaining">
            <th mat-header-cell *matHeaderCellDef>Remaining Bal.</th>
            <td class="text-right" mat-cell *matCellDef="let item; let i = index">
              {{(item.beginningBalance - item.amountUsed) | currency}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="copayColumnsToDisplay"></tr>
          <tr [class.current]="i === 0" mat-row *matRowDef="let item; let i = index; columns: copayColumnsToDisplay"></tr>
        </table>
      </div>
    </div>
  </mat-expansion-panel>

  <!-- PRIOR AUTHORIZATION -->

  <mat-expansion-panel *ngIf="priorDataSource.data?.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="header">
          Prior Authorization (PA)
        </div>
        <div class="status-label"
             [class.pending]="priorProgress === AuthorizationStatus.pending"
             [class.denied]="priorProgress === AuthorizationStatus.expired"
             [class.ready]="priorProgress === AuthorizationStatus.approved"
             [class.denied]="priorProgress === AuthorizationStatus.denied"
        >
          <ng-container *ngIf="priorProgress === AuthorizationStatus.pending">Pending</ng-container>
          <ng-container *ngIf="priorProgress === AuthorizationStatus.approved">Approved</ng-container>
          <ng-container *ngIf="priorProgress === AuthorizationStatus.denied">Denied</ng-container>
          <ng-container *ngIf="priorProgress === AuthorizationStatus.expired">Expired</ng-container>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="collapse-section">
      <div class="collapse-item">
        <div class="empty-state" *ngIf="!priorDataSource.data.length">
          No prior authorization yet.
        </div>

        <table class="prior-table" *ngIf="priorDataSource?.data.length" mat-table [dataSource]="priorDataSource">
          <ng-container matColumnDef="entity">
            <th mat-header-cell *matHeaderCellDef>Entity</th>
            <td mat-cell *matCellDef="let item; let i = index">
              {{item.insurance.planName}}
            </td>
          </ng-container>
          <ng-container matColumnDef="outcome">
            <th mat-header-cell *matHeaderCellDef>Outcome</th>
            <td class="text-left" mat-cell *matCellDef="let item; let i = index">
              <span *ngIf="item.parentAuthorizationId" class="appeal">Appeal:</span>

              <ng-container *ngIf="item.status === AuthorizationStatus.pending && isPeriodInRange(item)">
                Pending
              </ng-container>
              <ng-container *ngIf="item.status === AuthorizationStatus.approved && isPeriodInRange(item)">
                Approved
              </ng-container>
              <ng-container *ngIf="item.status === AuthorizationStatus.denied && isPeriodInRange(item)">
                Denied
              </ng-container>
              <ng-container *ngIf="!isPeriodInRange(item)">
                Expired
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="dateReceived">
            <th mat-header-cell *matHeaderCellDef>Effective Start</th>
            <td mat-cell *matCellDef="let item; let i = index">
              <span *ngIf="item.startDate">{{item.startDate | date: 'M/d/yyyy'}}</span>
              <span class="empty" *ngIf="!item.startDate">N/A</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="effectiveEndDate">
            <th mat-header-cell *matHeaderCellDef>Effective End</th>
            <td mat-cell *matCellDef="let item; let i = index">
              <div (click)="editEffectiveEndPrior(i)" *ngIf="!priorEditEffectiveEnd[i]">
                <span *ngIf="item.endDate">{{item.endDate | date: 'M/d/yyyy'}}</span>
                <span class="empty" *ngIf="!item.endDate">N/A</span>
                <mat-icon *ngIf="!priorEditEffectiveEnd[i]" class="edit-cell-icon">edit</mat-icon>
              </div>
              <div class="controls default-form" *ngIf="priorEditEffectiveEnd[i]">
                <div class="input-item" [ngClass]="{'input-success': priorEditEffectiveEndInput[i]}">
                  <div class="date-input">
                    <input matInput
                           [(ngModel)]="priorEditEffectiveEndInput[i]"
                           [matDatepicker]="priorEffectiveEndDatepicker"
                           [min]="item.startDate"
                           placeholder="MM/DD/YY"
                           (focus)="priorEffectiveEndDatepicker.open()"
                           (click)="priorEffectiveEndDatepicker.open()"
                           (keydown.enter)="savePriorEffectiveEndDate(i)"
                           (keydown.escape)="priorEditEffectiveEnd[i] = false"
                           id="priorEffectiveEndInput_{{i}}"
                    />
                    <mat-datepicker-toggle matSuffix [for]="priorEffectiveEndDatepicker" tabindex="-1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #priorEffectiveEndDatepicker></mat-datepicker>
                  </div>
                </div>
                <button class="save-btn" (click)="savePriorEffectiveEndDate(i)">Save</button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="referenceNumber">
            <th mat-header-cell *matHeaderCellDef>Number</th>
            <td mat-cell *matCellDef="let item; let i = index">
              {{item.number}}
            </td>
          </ng-container>
          <ng-container matColumnDef="fillsNumber">
            <th mat-header-cell *matHeaderCellDef>Number of fills</th>
            <td mat-cell *matCellDef="let item; let i = index">
              <div (click)="editFillsNumber(i)" *ngIf="!priorEditFillsNumber[i]">
                {{item.fillsNumber}}
                <span class="empty" *ngIf="!item.fillsNumber">N/A</span>
                <mat-icon *ngIf="!priorEditFillsNumber[i]" class="edit-cell-icon">edit</mat-icon>
              </div>
              <div class="controls default-form" *ngIf="priorEditFillsNumber[i]">
                <div class="input-item" [ngClass]="{'input-success': priorEditFillsNumberInput[i]}">
                  <input matInput
                         [(ngModel)]="priorEditFillsNumberInput[i]"
                         type="number"
                         (keydown.enter)="saveFillsNumber(i)"
                         (keydown.escape)="priorEditFillsNumber[i] = false"
                         name="firstName"
                         id="fillsNumberInput_{{i}}"
                  />
                </div>
                <button class="save-btn" (click)="saveFillsNumber(i)">Save</button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="priorColumnsToDisplay"></tr>
          <tr [class.current]="i === 0" mat-row *matRowDef="let item; let i = index; columns: priorColumnsToDisplay"></tr>
        </table>
      </div>
    </div>
  </mat-expansion-panel>

  <!-- PATIENT ASSISTANCE PROGRAM -->

  <mat-expansion-panel *ngIf="papDataSource.data?.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="header">
          Patient Assistance Program (PAP)
        </div>
        <div class="status-label"
             [class.pending]="papProgress === AuthorizationStatus.pending"
             [class.denied]="papProgress === AuthorizationStatus.expired"
             [class.ready]="papProgress === AuthorizationStatus.approved"
             [class.denied]="papProgress === AuthorizationStatus.denied"
        >
          <ng-container *ngIf="papProgress === AuthorizationStatus.pending">Pending</ng-container>
          <ng-container *ngIf="papProgress === AuthorizationStatus.approved">Approved</ng-container>
          <ng-container *ngIf="papProgress === AuthorizationStatus.denied">Denied</ng-container>
          <ng-container *ngIf="papProgress === AuthorizationStatus.expired">Expired</ng-container>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="collapse-section">
      <div class="collapse-item">
        <div class="empty-state" *ngIf="!papDataSource.data.length">
          No Patient Assistance Program yet.
        </div>

        <table class="pap-table" *ngIf="papDataSource?.data.length" mat-table [dataSource]="papDataSource">
          <ng-container matColumnDef="outcome">
            <th mat-header-cell *matHeaderCellDef>Outcome</th>
            <td class="text-left" mat-cell *matCellDef="let item; let i = index">
              <span *ngIf="item.parentAuthorizationId" class="appeal">Appeal:</span>
              <ng-container *ngIf="item.status === AuthorizationStatus.pending && isPeriodInRange(item)">
                Pending
              </ng-container>
              <ng-container *ngIf="item.status === AuthorizationStatus.approved && isPeriodInRange(item)">
                Approved
              </ng-container>
              <ng-container *ngIf="item.status === AuthorizationStatus.denied && isPeriodInRange(item)">
                Denied
              </ng-container>
              <ng-container *ngIf="!isPeriodInRange(item)">
                Expired
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="dateReceived">
            <th mat-header-cell *matHeaderCellDef>Effective Start</th>
            <td mat-cell *matCellDef="let item; let i = index">
              <span *ngIf="item.startDate">{{item.startDate | date: 'M/d/yyyy'}}</span>
              <span class="empty" *ngIf="!item.startDate">N/A</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="effectiveEndDate">
            <th mat-header-cell *matHeaderCellDef>Effective End</th>
            <td mat-cell *matCellDef="let item; let i = index">
              <div (click)="editEffectiveEndPAP(i)" *ngIf="!papEditEffectiveEnd[i]">
                <span *ngIf="item.endDate">{{item.endDate | date: 'M/d/yyyy'}}</span>
                <span class="empty" *ngIf="!item.endDate">N/A</span>
                <mat-icon *ngIf="!papEditEffectiveEnd[i]" class="edit-cell-icon">edit</mat-icon>
              </div>
              <div class="controls default-form" *ngIf="papEditEffectiveEnd[i]">
                <div class="input-item" [ngClass]="{'input-success': papEditEffectiveEndInput[i]}">
                  <div class="date-input">
                    <input matInput
                           [(ngModel)]="papEditEffectiveEndInput[i]"
                           [matDatepicker]="papEffectiveEndDatepicker"
                           [min]="item.startDate"
                           placeholder="MM/DD/YY"
                           (focus)="papEffectiveEndDatepicker.open()"
                           (click)="papEffectiveEndDatepicker.open()"
                           (keydown.enter)="savePAPEffectiveEndDate(i)"
                           (keydown.escape)="papEditEffectiveEnd[i] = false"
                           id="papEffectiveEndInput_{{i}}"
                    />
                    <mat-datepicker-toggle matSuffix [for]="papEffectiveEndDatepicker" tabindex="-1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #papEffectiveEndDatepicker></mat-datepicker>
                  </div>
                </div>
                <button class="save-btn" (click)="savePAPEffectiveEndDate(i)">Save</button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="papColumnsToDisplay"></tr>
          <tr [class.current]="i === 0" mat-row *matRowDef="let item; let i = index; columns: papColumnsToDisplay"></tr>
        </table>
      </div>
    </div>
  </mat-expansion-panel>

</div>
