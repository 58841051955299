import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-manage-empty-queue',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyManageQueueComponent {
  @Input() type: string;
  @Output() addFunction = new EventEmitter();

  addHandler(): void {
    this.addFunction.emit();
  }

  constructor() {
  }
}
