import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncapsulatedRestService } from '@core/services/encapsulated-rest.service';

@Injectable({ providedIn: 'root' })
export class PickListService extends EncapsulatedRestService {
  constructor(protected http: HttpClient){ super(http); }
  
  public getPhases() {
    return this.get<any>('PickList/get-phases');
  }

  public getAllRelationships() {
    const query = { skip: 0, take: 0 };

    return this.postItem<any, any>('PickList/Relationships', query);
  }
}
