import * as signalR from "@microsoft/signalr";
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { getToken } from '@shared/helpers/utils';
import { environment } from 'src/environments/environment';

export class MonitoringBaseService {
  private readonly serverTimeout     = 60 * 1000; /* 60 Sec */
  private readonly handshakeTimeout  = 30 * 1000; /* 30 Sec */
  private readonly keepAliveInterval = 30 * 1000; /* 30 Sec */

  private readonly connection: HubConnection;

  private get hubUrl() {
    return `${environment.resourceServer.signalRUrl}/${this.hub}`;
  } 

  constructor(private hub: string) {
    const options: signalR.IHttpConnectionOptions = {
      accessTokenFactory: () => getToken(),
      timeout: this.handshakeTimeout,
    };

    this.connection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Error)
    .withUrl(this.hubUrl, options)
    .withAutomaticReconnect()
    .build();

    this.connection.serverTimeoutInMilliseconds = this.serverTimeout;
    this.connection.keepAliveIntervalInMilliseconds = this.keepAliveInterval;

    this.connection.start()
    .then(() => { })
    .catch((err: any) => console.error('Error while starting connection: ' + err));
  }

  protected initListeners(listeners) {
    Object.getOwnPropertyNames(listeners).forEach(method => {
      this.connection.on(method, listeners[method]);
    });
  }

  protected invoke(method, ...args: any[]) {
    if(this.connection.state !== HubConnectionState.Connected) return;

    this.connection.invoke(method, ...args);
  }

  onDestroy(): void {
    this.connection.stop();
  }
}
