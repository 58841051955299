<div [ngClass]="{history: isHistory}" [class]="classes">
  <div class="empty-value" *ngIf="isHistory && !anyChecked">--</div>

  <mat-radio-group [(ngModel)]="optionsModel"
                   *ngIf="field.optionTypes[depth] === OptionType.Radio; else checkboxes"
  >
    <div class="option-wrapper" *ngFor="let option of options">
      <mat-radio-button (change)="radioChange(option)"
                        [value]="option"
                        [disabled]="disabled"
                        [disableRipple]="isHistory"
      >
        {{option.name}}
      </mat-radio-button>

      <app-check-box-list *ngIf="option.options?.length && option.isSelected"
                          [field]="field"
                          [depth]="depth+1"
                          [options]="option.options"
                          [isHistory]="isHistory"
      >
      </app-check-box-list>

      <input class="notes" *ngIf="showNoteFor(option, option.isSelected)" [(ngModel)]="option.note" [disabled]="disabled" />
    </div>
  </mat-radio-group>

  <ng-template #checkboxes>
    <div class="option-wrapper" [ngClass]="{history: isHistory}" *ngFor="let option of options">
      <mat-checkbox [(ngModel)]="option.isSelected"
                    (change)="setValue($event, option.options)"
                    [disabled]="disabled"
                    [ngClass]="{'empty-notes': !option.note}"
      >
        {{option.name}}
      </mat-checkbox>

      <app-check-box-list *ngIf="option.options?.length && option.isSelected"
                          [field]="field"
                          [depth]="depth+1"
                          [options]="option.options"
                          [isHistory]="isHistory"
      >
      </app-check-box-list>

      <input class="notes" *ngIf="showNoteFor(option, option.isSelected)" [(ngModel)]="option.note" [disabled]="disabled" />
    </div>
  </ng-template>
</div>
