export enum ContactMethod {
  phone = 0,
  fax = 1,
  email = 2,
  text = 3,
  mail = 4,
  manual = 5,
  supervisor = 6
}

export class ContactMethodName {
  static readonly [ContactMethod.phone] = 'Phone';
  static readonly [ContactMethod.fax] = 'Fax';
  static readonly [ContactMethod.email] = 'Email';
  static readonly [ContactMethod.text] = 'Text';
  static readonly [ContactMethod.mail] = 'Mail';
  static readonly [ContactMethod.manual] = 'Manual';
  static readonly [ContactMethod.supervisor] = 'Supervisor';
}
