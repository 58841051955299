import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interpolateTemplate } from 'src/app/shared/helpers/utils';
import { ContactMethod } from '../../../../../../../../shared/enums/contact-method.enum';

@Component({
  selector: 'app-text-task',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextTaskComponent {
  @Output() setSelectedTask = new EventEmitter();

  patientFirstName;
  patientLastName;
  patientGender;
  patientPhone;
  case;
  body;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.case = data.case;
    this.patientFirstName = data.case.patient.firstName;
    this.patientLastName = data.case.patient.lastName;
    const phone = data.case.patient.contactInfos && data.case.patient.contactInfos.length
      ? data.case.patient.contactInfos.find((contact) => contact.contactMethod === ContactMethod.phone)
      : null;
    this.patientPhone = phone.contactString;
    this.patientGender = data.case.patient.gender;
    this.body = data.taskData ? interpolateTemplate(data.taskData.text, this.getBodyTemplateObject()) : '';
  }

  setSelectedTaskHandler(): void {
    this.setSelectedTask.emit(null);
  }

  getBodyTemplateObject(): any {
    return {
      patientFirstName: this.case.patient.firstName || '',
      pharmacyName: this.case.specialtyPharmacy?.name || '',
      productName: this.case.prescription?.product?.name || ''
    };
  }
}
