import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPatient } from '@shared/interfaces/patient.interface';
import * as DocumentAction from '../../../../../../../../store/document/document.actions';
import { IDocumentUpdateRequest } from '../../../../../../interfaces/document-update-request.interface';
import { DocumentService } from '../../../../../../services/document.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { IDocument } from '../../../../../../interfaces/document.interface';
import { NgScrollbar } from 'ngx-scrollbar';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '@core/classes/UnsubscribeOnDestroy';
import { QueueItemsMonitoringService } from '@core/signalR/interface/queue-items-monitoring.service';
import { RegexPattern } from '@shared/utilities/regex-patterns';
import { MatDialog } from '@angular/material/dialog';
import { SubmitDeleteComponent } from '../../../../../../modals/submit-delete/submit-delete.component';
import { SupervisorDocumentService } from 'src/app/features/intake/services/supervisor-document.service';
import { ConfigurationService } from '@core/services/configuration.service';

@Component({
  selector: 'app-document-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  providers: [QueueItemsMonitoringService]
})
export class DocumentAsideComponent extends UnsubscribeOnDestroy implements OnInit {
  public readonly otherId = 6; // other document type option id;

  documentList: IDocument[];
  documentForm: FormGroup;
  patients: IPatient[] = [];
  isPatientLoading = false;
  isFinished = false;
  selectedPatient = null;
  selectedDocument: number;
  documentBatchId: number;
  suggestedPatients = [];
  focusedPatient = 0;
  isLoading = false;
  queueId: number;
  nameIsInvalid: boolean;
  programId: number;

  isListOverflown = false;
  showShadow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 

  supervisorView;

  get selectedDocumentImage() { 
    return (this.documentList?.length && this.selectedDocument >= 0)
      ? this.documentList[this.selectedDocument]
      : undefined;
  }

  @ViewChild('searchPatientField') searchPatientField: ElementRef;
  @ViewChild('patientList') patientList: ElementRef;
  @ViewChild('nextStack') nextStack: ElementRef;
  @ViewChild('nextButton') nextButton: ElementRef;
  @ViewChild(NgScrollbar) scrollbar: NgScrollbar;
  @ViewChild('patientsListScroll') patientsListScroll;

  constructor(
    private configurationService: ConfigurationService,
    private supervisorDocumentService: SupervisorDocumentService,
    private dialog: MatDialog,
    private queueItemsMonitoringService: QueueItemsMonitoringService,
    private store: Store<any>,
    public documentService: DocumentService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    super();
    this.documentForm = new FormGroup({
      documentType: new FormControl('', Validators.required),
      otherText: new FormControl(''),
      search: new FormControl(''),
    });

    this.documentForm.controls.search.valueChanges
    .subscribe(
      newValue => {
        this.nameIsInvalid = !RegexPattern.Name.Default.test(newValue);
      }
    );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queueId = parseInt(params.queueId);
      this.initialize();
    });
  }

  private initialize(): void {
    this.store.select('document').subscribe((state) => {
      const prevDocumentList = this.documentList;
      this.documentList = state.documentList;
      this.programId = state.programId;
      this.supervisorView = state.supervisorView;

      if (
        this.documentList &&
        this.documentList.length > 0 &&
        (!prevDocumentList || prevDocumentList.length === 0 || prevDocumentList.length !== this.documentList.length)
      ) {
        this.checkIsAllDocumentFinished();
      }

      if (
        (this.selectedDocument && this.selectedDocument !== state.selectedDocument) ||
        (this.selectedDocument === 0 && state.selectedDocument > 0)
      ) {
        this.updateDocument(this.selectedDocument);
      }

      this.selectedDocument = state.selectedDocument;

      this.selectedPatient = this.documentList && this.documentList.length ? this.documentList[this.selectedDocument].patient : null;

      if (this.documentList && this.documentList.length) {
        if (this.documentList[this.selectedDocument].documentType !== this.otherId) {
          this.documentForm.patchValue({
            documentType: this.documentList[this.selectedDocument].documentType,
          });
        } else {
          if (this.documentList[this.selectedDocument].documentType) {
            this.documentForm.patchValue({
              documentType: this.documentList[this.selectedDocument].documentType,
              otherText: this.documentList[this.selectedDocument].otherText,
            });
          }
        }
        this.documentList.forEach((document) => {
          if (document.patient) {
            this.setSuggestedPatients(document.patient);
          }
        });

        this.focusNotFilledData();

        this.documentBatchId = state.documentList[0].documentBatchId;

        this.lockQueueItem(this.documentBatchId);
      }
      else {
        this._isLocked = false;
      }

      if (this.documentList && !this.documentList.length) {
        if (this.supervisorView.isSupervisorView) {
          this.navBackToSupervisorQueue();
        }
        else { 
          this.getNextStack();
        }
      }
    });

    this.subscribeToSearch();
  }

  private subscribeToSearch(): void {
    this.documentForm.get('search').valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        debounceTime(300),
      )
      .subscribe((value: string) => {
        if (!value?.trim().length) return;
        
        this.isPatientLoading = true;

        const data = {
          search: this.documentForm.value.search,
          programId: this.programId,
        };

        this.documentService.getPatients(data)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            (response: IPatient[]) => {
              this.patients = response;

              setTimeout(() => {
                this.detectScrollChanges();
              });
            },
            (error) => throwError(error),
            () => (this.isPatientLoading = false)
          );
        
      });
  }

  private detectScrollChanges(): void {
    if (this.scrollbar) {
      this.scrollbar.scrolled
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(e => {
          const isFullDown = this.patientsListScroll.nativeElement.clientHeight + e.target.scrollTop >=
            this.patientList.nativeElement.scrollHeight;
          this.showShadow$.next(!isFullDown);
        });

      this.isListOverflown = this.patientsListScroll.nativeElement.clientHeight < this.patientList.nativeElement.scrollHeight;
      this.showShadow$.next(this.isListOverflown);
      this.cdr.detectChanges();
    }
  }

  clearSearch(): void {
    this.documentForm.patchValue({
      search: '',
    });
  }

  selectPatient(patient): void {
    this.selectedPatient = {
      id: patient.id,
      name: patient.name ? patient.name : `${patient.firstName} ${patient.lastName}`,
    };

    this.setDocumentOptions();

    this.documentForm.patchValue({
      search: '',
    });

    this.suggestedPatients = [];

    this.documentList.forEach((document) => {
      if (document.patient) {
        this.setSuggestedPatients(document.patient);
      }
    });
  }

  addPatient(): void {
    if (this.documentForm.value.search.trim().length) {
      const patient = { patientId: null, name: this.documentForm.value.search };
      this.selectPatient(patient);
    }
  }

  patientNavigate(event): void {
    if (this.patientList) {
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        event.preventDefault();
        this.focusedPatient === this.patientList.nativeElement.children.length - 1
          ? (this.focusedPatient = 0)
          : (this.focusedPatient = this.focusedPatient + 1);
        this.scrollbar.scrollTo({top: this.focusedPatient * 151});
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.focusedPatient === 0
          ? (this.focusedPatient = this.patientList.nativeElement.children.length - 1)
          : (this.focusedPatient = this.focusedPatient - 1);
        this.scrollbar.scrollTo({top: this.focusedPatient * 151});
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        this.selectPatient(this.patients[this.focusedPatient]);
      }
    }
  }

  switchDocument(): void {
    let untypedDocument;
    untypedDocument = this.documentList.findIndex(
      (document, index) => !document.documentType && !document.patient && index > this.selectedDocument
    );
    if (untypedDocument === -1) {
      untypedDocument = this.documentList.findIndex((document) => !document.documentType && !document.patient);
      untypedDocument = this.selectedDocument + 1 === this.documentList.length ? 0 : this.selectedDocument + 1;
    }

    this.store.dispatch(new DocumentAction.SetSelectedDocument(untypedDocument));
    if (this.documentList[untypedDocument].documentType === null) {
      this.clearForm();
    }
  }

  setSuggestedPatients(newPatient): void {
    const isAlreadySuggested = this.suggestedPatients.find(
      (patient) => patient.id === newPatient.id && patient.name.toLowerCase() === newPatient.name.toLowerCase()
    );
    if (!Boolean(isAlreadySuggested)) {
      this.suggestedPatients.push(newPatient);
    }
  }

  setDocumentOptions(): void {
    const options = {
      documentType: this.documentForm.value.documentType,
      otherText:
        this.documentForm.value.documentType === this.otherId
          ? this.documentForm.value.otherText
          : this.documentForm.patchValue({otherText: ''}),
      patient: this.selectedPatient,
    };

    const newList = this.documentList.map((item, index) => {
      if (index === this.selectedDocument) {
        return {
          ...item,
          documentType: options.documentType,
          otherText: options.otherText,
          patient: options.patient,
        };
      } else {
        return item;
      }
    });

    if (this.selectedPatient) {
      this.setSuggestedPatients(this.selectedPatient);
    }

    this.store.dispatch(new DocumentAction.SetDocumentsList(newList));

    if (
      ((this.documentForm.value.documentType &&
        this.documentList[this.selectedDocument].documentType !== this.otherId) ||
        this.documentForm.value.otherText.trim().length) &&
      this.selectedPatient &&
      this.selectedPatient.name
    ) {
      this.checkIsAllDocumentFinished();

      if (this.isFinished) {
        this.switchDocument();
      }
    }
  }

  focusPatientField(): void {
    this.searchPatientField?.nativeElement.focus();
  }

  focusNextStack(): void {
    this.nextStack?.nativeElement?.focus();
  }

  focusNextButton(): void {
    this.nextButton?.nativeElement.focus();
  }

  focusNotFilledData(): void {
    if (this.documentList[this.selectedDocument].documentType !== null && this.selectedPatient) {
      if (this.nextButton) {
        this.focusNextButton();
      } else if (this.nextStack) {
        this.focusNextStack();
      }
    } else if (this.documentList[this.selectedDocument].documentType !== null) {
      this.focusPatientField();
    }
  }

  clearForm(): void {
    this.documentForm.patchValue({
      otherText: '',
    });
  }

  checkIsAllDocumentFinished(): void {
    if (!this.isFinished) {
      const untypedDocument = this.documentList.find((document) => {
        if (document.documentType === this.otherId) {
          return !document.otherText || document.otherText.trim().length === 0 || !document.patient;
        } else {
          return !document.documentType || !document.patient;
        }
      });
      this.isFinished = !untypedDocument;
      if (!untypedDocument) {
        setTimeout(() => {
          this.focusNextStack();
        }, 0);
      }
    } else {
      this.focusNextStack();
    }
  }

  updateDocuments(): Promise<any> {
    const myPromise = this.getUpdatePromise();

    const promises = this.documentList.map((document) => {
      return myPromise(document);
    });

    return Promise.all(promises);
  }

  updateDocument(index): Promise<any> {
    if (this.documentList) {
      const document = {...this.documentList[index]};
      if (document.documentType || document.documentType === 0 || document.patient) {
        if (document.documentType === this.otherId && document.otherText.trim().length === 0) {
          document.documentType = null;
        }
        const myPromise = this.getUpdatePromise();
        const promise = myPromise({...document});
        return promise;
      }
    }
    return Promise.resolve();
  }

  getUpdatePromise(): (document) => Promise<any> {
    return (document) => {
      const updatedDocumentData: IDocumentUpdateRequest = {
        documentType: document.documentType || document.documentType === 0 ? Number(document.documentType) : null,
        patientId: document.patient && document.patient.id ? document.patient.id : null,
        newPatientName: document.patient?.name || null,
      };

      if (document.documentType === this.otherId) {
        updatedDocumentData.otherDocumentTypeName = document.otherText;
      }

      return new Promise((resolve) =>
        this.documentService.updateDocument(document.id, updatedDocumentData).subscribe(
          (response) => {
            resolve(response);
          },
          (error) => throwError(error)
        )
      );
    };
  }

  async submitSupervisorBatch() {
    const response = await this.updateDocument(this.selectedDocument);

    this.supervisorDocumentService.completeDocumentBatch(this.supervisorView.queueItemId).subscribe(
      (res) => {
        this.navBackToSupervisorQueue();
      },
      (error) => {
        this.isLoading = false;
        throwError(error);
      }
    );
  }

  private navBackToSupervisorQueue() { 
    const queueId = this.configurationService.config.supervisorQueueId;

    this.router.navigateByUrl(`/queue-supervisor-task/${queueId}`);
  }

  deleteCurrentPage() {
    this.dialog
      .open(SubmitDeleteComponent)
      .afterClosed()
      .subscribe(needDelete => {
        if (!needDelete) return;
        
        const documentId = this.documentList[this.selectedDocument].id;

        this.supervisorDocumentService.deleteDocumentImage(documentId)
          .subscribe(
            res => {
              const newDocumentList = this.documentList.filter((document) => document.id !== documentId);
              
              const newSelectedDocument =
                this.selectedDocument + 1 === this.documentList.length ? 0 : this.selectedDocument++;

              this.store.dispatch(new DocumentAction.SetSelectedDocument(newSelectedDocument));
              this.store.dispatch(new DocumentAction.SetDocumentsList(newDocumentList));
            },
            err => { 
              console.error(err);
            }
          );
      }
    );
  }

  getNextStack(): void {
    if (this.isLoading || !this.documentBatchId) return;

    this.isLoading = true;
    
    this.router.navigate(['/document-identify'], { queryParams: { queueId: this.queueId } });

    this.queueItemsMonitoringService.unlockItem();
    this.queueItemsMonitoringService.completeQueueItem(this.documentBatchId, this.queueId);

    this.updateDocument(this.selectedDocument).then((response) => {
      this.documentService.setDocumentBatchCompleted(this.documentBatchId).subscribe(
        (res) => {
          this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(true));
          this.suggestedPatients = [];
          this.isFinished = false;
          this.documentBatchId = undefined;
        },
        (error) => throwError(error),
        () => {
          this.isLoading = false;
        }
      );
    });
  }

  private _isLocked: boolean = false;
  private lockQueueItem(itemId: number) {
    if(this._isLocked) return;

    this.queueItemsMonitoringService.lockItem(itemId, this.queueId);

    this._isLocked = true;
  }
}
