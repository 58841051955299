import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { getExtension } from '@shared/utilities/file.utils';
import { base64ToFile, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-upload-photo-dialog',
  templateUrl: './upload-photo-dialog.component.html',
  styleUrls: ['./upload-photo-dialog.component.scss']
})
export class UploadPhotoDialogComponent {
  @ViewChild('fileInput') fileInput;

  errorMessage:string;
  allowedFiles = ['.jpg', '.png', '.jpeg'];

  imageFile;

  scale = 1;
  transform: ImageTransform = {};

  minZoom = 1;
  maxZoom = 2.5;

  croppedImage;

  constructor(private dialogRef: MatDialogRef<UploadPhotoDialogComponent>) { }

  selectPhoto(file) {
    this.errorMessage = undefined;
    
    if(!file?.name?.length) return;

    const extension = getExtension(file.name);

    if(!this.allowedFiles.includes(extension)){
      this.errorMessage = "Only images can be uploaded.";
      return;
    }

    this.imageFile = file;
  }

  handleError(message){
    this.errorMessage = "Only images can be uploaded.";
  }

  close(){
    this.dialogRef.close(undefined);
  }

  save(){
    const imageFile = base64ToFile(this.croppedImage);

    this.dialogRef.close({
      file: imageFile,
      base64: this.croppedImage,
    });
  }

  imageCropped(event){
    this.croppedImage = event.base64;
  }

  zoomOut() {
    if(this.scale <= this.minZoom) return;

    this.scale -= .05;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  zoomIn() {
    if(this.scale >= this.maxZoom) return;

    this.scale += .05;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  changeZoom(ev){
    this.scale = ev.value;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  openChoosingPhotoDialog(){
    this.fileInput?.nativeElement?.click();
  }
}
