import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root', })
export class FilesService {
  downloadFile(blob, fileName) {
    const link = document.createElement('a');

    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    
    document.body.append(link); // some browser needs the anchor to be in the doc

    link.click();
    link.remove();
    
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };
}
