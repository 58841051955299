<div class="task-wrapper">
  <app-task-header *ngIf="taskConfigurations?.header"
                   [case]="case"
                   [selectedTask]="selectedTask"
                   [headerData]="taskConfigurations.header"
                   (minimizeTask)="minimizeTask($event)"
                   (setSelectedTask)="setSelectedTask($event)"
  ></app-task-header>

  <app-task-body *ngIf="taskFormData"
                 [taskConfig]="taskConfigurations.body"
                 [case]="case"
                 [isHistory]="isHistory"
                 [taskForm]="taskFormData"
                 [selectedTask]="selectedTask"
                 (downloadDocument)="downloadDocument($event)"
                 (previewDocument)="previewDocument($event)"
  ></app-task-body>

  <app-task-footer #taskFooter *ngIf="taskConfigurations?.footer"
                   [footerData]="taskConfigurations.footer"
                   [formData]="taskFormData"
                   (rescheduleTask)="rescheduleTask()"
                   (escalateTask)="escalateToSupervisor()"
                   (submitTask)="submitTask()"
  ></app-task-footer>

  <div class="spinner-wrapper" *ngIf="isLoading">
    <app-loader-spinner [text]="loaderText"></app-loader-spinner>
  </div>
</div>
