import { Component, Input } from '@angular/core';
import { IUserQueue } from '../../interfaces/user-queue.interface';
import { QueueType } from '../../../../../../shared/enums/queue-type.enum';
import { getUrlSuffixFromQueueType } from '../../../../../../shared/helpers/utils';

@Component({
  selector: 'app-manager-dashboard-queues',
  templateUrl: './queues.component.html',
  styleUrls: ['./queues.component.scss'],
})
export class ManagerDashboardQueuesComponent {
  @Input() queueList: IUserQueue[];
  @Input() intakeQueueList: IUserQueue[];

  constructor() {
  }

  getUrlSuffixFromQueueType(queueType: QueueType) {
    return getUrlSuffixFromQueueType(queueType);
  }
}
