import { TabId } from './tab-identifier';

export class DefaultTabOrdering {
  public static readonly [TabId.patient]      = 1;
  public static readonly [TabId.insurance]    = 2;
  public static readonly [TabId.physician]    = 3;
  public static readonly [TabId.clinical]     = 4;
  public static readonly [TabId.prescription] = 5;
  public static readonly [TabId.consent]      = 6;
}
