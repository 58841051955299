<div class="top-block">
  <h2>Select Territories</h2>
  <p>
    Choose which territories that tasks will be performed for. Territories cannot be used in multiple queues. You
    can edit this later
  </p>
</div>

<div class="tasks-wrapper">
  <div class="search-form">
    <div class="input-item search">
      <img src="assets/icons/icon-search.svg" alt="search"/>
      <input matInput type="text" [(ngModel)]="searchValue" placeholder="Search for Territories" #search
             (keydown.enter)="getTerritories()" (keydown.tab)="focusTable($event)" tabindex="1"/>
      <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
              (click)="searchValue = ''">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <button class="primary-btn" (click)="getTerritories()" [disabled]="isLoading">Search</button>
  </div>
  <div class="selected-wrapper">
    <div class="selected-item"
         *ngFor="let id of selectedTerritories; index as i"
         [hidden]="!renderState(id)">
            <span *ngIf="showAll || maxBubblesCount > i">
                <b>{{ renderState(id) }}</b>
                <button (click)="checkState(id)">
                    <mat-icon>close</mat-icon>
                </button>
            </span>
    </div>
    <span class="show-more" *ngIf="!showAll && selectedTerritories.length > maxBubblesCount"
          (click)="showAll = !showAll">Show More</span>
    <span class="show-more" *ngIf="showAll && selectedTerritories.length > maxBubblesCount"
          (click)="showAll = !showAll">Show Less</span>
  </div>
  <table [@.disabled]="true" [hidden]="isLoading" mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let state">{{ state.name }}</td>
    </ng-container>
    <ng-container matColumnDef="isAdded">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="dataSource?.data?.length" class="primary-btn select-all" [class.deselect]="isAllSelected" (click)="selectAll()">
          <ng-container *ngIf="!isAllSelected">
            <mat-icon>add_circle</mat-icon>
            Select All
          </ng-container>
          <ng-container *ngIf="isAllSelected">
            <mat-icon>remove_circle</mat-icon>
            Unselect All
          </ng-container>
        </button>
      </th>
      <td mat-cell *matCellDef="let state">
        <button *ngIf="!selectedTerritories.includes(state.id); else check" tabindex="1">
          <img src="assets/icons/icon-add.svg" alt="add"/>
        </button>
        <ng-template #check>
          <button tabindex="1">
            <img src="assets/icons/icon-check.svg" alt="check"/></button>
        </ng-template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let state; columns: columnsToDisplay; index as i" [class.focused]="i === selectedItem"
        (click)="checkState(state.id)"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching</td>
    </tr>
  </table>

  <mat-paginator style="display: none;" [pageSize]="settings.itemPerPage"></mat-paginator>

  <app-pagination mat-paginator
                  *ngIf="data.length"
                  [style]="settings.paginationPageCount > 1 ? 'display: block' : 'display: none'"
                  [settings]="settings"
                  (setActivePageHandler)="setActivePage($event)"
  ></app-pagination>

  <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
