import { Injectable } from '@angular/core';
import { filterStatusesDataMock } from '../../../../../testing/mocks/filterStatusesDataMock';
import { filterSubstatusesDataMock } from '../../../../../testing/mocks/filterSubstatusesDataMock';
import { AbstractRestService } from '../../../core/services/abstract-rest.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Roles } from '../../../shared/enums/roles.enum';
import { IGetItems } from '../../supervisor/components/creation/interfaces/get-items.interface';
import { IReport } from '../interfaces/reports.interface';
import { IResponse } from 'src/app/shared/interfaces/response.interface';
import { ReportSections } from '../data/report-sections';
import { Store } from '@ngrx/store';
import { IUser } from 'src/app/shared/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class ReportsService extends AbstractRestService {
  reports$: BehaviorSubject<IReport[]> = new BehaviorSubject<IReport[]>([]);
  cleanCopyReport: IReport = null;
  currentReport$: BehaviorSubject<IReport> = new BehaviorSubject<IReport>(JSON.parse(localStorage.getItem('currentReport')));
  reportSaveState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  configReportFormStatus$: BehaviorSubject<string> = new BehaviorSubject<string>('INVALID');
  user: IUser;

  constructor(protected http: HttpClient, private store: Store<any>) {
    super(http);
    this.store.select('user').subscribe((state) => {
      this.user = state;
    });
  }

  getManagerReports(data): Observable<IResponse> {
    this.url = this.apiUrl + '/CaseDetailReports/BrowsePublicReports';
    return this.postItem<any, IResponse>(data);
  }

  getUserReports(data): Observable<IResponse> {
    this.url = this.apiUrl + '/CaseDetailReports/BrowsePrivateReports';
    return this.postItem<any, IResponse>(data);
  }

  createReport(data): Observable<any> {
    this.url = this.apiUrl + '/CaseDetailReports';
    return this.putItem<any, IResponse>(data);
  }

  updateReport(data): Observable<any> {
    this.url = this.apiUrl + '/CaseDetailReports';
    return this.updateItem<any, IResponse>(data);
  }

  removeReport(id: number): Observable<any> {
    this.url = this.apiUrl + '/CaseDetailReports/' + id;
    return this.deleteItem<any, IResponse>({});
  }

  exportReport(data): Observable<Blob> {
    this.url = this.apiUrl + '/CaseDetailReports/ExportReport';
    return this.postDownloadFile<any>(data);
  }

  getReportConfiguration(id: number): Observable<IResponse> {
    this.url = this.apiUrl + '/CaseDetailReports';
    return this.getItems<IResponse>(id);
  }

  getReportData(data): Observable<any> {
    this.url = this.apiUrl + '/CaseDetailReports/BrowseReportItems';
    return this.postItem<any, IResponse>(data);
  }

  //TODO: API contract, uncomment when we will have more than one Program
  // getPrograms(): Observable<any[]> {
  //   this.url = this.apiUrl + `/CaseDetailReports/GetPrograms`;
  //   return this.get();
  // }

  getStatuses(programId: number): Observable<any[]> {
    this.url = this.apiUrl + `/CaseDetailReports/GetStatuses/${programId}`;
    return this.get();
  }

  getTerritories(data: IGetItems): Observable<IResponse> {
    this.url = this.apiUrl + `/Territories`;
    return this.postItem<IGetItems, IResponse>(data);
  }

  getFileToDownload(id: number): Observable<any> {
    // TODO endpoint to get file to download
    // this.url = this.apiUrl + '/Report/Download';
    // return this.postItem<any, IResponse>({id});
    return of({});
  }

  saveLocalstorageReport(report: IReport): void {
    localStorage.setItem('currentReport', JSON.stringify(report));
  }

  removeLocalstorageReport(): void {
    localStorage.removeItem('currentReport');
  }

  convertToIReport(data): IReport {
    const projectionObj = JSON.parse(data.projection);
    const allColumns = ReportSections.map(x => x.columns).reduce((a, b) => a.concat(b));
    let selectedColumns = [];
    if (data.projection.trim() === '{}') {
      selectedColumns = allColumns;
    } else {
      for (const prop in projectionObj) {
        if (projectionObj[prop]) {
          const found = allColumns.find(x => x.key === projectionObj[prop].substring(1));
          if (found) {
            selectedColumns.push(found);
          }
        }
      }
    }

    return {
      id: data.id,
      name: data.name,
      modified: data.lastRunTime,
      description: data.description,
      isUserReport: data.isUserReport,
      filterData: {
        createPeriod: {
          period: data.period,
          browseStartDate: data.browseStartDate,
          browseEndDate: data.browseEndDate
        },
        disposition: data.disposition,
        status: data.caseStatuses.map(x => ({
          id: 0,
          name: x
        })),
        subStatus: data.caseSubStatuses.map(x => ({
          id: 0,
          name: x
        })),
        territory: data.territories.map(x => ({
          id: 0,
          name: x
        })),
      },
      selectedColumns,
    };
  }

  private getReportProjection(report): string {
    const columns = report?.selectedColumns || [];
    const obj = {
      _id: 0,
    };
    columns.forEach(x => {
      obj[x.name] = '$' + x.key;
    });

    return JSON.stringify(obj);
  }

  private getReportFilter(report): any {
    return {
      territories: report?.filterData?.territory?.map(x => x.name) || [],
      caseStatuses: report?.filterData?.status?.map(x => x.name) || [],
      caseSubStatuses: report?.filterData?.subStatus?.map(x => x.name) || [],
      period: report?.filterData?.createPeriod?.period,
      browseStartDate: report?.filterData?.createPeriod?.browseStartDate,
      browseEndDate: report?.filterData?.createPeriod?.browseEndDate,
      disposition: report?.filterData?.disposition,
    };
  }

  convertToReportObj(report): any {
    const filter = this.getReportFilter(report);
    return {
      id: report.id || 0,
      name: report.name?.trim(),
      description: report.description,
      isUserReport: this.user?.role && (this.user.role === Roles.ProgramAdmin || this.user.role === Roles.SystemAdmin) ? report.isUserReport : true,
      projection: this.getReportProjection(report),
      lastRunTime: report.modified,
      active: true,
      ...filter,
    };
  }
}
