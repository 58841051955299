import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-task-progress',
  templateUrl: './task-progress.component.html',
  styleUrls: ['./task-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskProgressComponent {
  /**
  * Percent 0.0 - 1.0
  */
  @Input() progress = 0;
  @Input() customClass = "";

  get color() {
    if(this.progress > 0 && this.progress < .33) return '#e94022';
    if(this.progress > .33 && this.progress < .66) return '#e9be22';
    if(this.progress > .66 && this.progress < .99) return '#4d81d2';
    
    return '#5dcaa0';
  }

  get rotation() {
    return 360 * this.progress + 'deg';
  }

  get style() {
    return `background-image: conic-gradient( ${this.color} ${this.rotation}, #d5d8dd 0deg);`;
  }
}
