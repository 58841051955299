import { Component, Output, EventEmitter, ViewChild, ElementRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { throwError } from 'rxjs';
import { TabState } from '../../../../configuration/tab.state';
import { CustomRequirementsValidator } from '../../../../configuration/validators/custom-requirements.validator';
import { DataEntryFormService } from '../../services/form.service';
import { BaseFormComponent } from '../base-form.component';

@Component({
  selector: 'app-intake-document-clinical-form',
  templateUrl: './clinical.component.html',
  styleUrls: ['../../form.component.scss', '../physician/physician.component.scss', './clinical.component.scss'],
})
export class ClinicalComponent extends BaseFormComponent implements OnInit {
  @Input('state') public state: TabState;
  @Input('isLoading') public isFormLoading: boolean;
  @Input() get case(): any { return this._case; }
           set case(newCase: any) { this.setNewCase(newCase); }

  @Output() submitForm = new EventEmitter();
  @Output() updateCase = new EventEmitter();

  @ViewChild('nextBtn') nextBtn: ElementRef;
  @ViewChild(NgScrollbar) scrollbar: NgScrollbar;
  @ViewChild('clinicalSearchList') clinicalSearchList: ElementRef;

  private _case;

  public clinicalForm: FormGroup;

  public focusedOnSearch = 0;
  public isLoading = false;
  public showResult = false;

  public diagnosticCodes = [];
  public selectedDiagnosis;

  constructor(private dataEntryService: DataEntryFormService) { super(); }

  ngOnInit(): void {
    this.state.SaveState = () => this.SaveState();
    this.clinicalForm = this.state.forms.clinicalForm;

    this.initDiagnosis();
  }

  public static initForms(state: TabState, caseData: any) {
    state.forms.clinicalForm = ClinicalComponent.getClinicalForm(state.config.fieldsConfig);

    ClinicalComponent.populateClinicalForm(state.forms.clinicalForm, caseData, state.autofillData);
  }

  private static getClinicalForm(field) {
    return new FormGroup({
      primaryDiagnosticCode: new FormControl(undefined, [CustomRequirementsValidator(field.primaryDiagnosticCode)]),
    });
  }

  private initDiagnosis(){
    if(this._case.diagnosis) this.selectedDiagnosis = this._case.diagnosis;
    else if(this.clinicalForm.controls.primaryDiagnosticCode.value) this.searchCodeHandler(true);
  }

  private static populateClinicalForm(form, caseData, jsonData) {
    if(!caseData && !jsonData) return;

    const code = caseData?.diagnosis?.code || jsonData?.diagnosisCode;

    const controls = form.controls;

    if(code) controls.primaryDiagnosticCode.patchValue(code);
  }

  private setNewCase(newCase: any) {
    if (this._case || !newCase) return;

    this._case = newCase;
  }

  submitHandler(): void {
    this.SaveState();

    BaseFormComponent.validateForms(this.state);

    if(this.state.isValid) this.goNext();
  }

  private SaveState() {
    BaseFormComponent.validateForms(this.state);

    if(this.state.isValid) {
      this.saveCase();
      
      this.state.isSaved = true;
    }
  }

  private goNext() {
    this.submitForm.emit();
  }

  saveCase(): void {
    if(!this.selectedDiagnosis) return;

    this._case.diagnosisId = this.selectedDiagnosis.id;
    this._case.diagnosis = this.selectedDiagnosis;

    this.updateCase.emit(this._case);

    const data = { caseManagementQueueItem: this.caseManagementQueueItem() };

    this.dataEntryService.updateCase(data).subscribe(
      (response) => { /* Magic 💩 */ },
      (error) => throwError(error)
    );
  }

  private caseManagementQueueItem() {
    return {
      id: this._case.id,
      patientId: this._case.patientId,
      salesRepId: this._case.salesRepId,
      caseInsurances: this._case.caseInsurances,
      physicianId: this._case.physicianId,
      facilityId: this._case.facilityId,
      diagnosisId: this._case.diagnosisId,
      prescriptionId: this._case.prescriptionId,
      programId: this._case.programId 
    };
  }

  selectCode(diagnosis): void {
    this.clinicalForm.patchValue({ primaryDiagnosticCode: diagnosis ? diagnosis.code : '', });
    this.selectedDiagnosis = diagnosis || {id: null, code: '', description: ''};
    this.showResult = false;
    this.diagnosticCodes = [];
  }

  searchCodeHandler(autofill = false): void {
    this.showResult = !autofill;

    const data = {search: this.clinicalForm.value.primaryDiagnosticCode};

    this.dataEntryService.searchDiagnosis(data).subscribe(
      (response) => { 
        this.diagnosticCodes = response;

        if(autofill) {
          const code = this.diagnosticCodes.find(e => e.code === this.clinicalForm.value.primaryDiagnosticCode);
          if(code) this.selectCode(code);
        }
      },
      (error) => throwError(error)
    );
  }

  searchNavigate(event): void {
    if (!this.diagnosticCodes.length) return;

    if (event.key === 'Tab' || event.key === 'ArrowDown') {
      event.preventDefault();
      this.focusedOnSearch === this.clinicalSearchList.nativeElement.children.length - 1
        ? (this.focusedOnSearch = 0)
        : (this.focusedOnSearch = this.focusedOnSearch + 1);
      this.scrollbar.scrollTo({top: this.focusedOnSearch * 151});
    }
    else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.focusedOnSearch === 0
        ? (this.focusedOnSearch = this.clinicalSearchList.nativeElement.children.length - 1)
        : (this.focusedOnSearch = this.focusedOnSearch - 1);
      this.scrollbar.scrollTo({top: this.focusedOnSearch * 151});
    }
    else if (event.key === 'Enter') {
      event.preventDefault();
      this.selectCode(this.diagnosticCodes[this.focusedOnSearch]);
      this.nextBtn.nativeElement.focus();
    }
  }
}
