import { Pipe, PipeTransform } from '@angular/core';
import { ContactMethod } from '../enums/contact-method.enum';
import { ContactType } from '../enums/contact-type.enum';
import { IContactData } from '../interfaces/contact-data.interface';

@Pipe({
  name: 'contactString',
})
export class ContactStringPipe implements PipeTransform {

  transform(value: IContactData[], contactMethod: ContactMethod, contactType: ContactType = ContactType.work): string {
    if (value && value.length) {
      for (const el of value) {
        if (el.contactType === contactType && el.contactMethod === contactMethod) {
          return el.contactString;
        }
      }
    }

    return '';
  }
}
