<div class="content">
  <div class="header">
    You are modifying this queue to fix the following unassigned task:
  </div>

  <div class="body">
    <div class="setting" *ngFor="let setting of settings">
      <div>
        <mat-icon *ngIf="!itemState[setting.selector].isCorrect"
                  class="state-error">error</mat-icon>

        <mat-icon *ngIf="itemState[setting.selector].isCorrect"
                  class="state-correct">check_circle</mat-icon>

        <span class="state-caption">{{setting.displayName}}</span>
      </div>
      <span class="value">{{itemState[setting.selector].name || '--'}}</span>
    </div>

    <div class="actions">
      <button class="cancel"
              (click)="cancel.emit()">Cancel</button>
              
      <button class="save"
              [disabled]="someIsIncorrect()"
              (click)="submit.emit()">Save and Complete</button>
    </div>
  </div>
</div>
