import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
// @ts-ignore
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';

@Component({
  selector: 'app-task-datepicker',
  templateUrl: './task-datepicker.component.html',
  styleUrls: ['./task-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskDatepickerComponent),
      multi: true,
    }
  ],
})
export class TaskDatepickerComponent extends InputControlAccessorDirective {
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
}
