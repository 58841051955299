<div class="input-item">
  <label [for]="id">{{label}}</label>
  <div class="date-input">
    <input
      matInput
      [matDatepicker]="taskInputDatepicker"
      [id]="id"
      [(ngModel)]="value"
      placeholder="MM/DD/YY"
      (focus)="taskInputDatepicker.open()"
      (click)="taskInputDatepicker.open()"
      [required]="required"
      [disabled]="disabled"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="taskInputDatepicker"
      tabindex="-1"
    ></mat-datepicker-toggle>
    <mat-datepicker #taskInputDatepicker></mat-datepicker>
  </div>
</div>
