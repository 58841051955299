import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ContactMethod } from '../../../../../../../../shared/enums/contact-method.enum';
import { deepEqual, getContactInfoObject, noWhitespaceValidator, phoneValidator } from '../../../../../../../../shared/helpers/utils';
import { IContactData } from '../../../../../../../../shared/interfaces/contact-data.interface';
import { ICaregiver } from '../../../../interfaces/caregiver.interface';
import { CaseService } from '../../../../services/case.service';

@Component({
  selector: 'app-new-caregiver-form',
  templateUrl: './new-caregiver-form.component.html',
  styleUrls: ['./new-caregiver-form.component.scss', '../../enrollment-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewCaregiverFormComponent implements OnInit, AfterViewInit {
  @Input() caregiver: ICaregiver;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('caregiverNameField') caregiverNameField;
  @ViewChild('newCaregiverFormElement') newCaregiverFormElement;

  newCaregiverForm: FormGroup;

  contactInfos: IContactData[] = [];
  relationships = [];

  deepEqual = deepEqual;

  initialData;

  constructor(private caseService: CaseService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    const name = this.caregiver
      ? `${this.caregiver.firstName ? this.caregiver.firstName : ''}${this.caregiver.middleName ? ' ' + this.caregiver.middleName : ''}${this.caregiver.lastName ? ' ' + this.caregiver.lastName : ''}`
      : '';

    this.initialData = {
      name,
      id: this.caregiver ? this.caregiver.id : 0,
      relationshipId: this.caregiver ? this.caregiver.relationshipId : null,
      relationshipName: this.caregiver ? this.caregiver.relationshipName : '',
      phone: this.caregiver ? this.getContactValue(this.caregiver.contactInfos, ContactMethod.phone) : '',
      email: this.caregiver ? this.getContactValue(this.caregiver.contactInfos, ContactMethod.email) : '',
      current: this.caregiver ? this.caregiver.current : '',
    };

    this.newCaregiverForm = new FormGroup({
      id: new FormControl(this.initialData.id),
      name: new FormControl(this.initialData.name.slice(), [Validators.required, noWhitespaceValidator]),
      relationshipId: new FormControl(this.initialData.relationshipId, [Validators.required]),
      phone: new FormControl(this.initialData.phone.slice(), [phoneValidator]),
      email: new FormControl(this.initialData.email.slice(), [Validators.email]),
      current: new FormControl(this.initialData.current, [Validators.required]),
    }, this.validator(Validators.required, ['phone', 'email']));

    const data = {
      take: -1
    };
    this.caseService.getRelationships(data).subscribe((response) => {
      this.relationships = response;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.caregiverNameField.nativeElement.focus();
    this.newCaregiverFormElement?.nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest'});
  }

  validator(validator: ValidatorFn, controls: string[] = null): (group: FormGroup) => ValidationErrors | null {
    return (group: FormGroup): ValidationErrors | null => {
      if (!controls) {
        controls = Object.keys(group.controls);
      }

      const hasAtLeastOne = group?.controls && controls
        .some(k => !validator(group.controls[k]));

      if (!hasAtLeastOne) {
        for (const name of controls) {
          group.controls[name].setErrors({required: true});
        }
      } else {
        for (const name of controls) {
          if (group.controls[name].hasError('required')) {
            let errors = group.controls[name].errors;
            // tslint:disable-next-line
            delete errors['required'];
            errors = errors && Object.keys(errors).length > 0 ? errors : null;
            group.controls[name].setErrors(errors);
          }
        }
      }

      return hasAtLeastOne ? null : {
        atLeastOne: true,
      };
    };
  }

  save(): void {
    if (this.contactInfos.length) {
      this.contactInfos.forEach((info) => {
        if (info.contactMethod === ContactMethod.phone) {
          info.contactString = this.newCaregiverForm.get('phone').value;
        }
        if (info.contactMethod === ContactMethod.email) {
          info.contactString = this.newCaregiverForm.get('email').value;
        }
      });
    } else {
      this.contactInfos = [
        getContactInfoObject(ContactMethod.email, this.newCaregiverForm.get('email').value),
        getContactInfoObject(ContactMethod.phone, this.newCaregiverForm.get('phone').value),
      ];
    }

    this.submitted.emit({...this.newCaregiverForm.getRawValue(), contactInfos: this.contactInfos});
  }

  private getContactValue(contactInfos: IContactData[], contactMethod: ContactMethod): string {
    for (const info of contactInfos) {
      if (info.contactMethod === contactMethod) {
        this.contactInfos.push(info);
        return info.contactString;
      }
    }

    this.contactInfos.push(getContactInfoObject(contactMethod, ''));
    return '';
  }
}
