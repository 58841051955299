import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfigModel } from '@core/models/application-config.model';
import { EncapsulatedRestService } from './encapsulated-rest.service';

@Injectable({ providedIn: 'root' })
export class ConfigurationService extends EncapsulatedRestService { 
  private static _config: ApplicationConfigModel;

  private static isLoaded = false;

  constructor(http: HttpClient) { super(http); }

  async load() { 
    if (ConfigurationService.isLoaded) return;

    const cfg: any = await this.get('configurations/app-configuration').toPromise();

    ConfigurationService._config = cfg.value as ApplicationConfigModel;

    Object.freeze(ConfigurationService._config);
  }

  get config() { 
    return ConfigurationService._config;
  }
}
