import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConsentMethodNames } from '@shared/enums/consent-method.enum';
import { ConsentTypeNames, ConsentTypes } from '@shared/enums/consent-types.enum';
import { getDateObject } from '../../../../../../../../shared/helpers/utils';
import { IConsent } from '../../../../interfaces/consent.interface';

@Component({
  selector: 'app-new-opt-in-form',
  templateUrl: './new-opt-in-form.component.html',
  styleUrls: ['./new-opt-in-form.component.scss', '../../enrollment-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewOptInFormComponent implements OnInit, AfterViewInit {
  @Input() type: ConsentTypes;
  @Input() isRenew = false;
  @Input() currentConsent: IConsent;
  @Input() consentMethods: any[];

  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  newOptInForm: FormGroup;

  ConsentTypes = ConsentTypes;
  ConsentTypeNames = ConsentTypeNames;
  ConsentMethodNames = ConsentMethodNames;

  @ViewChild('consentMethod') consentMethod;

  fromDefault = new Date();
  toDefault = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  effectiveFromMin: Date;

  getDateObject = getDateObject;

  constructor() {
  }

  ngOnInit(): void {
    if (this.currentConsent) {
      const currentConsentDate = getDateObject(this.currentConsent.dateReceived);
      const todayDate = new Date();
      this.effectiveFromMin = currentConsentDate.getTime() > todayDate.getTime()
        ? new Date(new Date(currentConsentDate).setDate(currentConsentDate.getDate() + 1)) : todayDate;
      this.fromDefault = this.effectiveFromMin;
      this.toDefault = new Date(new Date(this.fromDefault).setFullYear(this.fromDefault.getFullYear() + 1));
    }

    this.newOptInForm = new FormGroup({
      consentMethod: new FormControl('', [Validators.required]),
      effectiveFrom: new FormControl(this.fromDefault, [Validators.required]),
      effectiveTo: new FormControl(this.toDefault, [Validators.required]),
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.consentMethod.focus();
    });
  }
}
