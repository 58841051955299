<mat-expansion-panel id="PlanInformationPanel" [expanded]="expanded" (afterExpand)="openPanel(0)"
                     (afterCollapse)="closePanel(0)">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="task-indicator" *ngIf="selectedTask">
        <mat-progress-spinner
          [diameter]="32"
          [class]="setIndicatorColor(settings.value)"
          [value]="settings.value"
        >
        </mat-progress-spinner>
        <mat-icon *ngIf="settings.value === 100">check</mat-icon>
        <span *ngIf="settings.value !== 100">{{ settings.value }}%</span>
      </div>
      Plan Information
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="collapse-section" [formGroup]="planInformationForm">
    <div id="PayerInfo"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PayerInfo')"
         class="collapse-item" formGroupName="payerInfo">
      <h3>Payer Info</h3>
      <div class="form-row">
        <div class="input-item select">
          <label>Insurance Status</label>
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="insuranceStatus"
              name="insuranceStatus"
              placeholder="Select"
              id="insuranceStatus"
              disableOptionCentering
              panelClass="dropdown-item"
            >
              <mat-option>--</mat-option>
              <mat-option [value]="0">Current</mat-option>
              <mat-option [value]="1">Status 2</mat-option>
              <mat-option [value]="2">Status 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item select">
          <label>* Payer Type</label>
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="payerType"
              name="payerType"
              placeholder="Select"
              id="payerType"
              disableOptionCentering
              panelClass="dropdown-item"
            >
              <mat-option>--</mat-option>
              <mat-option [value]="0">Plan 1</mat-option>
              <mat-option [value]="1">Type 2</mat-option>
              <mat-option [value]="2">Type 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div id="PlanInfo"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PlanInfo')"
         class="collapse-item" formGroupName="planInfo">
      <h3>Plan Info</h3>
      <div class="form-row">
        <div class="input-item select">
          <label>Plan Type</label>
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="planType"
              name="planType"
              placeholder="Select"
              id="planType"
              disableOptionCentering
              panelClass="dropdown-item"
            >
              <mat-option>--</mat-option>
              <mat-option [value]="0">Commercial</mat-option>
              <mat-option [value]="1">Type 2</mat-option>
              <mat-option [value]="2">Type 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="policyId">Policy ID</label>
          <input name="policyId" id="policyId" formControlName="policyId"/>
        </div>
        <div class="input-item">
          <label for="groupId">Group ID</label>
          <input name="groupId" id="groupId" formControlName="groupId"/>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPolicyHolderSame">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is policy holder same as patient?</span>
        </div>
      </div>
    </div>
    <div id="Coverage"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('Coverage')"
         class="collapse-item" formGroupName="coverage">
      <h3>Coverage</h3>
      <div class="form-row">
        <div class="input-item select">
          <label>Priority</label>
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="priority"
              name="priority"
              placeholder="Select"
              id="priority"
              disableOptionCentering
              panelClass="dropdown-item"
            >
              <mat-option>--</mat-option>
              <mat-option [value]="0">Primary</mat-option>
              <mat-option [value]="1">Priority 2</mat-option>
              <mat-option [value]="2">Priority 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isInNetwork">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is physician in network?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is MD referral required?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item">
          <label for="originalEffectiveDate">Original Effective Date</label>
          <div class="date-input">
            <input
              matInput
              [matDatepicker]="originalEffectiveDate"
              id="originalEffectiveDate"
              formControlName="originalEffectiveDate"
              placeholder="MM/DD/YY"
              (focus)="originalEffectiveDate.open()"
              (click)="originalEffectiveDate.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="originalEffectiveDate"
              tabindex="-1"
            ></mat-datepicker-toggle>
            <mat-datepicker #originalEffectiveDate></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="form-row date-row">
        <div class="input-item">
          <label for="planEffectiveDateStart">Plan Effective Date Range</label>
          <div class="date-input">
            <input
              matInput
              [matDatepicker]="planEffectiveDateStart"
              id="planEffectiveDateStart"
              formControlName="planEffectiveDateStart"
              placeholder="MM/DD/YY"
              [max]="planInformationForm.controls.coverage.value.planEffectiveDateEnd"
              (focus)="planEffectiveDateStart.open()"
              (click)="planEffectiveDateStart.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="planEffectiveDateStart"
              tabindex="-1"
            ></mat-datepicker-toggle>
            <mat-datepicker #planEffectiveDateStart></mat-datepicker>
          </div>
        </div>
        <div class="devider">&mdash;</div>
        <div class="input-item">
          <div class="date-input">
            <input
              matInput
              [matDatepicker]="planEffectiveDateEnd"
              id="planEffectiveDateEnd"
              formControlName="planEffectiveDateEnd"
              placeholder="MM/DD/YY"
              [min]="planInformationForm.controls.coverage.value.planEffectiveDateStart"
              (focus)="planEffectiveDateEnd.open()"
              (click)="planEffectiveDateEnd.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="planEffectiveDateEnd"
              tabindex="-1"
            ></mat-datepicker-toggle>
            <mat-datepicker #planEffectiveDateEnd></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isPreExistingConditionRequired">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is pre-existing condition period required?</span>
        </div>
      </div>
      <div class="form-row date-row">
        <div class="input-item">
          <label for="preExistingDateDateStart">Pre-existing Period Range</label>
          <div class="date-input">
            <input
              matInput
              [matDatepicker]="preExistingDateDateStart"
              id="preExistingDateDateStart"
              formControlName="preExistingDateDateStart"
              placeholder="MM/DD/YY"
              [max]="planInformationForm.controls.coverage.value.preExistingDateDateEnd"
              (focus)="preExistingDateDateStart.open()"
              (click)="preExistingDateDateStart.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="preExistingDateDateStart"
              tabindex="-1"
            ></mat-datepicker-toggle>
            <mat-datepicker #preExistingDateDateStart></mat-datepicker>
          </div>
        </div>
        <div class="devider">&mdash;</div>
        <div class="input-item">
          <div class="date-input">
            <input
              matInput
              [matDatepicker]="preExistingDateDateEnd"
              id="preExistingDateDateEnd"
              formControlName="preExistingDateDateEnd"
              placeholder="MM/DD/YY"
              [min]="planInformationForm.controls.coverage.value.preExistingDateDateStart"
              (focus)="preExistingDateDateEnd.open()"
              (click)="preExistingDateDateEnd.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="preExistingDateDateEnd"
              tabindex="-1"
            ></mat-datepicker-toggle>
            <mat-datepicker #preExistingDateDateEnd></mat-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div id="MedicareAndMedicaid"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('MedicareAndMedicaid')"
         class="collapse-item" formGroupName="medicare">
      <h3>Medicare and Medicaid</h3>
      <h5>Medicare</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isMedicareReplacement">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is this a Medicare Replacement plan?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isMedicareSupplement">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is this Medicare Supplement?</span>
        </div>
      </div>
      <h5>Medicaid</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isMedicaId">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is this a Medicaid Plan?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item select">
          <label>State</label>
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="state"
              name="state"
              placeholder="Select"
              id="state"
              disableOptionCentering
              panelClass="dropdown-item"
            >
              <mat-option>--</mat-option>
              <mat-option [value]="0">NY</mat-option>
              <mat-option [value]="1">State 2</mat-option>
              <mat-option [value]="2">State 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isSpendDown">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Spend down?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="satisfyAmount">Amount to Satisfy</label>
          <input
            name="satisfyAmount"
            id="satisfyAmount"
            formControlName="satisfyAmount"
            mask="0*"
          />
          <span>$</span>
        </div>
        <div class="input-item prefix">
          <label for="metAmount">Amount Met</label>
          <input name="metAmount" id="metAmount" formControlName="metAmount" mask="0*"/>
          <span>$</span>
        </div>
      </div>
    </div>
    <div id="PlanPolicyLimits"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PlanPolicyLimits')"
         class="collapse-item" formGroupName="policyLimits">
      <h3>Policy Limits</h3>
      <div class="form-row">
        <div class="input-item select">
          <label>Individual or Family Plan, or Both?</label>
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="type"
              name="type"
              placeholder="Select"
              id="type"
              disableOptionCentering
              panelClass="dropdown-item"
            >
              <mat-option>--</mat-option>
              <mat-option [value]="0">Individual</mat-option>
              <mat-option [value]="1">Plan 2</mat-option>
              <mat-option [value]="2">Plan 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <h5>Deductible</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualDeductibleAmount">Individual Deductible Amount</label>
          <input
            name="individualDeductibleAmount"
            id="individualDeductibleAmount"
            formControlName="individualDeductibleAmount"
            mask="0*"
          />
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualDeductibleMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has individual deductible been met?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualDeductibleOOP">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is deductible included in OOP?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isCaryNextYear">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Does deductible carry over to next plan year?</span>
        </div>
      </div>
      <h5>Out of Pocket</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualOOP">Individual Out of Pocket</label>
          <input
            name="individualOOP"
            id="individualOOP"
            formControlName="individualOOP"
            mask="0*"
          />
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualOOPMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has individual OOP been met?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualOOPAmount">Individual OOP Amount Met</label>
          <input
            name="individualOOPAmount"
            id="individualOOPAmount"
            formControlName="individualOOPAmount"
            mask="0*"
          />
          <span>$</span>
        </div>
      </div>
      <h5>Lifetime Max</h5>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualLifetimeUnlimited">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Is the individual lifetime maximum unlimited?</span>
        </div>
      </div>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualLifetimeMaximum">Individual Lifetime Maximum</label>
          <input
            name="individualLifetimeMaximum"
            id="individualLifetimeMaximum"
            formControlName="individualLifetimeMaximum"
            mask="0*"
          />
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualLifetimeMaximumMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has the individual lifetime maximum been met?</span>
        </div>
      </div>
      <h5>Annual Cap</h5>
      <div class="form-row">
        <div class="input-item prefix">
          <label for="individualAnnualCap">Individual Annual Cap</label>
          <input
            name="individualAnnualCap"
            id="individualAnnualCap"
            formControlName="individualAnnualCap"
            mask="0*"
          />
          <span>$</span>
        </div>
      </div>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isIndividualAnnualCapMet">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Has individual annual cap been met?</span>
        </div>
      </div>
    </div>
    <div id="PlanExclusions"
         appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('PlanExclusions')"
         class="collapse-item" formGroupName="exclusions">
      <h3>Exclusions</h3>
      <div class="form-row">
        <div class="outcome-item">
          <div class="input-item toggle">
            <mat-button-toggle-group formControlName="isExclusions">
              <mat-button-toggle [value]="0"> Yes</mat-button-toggle>
              <mat-button-toggle [value]="1"> No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span>Are there exclusions?</span>
        </div>
      </div>
      <h3 style="margin-bottom: 16px">
        <mat-icon>assignment</mat-icon>
        Exclusion(s) Notes
      </h3>
      <div class="form-row">
        <div class="input-item">
          <textarea matInput formControlName="exclusionsNote"></textarea>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
