import { Component } from '@angular/core';
import { CaseColumns } from '../../../../enums/case-columns.enum';
import { enumToArray } from '../../../../helpers/utils';
import { SearchOptions } from 'src/app/shared/models/search-options.model';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../modal/modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class SearchFieldComponent {
  searchOptions = new SearchOptions();

  constructor(
    public dialog: MatDialog,
    private router: Router,
  ) {
  }

  caseColumns = enumToArray(CaseColumns);

  openModal(): void {
    if (this.searchOptions.searchValue.length >= 3) {
      this.dialog.open(SearchModalComponent, {
        data: {searchValue: this.searchOptions.searchValue},
        panelClass: 'searchDialog',
      });
    }
  }

  addSearchHandler(): void {
    // this.addSearch.emit();
  }

  goToSearchPage(): void {
    this.router.navigate(['search'], {state: {searchValue: this.searchOptions.searchValue, cases: []}});
  }

  clearSearchHandler(): void {
    this.searchOptions = new SearchOptions();
    // this.clearSearch.emit();
  }

  showCasesHandler(): void {
    // this.showCases.emit({
    //     search: this.searchOptions.searchValue,
    //     status: this.searchOptions.statusValue,
    // });
  }

  openCase(caseId): void {
    this.router.navigate(['/case'], {queryParams: {id: caseId}});
  }
}
