import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-supervisor-task-delete',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class SupervisorDeleteTaskComponent {
  @Output() taskDeleted = new EventEmitter<number>();

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }

  deleteTask(id): void {
    this.taskDeleted.emit(id);
  }
}
