<div id="pagination">
  <ul>
    <li>
      <button class="prev" [disabled]="settings.activePage === 0" (click)="setActivePage(settings.activePage - 1)">
        <img src="../../../../assets/icons/icon-arrow-pagination.svg" alt="prev"/> PREV
      </button>
    </li>
    <ng-container *ngIf="9 > settings.paginationPageCount; else shortPagination">
      <li *ngFor="let page of counter(settings.paginationPageCount); let i = index">
        <button
          (click)="setActivePage(i)"
          [ngClass]="{
              active: i === settings.activePage
          }"
        >
          {{ i + 1 }}
        </button>
      </li>
    </ng-container>
    <ng-template #shortPagination>
      <li>
        <button
          (click)="setActivePage(0)"
          [ngClass]="{
                        active: settings.activePage === 0
                    }"
        >
          1
        </button>
      </li>
      <ng-container *ngIf="0 >= settings.activePage - 3 ">
        <li>
          <button
            (click)="setActivePage(1)"
            [ngClass]="{
                            active: settings.activePage === 1
                        }"
          >
            2
          </button>
        </li>
        <li>
          <button
            (click)="setActivePage(2)"
            [ngClass]="{
                            active: settings.activePage === 2
                        }"
          >
            3
          </button>
        </li>
        <li>
          <button
            (click)="setActivePage(3)"
            [ngClass]="{
                            active: settings.activePage === 3
                        }"
          >
            4
          </button>
        </li>
        <li>
          <button
            (click)="setActivePage(4)"
            [ngClass]="{
                            active: settings.activePage === 4
                        }"
          >
            5
          </button>
        </li>
        <li>...</li>
      </ng-container>
      <ng-container *ngIf="settings.activePage - 3 > 0 && settings.paginationPageCount > settings.activePage + 4">
        <li>...</li>
        <li>
          <button (click)="setActivePage(settings.activePage - 2)">
            {{ settings.activePage - 1 }}
          </button>
        </li>
        <li>
          <button (click)="setActivePage(settings.activePage - 1)">
            {{ settings.activePage }}
          </button>
        </li>
        <li>
          <button (click)="setActivePage(settings.activePage)" class="active">
            {{ settings.activePage + 1 }}
          </button>
        </li>
        <li>
          <button (click)="setActivePage(settings.activePage + 1)">
            {{ settings.activePage + 2 }}
          </button>
        </li>
        <li>
          <button (click)="setActivePage(settings.activePage + 2)">
            {{ settings.activePage + 3 }}
          </button>
        </li>
        <li>...</li>
      </ng-container>
      <ng-container *ngIf="settings.activePage + 4 >= settings.paginationPageCount">
        <li>...</li>
        <li>
          <button
            (click)="setActivePage(settings.paginationPageCount - 5)"
            [ngClass]="{
                            active: settings.paginationPageCount - 5 === settings.activePage
                        }"
          >
            {{ settings.paginationPageCount - 4 }}
          </button>
        </li>
        <li>
          <button
            (click)="setActivePage(settings.paginationPageCount - 4)"
            [ngClass]="{
                            active: settings.paginationPageCount - 4 === settings.activePage
                        }"
          >
            {{ settings.paginationPageCount - 3 }}
          </button>
        </li>
        <li>
          <button
            (click)="setActivePage(settings.paginationPageCount - 3)"
            [ngClass]="{
                            active: settings.paginationPageCount - 3 === settings.activePage
                        }"
          >
            {{ settings.paginationPageCount - 2 }}
          </button>
        </li>
        <li>
          <button
            (click)="setActivePage(settings.paginationPageCount - 2)"
            [ngClass]="{
                            active: settings.paginationPageCount - 2 === settings.activePage
                        }"
          >
            {{ settings.paginationPageCount - 1 }}
          </button>
        </li>
      </ng-container>
      <li>
        <button
          (click)="setActivePage(settings.paginationPageCount - 1)"
          [ngClass]="{
                        active: settings.paginationPageCount - 1 === settings.activePage
                    }"
        >
          {{ settings.paginationPageCount }}
        </button>
      </li>
    </ng-template>
    <li>
      <button
        class="next"
        [disabled]="settings.activePage === settings.paginationPageCount - 1"
        (click)="setActivePage(settings.activePage + 1)"
      >
        NEXT
        <img src="../../../../assets/icons/icon-arrow-pagination.svg" alt="prev"/>
      </button>
    </li>
  </ul>
</div>
