import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { QueueType } from '../../../../../../shared/enums/queue-type.enum';
import { SupervisorAddUserColumns } from '../../../../../../shared/enums/supervisor-add-user-columns.enum';
import { deepCopy, enumToArray } from '../../../../../../shared/helpers/utils';
import { throwError } from 'rxjs';
import { QueueCreateService } from '../../services/creation.service';

@Component({
  selector: 'app-queue-users',
  templateUrl: './users.component.html',
  styleUrls: ['../../creation.component.scss', './users.component.scss'],
})
export class QueueCreationUsersComponent implements OnInit, AfterViewInit {
  @Input() selectedUsers;
  @Output() setSelectedUsersHandler = new EventEmitter();

  searchValue = '';
  columnsToDisplay = enumToArray(SupervisorAddUserColumns);
  showAll = false;
  maxBubblesCount = 20;
  isAllSelected = false;
  data = [];
  originalData = [];
  dataSource;
  isLoading = false;

  settings = {
    itemPerPage: 20,
    paginationPageCount: null,
    activePage: 0,
  };

  selectedItem = null;
  tableFocused = false;

  @ViewChild('search') search: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (this.tableFocused) {
      this.tableNavigate(event);
    }
  }

  constructor(
    public queueCreateService: QueueCreateService,
  ) {
  }

  ngOnInit(): void {
    this.getUsers();
  }

  ngAfterViewInit(): void {
    this.search.nativeElement.focus();
  }

  setActivePage(index): void {
    this.settings.activePage = index;
    this.paginator.pageIndex = index;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  setSelectedUsers(ids): void {
    this.setSelectedUsersHandler.emit(ids);
    if (ids.length === 0) {
      this.isAllSelected = false;
    }
    if (ids.length === this.data.length) {
      this.isAllSelected = true;
    }
  }

  selectAll(): void {
    this.isAllSelected = !this.isAllSelected;
    const IDs = this.data.map((state) => state.id);
    this.isAllSelected ? this.setSelectedUsers(IDs) : this.setSelectedUsers([]);
  }

  checkUser(id): void {
    if (!this.selectedUsers.includes(id)) {
      this.setSelectedUsers([...this.selectedUsers, id]);
    } else {
      const users = this.selectedUsers.filter((checkedId) => checkedId !== id);
      this.setSelectedUsers(users);
    }
  }

  getUserName(id): string {
    return this.originalData.find((el) => el.id === id)?.userName;
  }

  getUsers(): void {
    const data = { take: 0, search: this.searchValue, queueType: QueueType.caseManagement};
    this.isLoading = true;
    this.queueCreateService.getUsers(data).subscribe(
      (response) => {
        this.data = response.value.map((user) => {
          return {
            id: user.id,
            userName: `${user.firstName} ${user.lastName}`,
            assignments: user.queueConfigurations ? user.queueConfigurations.join(', ') : '',
            userProfileName: user.userProfileName,
            userProfileId: user.userProfileId,
          };
        });

        if (!this.searchValue) {
          this.originalData = [...this.data];
        }

        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.settings.paginationPageCount = Math.ceil(
          this.dataSource.filteredData.length / this.settings.itemPerPage
        );
      },
      (error) => throwError(error),
      () => (this.isLoading = false)
    );
  }

  focusTable(event): void {
    this.search.nativeElement.blur();
    this.selectedItem = -1;
    this.tableFocused = true;
  }

  tableNavigate(event): void {
    event.preventDefault();
    if (this.dataSource.filteredData) {
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        this.selectedItem = this.selectedItem + 1;
        if (this.dataSource.filteredData.length <= this.settings.itemPerPage) {
          if (this.selectedItem === this.dataSource.filteredData.length) {
            this.selectedItem = 0;
          }
        } else {
          if (this.selectedItem === this.settings.itemPerPage) {
            if (this.settings.paginationPageCount === this.settings.activePage + 1) {
              this.setActivePage(0);
            } else {
              this.setActivePage(this.settings.activePage + 1);
            }
            this.selectedItem = 0;
          }
        }
      }
      if (event.key === 'ArrowUp') {
        this.selectedItem = this.selectedItem - 1;

        if (this.dataSource.filteredData.length <= this.settings.itemPerPage) {
          if (this.selectedItem === -1) {
            this.selectedItem = this.dataSource.filteredData.length - 1;
          }
        } else {
          if (this.selectedItem === -1) {
            if (this.settings.activePage === 0) {
              this.setActivePage(this.settings.paginationPageCount - 1);
            } else {
              this.setActivePage(this.settings.activePage - 1);
            }
            this.selectedItem = this.settings.itemPerPage - 1;
          }
        }
      }
      if (event.key === 'Enter') {
        const id = this.dataSource._renderData._value[this.selectedItem].id;
        this.checkUser(id);
      }
      if (event.key === 'Backspace') {
        this.selectedItem = null;
        this.tableFocused = false;
        this.search.nativeElement.focus();
      }
    }
  }
}
