import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { isBlank } from '@shared/utilities/empty-checks';
import { ITaskField } from '../../../interfaces/task-field.interface';
import { DynamicTaskControl } from '../classes/dynamic-task.control';

export function CustomValidator(field: ITaskField): ValidatorFn {
  return (control: DynamicTaskControl): ValidationErrors | null => {
    const isInvalid = !field.isHidden && (isBlank(control.value) || isDateRangeAndEmpty(control.value));

    return isInvalid ? { error: 'Mandatory field is empty!' } : null;
  };
}

export function CustomNumberValidator(field: ITaskField): ValidatorFn {
  return (control: DynamicTaskControl): ValidationErrors | null => {
    const isInvalid = !field.isHidden && (isNaN(control.value) || (typeof control.value === 'string' && !control.value.trim()));

    return isInvalid ? { error: 'NaN!' } : null;
  };
}

function isDateRangeAndEmpty(value) {
  if(!value.dateStart && !value.dateEnd) return false;

  return (value.dateStart.isRequired && !value.dateStart.value) || (value.dateEnd.isRequired && !value.dateEnd.value);
}