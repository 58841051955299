import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SupervisorQueueService } from '../../services/supervisor-queue.service';

@Component({
  templateUrl: './queue-selection.component.html',
  styleUrls: ['./queue-selection.component.scss']
})
export class QueueSelectionComponent implements OnInit {
  queues: any[];
  
  selectedQueue = new FormControl(undefined, [Validators.required]);

  constructor(
    private dialogRef: MatDialogRef<QueueSelectionComponent>,
    private supervisorQueueService: SupervisorQueueService,
  ) {
  }

  ngOnInit(): void {
    this.loadQueues();
  }

  private loadQueues() { 
    this.supervisorQueueService.getQueues()
      .subscribe(
        res => {
          this.queues = res;
        },
        err => {
          console.error(err);
        }
      )
  }

  submit() { 
    if (this.selectedQueue.valid) this.dialogRef.close(this.selectedQueue.value);
  }
}
