import { DefaultTabOrdering } from '../parameters/default-tab-ordering';
import { FieldsConfig } from './fields-default.config';
import { TabId } from '../parameters/tab-identifier';
import { BaseConfig } from './base.config';

export class PatientConfig extends BaseConfig {
  constructor({ 
    ordering = DefaultTabOrdering[TabId.patient],
    displayName = 'Patient',
    nextTabName = undefined }) {
    super(TabId.patient, ordering, displayName, nextTabName);
  }

  public fieldsConfig = 
  {
    firstName: new FieldsConfig({displayName: 'First Name'}),
    middleName: new FieldsConfig({displayName: 'Middle Initial'}),
    lastName: new FieldsConfig({displayName: 'Last Name'}),
    dateOfBirth: new FieldsConfig({displayName: 'Date of Birth'}),
    gender: new FieldsConfig({displayName: 'Gender'}),
    
    address: new FieldsConfig({displayName: 'Address'}),
    city: new FieldsConfig({displayName: 'City'}),
    state: new FieldsConfig({ displayName: 'State' }),
    zipCode: new FieldsConfig({ displayName: 'ZIP Code' }),
    streetAddress: new FieldsConfig({displayName: 'Address'}),
    addressExtension: new FieldsConfig({ displayName: 'Address Line 2' }),
    
    bestTimeToContact: new FieldsConfig({displayName: 'Best Time to Contact'}),
    bestMethodToContact: new FieldsConfig({displayName: 'Best Method to Contact'}),
    phoneInfo: new FieldsConfig({displayName: 'Phone'}),
      contactType: new FieldsConfig({displayName: 'Patient Phone Type'}),
      contactString: new FieldsConfig({displayName: 'Patient Phone'}),
    email: new FieldsConfig({displayName: 'Email'}),
    hipaaConsent: new FieldsConfig({displayName: 'HIPAA Consent'}),
    hipaaConsentSignatureDate: new FieldsConfig({displayName: 'HIPAA Consent Signature Date'}),
    socialSecurityNumber: new FieldsConfig({displayName: 'SSN'}),
    externalId: new FieldsConfig({displayName: 'External Id'}),
    legalUSResident: new FieldsConfig({ displayName: 'Legal US Resident (Yes/No)' }),
    householdPeople: new FieldsConfig({ displayName: 'Total Number of People Within Household' }),
    householdIncome: new FieldsConfig({ displayName: 'Total Annual income for Entire Household' }),
    supportingDocumentation: new FieldsConfig({ displayName: 'Supporting documentation' }),
    supportingDocumentationText: new FieldsConfig({ displayName: 'Please describe other supporting documentation' }),
    signature: new FieldsConfig({ displayName: 'Patient Signature (or Caregiver)' }),
    signatureDate: new FieldsConfig({ displayName: 'Signature date' }),
    caregiverName: new FieldsConfig({ displayName: 'Caregiver Name' }),
    caregiverPhone: new FieldsConfig({ displayName: 'Caregiver Phone Number' }),
    caregiverRelationship: new FieldsConfig({ displayName: 'Caregiver Relationship to Patient' }),
  }
}
