import { BaseConfig } from './configs/base.config';

export class TabState {
  private _autofillData: any = undefined;

  public isValid: boolean | undefined = undefined;
  public withWarnings: boolean | undefined = undefined;

  public get autofillData() { return this._autofillData }
  
  public SaveState: () => void;
  public isSaved: boolean = false;

  public readonly forms: any = {};
  public readonly validation = {
    desired: [],
    invalid: [],
    required: [],
  };

  constructor( 
    public readonly config: BaseConfig,
    public readonly icon: string,
    public readonly iconSelected: string,
  ){ }

  public setAutofillData(data) {
    this._autofillData = data;
  }
}