import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CaseService } from '../../../../services/case.service';

@Component({
  templateUrl: './submit-close-case.component.html',
  styleUrls: ['./submit-close-case.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitCloseCaseComponent implements OnInit {

  public reason: any;
  public reasons: any[];

  constructor(
    private caseService: CaseService,
    private dialogRef: MatDialogRef<SubmitCloseCaseComponent>
    ) { }

  ngOnInit(): void {
    this.caseService.getCaseClosingReasons()
    .subscribe(
      res => {
        if(!res.success) return;

        this.reasons = res.value;
      },
      err => {
        console.error(err);
      });
  }

  submit(): void {
    this.dialogRef.close({reasonId: this.reason});
  }

  close(): void {
    this.dialogRef.close();
  }
}
