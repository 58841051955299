import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { getToken } from '../../shared/helpers/utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    // Only add an access token to whitelisted origins
    const apiUrl = new URL(environment.resourceServer.apiUrl).origin;
    const allowedOrigins = [window.location.origin, apiUrl];
    const requestUrl = new URL(request.url).origin;

    if (allowedOrigins.some((url) => requestUrl.includes(url))) {
      const accessToken = getToken();
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + accessToken,
        },
      });
    }
    return next.handle(request).toPromise();
  }
}
