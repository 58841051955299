<div mat-dialog-title>
  <h1>
    <mat-icon>textsms</mat-icon>
    Text Sent
  </h1>
  <button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p>
    The following was sent to <b>{{ patientFirstName }} {{ patientLastName }}</b> at
    <b>{{ patientPhone | phoneNumber }}</b>.
  </p>
  <div class="dialog-body" *ngIf="data.taskData" style="border-bottom: none;">
    <mat-icon>format_quote</mat-icon>
    {{ body }}
  </div>
  <ng-container *ngIf="!data.taskData">
    <div class="dialog-body">
      <mat-icon>format_quote</mat-icon>
      Hello, {{ patientGender === 0 ? "Ms." : patientGender === 1 ? "Mr." : "" }} {{ patientLastName }}. This is
      Heather from Patient Support Program. We’ve been trying to reach you to welcome you to the program. Please
      give me a call back at (502) 123-4973 at your convenience. Have a great day.
    </div>
    <p>Please expect a call back from {{ patientFirstName }} {{ patientLastName }} soon.</p>
  </ng-container>
</div>

<div mat-dialog-actions *ngIf="!data.taskData">
  <button mat-button class="back" mat-dialog-close (click)="setSelectedTaskHandler()">Okay</button>
</div>
