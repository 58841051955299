import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as DocumentAction from '../../../../store/document/document.actions';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyDocumentModalComponent {
  constructor(private store: Store<any>) {
  }

  updateBatch(): void {
    this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(true));
  }
}
