import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SupervisorAddUserColumns } from '../../../../../../../../shared/enums/supervisor-add-user-columns.enum';
import { enumToArray } from '../../../../../../../../shared/helpers/utils';
import { IManagerUser } from '../../../../../../../../shared/interfaces/manager-user.interface';
import { QueueCreateService } from '../../../../../creation/services/creation.service';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-supervisor-user-add',
  templateUrl: './add.component.html',
  styleUrls: ['../../users.component.scss', './add.component.scss'],
})
export class SupervisorAddUserComponent implements OnInit {
  @Output() usersSaved = new EventEmitter<IManagerUser[]>();

  searchValue = '';
  columnsToDisplay = enumToArray(SupervisorAddUserColumns);
  addedUsers: IManagerUser[] = [];
  queueId;
  data = [];
  dataSource;
  isLoading = false;
  isAllSelected = false;

  constructor(
    public queueCreateService: QueueCreateService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
  ) {
    this.addedUsers = [...dialogData.addedUsers];
    this.queueId = dialogData.queueId;
  }

  ngOnInit(): void {
    this.getUsers();
  }

  checkUser(user): void {
    if (!this.isSelected(user.id)) {
      this.addedUsers.push(user);
    } else {
      this.addedUsers = this.addedUsers.filter(el => el.id !== user.id);
    }
  }

  isSelected(id): boolean {
    return this.addedUsers.some(x => x.id === id);
  }

  selectAll(): void {
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      this.addedUsers = this.data;
    } else {
      this.addedUsers = [];
    }
  }

  saveUsers(): void {
    this.usersSaved.emit(this.addedUsers);
  }

  clearSearch(): void {
    this.searchValue = '';
    this.getUsers();
  }

  getUsers(): void {
    const data = {take: 0, search: this.searchValue};
    this.isLoading = true;
    this.queueCreateService.getUsers(data).subscribe(
      (response) => {
        this.data = response.value.map((user) => {
          return {
            id: user.id,
            userName: `${user.firstName} ${user.lastName}`,
            assignments: user.queueConfigurations ? user.queueConfigurations.join(', ') : '',
            userProfileName: user.userProfileName,
            userProfileId: user.userProfileId
          };
        });

        this.dataSource = new MatTableDataSource(this.data);
      },
      (error) => throwError(error),
      () => (this.isLoading = false)
    );
  }
}
