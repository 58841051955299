<div class="dialog">
  <div class="dialog-header">
    <mat-icon class="header-icon">warning</mat-icon>
    <span class="header-text">Are you sure you want to delete this page?</span>
    <mat-icon class="header-close-btn"
              mat-dialog-close>close</mat-icon>
  </div>

  <div class="dialog-content">
    <span class="description">
      This will remove this page permanently from the database. Please make sure this page needs to be deleted.
    </span>
  </div>

  <div class="dialog-footer stick-bottom">
    <button class="cancel-btn"
            mat-dialog-close>
      Cancel
    </button>
    <button (click)="submit()"
            class="submit-btn">
      <span>Yes, Delete</span>
      <mat-icon class="icon">delete</mat-icon>
    </button>
  </div>
</div>
