<div class="content-wrapper">
  <div class="header-wrapper">
    <h1>Reset password</h1>
    <mat-icon class="icon" (click)="close()">close</mat-icon>
  </div>
  <div class="body-wrapper">
    <form class="form" [formGroup]="dataForm">
      <div class="field-wrapper">
        <label for="oldPassword" class="field-caption">Old Password</label>
        <div>
          <input #oldPassword type="password" formControlName="oldPassword" matInput id="oldPassword" 
          class="field-input" [ngClass]="{'field-input-invalid': dataForm.controls.oldPassword.invalid}"/>
          <button class="hide-password-button" mat-icon-button matSuffix (click)="oldPassword.type = oldPassword.type === 'password' ? 'text' : 'password'">
            <mat-icon>{{oldPassword.type === 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </div>
      </div>

      <div class="field-wrapper">
        <label for="newPassword" class="field-caption">New Password</label>
        <div>
          <input #newPassword type="password" formControlName="newPassword" matInput id="newPassword" class="field-input"/>
          <button class="hide-password-button" mat-icon-button matSuffix (click)="newPassword.type = newPassword.type === 'password' ? 'text' : 'password'">
            <mat-icon>{{newPassword.type === 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </div>

        <div class="password-validation-bars">
          <span class="validation-bar" 
            [ngClass]="{ 
              'validation-bar-very-weak': passwordStrength === strengths.veryWeak,
              'validation-bar-weak': passwordStrength === strengths.weak,
              'validation-bar-medium': passwordStrength === strengths.medium,
              'validation-bar-strong': (passwordStrength === strengths.strong || passwordStrength === strengths.veryStrong)
            }"> </span>

            <span class="validation-bar" 
            [ngClass]="{ 
              'validation-bar-medium': passwordStrength === strengths.medium,
              'validation-bar-strong': (passwordStrength === strengths.strong || passwordStrength === strengths.veryStrong)
            }"> </span>

            <span class="validation-bar" 
            [ngClass]="{ 
              'validation-bar-strong': (passwordStrength === strengths.strong || passwordStrength === strengths.veryStrong)
            }"> </span>

            <span class="validation-bar" 
            [ngClass]="{ 
              'validation-bar-strong': (passwordStrength === strengths.veryStrong)
            }"> </span>
        </div>
        
        <div class="validation-captions">
          <span class="validation-result" style="width: 305px;">
            {{Configuration.Password.RequirementsText}}
          </span>
          <span class="validation-result"
          [ngClass]="{ 
              'validation-result-very-weak': passwordStrength === strengths.veryWeak,
              'validation-result-weak': passwordStrength === strengths.weak,
              'validation-result-medium': passwordStrength === strengths.medium,
              'validation-result-strong': (passwordStrength === strengths.strong || passwordStrength === strengths.veryStrong)
            }">{{passwordStrength}}</span>
        </div>
      </div>
    </form>
  </div>
  <div class="footer-wrapper">
    <button class="cancel-button" (click)="close()">Cancel</button>
    <button [disabled]="dataForm.invalid || !passwordStrength || passwordStrength == strengths.veryWeak || passwordStrength == strengths.weak" class="save-button" (click)="save()">Save</button>
  </div>
</div>

<div *ngIf="errorMessage" class="error-container">{{errorMessage}}</div>