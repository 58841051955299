import {animate, style, transition, trigger} from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({opacity: 0, transform: 'scale(0.95)'}),
    animate('500ms', style({opacity: 1, transform: 'scale(1)'})),
  ]),
  transition(':leave', [
    animate('300ms', style({opacity: 0, transform: 'scale(0.95)'})),
  ]),
]);
