import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TaskOutcome } from '../../../../../../shared/enums/task-outcome.enum';
import { TaskType } from '../../../../../../shared/enums/task-type.enum';
import { RescheduleTaskComponent } from '../dynamic-tasks-renderer/dialogs/reschedule/reschedule.component';
import { ExhaustedTaskComponent } from '../dynamic-tasks-renderer/dialogs/exhausted/exhausted.component';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { CaseService } from '../../services/case.service';
import { Store } from '@ngrx/store';
import * as CaseAction from '../../../../../../store/case/case.actions';

@Component({
  selector: 'app-case-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskCaseComponent implements OnInit, OnDestroy {
  @Input() selectedTask;
  @Input() case;
  @Output() minimizeTaskHandler = new EventEmitter();
  @Output() setSelectedTaskHandler = new EventEmitter();
  @Output() submitTaskHandler = new EventEmitter();

  taskType = TaskType;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private store: Store<any>,
    public caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    this.setInfoForHeader(this.case);
  }

  ngOnDestroy(): void {
    const headerInfo = {
      id: this.case.id,
      patient: `${this.case.patient.firstName} ${this.case.patient.lastName}`,
      queue: null,
      tasks: null,
    };

    if (!this.selectedTask.minimized) {
      this.store.dispatch(new CaseAction.SetCase(headerInfo));
    }
  }

  minimizeTask(val: boolean): void {
    this.minimizeTaskHandler.emit(val);
  }

  setSelectedTask(task): void {
    this.setSelectedTaskHandler.emit(task);
  }

  rescheduleTask(data): void {
    let dialogRef;
    const maxAttempts = this.selectedTask.maxAttempts || 3;

    if (data.attemptNumber >= maxAttempts - 1) {
      data.type = 'manual';
      dialogRef = this.dialog.open(ExhaustedTaskComponent, {data});
    } else {
      data.type = 'automatic';
      dialogRef = this.dialog.open(RescheduleTaskComponent, {data});
    }

    const dialog: any = dialogRef.componentInstance;

    dialog.scheduleFollowUp.subscribe((resp) => {
      const requestData = {
        queueItemId: this.case.id,
        queueTaskId: this.selectedTask.id,
        taskOutcome: TaskOutcome.repeat,
        rescheduleDate: resp.rescheduleDate,
        outcomeNote: data.outcomeNote,
        target: data.target,
        followUpReasonId: data.followUpReasonId,
        contactMethod: resp.contactMethod !== null ? Number(resp.contactMethod) : null,
      };
      this.submitTask(requestData);
    });

    if (dialog.escalateToSupervisor) {
      dialog.escalateToSupervisor.subscribe((resp) => {
        const requestData = {
          queueItemId: this.case.id,
          queueTaskId: this.selectedTask.id,
          taskOutcome: TaskOutcome.failure,
          outcomeNote: data.outcomeNote,
        };
        this.submitTask(requestData);
        this.router.navigate(['/']);
      });
    }
  }

  setInfoForHeader(caseInfo): void {
    this.caseService.getQueueCount(this.selectedTask.queueId).subscribe(
      (response) => {
        const headerInfo = {
          id: caseInfo.id,
          patient: `${caseInfo.patient.firstName} ${caseInfo.patient.lastName}`,
          queue: caseInfo.queueConfigurationName,
          tasks: response,
        };
        this.store.dispatch(new CaseAction.SetCase(headerInfo));
      },
      (error) => throwError(error)
    );
  }

  submitTask(data): void {
    this.submitTaskHandler.emit(data);
  }
}
