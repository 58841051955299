import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { Store } from '@ngrx/store';
import { getUserRole } from '../../shared/helpers/utils';
import { IUser } from '../../shared/interfaces/user.interface';
import { User } from '../../shared/models/user.model';
import * as UserAction from '../../store/user/user.actions';
import { LoginService } from 'src/app/features/login/services/login.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {

  userAuthResult = true;

  constructor(
    public oktaAuth: OktaAuthService,
    private router: Router,
    private store: Store<any>,
    public loginService: LoginService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const user = new User();
    const isAuthenticated = await this.oktaAuth.isAuthenticated();
    let userState;

    this.store.select('user').subscribe((res: IUser) => {
      userState = res;
    });

    if (isAuthenticated) {
      if (!userState && this.userAuthResult) {
        await this.oktaAuth.getUser().then((responce: any) => {
          user.name = responce.name;
          user.role = getUserRole(responce);
          user.email = responce.email;
          user.id = responce.sub;
          this.store.dispatch(new UserAction.SetUser(user));
        },
          (error) => {
            if (this.userAuthResult) {
              this.userAuthResult = false;
              this.loginService.logout().subscribe(
                () => {
                  this.loginService.oktaLogout();
                }
              );
            }
          });
      } else {
        user.name = userState.name;
        user.role = userState.role;
      }
      if (!this.userAuthResult && !isAuthenticated) {
        return false;
      }

      if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
        this.router.navigate(['/']);
        return false;
      }

      return true;
    }

    await this.oktaAuth.login(state.url);
    this.router.navigate(['/login']);

    return false;
  }
}
