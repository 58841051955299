import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ITaskField } from 'src/app/features/manager/components/case/interfaces/task-field.interface';
import { FieldType } from '../../../../../../interfaces/FieldType';

@Component({
  selector: 'app-task-elements-parser',
  templateUrl: './task-elements-parser.component.html',
  styleUrls: ['./task-elements-parser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskElementsParserComponent {
  FieldType = FieldType;

  private innerControl: FormGroup = new FormGroup({});

  @Input() case: any;
  @Input() fields: any[];
  @Input() isHistory: boolean;

  @Input() set parserForm(value: FormGroup) {
    this.innerControl = value;
  }

  get parserForm(): FormGroup {
    return this.innerControl;
  }

  @Output() addEvent: EventEmitter<any> = new EventEmitter();
  @Output() contactEvent: EventEmitter<any> = new EventEmitter();
  @Output() datepickerChanged: EventEmitter<any> = new EventEmitter();
  @Output() previewDocument = new EventEmitter<ITaskField>();
  @Output() downloadDocument = new EventEmitter<ITaskField>();
}
