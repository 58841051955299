<form [formGroup]="faxForm" (submit)="submit()" class="document-sidebar-form">
  <div class="missed-info">
    <h2>Missing Information</h2>
    <h3>Request Includes</h3>
    <h4 *ngIf="missedInfo.required?.length">Required Information</h4>
    <ul>
      <li *ngFor="let required of missedInfo.required">{{ required.name }}</li>
    </ul>
    <h4 *ngIf="missedInfo.desired?.length">Desired Information</h4>
    <ul>
      <li *ngFor="let desired of missedInfo.desired">{{ desired.name }}</li>
    </ul>
  </div>
  <div class="missed-info input-item" *ngIf="missedInfo.required?.length || missedInfo.desired?.length">
    <h3>Add Fax Number</h3>
    <label for="faxNumber">HCP Fax Number</label>
    <input formControlName="faxNumber"
           id="faxNumber"
           name="faxNumber"
           matInput
           #faxNumber
           (keypress)="numberOnly($event)"
           maxlength="14"
           type='text'
           mask="(000) 000-0000"
           placeholder="(_ _ _) _ _ _ - _ _ _ _"
    />
  </div>
  <div class="missed-info-buttons">
    <div class="missed-info-buttons-container">
      <button type="button" class="primary-btn invert missed-cancel" (click)="cancel()" [disabled]="isLoading">
        Cancel
      </button>
      <button [disabled]="faxForm.invalid || isLoading" type="submit" class="next-button next-stack" (keydown.enter)="submit()">
        Fax
      </button>
    </div>
  </div>
</form>
