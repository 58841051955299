import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-task-contact-item',
  templateUrl: './task-contact-item.component.html',
  styleUrls: ['./task-contact-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskContactItemComponent {
  @Input() name: string;
  @Input() value: string;
  @Input() iconName: string;
  @Input() description: string;
  @Input() disabled: boolean;

  @Output() contactEvent: EventEmitter<void> = new EventEmitter();
}
