import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DocumentService } from 'src/app/features/intake/services/document.service';
import { DocumentTypeModel } from '../../../../models/document-type.model';

@Component({
  selector: 'app-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.scss']
})
export class DocumentTypeListComponent implements OnInit {
  public readonly otherId = 6; // other document type option id;

  @Input() documentForm: FormGroup;
  @Input() documentControl: FormControl;

  @Output() setOtherText = new EventEmitter();
  @Output() changeFocus = new EventEmitter();
  @Output() changedType = new EventEmitter();

  @ViewChild('other') otherField: ElementRef;

  private programId;

  documentTypes: DocumentTypeModel[] = [];

  constructor(
    private store: Store<any>,
    private documentService: DocumentService,
  ) { }

  ngOnInit(): void {
    this.store.select('document')
      .subscribe(
        (state) => {
          if (state?.programId && this.programId !== state.programId) { 
            this.programId = state.programId;
            this.initDocumentTypes(state.programId);
          }
        }
      );
  }

  changeFocusFromType(changed) { 
    if (changed.value !== this.otherId) {
      this.changedType.emit();
    }
    else { 
      setTimeout(() => { 
        this.otherField?.nativeElement.focus();
      }, 10);
    }
  }

  private initDocumentTypes(programId: number): void {
    this.documentService.getDocumentTypes(programId).subscribe(
      (res: DocumentTypeModel[]) => {
        this.documentTypes = res;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
