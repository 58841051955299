import { IToggleOption } from '../interfaces/toggle-option.interface';

export const defaultToggleOptions: IToggleOption[] = [
  {
    id: true,
    name: 'Yes',
  },
  {
    id: false,
    name: 'No',
  },
];
