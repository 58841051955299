<div class="queue-title-wrap">
  <app-page-breadcrumbs [currentPage]="'Data Entry'"></app-page-breadcrumbs>

  <div class="queue-title">
    <h2>Data Entry</h2>
    <button routerLink="/document-data-entry" [queryParams]="{ queueId: queueId}" [disabled]="!data.length">
      Start <img *ngIf="data.length" src="../../../../../../../assets/icons/icon-arrow-long-right.svg" alt="begin"/>
      <img *ngIf="!data.length" src="../../../../../../../assets/icons/icon-arrow-long-right-gray.svg" alt="begin"/>
    </button>
  </div>
  <button class="primary-btn invert clear-all-filters" *ngIf="!isFiltersNotUsed" (click)="clearAllFilters()">
    Clear All Filters
    <mat-icon>close</mat-icon>
  </button>
</div>
<div *ngIf="!isLoaded" class="dashboard-loader-container">
  <app-loader></app-loader>
</div>
<table [hidden]="!data.length" mat-table [dataSource]="dataSource" matSort matSortActive="age" matSortDirection="desc">
  <ng-container matColumnDef="patient">
    <th mat-header-cell *matHeaderCellDef>
      <div class="filter-header">
        <div mat-sort-header>Patient</div>
        <div class="filter-buttons"
             [class.active]="!!settings.filter.value.patient !== !!settings.filter.initialValue.patient">
          <button class="filter-button" (click)="openFilter('patient')">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <button class="clear-button"
                  *ngIf="!!settings.filter.value.patient !== !!settings.filter.initialValue.patient"
                  (click)="clearFilter('patient')">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="filter-wrapper" *ngIf="settings.filter.show.patient">
          <div class="filter-title">
            <h5>Patient</h5>
            <button (click)="closeFilter('patient')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="filter-body">
            <div class="input-item">
              <input name="patient" id="patient" [(ngModel)]="settings.filter.value.patient"/>
            </div>
          </div>
          <div class="filter-controls">
            <button class="clear" (click)="clearFilter('patient')">Clear</button>
            <div class="primary-btn" (click)="applyFilter('patient')">Filter</div>
          </div>
        </div>
      </div>
    </th>
    <td mat-cell *matCellDef="let queue">
      {{ queue.patient }}
    </td>
  </ng-container>
  <ng-container matColumnDef="documentType">
    <th mat-header-cell *matHeaderCellDef>
      <div class="filter-header">
        <div mat-sort-header>Document Type(s)</div>
        <div class="filter-buttons" [class.active]="isDocumentTypeSelected()">
          <button class="filter-button" (click)="openFilter('documentType')">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <button class="clear-button" *ngIf="isDocumentTypeSelected()" (click)="clearFilter('documentType')">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="filter-wrapper" *ngIf="settings.filter.show.documentType">
          <div class="filter-title">
            <h5>Document Type</h5>
            <button (click)="closeFilter('documentType')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="filter-body">
            <div class="checkbox-item filter-checkbox" *ngFor="let type of documentTypes">
              <mat-checkbox [(ngModel)]="settings.filter.value.documentType[type]">{{
                DocumentTypeNames[type]
                }}</mat-checkbox>
            </div>
          </div>
          <div class="filter-controls">
            <button class="clear" (click)="clearFilter('documentType')">Clear</button>
            <div class="primary-btn" (click)="applyFilter('documentType')">Filter</div>
          </div>
        </div>
      </div>
    </th>
    <td mat-cell *matCellDef="let queue">{{ queue.documentTypeNames.join(", ") }}</td>
  </ng-container>
  <ng-container matColumnDef="receivedDate">
    <th mat-header-cell *matHeaderCellDef>
      <div class="filter-header">
        <div mat-sort-header>Received</div>
        <div class="filter-buttons"
             [class.active]="settings.filter.value.receivedDate.start || settings.filter.value.receivedDate.end">
          <button class="filter-button" (click)="openFilter('receivedDate')">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <button class="clear-button"
                  *ngIf="settings.filter.value.receivedDate.start || settings.filter.value.receivedDate.end"
                  (click)="clearFilter('receivedDate')">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="filter-wrapper" *ngIf="settings.filter.show.receivedDate">
          <div class="filter-title">
            <h5>Received</h5>
            <button (click)="closeFilter('receivedDate')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="filter-body">
            <div class="date-range">
              <mat-form-field appearance="fill">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="Start date"
                         [(ngModel)]="settings.filter.value.receivedDate.start"/>
                  <input matEndDate placeholder="End date"
                         [(ngModel)]="settings.filter.value.receivedDate.end"/>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
          <div class="filter-controls">
            <button class="clear" (click)="clearFilter('receivedDate')">Clear</button>
            <div class="primary-btn" (click)="applyFilter('receivedDate')">Filter</div>
          </div>
        </div>
      </div>
    </th>
    <td mat-cell *matCellDef="let queue">
      <div class="lock-wrapper"
           [style]="queue.queueStatus === 1 ? 'display: flex;align-items: center;' : 'padding-left: 44px'">
        <div *ngIf="queue.queueStatus === 1" class="lock-container">
          <mat-icon class="lock">lock</mat-icon>
          <img *ngIf="queue.userImageS3Url" [src]="queue.userImageS3Url"/>
          <mat-icon *ngIf="!queue.userImageS3Url" class="default-user">account_circle</mat-icon>
        </div>
        <div class="lock-text">
          <i *ngIf="queue.queueStatus === 1">In Progress</i>
          {{ queue.receivedDate | date: "E, M/d - hh:mmaaaaa" }}
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="age">
    <th mat-header-cell *matHeaderCellDef>
      <div class="filter-header">
        <div mat-sort-header>Aging</div>
        <div class="filter-buttons" [class.active]="
            settings.filter.value.age[0] !== settings.filter.initialValue.age[0] ||
            settings.filter.value.age[1] !== settings.filter.initialValue.age[1]
        ">
          <button class="filter-button" (click)="openFilter('age')">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <button class="clear-button" *ngIf="
              settings.filter.value.age[0] !== settings.filter.initialValue.age[0] ||
              settings.filter.value.age[1] !== settings.filter.initialValue.age[1]
          " (click)="clearFilter('age')">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="filter-wrapper" *ngIf="settings.filter.show.age">
          <div class="filter-title">
            <h5>Aging</h5>
            <button (click)="closeFilter('age')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="filter-body">
            <nouislider #ageSlider [connect]="true" [min]="0" [max]="maxHoursCount" [step]="1"
                        [(ngModel)]="settings.filter.value.age"></nouislider>
            <div class="range-inputs">
              <div class="input-item">
                <input type="number" name="start" id="start" min="0"
                       [max]="settings.filter.value.age[1]" [(ngModel)]="settings.filter.value.age[0]"
                       (input)="onChangeRange('ageSlider')"/>
              </div>
              <div class="input-item">
                <input type="number" name="end" id="end" [min]="settings.filter.value.age[0]"
                       [max]="maxHoursCount" [(ngModel)]="settings.filter.value.age[1]"
                       (input)="onChangeRange('ageSlider')"/>
              </div>
            </div>
            <div class="age-type">
              <div class="input-item toggle">
                <mat-button-toggle-group name="ageType" [(ngModel)]="settings.ageType"
                                         (change)="countAgeDuration()">
                  <mat-button-toggle value="hours"> Hours</mat-button-toggle>
                  <mat-button-toggle value="days"> Days</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>
          <div class="filter-controls">
            <button class="clear" (click)="clearFilter('age')">Clear</button>
            <div class="primary-btn" (click)="applyFilter('age')">Filter</div>
          </div>
        </div>
      </div>
    </th>
    <td mat-cell *matCellDef="let queue">
      <img [src]="'../../../../../assets/icons/icon-age-' + setAgeColor(queue.createDate) + '.svg'" alt="age"/>
      {{ setAge(queue.createDate) }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let queue; columns: columnsToDisplay" [class.locked]="queue.queueStatus === 1"
      (click)="openItem(queue)"></tr>
  <tr class="mat-row no-data" *matNoDataRow>
    <td class="mat-cell" colspan="4">No data matching the filter</td>
  </tr>
</table>

<mat-paginator style="display: none;" [pageSize]="settings.itemPerPage"></mat-paginator>

<app-pagination mat-paginator *ngIf="data.length && isLoaded"
                [style]="settings.paginationPageCount > 1 ? 'display: block' : 'display: none'" [settings]="settings"
                (setActivePageHandler)="setActivePage($event)"
></app-pagination>

<app-empty-queue *ngIf="!data.length && isLoaded" type="documents"></app-empty-queue>
