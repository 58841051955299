import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/classes/UnsubscribeOnDestroy';
import { formatPhone, interpolateTemplate } from 'src/app/shared/helpers/utils';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { TaskOutcome } from '@shared/enums/task-outcome.enum';
import { CaseService } from '../../../../services/case.service';

@Component({
  selector: 'app-task-followup-benefit',
  templateUrl: './followup-benefit.component.html',
  styleUrls: ['../../task.component.scss', './followup-benefit.component.scss'],
})
export class FollowupBenefitTaskComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() selectedTask;
  @Input() case;

  @Output() minimizeTaskHandler = new EventEmitter();
  @Output() setSelectedTaskHandler = new EventEmitter();
  @Output() rescheduleTaskHandler = new EventEmitter();
  @Output() submitTaskHandler = new EventEmitter();

  interpolatedDescription;
  followUpForm: FormGroup;
  contactMethods = {
    phone: null,
    fax: null,
    email: null,
  };

  constructor(private caseService: CaseService) {
    super();
    this.followUpForm = new FormGroup({
      contact: new FormControl(null, [Validators.required]),
      proceed: new FormControl(null, [Validators.required]),
      completed: new FormControl(null, [Validators.required]),
      note: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.interpolatedDescription =
      this.case.facility && this.case.physician
        ? interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject())
        : this.selectedTask.description;
    if (this.selectedTask.formData) {
      this.followUpForm.patchValue(this.selectedTask.formData);
    }
    this.caseService.case$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((caseValue) => {
        this.case = caseValue;
        this.interpolatedDescription =
          this.case.facility && this.case.physician
            ? interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject())
            : this.selectedTask.description;
      });
  }

  minimizeTask(val: boolean): void {
    this.selectedTask.formData = this.followUpForm.getRawValue();
    this.minimizeTaskHandler.emit(val);
  }

  setSelectedTask(task): void {
    this.setSelectedTaskHandler.emit(task);
  }

  submitTask(): void {
    const data = {
      queueItemId: this.case.id,
      queueTaskId: this.selectedTask.id,
      taskOutcome: null,
      outcomeNote: this.followUpForm.value.note,
    };
    if (
      this.followUpForm.value.contact === 0 &&
      this.followUpForm.value.proceed === 0 &&
      this.followUpForm.value.completed === 0
    ) {
      data.taskOutcome = TaskOutcome.success;
    } else if (this.followUpForm.value.proceed === 1) {
      data.taskOutcome = TaskOutcome.failure;
    } else {
      data.taskOutcome = TaskOutcome.repeat;
    }
    this.submitTaskHandler.emit(data);
  }

  rescheduleTask(): void {
    const data = {
      attemptNumber: this.selectedTask.attemptNumber,
      task: this.selectedTask.task,
      outcomeNote: this.followUpForm.value.note,
      case: this.case,
    };
    this.rescheduleTaskHandler.emit(data);
  }

  getDescriptionTemplateObject(): any {
    const facilityPhone =
      this.case.facility && this.case.facility.contactInfos && this.case.facility.contactInfos.length
        ? this.case.facility.contactInfos.find((contact) => contact.contactMethod === ContactMethod.phone)
        : null;
    const facilityName = this.case.facility?.name || '';
    const physicianName = `${this.case.physician?.firstName || ''} ${this.case.physician?.lastName || ''}`;

    this.contactMethods.phone = formatPhone(facilityPhone?.contactString);
    this.contactMethods.fax =
      this.case.facility && this.case.facility.contactInfos && this.case.facility.contactInfos.length
        ? formatPhone(
        this.case.facility.contactInfos.find((contact) => contact.contactMethod === ContactMethod.fax)
          ?.contactString
        )
        : null;
    this.contactMethods.email =
      this.case.facility && this.case.facility.contactInfos && this.case.facility.contactInfos.length
        ? this.case.facility.contactInfos.find((contact) => contact.contactMethod === ContactMethod.email)
          ?.contactString
        : null;

    return {
      facilityPhone: `<a href="#" class="highlighted">${formatPhone(facilityPhone?.contactString)}</a>`,
      facilityName: `<span class="highlighted">${facilityName}</span>`,
      physicianName: `<span class="highlighted">Dr. ${physicianName}</span>`,
    };
  }
}
