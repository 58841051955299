import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() settings;
  @Output() setActivePageHandler = new EventEmitter();

  pageCount = 0;
  pages = [];

  setActivePage(index): void {
    this.setActivePageHandler.emit(index);
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }

  constructor() {
  }
}
