import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AbstractRestService } from '@core/services/abstract-rest.service';
import { ICase } from '@shared/interfaces/case.interface';
import { IResponse } from '@shared/interfaces/response.interface';
import { IInsurancePlan } from '../interfaces/insurance-plan.interface';
import { IPlanObject } from '@shared/interfaces/plan-object.interface';
import { IPlan } from '@shared/interfaces/plan.interface';
import { insurancesListDataMock } from '../../../../../../../testing/mocks/insurancesListDataMock';

@Injectable({
  providedIn: 'root',
})
export class CaseService extends AbstractRestService {
  case$: Subject<any> = new Subject<any>();
  caseDetails$: Subject<any> = new Subject<any>();
  details$: BehaviorSubject<any> = new BehaviorSubject(null);
  drawerHandler: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  getCaseItem(id): Observable<ICase> {
    this.url = this.apiUrl + `/CaseManagementQueueItem/${id}`;
    return this.getItems<IResponse>('').pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getQueueCount(id: number): Observable<number> {
    this.url = this.apiUrl;
    return this.getItems<number>(`Queue/GetItemCountInQueue/${id}`);
  }

  closeCase(command): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseManagementQueueItem/CloseCase`;

    return this.postItem<any, IResponse>(command);
  }

  completeIntake(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/IntakeQueues/CompleteIntake`;

    return this.postItem<any, IResponse>(data);
  }

  getCaseTypeId(caseTypeName: string, programId: number): Observable<any> {
    this.url = this.apiUrl + `/IntakeQueues/GetCaseTypeId/${caseTypeName}/${programId}`;
    return this.get<IResponse>();
  }

  getFulfillments(id): Observable<any[]> {
    this.url = this.apiUrl + `/Fulfillments/GetByCase/${id}`;
    return this.getItems<IResponse>('').pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getSpecialtyPharmacies(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/SpecialtyPharmacies`;
    return this.postItem<any, IResponse>(data);
  }

  putFulfillments(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Fulfillments`;
    return this.putItem<any, IResponse>(data);
  }

  putPriorAuthorizations(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PriorAuthorizations`;
    return this.putItem<any, IResponse>(data);
  }

  updatePatient(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Patients`;
    return this.updateItem<any, IResponse>(data);
  }

  getRelationships(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PickList/Relationships`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getReasons(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PickList/FollowUpReasons`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getConsentTypes(programId: any): Observable<any[]> {
    this.url = this.apiUrl + `/Consents/getTypes?programId=` + programId;
    return this.get<any>().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getConsentMethods(programId: any): Observable<any[]> {
    this.url = this.apiUrl + `/Consents/getMethods?programId=` + programId;
    return this.get<any>().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getConsentsByTypes(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Consents/getByTypes`;
    return this.postItem<any, any>(data).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getEnrollmentConsents(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Consents`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getEnrollmentCaregivers(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Caregivers`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getEnrollmentAddresses(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PatientAddresses`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  createEnrollmentConsent(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Consents`;
    return this.putItem<any, IResponse>(data);
  }

  createEnrollmentCaregiver(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Caregivers`;
    return this.putItem<any, IResponse>(data);
  }

  updateEnrollmentCaregiver(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Caregivers`;
    return this.updateItem<any, IResponse>(data);
  }

  createAddress(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PatientAddresses`;
    return this.putItem<any, IResponse>(data);
  }

  updateAddress(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PatientAddresses`;
    return this.updateItem<any, IResponse>(data);
  }

  getAddressHistory(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PatientAddressHistory`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getCaseAttachments(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseAttachments`;
    return this.postItem<any, IResponse>(data);
  }

  getCaseClosingReasons(): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseManagementQueueItem/GetCaseClosingReasons`;

    return this.get<IResponse>();
  }

  downloadAttachment(id, observe?): Observable<Blob> {
    this.url = this.apiUrl + `/CaseAttachments/Download/${id}`;

    return this.getImage('', observe);
  }

  downloadAttachmentByKey(key): Observable<any> {
    this.url = this.apiUrl + `/CaseAttachments/get-by-key`;

    const formData = new FormData();

    formData.append('key', key);

    return this.http.post(this.url, formData, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  getTasksConfigurations(fileName: string): Observable<any> {
    return this.http.get(`${window.location.origin}/assets/configuration/tasks/${fileName}`);
  }

  updateCase(caseData): Observable<any> {
    this.url = this.apiUrl + `/CaseManagementQueueItem`;
    return this.patchItem<any, IResponse>({caseManagementQueueItem: caseData});
  }

  completeTask(command): Observable<any> {
    this.url = this.apiUrl + `/CaseManagementQueueItem/CompleteTask`;

    return this.postItem<any, IResponse>(command);
  }

  rescheduleTask(command): Observable<any> {
    this.url = this.apiUrl + `/CaseManagementQueueItem/RescheduleTask`;

    return this.postItem<any, IResponse>(command);
  }

  escalateToSupervisor(command): Observable<any> {
    this.url = this.apiUrl + `/CaseManagementQueueItem/EscalateTask`;

    return this.postItem<any, IResponse>(command);
  }

  mergeDocument(command): Observable<any> {
    this.url = this.apiUrl + `/Document/MergeDocument`;

    return this.http.post(this.url, command, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  searchPayers(searchRequest): Observable<any[]> {
    this.url = this.apiUrl + `/Payers/Search`;

    return this.postItem<any, IResponse>(searchRequest)
      .pipe(map((response) => response.value));
  }

  searchMedicalPlans(searchRequest): Observable<IPlan[]> {
    this.url = this.apiUrl + `/Plans/Search`;

    return this.postItem<any, IResponse>(searchRequest)
      .pipe(map((response) => response.value));
  }

  searchPharmacyPlans(searchRequest): Observable<IPlan[]> {
    this.url = this.apiUrl + `/PharmacyBenefitPlans/Search`;

    return this.postItem<any, IResponse>(searchRequest)
      .pipe(map((response) => response.value));
  }

  savePharmacyPlan(isNew: boolean, plan: IPlanObject): Observable<any> {
    this.url = this.apiUrl + `/PatientInsurance/updatePharmacyPlan`;

    return this.patchItem<any, IResponse>(plan)
      .pipe(map((response) => response.value));
  }

  saveMedicalPlan(isNew: boolean, plan: IPlanObject): Observable<any> {
    this.url = this.apiUrl + `/PatientInsurance/updateMedicalPlan`;

    return this.patchItem<any, IResponse>(plan)
      .pipe(map((response) => response.value));
  }

  updatePolicyData(policy: IInsurancePlan): Observable<any> {
    this.url = this.apiUrl + `/PatientInsurance`;

    return this.patchItem<any, IResponse>(policy)
      .pipe(map((response) => response.value));
  }

  getMissingInfoFaxCoversheet(command): Observable<any> {
    this.url = this.apiUrl + `/Document/GetMissingInfoFaxCoversheet`;

    return this.http.post(this.url, command, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  // TODO: get real endpoint
  getCaseInsurances(): Observable<any> {
    this.url = this.apiUrl + `/Case/GetInsurances`;

    return of(insurancesListDataMock);
  }
}
