import { DefaultTabOrdering } from '../parameters/default-tab-ordering';
import { FieldsConfig } from './fields-default.config';
import { TabId } from '../parameters/tab-identifier';
import { BaseConfig } from './base.config';

export class ConsentConfig extends BaseConfig {
  constructor({
    ordering = DefaultTabOrdering[TabId.consent],
    displayName = 'Consent',
    nextTabName = undefined }) {
    super(TabId.consent, ordering, displayName, nextTabName);
  }

  public fieldsConfig =
  {
    showPatientInfo: false,
    generateConsents: [],

    patientSignature: new FieldsConfig({ displayName: 'Patient Signature Included?' }),
    patientSignatureDate: new FieldsConfig({ displayName: 'Patient Signature Date' }),
    patientFirstName: new FieldsConfig({ displayName: 'First Name' }),
    patientLastName: new FieldsConfig({ displayName: 'Last Name' }),
    patientDateOfBirth: new FieldsConfig({ displayName: 'Date of birth' }),
  }
}
