import { DefaultTabOrdering } from '../parameters/default-tab-ordering';
import { FieldsConfig } from './fields-default.config';
import { TabId } from '../parameters/tab-identifier';
import { BaseConfig } from './base.config';

export class ClinicalConfig extends BaseConfig {
  constructor({ 
    ordering = DefaultTabOrdering[TabId.clinical],
    displayName = 'Clinical',
    nextTabName = undefined }) {
    super(TabId.clinical, ordering, displayName, nextTabName);
  }

  public fieldsConfig = 
  {
    primaryDiagnosticCode: new FieldsConfig({displayName: 'Primary Diagnosis Code'}),
  }
}
