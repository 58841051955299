export enum EnumRule {
  useStrict = 1,
  firstLetterLow = 2,
}

type TKey = string | number;
export function enumResolver<TEnum extends {[key in TKey]: any}>(val: TKey, enumType: TEnum, enumRule: EnumRule = EnumRule.useStrict): any {
  let value = undefined;

  if(typeof val === 'string') {
    let resolveName: (any) => any = undefined;

    switch(enumRule){
      case EnumRule.useStrict : resolveName = (name) => name; break;
      case EnumRule.firstLetterLow : resolveName = (name: string) => name[0].toLowerCase() + name.slice(1); break;
      default : return undefined;
    }

    value = resolveName(val);
  }
  else {
    value = val;
  }

  let keys = Object.keys(enumType); keys = keys.slice(keys.length / 2);

  if(keys.includes(value)) return enumType[value];

  let values = Object.values(enumType); values = values.slice(values.length / 2);

  if(values.includes(value)) return value;

  return undefined;
}
