export enum PhasesNames {
  enrollment = 'Enrollment',
  benefitsVerification = 'Benefits Verification',
  priorAuthorization = 'Prior Authorization',
  fulfillment = 'Fulfillment',
  adherence = 'Adherence',
  general = 'General',
  'Enrollment' = 'enrollment',
  'Benefits Verification' = 'benefitsVerification',
  'Prior Authorization' = 'priorAuthorization',
  'Fulfillment' = 'fulfillment',
  'Adherence' = 'adherence',
  'General' = 'general'
}
