<h2>Document Type</h2>

<div class="input-item checkbox">
  <mat-button-toggle-group #duration="matButtonToggleGroup"
                           name="documentType"
                           aria-label="Type"
                           [formControl]="documentControl"
                           (change)="changeFocusFromType($event)"
                           #documentType
                           autofocus
                           class="document-type-group">   
    <mat-button-toggle *ngFor="let type of documentTypes"
                       [value]="type.id">
    {{type.name}}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<form [formGroup]="documentForm"
      (ngSubmit)="changeFocus.emit()"
      novalidate>
  <div class="input-item small"
       *ngIf="documentForm.value.documentType === otherId">
    <label for="otherText">Other</label>

    <input #other
           name="otherText"
           id="otherText"
           formControlName="otherText"
           maxlength="50"
           (blur)="setOtherText.emit()" />
  </div>
</form>
