import {
  Component, HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-toggle-menu',
  templateUrl: './toggle-menu.component.html',
  styleUrls: ['./toggle-menu.component.scss'],
})
export class ToggleMenuComponent implements OnInit {
  @Input() position;
  @Input() minWidth;

  opened = false;
  menuStyle: string;
  buttonClass = 'toggleMenuButton';

  isFocused: boolean;

  @HostListener('document:keydown.esc', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (this.opened) {
      this.opened = false;
    }
  }

  constructor() {
  }

  ngOnInit(): void {
    if (this.position === 'bottom-right') {
      this.menuStyle = 'left: -1px; top: 42px;';
    } else if (this.position === 'bottom-left') {
      this.menuStyle = 'right: 0px; top: 42px;';
    } else if (this.position === 'top-left') {
      this.menuStyle = 'right: 0px; bottom: 42px;';
    } else if (this.position === 'top-right') {
      this.menuStyle = 'left: -1px; bottom: 42px;';
    }
  }

  toggle(): void {
    this.opened = !this.opened;
  }

  close(ev): void {
    if (this.opened) {
      const path = ev.path || (ev.composedPath && ev.composedPath());

      if (!path.some(el => el.classList?.contains(this.buttonClass)) || !this.isFocused) {
        this.opened = false;
      }
    }
  }
}
