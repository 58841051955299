import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import * as DocumentAction from '../../..//store/document/document.actions';
import { IDocumentOptions } from '../../../features/intake/interfaces/document-options.interface';
import { IDocument } from '../../../features/intake/interfaces/document.interface';
import { DeleteDocumentModalComponent } from '../../../features/intake/modals/delete/delete.component';
import { UncertainDocumentModalComponent } from '../../../features/intake/modals/uncertain-identification/uncertain-identification.component';
import { DocumentOptions } from '../../../features/intake/models/document-options.model';
import { NgScrollbar } from 'ngx-scrollbar';
import { DocumentService } from 'src/app/features/intake/services/document.service';
import { DocumentTypeModel } from 'src/app/features/intake/components/document/models/document-type.model';
import { QueueType } from '../../enums/queue-type.enum';
import { getQueueTypeFromUrlSuffix } from '../../helpers/utils';
import { UncertainDataEntryComponent } from 'src/app/features/intake/modals/uncertain-data-entry/uncertain-data-entry.component';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class DocumentViewerComponent {
  public readonly otherId = 6;

  @Input() canDelete = false;
  @Input() isDataEntry = false;
  @Output() escalateDataEntry = new EventEmitter();

  programId;
  documentList;
  documentsOptions: IDocumentOptions = new DocumentOptions();
  dragPosition = {x: 0, y: 0};
  isGrid: boolean;
  documentTypes: DocumentTypeModel[] = [];
  queueType: QueueType;
  queueTypes = QueueType;

  modals = {
    delete: DeleteDocumentModalComponent,
    information: UncertainDocumentModalComponent,
  };

  public get canEscalateDataEntry() { 
    return this.isDataEntry && UncertainDataEntryComponent.HasReasons();
  }

  @ViewChild(NgScrollbar) scrollbar: NgScrollbar;

  constructor(public dialog: MatDialog, private store: Store<any>, public documentService: DocumentService) {
    this.store.select('document').subscribe((state) => {
      this.documentsOptions.selectedDocument = state.selectedDocument;
      this.isGrid = state.grid;
      this.documentList = state.documentList;
      this.documentsOptions.currentDocumentOrder = state.selectedDocument + 1;
      if (this.scrollbar) {
        this.scrollbar.scrollTo({top: state.selectedDocument * 195});
      }

      if (state.documentList?.length && state.programId > 0 && this.programId !== state.programId) { 
        this.programId = state.programId;
        this.initDocumentTypes(state.programId);
      }
    });

    const queueTypeFromUrl = window.location.pathname.replace('/document-', '');
    this.queueType = getQueueTypeFromUrlSuffix(queueTypeFromUrl);
  }
  
  private initDocumentTypes(programId: number) {
    this.documentService.getDocumentTypes(programId).subscribe(
      (res: DocumentTypeModel[]) => { this.documentTypes = res; },
      (error) => { console.log(error); }
    );
  }

  selectDocument(selectedDocument: IDocument): void {
    const index = this.documentList.findIndex((document) => document === selectedDocument);
    this.store.dispatch(new DocumentAction.SetSelectedDocument(index));
    this.clearDocument();
  }

  getDocumentCaption(document) {
    if(!document) return undefined;

    return document.documentType === this.otherId 
    ? document.otherText 
    : this.documentTypes.find(e => e.id === document.documentType)?.name;
  }

  switchDocument(direction: string): void {
    let documentOrder = this.documentsOptions.selectedDocument;
    if (direction === 'prev' && documentOrder > 0) {
      documentOrder--;
    }
    if (direction === 'next' && documentOrder + 1 < this.documentList.length) {
       documentOrder++;
    }
    this.selectDocument(this.documentList[documentOrder]);
  }

  setSelectedDocument(): void {
    this.clearDocument();
    if (this.documentsOptions.currentDocumentOrder) {
      if (this.documentsOptions.currentDocumentOrder > this.documentList.length) {
        this.store.dispatch(new DocumentAction.SetSelectedDocument(this.documentList.length - 1));
      }
    }
  }

  zoomDocument(scale: string): number {
    const scaleStep = 0.25;
    if (scale === 'in' && this.documentsOptions.zoomPercent !== 2) {
      return (this.documentsOptions.zoomPercent += scaleStep);
    }
    if (scale === 'out' && this.documentsOptions.zoomPercent !== 0.5) {
      return (this.documentsOptions.zoomPercent -= scaleStep);
    }
  }

  rotateDocument(): number {
    return (this.documentsOptions.rotate -= 90);
  }

  setGrid(value: boolean): void {
    this.store.dispatch(new DocumentAction.SetGrid(value));
  }

  openDialog(modal): void {
    this.dialog.open(this.modals[modal]);
  }

  setDocumentOptions(value): void {
    this.documentsOptions.documentList[this.documentsOptions.currentDocumentOrder - 1].documentType =
      value.documentType;
    this.documentsOptions.documentList[this.documentsOptions.currentDocumentOrder - 1].patient = value.patient;
    this.switchDocument('next');
  }

  clearDocument(): void {
    this.documentsOptions.zoomPercent = 1;
    this.dragPosition = {x: 0, y: 0};
    this.setGrid(false);
  }
}
