import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-suggested-case',
  templateUrl: './suggested-case.component.html',
})
export class SuggestedCaseComponent  {
  @Input() caseId: number;
  @Input() get patientName(): string { return this._patientName; }
  set patientName(patientName: string) { this._patientName = (patientName ?? '').trim() }

  private _patientName: string = '';

  constructor(
    private router: Router,
    private dialogRef: MatDialog
  ) {
  }

  openCase(): void {
    this.dialogRef.closeAll();
    this.router.navigate(['/case'], {queryParams: {id: this.caseId}});
  }
}
