<table [hidden]="!_territories.length" mat-table
       [dataSource]="dataSource"
       matSort matSortActive="name"
       matSortDirection="asc">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let territory">{{ territory.name }}</td>
  </ng-container>
  <ng-container matColumnDef="remove">
    <th mat-header-cell *matHeaderCellDef>
      <button class="primary-btn" (click)="addTerritory()">
        <mat-icon>add_circle</mat-icon>
        Add Territory
      </button>
    </th>
    <td mat-cell *matCellDef="let territory">
      <button class="remove-button" (click)="removeTerritory(territory.id)">
        <mat-icon>close</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let user; columns: columnsToDisplay"></tr>
</table>

<app-manage-empty-queue *ngIf="!_territories.length"
                        (addFunction)="addTerritory()"
                        type="territories"
></app-manage-empty-queue>
