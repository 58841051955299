import { Component, OnInit } from '@angular/core';
import { casesDataMock } from '../../../../../../testing/mocks/casesDataMock';
import { isThisWeek } from '../../../../shared/helpers/utils';
import { Store } from '@ngrx/store';
import { ICase } from '../../../../shared/interfaces/case.interface';
import { AdvancedSearchOptions } from '../../../../shared/models/advanced-search-options.model';
import { CaseManagementService } from '../../../../shared/services/case-management.service';
import * as CaseAction from '../../../../store/case/case.actions';
import { SearchOptions } from 'src/app/shared/models/search-options.model';
import { IUserQueue } from './interfaces/user-queue.interface';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class ManagerDashboardComponent implements OnInit {
  searchOptions = new SearchOptions();
  intakeQueueList: IUserQueue[];
  queueList: IUserQueue[];

  cases = casesDataMock;
  allCases = this.cases;

  constructor(
    private store: Store<any>,
    private caseManagementService: CaseManagementService,
  ) {
  }

  ngOnInit(): void {
    this.caseManagementService.getIntakeQueues().subscribe(
      (response) => {
        this.intakeQueueList = response;
      }, 
      error => throwError(error));

    this.caseManagementService.getCaseManagementQueues()
      .subscribe((response) => {
        this.queueList = response;
      }, error => throwError(error));
    this.showCasesHandler();
    this.store.dispatch(new CaseAction.RemoveCase());
  }

  clearSearchHandler(): void {
    this.searchOptions = new SearchOptions();
    this.showCasesHandler();
  }

  addSearchHandler(): void {
    this.searchOptions.advancedOptions.push(new AdvancedSearchOptions());
  }

  showDuration(): ICase[] {
    if (this.searchOptions.durationValue === 'today') {
      return (this.cases = this.cases.filter(
        (caseItem) =>
          new Date(caseItem.due).getDate() === new Date().getDate() &&
          new Date(caseItem.due).getMonth() === new Date().getMonth() &&
          new Date(caseItem.due).getFullYear() === new Date().getFullYear()
      ));
    } else if (this.searchOptions.durationValue === 'week') {
      return (this.cases = this.cases.filter((caseItem) => isThisWeek(caseItem.due)));
    } else if (this.searchOptions.durationValue === 'month') {
      return (this.cases = this.cases.filter(
        (caseItem) =>
          new Date(caseItem.due).getMonth() === new Date().getMonth() &&
          new Date(caseItem.due).getFullYear() === new Date().getFullYear()
      ));
    } else {
      return this.cases;
    }
  }

  showStatus(): ICase[] {
    if (this.searchOptions.statusValue === 'all') {
      return this.cases;
    } else if (this.searchOptions.statusValue === 'open') {
      return (this.cases = this.cases.filter((caseItem) => caseItem.status === 'Open'));
    } else if (this.searchOptions.statusValue === 'closed') {
      return (this.cases = this.cases.filter((caseItem) => caseItem.status === 'Closed'));
    } else {
      return this.cases;
    }
  }

  searchCase(): ICase[] {
    const searchValue = this.searchOptions.searchValue.toLowerCase().replace(/[^A-Z0-9]+/gi, '');
    return (this.cases = this.cases.filter(
      (caseItem) =>
        Object.values(caseItem).filter((value) =>
          value
            .toString()
            .toLowerCase()
            .replace(/[^A-Z0-9]+/gi, '')
            .includes(searchValue)
        ).length
    ));
  }

  findByField(): ICase[] {
    this.searchOptions.advancedOptions.forEach((field) => {
      if (field.field && field.search) {
        this.cases = this.cases.filter((caseItem) => {
          if (field.exact) {
            const reg = new RegExp('\\b' + field.search + '\\b');
            return reg.test(caseItem[field.field]);
          } else {
            return caseItem[field.field].toLowerCase().includes(field.search.toLowerCase());
          }
        });
      }
    });

    return this.cases;
  }

  findBetweenDates(): ICase[] {
    const startDate = new Date(this.searchOptions.date.startDate).getTime();
    const endDate = new Date(this.searchOptions.date.endDate).getTime();

    if (startDate && endDate) {
      return (this.cases = this.cases.filter((caseItem) => caseItem.due >= startDate && caseItem.due <= endDate));
    } else if (startDate && !endDate) {
      return (this.cases = this.cases.filter((caseItem) => caseItem.due >= startDate));
    } else if (!startDate && endDate) {
      return (this.cases = this.cases.filter((caseItem) => caseItem.due <= endDate));
    } else {
      return this.cases;
    }
  }

  setAdvancedFieldSearchValue(): void {
    this.searchOptions.searchValue = this.searchOptions.advancedOptions.map((item) => item.search).join();

    if (this.searchOptions.date.startDate || this.searchOptions.date.endDate) {
      this.searchOptions.searchValue += this.searchOptions.searchValue.length ? ', ' : '';
      this.searchOptions.searchValue += this.searchOptions.date.startDate
        ? `${new Date(this.searchOptions.date.startDate).toDateString()}`
        : ', ...';
      this.searchOptions.searchValue += ' - ';
      this.searchOptions.searchValue += this.searchOptions.date.endDate
        ? `${new Date(this.searchOptions.date.endDate).toDateString()}`
        : '...';
    }
  }

  showCasesHandler(): void {
    this.cases = this.allCases;

    this.showDuration();
    this.showStatus();

    if (this.searchOptions.isAdvanced) {
      this.findByField();
      this.findBetweenDates();
      this.setAdvancedFieldSearchValue();
    } else {
      this.searchCase();
    }
  }
}
