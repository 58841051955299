import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IntakeModule } from '../intake/intake.module';
import { ManagerModule } from '../manager/manager.module';
import { SupervisorModule } from '../supervisor/supervisor.module';
import { HomeComponent } from './components/home/home.component';

const components = [
  HomeComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    IntakeModule,
    ManagerModule,
    SupervisorModule,
  ]
})
export class HomeModule {
}
