import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-task-add-button',
  templateUrl: './task-add-button.component.html',
  styleUrls: ['./task-add-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAddButtonComponent {
  @Input() name: string;

  @Output() addEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }
}
