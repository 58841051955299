import { Component, OnInit, NgZone, ViewChild, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-queue-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['../../creation.component.scss', './setup.component.scss'],
})
export class QueueCreationSetupComponent implements OnInit, AfterViewInit {
  @Input() setup;
  @Output() setSetupValueHandler = new EventEmitter();
  @Output() focusNextBtnHandler = new EventEmitter();

  queueForm: FormGroup;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('name') name: ElementRef;
  @ViewChild('description') description: ElementRef;
  @ViewChild('configuration') configuration: ElementRef;

  constructor(private _ngZone: NgZone) {
    this.queueForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      configuration: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.queueForm.patchValue({
      name: this.setup.name,
      description: this.setup.description,
      configuration: this.setup.configuration,
    });
  }

  ngAfterViewInit(): void {
    this.name.nativeElement.focus();
  }

  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  setSetup(): void {
    const setup = {
      name: this.queueForm.value.name,
      description: this.queueForm.value.description,
      configuration: this.queueForm.value.configuration,
    };
    this.setSetupValueHandler.emit(setup);
  }

  focusDescription(event): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.description.nativeElement.focus();
    }
  }

  focusConfiguration(event): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.configuration.nativeElement.firstChild.focus();
    }
  }

  switchConfiguration(event): void {
    if (event.key === 'Tab') {
      event.preventDefault();
      if (this.queueForm.value.configuration === 'pull') {
        this.queueForm.patchValue({
          configuration: 'push',
        });
      } else {
        this.queueForm.patchValue({
          configuration: 'pull',
        });
      }
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      this.focusNextBtnHandler.emit();
    }
  }
}
