<h2>Physician</h2>
<div class="details-wrapper">
  <ng-scrollbar class="custom-scrollbar case-scrollbar">
    <div class="sub-section consent">
      <div class="header">
        Physician
      </div>
      <app-editable-aside-field [fieldId]="'physician'" (editOutside)="openPhysicianEditDialog('physician')">
        <div text class="detail-item">
          <h3>HCP Name</h3>
          <p>{{ physician?.firstName | empty }} {{ physician?.lastName | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>NPI</h3>
          <p>{{ physician?.npi | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>HCP Specialty</h3>
          <p>{{ specialty | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>HCP ID</h3>
          <p>{{ physician?.id | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>DEA</h3>
          <p>{{ physician?.dea | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>SLN</h3>
          <p>{{ sln | empty }}</p>
        </div>
      </app-editable-aside-field>
    </div>
    <div class="divider"></div>
    <div class="sub-section consent">
      <div class="header">
        Office Info
      </div>
      <app-editable-aside-field [fieldId]="'officeInfo'" (editOutside)="openPhysicianEditDialog('office')">
        <div text class="detail-item">
          <h3>Office Name</h3>
          <p>{{ facility?.name | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>Office Address</h3>
          <p>{{ facility?.address?.streetAddress | empty }},
            {{ facility?.address?.city | empty }},
            {{ facility?.address?.zipCode | empty }}
          </p>
        </div>
        <div text class="detail-item">
          <h3>Office Phone</h3>
          <p>{{ officePhone | mask: '(000) 000 - 0000' | empty }}</p>
        </div>
        <div text class="detail-item">
          <h3>Office Fax</h3>
          <p>{{ officeFax | mask: '(000) 000 - 0000' | empty }}</p>
        </div>
      </app-editable-aside-field>
    </div>
    <div class="divider"></div>
    <div class="sub-section">
      <div class="header">
        Contact
      </div>
      <app-editable-aside-field [fieldId]="'contact'" [isEdit]="isEditContact"
                                (editOutside)="editPhysicianContact(physicianContact)"
                                (editClosed)="isEditContact = false"
                                [disableSave]="contactForm.invalid"
                                (saveData)="savePhysicianData($event, ['contactInfos', 'contact', ContactMethod.email, ContactType.work])">
        <div text class="detail-item">
          <h3>Office Contact</h3>
          <p>{{ contactForm.value.contact | empty }}</p>
        </div>
        <div inputs class="detail-item">
          <form [formGroup]="contactForm">
            <div class="input-row input-item" [class]="
            contactForm.controls['contact'].invalid &&
            (contactForm.controls['contact'].touched)
                ? 'input-error'
                : ''
          ">
              <label for="secondPhone">Office Contact</label>
              <input
                (keydown.enter)="savePhysicianData(true, ['contactInfos', 'contact', ContactMethod.email, ContactType.work])"
                #physicianContact id="secondPhone" maxlength="50" formControlName="contact"/>
            </div>
          </form>
        </div>
      </app-editable-aside-field>
      <app-editable-aside-field [fieldId]="'email'" [isEdit]="isEditEmail"
                                (editOutside)="editPhysicianEmail(physicianEmail)" (editClosed)="isEditEmail = false"
                                [disableSave]="emailForm.invalid"
                                (saveData)="savePhysicianData($event, ['contactInfos', 'email', ContactMethod.email, ContactType.work])">
        <div text class="detail-item">
          <h3>Office Contact Email</h3>
          <p>{{ emailForm.value.email | empty }}</p>
        </div>

        <div inputs class="detail-item">
          <form [formGroup]="emailForm">
            <div class="input-row input-item" [class]="
            emailForm.controls['email'].invalid &&
            (emailForm.controls['email'].touched)
                ? 'input-error'
                : ''
          ">
              <label for="email">Email</label>
              <input
                (keydown.enter)="savePhysicianData(true, ['contactInfos', 'email', ContactMethod.email, ContactType.work])"
                #physicianEmail id="email" maxlength="50" formControlName="email" matInput/>
            </div>
          </form>
        </div>
      </app-editable-aside-field>
    </div>
  </ng-scrollbar>
</div>
