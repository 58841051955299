import { AbstractControlOptions, FormControl, ValidatorFn } from '@angular/forms';
import { FieldType } from '../../../interfaces/FieldType';

export class DynamicTaskControl extends FormControl {
  public questionId: number;
  public fieldType: FieldType;

  constructor(formState?: any, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions){
    super(formState, validatorOrOpts);
  }
}
