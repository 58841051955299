/// Please use interpolation.configurator.ts file for configure behavior of interpolation
/// Make changes to this file only in exceptional cases

import { CustomStyler } from './custom-styler';
import { Matcher } from './matcher';
import { BaseSelector } from './selector-base';
import { SplittedSelector } from './splitted-selector';

export class CustomSelector extends BaseSelector {
  private matcher: Matcher;

  public select(selector: string): string {
    if(!this.matcher) throw new Error('matcher is not set');

    const splitted = new SplittedSelector(selector);
    const styler = new CustomStyler(splitted.styles);
    const value = this.matcher[splitted.property];

    const result = styler.canApply ? styler.applyFor(value) : value;

    return result;
  }

  public setModel(model: any): BaseSelector {
    return this;
  }

  public setMatcher(matcher: Matcher): BaseSelector {
    this.matcher = matcher; return this;
  }
}
