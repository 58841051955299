import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';
import { ITaskField } from 'src/app/features/manager/components/case/interfaces/task-field.interface';

@Component({
  selector: 'app-task-checkboxes-advanced',
  templateUrl: './task-checkboxes-advanced.component.html',
  styleUrls: ['./task-checkboxes-advanced.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskCheckboxesAdvancedComponent),
      multi: true,
    }
  ],
})
export class TaskCheckboxesAdvancedComponent extends InputControlAccessorDirective {
  @Input() field: ITaskField;
  @Input() isHistory: boolean;

  updateValue(newValue): void {
    this.value = newValue;
  }
}
