<form novalidate (keydown.enter)="$event.preventDefault()"
      [formGroup]="physicianForm" class="standard-form new-physician">
  <div class="form-row half-width first">
    <div class="input-item" [ngClass]="{
            'input-error':
                physicianForm.controls['firstName'].invalid &&
                physicianForm.controls['firstName'].touched,
            'input-success':
                physicianForm.controls['firstName'].valid &&
                physicianForm.controls['firstName'].touched
          }">
      <label for="firstName">First Name</label>
      <input #newPhysicianName name="firstName" id="firstName" formControlName="firstName"/>
    </div>
  </div>
  <div class="form-row half-width first">
    <div class="input-item" [ngClass]="{
            'input-error':
                physicianForm.controls['lastName'].invalid &&
                physicianForm.controls['lastName'].touched,
            'input-success':
                physicianForm.controls['lastName'].valid &&
                physicianForm.controls['lastName'].touched
          }">
      <label for="lastName">Last Name</label>
      <input name="lastName" id="lastName" formControlName="lastName"/>
    </div>
  </div>
  <div class="form-row">
    <div class="input-item" [ngClass]="{
            'input-error': physicianForm.controls['npi'].invalid && physicianForm.controls['npi'].touched,
            'input-success': physicianForm.controls['npi'].valid && physicianForm.controls['npi'].touched
          }">
      <label for="npi">Physician NPI</label>
      <input name="npi" id="npi" formControlName="npi" mask="0000000000"/>
    </div>
  </div>
  <div class="form-row half-width">
    <div class="input-item select" [ngClass]="{
          'input-error':
              physicianForm.controls['specialty'].invalid && physicianForm.controls['specialty'].touched,
          'input-success':
              physicianForm.controls['specialty'].valid && physicianForm.controls['specialty'].touched
        }">
      <label>Physician Specialty</label>
      <mat-form-field appearance="fill">
        <mat-select panelClass="dropdown-item" formControlName="specialty" name="specialty" placeholder="Select"
                    id="specialty">
          <mat-option value="1">Test 1 Allergy and Immunology</mat-option>
          <mat-option value="2">Test 2</mat-option>
          <mat-option value="3">Test 3</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="form-row controls">
    <button (click)="cancelEvent()" class="cancel-btn" mat-button>
      Cancel
    </button>
    <button class="save-btn" [disabled]="physicianForm.invalid"
            (click)="create.emit(physicianForm)" mat-button>
      Save
    </button>
  </div>
</form>
