import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeleteReason, DeleteReasonNames } from '@shared/enums/delete-reason.enum';
import { UncertainReason, UncertainReasonNames } from '@shared/enums/uncertain-reason.enum';
import { IDocument } from 'src/app/features/intake/interfaces/document.interface';

@Component({
  selector: 'app-supervisor-menu',
  templateUrl: './supervisor-menu.component.html',
  styleUrls: ['./supervisor-menu.component.scss']
})
export class SupervisorMenuComponent {
  @Input() selectedImage: IDocument;
  @Output() deleteCurrentPage = new EventEmitter();

  getReasonText() { 
    if (!this.selectedImage) return '--';

    const reasons = [
      ...this.selectedImage.deleteReasons.map(e => this.getDeleteReason(e)),
      ...this.selectedImage.uncertainReasons.map(e => this.getUncertainReason(e))
    ]

    const text = reasons.join(', ').trim();

    return text || '--';
  }

  private getUncertainReason(reason) {
    return reason === UncertainReason.other
      ? 'Other: ' + (this.selectedImage.otherUncertainReasonName || '--')
      : UncertainReasonNames[reason];
  }

  private getDeleteReason(reason) {
    return reason === DeleteReason.other
      ? 'Other: ' + (this.selectedImage.otherDeleteReasonName || '--')
      : DeleteReasonNames[reason];
  }
}
