export enum InquiryEntityTypes {
  Patient = 1,
  Payer = 2,
  Physician = 3,
  Pharmacy = 4,
  CurrentPatient = 5,
  ProspectivePatient = 6,
  Caregiver = 7,
  PhysicianOfficeOrMedicalFacility = 8
}

export class InquiryEntityTypesNames {
  static readonly [InquiryEntityTypes.Patient] = 'Patient';
  static readonly [InquiryEntityTypes.Payer] = 'Payer';
  static readonly [InquiryEntityTypes.Physician] = 'Physician';
  static readonly [InquiryEntityTypes.Pharmacy] = 'Pharmacy';
  static readonly [InquiryEntityTypes.CurrentPatient] = 'Patient (Current)';
  static readonly [InquiryEntityTypes.ProspectivePatient] = 'Patient (Prospective)';
  static readonly [InquiryEntityTypes.Caregiver] = 'Caregiver';
  static readonly [InquiryEntityTypes.PhysicianOfficeOrMedicalFacility] = 'Physician Office or Medical Facility';
}
