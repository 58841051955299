<app-supervisor-menu *ngIf="supervisorView?.isSupervisorView"
                     (deleteCurrentPage)="deleteCurrentPage()"
                     [selectedImage]="selectedDocumentImage">
</app-supervisor-menu>
<div class="document-sidebar"
     [formGroup]="documentForm">
  <div class="aside-wrapper">
    <app-document-type-list [documentForm]="documentForm"
                            class="document-type"
                            [documentControl]="documentForm.controls.documentType"
                            (changeFocus)="focusPatientField()"
                            (setOtherText)="setDocumentOptions()"
                            (changedType)="setDocumentOptions()">
    </app-document-type-list>

    <div class="patient">
      <h2>Patient</h2>
      <div class="patients-search">
        <div class="input-item search">
          <input matInput
                 type="text"
                 formControlName="search"
                 placeholder="Search by Name"
                 (keydown)="patientNavigate($event)"
                 maxlength="50"
                 autocomplete="off"
                 #searchPatientField
          />
          <mat-icon>search</mat-icon>
          <button mat-button
                  *ngIf="documentForm.value.search"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <ul class="patients-results" *ngIf="documentForm.value.search">
          <ng-scrollbar #patientsListScroll
                        *ngIf="!isPatientLoading && patients.length"
                        [class.shadow]="(showShadow$ | async) && patients.length > 2"
                        [style.minHeight.px]="patients.length < 3 ? patients.length * 152 : 280"
                        [style.height.px]="patients.length < 3 ? patients.length * 152 : 280"
                        class="outer-scrollbar"
          >
            <div class="patient-list" #patientList>
              <li *ngFor="let patient of patients; index as i"
                  (click)="selectPatient(patient)"
                  [class.focused]="focusedPatient === i"
              >
                <h5>
                  {{ patient.firstName }} {{ patient.middleName ? patient.middleName : null }}
                  {{ patient.lastName }}
                </h5>
                <p>
                  <mat-icon>person</mat-icon>
                  {{ patient.socialSecurityNumber | ssn }}
                </p>
                <p>
                  <mat-icon>calendar_today</mat-icon>
                  {{ patient.dateOfBirth | date: "MM / dd / yyyy" }}
                </p>
                <p>
                  <mat-icon>location_on</mat-icon>
                  {{ patient.currentMailingAddress?.address | fulladdress }}
                </p>
                <p>
                  <mat-icon>phone</mat-icon>
                  {{ patient.contactInfos | phone }}
                </p>
              </li>
            </div>
          </ng-scrollbar>
          <li *ngIf="isPatientLoading">
            <mat-spinner></mat-spinner>
          </li>
          <li *ngIf="!isPatientLoading && !patients.length" class="not-found">
            <h5>{{ documentForm.value.search }}</h5>
            <p>Patient Not Found</p>
          </li>
          <li>
            <div class="new-patient">
              <span class="new-patient-name">"{{documentForm.value.search}}"</span>
              <button [disabled]="nameIsInvalid" class="primary-btn" (click)="addPatient()">
                <mat-icon>add_circle</mat-icon>
                New Patient
              </button>
            </div>
            <span [ngClass]="{'error': nameIsInvalid}" class="adding-description">Please include first and last name.</span>
          </li>
        </ul>
      </div>
      <div class="suggest-wrapper" *ngIf="suggestedPatients">
        <button class="suggest-item"
                [ngClass]="{
                  active:
                      documentList[selectedDocument].patient &&
                      patient.name === documentList[selectedDocument].patient?.name
                }"
                *ngFor="let patient of suggestedPatients"
                (click)="selectPatient(patient)"
        >
          <ng-container *ngIf="!patient.id">NP: </ng-container>
          {{ patient.name }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="supervisorView">
    <app-supervisor-footer *ngIf="supervisorView.isSupervisorView; else defaultFooter"
                           [submitIsDisabled]="!isFinished || documentForm.value.documentType === otherId"
                           (submitBatch)="submitSupervisorBatch()">
    </app-supervisor-footer>

    <ng-template #defaultFooter>
      <button *ngIf="
            (isFinished && documentForm.value.documentType !== otherId) ||
            (isFinished && documentForm.value.documentType === otherId && documentForm.value.otherText.trim().length);
            else nextBtn"
              class="next-button next-stack"
              [disabled]="isLoading"
              (click)="getNextStack()"
              #nextStack>
        Next Stack
        <mat-icon>east</mat-icon>
      </button>
      <ng-template #nextBtn>
        <button #nextButton
                class="next-button"
                [disabled]="isLoading"
                (click)="switchDocument()">Next Page
          <mat-icon>east</mat-icon>
        </button>
      </ng-template>
    </ng-template>
  </ng-container>
</div>
