import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { setIndicatorColor } from '@shared/helpers/utils';
import { formatPhone } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-benefit-completion',
  templateUrl: './completion.component.html',
  styleUrls: ['../../../../task.component.scss', '../../benefit.component.scss', './completion.component.scss'],
})
export class BenefitCompletionComponent implements OnInit, OnChanges {
  @Input() settings;
  @Input() case;
  @Input() selectedTask;
  @Input() isDisabled;
  @Input() expanded;

  @Output() validateTaskHandler = new EventEmitter();
  @Output() openPanelHandler = new EventEmitter();
  @Output() closePanelHandler = new EventEmitter();
  @Output() currentSectionHandler = new EventEmitter();

  faxActive = false;
  phoneActive = false;
  contactInfo = null;

  completionForm: FormGroup;
  setIndicatorColor = setIndicatorColor;

  constructor() {
    this.completionForm = new FormGroup({
      coversheet: new FormGroup({
        coversheetNote: new FormControl(null, [Validators.required]),
      }),
    });
  }

  ngOnInit(): void {
    this.completionForm.valueChanges.subscribe(() => {
      this.validateTask(this.completionForm);
    });

    const facility = this.case.facility;
    const patient = this.case.patient;

    this.contactInfo = {
      physician: {
        fax:
          facility && facility.contactInfos && facility.contactInfos.length
            ? formatPhone(facility.contactInfos.find((contact) => contact.contactMethod === ContactMethod.fax)?.contactString) ?? null
            : null,
        name: `${this.case.physician?.firstName || ''} ${this.case.physician?.lastName || ''}`,
      },
      patient: {
        phone:
          patient.contactInfos && patient.contactInfos.length
            ? formatPhone(patient.contactInfos.find((contact) => contact.contactMethod === ContactMethod.phone)?.contactString)
            : null,
        name: `${patient.firstName || ''} ${patient.lastName || ''}`,
      },
    };
  }

  ngOnChanges(changes): void {
    if (changes.isDisabled && changes.isDisabled.currentValue) {
      this.completionForm.disable();
    } else if (changes.isDisabled && !changes.isDisabled.currentValue) {
      this.completionForm.enable();
    }
  }

  openPanel(index): void {
    if (!this.expanded) {
      this.openPanelHandler.emit({index, elementId: 'CompletionPanel', sectionIndex: 4});
    }
  }

  closePanel(index): void {
    this.closePanelHandler.emit(index);
  }

  currentSection(name: string): void {
    this.currentSectionHandler.emit(name);
  }

  validateTask(form): void {
    const data = {
      form,
      formName: 'completion',
    };
    this.validateTaskHandler.emit(data);
  }

  pretendSelected(): void {
    this.faxActive = true;
  }
}
