export const environment = {
  oidc: {
    clientId: `0oaken1mbeJIwCFcU4x6`,
    issuer: `https://dev-253390.okta.com/oauth2/default`,
    redirectUri: `${window.location.origin}/implicit/callback`,
    scopes: ['openid', 'profile', 'email'],
    testing: {
      disableHttpsCheck: `true`,
    },
  },
  resourceServer: {
    apiUrl: 'https://dev-api.p3casemanagement.com/api/v1',
    signalRUrl: 'https://dev-api.p3casemanagement.com/signal-r-hubs',
  },
  production: false,
  googlePlaceAPIKey: 'AIzaSyBO9kKeAZLvfnmKnFDl374XAaj0Q3m1zjw',
  features: {
    queueTrendReport: false
  },
  customer: {
    logo: {
      default: '',
      large: '',
      extra: '',
    },
    application: {
      canAddNewInsurancePlans: true,
    }
  }
};
