<div class="insurances-container" [class.empty]="!insurances?.length">
  <ng-container *ngIf="insurances?.length; else emptySplashTmpl">
    <div *ngIf="pharmacyInsurancesInactive?.length || medicalInsurancesInactive?.length"
         class="default-tabs"
    >
      <div class="tab"
           (click)="setTab(InsuranceTabsEnum.Active)"
           [class.active]="currentTab === InsuranceTabsEnum.Active"
      >
        Active
      </div>
      <div class="tab"
           (click)="setTab(InsuranceTabsEnum.Inactive)"
           [class.active]="currentTab === InsuranceTabsEnum.Inactive"
      >
        Inactive
      </div>
    </div>

    <div class="tab-content"
         [class.active-only]="!pharmacyInsurancesInactive?.length && !medicalInsurancesInactive?.length"
         [ngSwitch]="currentTab"
    >
      <div *ngSwitchCase="InsuranceTabsEnum.Active"
           [class.empty]="!pharmacyInsurancesActive?.length && !medicalInsurancesActive?.length">
        <ng-container *ngIf="pharmacyInsurancesActive?.length || medicalInsurancesActive?.length; else emptyActiveTmpl">
          <ng-container *ngIf="medicalInsurancesActive?.length">
            <div class="table-title">Medical Insurance</div>
            <app-insurances-table [isMedical]="true" [insurances]="medicalInsurancesActive"></app-insurances-table>
          </ng-container>

          <ng-container *ngIf="pharmacyInsurancesActive?.length">
            <div class="table-title">Pharmacy Insurance</div>
            <app-insurances-table [insurances]="pharmacyInsurancesActive"></app-insurances-table>
          </ng-container>
        </ng-container>

        <ng-template #emptyActiveTmpl>
          <div class="empty-splash">
            <img src="assets/icons/icon-empty-box.svg" alt="empty-box"/>
            <div class="title">No Active Benefit Verifications</div>
            <div class="description">
              There are no current active Benefit Verifications, but there are inactive ones.
            </div>

            <button (click)="currentTab = InsuranceTabsEnum.Inactive" class="primary-btn">
              View Inactive
              <img src="assets/icons/icon-arrow-long-right-white.svg" alt="arrow-right"/>
            </button>
          </div>
        </ng-template>
      </div>

      <ng-container *ngIf="pharmacyInsurancesInactive?.length || medicalInsurancesInactive?.length">
        <div *ngSwitchCase="InsuranceTabsEnum.Inactive">
          <ng-container *ngIf="medicalInsurancesInactive?.length">
            <div class="table-title">Medical Insurance</div>
            <app-insurances-table [isMedical]="true" [insurances]="medicalInsurancesInactive"></app-insurances-table>
          </ng-container>

          <ng-container *ngIf="pharmacyInsurancesInactive?.length">
            <div class="table-title">Pharmacy Insurance</div>
            <app-insurances-table [insurances]="pharmacyInsurancesInactive"></app-insurances-table>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #emptySplashTmpl>
    <div class="empty-splash">
      <img src="assets/icons/icon-empty-box.svg" alt="empty-box"/>
      <div class="title">No Benefit Verification History</div>
      <div class="description">
        This case doesn’t have any Benefit Verifications yet.
        Medical and Pharmacy Insurance BVs will show here once completed.
      </div>
    </div>
  </ng-template>
</div>
