import { Component, EventEmitter, HostListener, OnInit, ViewChild } from '@angular/core';
import { CaseStatus } from '../../enums/case-status.enum';
import { CaseSubStatus } from '../../enums/case-sub-status.enum';
import { SearchColumns } from '../../enums/search-columns.enum';
import { enumToArray, toSearchString } from '../../helpers/utils';
import { SearchOptions } from 'src/app/shared/models/search-options.model';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../../services/search.service';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { PickListService } from '@shared/services/pick-list.service';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  state;
  searchOptions = new SearchOptions();
  data = [];
  dataSource;
  columnsToDisplay = enumToArray(SearchColumns);
  searchType = 0;
  isFiltersNotUsed = true;
  isLoaded = undefined;
  phases = [];
  currentOpenedFilter: string;
  onSearchCases = new EventEmitter();
  
  settings = {
    itemPerPage: 20,
    paginationPageCount: null,
    activePage: 0,
    ageType: 'hours',
    filter: {
      show: {
        patient: false,
        payer: false,
        physician: false,
        territory: false,
        phase: false,
        status: false,
        subStatus: false,
      },
      value: {
        patient: null,
        payer: null,
        physician: null,
        territory: null,
        phase: {},
        status: {
          active: false,
          pending: false,
          inProcess: false,
          closed: false,
          completed: false,
        },
        subStatus: {
          dataEntryIncomplete: false,
          missingInformation: false,
          benefitVerification: false,
          followUpWithHCP: false,
          priorAuthorization: false,
          awaitingShipment: false,
          scheduleShipment: false,
          onProduct: false,
        },
      },
      initialValue: {
        patient: null,
        payer: null,
        physician: null,
        territory: null,
        phase: {},
        status: {
          active: false,
          pending: false,
          inProcess: false,
          closed: false,
          completed: false,
        },
        subStatus: {
          dataEntryIncomplete: false,
          missingInformation: false,
          benefitVerification: false,
          followUpWithHCP: false,
          priorAuthorization: false,
          awaitingShipment: false,
          scheduleShipment: false,
          onProduct: false,
        },
      },
    },
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      let shownFilter;
      for (const [key, value] of Object.entries(this.settings.filter.show)) {
        if (this.settings.filter.show[key]) {
          shownFilter = key;
        }
      }
      if (shownFilter) {
        this.applyFilter(shownFilter);
      }
    }
  }

  constructor(
    public dialog: MatDialog,
    public searchService: SearchService,
    private router: Router,
    public pickListService: PickListService,
  ) {
    if (!this.router.getCurrentNavigation().extras.state) return;
    
    this.searchOptions.searchValue = this.router.getCurrentNavigation().extras.state.searchValue;
  }

  ngOnInit(): void {
    this.searchCases();
    this.getAllPhases();

    this.onSearchCases
      .pipe(filter(() => this.searchOptions.searchValue?.length >= 3))
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.searchCases();
      });
  }

  searchCases(): void {
    const data = {
      take: 0,
      search: this.searchOptions.searchValue || '',
      type: this.searchType
    };
    
    this.isLoaded = undefined;

    setTimeout(() => this.isLoaded ??= false, 300);

    this.searchService.getCases(data).subscribe(
      (response) => {
        this.isLoaded = true;
        this.setCases(response.foundCases);
      },
      (error) => {
        throwError(error);
      }
    );
  }

  setCases(cases): void {
    this.data = cases;

    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.settings.filter.initialValue = {...this.settings.filter.value};
    this.setPagination();
  }

  setActivePage(index): void {
    this.settings.activePage = index;
    this.paginator.pageIndex = index;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  openFilter(filter): void {
    if (this.currentOpenedFilter) {
      this.closeFilter(this.currentOpenedFilter);
    }

    this.currentOpenedFilter = filter;
    this.settings.filter.show[filter] = true;
  }

  closeFilter(filter): void {
    this.settings.filter.show[filter] = false;
  }

  clearFilter(filter): void {
    switch (filter) {
      case 'phase':
        this.resetFilterPhases();
        break;
      case 'status':
        this.settings.filter.value[filter] = {
          active: false,
          pending: false,
          inProcess: false,
          closed: false,
          completed: false,
        };
        break;
      case 'subStatus':
        this.settings.filter.value[filter] = {
          dataEntryIncomplete: false,
          missingInformation: false,
          benefitsVerification: false,
          followUpWithHCP: false,
          priorAuthorization: false,
          awaitingShipment: false,
          scheduleShipment: false,
          onProduct: false,
        };
        break;
      default:
        this.settings.filter.value[filter] = this.settings.filter.initialValue[filter];
    }
    this.applyFilter(filter);
  }

  clearAllFilters(): void {
    this.settings.filter.value = {
      ...this.settings.filter.initialValue,
      status: {
        active: false,
        pending: false,
        inProcess: false,
        closed: false,
        completed: false,
      },
      subStatus: {
        dataEntryIncomplete: false,
        missingInformation: false,
        benefitVerification: false,
        followUpWithHCP: false,
        priorAuthorization: false,
        awaitingShipment: false,
        scheduleShipment: false,
        onProduct: false
      },
    };
    this.resetFilterPhases();
    this.applyFilter('');
  }

  checkIsFilterUsed(): void {
    this.isFiltersNotUsed =
      !!this.settings.filter.value.patient === !!this.settings.filter.initialValue.patient &&
      !!this.settings.filter.value.payer === !!this.settings.filter.initialValue.payer &&
      !!this.settings.filter.value.physician === !!this.settings.filter.initialValue.physician &&
      !!this.settings.filter.value.territory === !!this.settings.filter.initialValue.territory &&
      !this.isCheckboxSelected('phase') &&
      !this.isCheckboxSelected('status') &&
      !this.isCheckboxSelected('subStatus');
  }

  setPagination(): void {
    this.settings.paginationPageCount = Math.ceil(this.dataSource.filteredData.length / this.settings.itemPerPage);
  }

  applyFilter(filter): void {
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = this.settings.filter.value;
    this.closeFilter(filter);
    this.checkIsFilterUsed();
    this.setPagination();
  }

  isCheckboxSelected(type): boolean {
    return Object.values(this.settings.filter.value[type]).includes(true);
  }

  getAllPhases(): void {
    this.pickListService.getPhases().subscribe((response) => {
      this.phases = response.value.map(x => x.name);
    });
  }

  resetFilterPhases(): void {
    for (const key in this.settings.filter.value.phase) {
      if (this.settings.filter.value.phase.hasOwnProperty(key)) {
        this.settings.filter.value.phase[key] = false;
      }
    }
  }

  openCase(caseId): void {
    this.router.navigate(['/case'], {queryParams: {id: caseId}});
  }

  customFilterPredicate(): (data: any, filter: any) => boolean {
    const myFilterPredicate = (data, filter): boolean => {
      const patientFilter = filter.patient
        ? toSearchString(data.patient).includes(toSearchString(filter.patient))
        : true;
      const payerFilter = filter.payer ? toSearchString(data.payer).includes(toSearchString(filter.payer)) : true;
      const physicianFilter = filter.physician
        ? toSearchString(data.physician).includes(toSearchString(filter.physician))
        : true;
      const territoryFilter = filter.territory
        ? toSearchString(data.territory).includes(toSearchString(filter.territory))
        : true;

      let phaseFilter = false;
      if (Object.values(filter.phase).includes(true)) {
        phaseFilter = phaseFilter || filter.phase[data.phase];
      } else {
        phaseFilter = true;
      }

      let statusFilter = false;
      if (Object.values(filter.status).includes(true)) {
        statusFilter = statusFilter || filter.status[CaseStatus[data.status]];
      } else {
        statusFilter = true;
      }

      let subStatusFilter = false;
      if (Object.values(filter.subStatus).includes(true)) {
        subStatusFilter = subStatusFilter || filter.subStatus[CaseSubStatus[data.subStatus.name]];
      } else {
        subStatusFilter = true;
      }

      return (
        patientFilter &&
        payerFilter &&
        physicianFilter &&
        territoryFilter &&
        phaseFilter &&
        statusFilter &&
        subStatusFilter
      );
    };

    return myFilterPredicate;
  }

  openNewTab(caseId) {
    window.open(`${window.location.origin}/case?id=${caseId}`, '_blank');
  }
}
