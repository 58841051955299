import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, HostListener, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod, ContactMethodName } from '../../../../../../../../../../shared/enums/contact-method.enum';
import { ContactType } from '../../../../../../../../../../shared/enums/contact-type.enum';
import { DataEntryFormService } from '../../services/form.service';
import { throwError } from 'rxjs';
import { TabState } from '../../../../configuration/tab.state';
import { CustomRequirementsValidator } from '../../../../configuration/validators/custom-requirements.validator';
import { BaseFormComponent } from '../base-form.component';
import { NameResolver } from 'src/app/shared/utilities/name-resolver';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-intake-document-physician-form',
  templateUrl: './physician.component.html',
  styleUrls: ['../../form.component.scss', './physician.component.scss'],
})
export class PhysicianComponent extends BaseFormComponent implements OnInit {
  ContactMethod = ContactMethod;
  ContactMethodName = ContactMethodName;

  @Input('state') public state: TabState;
  @Input('contact') public contact = '';
  @Input('isLoading') public isFormLoading: boolean;
  @Input() get case(): any { return this._case; }
           set case(newCase: any) { this.setNewCase(newCase); }

  @Output() submitForm = new EventEmitter();
  @Output() updateCase = new EventEmitter();

  @ViewChild('addressField') private addressField: ElementRef;
  @ViewChild('officeName') private officeName: ElementRef;
  @ViewChild('facilityName') private facilityName: ElementRef;
  @ViewChild('searchFacility') private searchFacility: ElementRef;
  @ViewChild('searchPhysician') private searchPhysician: ElementRef;
  @ViewChild('physicianFacility') private physicianFacility: ElementRef;
  @ViewChild('facilitySearchList') private facilitySearchList: ElementRef;
  @ViewChild('physicianSearchList') private physicianSearchList: ElementRef;

  private _case;

  public facilityForm: FormGroup;
  public physicianForm: FormGroup;

  public get formInvalid () { return this.facilityForm.invalid || this.physicianForm.invalid }

  public physicians = [];
  public facilities = [];
  public suggestedPhysicians = [];
  public suggestedFacilities = [];
  public focusedSearch = 0;
  public physicianFacilities = false;
  public facilitiesSearch = false;

  public displayedContactMethods = [
    ContactMethod.phone,
    ContactMethod.fax,
    ContactMethod.email,
  ]

  public options = {
    selectedPhysician: null,
    selectedFacility: null,
    isEditingFacility: false,
  };

  constructor(
    private readonly mapsAPILoader: MapsAPILoader,
    private readonly dataEntryService: DataEntryFormService,
  ) { super(); }

  ngOnInit(): void {
    this.initSuggestions();

    this.state.SaveState = () => this.saveState();

    this.facilityForm = this.state.forms.facilityForm;
    this.physicianForm = this.state.forms.physicianForm;

    this.options.selectedFacility = this._case.facility || this.getFacilityCaseObject();
    this.options.selectedPhysician = this._case.physician || this.getPhysicianCaseObject();
  }

  public static initForms(state: TabState, caseData: any) {
    state.forms.facilityForm = PhysicianComponent.getFacilityForm(state.config.fieldsConfig);
    state.forms.physicianForm = PhysicianComponent.getPhysicianForm(state.config.fieldsConfig);

    PhysicianComponent.populateFacilityForm(state.forms.facilityForm, caseData, state.autofillData);
    PhysicianComponent.populatePhysicianForm(state.forms.physicianForm, caseData, state.autofillData);
  }

  private static getFacilityForm(field){
    return new FormGroup({
      hasPhysician: new FormControl(undefined, [CustomRequirementsValidator(field.hasPhysician)]),
      officeName: new FormControl(undefined, [CustomRequirementsValidator(field.officeName)]),
      officeEmail: new FormControl(undefined, [CustomRequirementsValidator(field.officeEmail), Validators.email]),
      facilityName: new FormControl(undefined, [CustomRequirementsValidator(field.facilityName)]),
      tax: new FormControl(undefined, [CustomRequirementsValidator(field.tax)]),
      address: new FormControl(undefined, [CustomRequirementsValidator(field.address)]),
      zipCode: new FormControl(undefined, [CustomRequirementsValidator(field.zipCode)]),
      officePhone: new FormControl(undefined, [CustomRequirementsValidator(field.officePhone)]),
      officeFax: new FormControl(undefined, [CustomRequirementsValidator(field.officeFax)]),
      state: new FormControl(undefined, [CustomRequirementsValidator(field.state)]),
      city: new FormControl(undefined, [CustomRequirementsValidator(field.city)]),
      phone: new FormControl(undefined, [CustomRequirementsValidator(field.phone)]),
      bestMethodToContact: new FormControl(undefined, [CustomRequirementsValidator(field.bestMethodToContact)]),
    });
  }

  private static populateFacilityForm(form, caseData, jsonData) {
    if(!caseData && !jsonData) return;

    const controls = form.controls;
    const casePrescriber = caseData || {};
    const jsonPrescriber = jsonData?.prescribers?.length ? jsonData.prescribers[0] : {};

    const caseFacility = casePrescriber.facility || {};
    const jsonFacility = jsonPrescriber.physicianOffice || {};

    const jsonFax = jsonFacility.fax;
    const jsonPhone = jsonFacility.phone;
    const jsonContactEmail = jsonFacility.contacts?.find(e => e.email).email;
    const jsonContactName = jsonFacility.contacts?.find(e => e.name).name;
    const jsonContactPhone = jsonFacility.contacts?.find(e => e.phone).phone;
    const caseFax = caseFacility.contactInfos?.find(x => x.contactMethod === ContactMethod.fax);
    const casePhone = caseFacility.contactInfos?.find(x => x.contactMethod === ContactMethod.phone);
    const caseEmail = caseFacility.contactInfos?.find(x => x.contactMethod === ContactMethod.email);

    controls.hasPhysician.patchValue(caseData.physician || jsonPrescriber ? '1' : undefined);
    controls.facilityName.patchValue(caseFacility.name || jsonFacility.name);
    controls.address.patchValue(caseFacility.address?.streetAddress || jsonFacility.address?.addressLine1);
    controls.city.patchValue(caseFacility.address?.city || jsonFacility.address?.city);
    controls.state.patchValue(caseFacility.address?.state || jsonFacility.address?.state);
    controls.zipCode.patchValue(caseFacility.address?.zipCode || jsonFacility.address?.zipCode);
    controls.officeName.patchValue(caseEmail?.name || jsonContactName);
    controls.officeEmail.patchValue(caseEmail?.contactString || jsonContactEmail);
    controls.officePhone.patchValue(casePhone?.contactString || jsonContactPhone);
    controls.phone.patchValue(casePhone?.contactString || jsonPhone);
    controls.officeFax.patchValue(caseFax?.contactString || jsonFax);
    controls.bestMethodToContact.patchValue(caseFacility?.preferredContactMethod);

    // this field is not present in json data for now
    controls.tax.patchValue(caseFacility.groupTaxId);

    form.updateValueAndValidity();
  }

  private static getPhysicianForm(field) {
    return new FormGroup({
      id: new FormControl(undefined),
      searchPhysician: new FormControl(undefined),
      searchFacility: new FormControl(undefined),
      physicianName: new FormControl(undefined, [CustomRequirementsValidator(field.physicianName)]),
      npi: new FormControl(undefined, [CustomRequirementsValidator(field.npi), Validators.maxLength(10), Validators.minLength(10)]),
      specialty: this.getControl({field: field.specialty, disabled: field.specialty.isReadOnly}),
    });
  }

  private static populatePhysicianForm(form, caseData, jsonData) {
    if(!caseData && !jsonData) return;

    const controls = form.controls;
    const casePrescriber = caseData.physician || {};
    const jsonPrescriber = jsonData?.prescribers?.length ? jsonData.prescribers[0] : {};

    const name = NameResolver.getNameString(casePrescriber) || NameResolver.getNameString(jsonPrescriber);

    controls.id.patchValue(casePrescriber.id);
    controls.physicianName.patchValue(name);
    controls.npi.patchValue(casePrescriber.npi || jsonPrescriber.npiNumber);

    // this field is not present in json data for now
    controls.specialty.patchValue(casePrescriber.physicianSpecialityId);

    form.updateValueAndValidity();
  }

  private initSuggestions() {
    if (!this.contact) return;
    
    const data = {take: 0, contactString: this.contact};

    this.dataEntryService.getSuggestedPhysicians(data).subscribe(
      (response) => { this.suggestedPhysicians = response; },
      (error) => throwError(error)
    );

    this.dataEntryService.getSuggestedFacilities(data).subscribe(
      (response) => { this.suggestedFacilities = response; },
      (error) => throwError(error)
    );
  }

  private setNewCase(newCase){
    if (this._case || !newCase) return 

    this._case = newCase;

    const hasFacilities = this.hasPhysicianFacilities(this._case.physician);

    this.setPhysicianFacilityVisibility( !this._case.facility && hasFacilities, !this._case.facility && !hasFacilities );
  }

  selectPhysician(physician): void {
    this.options.selectedPhysician = physician;

    if(!physician){
      this.options.selectedFacility = undefined;
    }

    this.facilityForm.patchValue({hasPhysician: physician ? '1' : ''});

    PhysicianComponent.populatePhysicianForm(this.physicianForm, { physician }, undefined);

    this.clearSearchPhysician();

    setTimeout(() => {
      this.focusedSearch = 0;

      const hasFacilities = this.hasPhysicianFacilities(physician);

      this.setPhysicianFacilityVisibility(hasFacilities, !hasFacilities);

      if (!this.physicianFacility) { this.searchPhysician.nativeElement.focus(); }
    }, 0);
  }

  clearSearchPhysician(): void {
    this.physicianForm.patchValue({ searchPhysician: undefined });
  }

  setPhysicianFacilityVisibility(showPhysicianFacilities, showSearch): void {
    this.physicianFacilities = showPhysicianFacilities;
    this.facilitiesSearch = showSearch;
  }

  showFacilitiesSearch(): void {
    this.setPhysicianFacilityVisibility(false, true);
  }

  hasPhysicianFacilities(physician): boolean {
    return physician && physician.addresses && physician.addresses.length > 0;
  }

  selectFacility(facilityObj, isAddress): void {
    const facility = isAddress ? this.getFacilityFromNpiAddress(facilityObj) : facilityObj;

    this.options.selectedFacility = facility;

    this.clearSearchFacility();

    const hasFacilities = this.hasPhysicianFacilities(this.options.selectedPhysician);

    this.setPhysicianFacilityVisibility(!facility && hasFacilities, !facility && !hasFacilities);
    
    this.options.isEditingFacility = false;

    PhysicianComponent.populateFacilityForm(this.facilityForm, {facility: this.options.selectedFacility, physician: this.case.physician}, undefined)

    setTimeout(() => {
      if (this.officeName && this.officeName.nativeElement) {
        this.officeName.nativeElement.focus();
      } else if (this.searchFacility && this.searchFacility.nativeElement) {
        this.searchFacility.nativeElement.focus();
      }
    }, 0);
  }

  selectNewFacility(): void {
    this.options.selectedFacility = this.getNewFacilityObject();

    this.clearSearchFacility();

    setTimeout(() => { this.facilityName?.nativeElement.focus(); }, 0);
  }

  private getNewFacilityObject() { 
    return {
      id: 0,
      groupTaxId: '',
      name: '',
      contactInfos: [],
      address: {
        streetAddress: undefined,
        addressExtension: undefined,
        zipCode: undefined,
        state: undefined,
        city: undefined,
      },
    }
  }

  clearSearchFacility(): void {
    this.physicianForm.patchValue({ searchFacility: undefined });
  }

  editFacility(): void {
    this.options.isEditingFacility = !this.options.isEditingFacility;

    if (!this.options.isEditingFacility) {
      this.options.selectedFacility = this.getFacilityCaseObject();
    }

    setTimeout(() => this.initPlaceAutocomplete(), 10);
  }

  exitEditFacility(): void {
    this.options.isEditingFacility = !this.options.isEditingFacility;

    PhysicianComponent.populateFacilityForm(this.facilityForm, {facility: this.options.selectedFacility, physician: this.case.physician}, undefined);
  }

  searchPhysicians(): void {
    this.clearSearchFacility();

    if (!this.physicianForm.value.searchPhysician || this.physicianForm.value.searchPhysician.length < 3) return;

    const data = {search: this.physicianForm.value.searchPhysician};

    this.dataEntryService.searchPhysicians(data).subscribe(
    (response) => {
      this.physicians = response;
    },
    (error) => throwError(error)
    );
  }

  searchFacilities(): void {
    this.clearSearchPhysician();

    if (!this.physicianForm.value.searchFacility || this.physicianForm.value.searchFacility.length < 3) return;

    const data = {search: this.physicianForm.value.searchFacility};

    this.dataEntryService.searchFacilities(data).subscribe(
      (response) => {
        this.facilities = response;
      },
      (error) => throwError(error)
    );
  }

  searchNavigate(event, search): void {
    if (!this.physicianForm.value.searchPhysician?.length && !this.physicianForm.value.searchFacility?.length) return;

    const searchList = search === 'physician' ? this.physicianSearchList : this.facilitySearchList;

    if (event.key === 'Tab' || event.key === 'ArrowDown') {
      event.preventDefault();
      this.focusedSearch === searchList.nativeElement.children.length - 1
        ? (this.focusedSearch = 0)
        : (this.focusedSearch = this.focusedSearch + 1);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.focusedSearch === 0
        ? (this.focusedSearch = searchList.nativeElement.children.length - 1)
        : (this.focusedSearch = this.focusedSearch - 1);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      if (search === 'physician') {
        this.selectPhysician(this.physicians[this.focusedSearch]);
      } else {
        this.selectFacility(this.facilities[this.focusedSearch], false);
      }
    }
  }

  submitHandler(): any {
    this.saveState();

    if(this.state.isValid) this.submitForm.emit();
  }

  private async saveState() {
    BaseFormComponent.validateForms(this.state);

    if(!this.state.isValid) return;

    await this.saveFacility();
    await this.savePhysician();
    await this.saveCase();

    this.state.isSaved = true;
  }

  saveFacility(): Promise<any> {
    if (!this.options.selectedFacility) return Promise.resolve();

    this.options.selectedFacility = this.getFacilityCaseObject();

    const data = { facility: this.options.selectedFacility };

    if (this.options.selectedFacility?.id) {
      this.dataEntryService.updateFacility(data).subscribe( 
        (response) => { /* Magic :D 💩 */ },
        (error) => throwError(error)
      );
      
      return Promise.resolve();
    } 

    return new Promise((resolve) =>
      this.dataEntryService.createFacility(data).subscribe(
        (response) => {
          this.options.selectedFacility.id = response.value;

          resolve(response);
        },
        (error) => throwError(error)
      )
    );
  }

  savePhysician(): Promise<any> {
    const data = { physician: this.getPhysicianCaseObject() };

    if(!data.physician) return Promise.resolve();

    if (data.physician.id) {
      this.dataEntryService.updatePhysician(data).subscribe(
        (response) => { /* Magic :D 💩 */ },
        (error) => throwError(error)
      );
      return Promise.resolve();
    }

    return new Promise((resolve) =>
      this.dataEntryService.createPhysician(data).subscribe(
        (response) => {
          this.options.selectedPhysician = data.physician;
          this.options.selectedPhysician.id = response.value;

          resolve(response);
        },
        (error) => throwError(error)
      )
    );
  }

  saveCase(): Promise<any> {
    this._case.physicianId = this.options.selectedPhysician?.id;
    this._case.physician = this.options.selectedPhysician;

    this._case.facilityId = this.options.selectedFacility?.id;
    this._case.facility = this.options.selectedFacility;

    if (this._case.facility && this._case.physician && this._case.physician.addresses) {
      const index = this._case.physician.addresses.findIndex((x) => x.id === this._case.facilityId);
      const facilityAddress = this.getFacilityAddressObject(this._case.facility);

      if (index > -1) {
        this._case.physician.addresses[index] = facilityAddress;
      } else {
        this._case.physician.addresses.push(facilityAddress);
      }
    }

    this.updateCase.emit(this._case);

    const data = {
      caseManagementQueueItem: {
        id: this._case.id,
        salesRepId: this._case.salesRepId,
        patientId: this._case.patientId,
        caseInsurances: this._case.caseInsurances,
        physicianId: this._case.physicianId,
        facilityId: this._case.facilityId,
        diagnosisId: this._case.diagnosisId,
        prescriptionId: this._case.prescriptionId,
        programId: this._case.programId
      },
    };

    return new Promise((resolve) =>
      this.dataEntryService.updateCase(data).subscribe(
        (response) => { resolve(response); },
        (error) => throwError(error)
      )
    );
  }
  
  getFacilityFromNpiAddress(address): any {
    const contactInfos = [];

    if (address.telephoneNumber) {
      contactInfos.push({
        name: '',
        contactString: ('' + address.telephoneNumber).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.phone,
        contactType: ContactType.work,
        externalSourceContactId: address.telephoneExternalSourceContactId
      });
    }

    if (address.faxNumber) {
      contactInfos.push({
        name: '',
        contactString: ('' + address.faxNumber).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.fax,
        contactType: ContactType.work,
        externalSourceContactId: address.faxNumberExternalSourceContactId
      });
    }

    if (address.email) {
      contactInfos.push({
        name: address.contactName,
        contactString: address.email,
        primary: true,
        contactMethod: ContactMethod.email,
        contactType: ContactType.work,
        externalSourceContactId: address.emailExternalSourceContactId
      });
    }

    return {
      id: address.id,
      name: address.name,
      address: {
        streetAddress: address.address1,
        addressExtension: address.address2,
        zipCode: address.postalCode,
        state: address.state,
        city: address.city,
      },
      groupTaxId: address.groupTaxId,
      contactInfos,
    };
  }

  getFacilityCaseObject(): any {
    const contactInfos = [];
    const formValue = this.facilityForm.getRawValue();

    if(!formValue.facilityName && !formValue.address) return undefined;
    
    if (formValue.officePhone) {
      contactInfos.push({
        name: '',
        contactString: ('' + formValue.officePhone).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.phone,
        contactType: ContactType.work,
        externalSourceContactId: this.options
          ?.selectedFacility
          ?.contactInfos
          ?.find(x => x.contactMethod === ContactMethod.phone && x.contactType === ContactType.work)
          ?.externalSourceContactId
      });
    }

    if (formValue.officeFax) {
      contactInfos.push({
        name: '',
        contactString: ('' + formValue.officeFax).replace(/\D/g, ''),
        primary: true,
        contactMethod: ContactMethod.fax,
        contactType: ContactType.work,
        externalSourceContactId: this.options
          ?.selectedFacility
          ?.contactInfos
          ?.find(x => x.contactMethod === ContactMethod.fax && x.contactType === ContactType.work)
          ?.externalSourceContactId
      });
    }

    if (formValue.officeEmail) {
      contactInfos.push({
        name: formValue.officeName,
        contactString: formValue.officeEmail,
        primary: true,
        contactMethod: ContactMethod.email,
        contactType: ContactType.work,
        externalSourceContactId: this.options
          ?.selectedFacility
          ?.contactInfos
          ?.find(x => x.contactMethod === ContactMethod.email && x.contactType === ContactType.work)
          ?.externalSourceContactId
      });
    }

    return {
      id: this.options?.selectedFacility?.id || 0,
      name: formValue.facilityName,
      address: {
        streetAddress: formValue.address,
        addressExtension: this.options?.selectedFacility?.address?.addressExtension,
        zipCode: formValue.zipCode,
        state: formValue.state,
        city: formValue.city,
      },
      groupTaxId: formValue.tax,
      contactInfos,
      phone: formValue.phone,
      preferredContactMethod: formValue.bestMethodToContact
    };
  }

  getFacilityAddressObject(facility): any {
    const phone = facility.contactInfos.find((x) => x.contactMethod === ContactMethod.phone);
    const fax = facility.contactInfos.find((x) => x.contactMethod === ContactMethod.fax);
    const email = facility.contactInfos.find((x) => x.contactMethod === ContactMethod.email);

    return {
      id: facility.id,
      telephoneNumber: phone ? phone.contactString : '',
      faxNumber: fax ? fax.contactString : '',
      email: email ? email.contactString : '',
      contactName: email ? email.name : '',
      name: facility.name,
      address1: facility.address.streetAddress,
      address2: facility.address.addressExtension,
      postalCode: facility.address.zipCode,
      city: facility.address.city,
      state: facility.address.state,
      groupTaxId: facility.groupTaxId,
    };
  }

  getPhysicianCaseObject(): any {
    const formValue = this.physicianForm.getRawValue();

    if(!formValue.physicianName && !formValue.specialty) return undefined;

    let physicianFacilities = [];

    const specialtyId = formValue.specialty;

    const specialty = specialtyId ? {id: specialtyId, name: null, code: null, license: null} : null;

    if (this.options.selectedPhysician) {
      physicianFacilities = this.options.selectedPhysician.addresses?.filter((x) => x.id).map((x) => x.id);

      if (physicianFacilities && this.options.selectedFacility && !physicianFacilities.includes(this.options.selectedFacility.id) ) {
        physicianFacilities.push(this.options.selectedFacility.id);
      }

      if ( specialty && this.options.selectedPhysician.taxonomies.length ) {
        const taxonomy = this.options.selectedPhysician.taxonomies.find(x => x.primary);
        specialty.name = taxonomy?.desc;
        specialty.code = taxonomy?.code;
        specialty.license = taxonomy?.license;
      }
    }

    const name = NameResolver.parse(formValue.physicianName);

    return {
      id: this.options.selectedPhysician?.id || 0,
      firstName: name.firstName,
      lastName: name.lastName,
      npi: formValue.npi,
      physicianSpecialityId: specialtyId,
      physicianSpeciality: specialty,
      physicianFacilities,
    };
  }

  private async initPlaceAutocomplete() {
    if (!this.addressField) return;

    await this.mapsAPILoader.load();

    const autocomplete = new google.maps.places.Autocomplete(
      this.addressField.nativeElement,
      {
        componentRestrictions: { country: 'US' },
        fields: ['address_components', 'formatted_address']
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();

      const city = place.address_components
        ? place.address_components.find(x => x.types.includes('locality'))
        : null;

      const state = place.address_components
        ? place.address_components.find(x => x.types.includes('administrative_area_level_1'))
        : null;

      const zipCode = place.address_components
        ? place.address_components.find(x => x.types.includes('postal_code'))
        : null;

      const splittedAddress = place.formatted_address
        ? place.formatted_address.split(', ')
        : [];

      const address = splittedAddress.length > 0
        ? splittedAddress[0]
        : '';

      this.facilityForm.get('address').setValue(address ? address : '');
      this.facilityForm.get('zipCode').setValue(zipCode ? zipCode.long_name : '');
      this.facilityForm.get('city').setValue(city.long_name);
      this.facilityForm.get('state').setValue(state.short_name);
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if ( !this.physicianFacilities || ['ArrowDown', 'Tab', 'ArrowUp', 'Enter'].every(e => e !== event.key) ) return;
    
    event.preventDefault();

    if (event.key === 'Tab' || event.key === 'ArrowDown') {
      this.focusedSearch === this.physicianFacility.nativeElement.children.length - 1
        ? (this.focusedSearch = 0)
        : (this.focusedSearch = this.focusedSearch + 1);
    } else if (event.key === 'ArrowUp') {
      this.focusedSearch === 0
        ? (this.focusedSearch = this.physicianFacility.nativeElement.children.length - 1)
        : (this.focusedSearch = this.focusedSearch - 1);
    }
    else if (event.key === 'Enter') {
      this.selectFacility(this.options.selectedPhysician.addresses[this.focusedSearch], true);
    }
  }
}
