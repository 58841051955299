// Use this file when adding some new business rules in the future

const PasswordConfiguration = {
  ValidationPattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/,
  ValidationString: '^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$',
  
  RequirementsText: 'Must be at least 7 characters long, 1 upper case letter, 1 number and 1 spec symbol',
};

const UserProfiles = {
  SystemAdmin: { id: 1 },
  ProgramAdmin: { id: 2 },
  AgentAll: { id: 3 },
  AgentRestricted: { id: 4 },
}

export class Configuration {
  public static readonly Password = Object.freeze(PasswordConfiguration);
  public static readonly UserProfiles = Object.freeze(UserProfiles);
}
