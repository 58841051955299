<div class="task-wrapper" *ngIf="case.isOpen">
  <div class="task-title">
    <h2>Current Tasks</h2>
    <button class="primary-btn invert" #createActivityEl (click)="createActivityEl.blur();createActivity.emit();">
      <mat-icon>add</mat-icon>
      Create Activity
    </button>
  </div>
  <div class="task-table">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="phase">
        <th mat-header-cell *matHeaderCellDef>Phase</th>
        <td mat-cell *matCellDef="let task">
          <img *ngIf="task.contactMethod === ContactMethod.supervisor" src="assets/icons/icon-escalade-red.svg" alt="escalade"/>
          <span> {{ task.phase }} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef>Task</th>
        
        <td mat-cell *matCellDef="let task">
          <span class="attempt" *ngIf="task.attemptNumber > 1">ATTEMPT {{ task.attemptNumber }}</span>

          <div class="task-name"
               (click)="(task.taskId !== ConfigsContract.SupervisorTaskId || hasAccessToSupervisorTasks) && !task.locked
               && setSelectedTask(task)">
            <span *ngIf="task.taskId === ConfigsContract.SupervisorTaskId" class="bold">Escalated: </span> {{task.task}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="due">
        <th mat-header-cell *matHeaderCellDef>Due</th>
        <td mat-cell *matCellDef="let task">{{ task.due | date: "EEE, M/d" }}</td>
      </ng-container>

      <ng-container matColumnDef="button">
        <th mat-header-cell *matHeaderCellDef> </th>

        <td mat-cell *matCellDef="let task">
          <button *ngIf="(task.taskId !== ConfigsContract.SupervisorTaskId || hasAccessToSupervisorTasks)"
                  (click)="setSelectedTask(task)">
            <ng-container *ngIf="task.taskId !== ConfigsContract.SupervisorTaskId">
              <span>Start</span>
              <img src="assets/icons/icon-arrow-back.svg" alt="start"/>
            </ng-container>

            <ng-container *ngIf="task.taskId === ConfigsContract.SupervisorTaskId">
              <span>Resolve</span>
              <img src="assets/icons/icon-material-arrow-right-red.svg" alt="start"/>
            </ng-container>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr [ngClass]="{'escalated': task.taskId === ConfigsContract.SupervisorTaskId}" mat-row *matRowDef="let task; columns: columnsToDisplay"></tr>
    </table>
    <div class="empty-current-tasks" *ngIf="!data?.length">No current tasks; try checking here later.</div>
  </div>
</div>

<div *ngIf="!case.isOpen" class="closed-case">
  <mat-icon class="icon">check_circle</mat-icon>
  <span class="text">Case Closed</span>
</div>
