import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { CaseService } from '../../services/case.service';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as CaseAction from '../../../../../../store/case/case.actions';
import { ExhaustedTaskComponent } from './dialogs/exhausted/exhausted.component';
import { RescheduleTaskComponent } from './dialogs/reschedule/reschedule.component';
import { TaskFooterComponent } from './components/task-footer/task-footer.component';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { UnsubscribeOnDestroy } from '@core/classes/UnsubscribeOnDestroy';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { isBlank } from '@shared/utilities/empty-checks';
import { ContactMethodCaptions } from '@shared/utilities/contact-method-captions';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EscalateTaskComponent } from './dialogs/escalate-task/escalate-task.component';
import { DynamicTaskControl } from './classes/dynamic-task.control';
import { ConfigsContract } from './contract/configs.contract';
import { TasksFormService } from './services/task-forms.service';
import { ITaskBlock } from '../../interfaces/task-block.interface';
import { ITaskField } from '../../interfaces/task-field.interface';
import { ITaskGroup } from '../../interfaces/task-group.interface';
import { ITaskSection } from '../../interfaces/task-section.interface';
import { ITask } from '../../interfaces/task.interface';
import { FieldType } from '../../interfaces/FieldType';
import { ITaskBody } from '../../interfaces/task-body.interface';
import { InterpolatedString } from '@shared/utilities/string-interpolation/interpolated-string';
import { Matcher } from '@shared/utilities/string-interpolation/matcher';
import { FilesService } from '@shared/services/files.service';
import { validateAllFormFieldsExplicitly } from '@shared/utilities/form-utilities';
import { TargetNames } from '@shared/enums/target-names.enum';
import { CoverageType } from 'src/app/shared/enums/insurance/coverage-type.enum';
import { PlanOrder } from 'src/app/shared/enums/plan-order.enum';
import { DocumentTypeName } from '@shared/enums/document-types.enum';

@Component({
  selector: 'app-dynamic-tasks-renderer',
  templateUrl: './dynamic-tasks-renderer.component.html',
  styleUrls: ['./dynamic-tasks-renderer.component.scss'],
})
export class DynamicTasksRendererComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
  @Input() case;
  @Input() isHistory;
  @Input() selectedTask;

  @Output() closeCaseHandler = new EventEmitter();
  @Output() minimizeTaskHandler = new EventEmitter();
  @Output() setSelectedTaskHandler = new EventEmitter();

  @Output() submitTaskHandler = new EventEmitter<any>();
  @Output() rescheduleTaskHandler = new EventEmitter<any>();
  @Output() escalateToSupervisorHandler = new EventEmitter<any>();

  @ViewChild('taskFooter') taskFooter?: TaskFooterComponent;

  taskConfigurations: ITask;
  taskFormData: FormGroup;
  isLoading = false;
  loaderText = 'One moment, merging your document…';

  private flatFields;
  private flatControls;

  private readonly interpolator: InterpolatedString = new InterpolatedString();

  constructor(
    protected fb: FormBuilder,
    public dialog: MatDialog,
    private store: Store<any>,
    public caseService: CaseService,
    public filesService: FilesService,
    private tasksFormService: TasksFormService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setInfoForHeader(this.case);
    this.getConfigurations();

    this.interpolator.setMatcher(this.getMatcher()).setModel(this.case);
  }

  ngOnDestroy(): void {
    const headerInfo = {
      id: this.case.id,
      patient: `${this.case.patient.firstName} ${this.case.patient.lastName}`,
      queue: null,
      tasks: null,
    };

    if (!this.selectedTask.minimized) {
      this.store.dispatch(new CaseAction.SetCase(headerInfo));
    }
  }

  minimizeTask(val: boolean): void {
    this.minimizeTaskHandler.emit(val);
  }

  setSelectedTask(task): void {
    this.setSelectedTaskHandler.emit(task);
  }

  downloadDocument(field: ITaskField): void {
    if (field.id === ConfigsContract.NewDocumentData) { 
      this.caseService.downloadAttachmentByKey(field.documentData.attachmentS3Key)
        .subscribe(
          resp => {
            const fileName = resp.headers
              .get('content-disposition')?.split('filename=')[1]
              .split(';')[0].replace(/['"]+/g, '') || DocumentTypeName[field.documentData.type as any];
            
            this.filesService.downloadFile(resp.body, fileName);
          }
        )
    }
    else { 
      this.getMergedDocument((res, fileName) => {
        this.filesService.downloadFile(res, fileName);
      });
    }
  }

  previewDocument(field): void {
    if (field.id === ConfigsContract.NewDocumentData) {
      this.caseService.downloadAttachmentByKey(field.documentData.attachmentS3Key)
        .subscribe(
          resp => {
            const fileName = resp.headers
              .get('content-disposition')?.split('filename=')[1]
              .split(';')[0].replace(/['"]+/g, '') || DocumentTypeName[field.documentData.type as any];

            const url = window.URL.createObjectURL(resp.body);
            const newTab = window.open(url, fileName);

            setTimeout(() => {
              newTab.name = fileName;
            }, 1000);
          }
        )
    }
    else { 
      this.getMergedDocument((res, fileName) => {
        const url = window.URL.createObjectURL(res);
        const newTab = window.open(url, fileName);

        setTimeout(() => {
          newTab.name = fileName;
        }, 1000);
      });
    }
  }

  private getCaseAttachment() { 
    
  }

  private getMergedDocument(onLoad: (res, fileName) => void): void {
    this.isLoading = true;

    const outcomeNotes = this.flatControls.find(e => e.questionId === ConfigsContract.OutcomeNoteId)?.value;
    const coversheetNotes = this.flatControls.find(e => e.questionId === ConfigsContract.CoversheetNoteId)?.value;
    const attachmentIds = this.flatControls.find(e => e.questionId === ConfigsContract.AttachmentsControlId)?.value?.map(e => e.id);

    const command = {
      queueItemId: this.selectedTask.id,
      outcomeNotes,
      coversheetNotes,
      attachmentIds
    };

    this.caseService.mergeDocument(command).subscribe(
      (resp) => {
        const fileName = resp.headers
          .get('content-disposition')?.split('filename=')[1]
          .split(';')[0].replace(/['"]+/g, '') || this.selectedTask.task;

        onLoad(resp.body, fileName);
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  rescheduleTask(createManualFollowUp = false): void {
    const maxAttempts = this.selectedTask.maxAttempts || ConfigsContract.UnlimitedAttempts;
    const currentAttempt = this.selectedTask.attemptNumber;

    if (maxAttempts !== ConfigsContract.UnlimitedAttempts && maxAttempts <= currentAttempt && !createManualFollowUp) {
      this.exhaustedTask();
      return;
    }

    const rescheduleTiming = this.selectedTask.rescheduleTiming;

    const dialogData: any = {
      attemptNumber: this.selectedTask.attemptNumber,
      rescheduleTiming,
      contactMethod: this.selectedTask.contactMethod,
      task: this.selectedTask.task,
      caseId: this.case.id,
      patientId: this.case.patient.id,
      taskId: this.selectedTask.taskId
    };

    this.dialog.open(RescheduleTaskComponent, {data: dialogData})
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        const answers = this.answerOnQuestions(this.flatControls, this.selectedTask.questions);
        const outcomeNotes = this.flatControls.find(e => e.questionId === ConfigsContract.OutcomeNoteId)?.value;
        const coversheetNotes = this.flatControls.find(e => e.questionId === ConfigsContract.CoversheetNoteId)?.value;

        const command = {
          ...result,
          queueItemId: this.selectedTask.id,
          answers,
          outcomeNotes,
          coversheetNotes
        };

        this.rescheduleTaskHandler.emit(command);
      });
  }

  exhaustedTask(): void {
    this.dialog.open(ExhaustedTaskComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result?.createManualFollowUp) {
          this.rescheduleTask(result.createManualFollowUp);
        } else if (result?.escalateToSupervisor) {
          this.escalateToSupervisor();
        }
      });
  }

  escalateToSupervisor(): void {
    this.dialog.open(EscalateTaskComponent)
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        this.escalateToSupervisorHandler.emit({
          ...result,
          queueItemId: this.selectedTask.id,
        });
      });
  }

  setInfoForHeader(caseInfo): void {
    this.caseService.getQueueCount(this.selectedTask.queueId).subscribe(
      (response) => {
        const headerInfo = {
          id: caseInfo.id,
          patient: `${caseInfo.patient.firstName} ${caseInfo.patient.lastName}`,
          queue: caseInfo.queueConfigurationName,
          tasks: response,
        };
        this.store.dispatch(new CaseAction.SetCase(headerInfo));
      },
      (error) => throwError(error)
    );
  }

  private getConfigurations(): void {
    this.caseService.getTasksConfigurations(this.selectedTask.fileName)
      .pipe(take(1))
      .subscribe((configData: ITask) => {
        if (this.selectedTask.contactMethod !== 1) {
          this.loaderText = '';
        }

        this.taskFormData = this.tasksFormService.getForm(configData.body);
        this.taskConfigurations = this.setupConfig(configData, this.selectedTask);
        this.checkBlocksForVisibility();
      });
  }

  private setupConfig(configData: ITask, taskData: any): ITask {
    this.flatFields = this.tasksFormService.getFlatFields(configData.body.groups);
    this.flatControls = this.tasksFormService.getFlatControls(this.taskFormData);

    this.initScripts(configData.body, taskData);

    this.flatFields.forEach(field => {
      const question = taskData.questions.find(e => e.question.id === field.id);
      const control = this.flatControls.find(e => e.questionId === field.id);

      if (question) {
        field.name ??= question.question.text;

        if (field.id === ConfigsContract.NewDocumentData && question.answer) { 
          field.documentData = JSON.parse(question.answer);
        }
      }

      if (field.changeListeners?.length) {
        this.bindObserver(field, control);
      }

      switch (field.type) {
        case FieldType.ToggleGroup:
        case FieldType.CheckboxesAdvanced:
          field.options = question.question.options;
          break;

        case FieldType.ContactItem:
          const contactMethod = this.selectedTask.contactMethod;

          if (isBlank(contactMethod)) {
            field.isHidden = true;
          } else {
            field.name = ContactMethodCaptions.ForContactCard(contactMethod);
            field.iconName = ContactMethodCaptions.Icon(contactMethod);
            field.value = this.getTaskContact();
          }
          break;
      }
    });

    if (configData.footer.escalateButton) {
      configData.footer.escalateButton.isVisible ||= (taskData.attemptNumber >= configData.footer.escalateButton.showAfter);
    }

    return configData;
  }

  private getTaskContact(): void {
    let contact;

    const entity = this.selectedTask.target;
    const contactMethod = this.selectedTask.contactMethod;
    const now = new Date();

    if (entity === TargetNames.Payer) {
      let patientInsurances = this.case.caseInsurances?.map((i) => i.patientInsurance);

      patientInsurances = patientInsurances?.filter(i => i != null && new Date(i.effectiveFrom) < now && new Date(i.effectiveTo) > now &&
        i.coverageTypeId === CoverageType.medical && i.insurancePriorityId != null)
        ?.sort((i1, i2) => i1.insurancePriorityId - i2.insurancePriorityId);

      if (patientInsurances && patientInsurances.length && PlanOrder[patientInsurances[0].insurancePriorityId]) {
        contact = patientInsurances[0].planPhone;
      }
    } else {
      contact = this.case.patient?.contactInfos?.find(e => e.contactMethod === contactMethod)?.contactString;
    }

    return contact || '--';
  }

  private initScripts(body: ITaskBody, taskData): void {
    if (!body.taskScripts) {
      return;
    }

    if (body.currentScript === undefined) {
      body.currentScript = -1;
    }

    body.taskScripts.forEach(scriptConfig => {
      const script = taskData.scripts.find(e => e.id === scriptConfig.scriptId);

      if (!script) {
        return;
      }

      scriptConfig.header ??= script.header;
      scriptConfig.rawScript = script.text;

      scriptConfig.mergedScript = this.interpolator.setInitialString(script.text).toString();
    });
  }

  // Add custom for interpolator selectors here if need
  private getMatcher(): Matcher {
    return {
      $PatientPhone: this.case?.patient?.contactInfos?.find(e => e.contactMethod === ContactMethod.phone)?.contactString || ' '
    };
  }

  submitTask(): void {
    validateAllFormFieldsExplicitly(this.taskFormData);

    if (this.taskFormData.invalid) {
      return;
    }

    this.isLoading = true;

    const answers = this.answerOnQuestions(this.flatControls, this.selectedTask.questions);
    const outcomeNotes = this.flatControls.find(e => e.questionId === ConfigsContract.OutcomeNoteId)?.value;
    const noteForAgent = this.flatControls.find(e => e.questionId === ConfigsContract.NoteForAgent)?.value;
    const coversheetNotes = this.flatControls.find(e => e.questionId === ConfigsContract.CoversheetNoteId)?.value;
    const attachmentIds = this.flatControls.find(e => e.questionId === ConfigsContract.AttachmentsControlId)?.value?.map(e => e.id);

    const command = {
      queueItemId: this.selectedTask.id,
      outcomeNotes,
      coversheetNotes,
      answers,
      attachmentIds,
      noteForAgent
    };

    this.submitTaskHandler.emit(command);
  }

  stopLoading(): void {
    this.isLoading = false;
  }

  private answerOnQuestions(controls: DynamicTaskControl[], answers: any[]): any[] {
    controls.forEach(control => {
      // Nested options
      if (control.fieldType === FieldType.CheckboxesAdvanced) {
        const a = answers.find(e => e.question.id === control.questionId);
        a.chosenOptions = this.getSelectedOptions(a.question.options);
        return;
      }

      // don`t set answer if it`s blank
      if (isBlank(control.value)) {
        return;
      }

      // Date range
      if (control.value.dateStart && control.value.dateEnd.value) {
        let a = answers.find(e => e.question.id === control.value.dateStart.id);
        a.answer = control.value.dateStart.value;

        a = answers.find(e => e.question.id === control.value.dateEnd.id);
        a.answer = control.value.dateEnd.value;

        return;
      }

      // Other values
      const answer = answers.find(e => e.question.id === control.questionId);
      if (answer) {
        answer.answer = control.value.toString();
      }
    });

    return answers;
  }

  private getSelectedOptions(options: any[]): any[] {
    const result = options.filter(e => e.isSelected).map(e => ({
      optionId: e.id,
      note: e.note,
      options: e.options,
    }));

    // sets selected children
    result.forEach(e => {
      if (e.options?.length) {
        e.options = this.getSelectedOptions(e.options);
      }
    });

    return result;
  }

  private bindObserver(field: ITaskField, control: AbstractControl): void {
    control.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => {
        const listener = field.changeListeners.find(e => e.value === value);

        if (!listener) {
          return;
        }

        this.flatFields.filter(e => listener.fieldsToHide?.includes(e.id)).forEach(f => {
          f.isHidden = true;
        });

        this.flatControls.filter(e => listener.fieldsToHide?.includes(e.questionId)).forEach((c: AbstractControl) => {
          if (c.value && c.value.dateStart && c.value.dateEnd) {
            c.value.dateStart.value = undefined;
            c.value.dateEnd.value = undefined;
          } else {
            c.setValue(undefined);
          }
        });

        this.flatFields.filter(e => listener.fieldsToShow?.includes(e.id)).forEach(f => {
          f.isHidden = false;
        });

        this.taskFooter.setRescheduleBtnState(listener.rescheduleIsDisabled);
        this.taskFooter.setEscalateBtnState(listener.escalateIsDisabled);
        this.taskFooter.setSubmitBtnState(listener.submitIsDisabled);

        this.taskFooter.setEscalateBtnVisibleState(listener.escalateIsVisible === undefined ? true : listener.escalateIsVisible);

        if (listener.setScript !== undefined) {
          // tslint:disable-next-line
          this.taskConfigurations.body.currentScript = listener.setScript!;
        }

        if (listener.customActions?.length) {
          listener.customActions.forEach(action => {
            this.runCustomAction(action);
          });
        }

        this.checkBlocksForVisibility();
      });
  }

  private runCustomAction(action): void {
    switch (action) {
      case 'CloseCase':
        this.closeCaseAction();
        break;
    }
  }

  private closeCaseAction(): void {
    this.closeCaseHandler.emit();
  }

  private checkBlocksForVisibility(): void {
    this.taskConfigurations.body.groups.forEach((group: ITaskGroup) => {
      group.sections.forEach((section: ITaskSection) => {
        section.blocks.forEach((block: ITaskBlock) => {
          block.isHidden = block.fields.every(el => el.isHidden);
        });
      });
    });
  }
}
