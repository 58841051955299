import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InsurancePlanType } from '@shared/enums/insurance-plan-type.enum';
import { CoverageType } from '@shared/enums/insurance/coverage-type.enum';
import { PlanOrder } from '@shared/enums/plan-order.enum';
import { phoneValidator } from '@shared/helpers/utils';
import { PickListService } from '@shared/services/pick-list.service';
import { Emptiness } from '@shared/utilities/empty-checks';
import { enumResolver, EnumRule } from '@shared/utilities/enum-resolver';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { TabState } from '../../../../../configuration/tab.state';
import { DataEntryFormService } from '../../../services/form.service';
import { BaseFormComponent } from '../../base-form.component';

@Component({
  selector: 'app-medical-insurance',
  templateUrl: './medical-insurance.component.html',
  styleUrls: ['./medical-insurance.component.scss', '../../../form.component.scss', '../../physician/physician.component.scss', './../insurance.component.scss']
})
export class MedicalInsuranceComponent implements OnInit {
  isValid = (c) => BaseFormComponent.isControlValid(c);
  isInvalid = (c) => !BaseFormComponent.isControlValid(c);

  @Input() state: TabState;
  @Input() insuranceForm: FormGroup;

  @Output() onInsuranceRemoved = new EventEmitter<number>();

  @ViewChild('searchList') searchList: ElementRef;

  public selectedPriority;

  public showPlan = false;
  public isPlansLoading = false;
  public focusedOnSearch = 0;
  public relationships = [];

  public medicalPlans = [];
  public disabledDate = new Date();

  public searchControl: FormControl = new FormControl();

  public readonly binaryOptions = ['Yes', 'No'];
  public readonly extendedBinaryOptions = [...this.binaryOptions, 'Unknown'];
  public readonly planTypes = ['Private/Commercial', 'Medicare Part D', 'Medicare Advantage', 'Medicaid', 'VA or Military'];

  constructor(
    private readonly pickListService: PickListService,
    private readonly dataEntryService: DataEntryFormService,
  ) { }

  ngOnInit(): void {
    this.showPlan = this.insuranceForm.controls.planId.value || this.insuranceForm.controls.planName.value;

    this.selectedPriority ??= this.insuranceForm.controls.planOrder.value;

    this.loadCollections();
  }

  private loadCollections() {
    if (this.state.config.fieldsConfig.policyHolderRelationship.isVisible) {
      this.loadRelationships();
    }
  }

  private loadRelationships() {
    this.pickListService
      .getAllRelationships()
      .subscribe(
        res => {
          if (res.success) this.relationships = res.value;
        }
      );
  }

  public static getNewForm(field, order): FormGroup {
    return new FormGroup({
      caseInsuranceId: new FormControl(0),
      patientInsuranceId: new FormControl(0),
      isInsuranceRemoved: new FormControl(false),
      externalSourceId: new FormControl(undefined),
      
      planId: BaseFormComponent.getControl({ field: field.planId, skipValidation: true }),
      planName: BaseFormComponent.getControl({ field: field.planName, skipValidation: true }),
      planType: BaseFormComponent.getControl({ field: field.planType, disabled: field.planType.isReadOnly, skipValidation: true }),
      planOrder: BaseFormComponent.getControl({ field: field.planOrder, value: order, skipValidation: true }),
      planPhone: BaseFormComponent.getControl({ field: field.planPhone, validators: [phoneValidator], skipValidation: true }),
      payerId: BaseFormComponent.getControl({ field: field.payerId, skipValidation: true }),
      payerName: BaseFormComponent.getControl({ field: field.payerName, skipValidation: true }),
      payerPhone: BaseFormComponent.getControl({ field: field.payerPhone, validators: [phoneValidator], skipValidation: true }),
      group: BaseFormComponent.getControl({ field: field.group, skipValidation: true }),
      policyHolderName: BaseFormComponent.getControl({ field: field.policyHolderName, skipValidation: true }),
      policyHolderDOB: BaseFormComponent.getControl({ field: field.policyHolderDOB, skipValidation: true }),
      policyHolderRelationship: BaseFormComponent.getControl({ field: field.policyHolderRelationship, skipValidation: true }),
      memberId: BaseFormComponent.getControl({ field: field.memberId, skipValidation: true }),
    });
  }

  public static populateForm({ controls }, caseInsurance, jsonData): void {
    if (!caseInsurance && !jsonData) return;

    const emp = new Emptiness();

    const jsonInsurance = jsonData?.insurance?.insurancePolicies?.[0] || { plan: {}, payer: {} };
    const patientInsurance = caseInsurance?.patientInsurance || {};

    const planType = enumResolver(emp.getValueOrDefault(patientInsurance.planType, jsonInsurance.plan.planType), InsurancePlanType);
    const order = enumResolver(
      emp.getValueOrDefault(patientInsurance.insurancePriorityId, jsonInsurance.insurancePriority),
      PlanOrder,
      EnumRule.firstLetterLow,
    );

    controls.caseInsuranceId.patchValue(caseInsurance.id || 0);
    controls.patientInsuranceId.patchValue(patientInsurance.id || 0);
    controls.externalSourceId.patchValue(jsonInsurance.externalSourcePolicyId);

    const policyHolderDOB = patientInsurance.policyHolderDOB || jsonInsurance.policyHolderDateOfBirth;

    controls.group.patchValue(patientInsurance.groupNumber || jsonInsurance.groupNumber);

    controls.policyHolderName.patchValue(patientInsurance.policyHolderName || jsonInsurance.policyHolderName);
    controls.policyHolderDOB.patchValue(policyHolderDOB ? new Date(policyHolderDOB) : undefined);
    controls.policyHolderRelationship.patchValue(patientInsurance.policyHolderRelationshipId);

    controls.planId.patchValue(patientInsurance.planId || 0);
    controls.planName.patchValue(patientInsurance.planName || jsonInsurance.plan.name);
    controls.planType.patchValue(planType);
    controls.planOrder.patchValue(order);
    controls.planPhone.patchValue(patientInsurance.planPhone || jsonInsurance.plan.phone);

    controls.payerId.patchValue(patientInsurance.payerId || 0);
    controls.payerName.patchValue(patientInsurance.payerName || jsonInsurance.payer.name);
    controls.payerPhone.patchValue(patientInsurance.payerPhone || jsonInsurance.payer.phone);
    
    controls.memberId.patchValue(patientInsurance.memberId);

    MedicalInsuranceComponent.updateValidationRules(controls)
  }

  public static getInsuranceObject(form: any, { caseId, patientId }, isNew = false) {
    const formData = form instanceof FormGroup
      ? form.getRawValue()
      : (form || {});

    if (!isNew && (formData.isInsuranceRemoved || (!formData.planName && !formData.planId))) return undefined;
    
    return {
      id: isNew ? 0 : formData.caseInsuranceId,
      caseId: caseId,
      insurancePriorityId: formData.planOrder,
      patientInsurance: {
        id: isNew ? 0 : formData.patientInsuranceId,
        planName: formData.planName,
        groupNumber: formData.group,
        policyHolderName: formData.policyHolderName,
        policyHolderDOB: formData.policyHolderDOB ? moment(formData.policyHolderDOB).format() : null,
        policyHolderRelationshipId: formData.policyHolderRelationship,
        memberId: formData.memberId,
        planType: formData.planType,
        insurancePriorityId: formData.planOrder,
        coverageTypeId: CoverageType.medical,
        payerId: formData.payerId,
        planId: formData.planId,
        payerName: formData.payerName,
        payerPhone: formData.payerPhone,
        planPhone: formData.planPhone,
        patientId: patientId
      }
    };
  }

  private static updateValidationRules(controls: any) { 
    const insuranceIsNotSet = !(controls.planId.value ||
      controls.planName.value ||
      controls.payerName.value ||
      controls.payerId.value);

    controls.planId.SkipValidation = insuranceIsNotSet;
    controls.planName.SkipValidation = insuranceIsNotSet;
    controls.planType.SkipValidation = insuranceIsNotSet;
    controls.planOrder.SkipValidation = insuranceIsNotSet;
    controls.planPhone.SkipValidation = insuranceIsNotSet;
    controls.payerId.SkipValidation = insuranceIsNotSet;
    controls.payerName.SkipValidation = insuranceIsNotSet;
    controls.payerPhone.SkipValidation = insuranceIsNotSet;
    controls.group.SkipValidation = insuranceIsNotSet;
    controls.policyHolderName.SkipValidation = insuranceIsNotSet;
    controls.policyHolderDOB.SkipValidation = insuranceIsNotSet;
    controls.policyHolderRelationship.SkipValidation = insuranceIsNotSet;
    controls.memberId.SkipValidation = insuranceIsNotSet;
  }

  selectPlan(plan): void {
    this.showPlan = true;

    MedicalInsuranceComponent.populateForm(this.insuranceForm, { patientInsurance: plan }, undefined);

    this.insuranceForm.controls.planOrder.patchValue(this.selectedPriority);
    this.insuranceForm.controls.isInsuranceRemoved.patchValue(false);

    MedicalInsuranceComponent.updateValidationRules(this.insuranceForm.controls);

    this.clearSearch();

    BaseFormComponent.validateForms(this.state);
  }

  removePlan(): void {
    this.showPlan = false;

    if (this.state.autofillData?.insurance?.insurancePolicies) { 
      this.state.autofillData.insurance.insurancePolicies = undefined;
    }

    this.onInsuranceRemoved.emit(this.insuranceForm.value.caseInsuranceId);
    
    this.insuranceForm.patchValue(this.getEmptyInsuranceObject());
    
    this.insuranceForm.controls.isInsuranceRemoved.patchValue(true);

    this.insuranceForm.controls.planOrder.patchValue(this.selectedPriority);

    MedicalInsuranceComponent.updateValidationRules(this.insuranceForm.controls);

    BaseFormComponent.validateForms(this.state);
  }

  private getEmptyInsuranceObject(): any {
    return {
      caseInsuranceId: 0,
      patientInsuranceId: 0,
      planId: undefined,
      planName: undefined,
      payerId: undefined,
      payerName: undefined,
      group: undefined,
      policyHolderName: undefined,
      policyHolderDOB: undefined,
      planType: undefined,
      planPhone: undefined,
    };
  }

  searchPlans(): void {
    if (+this.searchControl.value?.length < 3) return;

    this.isPlansLoading = true;
    this.dataEntryService
      .getInsurancePlans({ search: this.insuranceForm.value.searchMedicalInsurancePlan })
      .subscribe(
        (response) => {
          this.medicalPlans = this.state.config.fieldsConfig.canAddNewInsurancePlans
            ? response
            : (response ?? []).filter(e => e.planId);
        },
        (error) => {
          throwError(error);
        },
        () => {
          this.isPlansLoading = false;
        }
      );
  }

  clearSearch(): void {
    this.searchControl.patchValue(undefined);
  }

  searchNavigate(event): void {
    if (!this.medicalPlans.length) return;

    if (event.key === 'Tab' || event.key === 'ArrowDown') {
      event.preventDefault();
      this.focusedOnSearch === this.searchList.nativeElement.children.length - 1
        ? (this.focusedOnSearch = 0)
        : (this.focusedOnSearch = this.focusedOnSearch + 1);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.focusedOnSearch === 0
        ? (this.focusedOnSearch = this.searchList.nativeElement.children.length - 1)
        : (this.focusedOnSearch = this.focusedOnSearch - 1);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      this.selectPlan(this.medicalPlans[this.focusedOnSearch]);
    }
  }
}
