import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { addWorkDays } from '@shared/utilities/date-time.utils';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { ContactMethodCaptions } from '@shared/utilities/contact-method-captions';
import { WorkflowService } from '../../../../services/workflow.service';
import { FollowUpType } from '../../enums/follow-up-type.enum';
import { CaseService } from '../../../../services/case.service';
import { ConsentTypes } from '@shared/enums/consent-types.enum';

@Component({
    selector: 'app-reschedule-task',
    templateUrl: './reschedule.component.html',
    styleUrls: ['./reschedule.component.scss'],
})
export class RescheduleTaskComponent {
    readonly FollowUpType = FollowUpType;
    readonly ContactMethod = ContactMethod;
    readonly contactIcon = ContactMethodCaptions.Icon;
    readonly contactName = ContactMethodCaptions.ForContactCard;

    ConsentTypes = ConsentTypes;

    rescheduleForm: FormGroup;
    currentDate = new Date();
    contactMethods: ContactMethod[];

    get followUpType() { return this.rescheduleForm?.value.creation; }

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data,
        private readonly workflowService: WorkflowService,
        private dialogRef: MatDialogRef<RescheduleTaskComponent>,
        private caseService: CaseService,
    ) {
        this.initForm(data);
        this.initContactMethods(this.ConsentTypes.texting);
    }

    private initContactMethods(consentTypeId: ConsentTypes) {
        this.workflowService.getAllowedContactMethods(this.data.caseId, this.data.taskId)
            .subscribe(
                res => {
                    if (!res?.success) return;

                    if (res.value.indexOf(ContactMethod.text) === -1) {
                        this.contactMethods = res.value;
                        return
                    }

                    const request = { take: -1, patientId: this.data.patientId, consentTypeId };

                    this.caseService.getEnrollmentConsents(request)
                        .subscribe((consents) => {
                            this.contactMethods = res.value;

                            if (consents && consents.length == 0) {
                                this.contactMethods = this.contactMethods.filter(e => e !== ContactMethod.text);
                            }

                            if (this.contactMethods.length == 0) {
                                this.rescheduleForm.controls.contactMethod.clearValidators();
                                this.rescheduleForm.controls.contactMethod.updateValueAndValidity();
                            }
                        });
                },
                error => {
                    console.error(error);
                }
            )
    }

    private initForm(data) {
        data.rescheduleTiming ??= 0;

        const rescheduleTiming = addWorkDays(new Date(), data.rescheduleTiming);

        this.rescheduleForm = new FormGroup({
            creation: new FormControl(FollowUpType.auto, [Validators.required]),
            attemptDate: new FormControl(rescheduleTiming, [Validators.required]),
            attemptTime: new FormControl('1:00 PM', [Validators.required]),
            contactMethod: new FormControl(data.contactMethod || undefined, [Validators.required]),
        });
    }

    rescheduleTask(): void {
        if (this.rescheduleForm.invalid) return;

        const formData = this.rescheduleForm.getRawValue();

        const date = moment(formData.attemptDate);
        const time = moment(formData.attemptTime, 'h:mm A');

        date.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: 0
        });

        this.dialogRef.close({
            rescheduleDate: date.format(),
            contactMethod: this.rescheduleForm.value.contactMethod
        });
    }
}
