import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ITaskField } from 'src/app/features/manager/components/case/interfaces/task-field.interface';
import { OptionType } from '../enum/option.type';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';

@Component({
  selector: 'app-check-box-list',
  templateUrl: './check-box-list.component.html',
  styleUrls: ['./check-box-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxListComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckBoxListComponent extends InputControlAccessorDirective implements OnInit {
  OptionType = OptionType;

  classes: string;

  @Input() depth = 0;
  @Input() options: any;
  @Input() field: ITaskField;
  @Input() isHistory: boolean;

  @Input() optionsModel: any;
  @Output() changed = new EventEmitter<any>();

  public anyChecked: boolean;

  ngOnInit(): void {
    this.classes = this.field.additionalStyles
      ? 'options-wrapper' + this.field.additionalStyles[this.depth]
      : 'options-wrapper';

    if (this.field.optionTypes[this.depth] === OptionType.Radio) {
      this.optionsModel = this.options.find(el => el.isSelected);
    }

    this.anyChecked = this.options.some(el => el.isSelected);
  }

  showNoteFor(option, condition): boolean {
    return condition && this.field.freeTextAfter?.includes(option.id);
  }

  setValue(checkbox, nestedOptions): void {
    if (!this.optionsModel) {
      this.optionsModel = 0;
    }

    this.optionsModel += checkbox.checked ? 1 : -1;

    if (!this.optionsModel) {
      this.optionsModel = undefined;
    }

    if(!checkbox.checked && nestedOptions?.length) {
      nestedOptions.forEach(e => { e.isSelected = false; });
    }

    this.changed.emit(this.optionsModel);
  }

  radioChange(option): void {
    const optionToSelect = this.options.find(e => e.id === option.id);
    const optionsToDeselect = this.options.filter(e => e.isSelected);

    if(optionToSelect) optionToSelect.isSelected = true;

    optionsToDeselect.forEach(opt => {
      if(opt.options?.length) opt.options.forEach(e => { e.isSelected = false; });
    });

    this.changed.emit(option);
  }
}
