import { IDocumentOptions } from '../interfaces/document-options.interface';
import { IDocument } from '../interfaces/document.interface';
import { Document } from './document.model';

export class DocumentOptions implements IDocumentOptions {
  documentList: IDocument[] = [new Document()];
  selectedDocument: number;
  currentDocumentOrder = 1;
  zoomPercent = 1;
  rotate = 0;
  grid = false;
}
