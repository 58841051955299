import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptorModule } from './core/interceptors/auth.interceptor.module';
import { HomeModule } from './features/home/home.module';
import { IntakeModule } from './features/intake/intake.module';
import { LoginModule } from './features/login/login.module';
import { ManagerModule } from './features/manager/manager.module';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ChartsModule } from 'ng2-charts';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClickOutsideModule } from 'ng-click-outside';
import { NouisliderModule } from 'ng2-nouislider';
import { ProfileModule } from './features/profile/profile.module';
import { SharedModule } from './shared/shared.module';
import { reducers } from './store';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { UncertainDataEntryModule } from './features/intake/modals/uncertain-data-entry/uncertain-data-entry.module';

const oktaConfig = Object.assign(
  {
    onAuthRequired: ({oktaAuth, injector}) => {
      const router = injector.get(Router);
      router.navigate(['/login']);
    },
  },
  environment.oidc,
);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    OktaAuthModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    ChartsModule,
    ClickOutsideModule,
    NouisliderModule,
    AuthInterceptorModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googlePlaceAPIKey,
      libraries: ['places'],
    }),
    AppRoutingModule,
    LoginModule,
    ProfileModule,
    HomeModule,
    IntakeModule,
    ManagerModule,
    SharedModule,
    UncertainDataEntryModule,
  ],
  providers: [
    {provide: OKTA_CONFIG, useValue: oktaConfig},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
