import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactMethodName } from '@shared/enums/contact-method.enum';
import { DocumentTypeName } from '@shared/enums/document-types.enum';
import { ITaskField } from 'src/app/features/manager/components/case/interfaces/task-field.interface';

@Component({
  selector: 'app-task-preview-document',
  templateUrl: './task-preview-document.component.html',
  styleUrls: ['./task-preview-document.component.scss'],
})
export class TaskPreviewDocumentComponent {
  private _field: ITaskField;
  private _open: (field: ITaskField) => void;

  @Output() previewDocument = new EventEmitter<ITaskField>();
  @Output() downloadDocument = new EventEmitter<ITaskField>();

  @Input() set field(value: ITaskField) {
    this._field = value;

    this._field.name ??= 'Review Document';
    this._field.label ??= 'Preview';

    this._open = this._field.isPreview ? (e) => this.previewDocument.emit(e) : (e) => this.downloadDocument.emit(e);
  }

  get field(){ return this._field; };

  openDocument(): void {
    if(this._field.url){
      window.open(this._field.url, '_blank');
    }
    else {
      this._open(this.field);
    }
  }

  getDocumentType(type) { return DocumentTypeName[type]; }
  getMethod(method) { return ContactMethodName[method]; }
}
