export enum ConsentTypes {
  hipaa = 1,
  program = 2,
  marketing = 3,
  texting = 4,
  device = 5,
}

export class ConsentTypeNames {
  static readonly [ConsentTypes.hipaa] = "HIPAA";
  static readonly [ConsentTypes.program] = "Program";
  static readonly [ConsentTypes.marketing] = "Marketing";
  static readonly [ConsentTypes.texting] = "Texting";
  static readonly [ConsentTypes.device] = "Device";
}