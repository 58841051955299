<div class="collapse-section">
    <div class="collapse-item">
      <div class="section-header">
        <h3>
          {{ ConsentTypeNames[consent.type] + " " + (consent.type == ConsentTypes.hipaa ? "Consent" : "Opt-In") }}
        </h3>
        <div class="status-label" [class.ready]="consent.dataSource?.data[0]?.consentIsOnFile">
          {{ consent.dataSource?.data[0]?.consentIsOnFile
            ? (consent.type == ConsentTypes.hipaa ? "Consent" : "Opted In") 
            : (consent.type == ConsentTypes.hipaa ? "No Consent" : "Opted Out") }}
        </div>
      </div>
  
      <div class="empty-state" *ngIf="!consent.dataSource?.data.length">No {{ (consent.type == ConsentTypes.hipaa ? "consent" : "opt-in status") }} yet.</div>
  
      <table *ngIf="consent.dataSource?.data.length" mat-table [dataSource]="consent.dataSource">
        <ng-container matColumnDef="isOptedIn">
          <th mat-header-cell *matHeaderCellDef>Consent on file?</th>
          <td mat-cell *matCellDef="let item; let i = index">
            {{ expiredFile(item.effectiveEndDate) ? "Expired" : item.consentIsOnFile ? "Yes" : "No" }}
            <span>(Current)</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="method">
          <th mat-header-cell *matHeaderCellDef>Method Received</th>
          <td mat-cell *matCellDef="let item; let i = index">
            {{ ConsentMethodNames[item.consentMethod] | empty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="effectiveFrom">
          <th mat-header-cell *matHeaderCellDef>Effective From</th>
          <td mat-cell *matCellDef="let item; let i = index">
            {{
              item.dateReceived
                ? expiredFile(item.effectiveEndDate)
                  ? (getDate(item.effectiveEndDate) | date: "M/d/yyyy")
                  : (getDate(item.dateReceived) | date: "M/d/yyyy")
                : "N/A"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="effectiveTo">
          <th mat-header-cell *matHeaderCellDef>Effective To</th>
          <td mat-cell *matCellDef="let item; let i = index">
            {{
              item.effectiveEndDate
                ? expiredFile(item.effectiveEndDate)
                  ? "N/A"
                  : (getDate(item.effectiveEndDate) | date: "M/d/yyyy")
                : "N/A"
            }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr [class.current]="i === 0" mat-row *matRowDef="let item; let i = index; columns: columnsToDisplay"></tr>
      </table>
  
      <div class="controls" *ngIf="!(this.isCreate || this.isOptOut)">
        <button
          (click)="toggleConsentForm(true, true)"
          mat-button
          class="primary-btn add-btn"
          type="button"
        >
          <div class="add-btn-wrapper">
            <mat-icon>add</mat-icon>
            {{
                consent.dataSource?.data[0] &&
                consent.dataSource?.data[0]?.consentIsOnFile &&
              expiredFile(consent.dataSource?.data[0].effectiveEndDate)
                ? "Renew"
                : "Add"
            }}
            Consent
          </div>
        </button>
        <button
          [disabled]="
            !consent.dataSource?.data[0]?.consentIsOnFile ||
            !isConsentAvailable(consent.dataSource?.data[0])
          "
          (click)="toggleConsentForm(false, true)"
          class="opt-out-btn"
          mat-button
        >
          Opt Out
        </button>
      </div>
  
      <app-new-opt-in-form
        *ngIf="this.isCreate"
        [type]="consent.type"
        [isRenew]="consent.dataSource?.data[0]?.consentIsOnFile && expiredFile(consent.dataSource?.data[0].effectiveEndDate)"
        [currentConsent]="consent.dataSource?.data[0]"
        [consentMethods]="consentMethods"
        (cancelled)="toggleConsentForm(true, false)"
        (submitted)="createConsent($event, consent.type)"
      ></app-new-opt-in-form>
      <app-opt-out-form
        *ngIf="this.isOptOut"
        [type]="consent.type"
        [consentMethods]="consentMethods"
        (cancelled)="toggleConsentForm(false, false)"
        (submitted)="createConsent($event, consent.type, true)"
      ></app-opt-out-form>
    </div>
  </div>  