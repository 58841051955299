<div class="buttons-wrapper" [ngClass]="{'history': isHistory, 'empty': value === null}">
  <div [class]="styleClass">
    <mat-button-toggle-group (valueChange)="valueChanged.emit($event)"
                             [class]="additionalStyles"
                             [id]="id"
                             [(ngModel)]="value"
                             [disabled]="disabled"
                             [ngClass]="{disabled: disabled}"
    >
      <mat-button-toggle *ngFor="let option of options" [value]="option.id">
        {{option.name}}
        <span *ngIf="option.note"
              class="note">{{option.note}}</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <span *ngIf="field.name">
    <a *ngIf="field.reference" class="ref" [href]="field.reference.url">{{field.reference.text}}</a> {{field.name}}
  </span>
</div>
