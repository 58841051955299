import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { setIndicatorColor } from '@shared/helpers/utils';

@Component({
  selector: 'app-benefit-medical-aob',
  templateUrl: './medical-aob.component.html',
  styleUrls: ['../../../../task.component.scss', '../../benefit.component.scss', './medical-aob.component.scss'],
})
export class BenefitMedicalAOBComponent implements OnInit, OnChanges {
  @Input() settings;
  @Input() selectedTask;
  @Input() isDisabled;
  @Input() expanded;

  @Output() validateTaskHandler = new EventEmitter();
  @Output() openPanelHandler = new EventEmitter();
  @Output() closePanelHandler = new EventEmitter();
  @Output() currentSectionHandler = new EventEmitter();

  medicalAOBForm: FormGroup;
  setIndicatorColor = setIndicatorColor;

  constructor() {
    this.medicalAOBForm = new FormGroup({
      coverage: new FormGroup({
        isCoverage: new FormControl(null, [Validators.required]),
        isSameAsBB: new FormControl(null, [Validators.required]),
      }),
    });
  }

  ngOnInit(): void {
    this.medicalAOBForm.valueChanges.subscribe(() => {
      this.validateTask(this.medicalAOBForm);
    });
  }

  ngOnChanges(changes): void {
    if (changes.isDisabled && changes.isDisabled.currentValue) {
      this.medicalAOBForm.disable();
    } else if (changes.isDisabled && !changes.isDisabled.currentValue) {
      this.medicalAOBForm.enable();
    }
  }

  openPanel(index): void {
    if (!this.expanded) {
      this.openPanelHandler.emit({index, elementId: 'MedicalAOBPanel', sectionIndex: 2});
    }
  }

  closePanel(index): void {
    this.closePanelHandler.emit(index);
  }

  currentSection(name: string): void {
    this.currentSectionHandler.emit(name);
  }

  validateTask(form): void {
    const data = {
      form,
      formName: 'medicalAOB',
    };
    this.validateTaskHandler.emit(data);
  }
}
