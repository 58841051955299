import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EncapsulatedRestService } from '@core/services/encapsulated-rest.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends EncapsulatedRestService {
  constructor(protected http: HttpClient) { super(http); }

  public getCases(data: any): Observable<any> {
    return this.get('CaseSearch/find-cases-by-query', data);
  }
}
