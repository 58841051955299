import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchModalColumns } from '../../../../enums/search-modal-columns.enum';
import { SearchService } from '../../../../services/search.service';
import { throwError } from 'rxjs';
import { enumToArray } from 'src/app/shared/helpers/utils';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-search-field-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class SearchModalComponent implements OnInit {
  columnsToDisplay = enumToArray(SearchModalColumns);
  showItems = 5;
  cases = [];
  dataSource;
  searchValue;
  searchType = 0;
  caseCount = 0;
  isLoaded = undefined;

  onSearchCases = new EventEmitter();

  constructor(
    public searchService: SearchService,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.searchValue = this.data.searchValue;

    this.onSearchCases
      .pipe(filter(() => this.searchValue?.length >= 3))
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.searchCases();
      });
  }

  searchCases(): void {
    const data = {
      take: this.showItems,
      search: this.searchValue
    };
    
    this.isLoaded = undefined;

    setTimeout(() => this.isLoaded ??= false, 300);

    this.searchService.getCases(data).subscribe(
      (response) => {
        this.cases = response.foundCases;
        this.caseCount = response.caseCount;

        this.dataSource = new MatTableDataSource(this.cases);

        this.isLoaded = true;
      },
      (error) => {
        throwError(error);
      }
    );
  }

  goToSearchPage(): void {
    this.router.navigate(['search'], {state: {searchValue: this.searchValue, cases: this.cases}});
  }

  openCase(caseId): void {
    this.router.navigate(['/case'], {queryParams: {id: caseId}});
  }
}
