<button class="submit-button-green"
        (click)="btnClick.emit()"
        [disabled]="disabled || isLoading"
        [ngClass]="{loading: isLoading}"
        [style.min-width.px]="minWidth"
        #submitButton
>
  <span class="loading-block">
      <img class="loading-icon" src="assets/icons/icon-loading.svg" alt="loading" />
  </span>
  <span class="button-name">{{buttonName}}</span>
</button>
