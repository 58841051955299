import { Injectable } from '@angular/core';
import { AbstractRestService } from '@core/services/abstract-rest.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IResponse } from '@shared/interfaces/response.interface';
import { payersListDataMock } from '../../../../../../testing/mocks/payersListDataMock';
import { ITableQueryParams } from '@shared/interfaces/table-query-params.interface';
import { IPayer } from '@shared/interfaces/payer.interface';
import { IPBM } from '@shared/interfaces/pbm.interface';
import { pbmListDataMock } from '../../../../../../testing/mocks/pbmListDataMock';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { payerPlansDataMock } from '../../../../../../testing/mocks/payerPlansDataMock';
import { IPayerPlan } from '@shared/interfaces/payer-plan.interface';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PayerManagementService extends AbstractRestService {
  currentPayer$: BehaviorSubject<IPayer> = new BehaviorSubject<IPayer>({} as IPayer);
  currentPayerPlan$: BehaviorSubject<IPayerPlan> = new BehaviorSubject<IPayerPlan>({} as IPayerPlan);

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  getPayersList(query: ITableQueryParams): Observable<any> {
    this.url = this.apiUrl + `/Payers/Search`;
    query.take = query.take || 100000;

    return this.postItem<any, IResponse>(query).pipe(
      map((response: IResponse) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  getPayerPlansList(query: any): Observable<any> {
    this.url = this.apiUrl + `/Plans/Search`;
    query.take = query.take || 100000;

    return this.postItem<any, IResponse>(query).pipe(
      map((response: IResponse) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getPayerProfile(id: number): Observable<IPayer> {
    this.url = this.apiUrl + `/Payers?id=${id}`;

    return this.get<IResponse>().pipe(
      map((response: IResponse) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getPayerPlan(id: number): Observable<IPayerPlan> {
    this.url = this.apiUrl + `/Plans?id=${id}`;

    return this.get<IResponse>().pipe(
      map((response: IResponse) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  createPayerProfile(payer: IPayer): Observable<any> {
    this.url = this.apiUrl + `/Payers`;

    return this.postItem<IPayer, IResponse>(payer);
  }

  editPayerProfile(payer: IPayer): Observable<any> {
    this.url = this.apiUrl + `/Payers`;

    return this.putItem<IPayer, IResponse>(payer);
  }

  deletePayerProfile(id: number): Observable<any> {
    this.url = this.apiUrl + `/Payers?id=${id}`;

    return this.delete<boolean>();
  }

  createPayerPlan(plan: IPayerPlan): Observable<any> {
    this.url = this.apiUrl + `/Plans`;

    return this.postItem<IPayerPlan, IResponse>(plan);
  }

  editPayerPlan(plan: IPayerPlan): Observable<any> {
    this.url = this.apiUrl + `/Plans`;

    return this.putItem<IPayerPlan, IResponse>(plan);
  }

  activatePayerPlan(id: number): Observable<any> {
    this.url = this.apiUrl + `/Plans/activate?id=${id}`;

    return this.put<boolean>();
  }

  deactivatePayerPlan(id: number): Observable<any> {
    this.url = this.apiUrl + `/Plans/deactivate?id=${id}`;

    return this.put<boolean>();
  }

  getPlanTypes(): Observable<any> {
    this.url = this.apiUrl + `/PlanTypes`;
    return this.get<IResponse>().pipe(
      map((response: IResponse) => {
        return response;
      }),
      catchError((error) => throwError(error)),
    );
  }

  getPlanSubTypes(): Observable<any> {
    this.url = this.apiUrl + `/PlanSubTypes`;

    return this.get<IResponse>().pipe(
      map((response: IResponse) => {
        return response;
      }),
      catchError((error) => throwError(error)),
    );
  }
}
