import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { queueDataMock } from '../../../../../../../testing/mocks/queueDataMock';
import { AbstractRestService } from '../../../../../core/services/abstract-rest.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { IUserQueue } from '../interfaces/dashboard.interface';

@Injectable({
  providedIn: 'root',
})
export class QueueService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  getSupervisorQueues(programId: number = 0): Observable<IUserQueue[]> {
    this.url = this.apiUrl + '/SupervisorQueues/' + programId;
    return this.getItems<IResponse>('').pipe(
      map((response) => {
        response.value.map((queue) => {
          return (queue.queueConfiguration = {
            ...queue.queueConfiguration,
            chartData: [{data: queueDataMock.new.data, label: 'Supervisor'}],
            type: 'inc',
            documentType: 'supervisor',
          });
        });
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
