import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';
import { CaseService } from '../../../../services/case.service';
import { IResponse } from '@shared/interfaces/response.interface';
import { DocumentTypes } from '@shared/enums/document-types.enum';
import { DocumentTypeNames } from '@shared/enums/document-type-names.enum';
import { attachmentName } from '@shared/utilities/attachments.utils';
import { except, intersect } from '@shared/utilities/arrays.utils';
import { ImageService } from '@shared/services/image.service';
import { getDateString } from '@shared/helpers/utils';

@Component({
  selector: 'app-attachments-list-dialog',
  templateUrl: './attachments-list-dialog.component.html',
  styleUrls: ['./attachments-list-dialog.component.scss'],
})
export class AttachmentsListDialogComponent implements OnInit {
  private documentTypes = DocumentTypes;
  private documentTypeNames = DocumentTypeNames;
  
  columnsToDisplay = [
    'dragIcon',
    'documentType',
    'receiveDate',
    'documentRef',
    'controls',
  ];

  draggableAttachments = [];
  unselectedAttachments = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    public dialog: MatDialogRef<AttachmentsListDialogComponent>,
    private caseService: CaseService,
    private imageService: ImageService,
  ) {
  }

  ngOnInit(): void {
    this.loadAttachments();
  }

  private loadAttachments() {
    const request = { caseId: this.data.caseId, take: -1 };

    this.caseService.getCaseAttachments(request)
    .subscribe((response: IResponse) => {
      this.setDocuments(response.value);
    });
  }

  private setDocuments(attachments) {
    const documents = attachments.map(attachment => {
      const doc = this.mapAttachment(attachment);

      if(doc.imageKey && !doc.image) this.getThumbnail(doc);

      return doc;
    });

    if (!this.data.selectedDocuments?.length) {
      this.unselectedAttachments = documents;
      this.draggableAttachments = [];
    }
    else {
      this.draggableAttachments = intersect(documents, this.data.selectedDocuments, e => e.id);
      this.unselectedAttachments = except(documents, this.data.selectedDocuments, e => e.id);
    }
  }

  private mapAttachment(attachment): any {
    return {
      id: attachment.id,
      name: attachmentName(attachment),
      imageKey: attachment.s3ThumbnailKey,
      receiveDate: new Date(attachment.createDate),
      documentType: this.documentTypeNames[this.documentTypes[attachment.documentType]],
    };
  }

  private getThumbnail(document): void {
    this.imageService.getImageByS3Key(document.imageKey).subscribe(
      (thumb) => {
        this.imageService.createImageFromBlob(thumb, ev => {
          document.image = ev.target.result;
        });
      },
      (error) => console.error(error)
    );
  }

  close(): void {
    this.dialog.close(this.draggableAttachments);
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.draggableAttachments, event.previousIndex, event.currentIndex);
  }

  viewFile(document): void {
    this.caseService.downloadAttachment(document.id).subscribe(res => {
      const name = document.documentType + '_' + getDateString(document.receiveDate, 'MM-DD-YYYY');

      const newWindow = window.open(window.URL.createObjectURL(res), '_blank');

      newWindow.addEventListener("load", function() {
        setTimeout(() => { newWindow.document.title = name; }, 50);
      });
    }, 
    (error) => console.error(error));
  }

  removeFromDraggable(i: number): void {
    this.unselectedAttachments.unshift(this.draggableAttachments[i]);
    this.draggableAttachments.splice(i, 1);
  }

  addToDraggable(i: number): void {
    this.draggableAttachments.push(this.unselectedAttachments[i]);
    this.unselectedAttachments.splice(i, 1);
  }
}
