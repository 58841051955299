<table class="insurances-table"
       [class.medical]="isMedical"
       [@.disabled]="true" mat-table
       [dataSource]="dataSource" matSort
       matSortActive="completed"
       matSortDirection="desc"
>
  <ng-container matColumnDef="paddingLeft">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>

  <ng-container *ngIf="isMedical" matColumnDef="payerName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Payer</th>
    <td mat-cell *matCellDef="let element">
      {{ element.payerName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="planName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
    <td mat-cell *matCellDef="let element">
      {{ element.planName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="priorityId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Priority</th>
    <td mat-cell *matCellDef="let element">
      {{ element.priorityId }}
    </td>
  </ng-container>
  <ng-container matColumnDef="outcome">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Outcome</th>
    <td mat-cell *matCellDef="let element">
      <div class="outcome-block" [ngClass]="{'covered': element.outcome === InsuranceOutcomeTypesEnum.Covered,
      'not-covered': element.outcome === InsuranceOutcomeTypesEnum.NotCovered,
      'no-coverage': element.outcome === InsuranceOutcomeTypesEnum.NoCoverage
      }">
        <span>{{ element.outcome }}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="completed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
    <td mat-cell *matCellDef="let element">
      {{ element.completed | date: "MM/dd/yyyy" }} at <span>{{ element.completed | date: "h:mma" }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="copay">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Copay</th>
    <td mat-cell *matCellDef="let element">
      {{ element.copay | currency: 'USD' : 'symbol' : '.0-2' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="coinsurance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Coinsurance</th>
    <td mat-cell *matCellDef="let element">
      {{ element.coinsurance }}%
    </td>
  </ng-container>
  <ng-container matColumnDef="isRequired">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>PA Req'd?</th>
    <td mat-cell *matCellDef="let element">
      {{ element.isRequired ? 'Yes' : 'No' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div (click)="openDetailsDialog(element)" class="details-ref">
        Details <img class="arrow-icon" src="assets/icons/icon-arrow-long-right-blue.svg" alt="arrow-right" />
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="paddingRight">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
</table>
