<div class="container">
  <app-search-field></app-search-field>
</div>

<hr/>

<div class="container">
  <app-intake-dashboard-queues
    [queueList]="queueList"
    [intakeQueueList]="intakeQueueList"
  >
  </app-intake-dashboard-queues>
</div>
