import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConsentMethodNames } from '@shared/enums/consent-method.enum';
import { ConsentTypeNames, ConsentTypes } from '@shared/enums/consent-types.enum';
import { EnrollmentColumns } from '@shared/enums/enrollment-columns.enum';
import { getDateObject, expiredFile, getISOString, enumToArray } from '@shared/helpers/utils';
import { IConsent } from '../../../../../interfaces/consent.interface';
import { CaseService } from '../../../../../services/case.service';

@Component({
  selector: 'app-consent-item',
  templateUrl: './consent-item.component.html',
  styleUrls: ['./consent-item.component.scss']
})
export class ConsentItemComponent implements OnInit {
  @Input() consent;
  @Input() patientId;
  @Input() consentMethods: any[];
  
  @Output() updateCase: EventEmitter<void> = new EventEmitter<void>();
  
  columnsToDisplay = enumToArray(EnrollmentColumns);
  ConsentTypes = ConsentTypes;
  ConsentTypeNames = ConsentTypeNames;
  ConsentMethodNames = ConsentMethodNames;

  isCreate: boolean;
  isOptOut: boolean;

  today: Date = new Date();
  getDate: (ISO: string) => Date = getDateObject;
  expiredFile: (utc: string) =>  boolean = expiredFile;

  constructor(
    private caseService: CaseService
  ) {
  }

  ngOnInit(): void {
  }

  toggleConsentForm(create: boolean, show: boolean): void {
    create ? this.isCreate = show : this.isOptOut = show;
  }

  createConsent(data, consentTypeId: ConsentTypes, optOut = false): void {
    const consent = {
      id: 0,
      consentTypeId,
      consentIsOnFile: !optOut,
      consentMethod: +data.consentMethod,
      dateReceived: getISOString(data.effectiveFrom),
      effectiveEndDate: getISOString(data.effectiveTo),
      patientId: this.patientId,
    };

    this.caseService.createEnrollmentConsent({consent})
      .subscribe((res) => {
        this.caseService.getEnrollmentConsents({take: -1, patientId: this.patientId, consentTypeId})
          .subscribe((consents) => {
            this.consent.dataSource = new MatTableDataSource(consents);
          });
        this.toggleConsentForm(!optOut, false);

        this.updateCase.emit();
      });
  }

  isConsentAvailable(consent: IConsent): boolean {
    const todayTime = this.today.getTime();
    const dateReceived = getDateObject(consent.dateReceived).getTime();
    const effectiveEndDate = getDateObject(consent.effectiveEndDate).getTime();
    const result = consent ? (!consent.dateReceived || dateReceived <= todayTime) && (!consent.effectiveEndDate || effectiveEndDate >= todayTime) : false;
    return result;
  }
}
