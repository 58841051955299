<mat-drawer-container class="drawer-container" [autosize]="true">
  <mat-drawer #drawer class="drawer-sidenav"
              [class]="(caseService.details$ | async) && (caseService.details$ | async).id"
              mode="side"
              opened="true"
  >
    <div [ngSwitch]="(caseService.details$ | async)?.id" *ngIf="(caseService.details$ | async)">

      <!--Section KEY DETAILS-->
      <ng-container *ngSwitchCase="'keyDetails'">
        <h2>{{ (caseService.details$ | async).title }}</h2>
        <div class="details-wrapper">
          <ng-scrollbar class="custom-scrollbar case-scrollbar">
            <div *ngFor="let detail of (caseService.details$ | async).data" class="detail-item">
              <h3>{{ detail.title }}</h3>
              <p>{{ detail.value | empty }}</p>
            </div>
          </ng-scrollbar>
        </div>
      </ng-container>

      <!--Section PATIENT-->
      <app-section-patient *ngSwitchCase="'patient'"
                           (update)="sectionUpdate($event, 'patient')"
                           (openManagement)="openEnrollmentItem($event)"
                           [currentConsents]="currentConsents"
                           [patient]="case?.patient"
                           [diagnosis]="case?.diagnosis">
      </app-section-patient>

      <!--Section PAYER-->
      <ng-container *ngSwitchCase="'payer'">
        <div class="details-wrapper">
          <ng-scrollbar class="custom-scrollbar case-scrollbar">
            <app-section-insurances [case]="case"
                                    [caseInsurances]="case.caseInsurances"
            ></app-section-insurances>
          </ng-scrollbar>
        </div>
      </ng-container>

      <!--Section PHYSICIAN-->
      <app-section-physician *ngSwitchCase="'physician'"
                             (update)="facilityUpdate($event)"
                             (openManagement)="openPhysicianEditDialog($event)"
                             [physician]="case?.physician"
                             [facility]="case?.facility"
      >
      </app-section-physician>

      <!--Section PRESCRIPTION-->
      <ng-container *ngSwitchCase="'prescription'">
        <h2>{{ (caseService.details$ | async).title }}</h2>
        <div class="details-wrapper">
          <ng-scrollbar class="custom-scrollbar case-scrollbar">
            <div *ngFor="let detail of (caseService.details$ | async).data" class="detail-item">
              <h3>{{ detail.title }}</h3>
              <p>{{ detail.value | empty }}</p>
            </div>
          </ng-scrollbar>
        </div>
      </ng-container>

      <!--Section NOTES-->
      <app-section-notes *ngSwitchCase="'notes'"
                         [initialData]="(caseService.details$ | async).data"
      ></app-section-notes>

      <!--Section ATTACHMENTS-->
      <app-section-attachments *ngSwitchCase="'attachments'"
                               [attachments]="attachments"
                               [patient]="case.patient"
                               (thumbnailUpdated)="updateAttachment($event)"
      >
      </app-section-attachments>
    </div>
  </mat-drawer>

  <div class="case-navigation">
    <app-case-navigation *ngIf="case && (!selectedTask || selectedTask.minimized)"
                         [phaseList]="phaseList"
                         [shownPhase]="shownPhase"
                         (showPhase)="showPhaseHandler($event)"
    ></app-case-navigation>
  </div>

  <div [ngSwitch]="shownPhase">
    <app-case-benefit *ngSwitchCase="'benefit'" [case]="case"></app-case-benefit>
    <app-case-enrollment (updateCase)="getCase(case.id)"
                         (expandPanels)="afterExpandPanels($event)"
                         *ngSwitchCase="'enrollment'"
                         [panel]="panel"
                         [case]="case"
    ></app-case-enrollment>
    <app-case-authorization *ngSwitchCase="'lock'" [case]="case"></app-case-authorization>
    <app-case-fulfillment *ngSwitchCase="'fulfillment'" [case]="case"></app-case-fulfillment>
    <div *ngSwitchDefault>
      <ng-container *ngIf="!selectedTask || selectedTask.minimized">
        <div class="current-tasks-container">
          <app-manager-case-timeline [activeTasks]="activeTasks"
                                     (createActivity)="createActivityDialog()"
                                     (setSelectedTaskHandler)="setSelectedTask($event)"
                                     *ngIf="case" [case]="case">
          </app-manager-case-timeline>
        </div>

        <app-case-history [historyTimeline]="historyTimeline"
                          [completedTasks]="completedTasks"
                          [case]="case"
                          *ngIf="case">
        </app-case-history>
      </ng-container>
      <app-dynamic-tasks-renderer #dynamicTaskRenderer *ngIf="selectedTask"
                                  [style.collapsed]="selectedTask.minimized"
                                  [selectedTask]="selectedTask"
                                  [case]="case"
                                  [isHistory]="false"
                                  (minimizeTaskHandler)="minimizeTask($event)"
                                  (setSelectedTaskHandler)="setSelectedTask($event)"
                                  (closeCaseHandler)="closeCase()"
                                  (submitTaskHandler)="submitTask($event)"
                                  (escalateToSupervisorHandler)="escalateToSupervisor($event)"
                                  (rescheduleTaskHandler)="rescheduleTask($event)"
      >
      </app-dynamic-tasks-renderer>
    </div>
  </div>

  <div *ngIf="!case" class="case-loader-container">
    <app-loader></app-loader>
  </div>
</mat-drawer-container>

<div id="collapsedTask" *ngIf="selectedTask && selectedTask.minimized" (click)="minimizeTask(false)">
  <div class="task-name">
    <h5>Task</h5>
    <i>{{ selectedTask.task }}</i>
  </div>
  <mat-icon>open_in_full</mat-icon>
</div>
