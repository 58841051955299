<div mat-dialog-title>
    <h1><img src="assets/icons/icon-warning.svg" alt="warning" />Required Info</h1>
    <button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<div mat-dialog-content>
    <p>The following fields MUST be completed before proceeding:</p>
    <div class="dialog-body">
        <ul>
            <li *ngFor="let field of fields"><mat-icon>error</mat-icon>{{ field.name }}</li>
        </ul>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button class="back" mat-dialog-close>
        <img src="assets/icons/icon-arrow-back.svg" alt="Back" />Go Back
    </button>
    <button mat-button class="skip" mat-dialog-close (click)="close(true)">This info is missing</button>
</div>
