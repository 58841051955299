import { ISearchAdvancedOptions } from '../interfaces/search-advanced-options.interface';
import { ISearchOptions } from '../interfaces/search-options.interface';
import { AdvancedSearchOptions } from './advanced-search-options.model';

export class SearchOptions implements ISearchOptions {
  durationValue = 'today';
  searchValue = '';
  statusValue = 'all';
  isAdvanced = false;
  date: { startDate: string; endDate: string } = {startDate: '', endDate: ''};
  advancedOptions: ISearchAdvancedOptions[] = [new AdvancedSearchOptions()];
}
