import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatPhone, interpolateTemplate } from 'src/app/shared/helpers/utils';
import { TaskOutcome } from '@shared/enums/task-outcome.enum';
import { CaseService } from '../../../../services/case.service';
import { throwError } from 'rxjs';
import * as moment from 'moment';
import { PlanOrder } from 'src/app/shared/enums/plan-order.enum';
import { CoverageType } from 'src/app/shared/enums/insurance/coverage-type.enum';

@Component({
  selector: 'app-task-followup-prior',
  templateUrl: './followup-prior.component.html',
  styleUrls: ['../../task.component.scss', './followup-prior.component.scss'],
})
export class FollowupPriorTaskComponent implements OnInit {
  @Input() selectedTask;
  @Input() case;

  @Output() minimizeTaskHandler = new EventEmitter();
  @Output() setSelectedTaskHandler = new EventEmitter();
  @Output() rescheduleTaskHandler = new EventEmitter();
  @Output() submitTaskHandler = new EventEmitter();

  interpolatedDescription;
  followUpForm: FormGroup;

  constructor(public caseService: CaseService) {
    this.followUpForm = new FormGroup({
      contact: new FormControl(null, [Validators.required]),
      received: new FormControl(null, [Validators.required]),
      paOutcome: new FormControl(null, [Validators.required]),
      paId: new FormControl(null, [Validators.required]),
      paStartDate: new FormControl(null, [Validators.required]),
      paEndDate: new FormControl(null, [Validators.required]),
      note: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    const primaryMedicalInsurance = this.getPrimaryMedicalInsurance();
    this.interpolatedDescription =
      primaryMedicalInsurance
        ? interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject(primaryMedicalInsurance))
        : this.selectedTask.description;
    if (this.selectedTask.formData) {
      this.followUpForm.patchValue(this.selectedTask.formData);
    }
  }

  minimizeTask(val: boolean): void {
    this.selectedTask.formData = this.followUpForm.getRawValue();
    this.minimizeTaskHandler.emit(val);
  }

  setSelectedTask(task): void {
    this.setSelectedTaskHandler.emit(task);
  }

  submitTask(): void {
    const data = {
      queueItemId: this.case.id,
      queueTaskId: this.selectedTask.id,
      taskOutcome: null,
      outcomeNote: this.followUpForm.value.note,
    };
    if (this.followUpForm.value.paOutcome === 1) {
      data.taskOutcome = TaskOutcome.success;
      this.setPriorAuthorizations();
    } else if (this.followUpForm.value.paOutcome === 2) {
      data.taskOutcome = TaskOutcome.failure;
    } else {
      data.taskOutcome = TaskOutcome.repeat;
    }
    this.submitTaskHandler.emit(data);
  }

  rescheduleTask(): void {
    const data = {
      attemptNumber: this.selectedTask.attemptNumber,
      task: this.selectedTask.task,
      outcomeNote: this.followUpForm.value.note,
      case: this.case,
    };
    this.rescheduleTaskHandler.emit(data);
  }

  getDescriptionTemplateObject(primaryMedicalInsurance): any {
    const payerPhone = primaryMedicalInsurance?.payerPhone || '';
    const payerName = primaryMedicalInsurance?.payerName || '';

    return {
      payerPhone: `<a href="#" class="highlighted">${formatPhone(payerPhone)}</a>`,
      payerName: `<span class="highlighted">${payerName}</span>`,
    };
  }

  setPriorAuthorizations(): void {
    const data = {
      priorAuthorization: {
        authorizationNumber: this.followUpForm.value.paId,
        startDate: this.followUpForm.value.paStartDate ? moment(this.followUpForm.value.paStartDate).format() : null,
        endDate: this.followUpForm.value.paEndDate ? moment(this.followUpForm.value.paEndDate).format() : null,
        caseId: this.case.id,
      },
    };
    this.caseService.putPriorAuthorizations(data).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => throwError(error)
    );
  }

  getPrimaryMedicalInsurance(): any {
    return this.case.caseInsurances
      ?.find(x => x.insurancePriorityId === PlanOrder.primary && x.patientInsurance.coverageTypeId === CoverageType.medical)
      ?.patientInsurance;
  }
}
