import { IDocument } from '../interfaces/document.interface';

export class Document implements IDocument {
  id: number;
  documentBatchId: number;
  patient: any;
  patientId: null | number;
  documentType: null | string;
  rawPNGKey: string;
  thumbnailPNGKey: string;
  isDeleted: boolean;
  isUncertain: boolean;
  newPatientName?: string;
  otherText: string;
  source?: string;
  createDate?: string;

  deleteReasons: [];
  uncertainReasons: [];
  otherDeleteReasonName: string;
  otherUncertainReasonName: string;
}
