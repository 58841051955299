import { IDropdownOption } from '../interfaces/dropdown-option.interface';

export const defaultTaskDropdownOptions: IDropdownOption[] = [
  {
    id: 0,
    name: 'Yes',
  },
  {
    id: 1,
    name: 'No',
  }
];
