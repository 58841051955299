import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from '../../material/material.module';
import { SharedModule } from '../../shared/shared.module';
import { IntakeDashboardQueuesComponent } from './components/dashboard/components/queues/queues.component';
import { IntakeDashboardComponent } from './components/dashboard/dashboard.component';
import { DataEntryCaseComponent } from './components/document/components/data/components/form/components/case/case.component';
import { ClinicalComponent } from './components/document/components/data/components/form/components/clinical/clinical.component';
import { InsuranceComponent } from './components/document/components/data/components/form/components/insurance/insurance.component';
import { MissedComponent } from './components/document/components/data/components/form/components/missed/missed.component';
import { RequiredMissedDialog } from './components/document/components/data/components/dialog/required/required.component';
import { PatientComponent } from './components/document/components/data/components/form/components/patient/patient.component';
import { PhysicianComponent } from './components/document/components/data/components/form/components/physician/physician.component';
import { MissedCaseModalComponent } from './components/document/components/data/components/dialog/missed/missed.component';
import { PrescriptionComponent } from './components/document/components/data/components/form/components/prescription/prescription.component';
import { FormComponent } from './components/document/components/data/components/form/form.component';
import { DocumentDataComponent } from './components/document/components/data/data.component';
import { DocumentComponent } from './components/document/document.component';
import { DocumentAsideComponent } from './components/document/components/type/components/aside/aside.component';
import { DeleteDocumentModalComponent } from './modals/delete/delete.component';
import { EmptyDocumentModalComponent } from './modals/empty/empty.component';
import { UncertainDocumentModalComponent } from './modals/uncertain-identification/uncertain-identification.component';
import { DocumentTypeComponent } from './components/document/components/type/type.component';
import { DesiredMissedDialog } from './components/document/components/data/components/dialog/desired/desired.component';
import { DocumentTypeListComponent } from './components/document/components/type/components/document-type-list/document-type-list.component';
import { SupervisorFooterComponent } from './components/document/components/type/components/supervisor-footer/supervisor-footer.component';
import { SupervisorMenuComponent } from './components/document/components/type/components/supervisor-menu/supervisor-menu.component';
import { RoleGuard } from '@core/guards/role.guard';
import { Roles } from '@shared/enums/roles.enum';
import { SubmitDeleteComponent } from './modals/submit-delete/submit-delete.component';
import { ConsentComponent } from './components/document/components/data/components/form/components/consent/consent.component';
import { MedicalInsuranceComponent } from './components/document/components/data/components/form/components/insurance/medical-insurance/medical-insurance.component';
import { PharmacyInsuranceComponent } from './components/document/components/data/components/form/components/insurance/pharmacy-insurance/pharmacy-insurance.component';
import { NgxMaskModule } from 'ngx-mask';

export const routes: Routes = [
  {
    path: '',
    component: DocumentComponent,
    children: [
      {
        path: 'document-identify',
        component: DocumentTypeComponent,
      },
      {
        canActivate: [RoleGuard],
        data: { roles: [Roles.ProgramAdmin, Roles.SystemAdmin] }, // same as supervisor module
        path: 'document-supervisor-identify',
        component: DocumentTypeComponent,
      },
      {
        path: 'document-data-entry',
        component: DocumentDataComponent,
      },
    ],
  },
];

const components = [
  IntakeDashboardComponent,
  IntakeDashboardQueuesComponent,
  DocumentDataComponent,
  DocumentComponent,
  DocumentTypeComponent,
  UncertainDocumentModalComponent,
  EmptyDocumentModalComponent,
  DeleteDocumentModalComponent,
  DocumentAsideComponent,
  FormComponent,
  DataEntryCaseComponent,
  ClinicalComponent,
  InsuranceComponent,
  MissedComponent,
  PatientComponent,
  PhysicianComponent,
  PrescriptionComponent,
  MissedCaseModalComponent,
  DesiredMissedDialog,
  RequiredMissedDialog,
  DocumentTypeListComponent,
  SupervisorFooterComponent,
  SupervisorMenuComponent,
  SubmitDeleteComponent,
  ConsentComponent
];

@NgModule({
  declarations: [
    ...components,
    MedicalInsuranceComponent,
    PharmacyInsuranceComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    NgxMaskModule,
  ],
  exports: [
    ...components,
  ],
})
export class IntakeModule {
}
