<form (ngSubmit)="save()" [formGroup]="newCaregiverForm" class="enrollment create-form form-row caregiver-form"
      #newCaregiverFormElement>

  <h3 *ngIf="!caregiver">New Caregiver</h3>

  <div class="form-row">
    <div class="input-item name" [class]="
          newCaregiverForm.controls['name'].invalid &&
          (newCaregiverForm.controls['name'].touched)
              ? 'input-error'
              : ''
        ">
      <label for="name">Caregiver Name</label>
      <input name="name" id="name" formControlName="name" [maxLength]="55" #caregiverNameField/>
    </div>
  </div>

  <div class="form-row">
    <div class="input-item select" [class]="
          newCaregiverForm.controls['relationshipId'].invalid &&
          (newCaregiverForm.controls['relationshipId'].touched)
              ? 'input-error'
              : ''
        ">
      <label for="relationshipId">Relation</label>
      <mat-form-field appearance="fill">
        <mat-select panelClass="dropdown-item" formControlName="relationshipId" name="relationshipId"
                    placeholder="Select" id="relationshipId">
          <mat-option *ngFor="let relationship of relationships" [value]="relationship.id">{{relationship.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="input-item phone" [class]="
          newCaregiverForm.controls['phone'].invalid &&
          (newCaregiverForm.controls['phone'].touched)
              ? 'input-error'
              : ''
        ">
      <label for="phone">Phone</label>
      <input name="phone" id="phone" formControlName="phone" mask="(000) 000-0000" placeholder="(_ _ _) _ _ _ - _ _ _ _"
             maxlength="150"/>
    </div>
    <div class="input-item email" [class]="
          newCaregiverForm.controls['email'].invalid &&
          (newCaregiverForm.controls['email'].touched)
              ? 'input-error'
              : ''
        ">
      <label for="email">Email</label>
      <input name="email" id="email" formControlName="email" maxlength="150"/>
    </div>
  </div>

  <div class="form-row current">
    <div class="input-item toggle">
      <mat-button-toggle-group formControlName="current">
        <mat-button-toggle [value]="true"> Yes</mat-button-toggle>
        <mat-button-toggle [value]="false"> No</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <span>Is this an active caregiver?</span>
  </div>

  <div class="controls default-form fx-row">
    <button class="cancel-btn" type="button" mat-button (click)="cancelled.emit()">
      exit
    </button>

    <button
      [disabled]="(!newCaregiverForm.controls['phone'].value && !newCaregiverForm.controls['email'].value) || deepEqual(initialData, newCaregiverForm.getRawValue()) || newCaregiverForm.invalid"
      class="save-btn" mat-button type="submit">
      save
    </button>
  </div>

</form>
