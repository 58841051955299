import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { BaseConfig } from '../../../configuration/configs/base.config';
import { DataEntryTabsConfig } from '../../../configuration/data-entry-tabs.config';
import { PrescriptionConfig } from '../../../configuration/configs/prescription.config';
import { TabId } from '../../../configuration/parameters/tab-identifier';
import { ClinicalConfig } from '../../../configuration/configs/clinical.config';
import { InsuranceConfig } from '../../../configuration/configs/insurance.config';
import { PatientConfig } from '../../../configuration/configs/patient.config';
import { PhysicianConfig } from '../../../configuration/configs/physician.config';
import { Observable } from 'rxjs';
import { AbstractRestService } from 'src/app/core/services/abstract-rest.service';
import { IResponse } from 'src/app/shared/interfaces/response.interface';
import { ConsentConfig } from '../../../configuration/configs/consent.config';

@Injectable({ providedIn: 'root', })
export class ConfigResolver extends AbstractRestService {
  private defaults = 
  {
    [TabId.patient]: PatientConfig,
    [TabId.clinical]: ClinicalConfig,
    [TabId.insurance]: InsuranceConfig,
    [TabId.physician]: PhysicianConfig,
    [TabId.prescription]: PrescriptionConfig,
    [TabId.consent]: ConsentConfig,
  }

  constructor(protected http: HttpClient){ super(http) }

  public resolve({ documentTypeId, programId }) {
    return new Observable<any>(state => {
      this.intakeConfigRequest(programId)
      .subscribe(
        res => {
          const config = res.value.find(e => e.documentTypeId === documentTypeId);

          this.getJson(config.fileName)
          .subscribe(
            json => {
              const jsonConfig = this.toConfig(json);
              
              state.next(jsonConfig);
            });
        },
        err => {
          console.error(err);
        });
    });
  }

  private intakeConfigRequest(programId) {
    this.url = this.apiUrl + `/configurations/intake-configurations/${programId}`;

    return this.get<IResponse>();
  }

  private getJson(fileName) {
    return this.http.get(`${window.location.origin}/assets/configuration/intake/${fileName}`).pipe(catchError(error => new Observable(e => e.next(undefined))));
  }

  private toConfig(json) {
    const config = new DataEntryTabsConfig();
    const jsonTabs = json.filter(e => e.isDisplayed);

    jsonTabs.forEach(jsonTab => {
      const data: any = { ordering: jsonTab.ordering };

      if(jsonTab.displayName) data.displayName = jsonTab.displayName;

      const tabConfig: BaseConfig = new this.defaults[jsonTab.id](data);

      this.assignFields(tabConfig.fieldsConfig, jsonTab.fieldsConfig);

      config[jsonTab.id] = tabConfig;
    });

    return config;
  }

  private assignFields(target, source){
    if(!source) return;

    const empty = [undefined, null, ''];
    
    Object.getOwnPropertyNames(target).forEach(field => {
      if (empty.includes(source[field])) return;

      if (Array.isArray(source[field]) || typeof source[field] !== 'object') { 
        target[field] = source[field];
        return;
      }

      Object.getOwnPropertyNames(target[field]).forEach(prop => {
        if (!empty.includes(source[field][prop])) target[field][prop] = source[field][prop];
      });

      target[field].UpdateState();
    });
  }
}
