<form [formGroup]="patientForm"
      novalidate
      (ngSubmit)="submitHandler()"
      class="document-sidebar-form"
>
  <div class="aside-wrapper">
    <h2>{{state.config.tabDisplayName}}</h2>

    <div *ngIf="state.config.fieldsConfig.householdPeople.isVisible" class="form-row full-row">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['householdPeople']),
          'input-success' : isValid(patientForm.controls['householdPeople'])
        }">
        <label for="householdPeople">{{ state.config.fieldsConfig.householdPeople.displayName }}</label>
        <input name="householdPeople" id="householdPeople" formControlName="householdPeople"
               [readonly]="state.config.fieldsConfig.householdPeople.isReadOnly"/>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.householdIncome.isVisible" class="form-row full-row">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['householdIncome']),
          'input-success' : isValid(patientForm.controls['householdIncome'])
        }">
        <label for="householdIncome">{{ state.config.fieldsConfig.householdIncome.displayName }}</label>
        <input name="householdIncome" id="householdIncome" formControlName="householdIncome"
               [readonly]="state.config.fieldsConfig.householdIncome.isReadOnly"/>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.supportingDocumentation.isVisible" class="form-row">
      <div class="input-item select" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['supportingDocumentation']),
          'input-success' : isValid(patientForm.controls['supportingDocumentation'])
        }">
        <label>{{ state.config.fieldsConfig.supportingDocumentation.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="supportingDocumentation" name="supportingDocumentation" placeholder="Select"
                      id="supportingDocumentation" (valueChange)="updateForm()" multiple
                      disableOptionCentering panelClass="dropdown-item">
            <mat-option [value]="SupportingDocumentation.mostRecentTaxReturn">Most recent federal tax return</mat-option>
            <mat-option [value]="SupportingDocumentation.w2Form">W-2 form</mat-option>
            <mat-option [value]="SupportingDocumentation.other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.supportingDocumentation.isVisible && showAdditionalDocumentationText" class="form-row full-row">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['supportingDocumentationText']),
          'input-success' : isValid(patientForm.controls['supportingDocumentationText'])
        }">
        <label for="supportingDocumentationText">{{ state.config.fieldsConfig.supportingDocumentationText.displayName }}</label>
        <input name="supportingDocumentationText" id="supportingDocumentationText" formControlName="supportingDocumentationText"
               [readonly]="state.config.fieldsConfig.supportingDocumentationText.isReadOnly"/>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.signature.isVisible" class="form-row">
      <div class="input-item select" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['signature']),
          'input-success' : isValid(patientForm.controls['signature'])
        }">
        <label>{{ state.config.fieldsConfig.signature.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="signature" name="signature" placeholder="Select" id="signature"
                      (selectionChange)="updateForm()"
                      disableOptionCentering panelClass="dropdown-item">
            <mat-option>--</mat-option>
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.signatureDate.isVisible && patientForm.controls['signature'].value === true" class="form-row" style="margin-top: 17px">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['signatureDate']),
          'input-success' : isValid(patientForm.controls['signatureDate'])
        }">
        <label for="signatureDate">{{ state.config.fieldsConfig.signatureDate.displayName }}</label>
        <div class="date-input">
          <input matInput
                 [matDatepicker]="signatureDate"
                 id="signatureDate"
                 formControlName="signatureDate"
                 placeholder="MM/DD/YY"
                 [max]="disabledDate"
          />
          <mat-datepicker-toggle matSuffix [for]="signatureDate" tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker #signatureDate></mat-datepicker>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div *ngIf="state.config.fieldsConfig.firstName.isVisible" class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['firstName']),
          'input-success' : isValid(patientForm.controls['firstName'])
        }">
        <label for="firstName">{{ state.config.fieldsConfig.firstName.displayName }}</label>
        <input name="firstName" id="firstName" formControlName="firstName" maxlength="50" autofocus
               [readonly]="state.config.fieldsConfig.firstName.isReadOnly"/>
      </div>

      <div *ngIf="state.config.fieldsConfig.middleName.isVisible" class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['middleName']),
          'input-success' : isValid(patientForm.controls['middleName'])
        }">
        <label for="middleName">{{ state.config.fieldsConfig.middleName.displayName }}</label>
        <input name="middleName" id="middleName" formControlName="middleName" maxlength="50"
               [readonly]="state.config.fieldsConfig.middleName.isReadOnly"/>
      </div>

      <div *ngIf="state.config.fieldsConfig.lastName.isVisible" class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['lastName']),
          'input-success' : isValid(patientForm.controls['lastName'])
        }">
        <label for="lastName">{{ state.config.fieldsConfig.lastName.displayName }}</label>
        <input name="lastName" id="lastName" formControlName="lastName" maxlength="50"
               [readonly]="state.config.fieldsConfig.lastName.isReadOnly"/>
      </div>
    </div>

    <div class="form-row"
         style="margin-top: 17px">
      <div class="input-item"
           *ngIf="state.config.fieldsConfig.dateOfBirth.isVisible"
           [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['dateOfBirth']),
          'input-success' : isValid(patientForm.controls['dateOfBirth'])
        }">
        <label for="dateOfBirth">{{ state.config.fieldsConfig.dateOfBirth.displayName }}</label>
        <div class="date-input">
          <input matInput
                 [matDatepicker]="dateOfBirth"
                 id="dateOfBirth"
                 formControlName="dateOfBirth"
                 placeholder="MM/DD/YY"
                 [max]="disabledDate"
          />
          <mat-datepicker-toggle matSuffix
                                 [for]="dateOfBirth"
                                 tabindex="-1"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirth></mat-datepicker>
        </div>
      </div>

      <div class="input-item select"
          *ngIf="state.config.fieldsConfig.gender.isVisible"
          [ngClass]="{
        'input-error' : isInvalid(patientForm.controls['gender']),
        'input-success' : isValid(patientForm.controls['gender'])
      }">
        <label>{{ state.config.fieldsConfig.gender.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="gender"
                      name="gender"
                      placeholder="Select"
                      id="gender"
                      disableOptionCentering
                      panelClass="dropdown-item">
            <mat-option>--</mat-option>
            <mat-option [value]="0">Female</mat-option>
            <mat-option [value]="1">Male</mat-option>
            <mat-option [value]="2">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.legalUSResident.isVisible" class="form-row">
      <div class="input-item select" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['legalUSResident']),
          'input-success' : isValid(patientForm.controls['legalUSResident'])
        }">
        <label for="legalUSResident">{{ state.config.fieldsConfig.legalUSResident.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="legalUSResident" name="legalUSResident" placeholder="Select"
                      id="legalUSResident" disableOptionCentering
                      panelClass="dropdown-item">
            <mat-option>--</mat-option>
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.streetAddress.isVisible" class="form-row full-row" formGroupName="address">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls.address['controls']['streetAddress']),
          'input-success' : isValid(patientForm.controls.address['controls']['streetAddress'])
        }">
        <label for="address">{{ state.config.fieldsConfig.address.displayName }}</label>
        <input name="address" id="address" formControlName="streetAddress" maxlength="150" #addressField
               [readonly]="state.config.fieldsConfig.address.isReadOnly"
               (focus)="toggleAddressFocus(true)" (blur)="toggleAddressFocus(false)"/>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.addressExtension.isVisible" class="form-row full-row address-line-2" formGroupName="address">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls.address['controls']['addressExtension']),
          'input-success' : isValid(patientForm.controls.address['controls']['addressExtension'])
        }">
        <label for="address2">{{ state.config.fieldsConfig.addressExtension.displayName }}</label>
        <input name="address2" id="address2" formControlName="addressExtension" maxlength="150"
               [readonly]="state.config.fieldsConfig.addressExtension.isReadOnly"/>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.city.isVisible" class="form-row" formGroupName="address">
      <div class="input-item"
           [ngClass]="{
          'input-error' : isInvalid(patientForm.controls.address['controls']['city']),
          'input-success' : isValid(patientForm.controls.address['controls']['city'])
        }">
        <label for="city">{{ state.config.fieldsConfig.city.displayName }}</label>
        <input name="city"
               id="city"
               formControlName="city"
               [readonly]="state.config.fieldsConfig.city.isReadOnly" />
      </div>

      <div class="input-item"
           [ngClass]="{
          'input-error' : isInvalid(patientForm.controls.address['controls']['state']),
          'input-success' : isValid(patientForm.controls.address['controls']['state'])
        }">
        <label for="state">{{ state.config.fieldsConfig.state.displayName }}</label>
        <input name="state"
               id="state"
               formControlName="state"
               [readonly]="state.config.fieldsConfig.state.isReadOnly" />
      </div>

      <div class="input-item"
           [ngClass]="{
          'input-error' : isInvalid(patientForm.controls.address['controls']['zipCode']),
          'input-success' : isValid(patientForm.controls.address['controls']['zipCode'])
        }">
        <label for="zipCode">{{ state.config.fieldsConfig.zipCode.displayName }}</label>
        <input name="zipCode"
               id="zipCode"
               formControlName="zipCode"
               [readonly]="state.config.fieldsConfig.zipCode.isReadOnly" />
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.socialSecurityNumber.isVisible" class="form-row">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['socialSecurityNumber']),
          'input-success' : isValid(patientForm.controls['socialSecurityNumber'])
        }">
        <label for="socialSecurityNumber">{{ state.config.fieldsConfig.socialSecurityNumber.displayName }}</label>
        <input name="socialSecurityNumber" id="socialSecurityNumber" formControlName="socialSecurityNumber"
               [readonly]="state.config.fieldsConfig.socialSecurityNumber.isReadOnly"
               mask="000-00-0000" placeholder="AAA-GG-SSSS"/>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.bestTimeToContact.isVisible" class="form-row">
      <div class="input-item select" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['bestTimeToContact']),
          'input-success' : isValid(patientForm.controls['bestTimeToContact'])
        }">
        <label>{{ state.config.fieldsConfig.bestTimeToContact.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="bestTimeToContact" name="bestTimeToContact" placeholder="Select"
                      id="bestTimeToContact" disableOptionCentering panelClass="dropdown-item">
            <mat-option>--</mat-option>
            <mat-option [value]="0">Morning</mat-option>
            <mat-option [value]="1">Afternoon</mat-option>
            <mat-option [value]="2">Evening</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.bestMethodToContact.isVisible" class="form-row">
      <div class="input-item select" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['bestMethodToContact']),
          'input-success' : isValid(patientForm.controls['bestMethodToContact'])
        }">
        <label>{{ state.config.fieldsConfig.bestMethodToContact.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="bestMethodToContact" name="bestMethodToContact" placeholder="Select"
                      id="bestMethodToContact" disableOptionCentering panelClass="dropdown-item">
            <mat-option>--</mat-option>
            <mat-option [value]="0">Phone</mat-option>
            <mat-option [value]="2">Email</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row" formGroupName="phoneInfo">
      <div *ngIf="state.config.fieldsConfig.contactString.isVisible" class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls.phoneInfo['controls']['contactString']),
          'input-success' : isValid(patientForm.controls.phoneInfo['controls']['contactString'])
        }">
        <label for="phone">{{ state.config.fieldsConfig.contactString.displayName }}</label>
        <input name="contactString" id="contactString" formControlName="contactString" mask="(000) 000-0000"
               [readonly]="state.config.fieldsConfig.contactString.isReadOnly"
               placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
      </div>

      <div *ngIf="state.config.fieldsConfig.contactType.isVisible" class="input-item select" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls.phoneInfo['controls']['contactType']),
          'input-success' : isValid(patientForm.controls.phoneInfo['controls']['contactType'])
        }">
        <label>{{ state.config.fieldsConfig.contactType.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="contactType" name="contactType" placeholder="Select" id="contactType"
                      disableOptionCentering panelClass="dropdown-item">
            <mat-option>--</mat-option>
            <mat-option [value]="0">Home</mat-option>
            <mat-option [value]="1">Cell</mat-option>
            <mat-option [value]="2">Work</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.email.isVisible" class="form-row full-row">
      <div class="input-item" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['email']),
          'input-success' : isValid(patientForm.controls['email'])
        }">
        <label for="email">{{ state.config.fieldsConfig.email.displayName }}</label>
        <input name="email" id="email" formControlName="email" maxlength="150"
               [readonly]="state.config.fieldsConfig.email.isReadOnly"/>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.hipaaConsent.isVisible" class="form-row">
      <div class="input-item select" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['hipaaConsent']),
          'input-success' : isValid(patientForm.controls['hipaaConsent'])
        }">
        <label for="hipaaConsent">{{ state.config.fieldsConfig.hipaaConsent.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="hipaaConsent" name="hipaaConsent" placeholder="Select"
                      (selectionChange)="selectHipaaConsent()" id="hipaaConsent" disableOptionCentering
                      panelClass="dropdown-item">
            <mat-option>--</mat-option>
            <mat-option [value]="0">Yes</mat-option>
            <mat-option [value]="1">No</mat-option>
            <mat-option [value]="2">Unknown</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row" style="margin-top: 17px" *ngIf="+patientForm.value.hipaaConsent === 0 && state.config.fieldsConfig.hipaaConsentSignatureDate.isVisible">
      <div class="input-item select" [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['hipaaConsentSignatureDate']),
          'input-success' : isValid(patientForm.controls['hipaaConsentSignatureDate'])
        }">
        <label for="hipaaConsentSignatureDate">{{ state.config.fieldsConfig.hipaaConsentSignatureDate.displayName }}</label>
        <div class="date-input">
          <input matInput
                 [matDatepicker]="hipaaConsentSignatureDate"
                 id="hipaaConsentSignatureDate"
                 [readonly]="state.config.fieldsConfig.hipaaConsentSignatureDate.isReadOnly"
                 formControlName="hipaaConsentSignatureDate"
                 placeholder="MM/DD/YY"
                 [max]="disabledDate"
          />
          <mat-datepicker-toggle matSuffix [for]="hipaaConsentSignatureDate" tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker #hipaaConsentSignatureDate></mat-datepicker>
        </div>
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.caregiverName.isVisible"
         class="form-row full-row">
      <div class="input-item"
           [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['caregiverName']),
          'input-success' : isValid(patientForm.controls['caregiverName'])
        }">
        <label for="caregiverName">{{ state.config.fieldsConfig.caregiverName.displayName }}</label>
        <input name="caregiverName"
               id="caregiverName"
               formControlName="caregiverName"
               maxlength="50"
               [readonly]="state.config.fieldsConfig.caregiverName.isReadOnly" />
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.caregiverName.isVisible"
         class="form-row full-row">
      <div class="input-item"
           [ngClass]="{
            'input-error' : isInvalid(patientForm.controls['caregiverPhone']),
            'input-success' : isValid(patientForm.controls['caregiverPhone'])
          }">
        <label for="caregiverPhone">{{ state.config.fieldsConfig.caregiverPhone.displayName }}</label>
        <input name="caregiverPhone"
               id="caregiverPhone"
               formControlName="caregiverPhone"
               mask="(000) 000-0000"
               [readonly]="state.config.fieldsConfig.caregiverPhone.isReadOnly"
               placeholder="(_ _ _) _ _ _ - _ _ _ _" />
      </div>
    </div>

    <div *ngIf="state.config.fieldsConfig.caregiverRelationship.isVisible"
         class="form-row">
      <div class="input-item select"
           [ngClass]="{
          'input-error' : isInvalid(patientForm.controls['caregiverRelationship']),
          'input-success' : isValid(patientForm.controls['caregiverRelationship'])
        }">
        <label for="caregiverRelationship">{{ state.config.fieldsConfig.caregiverRelationship.displayName }}</label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="caregiverRelationship"
                      name="caregiverRelationship"
                      placeholder="Select"
                      id="caregiverRelationship"
                      disableOptionCentering
                      panelClass="dropdown-item">
            <mat-option>--</mat-option>
            <mat-option [value]="relationship.id"
                        *ngFor="let relationship of relationships">{{relationship.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <button *ngIf="state.config.nextTab" type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()" [disabled]="isFormLoading || patientForm.invalid">
    <b>Next:</b> {{ state.config.nextTab.config.tabDisplayName }}
    <mat-icon>east</mat-icon>
  </button>

  <button *ngIf="!state.config.nextTab" type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()" [disabled]="isFormLoading || patientForm.invalid">
    <b>Complete</b>
  </button>
</form>
