import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Label } from 'ng2-charts';
import { Store } from '@ngrx/store';
import { queueDataMock } from '../../../../../testing/mocks/queueDataMock';
import { ChartType } from '../../enums/chart-type.enum';
import { QueueTrendingService } from '../../services/queueTrending.service';
import { throwError } from 'rxjs';
import { FeatureService } from '../../services/feature.service';
import { Roles } from '../../enums/roles.enum';
import { QueueType } from '../../enums/queue-type.enum';

@Component({
  selector: 'app-queue-item',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss'],
})
export class QueueItemComponent implements OnInit, AfterViewInit {
  @Input() data;
  @Input() beginRoute;
  @Input() beginParams;
  @Input() viewRoute;
  @Input() viewParams;

  userRole: string;
  trendType;
  chartData = [];
  lineChartLabels: Label[] = [];

  @ViewChild('queueChart') queueChart;

  lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        top: 5,
        bottom: 5,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      yAxes: [
        {
          display: false,
          ticks: {
            display: false,
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          display: false,
          ticks: {
            display: false,
          },
        },
      ],
    },
  };

  chartType = {
    inc: [],
    dec: [],
    stable: []
  };

  lineChartType = 'line';

  constructor(
    private store: Store<any>,
    private queueTrendingService: QueueTrendingService,
    private featureService: FeatureService,
  ) {
    this.store.select('user').subscribe((state) => {
      this.userRole = state.role;
    });
  }

  ngOnInit(): void {
    if (this.data.queueConfiguration.queueId) {
      if (this.featureService.isFeatureEnabled('queueTrendReport')) {
        this.queueTrendingService.getQueueTrends(this.data.queueConfiguration.queueId).subscribe((result) => {
          const dataPoints = this.queueTrendingService.getDataPoints(result.value);
          this.trendType = this.queueTrendingService.getChartType(dataPoints);
          this.chartType[this.trendType] = this.queueTrendingService.getChartColor(this.queueChart, this.trendType);
          this.lineChartLabels = dataPoints.map(x => x.label);
          this.chartData = [{data: dataPoints, lineTension: 0}];
        }, err => throwError(err));
      } else {
        this.trendType = ChartType.increasing;
        this.lineChartLabels = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su', 'M'];
        this.chartData = [{data: queueDataMock.new.data, lineTension: 0}];
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.featureService.isFeatureEnabled('queueTrendReport')) {
        this.chartType[this.trendType] = this.queueTrendingService.getChartColor(this.queueChart, ChartType.increasing);
      }
    }, 0);
  }

  showBegin(): boolean {
    return this.beginRoute && this.showBeginOrViewListLink();
  }

  showViewList(): boolean {
    return (
      this.userRole === Roles.SystemAdmin ||
      this.userRole === Roles.ProgramAdmin ||
      this.userRole === Roles.AgentAll ||
      this.userRole === Roles.AgentRestricted
    );
  }

  public showViewListLink(): boolean {
    return this.viewRoute && this.showBeginOrViewListLink();
  }

  public showBeginOrViewListLink(): boolean {
    return (this.userRole === Roles.AgentAll || this.userRole === Roles.AgentRestricted)
      && !this.data.queueConfiguration.userCanPull
      && this.data.queueType !== QueueType.supervisor;
  }

  public showManage(): boolean {
    return (this.userRole === Roles.SystemAdmin || this.userRole === Roles.ProgramAdmin) && this.data.queueType !== QueueType.supervisor;
  }
}
