<form class="standard-form selected-physician" [ngClass]="{'header-only': headerOnly}">
  <div class="physician-title">
    <div>
      <img src="assets/icons/icon-check.svg" alt="check"/>
      <h5>
        <ng-content select="[title]"></ng-content>
      </h5>
    </div>
    <div>
      <button type="button" *ngIf="showEditButton && !editMode" (click)="edit()" class="edit">
        <mat-icon>edit</mat-icon>
      </button>
      <button type="button" (click)="removeForm.emit()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <ng-content *ngIf="!editMode" select="[viewContent]"></ng-content>

  <ng-container *ngIf="showEditButton && editMode">
    <ng-content select="[editContent]"></ng-content>

    <div class="action-buttons">
      <button class="cancel" (click)="exit()">Exit</button>
      <div class="primary-btn" (click)="save()">Save</div>
    </div>
  </ng-container>
</form>
