<div class="dialog">
  <div class="dialog-header">
    <span class="header-text">
      <img src="assets/icons/shield-active-blue.svg" alt="icon_shield"/>
      Update {{data?.isPharmacy ? 'Pharmacy Benefit Plan' : 'Payer & Plan'}}
    </span>
    <mat-icon class="header-close-btn" mat-dialog-close>close</mat-icon>
  </div>

  <div class="dialog-content" [ngClass]="{pharmacy: data?.isPharmacy}">
    <span class="description">
      You’re updating {{data?.isPharmacy ? 'the pharmacy benefit plan' : 'the payer and plan'}} for <span>Case ID #{{data?.case.id}}</span>.
    </span>

    <div class="divider"></div>

    <div class="medical-plan-dialog">
      <div class="input-item search">
        <label for="physicianSearch">Choose Different Plan</label>
        <div class="search-input-wrapper">
          <img *ngIf="!isPlanSearchActive"
               src="assets/icons/icon-search.svg"
               alt="search"
          />
          <img *ngIf="isPlanSearchActive"
               src="assets/icons/icon-search-active.svg"
               alt="search"
          />
          <input matInput
                 type="text"
                 id="physicianSearch"
                 [formControl]="planSearchControl"
                 (focus)="isPlanSearchActive = true"
                 (blur)="isPlanSearchActive = false"
                 [placeholder]="data?.isPharmacy ? 'Search by Plan Name, Phone, Bin, PCN' : 'Search by Payer, Plan, Plan Phone'"
                 (keydown)="searchNavigate($event)"
                 #searchInput
          />
        </div>
      </div>

      <!-- LIST OF PLANS -->

      <ul class="plan-results plan-search"
          *ngIf="planSearchControl.value.length >= 3 && !(planOptions.newPlan || planOptions.selectedPlan)"
          #planSearchList
      >
        <li *ngIf="data?.isPharmacy && this.canAddNewPharmacyPlansOnCaseSideTab" class="add">
          <button class="primary-btn" type="button" (click)="addNewPlan()">
            <mat-icon>add_circle</mat-icon>
            New Plan
          </button>
        </li>        
        <li *ngFor="let payer of payers$ | async; index as i" class="add">
          <div class="plan-title">
            <h5>{{ payer.name }}</h5>
            <span>Plan Unknown</span>
          </div>
          <button class="primary-btn" type="button" (click)="addNewPlan(payer)">
            <mat-icon>add_circle</mat-icon>
            Add Plan
          </button>
        </li>
        <li *ngFor="let plan of plans$ | async; index as i"
            (click)="selectPlan(plan)"
            [class.focused]="focusedSearch === i"
            class="plan"
        >
          <div class="plan-title">
            <h5>{{ plan.name }}</h5>
          </div>
          <div *ngIf="!data?.isPharmacy" class="plan-wrapper">
            <div class="plan-item">
              <h6>Payer</h6>
              <p>{{ plan?.payerName | empty}}</p>
            </div>
            <div class="plan-item">
              <h6>Phone</h6>
              <p>{{ plan.phone ? (plan.phone | mask : '(000) 000-0000') : '--'}}</p>
            </div>
          </div>
          <div *ngIf="data?.isPharmacy" class="plan-wrapper">
            <div class="plan-item phone">
              <h6>Phone</h6>
              <p>{{ plan.phone ? (plan.phone | mask : '(000) 000-0000') : '--'}}</p>
            </div>
            <div class="plan-item bin">
              <h6>BIN</h6>
              <p>{{ plan.bin | empty}}</p>
            </div>
            <div class="plan-item pcn">
              <h6>PCN</h6>
              <p>{{ plan.pcn | empty}}</p>
            </div>
          </div>
        </li>
      </ul>

      <!-- NEW PLAN -->

      <app-selected-physician
        [showEditButton]="false"
        [headerOnly]="true"
        (removeForm)="unselectNewPlan()"
        class="new-plan-selector"
        *ngIf="planOptions.newPlan && !data?.isPharmacy"
      >
        <ng-container title>
          <div class="new-plan-title">
            <div class="title">
              {{ planOptions.selectedPayer.name }}
            </div>
            <span>New Plan</span>
          </div>
        </ng-container>
      </app-selected-physician>

      <form *ngIf="planOptions.newPlan" class="new-plan-form" [formGroup]="newPlanForm">
        <ng-scrollbar class="custom-scrollbar scroll-wrapper">
          <div *ngIf="data?.isPharmacy" class="pharmacy-plan-heading">
            New Plan
            <mat-icon (click)="unselectNewPlan()" class="new-plan-close-btn" mat-dialog-close>close</mat-icon>
          </div>

          <div class="form-wrapper">
            <div class="form-row plan-name">
              <div class="input-item" [ngClass]="{
              'input-error': newPlanForm.controls['name'].invalid && (newPlanForm.controls['name'].touched),
              'input-success': newPlanForm.value.name && newPlanForm.controls['name'].touched
              }"
              >
                <label for="name">Plan Name</label>
                <input name="name" id="name" formControlName="name"/>
                <div class="error-label">This field is required</div>
              </div>

              <div *ngIf="data?.isPharmacy" class="pbm-wrapper">
                <mat-checkbox formControlName="isPBM">
                  <span class="checkbox-caption">Is PBM?</span>
                </mat-checkbox>
              </div>
            </div>

            <div *ngIf="!data?.isPharmacy" class="form-row">
              <div class="input-item select half-width" [ngClass]="{
                'input-error':
                    newPlanForm.controls['typeId'].invalid && newPlanForm.controls['typeId'].touched,
                'input-success':
                    newPlanForm.controls['typeId'].valid && newPlanForm.controls['typeId'].touched
              }">
                <label for="typeId">Type</label>
                <mat-form-field appearance="fill">
                  <mat-select panelClass="dropdown-item"
                              formControlName="typeId"
                              name="target"
                              placeholder="Select"
                              id="typeId"
                  >
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let type of types$ | async" [value]="type.id">
                      {{type.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="error-label">This field is required</div>
              </div>

              <div class="input-item select half-width" [ngClass]="{
                'input-error':
                    newPlanForm.controls['subTypeId'].invalid && newPlanForm.controls['subTypeId'].touched,
                'input-success':
                    newPlanForm.controls['subTypeId'].valid && newPlanForm.controls['subTypeId'].touched
              }">
                <label for="subTypeId">Subtype</label>
                <mat-form-field appearance="fill">
                  <mat-select panelClass="dropdown-item"
                              formControlName="subTypeId"
                              name="target"
                              placeholder="Select"
                              id="subTypeId"
                  >
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let subType of subTypes$ | async" [value]="subType.id">
                      {{subType.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="error-label">This field is required</div>
              </div>
            </div>

            <div class="form-row street">
              <div class="input-item" [ngClass]="{
                'input-error':
                    newPlanForm.controls['street'].invalid &&
                    (newPlanForm.controls['street'].touched),
                'input-success': newPlanForm.value.street && newPlanForm.controls['street'].touched
                }">
                <label for="street">Address</label>
                <input name="street" id="street" formControlName="street"/>
                <div class="error-label">This field is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="input-item" [ngClass]="{
                  'input-error':
                      newPlanForm.controls['addressExtension'].invalid &&
                      (newPlanForm.controls['addressExtension'].touched),
                  'input-success': newPlanForm.controls['addressExtension'].valid && newPlanForm.controls['addressExtension'].touched
                }">
                <input name="addressExtension" id="addressExtension" formControlName="addressExtension"/>
                <div class="error-label">This field is required</div>
              </div>
            </div>

            <div class="form-row">
              <div class="input-item city" [ngClass]="{
                  'input-error':
                      newPlanForm.controls['city'].invalid &&
                      (newPlanForm.controls['city'].touched),
                  'input-success':
                      newPlanForm.controls['city'].valid && newPlanForm.controls['city'].touched
                }">
                <label for="city">City</label>
                <input name="city" id="city" formControlName="city"/>
                <div class="error-label">This field is required</div>
              </div>
              <div class="input-item select state" [ngClass]="{
                'input-error':
                    newPlanForm.controls['state'].invalid && newPlanForm.controls['state'].touched,
                'input-success':
                    newPlanForm.controls['state'].valid && newPlanForm.controls['state'].touched
              }">
                <label for="state">State</label>
                <mat-form-field appearance="fill">
                  <mat-select panelClass="dropdown-item"
                              formControlName="state"
                              name="target"
                              placeholder="Select"
                              id="state"
                  >
                    <mat-option *ngFor="let state of states$ | async" [value]="state">
                      {{state}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="error-label">This field is required</div>
              </div>
              <div class="input-item zip" [ngClass]="{
              'input-error':
                  newPlanForm.controls['zip'].invalid &&
                  (newPlanForm.controls['zip'].touched),
              'input-success':
                  newPlanForm.controls['zip'].valid && newPlanForm.controls['zip'].touched
            }">
                <label for="zip">ZIP</label>
                <input name="zip" id="zip" formControlName="zip" placeholder="40222" maxlength="5" />
                <div class="error-label">This field is required</div>
              </div>
            </div>

            <div class="form-row">
              <div class="input-item phone half-width" [ngClass]="{
                  'input-error':
                      newPlanForm.controls['payerPhone'].invalid &&
                      (newPlanForm.controls['payerPhone'].touched),
                  'input-success':
                      newPlanForm.controls['payerPhone'].valid &&
                      newPlanForm.controls['payerPhone'].touched
                }">
                <label for="payerPhone">Phone</label>
                <input name="payerPhone"
                       id="payerPhone"
                       formControlName="payerPhone"
                       mask="(000) 000-0000"
                       placeholder="(_ _ _) _ _ _ - _ _ _ _"
                />
                <div class="error-label">This field is required</div>
              </div>

              <div class="input-item fax half-width" [ngClass]="{
                  'input-error':
                      newPlanForm.controls['payerFax'].invalid &&
                      (newPlanForm.controls['payerFax'].touched),
                  'input-success':
                      newPlanForm.controls['payerFax'].valid && newPlanForm.controls['payerFax'].touched
                }">
                <label for="payerFax">Fax</label>
                <input name="payerFax"
                       id="payerFax"
                       formControlName="payerFax"
                       mask="(000) 000-0000"
                       placeholder="(_ _ _) _ _ _ - _ _ _ _"
                />
                <div class="error-label">This field is required</div>
              </div>
            </div>

            <div *ngIf="data?.isPharmacy" class="form-row">
              <div class="input-item select preferred-specialty-pharmacy" [ngClass]="{
                  'input-error':
                      newPlanForm.controls['preferredSpecialtyPharmacy'].invalid && newPlanForm.controls['preferredSpecialtyPharmacy'].touched,
                  'input-success':
                      newPlanForm.controls['preferredSpecialtyPharmacy'].valid && newPlanForm.controls['preferredSpecialtyPharmacy'].touched
                }">
                <label for="preferredSpecialtyPharmacy">Preferred Specialty Pharmacy</label>
                <mat-form-field appearance="fill">
                  <mat-select panelClass="dropdown-item"
                              formControlName="preferredSpecialtyPharmacy"
                              name="target"
                              placeholder="Select"
                              id="preferredSpecialtyPharmacy"
                  >
                    <mat-option *ngFor="let item of preferredSpecialtyPharmacyList$ | async" [value]="item.id">
                      {{item.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="error-label">This field is required</div>
              </div>
            </div>

            <div *ngIf="data?.isPharmacy" class="form-row">
              <div class="input-item half-width" [ngClass]="{
              'input-error': newPlanForm.controls['bin'].invalid && (newPlanForm.controls['bin'].touched),
              'input-success': newPlanForm.value.bin && newPlanForm.controls['bin'].touched
              }"
              >
                <label for="bin">BIN</label>
                <input name="bin" id="bin" formControlName="bin"/>
                <div class="error-label">This field is required</div>
              </div>

              <div class="input-item half-width" [ngClass]="{
              'input-error': newPlanForm.controls['pcn'].invalid && (newPlanForm.controls['pcn'].touched),
              'input-success': newPlanForm.value.pcn && newPlanForm.controls['pcn'].touched
              }"
              >
                <label for="pcn">PCN</label>
                <input name="pcn" id="pcn" formControlName="pcn"/>
                <div class="error-label">This field is required</div>
              </div>
            </div>
          </div>
        </ng-scrollbar>
      </form>

      <!-- SELECTED PLAN -->

      <app-selected-physician
        [showEditButton]="false"
        (removeForm)="selectPlan(null)"
        *ngIf="planOptions.selectedPlan"
      >
        <ng-container title>
          {{ planOptions.selectedPlan.name }}
        </ng-container>
        <ng-container viewContent>
          <div *ngIf="!data?.isPharmacy" class="plan-wrapper selected">
            <div class="plan-item">
              <h6>Payer</h6>
              <p>{{ planOptions.selectedPlan.payerName | empty}}</p>
            </div>
            <div class="plan-item">
              <h6>Phone</h6>
              <p>{{ planOptions.selectedPlan.phone | mask: '(000) 000-0000' | empty}}</p>
            </div>
          </div>
          <div *ngIf="data?.isPharmacy" class="plan-wrapper selected">
            <div class="plan-item phone">
              <h6>Phone</h6>
              <p>{{ planOptions.selectedPlan.phone | mask : '(000) 000-0000' | empty}}</p>
            </div>
            <div class="plan-item bin">
              <h6>BIN</h6>
              <p>{{ planOptions.selectedPlan.bin | empty}}</p>
            </div>
            <div class="plan-item pcn">
              <h6>PCN</h6>
              <p>{{ planOptions.selectedPlan.pcn | empty}}</p>
            </div>
          </div>
        </ng-container>
      </app-selected-physician>
    </div>
  </div>

  <div class="dialog-footer stick-bottom">
    <button [disabled]="!canSave()" (click)="submit()" class="submit-btn">
      Save
    </button>
  </div>
</div>
