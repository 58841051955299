import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/classes/UnsubscribeOnDestroy';
import { CoverageType } from 'src/app/shared/enums/insurance/coverage-type.enum';
import { PlanOrder } from 'src/app/shared/enums/plan-order.enum';
import { formatPhone, interpolateTemplate } from 'src/app/shared/helpers/utils';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { TargetNames } from '@shared/enums/target-names.enum';
import { TaskContactMethodNames } from '@shared/enums/task-contact-method-names.enum';
import { TaskOutcome } from '@shared/enums/task-outcome.enum';
import { CaseService } from '../../../../../../services/case.service';

@Component({
  selector: 'app-task-adherence-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['../../../../task.component.scss', '../../../welcome/welcome.component.scss', './welcome.component.scss'],
})
export class AdherenceWelcomeTaskComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() selectedTask;
  @Input() case;

  @Output() minimizeTaskHandler = new EventEmitter();
  @Output() setSelectedTaskHandler = new EventEmitter();
  @Output() rescheduleTaskHandler = new EventEmitter();
  @Output() submitTaskHandler = new EventEmitter();

  welcomeForm: FormGroup;
  taskTarget;
  interpolatedName;
  interpolatedDescription;
  interpolatedScript;

  constructor(private caseService: CaseService) {
    super();
    this.welcomeForm = new FormGroup({
      contact: new FormControl(null, [Validators.required]),
      success: new FormControl(null, [Validators.required]),
      note: new FormControl(null, [Validators.required]),
    });
  }

  minimizeTask(val: boolean): void {
    this.selectedTask.formData = this.welcomeForm.getRawValue();
    this.minimizeTaskHandler.emit(val);
  }

  setSelectedTask(task): void {
    this.setSelectedTaskHandler.emit(task);
  }

  submitTask(): void {
    const contact = +this.welcomeForm.value.contact === 0;
    const success = +this.welcomeForm.value.success === 0;
    const data = {
      queueItemId: this.case.id,
      queueTaskId: this.selectedTask.id,
      taskOutcome: contact && success && this.selectedTask.attemptNumber < this.selectedTask.maxAttempts - 1 ?
        TaskOutcome.success :
        TaskOutcome.failure,
      outcomeNote: this.welcomeForm.value.note,
    };
    this.submitTaskHandler.emit(data);
  }

  rescheduleTask(): void {
    const data = {
      attemptNumber: this.selectedTask.attemptNumber,
      task: this.selectedTask.task,
      outcomeNote: this.welcomeForm.value.note,
      case: this.case,
      target: this.selectedTask.target,
      followUpReasonId: this.selectedTask.followUpReasonId
    };
    this.rescheduleTaskHandler.emit(data);
  }

  ngOnInit(): void {
    this.interpolatedDescription = interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject());
    this.interpolatedName = interpolateTemplate(this.selectedTask.task, this.getNameTemplateObject());
    this.taskTarget = (TargetNames[this.selectedTask.target] || 'patient').toLowerCase();

    this.interpolatedScript = interpolateTemplate(this.selectedTask.script, this.getScriptTemplateObject());

    this.selectedTask.formData = {
      ...this.selectedTask.formData,
      note: this.selectedTask.formData?.note || this.selectedTask.outcomeNote
    };
    this.welcomeForm.patchValue(this.selectedTask.formData);
    this.caseService.case$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((caseValue) => {
        this.case = caseValue;
        this.interpolatedDescription = interpolateTemplate(this.selectedTask.message, this.getDescriptionTemplateObject());
      });
  }

  getNameTemplateObject(): any {
    return {
      reason: this.selectedTask.followUpReason,
      contactMethod: TaskContactMethodNames[this.selectedTask.contactMethod] || '',
      entity: TargetNames[this.selectedTask.target] || ''
    };
  }

  getDescriptionTemplateObject(): any {
    const patientPhone =
      this.case.patient && this.case.patient.contactInfos && this.case.patient.contactInfos.length
        ? this.case.patient.contactInfos.find((contact) => contact.contactMethod === ContactMethod.phone)
        : null;
    const patientName = this.case.patient ? `${this.case.patient.firstName} ${this.case.patient.lastName}` : '';
    const genderPronoun = this.case.patient.gender === 0 ? 'her' : (this.case.patient.gender === 1 ? 'him' : 'them');
    const entity = this.getEntityProperties();
    const contactString = this.selectedTask.contactMethod === ContactMethod.email ?
      entity.contactString :
      formatPhone(entity.contactString);
    const contactLink = this.selectedTask.contactMethod === ContactMethod.email ? `mailto:${entity.contactString}` : '#';

    return {
      patientPhone: `<a href="#" class="highlighted">${formatPhone(patientPhone?.contactString)}</a>`,
      patientName: `<span class="highlighted">${patientName}</span>`,
      genderPronoun,
      reason: this.selectedTask.followUpReason?.toLowerCase() || '',
      entityName: `<span class="highlighted">${entity.name}</span>`,
      entityPhone: `<a href="${contactLink}" class="highlighted">${contactString}</a>`,
      contactMethod: TaskContactMethodNames[this.selectedTask.contactMethod] || ''
    };
  }

  getEntityProperties(): any {
    const entity = {
      name: '',
      contactString: '',
    };

    // tslint:disable-next-line
    if (this.selectedTask.target === TargetNames['Patient']) {
      entity.name = this.case.patient ? `${this.case.patient.firstName} ${this.case.patient.lastName}` : '';
      entity.contactString = this.findContactInfo(this.case.patient.contactInfos, this.selectedTask.contactMethod)?.contactString || '';
      // tslint:disable-next-line
    } else if (this.selectedTask.target === TargetNames['Payer']) {
      const primaryMedicalInsurance = this.case.caseInsurances
        ?.find(x => x.insurancePriorityId === PlanOrder.primary && x.patientInsurance.coverageTypeId === CoverageType.medical)
        ?.patientInsurance;
      entity.name = primaryMedicalInsurance?.payerName || '';
      entity.contactString = this.selectedTask.contactMethod === ContactMethod.email
        ? '' : (primaryMedicalInsurance?.payerPhone || '');
      // tslint:disable-next-line
    } else if (this.selectedTask.target === TargetNames['Physician']) {
      entity.name = this.case.physician ? `${this.case.physician.firstName} ${this.case.physician.lastName}` : '';
      entity.contactString = this.findContactInfo(this.case.facility?.contactInfos, this.selectedTask.contactMethod)?.contactString || '';
      // tslint:disable-next-line
    } else if (this.selectedTask.target === TargetNames['Pharmacy']) {
      entity.name = this.case.specialtyPharmacy?.name || '';
      entity.contactString =
        this.findContactInfo(this.case.specialtyPharmacy?.contactInfos, this.selectedTask.contactMethod)?.contactString || '';
    }

    return entity;
  }

  getScriptTemplateObject(): any {
    return {
      patientName: `${this.case.patient.firstName || ''}`,
      caseManagerName: 'Heather',
      newLine: '<br><br>'
    };
  }

  findContactInfo(contactInfos, contactMethod): any {
    if (!contactInfos || contactInfos.length === 0) {
      return null;
    }

    const result = contactInfos.find((contact) => contact.contactMethod === contactMethod);

    if (!result) {
      if (contactMethod === ContactMethod.text) {
        return contactInfos.find((contact) => contact.contactMethod === ContactMethod.phone);
      }
    }

    return result;
  }
}
