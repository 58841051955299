import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'nestedFormFields',
  pure: false,
})
export class NestedFormFieldsPipe implements PipeTransform {

  transform(value: FormGroup, i: number, j: number, k: number): FormGroup {
    return value.get('group_' + i).get('section_' + j).get('block_' + k) as FormGroup;
  }

}
