<div class="dialog-container">
  <div mat-dialog-title class="dialog-header">
    <div *ngIf="!generalInquirySaved" class="header">
      <img src="assets/icons/icon-patient-info.svg" alt="general_inquiry" />
      <h1>Log General Inquiry</h1>
    </div>

    <button class="close-btn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="inquiryFormGroup" class="dialog-body custom-scroll-default" [ngClass]="{
        'fill-form': inquiryFormGroup.get('inquiryTypeId').value !== null,
        'saved': generalInquirySaved
        }">

    <!-- SWITCH INQUIRY TYPE -->
    <div *ngIf="inquiryFormGroup.get('inquiryTypeId').value === null" class="switcher-container">
      <div class="form-row">
        <div class="input-item toggle">
          <label for="switcherControl">
            Select Inquiry Type
          </label>
          <mat-button-toggle-group [ngClass]="{'multiply': dialogData.length > 2}" id="switcherControl"
            #switcherInquiryType formControlName="inquiryTypeId">
            <mat-button-toggle *ngFor="let inquiryType of dialogData" [value]="inquiryType.id">
              {{inquiryType.name}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>

    <!-- FILL INQUIRY FORM -->
    <div class="fill-form-container" [ngClass]="{'saved': generalInquirySaved}"
      *ngIf="inquiryFormGroup.get('inquiryTypeId').value !== null">
      <div class="fill-form-name" *ngIf="!generalInquirySaved">
        {{getTypeById(inquiryFormGroup.get('inquiryTypeId').value)}}
      </div>

      <div *ngIf="!generalInquirySaved" class="activity-container">
        <div class="form-row first">
          <div class="input-item" [ngClass]="{
              'input-error' : inquiryFormGroup.controls['firstName'].touched && inquiryFormGroup.controls['firstName'].invalid,
              'input-success' : !inquiryFormGroup.controls['firstName'].pristine && inquiryFormGroup.controls['firstName'].valid
            }">
            <label for="firstName">First Name</label>
            <input #firstNameInput (keydown.enter)="focusInput('lastNameInput')"
              (keydown.tab)="focusInput('lastNameInput')" name="firstName" id="firstName" formControlName="firstName" />
            <div class="error-label">Please enter correct name</div>
          </div>

          <div class="input-item" [ngClass]="{
              'input-error' : inquiryFormGroup.controls['lastName'].touched && inquiryFormGroup.controls['lastName'].invalid,
              'input-success' : !inquiryFormGroup.controls['lastName'].pristine && inquiryFormGroup.controls['lastName'].valid
            }">
            <label for="lastName">Last Name</label>
            <input #lastNameInput (keydown.enter)="focusInput('phoneInput')" (keydown.tab)="focusInput('phoneInput')"
              name="lastName" id="lastName" formControlName="lastName" />
            <div class="error-label">Please enter correct name</div>
          </div>
        </div>

        <div class="form-row">
          <div class="input-item" [ngClass]="{
              'input-error' : inquiryFormGroup.controls['phone'].touched && inquiryFormGroup.controls['phone'].invalid,
              'input-success' : !inquiryFormGroup.controls['phone'].pristine && inquiryFormGroup.controls['phone'].valid
            }">
            <label for="phone">Phone</label>
            <input #phoneInput name="phone" (keydown.enter)="focusInput('emailInput')"
              (keydown.tab)="focusInput('emailInput')" id="phone" formControlName="phone" mask="(000) 000-0000"
              placeholder="(_ _ _) _ _ _ - _ _ _ _" />
            <div class="error-label">Please enter valid phone number</div>
          </div>

          <div class="input-item" [ngClass]="{
              'input-error' : inquiryFormGroup.controls['email'].touched && inquiryFormGroup.controls['email'].invalid,
              'input-success' : !inquiryFormGroup.controls['email'].pristine && inquiryFormGroup.controls['email'].valid
            }">
            <label for="email">Email</label>
            <input #emailInput name="email" id="email" formControlName="email" />
            <div class="error-label">Please enter valid email address</div>
          </div>
        </div>

        <div class="form-row">
          <div class="input-item select no-shadow" [ngClass]="{
          'input-error':
              inquiryFormGroup.controls['entity'].invalid && inquiryFormGroup.controls['entity'].touched,
          'input-success':
              inquiryFormGroup.controls['entity'].valid && inquiryFormGroup.controls['entity'].touched
        }">
            <label for="entityActivity">Select Entity</label>
            <mat-form-field appearance="fill">
              <mat-select panelClass="dropdown-item" formControlName="entity" name="target" placeholder="Select"
                id="entityActivity">
                <mat-option *ngFor="let inquiryEntityType of inquiryEntityTypes" [value]="inquiryEntityType.type">
                  {{InquiryEntityTypesNames[inquiryEntityType.type]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="error-label">This field is required</div>
          </div>

          <div class="input-item select" [ngClass]="{
          'input-error':
              inquiryFormGroup.controls['generalInquiryQuestionTypeId'].invalid && inquiryFormGroup.controls['generalInquiryQuestionTypeId'].touched,
          'input-success':
              inquiryFormGroup.controls['generalInquiryQuestionTypeId'].valid && inquiryFormGroup.controls['generalInquiryQuestionTypeId'].touched
        }">
            <label for="generalInquiryQuestionTypeId">Type of Question</label>
            <mat-form-field appearance="fill">
              <mat-select panelClass="dropdown-item" formControlName="generalInquiryQuestionTypeId" name="target"
                placeholder="Select" id="generalInquiryQuestionTypeId">
                <mat-option *ngFor="let question of questionTypes" [value]="question.id">
                  {{question.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="error-label">This field is required</div>
          </div>
        </div>

        <div class="form-row direction">
          <div class="input-item toggle half-width" [ngClass]="{
          'input-error':
              inquiryFormGroup.controls['direction'].invalid && inquiryFormGroup.controls['direction'].touched,
          'input-success':
              inquiryFormGroup.controls['direction'].valid && inquiryFormGroup.controls['direction'].touched
        }">
            <label for="directionActivity">Direction</label>
            <mat-button-toggle-group #directionActivity="matButtonToggleGroup" id="directionActivity"
              formControlName="direction">
              <mat-button-toggle [value]="TaskDirectionNames.In">Inbound</mat-button-toggle>
              <mat-button-toggle [value]="TaskDirectionNames.Out">Outbound</mat-button-toggle>
            </mat-button-toggle-group>
            <div class="error-label">This field is required</div>
          </div>
          <div class="input-item select half-width" [ngClass]="{
          'input-error':
              inquiryFormGroup.controls['method'].invalid && inquiryFormGroup.controls['method'].touched,
          'input-success':
              inquiryFormGroup.controls['method'].valid && inquiryFormGroup.controls['method'].touched
        }">
            <label for="contactMethodActivity">Contact Method</label>
            <mat-form-field appearance="fill">
              <mat-select panelClass="dropdown-item" formControlName="method" name="target" placeholder="Select"
                id="contactMethodActivity">
                <mat-option [value]="ContactMethod.phone">Call</mat-option>
                <mat-option [value]="ContactMethod.email">Email</mat-option>
                <mat-option [value]="ContactMethod.text">Text</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="error-label">This field is required</div>
          </div>
        </div>

        <div class="form-row date-row">
          <div class="input-item" [ngClass]="{
          'input-error':
              inquiryFormGroup.controls['contactDate'].invalid && inquiryFormGroup.controls['contactDate'].touched,
          'input-success':
              inquiryFormGroup.controls['contactDate'].valid && inquiryFormGroup.controls['contactDate'].touched
        }">
            <label for="contactDateActivity">Contact Date</label>
            <div class="date-input">
              <input matInput id="contactDateActivity" [matDatepicker]="contactDateActivityDatepicker" [max]="today"
                formControlName="contactDate" placeholder="MM/DD/YY" (focus)="contactDateActivityDatepicker.open()"
                (click)="contactDateActivityDatepicker.open()" />
              <mat-datepicker-toggle matSuffix [for]="contactDateActivityDatepicker" tabindex="-1">
              </mat-datepicker-toggle>
              <mat-datepicker #contactDateActivityDatepicker></mat-datepicker>
              <div class="error-label">This field is required</div>
            </div>
          </div>
          <div class="input-item" [ngClass]="{
          'input-error':
              inquiryFormGroup.controls['contactTime'].invalid && inquiryFormGroup.controls['contactTime'].touched,
          'input-success':
              inquiryFormGroup.controls['contactTime'].valid && inquiryFormGroup.controls['contactTime'].touched
        }">
            <label for="contactTimeActivity">Contact Time</label>
            <div class="date-input">
              <input [ngxTimepicker]="contactTime" id="contactTimeActivity" formControlName="contactTime" />
              <ngx-material-timepicker (closed)="focusInput('notesInput')" #contactTime></ngx-material-timepicker>
              <img class="icon-timer" src="assets/icons/icon-access-time.svg" alt="timer" />
            </div>
            <div class="error-label">This field is required</div>
          </div>
        </div>

        <div class="form-row">
          <div class="input-item notes" [ngClass]="{
          'input-error':
              inquiryFormGroup.controls['notes'].invalid && inquiryFormGroup.controls['notes'].touched,
          'input-success':
              inquiryFormGroup.controls['notes'].valid && inquiryFormGroup.controls['notes'].touched
        }">
            <label for="notes">Contact Notes</label>
            <textarea #notesInput rows="4" matInput id="notes" formControlName="notes"></textarea>
            <div class="error-label">This field is required</div>
          </div>
        </div>
      </div>

      <div *ngIf="generalInquirySaved" class="success-container">
        <div class="success-icon-container">
          <img src="assets/icons/icon-add-patient-check.svg" alt="user-success-icon" />
        </div>

        <div class="success-label">
          General Inquiry Saved!
        </div>

        <button mat-button class="save-button" (click)="dialog.close(true)">
          OK
        </button>
      </div>
    </div>
  </form>

  <div class="dialog-controls" mat-dialog-actions
    *ngIf="inquiryFormGroup.get('inquiryTypeId').value !== null && !generalInquirySaved">
    <button *ngIf="isOnlyOneInquiryType" class="prev cancel" mat-button mat-dialog-close>
      Cancel
    </button>
    <button *ngIf="!isOnlyOneInquiryType" mat-button class="prev" (click)="backToInquiryType()">
      <img class="back-blue-icon" src="assets/icons/icon-arrow-long-right-blue.svg" alt="back">
      <img class="back-white-icon" src="assets/icons/icon-arrow-long-right-white.svg" alt="back">
      Back: <span class="type">Select Inquiry Type</span>
    </button>
    <button mat-button class="save" [disabled]="inquiryFormGroup.invalid" (click)="createInquiry()">
      Submit
    </button>
  </div>
</div>

<div
  *ngIf="inquiryFormGroup.get('inquiryTypeId').value !== null && !generalInquirySaved && (callScript.length || inquiryLinks.length)"
  class="helper-container">
  <div *ngIf="inquiryLinks.length" class="faq-container" [ngClass]="{'has-script': callScript.length}">
    <div class="faq-header">This should help you:</div>

    <button class="faq-btn" (click)="openFAQ()">
      Frequently Asked Questions
      <img class="open-ref-icon" src="assets/icons/icon_open_ref_outside.svg" alt="open_next_tab" />
      <img class="hovered open-ref-icon" src="../../../../../../assets/icons/icon_open_ref_outside_white.svg"
        alt="open_next_tab" />
    </button>
  </div>

  <div *ngIf="callScript.length" class="call-script-container">
    <div class="call-script-header">Call Script:</div>
    <div class="call-script-description">
      This script will help you process the request
    </div>

    <div *ngFor="let item of callScript; let first = first" class="call-script-item" [ngClass]="{'first': first}">
      <img src="assets/icons/icon-material-check-blue.svg" alt="checked-icon" />
      {{item}}
    </div>
  </div>
</div>