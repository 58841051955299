import { Component, Input, OnInit } from '@angular/core';
import { InsuranceTabsEnum } from '../../../../enums/insurance-tabs.enum';
import { IInsurance } from '@shared/interfaces/insurance.interface';
import { CaseService } from '../../../../services/case.service';
import { take } from 'rxjs/operators';
import { isIncludedInTimeFrame } from '@shared/helpers/utils';
import { CoverageType } from '@shared/enums/insurance/coverage-type.enum';

@Component({
  selector: 'app-insurances',
  templateUrl: './insurances.component.html',
  styleUrls: ['./insurances.component.scss'],
})
export class InsurancesComponent implements OnInit {
  @Input() case: any;

  insurances: IInsurance[] = [];
  medicalInsurancesActive: IInsurance[] = [];
  medicalInsurancesInactive: IInsurance[] = [];
  pharmacyInsurancesActive: IInsurance[] = [];
  pharmacyInsurancesInactive: IInsurance[] = [];

  currentTab: InsuranceTabsEnum = InsuranceTabsEnum.Active;

  InsuranceTabsEnum = InsuranceTabsEnum;

  constructor(
    private caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    this.caseService.getCaseInsurances()
      .pipe(take(1))
      .subscribe((insurances: IInsurance[]) => {
        this.insurances = insurances;
        this.medicalInsurancesActive = [];
        this.medicalInsurancesInactive = [];
        this.pharmacyInsurancesActive = [];
        this.pharmacyInsurancesInactive = [];

        if (insurances?.length) {
          insurances.forEach((el: IInsurance) => {
            if (el.coverageType === CoverageType.medical) {
              isIncludedInTimeFrame(el.effectiveFrom, el.effectiveTo) ?
                this.medicalInsurancesActive.push(el) :
                this.medicalInsurancesInactive.push(el);
            } else {
              isIncludedInTimeFrame(el.effectiveFrom, el.effectiveTo) ?
                this.pharmacyInsurancesActive.push(el) :
                this.pharmacyInsurancesInactive.push(el);
            }
          });
        }
      });
  }

  setTab(tab: InsuranceTabsEnum): void {
    this.currentTab = tab;
  }
}
