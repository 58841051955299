import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CaseManagementService } from '../../../../shared/services/case-management.service';
import { IUserQueue } from '../../interfaces/user-queue.interface';
import * as DocumentAction from '../../../../store/document/document.actions';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-intake-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class IntakeDashboardComponent implements OnInit {
  queueList: IUserQueue[];
  intakeQueueList: IUserQueue[];

  constructor(
    private store: Store<any>,
    private caseManagementService: CaseManagementService,
  ) {
    this.store.dispatch(new DocumentAction.SetDocumentsCount(null));
  }

  ngOnInit(): void {
    this.caseManagementService.getIntakeQueues()
      .subscribe((response) => {
        this.intakeQueueList = response;
      }, error => throwError(error));

    this.caseManagementService.getCaseManagementQueues()
      .subscribe((response) => {
        this.queueList = response;
      }, error => throwError(error));
  }
}
