import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';
import { OktaService } from 'src/app/shared/services/okta.service';
import { LoginService } from '../../services/login.service';
import { getAdvancedEmailValidator } from '@shared/helpers/utils';
import { AppConfig } from '@core/configuration/application-config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  AppConfig = AppConfig;

  loginForm: FormGroup;

  errorMessage: string;
  passwordType = 'password';

  @ViewChild('email') email;
  @ViewChild('password') password;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  constructor(
    private router: Router,
    private loginService: LoginService,
    private oktaService: OktaService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.focusInput('email');
  }

  focusInput(inputName: string): void {
    setTimeout(() => this[inputName].nativeElement.focus());
  }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }

    const model = this.getModelFromForm();

    this.loginService.oktaLogin(model).then(transaction => {

      // more statuses https://github.com/okta/okta-auth-js/blob/master/docs/authn.md#transactionstatus
      switch (transaction.status) {
        case 'SUCCESS':
          this.loginService.proceedTransaction(transaction);
          break;
        case 'MFA_REQUIRED':
          this.oktaService.verifyEmailFactor(
            transaction.factors.find(x => x.factorType === 'email').id, {stateToken: transaction.data.stateToken}
          )
            .subscribe((response) => {
                this.router.navigateByUrl('login/email-verification', {
                  state: {
                    stateToken: transaction.data.stateToken,
                    factorId: transaction.factors.find(x => x.factorType === 'email').id,
                  }
                });
              },
              (error) => throwError(error)
            );
          break;

        case 'LOCKED_OUT':
          this.errorMessage = 'The user account is locked; self-service unlock or admin unlock is required.';
          break;

        default:
          this.errorMessage = 'Something went wrong.';
      }
    })
      .catch((exc) => {
        this.errorMessage = 'Something went wrong.';

        // #E0000004 - Authentication failed (The username or password is incorrect)
        // more: https://developer.okta.com/docs/reference/error-codes/#E0000004
        if (exc.errorCode === 'E0000004') {
          this.errorMessage = 'Authentication failed (The username or password is incorrect)';
        }
      });
  }

  private initForm(): void {
    this.loginForm = new FormGroup({
      email: new FormControl(undefined, [
        Validators.required,
        Validators.email,
        getAdvancedEmailValidator,
      ]),
      password: new FormControl(undefined, [Validators.required]),
      rememberMe: new FormControl(true),
    });
  }

  private getModelFromForm(): any {
    return {
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value,
      rememberMe: this.loginForm.controls.rememberMe.value,
    };
  }
}
