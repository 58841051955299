<form [formGroup]="clinicalForm"
      class="clinical-form document-sidebar-form"
      novalidate (ngSubmit)="submitHandler()"
      (keydown.enter)="$event.preventDefault()"
>
  <div class="aside-wrapper">
    <h2>{{state.config.tabDisplayName}}</h2>

    <div *ngIf="state.config.fieldsConfig.primaryDiagnosticCode.isVisible" class="input-item" [ngClass]="{
          'input-error' : isInvalid(clinicalForm.controls['primaryDiagnosticCode']),
          'input-success' : isValid(clinicalForm.controls['primaryDiagnosticCode'])
        }">
      <label>{{ state.config.fieldsConfig.primaryDiagnosticCode.displayName }}</label>
      <div class="search-form" (clickOutside)="showResult = false">
        <div class="input-item search">
          <img src="assets/icons/icon-search.svg" alt="search"/>
          <input matInput type="text" formControlName="primaryDiagnosticCode" placeholder="Search by ICD-10" autofocus
                 #primaryDiagnosticCode (input)="searchCodeHandler()"
                 [readonly]="state.config.fieldsConfig.primaryDiagnosticCode.isReadOnly"
                 (focus)="diagnosticCodes.length ? (showResult = true) : (showResult = false)"
                 (keydown)="searchNavigate($event)"/>
          <button mat-button *ngIf="clinicalForm.value.primaryDiagnosticCode && !state.config.fieldsConfig.primaryDiagnosticCode.isReadOnly" matSuffix mat-icon-button
                  aria-label="Clear" (click)="selectCode(null)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <ul class="physician-results" *ngIf="showResult">
          <ng-scrollbar #scrollbar *ngIf="!isLoading && diagnosticCodes.length" class="custom-scrollbar">
            <div class="code-list" #clinicalSearchList>
              <li *ngFor="let code of diagnosticCodes; index as i" (click)="selectCode(code)"
                  [class.focused]="focusedOnSearch === i">
                <b>{{ code.code }}</b> {{ code.description }}
              </li>
            </div>
          </ng-scrollbar>
          <li *ngIf="!diagnosticCodes.length && !isLoading" class="not-found">
            <h5>{{ clinicalForm.value.primaryDiagnosticCode }}</h5>
            <p>Diagnosis Code Not Found</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="selected-physician" *ngIf="selectedDiagnosis && selectedDiagnosis.id">
      <div class="physician-title">
        <img src="assets/icons/icon-check.svg" alt="check"/>
        <div class="code-selected">
          <b>{{ this.selectedDiagnosis.code }}</b> {{ this.selectedDiagnosis.description }}
        </div>
        <button (click)="selectCode(null)" *ngIf="!state.config.fieldsConfig.primaryDiagnosticCode.isReadOnly">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <button *ngIf="state.config.nextTab" type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()" [disabled]="isFormLoading || clinicalForm.invalid">
    <b>Next:</b> {{ state.config.nextTab.config.tabDisplayName }}
    <mat-icon>east</mat-icon>
  </button>

  <button *ngIf="!state.config.nextTab" type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()" [disabled]="isFormLoading || clinicalForm.invalid">
    <b>Complete</b>
  </button>
</form>
