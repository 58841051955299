<form [formGroup]="taskForm" class="task-body-wrapper" [ngClass]="{'history': isHistory}">
  <div class="groups-container">
    <mat-accordion>
      <ng-container *ngFor="let group of taskConfig.groups; let i = index">
        <ng-template [ngIf]="group.groupName" [ngIfElse]="simplified">
          <mat-expansion-panel id="group_{{i}}"
                               formGroupName="group_{{i}}"
                               [expanded]="currentGroupIndex === i"
                               (afterExpand)="openPanel(i)"
                               (afterCollapse)="closePanel(i)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <app-task-progress class="task-progress" [progress]="getProgress(i)"></app-task-progress>
                {{group.groupName}}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="sections-wrapper">
              <div *ngFor="let section of group.sections; let j = index"
                    appDetectScrollTop
                    (scrollTopDetected)="currentSectionIndex = j"
                    class="section"
                    [offsetTop]="210"
                    formGroupName="section_{{j}}">
                <app-task-section *ngIf="!section.isHidden" [section]="section">
                  <div class="block-container" *ngFor="let block of section.blocks; let k = index">
                    <ng-container *ngIf="!block.isHidden">
                      <div class="block-name" *ngIf="block.name">
                        <mat-icon *ngIf="block.iconName">{{block.iconName}}</mat-icon> {{block.name}}
                      </div>

                      <app-task-elements-parser *ngIf="block.fields?.length"
                                                [fields]="block.fields"
                                                [case]="case"
                                                (downloadDocument)="downloadDocument.emit($event)"
                                                (previewDocument)="previewDocument.emit($event)"
                                                [parserForm]="taskForm | nestedFormFields: i: j: k"
                                                [isHistory]="isHistory"
                      ></app-task-elements-parser>
                    </ng-container>
                  </div>
                </app-task-section>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-template>

        <ng-template #simplified>
          <div class="sections-wrapper">
            <div *ngFor="let section of group.sections; let j = index" class="section">
              <app-task-section *ngIf="!section.isHidden" [section]="section">
                <div class="block-container" *ngFor="let block of section.blocks; let k = index">
                  <ng-container *ngIf="!block.isHidden">
                    <div class="block-name" *ngIf="block.name">
                      <mat-icon *ngIf="block.iconName">{{block.iconName}}</mat-icon> {{block.name}}
                    </div>

                    <app-task-elements-parser *ngIf="block.fields?.length"
                                              [fields]="block.fields"
                                              [case]="case"
                                              (downloadDocument)="downloadDocument.emit($event)"
                                              (previewDocument)="previewDocument.emit($event)"
                                              [parserForm]="taskForm | nestedFormFields: i: j: k"
                                              [isHistory]="isHistory"
                    ></app-task-elements-parser>
                  </ng-container>
                </div>
              </app-task-section>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </mat-accordion>
  </div>

  <div *ngIf="areNamedGroupsIncluded" class="groups-indicators-wrapper">
    <div class="groups-indicators" [class.phase]="!selectedTask">
      <mat-accordion>
        <h5>{{taskConfig.name}}</h5>

        <mat-expansion-panel *ngFor="let group of taskConfig.groups; let i = index"
                             [expanded]="currentGroupIndex === i">
          <mat-expansion-panel-header *ngIf="group.groupName">
            <mat-panel-title>
              <app-task-progress class="task-progress" customClass="small" [progress]="getProgress(i)"></app-task-progress>
              {{group.groupName}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ul>
            <li *ngFor="let section of group.sections; let j = index"
                (click)="scrollToAnchor(i, j)"
                [ngClass]="{
                  'active': currentSectionIndex === j && currentGroupIndex === i,
                  'done': isDone(i, j)
                }"
            >
              <span>{{section.id}}</span>
              <span *ngIf="currentSectionIndex === j"> (Current)</span>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div class="task-script" *ngIf="!isHistory && taskConfig.taskScripts && taskConfig.taskScripts[taskConfig.currentScript]">
    <mat-expansion-panel #script>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{taskConfig.taskScripts[taskConfig.currentScript].header}}
        </mat-panel-title>

        <mat-panel-description>
          <mat-icon>{{ script.expanded ? 'close' : 'open_in_full' }}</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-scrollbar class="custom-scrollbar">
        <p [innerHtml]="taskConfig.taskScripts[taskConfig.currentScript].mergedScript"></p>
      </ng-scrollbar>
    </mat-expansion-panel>
  </div>
</form>
