import { Component, Input, OnInit } from '@angular/core';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { enumToArray } from 'src/app/shared/helpers/utils';
import { TargetNames } from '../../../../../../../../shared/enums/target-names.enum';
import { TaskContactMethodNames } from '../../../../../../../../shared/enums/task-contact-method-names.enum';
import { TaskDirectionNames } from '../../../../../../../../shared/enums/task-direction-names.enum';

@Component({
  selector: 'app-section-notes',
  templateUrl: './section-notes.component.html',
  styleUrls: ['./section-notes.component.scss', '../../aside-sections.scss'],
})
export class SectionNotesComponent implements OnInit {
  readonly ContactMethod = ContactMethod;

  @Input()
  get initialData(): any {
    return this._initialData;
  }

  set initialData(data: any) {
    if ((!this._initialData && data)
      || (this._initialData && data && this._initialData.count !== data.count)) {
      this._initialData = data;
      this.data = data;
      this.applyFilter();
    }
  }

  data;
  _initialData;


  contactMethodNames = TaskContactMethodNames;
  dataTypeNames = TargetNames;
  dataTypes = enumToArray(TargetNames);
  directionNames = TaskDirectionNames;
  filter = {
    show: false,
    count: 0,
    previousValue: {
      Patient: false,
      Payer: false,
      Physician: false,
      Pharmacy: false
    },
    value: {
      Patient: false,
      Payer: false,
      Physician: false,
      Pharmacy: false
    },
  };

  constructor() {
  }

  ngOnInit(): void {
    this.data = this.initialData;
  }

  showFilter(isShown): void {
    this.filter.show = isShown;
  }

  closeFilter(): void {
    this.populateFilter(this.filter.value, this.filter.previousValue);
    this.showFilter(false);
  }

  applyFilter(): void {
    this.populateFilter(this.filter.previousValue, this.filter.value);
    this.filterValues();
    this.showFilter(false);
  }

  clearFilter(): void {
    this.filter.value = {
      Patient: false,
      Payer: false,
      Physician: false,
      Pharmacy: false
    };

    this.populateFilter(this.filter.previousValue, this.filter.value);
    this.filterValues();
    this.showFilter(false);
  }

  getFilters(): Array<any> {
    const result = [];
    for (const filter in this.filter.value) {
      if (this.filter.value[filter]) {
        result.push(filter);
      }
    }

    return result;
  }

  filterValues(): void {
    const filters = this.getFilters();
    this.filter.count = filters.length;
    if (!filters?.length) {
      this.data = this.initialData;
    } else {
      this.data = {
        count: 0,
        items: []
      };
      this.initialData.items.forEach(element => {
        const notes = element.notes.filter(note => filters.includes(TargetNames[note.target]));
        if (notes.length) {
          this.data.items.push({
            phase: element.phase,
            notes,
          });
          this.data.count += notes.length;
        }
      });
    }
  }

  populateFilter(initFilterValue, newFilterValue): void {
    initFilterValue.Patient = newFilterValue.Patient;
    initFilterValue.Payer = newFilterValue.Payer;
    initFilterValue.Physician = newFilterValue.Physician;
    initFilterValue.Pharmacy = newFilterValue.Pharmacy;
  }
}
