import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateActivityDialogComponent } from '../../../../../modals/create-activity-dialog/create-activity-dialog.component';

@Component({
  selector: 'app-task-create-activity',
  templateUrl: './task-create-activity.component.html',
  styleUrls: ['./task-create-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCreateActivityComponent {
  @Input() case: any;

  constructor(private dialog: MatDialog) {
  }

  createActivity(): void {
    this.dialog.open(CreateActivityDialogComponent, {
      data: this.case,
      panelClass: 'selectTypeFormDialog',
    });
  }
}
