import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSearchComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DropdownSearchComponent),
      multi: true,
    },
  ],
})
export class DropdownSearchComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder = 'Select';
  @Input() searchPlaceholder = 'Search';
  @Input() hideSearchField: boolean;
  @Input() disable: boolean;

  @Input() set options(value: any[]) {
    this.optionsInner = value;
    this.initialOptions = [...value];
  }

  get options(): any[] {
    return this.optionsInner;
  }

  errors: ValidationErrors | null;
  control: FormControl = new FormControl('');
  searchControl: FormControl = new FormControl('');

  isSearchFocused: boolean;

  value: any;
  optionsInner: any[] = [];
  initialOptions: any[] = [];

  initialPlaceholder: string;

  onChangeCallback: (v: any) => void;
  onTouchCallback: (v: any) => void;

  onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
    this.initialPlaceholder = this.placeholder.slice();

    this.searchControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value: string) => {
        this.optionsInner = value ?
          this.initialOptions.filter(el => el.name?.toLowerCase().includes(value.toLowerCase())) :
          [...this.initialOptions];
        this.placeholder = value && this.optionsInner.length === 0 ?
          'No options for "' + value + '"' :
          this.initialPlaceholder.slice();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearSearch(): void {
    this.searchControl.setValue('');
  }

  focusSearchInput(): void {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: (v: any) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: (v: any) => void): void {
    this.onTouchCallback = fn;
  }

  setDisabledState(disable: boolean): void {
    this.disable = disable;
  }

  validate(c: FormControl): any {
    this.control = c;
    this.control.statusChanges.subscribe(_ => this.updateState());
  }

  updateValue(): void {
    if (this.onChangeCallback) {
      this.onChangeCallback(this.value);
    }
    this.updateState();
  }

  updateState(): void {
    if (!this.control) {
      return;
    }
    this.errors = this.control.errors;
  }

}
