/// Please use interpolation.configurator.ts file for configure behavior of interpolation
/// Make changes to this file only in exceptional cases

import { CustomSelector } from './custom-selector';
import { CustomStyler } from './custom-styler';
import { DefaultSelector } from './default-selector';
import { InterpolationConfig } from './interpolation.configurator';
import { Matcher } from './matcher';
import { BaseSelector } from './selector-base';

// High level abstraction on interpolation
export class InterpolatedString {
  private readonly custom: BaseSelector = new CustomSelector();
  private readonly default: BaseSelector = new DefaultSelector();

  private selector: BaseSelector;

  constructor(
  private initialString: string = undefined, 
  private model: any = undefined, 
  private matcher: Matcher= undefined){ }

  public toString(): string {
    return (this.initialString + " ")
    .replace(InterpolationConfig.SelectorRegex, (match) => { // Interpolate from model
      const value = match.replace(InterpolationConfig.BracketsSelectorRegex, () => '');

      this.selector ??= value[0] === InterpolationConfig.CustomSelectorPrefix
        ? this.custom.setMatcher(this.matcher)
        : this.default.setModel(this.model);

      return this.selector.select(value);
    })
    .replace(InterpolationConfig.HighlightRegex, (match) => { // Highlight text
      const splitted = match
        .replace(InterpolationConfig.HighlightBracketsSelectorRegex, () => '')
        .split(InterpolationConfig.GeneralSplitter);

      const value = splitted[0];
      const styles = splitted[1].split(InterpolationConfig.StyleSplitter);

      const styler = new CustomStyler(styles);

      return styler.canApply ? styler.applyFor(value) : value;
    });
  }

  public setMatcher(matcher: Matcher): InterpolatedString {
    this.matcher = matcher; return this;
  }

  public setInitialString(initialString: string): InterpolatedString {
    this.initialString = initialString; return this;
  }

  public setModel(model: any): InterpolatedString {
    this.model = model; return this;
  }

  public setSelector(selector: BaseSelector): InterpolatedString {
    this.selector = selector; return this;
  }
}
