import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-editable-aside-field',
  templateUrl: './editable-aside-field.component.html',
  styleUrls: ['./editable-aside-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableAsideFieldComponent {
  @Input() editMode = false;
  @Input() fieldId: string;
  @Input() disableSave = false;
  @Input() isEdit = false;

  @Output() saveData: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() editOutside: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickInside: EventEmitter<void> = new EventEmitter<void>();
  @Output() editClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  edit(): void {
    this.clickInside.emit();

    if (!this.isEdit) {
      this.editMode ? this.isEdit = true : this.editOutside.emit();
    }
  }

  close(save, ev): void {
    ev.stopPropagation();
    this.isEdit = false;
    this.saveData.emit(save);
  }

  closeEditMode(ev): void {
    if (this.isEdit &&
      ev.target && ev.composedPath().some(el => el.className && el.className.includes('editable-aside-field-container'))) {
      const exists = ev.composedPath().some(el => el.id === this.fieldId);

      if (!exists) {
        this.isEdit = false;
        this.editClosed.emit();
      }
    }
  }
}
