<ul *ngIf="!selectedOption && !isHistory">
  <li *ngFor="let option of options" (click)="selectOption(option)">
    <app-contact-option [option]="option"
                        [selected]="false"
                        [required]="required"
    ></app-contact-option>
  </li>
</ul>

<ul *ngIf="selectedOption" class="selected">
  <li>
    <app-contact-option (deselectOption)="selectOption(null)"
                        [option]="selectedOption"
                        [selected]="true"
    ></app-contact-option>
  </li>
</ul>
