import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor() {
  }

  public isFeatureEnabled(feature: string): boolean {
    return !feature || environment.features[feature];
  }
}
