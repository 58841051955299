<div class="top-block">
  <h2>Add Tasks</h2>
  <p>
    Add which tasks users will complete from this queue. Tasks in this queue cannot exist in other queues. You can
    edit this later.
  </p>
</div>

<div class="tasks-wrapper">
  <div class="search-form">
    <div class="input-item search">
      <img src="assets/icons/icon-search.svg" alt="search"/>
      <input matInput type="text" [(ngModel)]="searchValue" placeholder="Search for Task" #search
             (keydown.enter)="getTasks()" (keydown.tab)="focusTable($event)" tabindex="1"/>
      <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
              (click)="searchValue = ''">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <button class="primary-btn" (click)="getTasks()" [disabled]="isLoading">Search</button>
  </div>
  <table [@.disabled]="true" [hidden]="isLoading" mat-table [dataSource]="dataSource" matSort matSortActive="phase"
         matSortDirection="asc">
    <ng-container matColumnDef="phase">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Phase</th>
      <td mat-cell *matCellDef="let task">{{ task.phase }}</td>
    </ng-container>
    <ng-container matColumnDef="task">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Task Name</th>
      <td mat-cell *matCellDef="let task">{{ task.task }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let task" [innerHtml]="task.description"></td>
    </ng-container>
    <ng-container matColumnDef="isAdded">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="dataSource?.data?.length" class="primary-btn select-all" [class.deselect]="isAllSelected" (click)="selectAll()">
          <ng-container *ngIf="!isAllSelected">
            <mat-icon>add_circle</mat-icon>
            Select All
          </ng-container>
          <ng-container *ngIf="isAllSelected">
            <mat-icon>remove_circle</mat-icon>
            Unselect All
          </ng-container>
        </button>
      </th>
      <td mat-cell *matCellDef="let task">
        <button *ngIf="!selectedTasks.includes(task.id); else check" tabindex="1">
          <img src="../../../../../../../assets/icons/icon-add.svg" alt="add"/>
        </button>
        <ng-template #check>
          <button tabindex="1">
            <img src="../../../../../../../assets/icons/icon-check.svg" alt="check"/></button>
        </ng-template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let task; columns: columnsToDisplay; index as i" [class.focused]="i === selectedItem"
        (click)="checkTask(task.id)"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching</td>
    </tr>
  </table>

  <mat-paginator style="display: none;" [pageSize]="settings.itemPerPage"></mat-paginator>

  <app-pagination mat-paginator
                  *ngIf="data.length"
                  [style]="settings.paginationPageCount > 1 ? 'display: block' : 'display: none'"
                  [settings]="settings"
                  (setActivePageHandler)="setActivePage($event)"
  ></app-pagination>

  <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
