<div class="task-wrapper">
  <div class="task-title">
    <h2>Enrollment</h2>
  </div>

  <div class="task-description-wrapper">
    <div class="task-description">
      <div class="row">
        <div class="task-description-item">
          <h5>Case Type</h5>
          <p>{{case.typeName | empty}}</p>
        </div>
        <div class="task-description-item">
          <h5>Case status</h5>
          <p>{{case.status.name | empty}}</p>
        </div>
        <div class="task-description-item">
          <h5>Case Substatus</h5>
          <p>{{case.subStatus.name | empty}}</p>
        </div>
        <div class="task-description-item">
          <h5>Substatus Reason</h5>
          <p>{{ case.subStatusReason?.name | empty}}</p>
        </div>
      </div>
      <div class="row">
        <div class="task-description-item">
          <h5>Case Manager</h5>
          <p>
            <span class="user-img" *ngIf="case.caseManager">
              <img
                [src]="case.caseManager.userImageS3Url ? case.caseManager.userImageS3Url : 'http://reserverecruitment.co.za/images/usericon.png'"
                alt="user"/>
            </span>
            {{(case.caseManager ? case.caseManager.firstName + ' ' + case.caseManager.lastName : '') | empty }}
          </p>
        </div>
        <div class="task-description-item">
          <h5>Sales Rep</h5>
          <p>{{(case.salesRep ? case.salesRep.firstName + ' ' + case.salesRep.lastName : '') | empty }}</p>
        </div>
        <div class="task-description-item">
          <h5>Territory</h5>
          <p>{{case.territory | empty}}</p>
        </div>
        <div class="task-description-item">
          <h5>Enrollment Date</h5>
          <p>{{case.enrollmentStartDate | date: "M/d/yyyy" | empty}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="benefit-task-wrapper task-block">
  <mat-accordion>

    <!--CONSENTS AND OPTS-->

    <mat-expansion-panel [expanded]="panel === 'consent'" (afterExpand)="handleAfterExpand('consent')">
      <mat-expansion-panel-header (click)="clearPanel('consent')">
        <mat-panel-title>
          <div class="header">
            Consent
          </div>
          <div style="display: inherit" *ngFor="let consent of consents">
            <div class="status-label" [class.ready]="consent.dataSource?.data[0]?.consentIsOnFile">
              {{ ConsentTypeNames[consent.type] + " " + (consent.type == ConsentTypes.hipaa ? "Consent" : "Opt-In") }}
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngFor="let consent of consents">
        <app-consent-item
          [consent]="consent"
          [patientId]="case?.patient?.id"
          [consentMethods]="consentMethods"
          (updateCase)="this.updateCase.emit()"
        ></app-consent-item>
      </div>
    </mat-expansion-panel>

    <!--ADDRESSES-->

    <mat-expansion-panel [expanded]="panel === 'address'" (afterExpand)="handleAfterExpand('address')">
      <mat-expansion-panel-header (click)="clearPanel('address')">
        <mat-panel-title>
          <div class="header">
            Patient Addresses
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="collapse-section">

        <div class="collapse-item">
          <h3>Current Addresses</h3>

          <div class="empty-state" *ngIf="!currentAddresses$.getValue()?.length">
            No current addresses
          </div>

          <div *ngFor="let address of (currentAddresses$ | async); let i = index; trackBy: trackByIndex"
               class="address-block active-block"
               [class.edit-mode]="editAddressMode[i]"
          >
            <ng-container *ngIf="!editAddressMode[i]">
              <div class="info">
                <h5>
                  {{address.addressType === AddressType.shipping ? 'Shipping' : 'Mailing'}} Address
                  <div class="controls items">
                    <button class="favorite selected" (click)="setAddressCurrent(address)">
                      <mat-icon *ngIf="address.current">star</mat-icon>
                      <mat-icon *ngIf="!address.current">star_border</mat-icon>
                    </button>
                    <button (click)="editAddressMode[i] = true">
                      <mat-icon>create</mat-icon>
                    </button>
                  </div>
                </h5>
                <p>{{address.address?.streetAddress | empty}}</p>
                <p class="last">{{address.address?.city | empty}}
                  , {{address.address?.zipCode | empty}}</p>
                <span>Added {{getDate(address.createDate) | date: 'MM/dd/yyyy' | empty}}</span>∙<a class="history-link"
                                                                                                   (click)="viewAddressHistory(address)">View
                History
                <img src="../../../../../../../../../assets/icons/icon-arrow-long-right-blue.svg" alt="history"/>
              </a>
              </div>
            </ng-container>

            <ng-container *ngIf="editAddressMode[i]">
              <app-new-address-form [address]="address" (cancelled)="cancelEditAddress(address, i, true)"
                                    (submitted)="saveAddress($event, true, true, i)"></app-new-address-form>
            </ng-container>
          </div>
        </div>
        <div class="collapse-item">
          <h3>Other Addresses</h3>

          <div class="empty-state" *ngIf="!otherAddresses$.getValue()?.length">
            No other addresses
          </div>

          <div *ngFor="let address of (otherAddresses$ | async); let i = index; trackBy: trackByIndex"
               [class.edit-mode]="editAddressMode[i + currentAddresses$.getValue()?.length]" class="address-block">
            <ng-container *ngIf="!editAddressMode[i + currentAddresses$.getValue()?.length]">
              <div class="info">
                <h5>
                  {{address.addressType === AddressType.shipping ? 'Shipping' : 'Mailing'}} Address
                  <div class="controls items">
                    <button class="favorite" (click)="setAddressCurrent(address)">
                      <mat-icon *ngIf="address?.current">star</mat-icon>
                      <mat-icon *ngIf="!address?.current">star_border</mat-icon>
                    </button>
                    <button (click)="editAddressMode[i + currentAddresses$.getValue()?.length] = true">
                      <mat-icon>create</mat-icon>
                    </button>
                  </div>
                </h5>
                <p>{{address?.address?.streetAddress | empty}}</p>
                <p class="last">{{address?.address?.city | empty}}, {{address?.address?.zipCode | empty}}</p>
                <span>Added {{getDate(address?.createDate) | date: 'MM/dd/yyyy' | empty}}
                </span>∙<a class="history-link" (click)="viewAddressHistory(address)">View History
                <img src="../../../../../../../../../assets/icons/icon-arrow-long-right-blue.svg" alt="history"/>
              </a>
              </div>
            </ng-container>

            <ng-container *ngIf="editAddressMode[i + currentAddresses$.getValue()?.length]">
              <app-new-address-form [address]="address"
                                    (cancelled)="cancelEditAddress(address, i, false)"
                                    (submitted)="saveAddress($event, true, true, i + currentAddresses$.getValue()?.length)"
              ></app-new-address-form>
            </ng-container>
          </div>

          <button *ngIf="!(showAddressForm$ | async)"
                  (click)="toggleAddressForm()"
                  class="primary-btn add-btn"
                  type="button"
          >
            <mat-icon>add</mat-icon>
            Add Address
          </button>

          <app-new-address-form *ngIf="showAddressForm$ | async"
                                (submitted)="saveAddress($event, true, false)"
                                (cancelled)="toggleAddressForm()"
          ></app-new-address-form>
        </div>
      </div>
    </mat-expansion-panel>

    <!--CAREGIVERS-->

    <mat-expansion-panel [expanded]="panel === 'caregivers'" (afterExpand)="handleAfterExpand('caregivers')">
      <mat-expansion-panel-header (click)="clearPanel('caregivers')">
        <mat-panel-title>
          <div class="header">
            Caregivers
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="collapse-section caregiver-section">
        <div class="caregiver-wrapper">
          <div class="collapse-item">
            <h3>Active Caregivers</h3>

            <div class="empty-state" *ngIf="!(activeCaregivers$ | async)?.length">
              No active caregivers
            </div>
            <div class="caregiver-container">
              <div class="caregiver-block active-block" [class.edit-mode]="editCaregiverMode[i]"
                   *ngFor="let caregiver of (activeCaregivers$ | async); let i = index; trackBy: trackByIndex">
                <ng-container *ngIf="!editCaregiverMode[i]">
                  <div class="title">
                    <h3>{{caregiver.firstName | empty}}<span> {{caregiver.middleName}}</span> {{caregiver.lastName}}
                    </h3>
                    <div class="controls items">
                      <button class="favorite selected" (click)="setCaregiverCurrent(caregiver)">
                        <mat-icon>star</mat-icon>
                      </button>
                      <button (click)="editCaregiverMode[i] = true">
                        <mat-icon>create</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div class="description">
                    <div class="item">
                      <h5>Relation</h5>
                      <p>{{caregiver.relationshipName | empty}}</p>
                    </div>
                    <div class="item">
                      <h5>Phone</h5>
                      <p>{{(caregiver.contactInfos | contactString: ContactMethod.phone) | mask: '(000) 000-0000' |
                        empty}}
                      </p>
                    </div>
                    <div class="item">
                      <h5>Email</h5>
                      <p>{{(caregiver.contactInfos | contactString: ContactMethod.email) | empty}}</p>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="editCaregiverMode[i]">
                  <app-new-caregiver-form [caregiver]="caregiver" (submitted)="saveCaregiver($event, true, true, i)"
                                          (cancelled)="cancelEditCaregiver(caregiver, i, true)"></app-new-caregiver-form>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="caregiver-divider"></div>
          <div class="collapse-item">
            <h3>Inactive Caregivers</h3>

            <div class="empty-state" *ngIf="!(inactiveCaregivers$ | async)?.length">
              No inactive caregivers
            </div>
            <div class="caregiver-container">
              <div class="caregiver-block"
                   [class.edit-mode]="editCaregiverMode[i + activeCaregivers$.getValue().length]"
                   *ngFor="let caregiver of (inactiveCaregivers$ | async); let i = index; trackBy: trackByIndex"
              >
                <ng-container *ngIf="!editCaregiverMode[i + activeCaregivers$.getValue().length]">
                  <div class="title">
                    <h3>{{caregiver.firstName | empty}}<span> {{caregiver.middleName}}</span> {{caregiver.lastName}}
                    </h3>
                    <div class="controls items">
                      <button class="favorite" (click)="setCaregiverCurrent(caregiver)">
                        <mat-icon>star_border</mat-icon>
                      </button>
                      <button (click)="editCaregiverMode[i + activeCaregivers$.getValue().length] = true">
                        <mat-icon>create</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div class="description">
                    <div class="item">
                      <h5>Relation</h5>
                      <p>{{caregiver.relationshipName | empty}}</p>
                    </div>
                    <div class="item">
                      <h5>Phone</h5>
                      <p>{{(caregiver.contactInfos | contactString: ContactMethod.phone) | mask: '(000) 000-0000' |
                        empty}}
                      </p>
                    </div>
                    <div class="item">
                      <h5>Email</h5>
                      <p>{{(caregiver.contactInfos | contactString: ContactMethod.email) | empty}}</p>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="editCaregiverMode[i + (activeCaregivers$ | async)?.length]">
                  <app-new-caregiver-form [caregiver]="caregiver"
                                          (submitted)="saveCaregiver($event, true, true, i + activeCaregivers$.getValue().length)"
                                          (cancelled)="cancelEditCaregiver(caregiver, i, false)"
                  ></app-new-caregiver-form>
                </ng-container>
              </div>
            </div>
            <button *ngIf="!(showCaregiverForm$ | async)" (click)="toggleCaregiverForm()" class="primary-btn add-btn"
                    type="button">
              <mat-icon>add</mat-icon>
              Add Caregiver
            </button>
            <app-new-caregiver-form *ngIf="showCaregiverForm$ | async"
                                    (submitted)="saveCaregiver($event, true, false)"
                                    (cancelled)="toggleCaregiverForm()"
            ></app-new-caregiver-form>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
