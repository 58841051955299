import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractRestService } from '@core/services/abstract-rest.service';
import { IResponse } from '@shared/interfaces/response.interface';

@Injectable({ providedIn: 'root', })
export class SupervisorQueueService extends AbstractRestService {

  constructor(protected http: HttpClient) { super(http); }

  public getQueueItems(queueId: number){
    this.url = `${this.apiUrl}/SupervisorQueues/get-queue-items/${queueId}`;

    return this.get<IResponse>();
  }

  public getQueues() {
    this.url = `${this.apiUrl}/Queue/pick-all-queue-configurations`;

    return this.get<any>();
  }
}
