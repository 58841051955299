export enum CaseStatus {
  active = 'Active',
  pending = 'Pending',
  inProcess = 'In Process',
  closed = 'Closed',
  completed = 'Completed',
  'Active' = 'active',
  'Pending' = 'pending',
  'In Process' = 'inProcess',
  'Closed' = 'closed',
  'Completed' = 'completed',
}
