export class NameResolver {
  public static getNameString({ firstName, middleName, lastName }, separator = ' ') {
    const name = [];

    if (firstName) name.push(firstName);
    if (middleName) name.push(middleName);
    if (lastName) name.push(lastName);

    return name?.length ? name.join(separator) : undefined;
  }

  public static parse(name: string, splitter = ' ') {
    if (!name?.length) return undefined;

    const nameArr = name.split(splitter);

    if(!nameArr?.length) return NameResolver.nameObject({});
    if(nameArr.length === 1) return NameResolver.nameObject({firstName: nameArr[0]});
    if(nameArr.length === 2) return NameResolver.nameObject({firstName: nameArr[0], lastName: nameArr[1]});
    else return NameResolver.nameObject({firstName: nameArr[0], middleName: nameArr[1], lastName: nameArr[2]});
  }

  private static nameObject({firstName = '', lastName = '', middleName = ''}) {
    return {
      firstName,
      lastName,
      middleName
    }
  }
}

export const nameof = <T>(name: keyof T) => name;
