import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AbstractRestService } from 'src/app/core/services/abstract-rest.service';
import { IResponse } from 'src/app/shared/interfaces/response.interface';
import { EmailModel } from '../../management/account-management/models/email.model';
import { UserModel } from '../../management/account-management/models/user.model';
import { ResetPasswordModel } from '../models/reset-password.model';
import { UpdateAccountInfoModel } from '../models/update-account-info.model';

@Injectable({ providedIn: 'root' })
export class UserProfileService extends AbstractRestService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public resetPassword(userId: string, resetPasswordModel: ResetPasswordModel){
    this.url = this.apiUrl + '/Account/ResetPassword/' + userId;

    return this.putItem<ResetPasswordModel, IResponse>(resetPasswordModel).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public updateAccount(userId: string, updateAccountInfoModel: UpdateAccountInfoModel){
    this.url = this.apiUrl + '/Account/Update/' + userId;

    return this.putItem<UpdateAccountInfoModel, IResponse>(updateAccountInfoModel).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public deletePhoto(userId: string){
    this.url = this.apiUrl + '/Users/DeleteImage/' + userId;

    return this.delete().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public updateProfilePhoto(userId: string, file: File){
    this.url = this.apiUrl + '/Users/UpdateImage/' + userId;

    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.patchItem<FormData, any>(formData).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public uploadProfilePhoto({userId, stateToken, file}) {
    this.url = this.apiUrl + '/Users/UploadProfilePhoto/' + userId;

    const formData = new FormData();
    formData.append('file', file);

    return this.http.patch(this.url, formData, { headers: { "stateToken": stateToken } }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getUserPhoto(userId: string){
    this.url = this.apiUrl + '/Users/GetImage/' + userId;

    return this.getPicture().pipe(
      map((response) => {
        return this.blobToBase64(response);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public checkIfEmailExist(emailModel: EmailModel){
    this.url = this.apiUrl + '/UserAdministration/CheckIfEmailExist/';

    return this.putItem<EmailModel, IResponse>(emailModel).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public sendInvite(userModel){
    this.url = this.apiUrl + '/UserAdministration/SendInvite/';

    return this.postItem<UserModel, IResponse>(userModel).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getUserStatusByEmail(email: string) {
    const encodedEmail = encodeURIComponent(email);
    this.url = this.apiUrl + '/Users/GetUserStatusByEmail?email=' + encodedEmail;

    return this.get<IResponse>().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  private blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
