import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContactOption } from '../interfaces/contact-option.interface';

@Component({
  selector: 'app-contact-option',
  templateUrl: './contact-option.component.html',
  styleUrls: ['./contact-option.component.scss'],
})
export class ContactOptionComponent {
  @Input() option: IContactOption;
  @Input() selected: boolean;
  @Input() required: boolean;

  @Output() deselectOption: EventEmitter<void> = new EventEmitter();
}
