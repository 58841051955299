import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-manager-dashboard-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class ManagerDashboardFilterComponent {
  @Input() durationValue;
  @Output() showCases = new EventEmitter();

  action = '';
  group = '';

  constructor() {
  }

  showCasesHandler(): void {
    this.showCases.emit(this.durationValue);
  }
}
