import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthorizationStatus } from '../../../../../../../../shared/enums/authorizations/authorization-status.enum';
import { AuthorizationsService } from '../../../../services/authorizations.service';
import { groupBy, maxBy } from '@shared/utilities/arrays.utils';
import { AuthorizationType } from '@shared/enums/authorizations/authorization-type.enum';
import { IPriorAuthorization } from '@shared/interfaces/authorizations/prior-authorization.interface';
import { IAuthorization } from '@shared/interfaces/authorizations/authorization.interface';
import { ICopayAuthorization } from '@shared/interfaces/authorizations/copay-authorization.interface';
import { CopayType } from '@shared/interfaces/authorizations/copay-type.interface';
import { CoverageType } from '@shared/enums/insurance/coverage-type.enum';

@Component({
  selector: 'app-case-authorization',
  templateUrl: './case-authorization.component.html',
  styleUrls: [
    '../../../timeline/timeline.component.scss',
    '../benefit/benefit.component.scss',
    './case-authorization.component.scss',
  ],
})
export class CaseAuthorizationComponent {
  AuthorizationType = AuthorizationType;
  AuthorizationStatus = AuthorizationStatus;
  CoverageType = CoverageType;

  private _case;

  @Input() set case (newCase: any){
    this._case = newCase;

    this.loadAuthorizations();
    this.loadCopayTypes();
  }

  get case() { 
    return this._case; 
  }

  copayColumnsToDisplay = [
    'entity',
    'outcome',
    'dateReceived',
    'effectiveEndDate',
    'referenceNumber',
    'copayType',
    'balanceStart',
    'balanceUsed',
    'balanceRemaining',
  ];

  priorColumnsToDisplay = [
    'entity',
    'outcome',
    'dateReceived',
    'effectiveEndDate',
    'referenceNumber',
    'fillsNumber',
  ];

  papColumnsToDisplay = [
    'outcome',
    'dateReceived',
    'effectiveEndDate',
  ];

  priorDataSource: MatTableDataSource<IPriorAuthorization> = new MatTableDataSource([]);
  copayDataSource: MatTableDataSource<ICopayAuthorization> = new MatTableDataSource([]);
  papDataSource: MatTableDataSource<IAuthorization> = new MatTableDataSource([]);

  copayProgress: AuthorizationStatus;
  priorProgress: AuthorizationStatus;
  papProgress: AuthorizationStatus;

  copayEditEffectiveEnd: boolean[] = [];
  copayEditEffectiveEndInput: any[] = [];
  copayBalanceUsed: boolean[] = [];
  copayBalanceUsedInput: any[] = [];

  priorEditEffectiveEnd: boolean[] = [];
  priorEditFillsNumber: boolean[] = [];
  priorEditEffectiveEndInput: any[] = [];
  priorEditFillsNumberInput: number[] = [];

  papEditEffectiveEnd: boolean[] = [];
  papEditEffectiveEndInput: any[] = [];

  authorizationsExists: boolean;

  copayAuthorizationTypes: CopayType[];

  constructor(
    private readonly authorizationsService: AuthorizationsService,
  ) {
  }

  private loadCopayTypes(): void {
    this.authorizationsService.getCopayTypes().subscribe(
      res => {
        if (res.success) {
          this.copayAuthorizationTypes = res.value;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  private loadAuthorizations(): void {
    if (!this.case?.id) {
      return;
    }

    this.authorizationsService.getAuthorizations(this.case.id).subscribe(
      res => {
        this.initAuthorizations(res.success ? res.value : []);
      },
      error => {
        console.error(error);
      }
    );
  }

  private initAuthorizations(authorizations: any[]): void {
    this.authorizationsExists = !!authorizations.length;

    const groups = groupBy(authorizations, e => e.type);

    this.copayDataSource.data = groups[AuthorizationType.copay] ?? [];
    this.priorDataSource.data = groups[AuthorizationType.prior] ?? [];
    this.papDataSource.data = groups[AuthorizationType.patientAssistance] ?? [];

    this.initUi(groups[AuthorizationType.prior]?.length ?? 0, groups[AuthorizationType.copay]?.length ?? 0);

    this.initStatuses();
  }

  private initStatuses() {
    this.copayProgress = this.copayDataSource.data.length
      ? this.getStatus(this.copayDataSource.data)
      : undefined;

    this.priorProgress = this.priorDataSource.data.length
      ? this.getStatus(this.priorDataSource.data)
      : undefined;

    this.papProgress = this.papDataSource.data.length
      ? this.getStatus(this.papDataSource.data)
      : undefined;
  }

  private getStatus(authorizations: any[]): any {
    const authorization = maxBy(authorizations, e => e.endDate);

    return this.isPeriodInRange(authorization) ? authorization.status : AuthorizationStatus.expired;
  }

  private initUi(priorLength: number, copayLength: number): void {
    this.priorEditEffectiveEnd = Array(priorLength).fill(false);
    this.priorEditEffectiveEndInput = Array(priorLength).fill('');

    this.priorEditFillsNumber = Array(priorLength).fill(false);
    this.priorEditFillsNumberInput = Array(priorLength).fill(null);

    this.copayEditEffectiveEnd = Array(copayLength).fill(false);
    this.copayEditEffectiveEndInput = Array(copayLength).fill('');

    this.copayBalanceUsed = Array(copayLength).fill(false);
    this.copayBalanceUsedInput = Array(copayLength).fill(null);
  }

  isDateInFuture(date): boolean {
    const currentTime = new Date().getTime();
    const receivedTime = new Date(date).getTime();

    return receivedTime > currentTime;
  }

  saveCopayEffectiveEndDate(i: number): void {
    this.copayEditEffectiveEnd[i] = false;
    this.copayDataSource.data[i].endDate = this.copayEditEffectiveEndInput[i];
    this.copayEditEffectiveEndInput[i] = '';

    this.saveCopayData(this.copayDataSource.data[i]);
  }

  savePriorEffectiveEndDate(i: number): void {
    this.priorEditEffectiveEnd[i] = false;
    this.priorDataSource.data[i].endDate = this.priorEditEffectiveEndInput[i];
    this.priorEditEffectiveEndInput[i] = '';

    this.savePriorAuthData(this.priorDataSource.data[i]);
  }

  savePAPEffectiveEndDate(i: number): void {
    this.papEditEffectiveEnd[i] = false;
    this.papDataSource.data[i].endDate = this.papEditEffectiveEndInput[i];
    this.papEditEffectiveEndInput[i] = '';

    this.savePAPData(this.papDataSource.data[i]);
  }

  saveBalanceUsed(i: number): void {
    this.copayBalanceUsed[i] = false;
    this.copayDataSource.data[i].amountUsed = this.copayBalanceUsedInput[i];
    this.copayBalanceUsedInput[i] = null;

    this.saveCopayData(this.copayDataSource.data[i]);
  }

  saveFillsNumber(i: number): void {
    this.priorEditFillsNumber[i] = false;
    this.priorDataSource.data[i].fillsNumber = this.priorEditFillsNumberInput[i];
    this.priorEditFillsNumberInput[i] = null;

    this.savePriorAuthData(this.priorDataSource.data[i]);
  }

  editEffectiveEndCopay(i: number): void {
    this.copayEditEffectiveEnd[i] = true;
    this.copayEditEffectiveEndInput[i] = this.copayDataSource.data[i].endDate;
    setTimeout(() => document.getElementById('copayEffectiveEndInput_' + i).focus());
  }

  editEffectiveEndPrior(i: number): void {
    this.priorEditEffectiveEnd[i] = true;
    this.priorEditEffectiveEndInput[i] = this.priorDataSource.data[i].endDate;
    setTimeout(() => document.getElementById('priorEffectiveEndInput_' + i).focus());
  }

  editEffectiveEndPAP(i: number): void {
    this.papEditEffectiveEnd[i] = true;
    this.papEditEffectiveEndInput[i] = this.papDataSource.data[i].endDate;
    setTimeout(() => document.getElementById('papEffectiveEndInput_' + i).focus());
  }

  editBalanceUsed(i: number): void {
    this.copayBalanceUsed[i] = true;
    this.copayBalanceUsedInput[i] = this.copayDataSource.data[i].amountUsed;
    setTimeout(() => {
      document.getElementById('balanceUsedInput_' + i).focus();
      document.execCommand('selectall', null);
    });
  }

  editFillsNumber(i: number): void {
    this.priorEditFillsNumber[i] = true;
    this.priorEditFillsNumberInput[i] = this.priorDataSource.data[i].fillsNumber;
    setTimeout(() => {
      document.getElementById('fillsNumberInput_' + i).focus();
      document.execCommand('selectall', null);
    });
  }

  saveCopayData(data): void {
    this.authorizationsService.updateAuthorization(data).subscribe(
      res => {
        this.initStatuses();
      },
      err => {
        console.error(err);
      }
    );
  }

  savePriorAuthData(data): void {
    this.authorizationsService.updateAuthorization(data).subscribe(
      res => {
        this.initStatuses();
      },
      err => {
        console.error(err);
      }
    );
  }

  savePAPData(data): void {
    this.authorizationsService.updateAuthorization(data).subscribe(
      res => {
        this.initStatuses();
      },
      err => {
        console.error(err);
      }
    );
  }

  isPeriodInRange(item: IAuthorization): boolean {
    if (!item.endDate) {
      return true;
    }

    try {
      return new Date(item.endDate).getTime() > new Date().getTime();
    } catch (exc) {
      console.error('Wrong date format:', item.endDate);

      return false;
    }
  }
}
