import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { UncertainDataEntryReasons } from 'src/app/features/intake/enums/uncertain-data-entry-reasons.enum';
import { UncertainDataEntryComponent } from 'src/app/features/intake/modals/uncertain-data-entry/uncertain-data-entry.component';
import { DocumentIdentificationService } from './services/document-identification.service';
import * as DocumentAction from '../../../../../../store/document/document.actions';

@Component({
  selector: 'app-document-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss', '../../document.component.scss'],
})
export class DocumentDataComponent implements OnInit {
  public isGrid = undefined;
  public queueId = undefined;

  constructor(
    private readonly identificationService: DocumentIdentificationService,
    private readonly store: Store<any>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) {
    this.store.select('document').subscribe((state) => {
      this.isGrid = state.grid;
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queueId = parseInt(params.queueId);
    });
  }

  public showEscalationDialog() { 
    this.dialog
      .open(UncertainDataEntryComponent)
      .afterClosed()
      .subscribe(reasons => {
        if (!reasons?.length) return;

        this.escalateItemWith(reasons);
      });
  }

  private escalateItemWith(reasons: UncertainDataEntryReasons[]) { 
    this.store
      .select('document')
      .pipe(first())
      .subscribe((state) => {
        const batchId = state.documentList[state.selectedDocument].documentBatchId;

        const command = {
          batchId,
          reasons,
        }

        if (!command.batchId || !command.reasons?.length) return;

        this.identificationService
          .escalateItem(command)
          .subscribe(res => { 
            if (!res?.success) return;

            this.getNextStack();
          })
      });
  }

  private getNextStack(): void {
    this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(true));
    this.router.navigate(['/document-data-entry'], { queryParams: { queueId: this.queueId } });
  }
}
