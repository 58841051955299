<div *ngIf="!currentInsurance; else chosenInsuranceTmpl" class="insurances-container">
  <div class="active-plans">
    <div class="medical-insurances">
      <h2>Medical Insurance</h2>

      <ng-container *ngIf="medicalInsurances.length">
        <div (click)="choosePlan(plan)" *ngFor="let plan of medicalInsurances" class="plan">
          <div class="user-name">
            {{plan.patientInsurance?.payerName | empty}}
            <img class="arrow-right-icon" src="assets/icons/icon-arrow-long-right-blue.svg" alt="" />
          </div>
          <div class="plan-name">
            {{plan.patientInsurance?.planName | empty}}
          </div>
          <div class="insurance-type">
            {{insurancePriorityTypes[plan.patientInsurance?.insurancePriorityId] | empty}}
          </div>
          <div class="plan-type">
            {{plan.patientInsurance?.planTypeName}} – {{plan.patientInsurance?.planSubTypeName}}
          </div>
          <div *ngIf="plan.patientInsurance?.payerPhone" class="phone">
            <img class="phone-icon" src="assets/icons/icon_phone.svg" alt="phone" />
            {{plan.patientInsurance?.payerPhone | mask: '(999) 999-9999'}}
          </div>
        </div>
      </ng-container>

      <div *ngIf="!medicalInsurances.length" class="plan empty">
        <div class="heading">No Current Medical</div>
        <div class="content">
          Medical insurance will show after it has been added to this case.
        </div>
      </div>
    </div>

    <div class="pharmacy-insurances">
      <h2>Pharmacy Insurance</h2>

      <ng-container *ngIf="pharmacyInsurances.length">
        <div (click)="choosePlan(plan)" *ngFor="let plan of pharmacyInsurances" class="plan">
          <div class="user-name">
            {{plan.patientInsurance?.pharmacyBenefitPlan?.name}}
            <img class="arrow-right-icon" src="assets/icons/icon-arrow-long-right-blue.svg" alt="" />
          </div>
          <div class="insurance-type">
            {{insurancePriorityTypes[plan.patientInsurance?.insurancePriorityId]}}
          </div>
          <div class="phone">
            {{plan.patientInsurance?.planPhone | mask: '(999) 999-9999'}}
          </div>
        </div>
      </ng-container>

      <div *ngIf="!pharmacyInsurances.length" class="plan empty">
        <div class="heading">No Current Pharmacy</div>
        <div class="content">
          Pharmacy insurance will show after it has been added to this case.
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="inactiveInsurances.length" class="inactive-plans">
    <div (click)="inactivePlansOpened = !inactivePlansOpened" class="plans-toggler" [ngClass]="{opened: inactivePlansOpened}">
      {{inactivePlansOpened ? 'Hide' : 'See'}} {{inactivePlansOpened ? '' : inactiveInsurances.length}} inactive plan{{inactiveInsurances.length > 1 ? 's' : ''}}
      <img class="chevron-icon" src="assets/icons/icon_chevron_blue.svg" alt="chevron_down" />
    </div>

    <div *ngIf="inactivePlansOpened" class="plans-container">
      <div (click)="choosePlan(plan)" *ngFor="let plan of inactiveInsurances" class="plan inactive">
        <ng-container *ngIf="plan.patientInsurance?.coverageTypeId === CoverageType.medical">
          <div class="user-name">
            {{plan.patientInsurance?.payerName | empty}}
          </div>
          <div class="plan-name">
            {{plan.patientInsurance?.planName | empty}}
          </div>
          <div class="insurance-type">
            {{insurancePriorityTypes[plan.patientInsurance?.insurancePriorityId] | empty}}
          </div>
          <div class="plan-type">
            {{plan.patientInsurance?.planTypeName}} – {{plan.patientInsurance?.planSubTypeName}}
          </div>
          <div class="phone">
            {{plan.patientInsurance?.payerPhone ? (plan.patientInsurance?.payerPhone | mask: '(999) 999-9999') : '--'}}
          </div>
        </ng-container>
        <ng-container *ngIf="plan.patientInsurance?.coverageTypeId === CoverageType.pharmacy">
          <div class="user-name">
            {{plan.patientInsurance?.pharmacyBenefitPlan?.name | empty}}
          </div>
          <div class="insurance-type">
            {{insurancePriorityTypes[plan.patientInsurance?.insurancePriorityId] | empty}}
          </div>
          <div class="phone">
            {{plan.patientInsurance?.planPhone | mask: '(999) 999-9999'}}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #chosenInsuranceTmpl>
  <div class="current-insurance">
    <div (click)="backToInsuranceLists()" class="back-label">
      <img class="arrow-icon" src="assets/icons/icon-arrow-long-right-blue.svg" alt="arrow_left" />
      Back
    </div>

    <div class="custom-scrollbar">

      <!-- MEDICAL PLAN -->

      <div *ngIf="currentInsurance.patientInsurance.coverageTypeId === CoverageType.medical"
           class="patient-insurance"
           [ngClass]="{edit: editPatient}"
      >
        <app-editable-aside-field [fieldId]="'medicalPlan'"
                                  [editMode]="false"
                                  (clickInside)="openMedicalPlanDialog(false)"
        >
          <div text class="detail-item user-name-block">
            <h2>{{currentInsurance.patientInsurance.payerName | empty}}</h2>
            <mat-icon class="edit-icon">edit</mat-icon>
          </div>

          <div text class="detail-item">
            <h3>Plan name</h3>
            <p>{{ currentInsurance.patientInsurance.planName | empty }}</p>
          </div>

          <div text class="detail-item">
            <h3>Priority</h3>
            {{insurancePriorityTypes[currentInsurance.patientInsurance?.insurancePriorityId] | empty}} Medical
          </div>

          <div text class="detail-item">
            <h3>Type & Subtype</h3>
            <ng-container *ngIf="currentInsurance.patientInsurance?.planTypeName || currentInsurance.patientInsurance?.planSubTypeName; else noTypesTmpl">
              {{currentInsurance.patientInsurance?.planTypeName | empty}} – {{currentInsurance.patientInsurance?.planSubTypeName | empty}}
            </ng-container>
            <ng-template #noTypesTmpl>--</ng-template>
          </div>

          <div text class="detail-item">
            <h3>Plan phone</h3>
            <p>{{ currentInsurance.patientInsurance.planPhone ? (currentInsurance.patientInsurance.planPhone | mask: '(999) 999-9999') : '--' }}</p>
          </div>

          <button *ngIf="editPatient">
            Editing in modal
            <mat-icon class="edit-icon">edit</mat-icon>
          </button>
        </app-editable-aside-field>
      </div>

      <!-- PHARMACY PLAN -->

      <div *ngIf="currentInsurance.patientInsurance.coverageTypeId === CoverageType.pharmacy"
           class="patient-insurance"
           (click)="editPatient = true"
           [ngClass]="{edit: editPatient}"
      >
        <app-editable-aside-field [fieldId]="'pharmacyPlan'"
                                  [editMode]="false"
                                  (clickInside)="openMedicalPlanDialog(true)"
        >
          <div text class="detail-item user-name-block">
            <h2>{{currentInsurance.patientInsurance.payerName}}</h2>
            <mat-icon class="edit-icon">edit</mat-icon>
          </div>

          <div text class="detail-item">
            <h3>Priority</h3>
            {{insurancePriorityTypes[currentInsurance.patientInsurance?.insurancePriorityId] | empty}} Pharmacy
          </div>

          <div text class="detail-item">
            <h3>Is PBM?</h3>
            {{currentInsurance.patientInsurance?.pharmacyBenefitPlan?.isPBM ? 'Yes' : 'No'}}
          </div>

          <div text class="detail-item">
            <h3>Phone</h3>
            <p>{{ currentInsurance.patientInsurance?.pharmacyBenefitPlan?.phone ? (currentInsurance.patientInsurance?.pharmacyBenefitPlan?.phone | mask: '(999) 999-9999') : '--' }}</p>
          </div>

          <div text class="detail-item">
            <h3>Fax</h3>
            <p>{{ currentInsurance.patientInsurance?.pharmacyBenefitPlan?.fax ? (currentInsurance.patientInsurance?.pharmacyBenefitPlan?.fax | mask: '(999) 999-9999') : '--' }}</p>
          </div>

          <div text class="detail-item">
            <h3>BIN</h3>
            <p>{{ currentInsurance.patientInsurance?.pharmacyBenefitPlan?.bin | empty }}</p>
          </div>

          <div text class="detail-item">
            <h3>PCN</h3>
            <p>{{ currentInsurance.patientInsurance?.pharmacyBenefitPlan?.pcn | empty }}</p>
          </div>

          <button *ngIf="editPatient">
            Editing in modal
            <mat-icon class="edit-icon">edit</mat-icon>
          </button>
        </app-editable-aside-field>
      </div>

      <div class="divider"></div>

      <div class="policy-block">
        <div class="policy-header">
          <h4>Policy</h4>
        </div>

        <form class="policy-form" [formGroup]="policyFormGroup">
          <app-editable-aside-field [fieldId]="'memberId'"
                                    [disableSave]="policyFormGroup.get('memberId').invalid"
                                    [editMode]="true"
                                    (editClosed)="clearPolicyControls(['memberId'])"
                                    (saveData)="savePolicyData(['memberId'])"
          >
            <div text class="detail-item">
              <h3>Member ID</h3>
              <p>{{ currentInsurance.patientInsurance?.memberId | empty }}</p>
            </div>
            <div inputs class="detail-item">
              <div class="input-row input-item" [class.input-error]="policyFormGroup.get('memberId').invalid">
                <label for="memberId">Member ID</label>
                <input id="memberId" maxlength="50" formControlName="memberId" matInput />
              </div>
            </div>
          </app-editable-aside-field>

          <app-editable-aside-field [fieldId]="'planNumber'"
                                    [disableSave]="policyFormGroup.get('planNumber').invalid"
                                    [editMode]="true"
                                    (editClosed)="clearPolicyControls(['planNumber'])"
                                    (saveData)="savePolicyData(['planNumber'])"
          >
            <div text class="detail-item">
              <h3>Plan Number</h3>
              <p>{{ currentInsurance.patientInsurance?.planNumber | empty }}</p>
            </div>
            <div inputs class="detail-item">
              <div class="input-row input-item" [class.input-error]="policyFormGroup.get('planNumber').invalid">
                <label for="planNumber">Plan Number</label>
                <input id="planNumber" maxlength="50" formControlName="planNumber" matInput />
              </div>
            </div>
          </app-editable-aside-field>

          <app-editable-aside-field [fieldId]="'groupNumber'"
                                    [disableSave]="policyFormGroup.get('groupNumber').invalid"
                                    [editMode]="true"
                                    (editClosed)="clearPolicyControls(['groupNumber'])"
                                    (saveData)="savePolicyData(['groupNumber'])"
          >
            <div text class="detail-item">
              <h3>Group Number</h3>
              <p>{{ currentInsurance.patientInsurance?.groupNumber | empty }}</p>
            </div>
            <div inputs class="detail-item">
              <div class="input-row input-item" [class.input-error]="policyFormGroup.get('groupNumber').invalid">
                <label for="groupNumber">Group Number</label>
                <input id="groupNumber" maxlength="50" formControlName="groupNumber" matInput />
              </div>
            </div>
          </app-editable-aside-field>

          <app-editable-aside-field [fieldId]="'policyHolderName'"
                                    [disableSave]="policyFormGroup.get('policyHolderName').invalid"
                                    [editMode]="true"
                                    (editClosed)="clearPolicyControls(['policyHolderName'])"
                                    (saveData)="savePolicyData(['policyHolderName'])"
          >
            <div text class="detail-item">
              <h3>Policyholder Name</h3>
              <p>{{ currentInsurance.patientInsurance?.policyHolderName | empty }}</p>
            </div>
            <div inputs class="detail-item">
              <div class="input-row input-item" [class.input-error]="policyFormGroup.get('policyHolderName').invalid">
                <label for="policyHolderName">Policyholder Number</label>
                <input id="policyHolderName" maxlength="50" formControlName="policyHolderName" matInput />
              </div>
            </div>
          </app-editable-aside-field>

          <app-editable-aside-field [fieldId]="'policyHolderDOB'"
                                    [disableSave]="policyFormGroup.get('policyHolderDOB').invalid"
                                    [editMode]="true"
                                    (editClosed)="clearPolicyControls(['policyHolderDOB'])"
                                    (saveData)="savePolicyData(['policyHolderDOB'])"
          >
            <div text class="detail-item">
              <h3>Policyholder DOB</h3>
              <p>{{ currentInsurance.patientInsurance?.policyHolderDOB ? (currentInsurance.patientInsurance?.policyHolderDOB | date: 'MM/dd/yyyy') : '---' }}</p>
            </div>
            <div inputs class="detail-item">
              <div class="input-row date-input input-item">
                <label for="policyHolderDOB">Policyholder DOB</label>
                <input matInput
                       formControlName="policyHolderDOB"
                       [matDatepicker]="policyHolderDOB"
                       id="policyHolderDOB"
                       placeholder="MM/DD/YY"
                       (focus)="policyHolderDOB.open()"
                       (click)="policyHolderDOB.open()"
                />
                <mat-datepicker-toggle matSuffix [for]="policyHolderDOB" tabindex="-1"></mat-datepicker-toggle>
                <mat-datepicker #policyHolderDOB></mat-datepicker>
              </div>
            </div>
          </app-editable-aside-field>

          <app-editable-aside-field [fieldId]="'effectiveFrom'"
                                    [disableSave]="policyFormGroup.get('effectiveFrom').invalid || policyFormGroup.get('effectiveTo').invalid"
                                    [editMode]="true"
                                    (editClosed)="clearPolicyControls(['effectiveFrom', 'effectiveTo'])"
                                    (saveData)="savePolicyData(['effectiveFrom', 'effectiveTo'])"
          >
            <div text class="detail-item">
              <h3>Effective From</h3>
              <p>{{ currentInsurance.patientInsurance?.effectiveFrom ? (currentInsurance.patientInsurance?.effectiveFrom | date: 'MM/dd/yyyy') : '---' }}</p>
            </div>
            <div inputs class="detail-item">
              <div class="input-row date-input input-item">
                <label for="birthDate">Effective From</label>
                <input matInput
                       formControlName="effectiveFrom"
                       [matDatepicker]="effectiveFrom"
                       id="effectiveFrom"
                       placeholder="MM/DD/YY"
                       [max]="policyFormGroup.get('effectiveTo').value"
                       (focus)="effectiveFrom.open()"
                       (click)="effectiveFrom.open()"
                />
                <mat-datepicker-toggle matSuffix [for]="effectiveFrom" tabindex="-1"></mat-datepicker-toggle>
                <mat-datepicker #effectiveFrom></mat-datepicker>
              </div>
            </div>

            <div text class="detail-item">
              <h3>Effective To</h3>
              <p>{{ currentInsurance.patientInsurance?.effectiveTo ? (currentInsurance.patientInsurance?.effectiveTo | date: 'MM/dd/yyyy') : '---' }}</p>
            </div>
            <div inputs class="detail-item">
              <div class="input-row date-input input-item">
                <label for="birthDate">Effective To</label>
                <input matInput
                       formControlName="effectiveTo"
                       [matDatepicker]="effectiveTo"
                       id="birthDate"
                       placeholder="MM/DD/YY"
                       [min]="policyFormGroup.get('effectiveFrom').value"
                       (focus)="effectiveTo.open()"
                       (click)="effectiveTo.open()"
                />
                <mat-datepicker-toggle matSuffix [for]="effectiveTo" tabindex="-1"></mat-datepicker-toggle>
                <mat-datepicker #effectiveTo></mat-datepicker>
              </div>
            </div>
          </app-editable-aside-field>
        </form>

      </div>
    </div>
  </div>
</ng-template>
