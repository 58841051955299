import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractRestService } from '@core/services/abstract-rest.service';
import { IResponse } from '@shared/interfaces/response.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root', })
export class AuthorizationsService extends AbstractRestService {

  constructor( protected http: HttpClient, ) { super(http); }

  getAuthorization(id: number): Observable<IResponse> {
    this.url = this.apiUrl + `/Authorizations/get-authorization/${id}`;
    
    return this.get<IResponse>();
  }

  getAuthorizations(caseId: number): Observable<IResponse> {
    this.url = this.apiUrl + `/Authorizations/get-case-authorizations/${caseId}`;
    
    return this.get<IResponse>();
  }

  getCopayTypes(): Observable<IResponse> {
    this.url = this.apiUrl + `/Authorizations/get-copay-authorization-types`;
    
    return this.get<IResponse>();
  }

  createAuthorization(authorization: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Authorizations/create-authorization`;
    
    return this.postItem<any, IResponse>(authorization);
  }

  updateAuthorization(authorization: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Authorizations/update-authorization`;
    
    return this.putItem<any, IResponse>(authorization);
  }

  deleteAuthorization(id: number): Observable<IResponse> {
    this.url = this.apiUrl + `/Authorizations/delete-authorization/${id}`;
    
    return this.delete<IResponse>();
  }
}
