import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { getSuggestedCases } from '../../../../helpers/utils';

@Component({
  selector: 'app-suggested-cases',
  templateUrl: './suggested-cases.component.html',
})
export class SuggestedCasesComponent implements OnInit {
  suggestedCases = [];

  ngOnInit(): void {
    this.suggestedCases = getSuggestedCases();
  }
}
