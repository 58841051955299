import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../core/services/abstract-rest.service';
import { Observable } from 'rxjs';
import * as OktaAuth from '@okta/okta-auth-js';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LoginService extends AbstractRestService {
  private readonly OktaDeviceTokensKey: string = 'p3-okta-device-tokens';
  private authClient: any;

  constructor(protected http: HttpClient) {
    super(http);

    this.authClient = new OktaAuth({
      pkce: true,
      clientId: environment.oidc.clientId,
      responseMode: 'query',
      display: 'page',
      issuer: environment.oidc.issuer,
      scopes: environment.oidc.scopes,
      redirectUri: environment.oidc.redirectUri
    });
  }

  public login(): Observable<any> {
    this.url = this.apiUrl + `/Account/Login`;
    return this.postItem<any, any>();
  }

  public logout(): Observable<any> {
    this.url = this.apiUrl + `/Account/Logout`;
    return this.postItem<any, any>();
  }

  public oktaLogin(userData) {
    let deviceToken;

    if (userData.rememberMe) {
      deviceToken = this.getOktaDeviceToken(userData.email);  //Getting from localStorage or generating and storing in localStorage and returning
    }
    else {
      this.removeOktaDeviceToken(userData.email);

      //sending device token but we don't persist in localStorage so each time they log in, it will treat it as a new device and will force MFA (email verification)
      deviceToken = this.generateOktaDeviceToken(); 
    }

    // Note: authClient.signIn is deprecated, this method is removed at newest version,
    // after update use signInWithCredentials instead.
    // more at: https://github.com/okta/okta-auth-js
    return this.authClient.signIn({ username: userData.email, password: userData.password, context: { deviceToken } });
  }

  public proceedTransaction(transaction) {
    this.authClient.token.getWithRedirect({
      sessionToken: transaction.sessionToken,
      responseType: 'id_token',
      scopes: environment.oidc.scopes
    });
  }

  public oktaLogout() {
    this.authClient.tokenManager.get('idToken').then(token => {
      this.authClient.signOut({ idToken: token });
    });
  }

  //In order for okta to detect a new device, we must send a device token that we manage.
  //This is generated and stored in localStorage per email.
  //Passing this allows users that have already authenticated through MFA (email is sent with 6 digit code)
  //to bypass MFA if "Remember Me" is selected when logging in.
  private getOktaDeviceToken(email: string) {
    let deviceTokensString: string = localStorage.getItem(this.OktaDeviceTokensKey);
    let deviceTokens: any = {};
    let deviceToken: string;

    if (deviceTokensString !== null && typeof deviceTokensString !== 'undefined') {
      deviceTokens = JSON.parse(deviceTokensString);
    }

    if (deviceTokens.hasOwnProperty(email)) {
      deviceToken = deviceTokens[email];
    }
    else {
      deviceToken = this.generateOktaDeviceToken();
      deviceTokens[email] = deviceToken;
      localStorage.setItem(this.OktaDeviceTokensKey, JSON.stringify(deviceTokens));
    }

    return deviceToken;
  }

  private removeOktaDeviceToken(email: string) {
    let deviceTokensString: string = localStorage.getItem(this.OktaDeviceTokensKey);

    if (deviceTokensString === null || typeof deviceTokensString === 'undefined') 
      return;

    const deviceTokens = JSON.parse(deviceTokensString);

    if (!deviceTokens.hasOwnProperty(email))
      return;

    delete deviceTokens[email];
    localStorage.setItem(this.OktaDeviceTokensKey, JSON.stringify(deviceTokens));
  }

  private generateOktaDeviceToken() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }
}
