import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../core/services/abstract-rest.service';
import { Observable } from 'rxjs';
import { ChartType } from '../enums/chart-type.enum';
import { DayOfWeek } from '../enums/day-of-week.enum';
import { IResponse } from '../interfaces/response.interface';
import { getDayOfWeekShortName } from '../helpers/utils';

@Injectable({
  providedIn: 'root',
})
export class QueueTrendingService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getDataPoints(values): any {
    const grouppedValues = this.groupValues(values);
    const dataPoints = [];
    const numberOfDays = 7;
    const current = new Date();
    current.setHours(0, 0, 0, 0);
    current.setDate(current.getDate() - numberOfDays + 1);
    let prev = null;

    for (let i = 0; i < numberOfDays; i++) {
      let value = grouppedValues.get(current.getTime()) || prev;
      if (!value && grouppedValues.size > 0) {
        const firstItem = grouppedValues.values().next().value;
        value = {
          usersCount: firstItem.usersCount,
          itemsCount: firstItem.itemsCount,
          y: firstItem.y
        };
      }

      const dataPoint = {
        label: `${getDayOfWeekShortName(current)}, ${current.getMonth()}/${current.getDate()}`,
        usersCount: value?.usersCount || 0,
        itemsCount: value?.itemsCount || 0,
        date: new Date(current),
        y: value?.y || 0
      };

      prev = dataPoint;

      const dayOfWeekNumber = current.getDay();

      if (dayOfWeekNumber !== DayOfWeek.saturday && dayOfWeekNumber !== DayOfWeek.sunday) {
        dataPoints.push(dataPoint);
      }

      current.setDate(current.getDate() + 1);
    }

    return dataPoints;
  }

  public groupValues(values): any {
    const res = new Map();
    let nextDate = null;
    for (let i = 0; i < values.length; i++) {
      const currentDate = new Date(values[i].Updated);
      currentDate.setHours(0, 0, 0, 0);
      if (values[i + 1]) {
        nextDate = new Date(values[i + 1].Updated);
        nextDate?.setHours(0, 0, 0, 0);
      }
      if (!nextDate || currentDate !== nextDate) {
        res.set(currentDate.getTime(), {
          usersCount: values[i].UsersCount,
          itemsCount: values[i].ItemsCount,
          y: values[i].ItemsCount
        });
      }
    }

    return res;
  }

  public getChartType(dataPoints): ChartType {
    if (dataPoints[dataPoints.length - 1]?.y > dataPoints[dataPoints.length - 2].y) {
      return ChartType.increasing;
    } else if (dataPoints[dataPoints.length - 1]?.y < dataPoints[dataPoints.length - 2].y) {
      return ChartType.declining;
    }

    return ChartType.stable;
  }

  getColorsByType(chartType): Array<string> {
    if (chartType === ChartType.increasing) {
      return ['#eb9e8d', 'rgba(244, 201, 191, 0.5)', 'rgba(252, 241, 239, 0.25)', 'rgba(252, 241, 239, 0)', '#e3745e'];
    } else if (chartType === ChartType.declining) {
      return ['#8edabd', 'rgba(190, 234, 217, 0.5', 'rgba(239, 250, 246, 0.25)', 'rgba(239, 250, 246, 0)', '#5dcaa0'];
    }

    return ['#83a7e0', 'rgba(183, 205, 237, 0.5)', 'rgba(237, 242, 251, 0.25)', 'rgba(237, 242, 251, 0)', '#345a93'];
  }

  public getChartColor(element, chartType): Array<any> {
    const canvas = element?.nativeElement;

    if (canvas) {
      const ctx = canvas.getContext('2d');
      const gradientStroke = ctx.createLinearGradient(0, 0, 0, canvas.offsetHeight);
      const colors = this.getColorsByType(chartType);
      gradientStroke.addColorStop(0, colors[0]);
      gradientStroke.addColorStop(0.41, colors[1]);
      gradientStroke.addColorStop(0.81, colors[2]);
      gradientStroke.addColorStop(1, colors[3]);

      return [
        {
          borderColor: colors[4],
          backgroundColor: gradientStroke,
        },
      ];
    }

    return [];
  }

  public getQueueTrends(queueId: string): Observable<IResponse> {
    this.url = this.apiUrl + `/QueueTrendingReport`;
    return this.getItems<IResponse>(queueId);
  }
}
