import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reschedule-task',
  templateUrl: './exhausted.component.html',
  styleUrls: ['../reschedule/reschedule.component.scss', './exhausted.component.scss'],
})
export class ExhaustedTaskComponent {
  constructor(private dialogRef: MatDialogRef<ExhaustedTaskComponent>) { }

  reschedule(): void {
    this.dialogRef.close({createManualFollowUp: true});
  }

  escalate(): void {
    this.dialogRef.close({escalateToSupervisor: true});
  }

  close(): void {
    this.dialogRef.close();
  }
}
