<form *ngIf="parserForm"
      [formGroup]="parserForm"
      class="task-elements-parser-container"
      [ngClass]="{history: isHistory}"
>
  <div *ngFor="let field of fields; let i = index">
    <ng-container *ngIf="!field.isHidden" [ngSwitch]="field.type">
      <app-task-dropdown *ngSwitchCase="FieldType.Dropdown"
                         [label]="field.name"
                         formControlName="control_{{field.id}}"
                         [options]="field.options"
                         class="task-element"
      ></app-task-dropdown>

      <app-task-input *ngSwitchCase="FieldType.Input"
                      [field]="field"
                      formControlName="control_{{field.id}}"
                      class="task-element"
      ></app-task-input>

      <app-task-toggle-group *ngSwitchCase="FieldType.ToggleGroup"
                             [field]="field"
                             formControlName="control_{{field.id}}"
                             class="task-element"
                             [isHistory]="isHistory"
      ></app-task-toggle-group>

      <app-task-datepicker *ngSwitchCase="FieldType.Datepicker"
                           [label]="field.name"
                           [id]="field.id"
                           formControlName="control_{{field.id}}"
                           (valueChanged)="datepickerChanged.emit(field)"
                           class="task-element"
      ></app-task-datepicker>

      <app-task-datepicker-range *ngSwitchCase="FieldType.DatepickerRange"
                                 [field]="field"
                                 [id]="field.id"
                                 formControlName="control_{{field.id}}"
                                 class="task-element"
      ></app-task-datepicker-range>

      <app-task-add-button *ngSwitchCase="FieldType.AddButton"
                           [name]="field.name"
                           [style.display]="isHistory?'none':'block'"
                           (addEvent)="addEvent.emit(field)"
                           class="task-element"
      ></app-task-add-button>

      <div *ngSwitchCase="FieldType.InputDropdownRow" class="form-row">
        <app-task-input *ngIf="field.input"
                        [id]="field.input.id"
                        formControlName="control_{{field.id}}"
                        [field]="field.name"
                        class="task-element"
        ></app-task-input>
        <app-task-dropdown *ngIf="field.dropdown"
                           [label]="field.dropdown.name"
                           [id]="field.dropdown.id"
                           formControlName="control_{{field.id}}"
                           [options]="field.dropdown.options"
                           class="task-element"
        ></app-task-dropdown>
      </div>

      <app-task-textarea *ngSwitchCase="FieldType.Textarea"
                         [placeholder]="field.placeholder"
                         formControlName="control_{{field.id}}"
                         [label]="field.name"
                         class="task-element"
      ></app-task-textarea>

      <app-task-static-text *ngSwitchCase="FieldType.StaticTemplate"
                            [staticTemplate]="field.template"
                            [style.display]="isHistory?'none':'block'"
                            class="task-element"
      ></app-task-static-text>

      <app-task-contact-item *ngSwitchCase="FieldType.ContactItem"
                             [name]="field.name"
                             [style.display]="isHistory?'none':'block'"
                             [iconName]="field.iconName"
                             [value]="field.value"
                             [disabled]="field.disabled"
                             [description]="field.description"
                             (contactEvent)="contactEvent.emit(field)"
                             class="task-element"
      ></app-task-contact-item>

      <app-task-select-contact *ngSwitchCase="FieldType.ContactsList"
                               formControlName="control_{{field.id}}"
                               [options]="field.options"
                               class="task-element"
                               [isHistory]="isHistory"
      ></app-task-select-contact>

      <app-task-preview-document *ngSwitchCase="FieldType.PreviewDocument"
                                 [field]="field"
                                 [style.display]="isHistory?'none':'block'"
                                 (downloadDocument)="downloadDocument.emit($event)"
                                 (previewDocument)="previewDocument.emit($event)"
                                 class="task-element"
      ></app-task-preview-document>

      <app-task-select-attachments *ngSwitchCase="FieldType.SelectAttachments"
                                   formControlName="control_{{field.id}}"
                                   [field]="field"
                                   [case]="case"
                                   class="task-element"
                                   [isHistory]="isHistory"
      ></app-task-select-attachments>

      <app-task-message *ngSwitchCase="FieldType.Message"
                        [field]="field"
                        [style.display]="isHistory?'none':'block'"
                        class="task-element"
      ></app-task-message>

      <app-task-checkboxes-advanced *ngSwitchCase="FieldType.CheckboxesAdvanced"
                                    type="number"
                                    formControlName="control_{{field.id}}"
                                    [id]="field.id"
                                    [field]="field"
                                    class="task-element"
                                    [isHistory]="isHistory"
      ></app-task-checkboxes-advanced>

      <app-task-create-activity *ngSwitchCase="FieldType.CreateActivity"
                                [id]="field.id"
                                [case]="case"
                                class="task-element"
      ></app-task-create-activity>
    </ng-container>
  </div>
</form>
