import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appDetectScrollTop]',
})
export class DetectScrollTopDirective implements OnInit {
  @Input() offsetTop = 0;
  @Output() scrollTopDetected: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elRef: ElementRef) {
  }

  ngOnInit(): void {
    const scrollWrapper = document.getElementsByClassName('mat-drawer-content')[0];

    if (scrollWrapper) {
      scrollWrapper.addEventListener('scroll', (ev: any) => {
        if (
          ev.target.scrollTop > this.elRef.nativeElement.offsetTop + this.offsetTop &&
          ev.target.scrollTop < this.elRef.nativeElement.offsetTop + this.offsetTop +
          this.elRef.nativeElement.offsetHeight
        ) {
          this.scrollTopDetected.emit();
        }
      });
    }

  }
}
