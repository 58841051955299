import { isBlank } from './empty-checks';

export class Assign {
  public static withoutEmptyValues(target, source): any {
    Object.getOwnPropertyNames(source).forEach(prop => {
      if (isBlank(source[prop])) return;

      if (typeof source[prop] === 'object') {
        if (!target.hasOwnProperty(prop) || isBlank(target[prop]))
          target[prop] = {};

        Assign.withoutEmptyValues(target[prop], source[prop]);
      }
      else {
        target[prop] = source[prop];
      }
    });
  }
}
