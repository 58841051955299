<div mat-dialog-title>
  <h1>Select Attachments</h1>
  <button class="close-btn" mat-dialog-close><mat-icon>close</mat-icon></button>
</div>

<div mat-dialog-content>
  <div class="dialog-body">
    <table [@.disabled]="true" mat-table matSort [dataSource]="dataSource" class="attachments-list-table">
      <ng-container matColumnDef="dragIcon">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>document type</th>
      </ng-container>
      <ng-container matColumnDef="receiveDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>received date</th>
      </ng-container>
      <ng-container matColumnDef="documentRef">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
      <ng-container matColumnDef="controls">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <tr class="table-header" mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    </table>

    <div class="scroll-container custom-scroll-default">
      <div class="attachments-list-body">
        <div cdkDropList class="drop-list" (cdkDropListDropped)="drop($event)">
          <ng-container *ngFor="let document of draggableAttachments; let i = index">
            <div cdkDrag class="drop-box attachments-drop-box">
              <div class="fx-row fx-v-align-center">
                <div class="cell dragIcon">
                  <img class="drag-icon" src="assets/icons/icon_grabber.svg" alt="icon_grabber"/>
                </div>
                <div class="cell documentType">
                  <div class="document-type">{{document.documentType}}</div>
                </div>
                <div class="cell receiveDate">
                  {{document.receiveDate | date:"MM/dd/yyyy"}} at {{document.receiveDate | date: "hh:mm"}}
                </div>
                <div class="cell documentRef">
                  <button class="view-document-btn" (click)="viewFile(document)">
                    View
                    <img src="assets/icons/icon_open_ref_outside.svg" alt="open-outside" />
                  </button>
                </div>
                <div class="cell controls">
                  <button class="select-btn" mat-button mat-icon-button disableRipple (click)="removeFromDraggable(i)" tabindex="1">
                    <img class="add-column" src="assets/icons/icon-check.svg" alt="check"/>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="unselectedAttachments && unselectedAttachments.length" class="attachments-list-body unselected-list">
        <ng-container *ngFor="let document of unselectedAttachments; let i = index">
          <div class="drop-list">
            <div class="drop-box attachments-drop-box">
              <div class="fx-row fx-v-align-center">
                <div class="cell dragIcon">
                  <img src="assets/icons/icon_grabber.svg" class="drag-icon" alt="icon_grabber"/>
                </div>
                <div class="cell documentType">
                  <div class="file-type">{{document.documentType}}</div>
                </div>
                <div class="cell receiveDate">
                  {{document.receiveDate | date: 'dd/MM/yyyy'}} at {{document.receiveDate | date: 'hh:mm'}}
                </div>
                <div class="cell documentRef">
                  <button class="view-document-btn" (click)="viewFile(document)">
                    View
                    <img src="assets/icons/icon_open_ref_outside.svg" alt="open-outside" />
                  </button>
                </div>
                <div class="cell controls">
                  <button (click)="addToDraggable(i)" class="select-btn deselect" disableRipple mat-button mat-icon-button tabindex="1">
                    <img class="add-column" src="assets/icons/icon-add.svg" alt="add"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button class="save-attachments-btn" mat-dialog-close (click)="close()">
    Save
  </button>
</div>
