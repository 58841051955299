export enum Period {
  'All Time' = 0,
  'This Week' = 1,
  'Previous Week' = 2,
  'This Month' = 3,
  'Previous Month' = 4,
  'This Quarter' = 5,
  'Previous Quarter' = 6,
  'This Year' = 7,
  'Previous Year' = 8,
  'Custom Date Range' = 9
}
