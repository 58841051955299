<div mat-dialog-title>
  <h1><img alt="age" src="assets/icons/icon-age-red.svg"/>Attempts Exhausted</h1>
  <button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p>You've reached the <b>maximum</b> amount of <b>attempts</b> for this task. What would you like to do?</p>
</div>

<div mat-dialog-actions>
  <button mat-button class="back" (click)="reschedule()">
    <img alt="back" src="assets/icons/icon-age-blue.svg"/>Manual Follow-Up
  </button>
  <button mat-button (click)="escalate()">Escalate to Supervisor
    <mat-icon>east
    </mat-icon>
  </button>
</div>
