import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { IPayer } from '@shared/interfaces/payer.interface';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IResponse } from '@shared/interfaces/response.interface';
import { ITableQueryParams } from '@shared/interfaces/table-query-params.interface';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { IPBM } from '@shared/interfaces/pbm.interface';
import { pbmListDataMock } from '../../../../../../testing/mocks/pbmListDataMock';
import { AbstractRestService } from '@core/services/abstract-rest.service';
import { pharmacyPlansDataMock } from '../../../../../../testing/mocks/pharmacyPlansDataMock';
import { IPharmacyBenefitPlan } from '../../../manager/components/case/interfaces/insurance-plan.interface';
import { IPSP } from '../interfaces/psp.interface';
import { IPBPQueryParams } from '../interfaces/pbp-query-params.interface';

export const DEFAULT_QUERY: ITableQueryParams = {
  search: '',
};

export const DEFAULT_PBP: IPBPQueryParams = {
  isPBM: false,
};

@Injectable({
  providedIn: 'root',
})
export class PharmacyPlanManagementService extends AbstractRestService {
  currentPharmacyPlan$: BehaviorSubject<IPharmacyBenefitPlan> = new BehaviorSubject<IPharmacyBenefitPlan>({} as IPharmacyBenefitPlan);

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  getPharmacyPlansList(query: IPBPQueryParams = DEFAULT_PBP): Observable<any> {
    this.url = this.apiUrl + `/PharmacyBenefitPlans/Search`;
    query.take = query.take || 100000;

    return this.postItem<any, IResponse>(query).pipe(
      map((response: IResponse) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  getPharmacyPlanById(id: number): Observable<IPharmacyBenefitPlan> {
    this.url = this.apiUrl + `/PharmacyBenefitPlans?id=${id}`;

    return this.get<IResponse>().pipe(
      map((response: IResponse) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getPSPList(query: ITableQueryParams = DEFAULT_QUERY): Observable<IPSP[]> {
    this.url = this.apiUrl + '/SpecialtyPharmacies';
    query.take = query.take || 100000;

    return this.postItem<any, IResponse>(query).pipe(
      map((response: IResponse) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  createPharmacyPlan(plan: IPharmacyBenefitPlan): Observable<any> {
    this.url = this.apiUrl + `/PharmacyBenefitPlans`;

    return this.postItem<IPharmacyBenefitPlan, IResponse>(plan);
  }

  editPharmacyPlan(plan: IPharmacyBenefitPlan): Observable<any> {
    this.url = this.apiUrl + `/PharmacyBenefitPlans`;

    return this.putItem<IPharmacyBenefitPlan, IResponse>(plan);
  }
}
