<div mat-dialog-title>
  <h1><img src="assets/icons/icon-warning.svg" alt="warning"> Uncertain Info</h1>
  <button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content [formGroup]="uncertainForm" class="dialog-content">
  <h2>Why is this info uncertain?</h2>
  <div class="checkbox-item">
    <mat-checkbox formControlName="blurryPage">Blurry page</mat-checkbox>
  </div>
  <div class="checkbox-item">
    <mat-checkbox formControlName="illegibleWriting">Illegible writing</mat-checkbox>
  </div>
  <div class="checkbox-item">
    <mat-checkbox formControlName="other">Other</mat-checkbox>
  </div>
  <div class="input-item" *ngIf="uncertainForm.value.other">
    <input name="otherText" id="otherText" maxlength="255" formControlName="otherText"/>
  </div>
</div>

<div mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    (click)="uncertainImage()"
    [disabled]="
            uncertainForm.value.blurryPage ||
            uncertainForm.value.illegibleWriting ||
            (uncertainForm.value.other && uncertainForm.value.otherText.trim().length)
                ? false
                : true
        "
  >
    Escalate to Supervisor
    <mat-icon>east</mat-icon>
  </button>
</div>
