export enum DocumentTypes {
  prescription = 1,
  enrollmentForm = 2,
  papForm = 3,
  insuranceCard = 4,
  proofOfIncome = 5,
  other = 6,
  HIPAAForm = 7,
  mergedDocument = 8
}

export class DocumentTypeName {
  public static readonly [DocumentTypes.enrollmentForm] = 'Enrollment Form';
  public static readonly [DocumentTypes.papForm] = 'PAP Form';
  public static readonly [DocumentTypes.prescription] = 'Prescription';
  public static readonly [DocumentTypes.insuranceCard] = 'Insurance Card';
  public static readonly [DocumentTypes.proofOfIncome] = 'Proof of Income';
  public static readonly [DocumentTypes.other] = 'Other';
  public static readonly [DocumentTypes.HIPAAForm] = 'HIPAA Form';
  public static readonly [DocumentTypes.mergedDocument] = 'Merged Attachment';
}
