import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractRestService } from '@core/services/abstract-rest.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UsersService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getPhotoById(userId: number){
    this.url = this.apiUrl + '/Users/GetImageByUserId/' + userId;

    return this.getPicture().pipe(
      map((response) => {
        return this.blobToBase64(response);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  private blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
