<div class="top-block">
  <b>DUE {{ date | date: headerData.dateFormat }}</b>

  <h2>
    <span *ngIf="headerData.prefix">{{headerData.prefix}}</span>
    {{taskName}}
  </h2>

  <div class="description">
    <span class="attempt" *ngIf="attemptNumber > 1">attempt {{ attemptNumber }}</span>
    <p class="description-text" [innerHtml]="description"></p>
  </div>

  <div class="note" *ngIf="selectedTask.noteForAgent">
    <span class="note-header">Note from Supervisor: {{selectedTask.updatedBy | fullName : false}}</span>
    <p class="note-text">{{selectedTask.noteForAgent}}</p>
  </div>

  <div class="note" *ngIf="selectedTask.reason">
    <span class="note-header">Reason</span>
    <p class="note-text">{{selectedTask.reason}}</p>
  </div>

  <div class="buttons-block">
    <button class="collapse" (click)="minimizeTask.emit(true)">
      <mat-icon>remove</mat-icon>
    </button>
    
    <button class="close" (click)="setSelectedTask.emit(null)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
