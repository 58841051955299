import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OktaAuthService } from '@okta/okta-angular';
import { Roles } from '@shared/enums/roles.enum';

@Injectable({ providedIn: 'root' })
export class AccessService { 
  constructor(
    private oktaAuth: OktaAuthService,
    private store: Store<any>,
  ) { }

  public async userHasRole(...roles: Roles[]): Promise<boolean> { 
    const isAuthenticated = await this.oktaAuth.isAuthenticated();

    if (!isAuthenticated) return false;

    let user = undefined;

    this.store.select('user').subscribe(u => user = u);

    if (!user) return false;

    return roles.indexOf(user.role) >= 0;
  }
}
