import { Component } from '@angular/core';

@Component({
  selector: 'app-widgets-wrapper',
  templateUrl: './widgets-wrapper.component.html',
  styleUrls: ['./widgets-wrapper.component.scss']
})
export class WidgetsWrapperComponent {
  constructor() { }
}
