<div class="dialog">
  <div class="dialog-header">
    <mat-icon class="header-icon">warning</mat-icon>
    <span class="header-text">Uncertain Info</span>
    <mat-icon class="header-close-btn"
              mat-dialog-close>close</mat-icon>
  </div>

  <div class="dialog-content">
    <h2>Why is this info uncertain?</h2>

    <div class="checkbox-item">
       <mat-checkbox *ngFor="let reason of uncertainReasons"
                     [(ngModel)]="reason.isSelected">{{reason.name}}</mat-checkbox>
    </div>
  </div>

  <div class="dialog-footer stick-bottom">
    <button class="submit-btn"
            [disabled]="valuesNotSelected"
            (click)="submit()">
      <span>Escalate to Supervisor</span>
      <mat-icon class="icon">east</mat-icon>
    </button>
  </div>
</div>
