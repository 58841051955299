import { Action } from '@ngrx/store';
import { IDocument } from '../../features/intake/interfaces/document.interface';

export enum DocumentTypes {
  SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST',
  SET_SELECTED_DOCUMENT = 'SET_SELECTED_DOCUMENT',
  SET_GRID = 'SET_GRID',
  SET_QUEUE_TYPE = 'SET_QUEUE_TYPE',
  SET_DOCUMENTS_COUNT = 'SET_DOCUMENTS_COUNT',
  UPDATE_DOCUMENTS_BATCH = 'UPDATE_DOCUMENTS_BATCH',
  RESTORE_DOCUMENTS = 'RESTORE_DOCUMENTS',
  SET_SUPERVISOR_STATE = 'SET_SUPERVISOR_STATE',
  SET_DOCUMENTS_BATCH_DATA = 'SET_DOCUMENTS_BATCH_DATA'
}

export class SetDocumentsList implements Action {
  readonly type = DocumentTypes.SET_DOCUMENTS_LIST;

  constructor(public payload: Array<IDocument>) {
  }
}

export class SetSelectedDocument implements Action {
  readonly type = DocumentTypes.SET_SELECTED_DOCUMENT;

  constructor(public payload: number) {
  }
}

export class SetGrid implements Action {
  readonly type = DocumentTypes.SET_GRID;

  constructor(public payload: boolean) {
  }
}

export class SetDocumentsCount implements Action {
  readonly type = DocumentTypes.SET_DOCUMENTS_COUNT;

  constructor(public payload: number) {
  }
}

export class UpdateDocumentsBatch implements Action {
  readonly type = DocumentTypes.UPDATE_DOCUMENTS_BATCH;

  constructor(public payload: boolean) {
  }
}

export class SetDocumentsBatchData implements Action {
  readonly type = DocumentTypes.SET_DOCUMENTS_BATCH_DATA;

  constructor(public payload: any) {
  }
}

export class RestoreDocuments implements Action {
  readonly type = DocumentTypes.RESTORE_DOCUMENTS;
}

export class SetSupervisorState implements Action {
  readonly type = DocumentTypes.SET_SUPERVISOR_STATE;

  constructor(public payload: any) { }
}

export type DocumentActions =
  | SetDocumentsList
  | SetSelectedDocument
  | SetGrid
  | SetDocumentsCount
  | UpdateDocumentsBatch
  | SetDocumentsBatchData
  | RestoreDocuments
  | SetSupervisorState;
