import { Component, Input } from '@angular/core';
import { IUserQueue } from '../../interfaces/dashboard.interface';
import { QueueType } from '../../../../../../shared/enums/queue-type.enum';
import { getUrlSuffixFromQueueType } from '../../../../../../shared/helpers/utils';

@Component({
  selector: 'app-supervisor-dashboard-queues',
  templateUrl: './queues.component.html',
  styleUrls: ['./queues.component.scss'],
})
export class SupervisorDashboardQueuesComponent {
  @Input() queueList: IUserQueue[];
  @Input() intakeQueueList: IUserQueue[];
  @Input() supervisorQueueList: IUserQueue[];

  getUrlSuffixFromQueueType(queueType: QueueType) {
    return getUrlSuffixFromQueueType(queueType);
  }
}
