import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';
import { ITaskField } from 'src/app/features/manager/components/case/interfaces/task-field.interface';

@Component({
  selector: 'app-task-datepicker-range',
  templateUrl: './task-datepicker-range.component.html',
  styleUrls: ['./task-datepicker-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskDatepickerRangeComponent),
      multi: true,
    }
  ],
})
export class TaskDatepickerRangeComponent extends InputControlAccessorDirective implements OnInit {
  @Input() field: ITaskField;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  dateStart: string;
  dateEnd: string;

  ngOnInit(): void {
    this.value = this.field.value;

    this.dateStart = this.value.dateStart.value;
    this.dateEnd = this.value.dateEnd.value;
  }

  updateDateStart(date: any): void {
    super.value.dateStart = {
      ...super.value.dateStart,
      value: date.value
    };

    super.writeValue(super.value);
    super.notifyValueChange();
  }

  updateDateEnd(date: any): void {
    super.value.dateEnd = {
      ...super.value.dateEnd,
      value: date.value
    };

    super.writeValue(super.value);
    super.notifyValueChange();
  }
}
