import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { caseTimelineDataMock } from '../../../../../../testing/mocks/caseTimelineDataMock';
import { BestMethodToContact } from '@shared/enums/best-method-to-contact.enum';
import { BestTimeToContact } from '@shared/enums/best-time-to-contact.enum';
import { ConsentTypeId } from '@shared/enums/consent-type-id.enum';
import { ConsentTypes } from '@shared/enums/consent-types.enum';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { HIPAAConsent } from '@shared/enums/HIPAA-consent.enum';
import { PatientContactType } from '@shared/enums/patient-contact-type.enum';
import { PatientGender } from '@shared/enums/patient-gender.enum';
import { PhasesNames } from '@shared/enums/phases-names.enum';
import { PlanType } from '@shared/enums/plan-type.enum';
import { Roles } from '@shared/enums/roles.enum';
import { TargetNames } from '@shared/enums/target-names.enum';
import { TaskContactMethodNames } from '@shared/enums/task-contact-method-names.enum';
import { TaskDirectionFullNames } from '@shared/enums/task-direction-full-names.enum';
import { TaskDirectionNames } from '@shared/enums/task-direction-names.enum';
import { IFacility } from '@shared/interfaces/facility.interface';
import { IResponse } from '@shared/interfaces/response.interface';
import { User } from '@shared/models/user.model';
import * as CaseAction from '../../../../store/case/case.actions';
import { Store } from '@ngrx/store';
import { OktaAuthService } from '@okta/okta-angular';
import { Subject, throwError } from 'rxjs';
import { DataEntryFormService } from '../../../intake/components/document/components/data/components/form/services/form.service';
import { CreateActivityDialogComponent } from './components/modals/create-activity-dialog/create-activity-dialog.component';
import { PhysicianEditModalComponent } from './components/modals/physician-edit-modal/physician-edit-modal.component';
import { IConsent } from './interfaces/consent.interface';
import { CaseService } from './services/case.service';
import {
  getDateString,
  getFullAddress,
  getAgeString,
  formatPhone,
  getDateDiff,
  addSuggestedCase,
  interpolateTemplate,
  getConsentValue,
  getDurationDays,
} from 'src/app/shared/helpers/utils';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '@shared/components/search/components/modal/modal.component';
import { PlanOrder } from 'src/app/shared/enums/plan-order.enum';
import { CoverageType } from 'src/app/shared/enums/insurance/coverage-type.enum';
import { DynamicTasksRendererComponent } from './components/dynamic-tasks-renderer/dynamic-tasks-renderer.component';
import { groupBy, minBy } from '@shared/utilities/arrays.utils';
import { WorkflowService } from './services/workflow.service';
import { TaskOutcome } from '@shared/enums/task-outcome.enum';
import { SubmitCloseCaseComponent } from './components/dynamic-tasks-renderer/dialogs/submit-close-case/submit-close-case.component';
import { PhaseConstants } from '@shared/constants/phases.constants';
import { HistoryTimelineStatus } from './enums/history-timeline-status.enum';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss'],
})
export class ManagerDashboardCaseComponent implements OnInit, OnDestroy {
  user = new User();
  currentPhase = '';
  Roles = Roles;
  panel: string;

  case: any;
  caseTimeline = caseTimelineDataMock;
  shownPhase;
  selectedTask = null;
  phaseList = caseTimelineDataMock.map((item) => {
    return {phase: item.phase, id: item.id, disabled: item.disabled};
  });
  activeTasks = [];
  completedTasks = [];
  historyTimeline = [];
  taskId = null;
  nextTaskId = null;
  destroyed = false;

  currentConsents = {
    hipaaConsent: null,
    textingConsent: null,
    programConsent: null,
    marketingConsent: null,
    deviceConsent: null,
  };
  attachments = [];

  onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('dynamicTaskRenderer') dynamicTaskRenderer: DynamicTasksRendererComponent;

  constructor(
    private route: ActivatedRoute,
    private store: Store<any>,
    public oktaAuth: OktaAuthService,
    private router: Router,
    private workflowService: WorkflowService,
    public caseService: CaseService,
    public dialog: MatDialog,
    private dataEntryService: DataEntryFormService,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((params) => {
        if (!params?.id) {
          this.router.navigate(['/']);
        } else {
          this.setSelectedTask(null);
          this.shownPhase = undefined;
          this.currentPhase = undefined;
          this.getCase(params?.id, 'keyDetails');
          this.getCaseAttachments(params?.id);
        }
      });

    this.store.select('user').subscribe((state) => (this.user = state));
    this.caseService.drawerHandler
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.drawer.open());
  }

  ngOnDestroy(): void {
    this.store.dispatch(new CaseAction.RemoveCase());
    this.destroyed = true;
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setDetails(detail): void {
    this.caseService.details$.next(detail ? this.getCaseDetailsObject().find((item) => item.id === detail) : null);
  }

  getCaseAttachments(caseId): void {
    const data = {caseId: +caseId, take: -1};
    this.caseService.getCaseAttachments(data).subscribe(result => {
      this.attachments = result.value;
    }, err => throwError(err));
  }

  updateAttachment(data): void {
    const attachment = this.attachments.find(x => x.id === data.id);
    attachment.thumbnail = data.thumbnail;
  }

  showPhaseHandler(phase): void {
    this.shownPhase = phase;
    this.currentPhase = phase;
  }

  sectionUpdate(data: any, prop: string): void {
    this.case[prop] = data;
    this.caseService.case$.next(this.case);
    this.caseService.updatePatient(this.case)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: IResponse) => {
      }, throwError);
  }

  facilityUpdate(data: IFacility): void {
    this.caseService.case$.next(this.case);
    this.dataEntryService.updateFacility({facility: data})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: IResponse) => {
      }, throwError);
  }

  minimizeTask(data): void {
    this.selectedTask.minimized = data;
    this.shownPhase = data ? this.currentPhase : '';
  }

  openEnrollmentItem(item: string): void {
    this.shownPhase = 'enrollment';
    this.panel = item;
  }

  afterExpandPanels(section: string): void {
    this.panel = section;
  }

  openPhysicianEditDialog(step: string): void {
    this.dialog.open(PhysicianEditModalComponent, {
      data: {
        step,
        case: this.case,
      },
      panelClass: 'selectTypeFormDialog',
    })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((caseId: number) => {
        if (caseId) {
          this.caseService.case$.next(this.case);
          this.getCase(caseId, 'physician');
        }
      });
  }

  createActivityDialog(): void {
    this.dialog.open(CreateActivityDialogComponent, {
      data: this.case,
      panelClass: 'selectTypeFormDialog',
    })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (data) {
          this.getCase(this.case.id);
        }
      });
  }

  setSelectedTask(task): void {
    this.selectedTask = task;

    if (!task) {
      this.taskId = null;
    }
  }

  getCase(id: number, activeTab?: string): void {
    this.caseService.getCaseItem(id)
      .pipe(
        filter(data => !!data),
        take(1),
      )
      .subscribe(
        (response) => {
          if (response.redirect) {
            this.router.navigate(['/']);
          }

          this.case = response;
          addSuggestedCase(this.case);
          this.setCurrentConsents();

          this.caseService.caseDetails$.next({caseDetails: this.getCaseDetailsObject(), activeTab: 'keyDetails'});

          if (activeTab) {
            this.setDetails(activeTab);
            this.caseService.caseDetails$.next({caseDetails: this.getCaseDetailsObject(), activeTab});
          } else if (this.caseService.details$.getValue()?.id) {
            this.setDetails(this.caseService.details$.getValue().id);
          }

          this.activeTasks = this.case.tasks.filter((x) => x.outcome === TaskOutcome.inProgress);
          this.completedTasks = this.case.tasks.filter((x) => x.outcome !== TaskOutcome.inProgress);
          this.completedTasks.sort((a, b) => new Date(a.createDate) < new Date(b.createDate) ? -1 : 1);

          this.initPhases();

          this.checkPhaseEnabled(PhasesNames.priorAuthorization);
          this.checkPhaseEnabled(PhasesNames.fulfillment);

          if (this.taskId && !this.selectedTask?.minimized) {
            const selectedTask = this.activeTasks.find((task) => task.id === this.taskId);
            this.setSelectedTask(selectedTask);
          } else if (this.nextTaskId) {
            const selectedTask = this.activeTasks.find((task) => task.taskId === this.nextTaskId);
            this.setSelectedTask(selectedTask);
          }

          const headerInfo = {
            id: this.case.id,
            patient: `${this.case.patient.firstName} ${this.case.patient.lastName}`,
            queue: null,
            tasks: null,
          };

          if (!this.destroyed) {
            this.store.dispatch(new CaseAction.SetCase(headerInfo));
          }
        },
        (error) => throwError(error)
      );
  }

  checkPhaseEnabled(phaseName): void {
    if (this.case.tasks.some(x => x.phase === phaseName && x.outcome !== null)) {
      const found = this.phaseList.find(x => x.phase === phaseName);
      if (found) {
        found.disabled = false;
      }
    }
  }

  private initPhases(): void {
    this.workflowService.getPhasesEstimate(this.case.id).subscribe(
      res => {
        if (!res.success) {
          return;
        }

        const phases = res.value;
        const taskGroups = groupBy<any, any>(this.case.tasks, e => e.phase);

        this.historyTimeline = phases.map(e => {
          if (e.id === PhaseConstants.EnrollmentId) {
            const enrollmentStart = this.case.enrollmentStartDate;
            const enrollmentEnd = minBy(this.case.history.filter(
              el => el.phaseId !== PhaseConstants.EnrollmentId),
              el => new Date(el.createdDate),
            )?.createdDate;

            const enrollmentSpent = enrollmentStart
              ? getDateDiff(enrollmentEnd || new Date(), new Date(enrollmentStart), 'hours', true)
              : 0;

            return {
              phase: e.name,
              weight: e.weight,
              spent: getDurationDays(enrollmentSpent),
              estimate: getDurationDays(e.estimatedDuration),
              spentTime: enrollmentSpent,
              estimateTime: e.estimatedDuration,
              status: enrollmentEnd && enrollmentStart
                ? HistoryTimelineStatus.Complete
                : enrollmentStart
                  ? HistoryTimelineStatus.Current
                  : HistoryTimelineStatus.Upcoming,
            };
          }

          const spentHours = this.getSpent(taskGroups[e.name] ?? []);

          return {
            weight: e.weight,
            phase: e.name,
            spent: getDurationDays(spentHours),
            status: this.getStatus(taskGroups[e.name]),
            estimate: getDurationDays(e.estimatedDuration),
            spentTime: spentHours,
            estimateTime: e.estimatedDuration,
          };
        });
      },
      err => {
        console.error(err);
      }
    );
  }

  private getSpent(tasks: any[]): number {
    return tasks.reduce((acc, cur) => {
      return acc + getDateDiff(
        cur.completed || new Date(), // If not completed - use today date
        cur.createDate, // when task is created
        'hours', // in hours
        true); // decimal value
    }, 0);
  }

  private getStatus(tasks): string {
    if (!tasks?.length) {
      return HistoryTimelineStatus.Upcoming;
    }

    if (tasks.find(e => e.outcome === 3)) {
      return HistoryTimelineStatus.Current;
    }

    return HistoryTimelineStatus.Complete;
  }

  groupCaseTasks(filterCallback = null, sortCallback = null): object {
    let tasks = [...this.case.tasks];

    if (filterCallback) {
      tasks = tasks.filter(filterCallback);
    }

    if (sortCallback) {
      tasks = tasks.sort(sortCallback);
    }

    const obj = {};

    obj[PhasesNames.enrollment] = [];
    obj[PhasesNames.benefitsVerification] = [];
    obj[PhasesNames.priorAuthorization] = [];
    obj[PhasesNames.fulfillment] = [];

    for (const item of tasks) {
      if (obj[item.phase]) {
        obj[item.phase].push(item);
      }
    }

    return obj;
  }

  getEstimate(phase, groppedTasks): number {
    const reducer = (accumulator, currentValue) => {
      const first = currentValue.createDate;
      const second = currentValue.due;
      const diff = getDateDiff(second, first, 'hours', true);
      return accumulator + diff;
    };

    return groppedTasks[phase].reduce(reducer, 0);
  }

  submitTask(command): void {
    this.caseService.completeTask(command).subscribe(
      (res) => {
        if (!res.success) {
          this.dynamicTaskRenderer?.stopLoading();
          return;
        }

        this.taskId = null;
        this.setSelectedTask(null);
        this.getCase(this.case.id);
      },
      error => {
        console.error(error);
        this.dynamicTaskRenderer?.stopLoading();
      });
  }

  rescheduleTask(command): void {
    this.caseService.rescheduleTask(command).subscribe(
      (res) => {
        if (!res.success) {
          this.dynamicTaskRenderer?.stopLoading();
          return;
        }

        this.taskId = null;
        this.setSelectedTask(null);
        this.getCase(this.case.id);
      },
      error => {
        console.error(error);
        this.dynamicTaskRenderer?.stopLoading();
      });
  }

  escalateToSupervisor(command): void {
    this.caseService.escalateToSupervisor(command).subscribe(
      (res) => {
        if (!res.success) {
          this.dynamicTaskRenderer?.stopLoading();
          return;
        }

        this.taskId = null;
        this.setSelectedTask(null);
        this.getCase(this.case.id);
      },
      error => {
        console.error(error);
        this.dynamicTaskRenderer?.stopLoading();
      });
  }

  closeCase(): void {
    this.dialog.open(SubmitCloseCaseComponent)
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        const command = {
          ...result,
          caseId: this.case.id,
          taskId: this.selectedTask.id,
        };

        this.caseService.closeCase(command)
          .subscribe(
            res => {
              if (!res.success) {
                return;
              }

              this.taskId = null;
              this.setSelectedTask(null);
              this.getCase(this.case.id);
            },
            err => {
              console.error(err);
            });
      });
  }

  getCaseDetailsObject(): Array<any> {
    return [
      this.getKeyDetailsObject(),
      this.getPatientDetailsObject(),
      this.getPayerDetailsObject(),
      this.getPhysicianDetailsObject(),
      this.getPrescriptionsObject(),
      this.getAttachmentsObject(),
      this.getNotesObject(),
    ];
  }

  openModal(): void {
    this.dialog.open(SearchModalComponent, {
      data: {searchValue: ''},
      panelClass: 'searchDialog',
    });
  }

  getKeyDetailsObject(): object {
    const age = getAgeString(this.case.patient.dateOfBirth);

    const dob = this.case.patient.dateOfBirth
      ? `${getDateString(this.case.patient.dateOfBirth, 'M/D/YYYY')} · ${age}`
      : '';

    const hipaaConsent = this.case.patient.currentConsents?.find(x => x.consentTypeId === ConsentTypeId.hipaa);

    return {
      title: 'Key Details',
      id: 'keyDetails',
      icon: 'flag',
      data: [
        {
          title: 'Case Type',
          value: this.case.typeName,
        },
        {
          title: 'Case Status',
          value: this.case.status.name,
        },
        {
          title: 'Substatus',
          value: this.case.subStatus.name,
        },
        {
          title: 'Substatus Reason',
          value: this.case.subStatusReason?.name ?? '--',
        },
        {
          title: 'Patient Id',
          value: this.case.patientId,
        },
        {
          title: 'Case ID',
          value: this.case.id,
        },
        {
          title: 'Patient DOB',
          value: dob,
        },
        {
          title: 'HIPAA Consent',
          value: HIPAAConsent[getConsentValue(hipaaConsent)],
        },
        {
          title: 'Territory',
          value: this.case.territory,
        },
        {
          title: 'HCP',
          value: `${this.case.physician ? (this.case.physician.firstName || '') : ''} ${this.case.physician ? (this.case.physician.lastName || '') : ''}`,
        },
        {
          title: 'Specialty Pharmacy',
          value: `${this.case.specialtyPharmacy?.name ?? ''}`,
        },
        {
          title: 'Enrollment Date',
          value: getDateString(this.case.enrollmentStartDate, 'M/D/YYYY'),
        },
        {
          title: 'Treatment Start',
          value: this.case.threatmentStart,
        },
      ],
    };
  }

  getPatientDetailsObject(): object {
    const patient = this.case.patient;

    const ssn =
      patient.socialSecurityNumber && patient.socialSecurityNumber.length > 4
        ? `${patient.socialSecurityNumber.substring(patient.socialSecurityNumber.length - 4)}`
        : '';

    const email =
      patient.contactInfos && patient.contactInfos.length
        ? patient.contactInfos.find((contact) => contact.contactMethod === ContactMethod.email)?.contactString ??
        null
        : null;

    const phone =
      patient.contactInfos && patient.contactInfos.length
        ? patient.contactInfos.find((contact) => contact.contactMethod === ContactMethod.phone)
        : null;

    const age = getAgeString(patient.dateOfBirth);

    const dob = patient.dateOfBirth
      ? `${getDateString(patient.dateOfBirth, 'M/D/YYYY')} · ${age}`
      : '';
    const hipaaConsent = this.case.patient.currentConsents?.find(x => x.consentTypeId === ConsentTypeId.hipaa);

    return {
      title: 'Patient',
      id: 'patient',
      icon: 'person',
      data: [
        {
          title: 'Patient Id',
          value: patient.id,
        },
        {
          title: 'Name',
          value: `${patient.lastName || ''}, ${patient.firstName || ''}`,
        },
        {
          title: 'Date of Birth',
          value: dob,
        },
        {
          title: 'HIPAA Consent',
          value: HIPAAConsent[getConsentValue(hipaaConsent)]
        },
        {
          title: 'Consent Date',
          value: hipaaConsent?.dateReceived ? getDateString(hipaaConsent.dateReceived, 'M/D/YYYY') : '--',
        },
        {
          title: 'SSN Last 4 Digits',
          value: ssn,
        },
        {
          title: 'Gender',
          value: PatientGender[patient.gender],
        },
        {
          title: 'Address',
          value: getFullAddress(patient.currentMailingAddress?.address),
        },
        {
          title: `Patient Phone ${phone?.contactMethod ? `(${PatientContactType[phone.contactMethod]})` : ''}`,
          value: formatPhone(phone?.contactString),
        },
        {
          title: 'Best Time to Contact',
          value: BestTimeToContact[patient.bestTimeToContact],
        },
        {
          title: 'Best Method to Contact',
          value: BestMethodToContact[patient.bestMethodToContact],
        },
        {
          title: 'Email',
          value: email,
        },
        {
          title: 'Primary Diagnosis',
          value: this.case.diagnosis?.code,
        },
        {
          title: 'Previous Medical History',
          value: '',
        },
      ],
    };
  }

  getPayerDetailsObject(): object {
    const primaryMedicalInsurance = this.getPrimaryMedicalInsurance();

    const data = [
      {
        title: 'Payer Name',
        value: primaryMedicalInsurance?.payerName,
      },
      {
        title: 'Payer Phone',
        value: formatPhone(primaryMedicalInsurance?.payerPhone),
      },
      {
        title: 'Payer Fax',
        value: formatPhone(primaryMedicalInsurance?.payerFax),
      },
      {
        title: 'Plan Name',
        value: primaryMedicalInsurance?.planName,
      },
      {
        title: 'Plan Phone',
        value: formatPhone(primaryMedicalInsurance?.planPhone),
      },
      {
        title: 'Plan Type',
        value: PlanType[primaryMedicalInsurance?.planType],
      },
      {
        title: 'Policy Holder Name',
        value: primaryMedicalInsurance?.policyHolderName,
      },
      {
        title: 'Policy Number',
        value: primaryMedicalInsurance?.planNumber,
      },
      {
        title: 'Group Number',
        value: primaryMedicalInsurance?.groupNumber,
      },
      {
        title: 'Member Id',
        value: primaryMedicalInsurance?.memberId,
      },
      {
        title: 'Pharmacy Benefit Manager (PBM)',
        value: this.case.pharmacyBenefitManager,
      },
      {
        title: 'PBM Phone',
        value: primaryMedicalInsurance?.pbmPhone,
      },
      {
        title: 'PBM Fax',
        value: primaryMedicalInsurance?.pbmFax,
      },
      {
        title: 'PBM Group Number',
        value: primaryMedicalInsurance?.pbmGroupNumber,
      },
      {
        title: 'Bin',
        value: primaryMedicalInsurance?.rxBIN,
      },
      {
        title: 'PCN',
        value: primaryMedicalInsurance?.rxPCN,
      },
    ];

    const emptyData = [
      {
        title: '',
        value: 'Patient does not have insurance.',
      },
    ];

    return {
      title: 'Payer',
      id: 'payer',
      icon: 'shield',
      data: primaryMedicalInsurance?.planId ? data : emptyData,
    };
  }

  getPhysicianDetailsObject(): object {
    const physician = this.case.physician;
    const specialty = physician && physician.taxonomies ? physician.taxonomies.map((x) => x.desc).join(', ') : '';
    const sln = physician && physician.taxonomies ? physician.taxonomies.map((x) => x.license).join(', ') : '';
    const facility = this.case.facility;

    const contact =
      facility && facility.contactInfos && facility.contactInfos.length
        ? facility.contactInfos.find((item) => item.contactMethod === ContactMethod.email) ?? null
        : null;

    const phone =
      facility && facility.contactInfos && facility.contactInfos.length
        ? facility.contactInfos.find((item) => item.contactMethod === ContactMethod.phone) ?? null
        : null;

    const fax =
      facility && facility.contactInfos && facility.contactInfos.length
        ? facility.contactInfos.find((item) => item.contactMethod === ContactMethod.fax) ?? null
        : null;

    return {
      title: 'Physician',
      id: 'physician',
      icon: 'stethoscope',
      data: [
        {
          title: 'HCP Name',
          value: `${physician?.firstName || ''} ${physician?.lastName || ''}`,
        },
        {
          title: 'NPI',
          value: physician?.npi,
        },
        {
          title: 'HCP Specialty',
          value: specialty,
        },
        {
          title: 'DEA',
          value: physician?.dea,
        },
        {
          title: 'SLN',
          value: sln,
        },
        {
          title: 'Office Name',
          value: facility?.name,
        },
        {
          title: 'Office Contact',
          value: contact?.name,
        },
        {
          title: 'Office Contact Emai',
          value: contact?.contactString,
        },
        {
          title: 'Office Address',
          value: getFullAddress(facility?.address),
        },
        {
          title: 'Office Contact Phone',
          value: formatPhone(phone?.contactString),
        },
        {
          title: 'Office Contact Fax',
          value: formatPhone(fax?.contactString),
        },
      ],
    };
  }

  getPrescriptionsObject(): object {
    const prescription = this.case.prescription || {};

    return {
      title: 'Prescription',
      id: 'prescription',
      icon: 'pill',
      data: [
        {
          title: 'Product Name',
          value: prescription.product?.name,
        },
        {
          title: 'Product NDC',
          value: prescription.product?.ndc,
        },
        {
          title: 'Dosage',
          value: prescription.dosage,
        },
        {
          title: 'Quantity',
          value: prescription.quantity,
        },
        {
          title: 'Refills Written',
          value: prescription.refillsNumber,
        },
        {
          title: 'Physician Signature Date',
          value: getDateString(prescription.physicianSignatureDate, 'M/D/YYYY'),
        },
      ],
    };
  }

  getShippingObject(): object {
    return {
      title: 'Payment Details',
      id: 'shipping',
      icon: 'shipping',
      data: [],
    };
  }

  getAttachmentsObject(): object {
    return {
      title: 'Attachments',
      id: 'attachments',
      icon: 'attachment',
      data: [],
    };
  }

  private getNote(task): string {
    const note = task.outcomeNote || task.coversheetNote || task.noteForAgent;

    return note ? note.trim() : undefined;
  }

  getNotesObject(): object {
    const groupped = this.groupCaseTasks(
      (task) => task.completed && !!this.getNote(task),
      (a, b) => a.completed < b.completed
    );

    const notesObject = {
      title: 'Notes',
      id: 'notes',
      icon: 'notes',
      data: {
        count: 0,
        items: []
      },
    };

    for (const key in groupped) {
      if (key && groupped[key]?.length) {
        notesObject.data.items.push({
          phase: key,
          notes: groupped[key].map(item => {
            return {
              ...item,
              task: interpolateTemplate(item.task, {
                reason: item.followUpReason,
                contactMethod: TaskContactMethodNames[item.contactMethod] || '',
                entity: TargetNames[item.target] || '',
                direction: TaskDirectionFullNames[item.direction] || ''
              }),
              icon: this.getNoteIcon(item.contactMethod, item.direction)
            };
          })
        });
        notesObject.data.count += groupped[key].length;
      }
    }

    return notesObject;
  }

  getNoteIcon(method, direction): object {
    const result = {
      value: '',
      isImage: false,
    };

    if (method === ContactMethod.phone) {
      // tslint:disable-next-line
      result.value = direction === TaskDirectionNames['In']
        ? '../../../../assets/icons/icon-phone-incoming.svg'
        : '../../../../assets/icons/icon-phone-outgoing.svg';
      result.isImage = true;
    } else if (method === ContactMethod.fax) {
      result.value = 'print';
    } else if (method === ContactMethod.email) {
      result.value = 'email';
    } else if (method === ContactMethod.text) {
      result.value = 'textsms';
    }

    return result;
  }

  private setCurrentConsents(): void {
    this.case.patient.currentConsents.forEach((el: IConsent) => {
      switch (el.consentTypeId) {
        case ConsentTypes.hipaa:
          this.currentConsents.hipaaConsent = el;
          break;
        case ConsentTypes.program:
          this.currentConsents.programConsent = el;
          break;
        case ConsentTypes.marketing:
          this.currentConsents.marketingConsent = el;
          break;
        case ConsentTypes.texting:
          this.currentConsents.textingConsent = el;
          break;
        case ConsentTypes.device:
          this.currentConsents.deviceConsent = el;
          break;
      }
    });
  }

  private getPrimaryMedicalInsurance(): any {
    const primaryMedicalInsurance = this.case.caseInsurances?.find(x =>
      x.insurancePriorityId === PlanOrder.primary &&
      x.patientInsurance.coverageTypeId === CoverageType.medical
    )
      ?.patientInsurance;

    return primaryMedicalInsurance;
  }
}
