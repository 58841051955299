<div class="queue-wrapper">
  <div *ngIf="!queueList && !intakeQueueList" class="dashboard-loader-container">
    <app-loader></app-loader>
  </div>
  <div *ngIf="queueList || intakeQueueList">
    <h2 *ngIf="intakeQueueList && intakeQueueList.length">
      Intake Queues
    </h2>
    <app-queue-item *ngFor="let queue of intakeQueueList" [data]="queue"
                    [viewRoute]="'queue-' + getUrlSuffixFromQueueType(queue.queueType)"
                    [viewParams]="{ id: queue.id }"
                    [beginRoute]="'document-' + getUrlSuffixFromQueueType(queue.queueType)"
                    [beginParams]="{ queueId: queue.id }">
    </app-queue-item>

    <h2 *ngIf="queueList && queueList.length">
      Patient Management Queues
    </h2>
    <app-queue-item *ngFor="let queue of queueList" [data]="queue" [viewRoute]="'queue-manager-task'"
                  [viewParams]="{ id: queue.id }"
                  [beginRoute]="'case'"
                  [beginParams]="{ id: queue.firstCaseIdInQueue }">
    </app-queue-item>
  </div>
</div>
