import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CoverageType } from '@shared/enums/insurance/coverage-type.enum';
import { phoneValidator } from '@shared/helpers/utils';
import { isBlank } from '@shared/utilities/empty-checks';
import { TabState } from '../../../../../configuration/tab.state';
import { BaseFormComponent } from '../../base-form.component';

@Component({
  selector: 'app-pharmacy-insurance',
  templateUrl: './pharmacy-insurance.component.html',
  styleUrls: ['./pharmacy-insurance.component.scss', '../../../form.component.scss', '../../physician/physician.component.scss', './../insurance.component.scss']
})
export class PharmacyInsuranceComponent {
  isValid = (c) => BaseFormComponent.isControlValid(c);
  isInvalid = (c) => !BaseFormComponent.isControlValid(c);

  @Input() state: TabState;
  @Input() insuranceForm: FormGroup;

  public static getNewForm(field, order): FormGroup {
    return new FormGroup({
      caseInsuranceId: new FormControl(0),
      patientInsuranceId: new FormControl(0),
      pharmacyBenefitPlanId: new FormControl(0),
      planOrder: new FormControl(order),

      externalSourcePharmacyBenefitPlanId: BaseFormComponent.getControl({ field: field.externalSourcePharmacyBenefitPlanId, skipValidation: true }),
      pharmacyInsuranceName: BaseFormComponent.getControl({ field: field.pharmacyInsuranceName, skipValidation: true }),
      phone: BaseFormComponent.getControl({ field: field.phone, skipValidation: true, validators: [phoneValidator] }),
      pharmacyId: BaseFormComponent.getControl({ field: field.pharmacyId, skipValidation: true }),
      BIN: BaseFormComponent.getControl({ field: field.BIN, skipValidation: true }),
      PCN: BaseFormComponent.getControl({ field: field.PCN, skipValidation: true }),
      isPBM: BaseFormComponent.getControl({ field: field.isPBM, skipValidation: true }),
    });
  }

  public static populateForm({ controls }, pharmacyCaseInsurance, jsonData): void {
    if (!pharmacyCaseInsurance && !jsonData) return;

    const jsonInsurance = jsonData?.insurance?.pharmacyBenefitPlans?.[0] || {};
    const patientInsurance = pharmacyCaseInsurance?.patientInsurance || {};
    const benefitPlan = patientInsurance.pharmacyBenefitPlan || {};

    controls.caseInsuranceId.patchValue(pharmacyCaseInsurance?.id || 0);
    controls.patientInsuranceId.patchValue(patientInsurance.id || 0);

    controls.externalSourcePharmacyBenefitPlanId.patchValue(benefitPlan.externalSourcePharmacyBenefitPlanId || jsonInsurance.externalSourcePharmacyBenefitPlanId);
    controls.pharmacyInsuranceName.patchValue(benefitPlan.name || jsonInsurance.name);
    controls.pharmacyId.patchValue(benefitPlan.specialtyPharmacyId || jsonInsurance.pharmacyId);
    controls.pharmacyBenefitPlanId.patchValue(benefitPlan.id);
    controls.phone.patchValue(benefitPlan.phone || jsonInsurance.phone);
    controls.BIN.patchValue(benefitPlan.bin || jsonInsurance.BIN);
    controls.PCN.patchValue(benefitPlan.pcn || jsonInsurance.PCN);
    controls.isPBM.patchValue(benefitPlan.isPBM || jsonInsurance.isPBM);
  }

  public static getInsuranceObject(form: any, { caseId, patientId }, isNew = false) {
    const formData = form instanceof FormGroup
      ? form.getRawValue()
      : (form || {});

    if (!isNew && (!formData.pharmacyId && !formData.pharmacyInsuranceName)) return undefined;

    return {
      id: isNew ? 0 : formData.caseInsuranceId,
      caseId: caseId,
      insurancePriorityId: formData.planOrder,
      patientInsurance: {
        id: isNew ? 0 : formData.patientInsuranceId,
        insurancePriorityId: formData.planOrder,
        coverageTypeId: CoverageType.pharmacy,
        patientId: patientId,
        pharmacyBenefitPlanId: formData.pharmacyBenefitPlanId,
        pharmacyBenefitPlan: {
          pharmacyBenefitPlanId: formData.pharmacyBenefitPlanId,
          isPBM: isBlank(formData.isPBM) ? false : formData.isPBM,
          name: formData.pharmacyInsuranceName,
          phone: formData.phone,
          BIN: formData.BIN,
          PCN: formData.PCN,
          specialtyPharmacyId: isBlank(formData.pharmacyId) ? null : +formData.pharmacyId,
          address: null,
          externalSourcePharmacyBenefitPlanId: formData.externalSourcePharmacyBenefitPlanId
        }
      }
    };
  }
}
