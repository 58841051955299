<div [class]="'queue-item ' + data.queueType | lowercase">
  <div class="text-block">
    <h4>{{ data.queueConfiguration.title || data.queueConfiguration.name }}</h4>
    <p>{{ data.queueConfiguration.description }}</p>
  </div>
  <div class="task-block" *ngIf="(userRole === 'SystemAdmin' || userRole === 'ProgramAdmin') && data.id === 1; else chart">
    <b>{{ data.taskCount }}</b> Tasks
  </div>
  <ng-template #chart>
    <div class="chart-block">
      <canvas baseChart
              [datasets]="chartData"
              [colors]="chartType[trendType]"
              [labels]="lineChartLabels"
              [chartType]="lineChartType"
              [options]="lineChartOptions"
              #queueChart
      >
      </canvas>
      <div [class]="'chart-stats ' + trendType">
        <h6 *ngIf="trendType === 'dec'">
          <mat-icon>arrow_downward</mat-icon>
          Declining
        </h6>
        <h6 *ngIf="trendType === 'inc'">
          <mat-icon>arrow_upward</mat-icon>
          Increasing
        </h6>
        <h6 *ngIf="trendType === 'stable'">
          <mat-icon>arrow_forward</mat-icon>
          Stabilizing
        </h6>
        <p>
          <b>{{ data.taskCount }}</b> Tasks
        </p>
        <p>
          <b>{{ data.assignedUserCount }}</b> Assigned
        </p>
      </div>
    </div>
  </ng-template>
  <div class="button-block">
    <button class="main-button queue-btn"
            [routerLink]="beginRoute ? beginRoute : '/'"
            [queryParams]="beginParams"
            [disabled]="!data.taskCount"
            *ngIf="showBegin(); else viewListButton">
      Begin <img *ngIf="data.taskCount" src="assets/icons/icon-arrow-long-right.svg" alt="begin" />
      <img *ngIf="!data.taskCount" src="assets/icons/icon-arrow-long-right-gray.svg" alt="begin" />
    </button>

    <ng-template #viewListButton>
      <button class="main-button view-list-btn"
              [routerLink]="viewRoute ? viewRoute : '/'"
              [disabled]="!showViewList()"
              [queryParams]="viewParams"
      >
        View List
        <img src="assets/icons/icon-arrow-long-right-white.svg" alt="begin" />
      </button>
    </ng-template>

    <a routerLink="/manage-queue" [queryParams]="{ id: data.queueConfiguration.id }" *ngIf="showManage(); else viewListLink">
      Manage
      <img src="assets/icons/icon-arrow-long-right-blue.svg" alt="view" />
    </a>

    <ng-template #viewListLink>
      <a [routerLink]="viewRoute ? viewRoute : '/'" [queryParams]="viewParams" *ngIf="showViewListLink()">
        View List
        <img src="assets/icons/icon-arrow-long-right-blue.svg" alt="view" />
      </a>
    </ng-template>
  </div>
</div>
