<button class="back-btn" (click)="cancelBatch()">
  <mat-icon>west</mat-icon>
  Back to Queue
</button>

<button class="submit-btn"
        [disabled]="submitIsDisabled"
        (click)="submitBatch.emit()">
  Submit
</button>
