import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { TargetNames } from '@shared/enums/target-names.enum';
import { TaskDirectionNames } from '@shared/enums/task-direction-names.enum';
import { TaskOutcome } from '@shared/enums/task-outcome.enum';
import { get24FromAmpmTime, getDateString, getTimeFormat } from '@shared/helpers/utils';
import { IPhase } from '@shared/interfaces/phase.interface';
import { IReason } from '@shared/interfaces/reason.interface';
import { CaseService } from '../../../services/case.service';
import { PickListService } from '@shared/services/pick-list.service';

@Component({
  selector: 'app-create-activity-dialog',
  templateUrl: './create-activity-dialog.component.html',
  styleUrls: ['./create-activity-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateActivityDialogComponent implements OnInit, OnDestroy {
  contactForm = new FormGroup({
    target: new FormControl('', Validators.required),
    direction: new FormControl('', Validators.required),
    contactMethod: new FormControl('', Validators.required),
    contactDate: new FormControl(new Date(), Validators.required),
    contactTime: new FormControl(getTimeFormat(new Date()), Validators.required),
    outcomeNote: new FormControl(''),
  });

  followupForm = new FormGroup({
    target: new FormControl('', Validators.required),
    associatedPhase: new FormControl('', Validators.required),
    followUpReasonId: new FormControl({value: null, disabled: true}, Validators.required),
    contactMethod: new FormControl('', Validators.required),
    outcomeNote: new FormControl(''),
    followUpDate: new FormControl('', Validators.required),
  });

  switcherControl: FormControl = new FormControl('');
  createBothControl: FormControl = new FormControl({value: null, disabled: true});

  onDestroy$: Subject<void> = new Subject<void>();

  ContactMethod = ContactMethod;
  TargetNames = TargetNames;
  TaskDirectionNames = TaskDirectionNames;

  today = new Date();

  phases: IPhase[] = [];
  reasons: IReason[] = [];

  @ViewChild('switcherType') switcherType;
  @ViewChild('targetActivity') targetActivity;
  @ViewChild('targetFollowup') targetFollowup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialogRef<CreateActivityDialogComponent>,
    public caseService: CaseService,
    public pickListService: PickListService,
  ) {
  }

  ngOnInit(): void {
    this.followupForm.get('associatedPhase').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        if (val) {
          this.phaseChanged(+val);
        }
      });

    this.contactForm.get('contactDate').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        if (val) {
          this.contactForm.get('contactTime').enable();
        } else {
          this.contactForm.get('contactTime').patchValue('');
          this.contactForm.get('contactTime').disable();
        }
      });

    this.contactForm.statusChanges
      .subscribe((val) => {
        val !== 'INVALID' ? this.createBothControl.enable() : this.createBothControl.disable();
      });

    this.switcherControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        if (val === 'contact') {
          setTimeout(() => {
            this.targetActivity.focus();
          });
        } else if (val === 'followup') {
          setTimeout(() => {
            this.targetFollowup.focus();
          });
        }
      });

    this.createBothControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        if (val) {
          this.switcherControl.patchValue('followup');
        }
      });

    this.pickListService.getPhases()
      .subscribe((phases: IPhase[]) => {
        this.phases = phases;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  switchToActivity(): void {
    this.switcherControl.patchValue('contact');
  }

  switchToFollowup(): void {
    this.switcherControl.patchValue('followup');
  }

  submit(): void {
    // TODO: Need to be refactored
    this.dialog.close(true);
  }

  save() {
    const contact = {
      ...this.contactForm.getRawValue(),
      QueueItemId: this.data.id,
      QueueTaskId: 0,
      WorkflowTaskId: 24,
      taskOutcome: TaskOutcome.success,
    };
    const contactTime = get24FromAmpmTime(contact.contactTime).split(':');
    contact.contactDate.setHours(+contactTime[0], +contactTime[1]);
    contact.contactDate = getDateString(contact.contactDate, '');

    const followup = {
      ...this.followupForm.getRawValue(),
      QueueItemId: this.data.id,
      QueueTaskId: 0,
      WorkflowTaskId: 25,
    };
    followup.followUpDate = getDateString(followup.followUpDate, 'YYYY-MM-DD');

    // TODO: Need to be refactored
    // TODO: Its called wrong endpoint
    // if (!this.createBothControl.value) {
    //   return this.caseService.completeCase(this.switcherControl.value === 'contact' ? contact : followup);
    // }

    // return combineLatest([
    //   this.caseService.completeCase(contact),
    //   this.caseService.completeCase(followup),
    // ]);
  }

  phaseChanged(phaseId: number): void {
    this.caseService.getReasons({search: '', skip: 0, phaseId})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((reasons: IReason[]) => {
        this.reasons = reasons;
        if (this.followupForm.get('followUpReasonId').disabled) {
          this.followupForm.get('followUpReasonId').enable();
        } else {
          this.followupForm.get('followUpReasonId').patchValue('');
        }
      });
  }
}
