<div class="page-stepper-container">
  <app-breadcrumbs *ngIf="breadcrumbs.length > 0" [items]="breadcrumbs"></app-breadcrumbs>

  <ng-content></ng-content>

  <div class="controls">
    <div class="queue-stepper">
      <button class="step-button prev" *ngIf="currentPosition >= 0" (click)="back()">
        <img src="assets/icons/icon-step-arrow.svg" alt="back"/>
        <span>Back</span>
      </button>
      <div class="queue-steps">
        <div class="queue-step" *ngFor="let step of stepsItems; index as i"
             [ngClass]="{active: i === currentPosition, done: i < currentPosition}">
          <ng-container *ngIf="i < currentPosition">
            <mat-icon>done</mat-icon>
          </ng-container>
          <ng-container *ngIf="!(i < currentPosition)">{{ i + 1 }}</ng-container>
        </div>
      </div>
      <button class="step-button next" *ngIf="showSkipButton && currentPosition < stepsItems.length - 1"
              (click)="next()">
        <span>Skip</span>
        <img src="assets/icons/icon-step-arrow.svg" alt="skip"/>
      </button>
    </div>

    <div class="fx-row">
      <button #nextBtn type="submit" class="next-button next-stack" (click)="next()"
              *ngIf="showNextButton || currentPosition < stepsItems.length - 1" tabindex="1" [disabled]="disableNext">
        <b>Next:</b> <span>{{ currentStepName$ | async }}</span>
        <mat-icon>arrow_forward</mat-icon>
      </button>
      <button type="submit" (click)="previewSteps()" class="next-button preview"
              *ngIf="showPreview && currentPosition === stepsItems.length - 1">
        <div class="report-save-loader" *ngIf="previewLoading">
          <app-loader [path]="'/assets/images/Mint_Loader.json'"></app-loader>
        </div>
        Preview
      </button>
      <button (click)="complete()" [disabled]="disableComplete" class="next-button complete"
              *ngIf="showComplete || currentPosition === stepsItems.length - 1">
        <div class="report-save-loader" *ngIf="lastStepName === 'Running...'||lastStepName === 'Saving...'">
          <app-loader [path]="'/assets/images/DarkGreen_Loader.json'"></app-loader>
        </div>
        {{lastStepName}}
      </button>
    </div>
  </div>
</div>
