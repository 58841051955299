import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Label, Color } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';
import { queueDataMock } from '../../../../../../../../testing/mocks/queueDataMock';
import { supervisorChartDataMock } from '../../../../../../../../testing/mocks/supervisorChartDataMock';
import { ChartType } from '@shared/enums/chart-type.enum';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { QueueTrendingService } from 'src/app/shared/services/queueTrending.service';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { isIntakeQueue, getUrlSuffixFromQueueType } from 'src/app/shared/helpers/utils';
import { QueueType } from 'src/app/shared/enums/queue-type.enum';

@Component({
  selector: 'app-supervisor-manage-queue-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss'],
})
export class SupervisorQueueTopComponent {
  @Input() activePage: string;
  @Input() name: string;
  @Input() description: string;

  @Input() queueConfigurationId: number;
  @Output() setActivePageHandler = new EventEmitter();
  @Output() infoSaved = new EventEmitter();

  @Input()
  get queueId(): any {
    return this._queueId;
  }

  set queueId(queueId) {
    if (this._queueId !== queueId) {
      this._queueId = queueId;
      this.getTrendingReport();
    }
  }

  @Input() queueType: QueueType;

  isEdit = false;
  queueForm: FormGroup;
  _queueId;

  lineChartLabels: Label[] = [];

  queueData = queueDataMock;
  data: ChartDataSets[] = [
    {
      data: [],
      label: 'Reimbursement',
      pointBackgroundColor: '#fff',
      pointBorderColor: '#9f5242',
      pointBorderWidth: 2,
      radius: 5,
      hoverRadius: 8,
      pointHoverBackgroundColor: '#5b2f26',
      pointHoverBorderColor: '#fff',
      lineTension: 0
    },
  ];

  chartColor: Color[] = [];

  lineChartType = 'line';

  @ViewChild('queueChart') queueChart;

  constructor(
    private queueTrendingService: QueueTrendingService,
    private featureService: FeatureService,
    private router: Router,
  ) {
  }

  setActivePage(page: string): void {
    this.setActivePageHandler.emit(page);
  }

  viewList(): void {
    if (this.queueType === QueueType.supervisor) {
      this.router.navigate(['queue-supervisor-task', this.queueId]);  
    } else if (this.queueType != QueueType.caseManagement) {
      this.router.navigate(['queue-' + getUrlSuffixFromQueueType(this.queueType)], {
        queryParams: {
          id: this.queueId,
        }
      });
    } else {
      this.router.navigate(['queue-manager-task'], {
        queryParams: {
          id: this.queueId,
        }
      });
    }
  }

  toggleEdit(): void {
    this.isEdit = !this.isEdit;
    if (this.isEdit) {
      this.queueForm = new FormGroup({
        name: new FormControl(this.name, Validators.required),
        description: new FormControl(this.description, Validators.required)
      });
    }
  }

  saveData(): void {
    this.name = this.queueForm.value.name;
    this.description = this.queueForm.value.description;
    const model = {
      name: this.name,
      description: this.description
    };
    this.infoSaved.emit(model);
    this.toggleEdit();
  }

  isIntakeQueue(): boolean {
    return isIntakeQueue(this.queueType);
  }

  customTooltips = (tooltip) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      this.queueChart?.nativeElement?.parentNode.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0 as any;
      return;
    }

    function getBody(bodyItem): any {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map(getBody);
      let innerHtml = '';
      bodyLines.forEach((body, i) => {
        const index = tooltip.dataPoints[0].index;
        innerHtml += '<div>' + '<b>' + (this.data[0].data[index] as any)?.itemsCount + '</b> Tasks' + '</div>';
        innerHtml += '<div>' + '<b>' + (this.data[0].data[index] as any)?.usersCount + '</b> CMs' + '</div>';
      });
      tooltipEl.innerHTML = innerHtml;
    }
    const positionY = this.queueChart?.nativeElement.offsetTop;
    const positionX = this.queueChart?.nativeElement.offsetLeft;
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1 as any;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  }

  lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
      },
    },
    scales: {
      yAxes: [
        {
          display: false,
          ticks: {
            display: false,
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontFamily: 'ProximaNova',
            fontSize: 13,
            fontColor: '#6c7689',
            callback: (value, index) => {
              const result = [value];
              const currentDate = new Date();
              // tslint:disable-next-line
              const date = this.data[0].data[index]['date'];
              if (date && currentDate.getFullYear() === date.getFullYear()
                && currentDate.getMonth() === date.getMonth()
                && currentDate.getDate() === date.getDate()) {
                result.push('Today');
              }
              return result;
            }
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
      custom: this.customTooltips,
    },
  };

  getTrendingReport(): void {
    if (this.queueId) {
      if (this.featureService.isFeatureEnabled('queueTrendReport')) {
        this.queueTrendingService.getQueueTrends(this.queueId).subscribe((result) => {
          this.chartColor = this.queueTrendingService.getChartColor(this.queueChart, ChartType.increasing);
          const dataPoints = this.queueTrendingService.getDataPoints(result.value);
          this.lineChartLabels = dataPoints.map(x => x.label);
          this.data[0].data = dataPoints;
        }, err => throwError(err));
      } else {
        this.chartColor = this.queueTrendingService.getChartColor(this.queueChart, ChartType.increasing);
        this.lineChartLabels = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su', 'M'];
        this.data[0].data = supervisorChartDataMock;
      }
    }
  }
}
