import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ITaskBlock } from '../../../interfaces/task-block.interface';
import { ITaskBody } from '../../../interfaces/task-body.interface';
import { ITaskField } from '../../../interfaces/task-field.interface';
import { ITaskGroup } from '../../../interfaces/task-group.interface';
import { ITaskSection } from '../../../interfaces/task-section.interface';
import { DynamicTaskControl } from '../classes/dynamic-task.control';
import { ConfigsContract } from '../contract/configs.contract';
import { CustomNumberValidator, CustomValidator } from '../validators/cutom-validator';

@Injectable({ providedIn: 'root', })
export class TasksFormService {

  // Add new validators here they will be automatically added to field if it configured
  private getValidator(field: ITaskField, validatorName: string): any {
    switch(validatorName)
    {
      case 'email' : {
        return Validators.email;
      }

      case 'number' : {
        return CustomNumberValidator(field);
      }

      case 'required' : {
        return CustomValidator(field);
      }
    }
  }

  public getForm(body: ITaskBody): FormGroup {
    return body.groups.reduce((form: FormGroup, group: ITaskGroup, index) => {
      const subForm = this.getGroupForm(group);

      form.addControl(`${ConfigsContract.GroupPrefix}${index}`, subForm);

      return form;
    }, new FormGroup({}));
  }

  private getGroupForm(group: ITaskGroup): FormGroup {
    return group.sections.reduce((form: FormGroup, section: ITaskSection, index) => {
      const subForm = this.getSectionForm(section);

      form.addControl(`${ConfigsContract.SectionPrefix}${index}`, subForm);

      return form;
    }, new FormGroup({}));
  }

  private getSectionForm(section: ITaskSection): FormGroup {
    return section.blocks.reduce((form: FormGroup, block: ITaskBlock, index) => {
      const subForm = this.getBlockForm(block);

      form.addControl(`${ConfigsContract.BlockPrefix}${index}`, subForm);

      return form;
    }, new FormGroup({}));
  }

  private getBlockForm(block: ITaskBlock): FormGroup {
    return block.fields.reduce((form, field: ITaskField) => {
      const value = field.value;
      const validators = this.getValidators(field);
      const control = new DynamicTaskControl(value, validators);

      control.questionId = field.id; // field id must be equal to question Id
      control.fieldType = field.type;

      form.addControl(`${ConfigsContract.ControlPrefix}${field.id}`, control);

      return form;
    }, new FormGroup({}));
  }

  private getValidators(field: ITaskField): any[] {
    return field.validators?.map(key => this.getValidator(field, key));
  }

  public getFlatControls(form: FormGroup): any {
    return Object.keys(form.controls).reduce((acc, key) => {
      if (form.controls[key] instanceof FormGroup) {
        acc.push(...this.getFlatControls(form.controls[key] as FormGroup));
      } else if (form.controls[key] instanceof DynamicTaskControl) {
        acc.push(form.controls[key]);
      }

      return acc;
    }, []);
  }

  public getFlatFields(groups: ITaskGroup[]): ITaskField[] {
    return groups.reduce((acc1, group) => {
      const fields = group.sections.reduce((acc2, section) => {
        const blocks = section.blocks.reduce((acc3, block) => {
          return [...acc3, ...block.fields];
        }, []);
        return [...acc2, ...blocks];
      }, []);
      return [...acc1, ...fields];
    }, []);
  }
}
