<mat-expansion-panel id="CompletionPanel" [expanded]="expanded" (afterExpand)="openPanel(4)"
                     (afterCollapse)="closePanel(4)">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="task-indicator" *ngIf="selectedTask">
        <mat-progress-spinner [diameter]="32" [class]="setIndicatorColor(settings.value)" [value]="settings.value">
        </mat-progress-spinner>
        <mat-icon *ngIf="settings.value === 100">check</mat-icon>
        <span *ngIf="settings.value !== 100">{{ settings.value }}%</span>
      </div>
      Completion of Benefits
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="collapse-section" [formGroup]="completionForm">
    <div id="ReviewSOBPacket" appDetectScrollTop [offsetTop]="210"
         (scrollTopDetected)="currentSection('ReviewSOBPacket')" class="collapse-item">
      <h3>Review Summary of Benefits Packet</h3>
      <div class="review-wrapper">
        <div class="img-block">
          <div class="img-wrapper">
            <a href="https://i.pinimg.com/originals/34/82/45/348245d4932b615b492f58ea003481ae.gif" target="_blank">
              <img src="https://i.pinimg.com/originals/34/82/45/348245d4932b615b492f58ea003481ae.gif" alt="summary"/>
            </a>
            <span>Preview <mat-icon>launch</mat-icon></span>
          </div>
          <p><b>9 pgs.</b>SOB-PtAMD_Case2345.pdf</p>
        </div>
        <div class="img-description">
          <p>If anything is inaccurate, please alter above.</p>
          <p>A new SOB packet will be generated and you will need to close the previous one.</p>
        </div>
      </div>
    </div>
    <div id="CommunicateSOB" appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('CommunicateSOB')"
         class="collapse-item">
      <h3>Communicate SOB</h3>
      <div class="contact-item" *ngIf="contactInfo.physician.fax">
        <button class="communicate-button" [disabled]="completionForm.disabled">
          <mat-icon>print</mat-icon>
          Fax
        </button>
        <b>{{contactInfo.physician.fax}}</b>
        <span>Physician, Dr. {{contactInfo.physician.name | capitalize}}</span>
      </div>
      <div class="contact-item" *ngIf="contactInfo.patient.phone">
        <button class="communicate-button" [disabled]="completionForm.disabled">
          <mat-icon>phone</mat-icon>
          Phone
        </button>
        <b>{{contactInfo.patient.phone}}</b>
        <span>Patient, {{contactInfo.patient.name}}</span>
      </div>
    </div>
    <div id="CoversheetNote" appDetectScrollTop [offsetTop]="210" (scrollTopDetected)="currentSection('CoversheetNote')"
         class="collapse-item" formGroupName="coversheet">
      <h3 style="margin-bottom: 16px">
        <mat-icon>assignment</mat-icon>
        Coversheet Note(s)
      </h3>
      <div class="form-row">
        <div class="input-item">
          <textarea matInput formControlName="coversheetNote"></textarea>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
