import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '@core/configuration/application-config';
import { UncertainDataEntryReasons } from '../../enums/uncertain-data-entry-reasons.enum';

@Component({
  templateUrl: './uncertain-data-entry.component.html',
  styleUrls: ['./uncertain-data-entry.component.scss']
})
export class UncertainDataEntryComponent implements OnInit {
  public readonly uncertainReasons = [
    // Note: Add standard reasons here
  ];

  get valuesNotSelected() { 
    return !this.uncertainReasons.find(e => e.isSelected);
  }

  constructor(private readonly dialogRef: MatDialogRef<UncertainDataEntryComponent>) { }

  ngOnInit(): void {
    this.initDynamicReasons();
  }

  public static HasReasons() { 
    // TODO: If we add standard reasons in future
    // TODO: this method shouldn't be called
    return AppConfig.CanAddNewInsurancePlans;
  }

  private initDynamicReasons() { 
    if (AppConfig.CanAddNewInsurancePlans) {
      this.uncertainReasons.push({
        isSelected: false,
        name: 'Medical Insurance Not Available',
        value: UncertainDataEntryReasons.noMedicalInsurance
      });
    }
  }

  public submit() { 
    const selectedReasons = this.uncertainReasons
      .filter(e => e.isSelected)
      .map(e => e.value);
    
    this.dialogRef.close(selectedReasons);
  }
}
