import { NgModule } from '@angular/core';
import { MouseWheelClickDirective } from './mouse-wheel-click.directive';

@NgModule({
  declarations: [
    MouseWheelClickDirective
  ],
  exports: [
    MouseWheelClickDirective
  ]
})
export class MouseWheelModule {
}
