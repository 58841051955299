import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SupervisorAddTaskColumns } from '../../../../../../shared/enums/supervisor-add-task-columns.enum';
import { enumToArray } from '../../../../../../shared/helpers/utils';
import { MatPaginator } from '@angular/material/paginator';
import { throwError } from 'rxjs';
import { ISupervisorTask } from '../../../../../../shared/interfaces/supervisor-task.interface';
import { QueueCreateService } from '../../services/creation.service';
import { InterpolatedString } from '@shared/utilities/string-interpolation/interpolated-string';
import { MockSelector } from '@shared/utilities/string-interpolation/mock-selector';

@Component({
  selector: 'app-queue-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['../../creation.component.scss', './tasks.component.scss'],
})
export class QueueCreationTasksComponent implements OnInit, AfterViewInit {
  @Input() selectedTasks;
  @Output() setSelectedTasksHandler = new EventEmitter();

  searchValue = '';
  columnsToDisplay = enumToArray(SupervisorAddTaskColumns);
  data: ISupervisorTask[] = [];
  dataSource;
  isLoading = false;
  selectedItem = null;
  tableFocused = false;
  isAllSelected = false;

  settings = {
    itemPerPage: 20,
    paginationPageCount: null,
    activePage: 0,
  };

  @ViewChild('search') search: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (this.tableFocused) {
      this.tableNavigate(event);
    }
  }

  constructor(public queueCreateService: QueueCreateService) {
  }

  ngOnInit(): void {
    this.getTasks();
  }

  ngAfterViewInit(): void {
    this.search.nativeElement.focus();
  }

  setActivePage(index): void {
    this.settings.activePage = index;
    this.paginator.pageIndex = index;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  setSelectedTasks(ids): void {
    this.setSelectedTasksHandler.emit(ids);
    if (ids.length === 0) {
      this.isAllSelected = false;
    }
    if (ids.length === this.data.length) {
      this.isAllSelected = true;
    }
  }

  selectAll(): void {
    this.isAllSelected = !this.isAllSelected;
    const IDs = this.data.map((state) => state.id);
    this.isAllSelected ? this.setSelectedTasks(IDs) : this.setSelectedTasks([]);
  }

  checkTask(id): void {
    if (!this.selectedTasks.includes(id)) {
      this.setSelectedTasks([...this.selectedTasks, id]);
    } else {
      const tasks = this.selectedTasks.filter((checkedId) => checkedId !== id);
      this.setSelectedTasks(tasks);
    }
  }

  getTasks(): void {
    const data = {take: 0, search: this.searchValue};
    this.isLoading = true;
    this.queueCreateService.getTasks(data).subscribe(
      (response) => {
        this.data = response.value.map((task: ISupervisorTask) => {
          return {
            id: task.id,
            phase: task.phase,
            task: task.name,
            description: task.description,
          };
        });

        this.interpolateTasksText(this.data);

        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.settings.paginationPageCount = Math.ceil(
          this.dataSource.filteredData.length / this.settings.itemPerPage
        );
      },
      (error) => throwError(error),
      () => (this.isLoading = false)
    );
  }

  private interpolateTasksText(tasks: any[]) {
    const interpolatedString = new InterpolatedString();

    interpolatedString.setSelector(new MockSelector());

    tasks.forEach(task => {
      task.name = interpolatedString.setInitialString(task.name).toString();
      task.description = interpolatedString.setInitialString(task.description).toString();
    });
  }

  focusTable(event): void {
    this.search.nativeElement.blur();
    this.selectedItem = -1;
    this.tableFocused = true;
  }

  tableNavigate(event): void {
    event.preventDefault();
    if (this.dataSource.filteredData) {
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        this.selectedItem = this.selectedItem + 1;
        if (this.dataSource.filteredData.length <= this.settings.itemPerPage) {
          if (this.selectedItem === this.dataSource.filteredData.length) {
            this.selectedItem = 0;
          }
        } else {
          if (this.selectedItem === this.settings.itemPerPage) {
            if (this.settings.paginationPageCount === this.settings.activePage + 1) {
              this.setActivePage(0);
            } else {
              this.setActivePage(this.settings.activePage + 1);
            }
            this.selectedItem = 0;
          }
        }
      }
      if (event.key === 'ArrowUp') {
        this.selectedItem = this.selectedItem - 1;

        if (this.dataSource.filteredData.length <= this.settings.itemPerPage) {
          if (this.selectedItem === -1) {
            this.selectedItem = this.dataSource.filteredData.length - 1;
          }
        } else {
          if (this.selectedItem === -1) {
            if (this.settings.activePage === 0) {
              this.setActivePage(this.settings.paginationPageCount - 1);
            } else {
              this.setActivePage(this.settings.activePage - 1);
            }
            this.selectedItem = this.settings.itemPerPage - 1;
          }
        }
      }
      if (event.key === 'Enter') {
        const id = this.dataSource._renderData._value[this.selectedItem].id;
        this.checkTask(id);
      }
      if (event.key === 'Backspace') {
        this.selectedItem = null;
        this.tableFocused = false;
        this.search.nativeElement.focus();
      }
    }
  }
}
