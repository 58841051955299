<div mat-dialog-title>
  <div class="header">
    <h1><img src="../../../../../../../../assets/icons/icon-warning.svg" alt="warning"/>Are you sure?</h1>
  </div>
  <button class="close-btn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <p>You’re about to make the following the
    <b>{{data.address.current ? 'Obsolete' : 'Current'}} {{data.address.addressType === AddressType.mailing ?
      'Mailing' : 'Shipping'}} Address</b>
    for patient <b>{{data.patient.firstName}} {{data.patient.lastName}}</b>:
  </p>
</div>

<div mat-dialog-content>
  <div class="dialog-body">
    <p>{{data.address?.address?.streetAddress | empty}}</p>
    <p class="last">{{data.address?.address?.city | empty}}, {{data.address?.address?.zipCode | empty}}</p>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button class="prev" mat-dialog-close
          (mouseenter)="arrowBackBlue.style.display = 'none'; arrowBackWhite.style.display = 'inline-block'"
          (mouseleave)="arrowBackBlue.style.display = 'inline-block'; arrowBackWhite.style.display = 'none'">
    <img #arrowBackBlue src="../../../../../../../../assets/icons/icon-arrow-back.svg" alt="back"/>
    <img #arrowBackWhite style="display: none" src="../../../../../../../../assets/icons/icon-arrow-back-white.svg"
         alt="back"/>
    Go Back
  </button>

  <button mat-button class="next" (click)="dialog.close(true)"
          (mouseenter)="arrowNextBlue.style.display = 'none'; arrowNextWhite.style.display = 'inline-block'"
          (mouseleave)="arrowNextBlue.style.display = 'inline-block'; arrowNextWhite.style.display = 'none'">
    Proceed
    <img #arrowNextBlue src="../../../../../../../../assets/icons/icon-arrow-back.svg" alt="next"/>
    <img #arrowNextWhite style="display: none" src="../../../../../../../../assets/icons/icon-arrow-back-white.svg"
         alt="back"/>
  </button>
</div>
