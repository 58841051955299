import { Injectable } from '@angular/core';

import * as zxcvbn from 'zxcvbn';

@Injectable({providedIn: 'root'})
export class PasswordValidationService{

  private passwordMapping = [
    PasswordStrength.veryWeak,
    PasswordStrength.weak,
    PasswordStrength.medium,
    PasswordStrength.strong,
    PasswordStrength.veryStrong,
  ]

  public validatePassword(password: string): PasswordStrength{
    let result = zxcvbn(password);

    return this.passwordMapping[result.score];
  }
}

export enum PasswordStrength {
  veryWeak = "Very Weak",
  weak = "Weak",
  medium = "Medium",
  strong = "Strong",
  veryStrong = "Very Strong"
}