import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  transform(input: any, content?: string): any {
    if (input === null || input === undefined) {
      return content ?? '--';
    } else {
      const cleaned = ('' + input).replace(/[\s,·]+/g, '');

      if (cleaned === '') {
        return content ?? '--';
      }

      return input;
    }
  }
}
