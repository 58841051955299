import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IItemAdjustmentState } from '../../interfaces/item-adjustment-state.interface';

@Component({
  selector: 'app-queue-adjustment-state',
  templateUrl: './queue-adjustment-state.component.html',
  styleUrls: ['./queue-adjustment-state.component.scss']
})
export class QueueAdjustmentStateComponent implements OnInit {
  @Input() itemState: IItemAdjustmentState;

  @Output() cancel = new EventEmitter();
  @Output() submit = new EventEmitter();

  ngOnInit(): void {
    this.settings = [
      {
        selector: 'task',
        displayName: 'TASK'
      },
      {
        selector: 'territory',
        displayName: 'TERRITORY'
      }
    ];

    // Show program section only if installed more than one
    if (this.itemState.programsCount > 1) { 
      this.settings.unshift({
        selector: 'program',
        displayName: 'PROGRAM'
      });
    }
  }

  public settings;

  public someIsIncorrect() { 
    return this.settings.some(e => !this.itemState[e.selector].isCorrect);
  }
}
