import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Configuration } from '@core/configuration/configuration-constants';
import { BaseErrorModel } from 'src/app/core/models/base-error.model';
import { IUser } from 'src/app/shared/interfaces/user.interface';
import { PasswordStrength, PasswordValidationService } from 'src/app/shared/services/password-validation.service';
import { ResetPasswordModel } from '../../../models/reset-password.model';
import { UserProfileService } from '../../../services/user-profile.service';

@Component({
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent {
  Configuration = Configuration;

  dataForm: FormGroup;

  passwordStrength: PasswordStrength;
  strengths = PasswordStrength;
  errorMessage:string;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private user: IUser,
    private userProfileService: UserProfileService,
    private passwordValidationService: PasswordValidationService,
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.dataForm = this.formBuilder.group({
      oldPassword: new FormControl(undefined, [Validators.required]),
      newPassword: new FormControl(undefined, [Validators.required]),
    });

    this.dataForm.controls.newPassword.valueChanges.subscribe(res => {
      this.validatePassword(res);
    });
  }

  save(){
    const model = this.getModelFromForm();

    if(!model) return;

    if(model.currentPassword === model.newPassword){
      this.errorMessage = "New password cannot be the same as your old password.";
      return;
    }

    this.userProfileService.resetPassword(this.user.id, model).subscribe(
      (res) => {
        this.dialogRef.close(true);
      },
      (error: BaseErrorModel) => {
        switch(error.statusCode)
        {
          case 400: this.errorMessage = "Connection problem."; break;
          case 403: case 500:
            this.errorMessage = "Password is invalid.";
            this.dataForm.controls.oldPassword.setErrors({"Incorrect": true})
            break;
          default: this.errorMessage = "Something went wrong.";
        }
      }
    )
  }

  private getModelFromForm(): ResetPasswordModel{
    return this.dataForm.invalid
    ? undefined
    : {
      currentPassword: this.dataForm.controls.oldPassword.value,
      newPassword: this.dataForm.controls.newPassword.value
    };
  }

  close(){
    this.dialogRef.close(false);
  }

  validatePassword(password) {
    const pattern = Configuration.Password.ValidationPattern;

    if(!password?.length || !pattern.test(password)){
      this.passwordStrength = PasswordStrength.weak;
      return;
    }

    this.passwordStrength = this.passwordValidationService.validatePassword(password);
  }
}
