<div class="login-wrapper">
    <div *ngIf="!islinkExpired && !isPasswordReset">
        <h1 class="password-reset">Password reset?</h1>
        <form class="form" [formGroup]="resetPasswordForm">
            <div class="field-wrapper">
                <label for="password" class="field-caption">New Password</label>
                <div>
                    <input #password type="Your password" formControlName="password" matInput id="password"
                        class="field-input" (keyup)="validatePassword()" />
                    <button class="hide-password-button" mat-icon-button matSuffix
                        (click)="password.type = password.type === 'password' ? 'text' : 'password'">
                        <mat-icon>{{password.type === 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <div class="password-validation-bars">
                        <span class="validation-bar" [ngClass]="{ 
                            'validation-bar-very-weak': passwordStrength === strengths.veryWeak,
                            'validation-bar-weak': passwordStrength === strengths.weak,
                            'validation-bar-medium': passwordStrength === strengths.medium,
                            'validation-bar-strong': (passwordStrength === strengths.strong || passwordStrength === strengths.veryStrong)
                          }"> </span>

                        <span class="validation-bar" [ngClass]="{ 
                            'validation-bar-medium': passwordStrength === strengths.medium,
                            'validation-bar-strong': (passwordStrength === strengths.strong || passwordStrength === strengths.veryStrong)
                          }"> </span>

                        <span class="validation-bar" [ngClass]="{ 
                            'validation-bar-strong': (passwordStrength === strengths.strong || passwordStrength === strengths.veryStrong)
                          }"> </span>

                        <span class="validation-bar" [ngClass]="{ 
                            'validation-bar-strong': (passwordStrength === strengths.veryStrong)
                          }"> </span>
                    </div>

                    <div class="validation-captions">
                        <span class="validation-result" style="width: 305px;">
                            Must be at least 7 characters long, 1 upper case letter, 1 number and 1 spec symbol
                        </span>
                        <span class="validation-result" [ngClass]="{ 
                            'validation-result-very-weak': passwordStrength === strengths.veryWeak,
                            'validation-result-weak': passwordStrength === strengths.weak,
                            'validation-result-medium': passwordStrength === strengths.medium,
                            'validation-result-strong': (passwordStrength === strengths.strong || passwordStrength === strengths.veryStrong)
                          }">{{passwordStrength}}</span>
                    </div>
                </div>
            </div>
            <div class="footer-field">
            </div>
        </form>
        <button [disabled]="canResetPassword()" class="submit-button" (click)="resetPassword()">Reset</button>
    </div>
    <div *ngIf="errorMessage" class="error-container">{{errorMessage}}</div>

    <div class="password-reset-container" *ngIf="!islinkExpired && isPasswordReset">
        <img src="assets/icons/icon-prior-auth.svg" alt="email-sent" />
        <h1 class="password-reseted">Password has been reset</h1>
        <button class="action-button" routerLink="/login">Login</button>
    </div>
    <div class="password-reset-container" *ngIf="islinkExpired">
        <img src="assets/icons/link-expired.svg" alt="email-sent" />
        <h1 class="link-expired">Link has expired</h1>
        <p>Link sent to {{requestedEmail}} has expired</p>
        <button class="action-button" (click)="forgotPassword()">Send again</button>
    </div>
</div>