<div class="input-item field-wrapper select"
     [ngClass]="{'input-error': control.touched && control.invalid}"
>
  <label *ngIf="label" [for]="id" class="field-caption">{{label}}</label>

  <mat-form-field appearance="fill" class="field">
    <mat-select [(ngModel)]="value"
                [placeholder]="placeholder"
                panelClass="dropdown-item dropdown-search-select"
                [id]="id"
                [disabled]="disable"
                (opened)="focusSearchInput()"
                (selectionChange)="updateValue()"
                (closed)="clearSearch()"
                [disableRipple]="true"
                [disableOptionCentering]="true"
    >
      <div *ngIf="!hideSearchField"
           class="input-item option-input search-input"
           [ngClass]="{'input-error': searchControl.touched && searchControl.invalid}"
      >
        <img [hidden]="isSearchFocused" class="icon-search" src="assets/icons/icon-search.svg" alt="search-icon" />
        <img [hidden]="!isSearchFocused" class="icon-search" src="assets/icons/icon-search-active.svg" alt="search-icon" />
        <input [formControl]="searchControl"
               #searchInput
               [placeholder]="searchPlaceholder"
               (focus)="isSearchFocused = true"
               (blur)="isSearchFocused = false"
        />
      </div>

      <div class="options-wrapper">
        <mat-option *ngFor="let option of options" [value]="option.id">
          {{option.name}}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</div>
