import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInOut } from '../../animations/animations';

export enum MessageBarStyle {
  success,
  warning,
  error,
  info,
}

export class MessageBarConfig {
  message: string;
  style: MessageBarStyle;
}

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
  animations: [fadeInOut],
})
export class MessageBarComponent {
  @Input() autoHideTime = 2400; // ms

  @Input() set displayedMessage(value: string) {
    this.displayedMessageInner = value;

    if (this.autoHideTime && value) {
      this.runAutoHide();
    }
  }

  get displayedMessage(): string {
    return this.displayedMessageInner;
  }

  @Input() isError: boolean;

  @Output() hideMessage = new EventEmitter();

  messageBoxStyle = MessageBarStyle;

  private displayedMessageInner = '';
  private timer;

  hide(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => this.hideMessage.emit(), this.autoHideTime);
  }

  hideImmediately(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.hideMessage.emit();
  }

  runAutoHide(): void {
    this.hide();
  }
}
