import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../../../core/services/abstract-rest.service';
import { Observable } from 'rxjs';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { IUpdateData } from '../interfaces/update.interface';

@Injectable({
  providedIn: 'root',
})
export class QueueUpdateService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public updateQueue(data: IUpdateData): Observable<IResponse> {
    this.url = this.apiUrl + `/QueueConfiguration`;
    return this.updateItem<IUpdateData, IResponse>(data);
  }
}
