import { environment } from 'src/environments/environment';

export class AppConfig { 
  public static get Logo() { 
    if (!environment.customer?.logo?.default) return '';

    if (window.innerWidth > 1280 && environment.customer.logo.extra) return `assets/logo/${environment.customer.logo.extra}`;
    if (window.innerWidth > 650 && environment.customer.logo.large) return `assets/logo/${environment.customer.logo.large}`;

    return `assets/logo/${environment.customer.logo.default}`;
  }

  public static get CanAddNewInsurancePlans() { 
    return environment.customer.application.canAddNewInsurancePlans;
  }
}
