import { SupportingDocumentation } from '../enums/supporting-documentation.enum';

export class SupportingDocumentationUtils {
  public static getArray(docObj) {
    if (!docObj) {
      return [];
    }

    const keys = Object.keys(SupportingDocumentation);
    return keys.slice(keys.length / 2).reduce((acc, key) => {
      if(docObj[key]) acc.push(SupportingDocumentation[key]);
      return acc;
    }, []);
  }

  public static getObject(array: SupportingDocumentation[]) {
    if (!Array.isArray(array))
      return null;

    return array.reduce((acc, val) => {
      acc[SupportingDocumentation[val]] = true;
      return acc;
    }, {});
  }
}
