import { MapsAPILoader } from '@agm/core';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressType } from '../../../../../../../../shared/enums/address-type.enum';
import { deepEqual } from '../../../../../../../../shared/helpers/utils';
import { IAddress } from '../../../../interfaces/address.interface';

@Component({
  selector: 'app-new-address-form',
  templateUrl: './new-address-form.component.html',
  styleUrls: ['./new-address-form.component.scss', '../../enrollment-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewAddressFormComponent implements OnInit, AfterViewInit {
  @Input() address: IAddress;

  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  newAddressForm: FormGroup;

  AddressType = AddressType;

  deepEqual = deepEqual;
  initData;

  @ViewChild('addressField') addressField;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.initData = {
      id: this.address ? this.address.id : 0,
      address: this.address ? this.address.address.streetAddress : '',
      streetAddress: this.address ? this.address.address.streetAddress : '',
      zipCode: this.address ? this.address.address.zipCode : '',
      city: this.address ? this.address.address.city : '',
      state: this.address ? this.address.address.state : '',
      addressType: this.address ? this.address.addressType : '',
      current: this.address ? this.address.current : null,
    };

    this.newAddressForm = new FormGroup({
      id: new FormControl(this.initData.id),
      address: new FormControl(this.initData.address?.slice(), [Validators.required]),
      streetAddress: new FormControl(this.initData.streetAddress?.slice(), [Validators.required]),
      zipCode: new FormControl(this.initData.zipCode),
      city: new FormControl(this.initData.city?.slice()),
      state: new FormControl(this.initData.state?.slice()),
      addressType: new FormControl(this.initData.addressType, [Validators.required]),
      current: new FormControl(this.initData.current, [Validators.required]),
    });

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.addressField.nativeElement,
        {
          componentRestrictions: {country: 'US'},
          fields: ['address_components', 'formatted_address']
        });

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        const splittedAddress = place.formatted_address ? place.formatted_address.split(', ') : [];
        const address = splittedAddress.length > 0 ? splittedAddress[0] : '';
        const city = place.address_components ?
          place.address_components.find(x => x.types.includes('locality'))?.short_name : '';
        const state = place.address_components ?
          place.address_components.find(x => x.types.includes('administrative_area_level_1'))?.short_name : '';
        const zipCode = place.address_components ?
          place.address_components.find(x => x.types.includes('postal_code'))?.short_name : '';

        this.newAddressForm.get('address').patchValue(address);
        this.newAddressForm.get('city').patchValue(city);
        this.newAddressForm.get('state').patchValue(state);
        this.newAddressForm.get('zipCode').patchValue(zipCode);

        this.cdr.detectChanges();
      });
    });
  }

  ngAfterViewInit(): void {
    this.addressField.nativeElement.focus();
  }

  save(): void {
    this.submitted.emit(this.newAddressForm.getRawValue());
  }
}
