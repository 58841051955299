import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ReportsService } from '../../../../../reports/services/reports.service';
import { QueueCreateService } from '../../services/creation.service';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-queue-territories',
  templateUrl: './territories.component.html',
  styleUrls: ['../../creation.component.scss', './territories.component.scss'],
})
export class QueueCreationTerritoriesComponent implements OnInit, AfterViewInit {
  @Input() selectedTerritories;
  @Output() setSelectedTerritoriesHandler = new EventEmitter();

  searchValue = '';
  data = [];

  columnsToDisplay = ['name', 'isAdded'];
  showAll = false;
  maxBubblesCount = 20;
  isAllSelected = false;
  isLoading = false;
  dataSource;

  settings = {
    itemPerPage: 20,
    paginationPageCount: null,
    activePage: 0,
  };

  selectedItem = null;
  tableFocused = false;

  @ViewChild('search') search: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (this.tableFocused) {
      this.tableNavigate(event);
    }
  }

  constructor(
    public queueCreateService: QueueCreateService,
    private reportsService: ReportsService,
    ) {
  }

  ngOnInit(): void {
    this.getTerritories();
  }

  ngAfterViewInit(): void {
    this.search.nativeElement.focus();
  }

  getTerritories(): void {
    const data = {take: 0, search: this.searchValue};
    this.isLoading = true;
    this.reportsService.getTerritories(data).subscribe(
      (response) => {
        this.data = response.value.map((territory) => {
          return {
            id: territory.id,
            name: territory.name,
          };
        });

        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.settings.paginationPageCount = Math.ceil(this.dataSource.filteredData.length / this.settings.itemPerPage);
      },
      (error) => throwError(error),
      () => (this.isLoading = false)
    );
  }

  setSelectedTerritories(ids): void {
    this.setSelectedTerritoriesHandler.emit(ids);
    if (ids.length === 0) {
      this.isAllSelected = false;
    }
    if (ids.length === this.data.length) {
      this.isAllSelected = true;
    }
  }

  selectAll(): void {
    this.isAllSelected = !this.isAllSelected;
    const IDs = this.data.map((state) => state.id);
    this.isAllSelected ? this.setSelectedTerritories(IDs) : this.setSelectedTerritories([]);
  }

  checkState(id): void {
    if (!this.selectedTerritories.includes(id)) {
      this.setSelectedTerritories([...this.selectedTerritories, id]);
    } else {
      const territories = this.selectedTerritories.filter((checkedId) => checkedId !== id);
      this.setSelectedTerritories(territories);
    }
  }

  renderState(id): string {
    return this.data.find((state) => state.id === id)?.name;
  }

  setActivePage(index): void {
    this.settings.activePage = index;
    this.paginator.pageIndex = index;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  focusTable(event): void {
    this.search.nativeElement.blur();
    this.selectedItem = -1;
    this.tableFocused = true;
  }

  tableNavigate(event): void {
    event.preventDefault();
    if (this.dataSource.filteredData) {
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        this.selectedItem = this.selectedItem + 1;
        if (this.dataSource.filteredData.length <= this.settings.itemPerPage) {
          if (this.selectedItem === this.dataSource.filteredData.length) {
            this.selectedItem = 0;
          }
        } else {
          if (this.selectedItem === this.settings.itemPerPage) {
            if (this.settings.paginationPageCount === this.settings.activePage + 1) {
              this.setActivePage(0);
            } else {
              this.setActivePage(this.settings.activePage + 1);
            }
            this.selectedItem = 0;
          }
        }
      }
      if (event.key === 'ArrowUp') {
        this.selectedItem = this.selectedItem - 1;

        if (this.dataSource.filteredData.length <= this.settings.itemPerPage) {
          if (this.selectedItem === -1) {
            this.selectedItem = this.dataSource.filteredData.length - 1;
          }
        } else {
          if (this.selectedItem === -1) {
            if (this.settings.activePage === 0) {
              this.setActivePage(this.settings.paginationPageCount - 1);
            } else {
              this.setActivePage(this.settings.activePage - 1);
            }
            this.selectedItem = this.settings.itemPerPage - 1;
          }
        }
      }
      if (event.key === 'Enter') {
        const id = this.dataSource._renderData._value[this.selectedItem].id;
        this.checkState(id);
      }
      if (event.key === 'Backspace') {
        this.selectedItem = null;
        this.tableFocused = false;
        this.search.nativeElement.focus();
      }
    }
  }
}
