import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-document-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss', '../../document.component.scss'],
})
export class DocumentTypeComponent {
  isGrid;
  canDelete;

  constructor(private store: Store<any>) {
    this.store.select('document').subscribe((state) => {
      this.isGrid = state.grid;
      this.canDelete = !state.supervisorView?.isSupervisorView;
    });
  }
}
