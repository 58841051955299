import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/classes/UnsubscribeOnDestroy';
import { CoverageType } from 'src/app/shared/enums/insurance/coverage-type.enum';
import { PlanOrder } from 'src/app/shared/enums/plan-order.enum';
import { BenefitsVerificationTasks } from '@shared/enums/benefits-verification-tasks.enum';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { TaskOutcome } from '@shared/enums/task-outcome.enum';
import { formatPhone, interpolateTemplate, setIndicatorColor } from '@shared/helpers/utils';
import { CaseService } from '../../../../services/case.service';

@Component({
  selector: 'app-task-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['../../task.component.scss', './benefit.component.scss'],
})
export class BenefitTaskComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
  @Input() case;
  @Input() isDisabled;

  @Input()
  get selectedTask(): any {
    return this.selectedTaskInner;
  }

  set selectedTask(task: any) {
    if (this.selectedTaskInner !== task) {
      this.selectedTaskInner = task;
      if (this.selectedTaskInner) {
        this.interpolatedDescription = interpolateTemplate(this.selectedTaskInner.message, this.getDescriptionTemplateObject());
      }
    }
  }

  @Output() minimizeTaskHandler = new EventEmitter();
  @Output() setSelectedTaskHandler = new EventEmitter();
  @Output() rescheduleTaskHandler = new EventEmitter();
  @Output() submitTaskHandler = new EventEmitter();

  bvTaskForm: FormGroup;
  indexExpanded = -1;
  isPharmacyShown = false;
  isCompletionShown = false;
  interpolatedDescription;
  allowAssignSection = true;

  allowAssignSectionTimer = null;

  setIndicatorColor = setIndicatorColor;

  benefitTasks = {
    planInformation: {
      value: 0,
      payerInfo: false,
      planInfo: false,
      coverage: false,
      medicare: false,
      policyLimits: false,
      exclusions: false,
    },
    medicalBB: {
      value: 0,
      policyLimits: false,
      networkSpecialtyPharmacies: false,
      payerLimits: false,
      officeVisit: false,
      authorization: false,
      exclusions: false,
    },
    medicalAOB: {
      value: 0,
      coverage: false,
    },
    pharmacy: {
      value: 0,
      planInfo: false,
      policyLimits: false,
      networkSpecialtyPharmacies: false,
      drugStatus: false,
      authorization: false,
      exclusions: false,
    },
    completion: {
      value: 0,
      coversheet: false,
    },
  };

  selectedTaskInner = null;

  currentSection: string;

  @ViewChild('benefitPlan') benefitPlan;
  @ViewChild('benefitMedicalBB') benefitMedicalBB;
  @ViewChild('benefitMedicalAOB') benefitMedicalAOB;
  @ViewChild('benefitPharmacy') benefitPharmacy;
  @ViewChild('benefitCompletion') benefitCompletion;

  constructor(private caseService: CaseService) {
    super();
    this.bvTaskForm = new FormGroup({
      note: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.interpolatedDescription = interpolateTemplate(this.selectedTask?.message, this.getDescriptionTemplateObject());
    this.isPharmacyShown = !this.selectedTask || this.selectedTask.taskId === BenefitsVerificationTasks.callPBM
      || this.selectedTask.taskId === BenefitsVerificationTasks.sendSummary;
    this.isCompletionShown = !this.selectedTask || this.selectedTask.taskId === BenefitsVerificationTasks.sendSummary;
    this.caseService.case$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((caseValue) => {
        this.case = caseValue;
        this.interpolatedDescription = interpolateTemplate(this.selectedTask?.message, this.getDescriptionTemplateObject());
      });
  }

  ngAfterViewInit(): void {
    this.populateFormFromCase();
  }

  minimizeTask(val: boolean): void {
    this.selectedTask.bvFormData = this.selectedTask.bvFormData || {};
    const form = this.getFormData();
    this.selectedTask.bvFormData.planInfo = form.planInfo;
    this.selectedTask.bvFormData.medicalBBInfo = form.medicalBBInfo;
    this.selectedTask.bvFormData.medicalAOBInfo = form.medicalAOBInfo;
    this.selectedTask.bvFormData.pharmacyInfo = form.pharmacyInfo;
    this.selectedTask.bvFormData.completionInfo = form.completionInfo;
    this.selectedTask.note = this.bvTaskForm.value.note;
    this.minimizeTaskHandler.emit(val);
  }

  setSelectedTask(task): void {
    this.setSelectedTaskHandler.emit(task);
  }

  rescheduleTask(): void {
    const data = {
      attemptNumber: this.selectedTask.attemptNumber,
      task: this.selectedTask.task,
      case: this.case,
      outcomeNote: this.bvTaskForm.value.note
    };
    this.rescheduleTaskHandler.emit(data);
  }

  validateTask(data): void {
    let valid = 0;
    let invalid = 0;
    for (const [key, value] of Object.entries(data.form.controls)) {
      // tslint:disable-next-line
      this.benefitTasks[data.formName][key] = value['valid'];
      // tslint:disable-next-line
      for (const [keyInitial, valueInitial] of Object.entries(value['controls'])) {
        // tslint:disable-next-line
        valueInitial['valid'] ? valid++ : invalid++;
      }
    }
    // tslint:disable-next-line
    this.benefitTasks[data.formName]['value'] = Math.round((valid * 100) / (valid + invalid));
  }

  getFormData(): any {
    return {
      planInfo: this.benefitPlan?.planInformationForm?.getRawValue(),
      medicalBBInfo: this.benefitMedicalBB?.medicalBBForm?.getRawValue(),
      medicalAOBInfo: this.benefitMedicalAOB?.medicalAOBForm?.getRawValue(),
      pharmacyInfo: this.benefitPharmacy?.pharmacyForm?.getRawValue(),
      completionInfo: this.benefitCompletion?.completionForm?.getRawValue()
    };
  }

  saveBenefitTask(): void {
    const data = {
      queueItemId: this.case.id,
      queueTaskId: this.selectedTask.id,
      taskOutcome: TaskOutcome.success,
      benefitVerification: JSON.stringify(this.getFormData()),
      nextTaskId: null,
      outcomeNote: this.bvTaskForm.value.note
    };

    if (this.benefitTasks.planInformation.value === 100 &&
      this.benefitTasks.medicalBB.value === 100 &&
      this.benefitTasks.medicalAOB.value === 100) {
      this.isPharmacyShown = true;
      data.nextTaskId = BenefitsVerificationTasks.callPBM;
    }

    if (this.isPharmacyShown && this.benefitTasks.pharmacy.value === 100) {
      this.isCompletionShown = true;
      data.nextTaskId = BenefitsVerificationTasks.sendSummary;
    }
    if (this.isCompletionShown && this.benefitTasks.completion.value === 100) {
      data.nextTaskId = null;
    }

    this.bvTaskForm.patchValue({
      note: null
    });

    this.submitTaskHandler.emit(data);
  }

  getDescriptionTemplateObject(): any {
    const facilityFax = this.case?.facility && this.case.facility.contactInfos && this.case.facility.contactInfos.length
      ? this.case.facility.contactInfos.find((contact) => contact.contactMethod === ContactMethod.fax)
      : null;
    const facilityName = this.case?.facility?.name;
    const primaryMedicalInsurance = this.case.caseInsurances
      ?.find(x => x.insurancePriorityId === PlanOrder.primary && x.patientInsurance.coverageTypeId === CoverageType.medical)
      ?.patientInsurance;
    const payerName = primaryMedicalInsurance?.payerName;
    const payerPhone = primaryMedicalInsurance?.payerPhone;

    return {
      facilityFax: `<a href="#" class="highlighted">${formatPhone(facilityFax?.contactString)}</a>`,
      facilityName: `<span class="highlighted">${facilityName}</span>`,
      payerName: `<span class="highlighted">${payerName}</span>`,
      payerPhone: `<a href="#" class="highlighted">${formatPhone(payerPhone)}</a>`
    };
  }

  populateFormFromCase(): void {
    setTimeout(() => {
      const parsed = this.case?.benefitVerification ? JSON.parse(this.case.benefitVerification) : null;
      if (this.benefitPlan?.planInformationForm && (this.selectedTask?.bvFormData?.planInfo || parsed?.planInfo)) {
        this.benefitPlan.planInformationForm.patchValue(this.selectedTask?.bvFormData?.planInfo || parsed?.planInfo);
      }
      if (this.benefitMedicalBB?.medicalBBForm && (this.selectedTask?.bvFormData?.medicalBBInfo || parsed?.medicalBBInfo)) {
        this.benefitMedicalBB.medicalBBForm.patchValue(this.selectedTask?.bvFormData?.medicalBBInfo || parsed?.medicalBBInfo);
      }
      if (this.benefitMedicalAOB?.medicalAOBForm && (this.selectedTask?.bvFormData?.medicalAOBInfo || parsed?.medicalAOBInfo)) {
        this.benefitMedicalAOB.medicalAOBForm.patchValue(this.selectedTask?.bvFormData?.medicalAOBInfo || parsed?.medicalAOBInfo);
      }
      if (this.benefitPharmacy?.pharmacyForm && (this.selectedTask?.bvFormData?.pharmacyInfo || parsed?.pharmacyInfo)) {
        this.benefitPharmacy.pharmacyForm.patchValue(this.selectedTask?.bvFormData?.pharmacyInfo || parsed?.pharmacyInfo);
      }
      if (this.benefitCompletion?.completionForm && (this.selectedTask?.bvFormData?.completionInfo || parsed?.completionInfo)) {
        this.benefitCompletion.completionForm.patchValue(this.selectedTask?.bvFormData?.completionInfo || parsed?.completionInfo);
      }
      if (this.selectedTask?.note) {
        this.bvTaskForm.patchValue({
          note: this.selectedTask.note
        });
      }
    });
  }

  openPanel({index, elementId, sectionIndex}): void {
    this.indexExpanded = index === this.indexExpanded ? -1 : index;
    this.scrollToAnchor(elementId, sectionIndex);
  }

  closePanel(index: number): void {
    if (index === this.indexExpanded) {
      this.indexExpanded = -1;
    }
  }

  scrollToAnchor(elementId: string, sectionIndex: number): void {
    this.allowAssignSection = false;
    const expanded = this.indexExpanded === sectionIndex;

    this.indexExpanded = sectionIndex;
    this.currentSection = elementId;

    // waiting for expandEnd of the accordion
    setTimeout(() => {
      document.getElementById(elementId).scrollIntoView({behavior: 'smooth'});

      // allow set section label as current after scrollEnd only
      if (!this.allowAssignSectionTimer) {
        this.allowAssignSectionTimer = setTimeout(() => {
          this.allowAssignSection = true;
          this.allowAssignSectionTimer = null;
        }, 2500);
      }
    }, expanded ? 0 : 500);
  }

  setCurrentAnchor(id: string, sectionIndex: number): void {
    if (this.currentSection !== id && this.indexExpanded === sectionIndex && this.allowAssignSection) {
      this.currentSection = id;
    }
  }
}
