<form novalidate
      *ngIf="cases?.length"
      (keydown.enter)="$event.preventDefault()"
      class="document-sidebar-form">
  <div class="aside-wrapper case-wrapper">
    <h2>{{ title }} Cases</h2>
    <h5>Assign Case</h5>
    <div class="physician-facilities search-form">
      <ul class="physician-results facility-results case-results">
        <div #caseList>
          <li *ngFor="let case of cases; index as i"
              [class.focused]="focusedSearch === i"
              (click)="selectCase(case)"
          >
            <div class="physician-title">
              <h5>
                Case #{{ case.id }}
                <span>&mdash;
                  <!-- {{case.queue}} -->
                  {{case.typeName}}
                </span>
              </h5>
              <span *ngIf="case.mostLikely">
                  <mat-icon>grade</mat-icon>Most Likely
              </span>
            </div>
            <div class="physician-wrapper">
              <div class="physician-item">
                <h6>Case Status</h6>
                <p>{{ case.status | empty }}</p>
              </div>
              <div class="physician-item">
                <h6>Substatus</h6>
                <p>{{ case.subStatus | empty }}</p>
              </div>
              <div class="physician-item">
                <h6>Reason</h6>
                <p>{{ case.subStatusReason | empty }}</p>
              </div>
              <div class="physician-item">
                <h6>HCP</h6>
                <p>
                  {{case.physician | capitalize | empty}}
                </p>
              </div>
              <div class="physician-item">
                <h6>Territory</h6>
                <p>{{ case.territory | empty }}</p>
              </div>
              <div class="physician-item">
                <h6>Enrolled</h6>
                <p>
                  {{case.enrolled | date: "M/d/yyyy" | empty}}
                </p>
              </div>
            </div>
          </li>
        </div>
        <li class="add">
          <button class="primary-btn" type="button" (click)="createCase()" #addButton>
            <mat-icon>add_circle</mat-icon>
            New Case
          </button>
        </li>
      </ul>
    </div>
  </div>
</form>
