import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { getExtension } from '@shared/utilities/file.utils';

@Directive({
  selector: '[appDropFile]'
})
export class DropFileDirective {
  @Output() onFileDropped = new EventEmitter<any>();
  @Output() onError = new EventEmitter<string>();

  @Input() allowedFiles: string[];

  @HostBinding('class.fileover') fileOver: boolean;
  @HostBinding('class.fileerror') fileError: boolean;

  constructor() { }

  @HostListener('dragover', ['$event'])
  whenDragOver(e) {
    e.preventDefault();
    e.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  whenDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  whenDrop(e) {
    e.preventDefault();
    e.stopPropagation();

    this.fileOver = false;
    this.fileError = false;

    let file = this.getFile(e);

    if(!file){
      this.fileError = true;
      this.onError.emit('The file has wrong format!');
      return;
    }

    this.onFileDropped.emit(file);
  }

  private getFile(e){
    let files: any[] = Array.from(e.dataTransfer.files);

    if (!files.length) return undefined; 

    if(!this.allowedFiles) return files[0];

    return files.find(f => this.allowedFiles.some(afe => afe === getExtension(f.name)));
  }
}