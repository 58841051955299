<form class="standard-form new-facility" [formGroup]="newFacilityForm">
  <div class="form-row half-width first">
    <div class="input-item" [ngClass]="{
              'input-error':
                  newFacilityForm.controls['facilityName'].invalid &&
                  (newFacilityForm.controls['facilityName'].touched),
              'input-success':
                  newFacilityForm.controls['facilityName'].valid &&
                  newFacilityForm.controls['facilityName'].touched
            }">
      <label for="facilityName">{{ physicianFormFields.facilityName }}</label>
      <input name="facilityName" id="facilityName" formControlName="facilityName" #newFacilityName/>
    </div>
  </div>
  <div class="form-row half-width first">
    <div class="input-item" [ngClass]="{
              'input-error':
                  newFacilityForm.controls['tax'].invalid &&
                  (newFacilityForm.controls['tax'].touched),
              'input-success': newFacilityForm.controls['tax'].valid && newFacilityForm.controls['tax'].touched
            }">
      <label for="tax">{{ physicianFormFields.tax }}#</label>
      <input name="tax" id="tax" formControlName="tax"/>
    </div>
  </div>
  <div class="form-row address-row half-width">
    <div class="input-item" [ngClass]="{
              'input-success': newFacilityForm.value.address && newFacilityForm.controls['address'].touched
            }">
      <label for="address">{{ physicianFormFields.address }}</label>
      <input name="address" id="address" formControlName="address"/>
    </div>
  </div>
  <div class="form-row half-width">
    <div class="input-item" [ngClass]="{
              'input-error':
                  newFacilityForm.controls['zipCode'].invalid &&
                  (newFacilityForm.controls['zipCode'].touched),
              'input-success':
                  newFacilityForm.controls['zipCode'].valid && newFacilityForm.controls['zipCode'].touched
            }">
      <label for="zipCode">{{ physicianFormFields.zipCode }}</label>
      <input name="zipCode" id="zipCode" formControlName="zipCode"/>
    </div>
  </div>
  <div class="form-row half-width">
    <div class="input-item" [ngClass]="{
              'input-error':
                  newFacilityForm.controls['officePhone'].invalid &&
                  (newFacilityForm.controls['officePhone'].touched),
              'input-success':
                  newFacilityForm.controls['officePhone'].valid &&
                  newFacilityForm.controls['officePhone'].touched
            }">
      <label for="officePhone">{{ physicianFormFields.officePhone }}</label>
      <input name="officePhone" id="officePhone" formControlName="officePhone" mask="(000) 000-0000"
             placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
    </div>
  </div>
  <div class="form-row half-width">
    <div class="input-item" [ngClass]="{
              'input-error':
                  newFacilityForm.controls['officeFax'].invalid &&
                  (newFacilityForm.controls['officeFax'].touched),
              'input-success':
                  newFacilityForm.controls['officeFax'].valid && newFacilityForm.controls['officeFax'].touched
            }">
      <label for="officeFax">{{ physicianFormFields.officeFax }}</label>
      <input name="officeFax" id="officeFax" formControlName="officeFax" mask="(000) 000-0000"
             placeholder="(_ _ _) _ _ _ - _ _ _ _"/>
    </div>
  </div>
  <div class="form-row half-width">
    <div class="input-item">
      <label for="officeName">{{ physicianFormFields.officeName }}</label>
      <input name="officeName" id="officeName" formControlName="officeName"/>
    </div>
  </div>
  <div class="form-row half-width">
    <div class="input-item">
      <label for="officeEmail">{{ physicianFormFields.officeEmail }}</label>
      <input name="officeEmail" id="officeEmail" formControlName="officeEmail"/>
    </div>
  </div>
  <div class="form-row controls">
    <button (click)="cancelEvent()"
            class="cancel-btn"
            mat-button>
      Cancel
    </button>
    <button class="save-btn"
            [disabled]="newFacilityForm.invalid"
            (click)="create.emit(newFacilityForm)"
            mat-button>
      Save
    </button>
  </div>
</form>
