import { DefaultTabOrdering } from '../parameters/default-tab-ordering';
import { FieldsConfig } from './fields-default.config';
import { TabId } from '../parameters/tab-identifier';
import { BaseConfig } from './base.config';

export class PhysicianConfig extends BaseConfig {
  constructor({ 
    ordering = DefaultTabOrdering[TabId.physician],
    displayName = 'Physician',
    nextTabName = undefined }) {
    super(TabId.physician, ordering, displayName, nextTabName);
  }

  public fieldsConfig = 
  {
    useNPISearch: true,

    hasPhysician: new FieldsConfig({displayName: 'Physician'}),
    facilityName: new FieldsConfig({displayName: 'Facility Name'}),
    tax: new FieldsConfig({displayName: 'Group Tax ID'}),
    address: new FieldsConfig({displayName: 'Address'}),
    zipCode: new FieldsConfig({displayName: 'ZIP Code'}),
    officeName: new FieldsConfig({displayName: 'Office Contact Name'}),
    officeEmail: new FieldsConfig({displayName: 'Contact Email'}),
    officePhone: new FieldsConfig({displayName: 'Office Phone'}),
    officeFax: new FieldsConfig({displayName: 'Office Fax'}),
    npi: new FieldsConfig({displayName: 'NPI #'}),
    physicianName: new FieldsConfig({displayName: 'Physician Name'}),
    city: new FieldsConfig({displayName: 'City'}),
    phone: new FieldsConfig({displayName: 'Phone'}),
    state: new FieldsConfig({displayName: 'State'}),
    specialty: new FieldsConfig({displayName: 'Physician Specialty'}),
    bestMethodToContact: new FieldsConfig({ displayName: 'Preferred Contact Method'}),
  }
}
