import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { queueDataMock } from '../../../../testing/mocks/queueDataMock';
import { AbstractRestService } from '@core/services/abstract-rest.service';
import { IUserQueue } from '../../features/intake/interfaces/user-queue.interface';
import { IResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class CaseManagementService extends AbstractRestService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getIntakeQueues(programId: number = 0): Observable<IUserQueue[]> {
    this.url = this.apiUrl + '/IntakeQueues/' + programId;
    return this.getItems<IResponse>('').pipe(
      map((response) => {
        response.value.map((queue) => {
          return (queue.queueConfiguration = {
            ...queue.queueConfiguration,
            chartData: [{data: queueDataMock.new.data, label: 'Identify'}],
            type: 'inc',
            documentType: 'identify',
          });
        });
        return response.value.sort((e1, e2) => e1.id - e2.id);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getCaseManagementQueues(programId: number = 0): Observable<IUserQueue[]> {
    this.url = this.apiUrl + '/CaseManagementQueues/' + programId;
    return this.getItems<IResponse>('').pipe(
      map((response) => {
        response.value.map((queue) => {
          return (queue.queueConfiguration = {
            ...queue.queueConfiguration,
            chartData: [{data: queueDataMock.new.data, label: 'Identify'}],
            type: 'inc',
            documentType: 'identify',
          });
        });
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
