import { ColumnFilterEnum } from '../interfaces/column-filter.enum';
import { IReportSection } from '../interfaces/report-section.interface';

export const ReportSections: IReportSection[] = [
  {
    id: 0,
    name: 'Case',
    columns: [
      {
        id: 14,
        key: 'CaseId',
        name: 'Case Id',
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 15,
        key: 'Disposition',
        name: 'Case Disposition',
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 16,
        key: 'PhaseName',
        name: 'Current Phase', // (Enrollment, Benefit Verification, Prior Authorization, Fulfillment)
        filterType: ColumnFilterEnum.Switcher,
        permanent: true,
      },
      {
        id: 17,
        key: 'Type',
        name: 'Case Type', // (Reimbursement, PAP, Copay, etc.)
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 18,
        key: 'Status',
        name: 'Case Status',
        filterType: ColumnFilterEnum.Switcher,
        permanent: true,
      },
      {
        id: 19,
        key: 'SubStatus',
        name: 'Case SubStatus',
        filterType: ColumnFilterEnum.Switcher,
        permanent: true,
      },
      {
        id: 20,
        key: 'SubStatusReason',
        name: 'Case SubStatus Reason',
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 21,
        key: 'Territory',
        name: 'Territory',
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 22,
        key: 'CreateDate',
        name: 'Case Create Date',
        filterType: ColumnFilterEnum.DateRange,
      },
    ],
  },
  {
    id: 1,
    name: 'Patient',
    columns: [
      {
        id: 0,
        key: 'PatientId',
        name: 'Patient Id',
        description: '<[Provide descriptions for columns]> ???',
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 1,
        key: 'PatientLastName',
        name: 'Patient Last Name',
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 2,
        key: 'PatientFirstName',
        name: 'Patient First Name',
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 3,
        key: 'PatientDateOfBirth',
        name: 'Patient Date of Birth',
        filterType: ColumnFilterEnum.DateRange,
      },
      {
        id: 4,
        key: 'PatientHipaaConsentIsOnFile',
        name: 'HIPAA Consent on File', // (yes/no)
        filterType: ColumnFilterEnum.Switcher,
      },
      {
        id: 5,
        key: 'PatientHipaaDateReceived',
        name: 'HIPAA Consent Date',
        filterType: ColumnFilterEnum.DateRange,
      },
      {
        id: 6,
        key: 'PatientSocialSecurityNumber',
        name: 'Last 4 igits of SSN',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 7,
        key: 'PatientGenderString',
        name: 'Gender',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 8,
        key: 'PatientCurrentMailingAddressString',
        name: 'Patient Address', // (or could split into all fields i.e. address line 1, address line 2, city, state, zip)
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 9,
        key: 'PatientPhone',
        name: 'Patient Phone',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 10,
        key: 'PatientPhoneTypeString',
        name: 'Patient Phone Type', // (Home|Work|Mobile)
        filterType: ColumnFilterEnum.Switcher,
      },
      {
        id: 11,
        key: 'PatientEmail',
        name: 'Patient Email',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 12,
        key: 'PatientBestTimeToContactString',
        name: 'Best Time to Contact',
        filterType: ColumnFilterEnum.DateRange,
      },
      {
        id: 13,
        key: 'PatientBestMethodToContactString',
        name: 'Best Method to Contact',
        filterType: ColumnFilterEnum.Options,
      },
    ]
  },
  {
    id: 2,
    name: 'Key Milestones',
    columns: [
      {
        id: 23,
        key: 'EnrollmentFormReceiptDate',
        name: 'Enrollment Form Receipt Date',
        filterType: ColumnFilterEnum.DateRange,
      },
      {
        id: 24,
        key: 'EnrollmentDate',
        name: 'Enrollment Date',
        filterType: ColumnFilterEnum.DateRange,
      },
      {
        id: 25,
        key: 'BenefitVerificationCompleteDate',
        name: 'Benefit Verification Complete Date',
        filterType: ColumnFilterEnum.DateRange,
      },
      {
        id: 26,
        key: 'PACompleteDate',
        name: 'Prior Auth Complete Date',
        filterType: ColumnFilterEnum.DateRange,
      },
      {
        id: 27,
        key: 'TriageToSPCompleteDate',
        name: 'Triage to Specialty Pharmacy Complete Date',
        filterType: ColumnFilterEnum.DateRange,
      },
      {
        id: 28,
        key: 'FirstShipDate',
        name: 'First Ship Date',
        filterType: ColumnFilterEnum.DateRange,
      },
      {
        id: 29,
        key: 'NurseVisitCompleteDate',
        name: 'Nurse Visit Complete Date',
        filterType: ColumnFilterEnum.DateRange,
      },
    ],
  },
  {
    id: 3,
    name: 'Task Completion Dates',
    columns: [
      {
        id: 30,
        key: 'WelcomeCallToPatientDate',
        name: 'Welcome Call to Patient',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 31,
        key: 'BVCallPayerDate',
        name: 'Benefit Verification Call payer',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 32,
        key: 'BVCallPBMDate',
        name: 'Benefit Verification Call the Pharmacy Benefit Manager',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 33,
        key: 'BVSendSOBToPhysicianDate',
        name: 'Benefit Verification Send SOB to Physician',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 34,
        key: 'BVFollowupWithHCPDate',
        name: 'Benefit Verification Followup with HCP',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 35,
        key: 'PAFollowupWithHCPDate',
        name: 'Prior Auth Followup with HCP',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 36,
        key: 'PACallPayerDate',
        name: 'Prior Auth Call Payer',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 37,
        key: 'TriageToSPDate',
        name: 'Triage to Specialty Pharmacy',
        filterType: ColumnFilterEnum.Options,
      },
    ],
  },
  {
    id: 4,
    name: 'Physician',
    columns: [
      {
        id: 38,
        key: 'PhysicianId',
        name: 'HCP Id',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 39,
        key: 'PhysicianLastName',
        name: 'Physician Last Name',
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 40,
        key: 'PhysicianFirstName',
        name: 'Physician First Name',
        filterType: ColumnFilterEnum.Options,
        permanent: true,
      },
      {
        id: 41,
        key: 'PhysicianNPI',
        name: 'NPI #',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 44,
        key: 'PhysicianOffice',
        name: 'Physician Office',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 45,
        key: 'FacilityName',
        name: 'Office Name',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 46,
        key: 'FacilityAddressString',
        name: 'Office Address',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 47,
        key: 'FacilityPhone',
        name: 'Office Phone',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 48,
        key: 'FacilityFax',
        name: 'Office Fax',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 49,
        key: 'FacilityContactName',
        name: 'Office Contact Name',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 50,
        key: 'FacilityEmail',
        name: 'Office Contact Email',
        filterType: ColumnFilterEnum.Options,
      },
    ],
  },
  {
    id: 5,
    name: 'Payer',
    columns: [
      {
        id: 51,
        key: 'PayerName',
        name: 'Payer Name',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 52,
        key: 'PayerPhone',
        name: 'Payer Phone',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 53,
        key: 'PayerFax',
        name: 'Payer Fax',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 54,
        key: 'PlanName',
        name: 'Plan Name',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 55,
        key: 'PlanTypeString',
        name: 'Plan Type',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 56,
        key: 'PolicyHolderName',
        name: 'Policy Holder Name',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 57,
        key: 'PlanNumber',
        name: 'Policy Number',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 58,
        key: 'GroupNumber',
        name: 'Group Number',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 59,
        key: 'PharmacyBenefitManager',
        name: 'Pharmacy Benefit Manager (PBM)',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 64,
        key: 'RxBIN',
        name: 'BIN',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 65,
        key: 'RxPCN',
        name: 'PCN',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 66,
        key: 'PayerCovered',
        name: 'Payer Covered', // (yes/no/unknown)
        filterType: ColumnFilterEnum.Switcher,
      },
      {
        id: 67,
        key: 'PARequired',
        name: 'PA Required', // (yes/no/unknown)
        filterType: ColumnFilterEnum.Switcher,
      },
    ],
  },
  {
    id: 6,
    name: 'Prescription',
    columns: [
      {
        id: 68,
        key: 'ProductName',
        name: 'Product Name',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 69,
        key: 'ProductNDC',
        name: 'Product NDC',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 70,
        key: 'Dosage',
        name: 'Dosage',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 71,
        key: 'Quantity',
        name: 'Quantity',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 75,
        key: 'PhysicianSignature',
        name: 'Prescription Signed',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 76,
        key: 'PhysicianSignatureDate',
        name: 'Prescription Signature Date',
        filterType: ColumnFilterEnum.DateRange,
      }
    ],
  },
  {
    id: 7,
    name: 'Specialty Pharmacy',
    columns: [
      {
        id: 78,
        key: 'SpecialtyPharmacyName',
        name: 'Current Specialty Pharmacy Name',
        filterType: ColumnFilterEnum.Options,
      },
      {
        id: 79,
        key: 'SpecialtyPharmacyStatus',
        name: 'Specialty Pharmacy Status',
        filterType: ColumnFilterEnum.Switcher,
      },
      {
        id: 80,
        key: 'SpecialtyPharmacySubStatus',
        name: 'Specialty Pharmacy SubStatus',
        filterType: ColumnFilterEnum.Switcher,
      },
      {
        id: 81,
        key: 'ShipDate',
        name: 'Last Ship Date',
        filterType: ColumnFilterEnum.DateRange,
      }
    ],
  },
];
