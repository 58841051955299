import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UncertainReason } from '../../../../shared/enums/uncertain-reason.enum';
import { DocumentService } from '../../services/document.service';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import * as DocumentAction from '../../../../store/document/document.actions';
import { IDocument } from '../../interfaces/document.interface';

@Component({
  selector: 'app-uncertain-document',
  templateUrl: './uncertain-identification.component.html',
  styleUrls: ['./uncertain-identification.component.scss'],
})
export class UncertainDocumentModalComponent {
  uncertainForm: FormGroup;
  imageID: number;
  uncertainReason = UncertainReason;
  documentList: IDocument[];
  selectedDocument: number;

  constructor(private store: Store<any>, public documentService: DocumentService) {
    this.uncertainForm = new FormGroup({
      blurryPage: new FormControl(false),
      illegibleWriting: new FormControl(false),
      other: new FormControl(false),
      otherText: new FormControl(''),
    });

    this.store.select('document').subscribe((state) => {
      this.imageID = state.documentList[state.selectedDocument].id;
      this.documentList = state.documentList;
      this.selectedDocument = state.selectedDocument;
    });
  }

  uncertainImage(): void {
    const formValue = this.uncertainForm.getRawValue();

    const reasons = [];
    let otherText = null;

    if(formValue.blurryPage) reasons.push(UncertainReason.blurryPage);
    if(formValue.illegibleWriting) reasons.push(UncertainReason.illegibleWriting);
    if(formValue.other) {
      reasons.push(UncertainReason.other);
      otherText = formValue.otherText;
    }

    const uncertainData = {
      uncertainReasons: reasons,
      uncertainReasonString: otherText,
    };

    this.documentService.uncertainImage(this.imageID, uncertainData).subscribe(
      (response) => {
        const newDocumentList = this.documentList.filter((document) => document.id !== this.imageID);
        const newSelectedDocument =
          this.selectedDocument + 1 === this.documentList.length ? 0 : this.selectedDocument++;

        this.store.dispatch(new DocumentAction.SetSelectedDocument(newSelectedDocument));
        this.store.dispatch(new DocumentAction.SetDocumentsList(newDocumentList));
      },
      (error) => throwError(error)
    );
  }
}
