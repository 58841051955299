// TODO: 1) top navigation 2) page cases http://localhost:4200/case?id=12
export const caseTimelineDataMock = [
  {
    phase: 'Enrollment',
    id: 'enrollment',
    estimate: '2 Days',
    spent: '2 Days',
    status: 'Complete',
    disabled: false
  },
  {
    phase: 'Benefits Verification',
    id: 'benefit',
    estimate: '3 Days',
    spent: '4 Days',
    status: 'Complete',
    disabled: false
  },
  {
    phase: 'Authorization',
    id: 'lock',
    estimate: '2 Days',
    spent: '2 Days',
    status: 'Current',
    disabled: false,
  },
  {
    phase: 'Fulfillment',
    id: 'fulfillment',
    estimate: '2 Days',
    status: 'Upcoming',
    disabled: true
  },
];
