import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataEntryFormService } from '../../services/form.service';
import { TabState } from '../../../../configuration/tab.state';
import { BaseFormComponent } from '../base-form.component';
import { PlanOrder } from 'src/app/shared/enums/plan-order.enum';
import { MedicalInsuranceComponent } from './medical-insurance/medical-insurance.component';
import { PharmacyInsuranceComponent } from './pharmacy-insurance/pharmacy-insurance.component';
import { CoverageType } from '@shared/enums/insurance/coverage-type.enum';
import { groupBy, maxBy } from '@shared/utilities/arrays.utils';
import { Assign } from '@shared/utilities/assign';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-intake-document-insurance-form',
  templateUrl: './insurance.component.html',
  styleUrls: ['../../form.component.scss', '../physician/physician.component.scss', './insurance.component.scss'],
})
export class InsuranceComponent extends BaseFormComponent implements OnInit, OnDestroy {
  PlanOrder = PlanOrder;
  CoverageType = CoverageType;
  
  @Input() public state: TabState;
  @Input('isLoading') public isFormLoading: boolean;

  private _case;
  @Input() set case(newCase: any) { this.setNewCase(newCase); }
           get case(): any { return this._case; }

  @Output() submitForm = new EventEmitter();
  @Output() setPatient = new EventEmitter();
  @Output() updateCase = new EventEmitter();
  @Output() validateForm = new EventEmitter();

  public medicalInsuranceForm: FormGroup;
  public pharmacyInsuranceForm: FormGroup;

  public patientHasInsurance: FormControl;
  public addSecondaryMedicalInsurance: FormControl;

  public get formInvalid() {
    return this.medicalInsuranceForm.invalid || this.pharmacyInsuranceForm.invalid || this.patientHasInsurance.invalid;
  }

  public readonly binaryOptions = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    }
  ];

  public extendedBinaryOptions = [];

  constructor(
    private readonly cdrRef: ChangeDetectorRef,
    private readonly dataEntryService: DataEntryFormService
  ) { super(); }

  private patientInsuranceChanges$: Subscription;
  private addSecondaryMedicalInsuranceChanges$: Subscription;

  public get hasInsurance() { 
    return (this.patientHasInsurance.value !== undefined && this.state.config.fieldsConfig.patientHasInsuranceReversed)
      ? !this.patientHasInsurance.value
      : this.patientHasInsurance.value;
  }

  public get canHaveSecondaryInsurance() { 
    return this.state.config.fieldsConfig.insurancePriorities?.some(e => e == PlanOrder.secondary);
  }

  ngOnInit(): void {
    this.state.SaveState = () => this.SaveState();

    this.medicalInsuranceForm = this.state.forms.medicalInsuranceForm;
    this.pharmacyInsuranceForm = this.state.forms.pharmacyInsuranceForm;

    this.patientHasInsurance = this.state.forms.patientHasInsurance;
    this.addSecondaryMedicalInsurance = this.state.forms.addSecondaryMedicalInsurance;

    this.patientInsuranceChanges$ = this.patientHasInsurance.valueChanges
    .subscribe(
      newValue => {
        const hasInsurance = (newValue !== undefined && this.state.config.fieldsConfig.patientHasInsuranceReversed)
          ? !newValue
          : newValue;
        
        this.onPatientInsuranceFlagChanged(hasInsurance);
      }
    );
    
    this.addSecondaryMedicalInsuranceChanges$ = this.addSecondaryMedicalInsurance.valueChanges
    .subscribe(
      addControls => {
        if (addControls) {
          this.addMedical(PlanOrder.secondary);
        }
        else {
          this.removeMedical(PlanOrder.secondary);
        }

        this.cdrRef.detectChanges();
      }
    );

    this.extendedBinaryOptions = [
      ...this.binaryOptions,
      {
        name: 'Unknown',
        value: this.state.config.fieldsConfig.patientHasInsuranceReversed,
      }
    ];
  }

  ngOnDestroy(): void {
    this.patientInsuranceChanges$.unsubscribe();
    this.addSecondaryMedicalInsuranceChanges$.unsubscribe();
  }

  public static initForms(state: TabState, caseData: any): void {
    state.forms.addSecondaryMedicalInsurance = new FormControl(false);
    state.forms.patientHasInsurance = BaseFormComponent.getControl({ field: state.config.fieldsConfig.patientHasInsurance });

    state.forms.medicalInsuranceForm = new FormGroup({});
    state.forms.pharmacyInsuranceForm = new FormGroup({});

    if (caseData.caseInsurances?.length) { 
      InsuranceComponent.initInsurances(state, caseData.caseInsurances, caseData);
    }

    const hasSecondary = Object
      .getOwnPropertyNames(state.forms.medicalInsuranceForm.controls)
      .find(prop => state.forms.medicalInsuranceForm.controls[prop].value.planOrder === PlanOrder.secondary);
    
    state.forms.addSecondaryMedicalInsurance.patchValue(!!hasSecondary);

    InsuranceComponent.setInsuranceVisibility(
      Object.keys(state.forms.medicalInsuranceForm.controls).length + Object.keys(state.forms.pharmacyInsuranceForm.controls).length,
      state.forms.patientHasInsurance,
      state
    );
  }

  private static initInsurances(state: TabState, allInsurances, caseData) { 
    const insurancePriorities = state.config.fieldsConfig.insurancePriorities;
    const allowedInsurances = state.config.fieldsConfig.showPharmacyInsurance
      ? [CoverageType.medical, CoverageType.pharmacy]
      : [CoverageType.medical];
    
    const insurances = allInsurances.filter(e =>
      insurancePriorities.includes(e.insurancePriorityId) &&
      allowedInsurances.includes(e.patientInsurance.coverageTypeId)
    );

    if (!insurances.some(e =>
      e.insurancePriorityId === PlanOrder.primary &&
      e.patientInsurance.coverageTypeId === CoverageType.medical)) {
      const context = {
        caseId: caseData.id,
        patientId: caseData.patientId,
      }

      const insurance = MedicalInsuranceComponent.getInsuranceObject({ planOrder: PlanOrder.primary }, context, true);

      insurances.push(insurance);
    }

    if (!insurances.some(e =>
      e.insurancePriorityId === PlanOrder.primary &&
      e.patientInsurance.coverageTypeId === CoverageType.pharmacy) &&
      state.config.fieldsConfig.showPharmacyInsurance) {
      const context = {
        caseId: caseData.id,
        patientId: caseData.patientId,
      }

      const insurance = PharmacyInsuranceComponent.getInsuranceObject({ planOrder: PlanOrder.primary }, context, true);

      insurances.push(insurance);
    }

    const groups = groupBy<any, any>(insurances, e => e.patientInsurance.coverageTypeId);

    if (groups[CoverageType.medical]?.length) {
      const insurancesToShow = InsuranceComponent.getInsurances(groups, CoverageType.medical);

      if(!insurancesToShow.length) return;

      insurancesToShow.forEach((insurance) => { 
        const form = MedicalInsuranceComponent.getNewForm(state.config.fieldsConfig, insurance.insurancePriorityId);

        MedicalInsuranceComponent.populateForm(form, insurance, state.autofillData);

        state.forms.medicalInsuranceForm.addControl(`medical_${form.value.planOrder}`, form);
      });
    }

    if (groups[CoverageType.pharmacy]?.length) {
      const insurancesToShow = InsuranceComponent.getInsurances(groups, CoverageType.pharmacy);

      if (!insurancesToShow.length) return;

      insurancesToShow.forEach((insurance) => {
        const form = PharmacyInsuranceComponent.getNewForm(state.config.fieldsConfig, insurance.insurancePriorityId);

        PharmacyInsuranceComponent.populateForm(form, insurance, state.autofillData);

        state.forms.pharmacyInsuranceForm.addControl(`pharmacy_${form.value.planOrder}`, form);
      });
    }
  }

  private static getInsurances(groups, coverageType: CoverageType) { 
    const medicalInsurances = groupBy<any, any>(groups[coverageType], e => e.insurancePriorityId);

    const date = new Date(0);

    const mostRecentPrimary = maxBy(medicalInsurances[PlanOrder.primary], e => e.patientInsurance.effectiveTo ? new Date(e.patientInsurance.effectiveTo) : date);
    const mostRecentSecondary = maxBy(medicalInsurances[PlanOrder.secondary], e => e.patientInsurance.effectiveTo ? new Date(e.patientInsurance.effectiveTo) : date);
    const mostRecentTertiary = maxBy(medicalInsurances[PlanOrder.tertiary], e => e.patientInsurance.effectiveTo ? new Date(e.patientInsurance.effectiveTo) : date);

    return [mostRecentPrimary, mostRecentSecondary, mostRecentTertiary].filter(e => e);
  }

  private setNewCase(newCase: any): void {
    if (this._case || !newCase) return;

    this._case = newCase;
  }

  private static setInsuranceVisibility(insurances, control, state) { 
    const hasInsurance = state.config.fieldsConfig.patientHasInsuranceReversed
      ? !insurances
      : !!insurances;

    control.patchValue(hasInsurance);
  }

  private onPatientInsuranceFlagChanged(hasInsurance) { 
    if (hasInsurance) {
      const context = {
        caseId: this._case.id,
        patientId: this._case.patientId,
      }

      const insurances: any[] = [MedicalInsuranceComponent.getInsuranceObject({ planOrder: PlanOrder.primary }, context, true)];

      if (this.state.config.fieldsConfig.showPharmacyInsurance) { 
        insurances.push(PharmacyInsuranceComponent.getInsuranceObject({ planOrder: PlanOrder.primary }, context, true));
      }

      InsuranceComponent.initInsurances(this.state, insurances.filter(e => e) , this._case);
    }
    else {
      this.state.forms.medicalInsuranceForm = new FormGroup({});
      this.state.forms.pharmacyInsuranceForm = new FormGroup({});

      this.state.forms.addSecondaryMedicalInsurance.patchValue(false);
    }

    this.medicalInsuranceForm = this.state.forms.medicalInsuranceForm;
    this.pharmacyInsuranceForm = this.state.forms.pharmacyInsuranceForm;
  }

  addMedical(planOrder: PlanOrder) {
    const context = {
      caseId: this._case.id,
      patientId: this._case.patientId,
    }

    const obj = MedicalInsuranceComponent.getInsuranceObject({ planOrder: planOrder }, context, true);

    const form = MedicalInsuranceComponent.getNewForm(this.state.config.fieldsConfig, obj.insurancePriorityId);

    MedicalInsuranceComponent.populateForm(form, obj, this.state.autofillData);

    this.state.forms.medicalInsuranceForm.addControl(`medical_${form.value.planOrder}`, form);
  }

  removeMedical(planOrder: PlanOrder) {
    const formData = this.state.forms.medicalInsuranceForm.controls[`medical_${planOrder}`]?.getRawValue();

    if (!formData) return;

    const insuranceId = formData.caseInsuranceId;

    this.insuranceRemovedHandler(insuranceId);

    this.state.forms.medicalInsuranceForm.removeControl(`medical_${planOrder}`);
  }

  setPatientHandler(patient): void {
    this.setPatient.emit(patient);
  }

  getForm(type: CoverageType, priority: PlanOrder) {
    const formFor = type === CoverageType.medical
      ? this.medicalInsuranceForm.controls
      : this.pharmacyInsuranceForm.controls;
    
    const insurance = (type === CoverageType.medical ? 'medical_' : 'pharmacy_') + priority;

    return formFor[insurance];
  }

  submitHandler(): any {
    this.SaveState();

    if (this.state.isValid) this.submitForm.emit();
  }

  insuranceRemovedHandler(id: number) { 
    const index = this._case.caseInsurances.findIndex(e => e.id === id);

    if (index < 0) return;

    this._case.caseInsurances.splice(index, 1);

    this.state.forms.removedInsurances ??= [];
    this.state.forms.removedInsurances.push(id);
  }

  private SaveState(): void {
    BaseFormComponent.validateForms(this.state);

    if (!this.state.isValid) return;

    this.updateInsurances();

    this.state.isSaved = true;
  }

  updateInsurances(): void {
    if (!this.hasInsurance) {
      const data = {
        queueItemId: this._case.id,
        caseInsuranceModels: []
      }

      this.dataEntryService.updateCaseInsurances(data).subscribe(
        (res) => { this._case.caseInsurances = [] },
        (error) => { console.error(error); },
      );

      return;
    }

    const context = {
      caseId: this._case.id,
      patientId: this._case.patientId,
    }

    const currentInsurances = this._case.caseInsurances;

    const medicalInsurances = Object.getOwnPropertyNames(this.medicalInsuranceForm.controls)
      .map(prop => MedicalInsuranceComponent.getInsuranceObject(this.medicalInsuranceForm.controls[prop] as FormGroup, context))
      .filter(e => e);
    
    const pharmacyInsurances = Object.getOwnPropertyNames(this.pharmacyInsuranceForm.controls)
      .map(prop => PharmacyInsuranceComponent.getInsuranceObject(this.pharmacyInsuranceForm.controls[prop] as FormGroup, context))
      .filter(e => e);

    const formInsurances = [...medicalInsurances, ...pharmacyInsurances];
    
    formInsurances.forEach(insurance => {
      if (insurance.id) {
        const insuranceToUpdate = this._case.caseInsurances.find(e => e.id === insurance.id);

        Assign.withoutEmptyValues(insuranceToUpdate, insurance);
      }
      else { 
        this._case.caseInsurances.push(insurance);
      }
    });

    const data = {
      queueItemId: this._case.id,
      insuranceToRemove: this.state.forms.removedInsurances,
      caseInsuranceModels: this._case.caseInsurances
    }

    this.dataEntryService.updateCaseInsurances(data).subscribe(
      (res) => {
        if (!res.success) return;

        this._case.caseInsurances = res.value;

        this.state.forms.medicalInsuranceForm = new FormGroup({});
        this.state.forms.pharmacyInsuranceForm = new FormGroup({});

        InsuranceComponent.initInsurances(this.state, this._case.caseInsurances, this._case);

        this.cdrRef.detectChanges();
      },
      (error) => {
        this._case.caseInsurances = currentInsurances;

        console.error(error);
      },
    );
  }
}
