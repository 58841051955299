/// Please use interpolation.configurator.ts file for configure behavior of interpolation
/// Make changes to this file only in exceptional cases

import { InterpolationConfig } from './interpolation.configurator';

export class CustomStyler {
  public get canApply(): boolean { return this.styles.length > 0 }

  constructor(private styles: string[]) { }

  public resetStyles(styles: string[]): CustomStyler{
    this.styles = styles; return this;
  }

  public applyFor(value: string): string{
    const classes = this.styles.map(prop => InterpolationConfig.StyleClassMatcher[prop]);
    return `<span class="${classes.join(' ').trim()}">${value}</span>`
  } 
}
