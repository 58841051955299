<div class="login-wrapper">
  <div *ngIf="!isMessageSent">
    <h1>Forgot your password?</h1>
    <form class="form" [formGroup]="forgotPasswordForm">
      <div class="field-wrapper">
        <label for="email" class="field-caption">Email</label>
        <div>
          <input #email
                 type="email"
                 formControlName="email"
                 matInput
                 id="email"
                 class="field-input"
                 (paste)="forgotPasswordForm.markAsTouched()"
                 [ngClass]="{'field-input-invalid': forgotPasswordForm.controls.email.dirty && forgotPasswordForm.controls.email.invalid}"
          />
        </div>
      </div>
      <div class="footer-field">
      </div>
    </form>
    <button [disabled]="forgotPasswordForm.invalid"
            class="submit-button"
            (click)="forgotPassword()"
    >
      Send Link
    </button>
    <a routerLink="/login">Back to Sign In</a>
  </div>
  <div class="email-sent-container" *ngIf="isMessageSent">
    <img src="assets/icons/email-sent.svg" alt="email-sent"/>
    <h1 class="email-sent">An email has been sent</h1>
    <button type="button" class="return-login-button" routerLink="/login">Return to login</button>
  </div>
</div>
