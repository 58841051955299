export enum CaseSubStatus {
  dataEntryIncomplete = 'Data Entry Incomplete',
  missingInformation = 'Missing Information',
  benefitVerification = 'Benefit Verification',
  followUpWithHCP = 'Follow-up with HCP',
  priorAuthorization = 'Prior Authorization',
  awaitingShipment = 'Awaiting Shipment',
  scheduleShipment = 'Schedule Shipment',
  onProduct = 'On Product',
  'Data Entry Incomplete' = 'dataEntryIncomplete',
  'Missing Information' = 'missingInformation',
  'Benefit Verification' = 'benefitVerification',
  'Follow-up with HCP' = 'followUpWithHCP',
  'Prior Authorization' = 'priorAuthorization',
  'Awaiting Shipment' = 'awaitingShipment',
  'Schedule Shipment' = 'scheduleShipment',
  'On Product' = 'onProduct'
}
