import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';
import { IContactOption } from './interfaces/contact-option.interface';

@Component({
  selector: 'app-task-select-contact',
  templateUrl: './task-select-contact.component.html',
  styleUrls: ['./task-select-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSelectContactComponent extends InputControlAccessorDirective {
  @Input() options: IContactOption[];
  @Input() isHistory: boolean;

  selectedOption: IContactOption;

  selectOption(option: IContactOption): void {
    this.selectedOption = option;
    super.writeValue(option);
  }
}
