<div class="title">
  <img *ngIf="selected" src="assets/icons/icon-check.svg" alt="check"/>
  <h5>
    {{ option.name }}
  </h5>
  <span *ngIf="option.recommended && !selected"><mat-icon>grade</mat-icon>Recommended</span>
  <button *ngIf="selected" (click)="deselectOption.emit()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="wrapper">
  <div class="item">
    <h6>Phone</h6>
    <p>{{ option.phone | phoneNumber | empty }}</p>
  </div>
  <div class="item">
    <h6>Fax</h6>
    <p>{{ option.fax | phoneNumber | empty }}</p>
  </div>
</div>
