<div class="enrollment create-form form-row date-row">

  <h3>{{ConsentTypeNames[type]}} Opt-Out</h3>

  <div class="form-row half-width">
    <div class="input-item select"
         [class]="renewOptInControl.invalid && renewOptInControl.touched ? 'input-error' : ''">
      <label for="consentMethod">Method Received</label>
      <mat-form-field appearance="fill">
        <mat-select [formControl]="renewOptInControl" name="consentMethod"
                    placeholder="Select" id="consentMethod"
                    disableOptionCentering #consentMethod
                    panelClass="dropdown-item">
          <mat-option *ngFor="let method of consentMethods; index as i"
                    [value]="i">{{ConsentMethodNames[method]}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="controls default-form fx-row">
    <button class="cancel-btn" mat-button (click)="cancelled.emit()">
      exit
    </button>

    <button class="save-btn" [disabled]="renewOptInControl.invalid"
            mat-button (click)="save()">
      save
    </button>
  </div>

</div>
