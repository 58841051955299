import { DataEntryTabsConfig } from './data-entry-tabs.config';
import { TabId } from './parameters/tab-identifier';
import { TabState } from './tab.state';

export class FormState {
  private _patient: TabState;
  private _insurance: TabState;
  private _physician: TabState;
  private _clinical: TabState;
  private _prescription: TabState;
  private _consent: TabState;

  private _currentTabId = TabId.caseSelection; // by default first tab is caseSelection
  private _displayedTabs: TabState[] = [];
  private _canChangeTab: boolean = false;
  private _autofillData: any = undefined;

  public get patient() { return this._patient };
  public get clinical() { return this._clinical };
  public get insurance() { return this._insurance };
  public get physician() { return this._physician };
  public get prescription() { return this._prescription };
  public get consent() { return this._consent };

  public get currentTabId() { return this._currentTabId; }
  public get canChangeTab() { return this._canChangeTab; }
  public get displayedTabs() { return this._displayedTabs; }
  public get currentTab() { return this._displayedTabs.find(e => e.config.tabId === this._currentTabId); }

  public get allWarnings() { return this._getAllWarnings(); }
  public get autofillData() { return this._autofillData }

  constructor(public readonly config: DataEntryTabsConfig){ 
    this.initTabs();
    this.orderTabs();
  }

  public setCurrentTab(tab: TabId): void {
    this._currentTabId = tab;
  }

  public UpdateState(): void {
    this._canChangeTab = this.currentTab?.isValid;
  }

  public setAutofillData(data) {
    this._autofillData = data;

    this._displayedTabs.forEach(tab => tab.setAutofillData(data));
  }

  private _getAllWarnings() {
    const warnings = this._displayedTabs.reduce((acc, cur) => { 
      if(cur.validation.desired.length) acc.desired = [...acc.desired, ...cur.validation.desired];
      if(cur.validation.required.length) acc.required = [...acc.required, ...cur.validation.required];
      return acc;
    }, { required: [], desired: [] });

    return warnings;
  }

  private initTabs(): void {
    if(this.config[TabId.patient]) {
      this._patient = new TabState(this.config[TabId.patient], './assets/icons/person.svg', './assets/icons/person-active.svg');
      this._displayedTabs.push(this.patient);
    }
    if(this.config[TabId.insurance]) {
      this._insurance = new TabState(this.config[TabId.insurance], './assets/icons/shield.svg', './assets/icons/shield-active.svg');
      this._displayedTabs.push(this.insurance);
    }
    if(this.config[TabId.physician]) {
      this._physician = new TabState(this.config[TabId.physician], './assets/icons/stethoscope.svg', './assets/icons/stethoscope-active.svg');
      this._displayedTabs.push(this.physician);
    }
    if(this.config[TabId.clinical]) {
      this._clinical = new TabState(this.config[TabId.clinical], './assets/icons/heartbeat.svg', './assets/icons/heartbeat-active.svg');
      this._displayedTabs.push(this.clinical);
    }
    if (this.config[TabId.prescription]) {
      this._prescription = new TabState(this.config[TabId.prescription], './assets/icons/pill.svg', './assets/icons/pill-active.svg');
      this._displayedTabs.push(this.prescription);
    }
    if (this.config[TabId.consent]) {
      this._consent = new TabState(this.config[TabId.consent], './assets/icons/intake-consent.svg', './assets/icons/intake-consent-active.svg');
      this._displayedTabs.push(this.consent);
    }
  }

  public orderTabs(): void {
    if(!this._displayedTabs.length) return;

    this._displayedTabs = this._displayedTabs.sort((e1, e2) => e1.config.tabOrder - e2.config.tabOrder);
    for(let i = 0; i < this._displayedTabs.length - 1; i++) this._displayedTabs[i].config.nextTab = this._displayedTabs[i+1];
    this._displayedTabs[this._displayedTabs.length - 1].config.nextTab = undefined;
  }
}
