import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractRestService } from '@core/services/abstract-rest.service';
import { IResponse } from '../interfaces/response.interface';
import { IInquiryType } from '@shared/interfaces/inquiry-type.interface';
import { IInquiryQuestionType } from '@shared/interfaces/inquiry-question-type.interface';
import { IInquiryLink } from '@shared/interfaces/inquiry-link.interface';
import { IInquiryObject } from '@shared/interfaces/inquiry-object.interface';
import { IInquiryEntityType } from '@shared/interfaces/inquiry-entity-type.interface';

@Injectable({
  providedIn: 'root',
})
export class GeneralInquiryService extends AbstractRestService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getInquiryTypes(): Observable<IInquiryType[]> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`GeneralInquiry/InquiryTypes`)
      .pipe(map((response) => response.value));
  }

  getInquiryQuestionTypes(inquiryTypeId: number): Observable<IInquiryQuestionType[]> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`GeneralInquiry/QuestionTypes?inquiryTypeId=${inquiryTypeId}`)
      .pipe(map((response) => response.value));
  }

  getInquiryLinks(inquiryTypeId: number): Observable<IInquiryLink[]> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`GeneralInquiry/InquiryLinks?inquiryTypeId=${inquiryTypeId}`)
      .pipe(map((response) => response.value));
  }

  createGeneralInquiry(data: IInquiryObject): Observable<IResponse> {
    this.url = this.apiUrl + `/GeneralInquiry/CreateGeneralInquiry`;
    return this.postItem<any, IResponse>(data);
  }

  getEntityTypes(programId: number): Observable<IInquiryEntityType[]> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`GeneralInquiry/InquiryEntityTypes?programId=${programId}`)
      .pipe(map((response) => response.value));
  }
}
