<h1>Escalation</h1>

<div class="reason-box">
  <p class="reason-header">REASON</p>
  <p class="reason-text">{{getReasonText()}}</p>
</div>

<p class="description">
  Please delete this page, OR identify the document type and patient below.
</p>

<button class="delete-btn"
        (click)="deleteCurrentPage.emit()">
  <mat-icon>delete</mat-icon>
  Delete Page
</button>
