export enum DocumentTypeNames {
  enrollmentForm = 'Enrollment Form',
  papForm = 'PAP Form',
  prescription = 'Prescription',
  insuranceCard = 'Insurance Card',
  proofOfIncome = 'Proof of Income',
  other = 'Other',
  HIPAAForm = 'HIPAA Form',
  mergedDocument = 'Merged Attachment',
}
