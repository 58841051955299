<div (click)="edit()" (clickOutside)="closeEditMode($event)" [id]="fieldId" [ngClass]="{edit: isEdit}"
     class="editable-aside-field-container">
  <mat-icon class="edit-icon" *ngIf="!isEdit">edit</mat-icon>

  <div *ngIf="!isEdit">
    <ng-content select="[text]"></ng-content>
  </div>
  <div *ngIf="isEdit">
    <ng-content select="[inputs]"></ng-content>
  </div>

  <div class="controls fx-row" *ngIf="isEdit">
    <button class="cancel-btn" mat-button (click)="close(false, $event)">
      exit
    </button>

    <button class="save-btn" mat-button (click)="close(true, $event)" [disabled]="disableSave">
      save
    </button>
  </div>
</div>
