import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss']
})
export class ExpandableListComponent implements OnInit {
  @Input() caption: string;
  @Input() position: string;

  opened: boolean;

  menuStyle: string;

  constructor() {
  }

  ngOnInit(): void {
    if (this.position === 'right') {
      this.menuStyle = 'margin-left: 100%; top: -12px; left: 16px';
    }
  }

  toggle(): void {
    this.opened = !this.opened;
  }

  close(ev): void {
    this.opened = false;
  }
}
