<div class="task-preview-document">
  <div class="header">
    <div class="preview-header">{{field.name}}</div>

    <button (click)="openDocument()" class="primary-btn invert" >
      {{field.label}}
      <img class="btn-icon" src="assets/icons/icon_open_ref_outside.svg" alt="open-outside" />
      <img class="btn-icon active" src="../../../../../../../../../../../assets/icons/icon_open_ref_outside_white.svg" alt="open-outside" />
    </button>
  </div>

  <div *ngIf="field.documentData" class="data">
    <div class="row">
      <b>DOCUMENT TYPE</b>
      <span>{{getDocumentType(field.documentData.type)}}</span>
    </div>

    <div class="row">
      <b>RECEIVED</b>
      <span>{{field.documentData.received | date: 'MM/dd/yyyy'}} at {{field.documentData.received | date: 'h:mm a'}}</span>
    </div>

    <div class="row">
      <b>METHOD</b>
      <span>{{getMethod(field.documentData.method)}}</span>
    </div>
  </div>
</div>
