import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { DataEntryFormService } from './services/form.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as DocumentAction from '../../../../../../../../store/document/document.actions';
import { FormState } from '../../configuration/form.state';
import { TabId } from '../../configuration/parameters/tab-identifier';
import { CaseService } from 'src/app/features/manager/components/case/services/case.service';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { RequiredMissedDialog } from '../dialog/required/required.component';
import { DesiredMissedDialog } from '../dialog/desired/desired.component';
import { PatientComponent } from './components/patient/patient.component';
import { PhysicianComponent } from './components/physician/physician.component';
import { PrescriptionComponent } from './components/prescription/prescription.component';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { ClinicalComponent } from './components/clinical/clinical.component';
import { BaseFormComponent } from './components/base-form.component';
import { ConfigResolver } from './services/config-resolver.service';
import { MissedCaseModalComponent } from '../dialog/missed/missed.component';
import { QueueItemsMonitoringService } from '@core/signalR/interface/queue-items-monitoring.service';
import { ConsentComponent } from './components/consent/consent.component';
import { ImageService } from '@shared/services/image.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-intake-document-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [QueueItemsMonitoringService]
})
export class FormComponent implements OnInit {
  public readonly TabId = TabId;

  public case;
  public cases;
  public formState: FormState;
  public isLoading = false;
  private faxNumber: string;
  private queueId: number;

  public documentInfo = {
    batchId: '',
    contact: '',
    createDate: undefined,
    patient: undefined,
    typeId: undefined,
    index: -1,
    programId: undefined,
    newPatientName: undefined,
    parentDocumentType: undefined,
  };

  private autoFillData = undefined;

  private readonly tabs = {
    [TabId.patient]: PatientComponent,
    [TabId.clinical]: ClinicalComponent,
    [TabId.insurance]: InsuranceComponent,
    [TabId.physician]: PhysicianComponent,
    [TabId.prescription]: PrescriptionComponent,
    [TabId.consent]: ConsentComponent,
  };

  constructor(
    private router: Router,
    private store: Store<any>,
    private dialog: MatDialog,
    private caseService: CaseService,
    private configResolver: ConfigResolver,
    private dataEntryService: DataEntryFormService,
    private queueItemsMonitoringService: QueueItemsMonitoringService,
    private imageService: ImageService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queueId = parseInt(params.queueId);
      this.initDocument();
    });
  }

  private initDocument(): void {
    this.cleanupState();

    const sub = this.store.select('document').subscribe((state) => {
      if (!state.documentList) {
        return;
      }

      this.initJsonData(state.importData); // 1 - JsonData
      this.initState(state);  // 2 - state
      this.initCases(this.documentInfo.batchId, state.programId);  // 3 - load cases

      this.queueItemsMonitoringService.lockItem(this.documentInfo.batchId, this.queueId);

      setTimeout(() => sub.unsubscribe(), 0);
    });
  }

  private initJsonData(importData) {
    try {
      if (importData) {
        this.autoFillData = JSON.parse(importData);
      }
    } catch (ex) {
      console.error('Exception was thrown while parsing importData, so attached data can`t be autofilled');
      console.error(ex);
    }
  }

  private cleanupState(): void {
    this.case = undefined;
    this.cases = undefined;
    this.formState = undefined;
    this.autoFillData = undefined;
    this.documentInfo = {
      batchId: '',
      contact: '',
      createDate: undefined,
      patient: undefined,
      typeId: undefined,
      parentDocumentType: undefined,
      index: -1,
      programId: undefined,
      newPatientName: undefined,
    };
  }

  private initState(state) {
    const document = state.documentList[state.selectedDocument];
    const patient = document.patient;

    this.documentInfo.batchId = state.documentList[state.selectedDocument].documentBatchId;
    this.documentInfo.contact = state.contact;
    this.documentInfo.createDate = document.createDate;
    this.documentInfo.patient = patient;
    this.documentInfo.typeId = document.documentType;
    this.documentInfo.parentDocumentType = state.parentDocumentType;
    this.documentInfo.index = state.selectedDocument;
    this.documentInfo.programId = state.programId;
    this.documentInfo.newPatientName = document.newPatientName;

    this.case = null;
    this.cases = [];
  }

  private initCases(queueItemId, programId): any {
    const query = {
      patientId: this.documentInfo.patient?.id,
      newPatientName: this.documentInfo.newPatientName,
      queueItemId,
      programId,
      externalSourcePatientId: this.autoFillData?.patient?.externalSourcePatientId,
      caseTypeName: this.autoFillData?.caseType,
      productId: this.autoFillData?.prescription?.productId
    };

    this.dataEntryService.searchCases(query).subscribe(
      (response) => {
        if (response.cases?.length) {
          this.cases = response.cases;
        }
        else { 
          if (!this.documentInfo.patient?.id) {
            this.documentInfo.patient = response.caseModel.patient;
          }

          this.case = response.caseModel;
        }

        this.initConfig(programId);  // 4 - finish initialization
      },
      (error) => {
        this.router.navigate(['/']);
        throwError(error);
      }
    );

    return this;
  }

  private initConfig(programId): any {
    const params = { documentTypeId: this.documentInfo.parentDocumentType, programId };

    this.configResolver.resolve(params).subscribe(
      config => {
        this.formState = new FormState(config);

        if (this.autoFillData) {
          this.formState.setAutofillData(this.autoFillData);
        }

        if (this.case) {
          this._initAllForms();
          this.formState.setCurrentTab(this.formState.displayedTabs[0].config.tabId);
        }
      },
      error => {
        console.error(error);
      });

    return this;
  }

  onCaseSelected(caseModel) { 
    this.dataEntryService.getCase(caseModel.id).subscribe(
      (response) => {
        this.setCurrentCase(response.value);
      },
      (error) => throwError(error)
    );
  }

  setCurrentCase(selectedCase): void {
    this.case = selectedCase;
    this._initAllForms();
    this.formState.setCurrentTab(this.formState.displayedTabs[0].config.tabId);
  }

  private _initAllForms(): void {
    this.formState.displayedTabs.forEach(tab => {
      this.tabs[tab.config.tabId].initForms(tab, this.case);

      BaseFormComponent.validateForms(tab);
    });
  }

  onCaseCreated(): void {
    const data = {
      caseManagementQueueItem: {
        patientId: this.documentInfo.patient?.id,
        enrollmentStartDate: this.documentInfo.createDate,
        typeName: this.autoFillData?.caseType,
        programId: this.documentInfo.programId
      }
    };

    this.dataEntryService.createCase(data).subscribe(
      (response) => {
        this.setCurrentCase(response.value);
      },
      (error) => throwError(error)
    );
  }

  setPatientHandler(patient): void {
    this.documentInfo.patient = patient;
  }

  setTab(tabId: TabId): void {
    if ([this.TabId.caseSelection, tabId].includes(this.formState.currentTabId)) {
      return;
    }

    const currentTab = this.formState.currentTab;
    
    BaseFormComponent.validateForms(currentTab);

    this.formState.UpdateState();

    if (this.formState.canChangeTab) {
      this._proceedStep(currentTab, () => { 
        currentTab.SaveState();

        this.formState.setCurrentTab(tabId);
      });
    }
  }

  submitStep(): void {
    const current = this.formState.currentTab;

    this.formState.UpdateState();

    if (!this.formState.canChangeTab) {
      return;
    }

    const nextId = current.config.nextTab?.config.tabId;

    // if we have next tab it takes us to the next tab
    // if we haven`t next - it mean`s that current tab is the last and we can finish
    // int that case it takes us to the finish step
    const nextStep = nextId ? () => this.formState.setCurrentTab(nextId) : () => this._tryCompleteIntake();

    this._proceedStep(current, nextStep);
  }

  submitMissedInfo(faxNumber: string): void {
    this.faxNumber = faxNumber;
    this.completeDocumentBatchAndIntake();
  }

  cancelMissedInfo(): void {
    const tab = this.formState.displayedTabs[this.formState.displayedTabs.length - 1];
    this.formState.setCurrentTab(tab.config.tabId);
  }

  private _proceedStep(tab, successHandler: () => void): void {
    // after each step we should validate tab state from which we move
    // this method calls every time when we change tabs

    if (!tab.validation.required.isApproved && tab.validation.required.length) {
      // in case when we have some missed required info we should show appropriate dialog
      // if user approve that this info is missing "isApproved" will be true
      this._showDialog(RequiredMissedDialog, tab.validation.required)
        .then(canChangeTab => {
          if (canChangeTab) {
            tab.validation.required.isApproved = true;
            this._proceedStep(tab, successHandler);
          }
        });
    } else if (!tab.validation.desired.isApproved && tab.validation.desired.length) {
      // in case when we have some missed desired info we should show appropriate dialog
      // if user approve that this info is missing "isApproved" will be true
      this._showDialog(DesiredMissedDialog, tab.validation.desired)
        .then(canChangeTab => {
          if (canChangeTab) {
            tab.validation.desired.isApproved = true;
            this._proceedStep(tab, successHandler);
          }
        });
    } else {
      // in case when everything is good we need to let user go to the next step
      // it can be next tab or "complete step"
      successHandler();
    }
  }

  private _showDialog(component: ComponentType<unknown>, data): Promise<any> {
    return new Promise((resolve) => {
      this.dialog.open(component, { data }).afterClosed().subscribe(result => resolve(result));
    });
  }

  private _tryCompleteIntake(): void {
    const invalidTab = this.formState.displayedTabs.find(e => !e.isValid);

    if (invalidTab) {
      this.formState.setCurrentTab(invalidTab.config.tabId);
      return;
    }

    const notSavedTab = this.formState.displayedTabs.find(e => !e.isSaved);

    // If some tab is not saved
    if (notSavedTab) {
      // When not saved current tab - just save
      if (this.formState.currentTab.config.tabId === notSavedTab.config.tabId) {
        notSavedTab.SaveState();
      }
      else {
        // when not saved other tab - navigate to that tab
        this.formState.setCurrentTab(notSavedTab.config.tabId);
        return;
      }
    }

    const warnings = this.formState.allWarnings;

    if (warnings.required.length || warnings.desired.length) {
      this._showDialog(MissedCaseModalComponent, warnings).then(needReview => {
        if (needReview) {
          this._showMissedInfoStep();
        }
      });
      return;
    }

    this.removeCoverSheet();
    this.completeDocumentBatchAndIntake();
  }

  private _showMissedInfoStep(): void {
    this.formState.setCurrentTab(TabId.missedInformation);
    this.addMissingInfoCoverSheet();
  }

  private addMissingInfoCoverSheet() {
    const missingFields = this.getMissedFields(this.formState.allWarnings);

    if (!missingFields.length) 
      return;

    this.caseService.getMissingInfoFaxCoversheet({ caseId: this.case.id, missingFields }).subscribe(
      (response) => {
        const hasCoverSheet = response.body.size > 0;

        if (!hasCoverSheet)
          return;

        this.imageService.createImageFromBlob(response.body, ev => {
          const newDoc = { isMissingInfoCoverSheet: true, raw: ev.target.result, thumbnail: ev.target.result };
          this.applyCoverSheets(newDoc);
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  private applyCoverSheets(document) {
    this.store.select('document').pipe(take(1)).subscribe((state) => {
      if (!state.documentList) {
        return;
      }

      const documentList = state.documentList.filter(x => !x.isMissingInfoCoverSheet);
      documentList.unshift(document);
      this.store.dispatch(new DocumentAction.SetDocumentsList(documentList));
      const coversheetIndex = 0;
      this.store.dispatch(new DocumentAction.SetSelectedDocument(coversheetIndex));
    });
  }

  private removeCoverSheet() {
    this.store.select('document').pipe(take(1)).subscribe((state) => {
      if (!state.documentList) {
        return;
      }
      
      const documentsWithoutCoverSheets = state.documentList.filter(x => !x.isMissingInfoCoverSheet);
      const numCoverSheets = state.documentList.length - documentsWithoutCoverSheets.length;
      this.store.dispatch(new DocumentAction.SetDocumentsList(documentsWithoutCoverSheets));

      let newSelectedDocumentIndex = state.selectedDocument - numCoverSheets;

      if (newSelectedDocumentIndex < 0)
        newSelectedDocumentIndex = 0;

      this.store.dispatch(new DocumentAction.SetSelectedDocument(newSelectedDocumentIndex));
    });
  }

  private completeDocumentBatchAndIntake(): void {
    if (!!this.documentInfo.batchId) {
      this.completeDocumentBatch();

      return;
    }

    this._completeIntake();
  }

  private _completeIntake(): void {
    const missingFields = this.getMissedFields(this.formState.allWarnings);

    const productId = PrescriptionComponent.getProduct(this.formState.prescription);

    const data = {
      caseId: this.case.id,
      productId: productId,
      missingFields,
      taskItemId: this.documentInfo.batchId,
      faxNumber: this.faxNumber
    };

    this.caseService.completeIntake(data).subscribe(
      (response) => {
        this._getNextStack();
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  private getMissedFields(allWarnings: { required: any[], desired: any[] }): any[] {
    const missedRequired = allWarnings.required.map(e => ({ ...e, isRequired: true, }));
    const missedDesired = allWarnings.desired.map(e => ({ ...e, isRequired: false, }));
    
    return [...missedRequired, ...missedDesired];
  }

  private completeDocumentBatch(): boolean {
    if (!this.documentInfo.batchId) {
      return;
    }

    this.isLoading = true;

    this.queueItemsMonitoringService.unlockItem();
    this.queueItemsMonitoringService.completeQueueItem(this.documentInfo.batchId, this.queueId);

    this.dataEntryService.setDocumentBatchCompleted(Number(this.documentInfo.batchId), Number(this.case.id))
      .subscribe(
        (response) => {
          this.store.dispatch(new DocumentAction.UpdateDocumentsBatch(true));
          this._completeIntake(); // Need to complete document batch before completing intake.
        },
        (error) => {
          this.isLoading = false;
          throwError(error);
        }
      );
  }

  private _getNextStack(): void {
    this.router.navigate(['/document-data-entry'], { queryParams: { queueId: this.queueId } });
    this.initDocument();
  }
}
