import { DefaultTabOrdering } from '../parameters/default-tab-ordering';
import { FieldsConfig } from './fields-default.config';
import { TabId } from '../parameters/tab-identifier';
import { BaseConfig } from './base.config';

export class PrescriptionConfig extends BaseConfig {
  constructor({ 
    ordering = DefaultTabOrdering[TabId.prescription],
    displayName = 'Prescription',
    nextTabName = undefined }) {
    super(TabId.prescription, ordering, displayName, nextTabName);
  }

  public fieldsConfig =
    {
      preselectedProductId: 0,
    
      medication: new FieldsConfig({ displayName: 'Medication' }),
      dosage: new FieldsConfig({ displayName: 'Dosage' }),
      quantity: new FieldsConfig({ displayName: 'Quantity' }),
      signature: new FieldsConfig({ displayName: 'Physician Signature Included?' }),
      physicianSignatureDate: new FieldsConfig({ displayName: 'Physician Signature Date' }),
      refillsNumber: new FieldsConfig({ displayName: 'Number of Refills' }),

      notes: new FieldsConfig({ displayName: 'Rx Notes' }),
      refills: new FieldsConfig({ displayName: 'Refills' }),
      prescriptionStart: new FieldsConfig({ displayName: 'Prescription Start' }),
      prescriptionEnd: new FieldsConfig({ displayName: 'Prescription End' }),

      dispenseAsWritten: new FieldsConfig({ displayName: 'Dispense as written' }),

      // Custom fields:
      // TODO: Should be refactored in future, to exclude specific code out of standard code
      isOptionChecked: new FieldsConfig({ displayName: 'Is RelieVRx checked?' }),
    };
}
