import { Component, forwardRef, Injector, Input } from '@angular/core';
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentsListDialogComponent } from '../../../../dialogs/attachments-list-dialog/attachments-list-dialog.component';
import { ITaskField } from 'src/app/features/manager/components/case/interfaces/task-field.interface';

@Component({
  selector: 'app-task-select-attachments',
  templateUrl: './task-select-attachments.component.html',
  styleUrls: ['./task-select-attachments.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskSelectAttachmentsComponent),
      multi: true,
    }
  ],
})
export class TaskSelectAttachmentsComponent extends InputControlAccessorDirective {
  @Input() case;
  @Input() field: ITaskField;
  @Input() isHistory: boolean;

  previewValues = [];
  selectedFileImages: string[] = [];

  constructor(
    public injector: Injector,
    private dialog: MatDialog,
  ) {
    super(injector);
  }

  fileSelected(ev: any): void {
    if (ev.target.files && ev.target.files[0]) {
      this.selectedFileImages = [];

      const files = ev.target.files;
      const reader = new FileReader();

      super.writeValue(super.value.push(files));
      this.previewValues = files.slice(0, 2);

      super.value.forEach((file: any) => {
        reader.readAsDataURL(files[0]);
        reader.onload = e => this.selectedFileImages.push(reader.result.toString());
      });
    }
  }

  openAttachmentsListDialog(): void {
    if (!this.isHistory) {
      this.dialog.open(AttachmentsListDialogComponent, {
        panelClass: 'select-attachment-dialog',
        data: {
          caseId: this.case.id,
          selectedDocuments: super.value
        },
      })
        .afterClosed()
        .subscribe((documents) => {
          if (!documents) {
            return;
          }

          super.value = documents;
          super.writeValue(documents);
        });
    }
  }
}
