<div class="navigation">
  <ul>
    <li (click)="showPhaseHandler('')" [class.active]="!shownPhase">
      <img *ngIf="shownPhase" src="../../../../../../../assets/icons/icon-activity.svg"/>
      <img *ngIf="!shownPhase" src="../../../../../../../assets/icons/icon-activity-active.svg"/>
      <span>Activity</span>
    </li>
    <li
      *ngFor="let phase of phaseList"
      (click)="showPhaseHandler(phase.id)"
      [class.active]="shownPhase === phase.id"
      [class.disabled]="phase.disabled"
      [class]="phase.id"
    >
      <img class="phase-icon"
           [src]="'../../../../../assets/icons/' + phase.id + (shownPhase === phase.id ? '-active.svg' : '.svg')"
      />
      <span class="phase-name">{{ phase.phase }}</span>
    </li>
  </ul>
</div>
