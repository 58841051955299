export function addWorkDays(startDate: Date, daysToAdd: number) {
  const date = new Date(startDate);

  const dayOfWeek = date.getDay();

  if (dayOfWeek == 0) daysToAdd++;

  if (dayOfWeek + daysToAdd >= 6) {
    const remainingWorkDays = daysToAdd - (5 - dayOfWeek);
    
    daysToAdd += 2;

    if (remainingWorkDays > 5) {
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5);

      if (remainingWorkDays % 5 == 0) daysToAdd -= 2;
    }
  }

  date.setDate(date.getDate() + daysToAdd);

  return date;
}
