import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncapsulatedRestService } from '@core/services/encapsulated-rest.service';
import { IResponse } from '@shared/interfaces/response.interface';
import { EscalateDataEntryItemCommand } from '../models/escalate-data-entry-item.command';

@Injectable({ providedIn: 'root' })
export class DocumentIdentificationService extends EncapsulatedRestService {

  constructor(http: HttpClient) { super(http) }

  public escalateItem(command: EscalateDataEntryItemCommand) { 
    return this.postItem<EscalateDataEntryItemCommand, IResponse>('DataEntryQueue/EscalateDocumentBatch', command);
  }
}
