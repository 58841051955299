<div class="document-sidebar">
  <div class="sidebar-controls" *ngIf="formState?.currentTabId !== TabId.missedInformation">
    <div *ngFor="let tab of formState?.displayedTabs"
         class="control-item"
         [ngClass]="{'active': formState?.currentTabId === tab.config.tabId}"
         [class.valid]="tab.isValid === true && tab.withWarnings === false"
         [class.invalid]="tab.isValid === false || tab.withWarnings === true"
         (click)="setTab(tab.config.tabId)"
         [title]="tab.config.tabDisplayName"
    >
      <img [src]="formState?.currentTabId === tab.config.tabId ? tab.iconSelected : tab.icon"/>
    </div>
  </div>

  <div class="sidebar-content" [ngClass]="{'tab-active': formState?.currentTabId !== 'case'}">
    <ng-scrollbar class="custom-scrollbar">
      <ng-container [ngSwitch]="formState?.currentTabId">
        <app-intake-document-patient-form class="document-form" *ngSwitchCase="TabId.patient"
                                          [isLoading]="isLoading"
                                          [newPatientName]="documentInfo.newPatientName"
                                          (submitForm)="submitStep()"
                                          (setPatient)="setPatientHandler($event)"
                                          [case]="case" [state]="formState.patient"
                                          #patientForm
        ></app-intake-document-patient-form>

        <app-intake-document-insurance-form class="document-form" *ngSwitchCase="TabId.insurance"
                                            [isLoading]="isLoading"
                                            (setPatient)="setPatientHandler($event)"
                                            (submitForm)="submitStep()"
                                            [case]="case" [state]="formState.insurance"
                                            #insuranceForm
        ></app-intake-document-insurance-form>

        <app-intake-document-clinical-form class="document-form" *ngSwitchCase="TabId.clinical"
                                           [isLoading]="isLoading"
                                           (submitForm)="submitStep()"
                                           [case]="case"
                                           [state]="formState.clinical"
                                           #clinicalForm
        ></app-intake-document-clinical-form>

        <app-intake-document-prescription-form class="document-form" *ngSwitchCase="TabId.prescription"
                                               (submitForm)="submitStep()"
                                               [isLoading]="isLoading"
                                               [case]="case"
                                               [state]="formState.prescription"
                                               #prescriptionForm
        ></app-intake-document-prescription-form>

        <app-case-missed class="document-form"
                         *ngSwitchCase="TabId.missedInformation"
                         [case]="case" [state]="formState"
                         [isLoading]="isLoading"
                         (submitMissedInfo)="submitMissedInfo($event)"
                         (cancelMissedInfo)="cancelMissedInfo()"
        ></app-case-missed>

        <app-intake-document-physician-form class="document-form" *ngSwitchCase="TabId.physician"
                                            (submitForm)="submitStep()"
                                            [isLoading]="isLoading"
                                            [case]="case" [contact]="documentInfo.contact"
                                            [state]="formState.physician"
                                            #physicianForm
        ></app-intake-document-physician-form>

        <app-data-entry-case *ngSwitchDefault class="document-form"
                             (submitForm)="submitStep()"
                             [patient]="documentInfo.patient" [cases]="cases"
                             (caseSelected)="onCaseSelected($event)"
                             (caseCreated)="onCaseCreated()"
        ></app-data-entry-case>

        <app-consent (submitForm)="submitStep()"
                   class="document-form"
                   [case]="case"
                   *ngSwitchCase="TabId.consent"
                   [state]="formState.consent"
                   [isLoading]="isLoading">
        </app-consent>
      </ng-container>
    </ng-scrollbar>

    <app-loader *ngIf="!cases" class="document-loader-container"></app-loader>
  </div>
</div>
