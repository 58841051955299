import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
})
export class SubmitButtonComponent {
  @Input() buttonName: string;
  @Input() isLoading: boolean;
  @Input() disabled: boolean;
  @Input() minWidth: number; // px to adjust loading state

  @Output() btnClick: EventEmitter<void> = new EventEmitter();

  @ViewChild('submitButton') submitButton;

  constructor() {
  }

  focusButton(): void {
    setTimeout(() => this.submitButton.nativeElement.focus());
  }
}
