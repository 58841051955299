import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { getDateString } from '../../../../../../../shared/helpers/utils';
import { IAddress } from '../../../interfaces/address.interface';
import { CaseService } from '../../../services/case.service';

@Component({
  selector: 'app-address-history-dialog',
  templateUrl: './address-history-dialog.component.html',
  styleUrls: ['./address-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressHistoryDialogComponent implements OnInit {
  addressHistoryDataSource: MatTableDataSource<IAddress>;
  columnsToDisplay = [
    'address',
    'cityState',
    'zipCode',
    'createDate',
  ];

  getDate = getDateString;

  constructor(
    @Inject(MAT_DIALOG_DATA) public address,
    public dialog: MatDialogRef<AddressHistoryDialogComponent>,
    private caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    // TODO: remove when endpoint is ready
    this.addressHistoryDataSource = new MatTableDataSource([this.address]);

    // TODO: uncomment when endpoint is ready
    // this.caseService.getAddressHistory({id: this.address.id})
    //   .subscribe((data: IAddressHistory) => {
    //     this.addressHistoryDataSource = new MatTableDataSource(data);
    //   });
  }
}
