<header>
  <div routerLink="/" class="title">P3</div>
  <div *ngIf="case" class="header-info">
    <div class="case-info" *ngIf="case.patient || case.id">
      <b *ngIf="case.patient">{{ case.patient }}</b> <i *ngIf="case.id">&#9679;</i> <span
      *ngIf="case.id">Case ID #{{ case.id }}</span>
    </div>
<!--    TODO: uncomment when Skip To Next button is implemented -->
<!--    <div class="task-info" *ngIf="case.queue || case.tasks">-->
<!--      <b *ngIf="case.queue">{{ case.queue }} Queue</b> <span *ngIf="case.tasks">({{ case.tasks }} tasks left)</span>-->
<!--      <button>Skip To Next-->
<!--        <mat-icon>arrow_forward</mat-icon>-->
<!--      </button>-->
<!--    </div>-->
  </div>
  <div *ngIf="documentData.count" class="document-info">
    <b>{{ documentData.count }} {{ queueType === queueTypes.identification ? "Stacks" : null }}</b>
    {{ queueType === queueTypes.dataEntry ? "Docs" : null }} in Queue
    <button *ngIf="false">
      Skip To Next
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</header>
