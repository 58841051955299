import { Component, ViewChild, ElementRef } from '@angular/core';
import { CreationSteps } from '../../../../shared/enums/creation-steps.enum';
import { enumToArray } from '../../../../shared/helpers/utils';
import { QueueCreateService } from './services/creation.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-creation',
  templateUrl: './creation.component.html',
  styleUrls: ['./creation.component.scss'],
})
export class QueueCreationComponent {
  settings = {
    steps: enumToArray(CreationSteps),
    activeStep: 'setup',
    setup: {
      name: '',
      description: '',
      configuration: 'pull',
    },
    selectedTasks: [],
    selectedTerritories: [],
    selectedUsers: [],
  };

  @ViewChild('nextBtn') nextBtn: ElementRef;

  constructor(
    public queueCreateService: QueueCreateService,
    private router: Router,
  ) {
  }

  setSetupValue(setup): void {
    this.settings.setup.name = setup.name.trim();
    this.settings.setup.description = setup.description.trim();
    this.settings.setup.configuration = setup.configuration;
  }

  setSelectedTasks(tasks): void {
    this.settings.selectedTasks = tasks;
  }

  setSelectedTerritories(territories): void {
    this.settings.selectedTerritories = territories;
  }

  setSelectedUsers(users): void {
    this.settings.selectedUsers = users;
  }

  setStep(direction): void {
    const currentIndex = this.settings.steps.findIndex((step) => step === this.settings.activeStep);
    if (direction === 'next') {
      if (this.settings.activeStep !== 'users') {
        this.settings.activeStep = String(this.settings.steps[currentIndex + 1]);
      } else {
        this.submitQueue();
      }
    } else {
      this.settings.activeStep = String(this.settings.steps[currentIndex - 1]);
    }
  }

  submitQueue(): void {
    const data = {
      queueConfiguration: {
        name: this.settings.setup.name,
        description: this.settings.setup.description,
        userCanPull: this.settings.setup.configuration === 'pull',
        userIDs: [...this.settings.selectedUsers],
        territoryIDs: [...this.settings.selectedTerritories],
        queueItemTaskIDs: [...this.settings.selectedTasks],
      },
    };
    this.queueCreateService.saveQueue(data).subscribe(
      (response) => {
        this.router.navigate(['/manage-queue'], {queryParams: {id: response.value}});
      },
      (error) => throwError(error)
    );
  }

  skipStep(): void {
    switch (this.settings.activeStep) {
      case 'tasks':
        this.settings.selectedTasks = [];
        break;
      case 'territories':
        this.settings.selectedTerritories = [];
        break;
      case 'users':
        this.settings.selectedUsers = [];
        break;
      default:
        break;
    }
    this.setStep('next');
  }

  setNextButtonText(): string {
    switch (this.settings.activeStep) {
      case 'setup':
        return 'Add Tasks';
      case 'tasks':
        return 'Select Territories';
      case 'territories':
        return 'Assign Users';
      default:
        return 'Complete';
    }
  }

  isStepDone(index): boolean {
    const activeStepIndex = this.settings.steps.findIndex((currentStep) => currentStep === this.settings.activeStep);
    return index < activeStepIndex;
  }

  focusNextBtn(): void {
    this.nextBtn.nativeElement.focus();
  }
}
