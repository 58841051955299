import { Component, forwardRef, Input } from '@angular/core';
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITaskField } from '../../../../../../interfaces/task-field.interface';

@Component({
  selector: 'app-task-toggle-list',
  templateUrl: './task-toggle-list.component.html',
  styleUrls: ['./task-toggle-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskToggleListComponent),
      multi: true,
    }
  ],
})
export class TaskToggleListComponent extends InputControlAccessorDirective {
  @Input() name: string;
  @Input() description: string;
  @Input() fields: ITaskField[];

  updateToggleListControl(tgValue: number): void {
    if (super.value && super.value.length) {
      const indexTG = super.value.findIndex(el => el === tgValue);
      super.writeValue(indexTG >= 0 ? super.value.splice(indexTG, 1) : super.value.push(tgValue));
    }
  }
}
