import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IFilterOption } from '../../interfaces/filter-option.interface';

@Component({
  selector: 'app-option-chip',
  templateUrl: './option-chip.component.html',
  styleUrls: ['./option-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionChipComponent {
  @Input() option: IFilterOption;

  @Output() deleteOption: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

}
