import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


// StatusCodeInterceptor intercepting response status codes across the entire application
// in case of receiving not OK the status of the code the trigger catchError 
// in which the general model of an error which will be returned into the calling block of code (error handler)
// depending on the status, a general or more specific message should be displayed
@Injectable()
export class StatusCodeInterceptor implements HttpInterceptor{
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        const error = {
          statusCode: response.status,
          message: this.errorFor[response.status] || response.statusText
        }
        return throwError(error);
      })
    );
  }

  // general descriptions of common error messages are described here
  // if necessary, this list can be extended
  private errorFor = 
  {
    401: 'Unauthorized! Please re-login.',
    404: 'The requested resource was not found.',
    500: 'An error occurred on the server side!',
  }
}