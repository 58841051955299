import { DocumentTypes, DocumentActions } from './document.actions';

export let initialState = {
  supervisorView: undefined,
  documentList: null,
  selectedDocument: 0,
  grid: false,
  contact: undefined,
  documentsCount: null,
  updateDocumentBatch: false,
  documentsBatchId: null,
  importData: undefined
};

export function reducer(state = initialState, action: DocumentActions): object {
  switch (action.type) {
    case DocumentTypes.SET_DOCUMENTS_LIST:
      return {...state, documentList: action.payload};
    case DocumentTypes.SET_SELECTED_DOCUMENT:
      return {...state, selectedDocument: action.payload};
    case DocumentTypes.SET_GRID:
      return {...state, grid: action.payload};
    case DocumentTypes.SET_DOCUMENTS_COUNT:
      return {...state, documentsCount: action.payload};
    case DocumentTypes.UPDATE_DOCUMENTS_BATCH:
      return {...initialState, updateDocumentBatch: action.payload};
    case DocumentTypes.RESTORE_DOCUMENTS:
      return {...initialState};
    case DocumentTypes.SET_SUPERVISOR_STATE:
      return { ...state, supervisorView: action.payload };
    case DocumentTypes.SET_DOCUMENTS_BATCH_DATA:
      return {
        ...initialState,
        documentsBatchId: action.payload.id,
        contact: action.payload.contact,
        importData: action.payload.importData,
        programId: action.payload.programId,
        parentDocumentType: action.payload.parentDocumentType
      };
    default:
      return state;
  }
}
