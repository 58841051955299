export class AddressResolver {
  private _address = undefined;

  public get address() { return this._address; }

  constructor(address: any[] | any = undefined, type = 'Mailing') {
    if(!address) return;

    this.resolve(address, type);
  }

  public resolve(address: any[] | any = undefined, type = 'Mailing') {
    if(!address) return undefined;

    if(Array.isArray(address)) this._resolveInternal(address.find(e => e.type === type));
    else this._resolveInternal(address);

    return this._address;
  }

  private _resolveInternal(address: any) {
    if(!address) {
      this._address = undefined;
      return;
    }

    if(address.addressLine1 && address.addressLine2) this._address = this.resolveFromJson(address);
    else if(address.streetAddress) this._address = this.resolveFromCase(address);
  }

  private resolveFromJson(address): any {
    return {
      streetAddress: address.addressLine1,
      addressExtension: address.addressLine2,
      zipCode: address.zipCode,
      city: address.city,
      state: address.state,
    }
  }

  private resolveFromCase(address): any {
    return { ...address };
  }
}
