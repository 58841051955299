import { Component, Output, EventEmitter, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { NameResolver } from '@shared/utilities/name-resolver';

@Component({
  selector: 'app-data-entry-case',
  templateUrl: './case.component.html',
  styleUrls: ['../physician/physician.component.scss', './case.component.scss'],
})
export class DataEntryCaseComponent {
  @Input()
  get patient(): any {
    return this._patient;
  }

  set patient(patient: any) {
    if (patient) {
      this.title = NameResolver.getNameString(patient);
    }
    else { 
      this.title = '';
    }

    if(this.title) this.title += '\'s';
    
    this._patient = patient;
  }

  @Input() cases = [];

  @Output() submitForm = new EventEmitter();
  @Output() caseSelected = new EventEmitter();
  @Output() caseCreated = new EventEmitter();

  focusedSearch = 0;
  title = '';
  _patient;

  @ViewChild('caseList') caseList: ElementRef;
  @ViewChild('addButton') addButton: ElementRef;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (this.caseList && (event.key === 'Tab' || event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter')) {
      event.preventDefault();
      if (event.key === 'Tab' || event.key === 'ArrowDown') {
        if (this.focusedSearch >= this.caseList.nativeElement.children.length - 1) {
          this.addButton.nativeElement.focus();
          if (this.focusedSearch === this.caseList.nativeElement.children.length) {
            this.focusedSearch = 0;
            this.addButton.nativeElement.blur();
          } else {
            this.focusedSearch = this.caseList.nativeElement.children.length;
          }
        } else {
          this.focusedSearch = this.focusedSearch + 1;
          this.addButton.nativeElement.blur();
        }
      }
      if (event.key === 'ArrowUp') {
        if (this.focusedSearch <= 0) {
          this.addButton.nativeElement.focus();
          this.focusedSearch === -1
            ? (this.focusedSearch = this.caseList.nativeElement.children.length - 1)
            : (this.focusedSearch = this.caseList.nativeElement.children.length);
        } else {
          this.focusedSearch = this.focusedSearch - 1;
          this.addButton.nativeElement.blur();
        }
      }
      if (event.key === 'Enter') {
        if (this.focusedSearch === this.caseList.nativeElement.children.length) {
          this.createCase();
        } else {
          this.selectCase(this.cases[this.focusedSearch]);
        }
      }
    }
  }

  selectCase(selectedCase): void {
    this.caseSelected.emit(selectedCase);
  }

  createCase(): void {
    this.caseCreated.emit();
  }
}
