import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { NouisliderModule } from 'ng2-nouislider';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from '../../material/material.module';
import { SharedModule } from '../../shared/shared.module';
import { ManagerModule } from '../manager/manager.module';
import { QueueCreationComponent } from './components/creation/creation.component';
import { QueueCreationSetupComponent } from './components/creation/components/setup/setup.component';
import { QueueCreationTasksComponent } from './components/creation/components/tasks/tasks.component';
import { QueueCreationTerritoriesComponent } from './components/creation/components/territories/territories.component';
import { QueueCreationUsersComponent } from './components/creation/components/users/users.component';
import { SupervisorDashboardComponent } from './components/dashboard/dashboard.component';
import { SupervisorDashboardQueuesComponent } from './components/dashboard/components/queues/queues.component';
import { EmptyManageQueueComponent } from './components/manage/components/empty/empty.component';
import { SupervisorManageQueueComponent } from './components/manage/manage.component';
import { SupervisorDisableQueueComponent } from './components/manage/components/settings/modals/disable/disable.component';
import { SupervisorSettingsComponent } from './components/manage/components/settings/settings.component';
import { SupervisorAddTaskComponent } from './components/manage/components/tasks/modals/add/add.component';
import { SupervisorDeleteTaskComponent } from './components/manage/components/tasks/modals/delete-dialog/delete-dialog.component';
import { SupervisorTasksComponent } from './components/manage/components/tasks/tasks.component';
import { SupervisorAddTerritoryComponent } from './components/manage/components/territories/components/add/add.component';
import { SupervisorTerritoriesComponent } from './components/manage/components/territories/territories.component';
import { SupervisorQueueTopComponent } from './components/manage/components/top/top.component';
import { SupervisorAddUserComponent } from './components/manage/components/users/components/add/add.component';
import { SupervisorUsersComponent } from './components/manage/components/users/users.component';
import { SupervisorQueueComponent } from './components/queue/queue.component';
import { SupervisorQueueTaskComponent } from './components/queue/components/task/task.component';
import { QueueSelectionComponent } from './dialogs/queue-selection/queue-selection.component';
import { QueueAdjustmentStateComponent } from './components/manage/components/queue-adjustment-state/queue-adjustment-state.component';

export const routes: Routes = [
  {
    path: '',
    component: SupervisorQueueComponent,
    children: [
      {
        path: 'queue-supervisor-task/:id',
        component: SupervisorQueueTaskComponent,
      },
    ],
  },
  {
    path: 'create-queue',
    component: QueueCreationComponent,
  },
  {
    path: 'manage-queue',
    component: SupervisorManageQueueComponent,
  },
];

const components = [
  SupervisorDashboardComponent,
  SupervisorDashboardQueuesComponent,
  SupervisorManageQueueComponent,
  SupervisorQueueTopComponent,
  SupervisorUsersComponent,
  SupervisorTasksComponent,
  SupervisorTerritoriesComponent,
  SupervisorSettingsComponent,
  SupervisorAddUserComponent,
  SupervisorAddTerritoryComponent,
  SupervisorAddTaskComponent,
  SupervisorDeleteTaskComponent,
  SupervisorDisableQueueComponent,
  EmptyManageQueueComponent,
  QueueCreationComponent,
  QueueCreationSetupComponent,
  QueueCreationTasksComponent,
  QueueCreationTerritoriesComponent,
  QueueCreationUsersComponent,
  SupervisorQueueComponent,
  SupervisorQueueTaskComponent,
  QueueSelectionComponent,
  QueueAdjustmentStateComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    MaterialModule,
    NouisliderModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    ChartsModule,
    ManagerModule,
  ]
})
export class SupervisorModule {
}
