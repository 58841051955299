<div class="task-dropdown input-item select">
  <label>{{label}}</label>

  <mat-form-field appearance="fill">
    <mat-select
      [(value)]="value"
      [required]="required"
      [placeholder]="placeholder"
      [id]="id"
      [disableOptionCentering]="disableOptionCentering"
      panelClass="dropdown-item"
      [disabled]="disabled"
    >
      <mat-option>--</mat-option>
      <mat-option *ngFor="let option of options"
                  [value]="option.id"
                  (onSelectionChange)="optionSelected.emit(option)"
      >
        {{option.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
