import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod } from '../../../../../../../../shared/enums/contact-method.enum';
import { ContactType } from '../../../../../../../../shared/enums/contact-type.enum';
import { IFacility } from '../../../../../../../../shared/interfaces/facility.interface';
import { IPhysician } from '../../../../../../../../shared/interfaces/physician.interface';

@Component({
  selector: 'app-section-physician',
  templateUrl: './section-physician.component.html',
  styleUrls: ['./section-physician.component.scss', '../../aside-sections.scss']
})
export class SectionPhysicianComponent implements OnInit, OnChanges {
  @Input() physician: IPhysician;
  @Input() facility: IFacility;

  @Output() update: EventEmitter<IFacility> = new EventEmitter<IFacility>();
  @Output() openManagement: EventEmitter<string> = new EventEmitter<string>();

  ContactMethod = ContactMethod;
  ContactType = ContactType;

  officePhone: string;
  officeFax: string;
  specialty: string;
  sln: string;

  emailForm: FormGroup;
  contactForm: FormGroup;

  isEditContact = false;
  isEditEmail = false;

  constructor(private cdr: ChangeDetectorRef) {
    this.emailForm = new FormGroup({
      email: new FormControl(null, [Validators.email]),
    });
    this.contactForm = new FormGroup({
      contact: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.specialty = this.physician?.taxonomies?.map(x => x.desc).join(', ');
    this.sln = this.physician?.taxonomies?.map(x => x.license).join(', ');
    this.updateContactInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.facility.currentValue !== changes.facility.previousValue) {
      this.updateContactInfo();
    }
  }

  savePhysicianData(save: boolean, inputs: any[]): void {
    if (save) {
      this.setContactInfo(inputs, true);
      this.update.emit(this.facility);
    } else {
      this.setContactInfo(inputs, false);
    }

    this.isEditContact = false;
    this.isEditEmail = false;
    this.cdr.detectChanges();
  }

  openPhysicianEditDialog(type: string): void {
    this.openManagement.emit(type);
  }

  editPhysicianContact(physicianContact): void {
    this.isEditContact = true;
    setTimeout(() => {
      physicianContact.focus();
    });
  }

  editPhysicianEmail(physicianEmail): void {
    this.isEditEmail = true;
    setTimeout(() => {
      physicianEmail.focus();
    });
  }

  private setContactInfo(inputs, save): void {
    let exists = false;

    this.facility.contactInfos.forEach((info) => {
      if (info.contactMethod === inputs[2] && info.contactType === inputs[3]) {
        const obj = {};
        if (inputs[1] === 'contact') {
          obj[inputs[1]] = info.name?.slice();
          save ? info.name = this[inputs[1] + 'Form'].value[inputs[1]]?.slice() : this[inputs[1] + 'Form'].patchValue(obj);
        } else if (inputs[1] === 'email' || inputs[1] === 'mobile') {
          obj[inputs[1]] = info.contactString?.slice();
          save ? info.contactString = this[inputs[1] + 'Form'].value[inputs[1]]?.slice() : this[inputs[1] + 'Form'].patchValue(obj);
        } else {
          save ? info.contactString = this[inputs[1]].slice() : this[inputs[1]] = info.contactString.slice();
        }
        exists = true;
      }
    });

    if (!exists) {
      if (save) {
        this.facility.contactInfos.push({
          name: inputs[1] === 'contact' ? this.contactForm.value.contact : null,
          primary: true,
          contactString: inputs[1] === 'contact' ? this.emailForm.value.email
            : inputs[1] === 'email' || inputs[1] === 'mobile' ? this[inputs[1] + 'Form'].value[inputs[1]] : this[inputs[1]],
          contactType: inputs[3],
          contactMethod: inputs[2],
        });

        this.updateContactInfo();
      } else {
        if (inputs[1] === 'email' || inputs[1] === 'mobile' || inputs[1] === 'contact') {
          const obj = {};
          obj[inputs[1]] = '';
          this[inputs[1] + 'Form'].patchValue(obj);
        } else {
          this[inputs[1]] = '';
        }
      }
    }
  }

  private updateContactInfo(): void {
    this.facility?.contactInfos.forEach((info) => {
      if (info.contactMethod === ContactMethod.phone && info.contactType === ContactType.work) {
        this.officePhone = info.contactString.slice();
      }
      if (info.contactMethod === ContactMethod.email && info.contactType === ContactType.work) {
        this.contactForm.patchValue({contact: info.name?.slice()});
        this.emailForm.patchValue({email: info.contactString?.slice()});
      }
      if (info.contactMethod === ContactMethod.fax && info.contactType === ContactType.work) {
        this.officeFax = info.contactString.slice();
      }
    });
  }

}
