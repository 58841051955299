import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[onMouseWheelClick]' })
export class MouseWheelClickDirective {
  @Output('onMouseWheelClick') onMouseWheelClick = new EventEmitter();

  @HostListener('mousedown', ['$event'])
  mouseWheelClick(event) {
    if (event.which === 2) { 
      event.preventDefault();
      this.onMouseWheelClick.emit(event);
    }
  }
}
