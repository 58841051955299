import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './missed.component.html',
  styleUrls: ['./missed.component.scss'],
})
export class MissedCaseModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly fields,
    public dialog: MatDialogRef<MissedCaseModalComponent>) { }

  public close(isApproved: boolean){
    this.dialog.close(isApproved);
  }
}
