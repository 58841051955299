import { DefaultTabOrdering } from '../parameters/default-tab-ordering';
import { FieldsConfig } from './fields-default.config';
import { TabId } from '../parameters/tab-identifier';
import { BaseConfig } from './base.config';
import { PlanOrder } from '@shared/enums/plan-order.enum';

export class InsuranceConfig extends BaseConfig {
  constructor({ 
    ordering = DefaultTabOrdering[TabId.insurance],
    displayName = 'Insurance',
    nextTabName = undefined }) {
    super(TabId.insurance, ordering, displayName, nextTabName);
  }

  public fieldsConfig = 
  {
    useCheckBox: false,
    showPharmacyInsurance: false,
    canAddNewInsurancePlans: true,
    patientHasInsuranceReversed: false,
    insurancePriorities: [ PlanOrder.primary ],
    patientHasInsurance: new FieldsConfig({ displayName: 'Does the patient have insurance' }),

    /* newPlanForm */
    planName: new FieldsConfig({displayName: 'Plan Name'}),
    planId: new FieldsConfig({displayName: 'Plan Id'}),
    group: new FieldsConfig({displayName: 'Group'}),
    policyHolderName: new FieldsConfig({displayName: 'Policy Holder Name'}),
    policyHolderDOB: new FieldsConfig({ displayName: 'Policy Holder DOB' }),
    policyHolderRelationship: new FieldsConfig({ displayName: 'Policy holder Relationship' }),
    planType: new FieldsConfig({displayName: 'Plan Type'}),
    planOrder: new FieldsConfig({displayName: 'Secondary Plan?'}),
    planPhone: new FieldsConfig({ displayName: 'Plan Phone' }),
    payerId: new FieldsConfig({ displayName: 'Payer Id' }),
    payerPhone: new FieldsConfig({ displayName: 'Payer Phone' }),
    payerName: new FieldsConfig({ displayName: 'Payer Name' }),
    memberId: new FieldsConfig({ displayName: 'Member Id' }),

    /* pharmacyInsuranceForm */
    externalSourcePharmacyBenefitPlanId: new FieldsConfig({ displayName: 'CPR+ Payer ID' }),
    pharmacyInsuranceName: new FieldsConfig({displayName: 'Pharmacy Insurance Name'}),
    phone: new FieldsConfig({displayName: 'Phone'}),
    pharmacyId: new FieldsConfig({displayName: 'Pharmacy ID'}),
    BIN: new FieldsConfig({displayName: 'BIN'}),
    PCN: new FieldsConfig({displayName: 'PCN'}),
    isPBM: new FieldsConfig({ displayName: 'Is PBM' }),
  }
}
