<div mat-dialog-title class="mat-dialog-title">
  <h1><img src="assets/icons/icon-warning.svg" alt="delete"/> Disable Queue</h1>
  <button mat-dialog-close (click)="enableQueue()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <h2>Are you sure you want to disable this queue?</h2>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="disableQueue()">Disable Queue</button>
</div>
