/// Please use interpolation.configurator.ts file for configure behavior of interpolation
/// Make changes to this file only in exceptional cases

import { CustomStyler } from './custom-styler';
import { Matcher } from './matcher';
import { BaseSelector } from './selector-base';
import { SplittedSelector } from './splitted-selector';

export class DefaultSelector extends BaseSelector {
  private model: any;

  public select(selector: string): string {
    if(!this.model) throw new Error('model is not set');

    const splitted = new SplittedSelector(selector);
    const styler = new CustomStyler(splitted.styles);
    const value = this.getValue(splitted.properties);

    const result = styler.canApply ? styler.applyFor(value) : value;

    return result;
  }

  public setModel(model: any): BaseSelector {
    this.model = model; return this;
  }

  public setMatcher(matcher: Matcher): BaseSelector {
    return this;
  }

  private getValue(prop: string[]){
    let value = this.model;

    try {
      prop.forEach(e => value = value[e]);
    }
    catch(exc) {
      throw new Error(`selector is invalid!`);
    }

    return value;
  }
}
