import { FormControl, FormGroup } from '@angular/forms';
import { nameof } from '@shared/utilities/name-resolver';
import { AbstractWarnControl } from 'src/app/shared/models/abstract-warning.control';
import { TabState } from '../../../configuration/tab.state';
import { CustomRequirementsValidator } from '../../../configuration/validators/custom-requirements.validator';

export abstract class BaseFormComponent {
  public static validateForms(state: TabState) {
    const invalid = [];
    const required = [];
    const desired = [];
    
    const forms = Object
      .getOwnPropertyNames(state.forms)
      .filter(prop => state.forms[prop] instanceof FormGroup)
      .map(prop => state.forms[prop] as FormGroup);
    
    const controls = Object
      .getOwnPropertyNames(state.forms)
      .filter(prop => state.forms[prop].hasOwnProperty(nameof<AbstractWarnControl>('SkipValidation')))
      .map(prop => state.forms[prop] as AbstractWarnControl);

    forms.forEach(f => {
      f.markAllAsTouched();
      
      BaseFormComponent.validate(f.controls, invalid, required, desired);
    });

    if (controls.length) BaseFormComponent.validateControlArray(controls, invalid, required, desired);

    state.isValid = !invalid.length;
    state.withWarnings = !!(required.length + desired.length);

    state.validation.invalid = invalid;
    state.validation.required = required;
    state.validation.desired = desired;
  }

  private static validate(form, invalid, required, desired) {
    const controls = Object.getOwnPropertyNames(form).map(ctrl => form[ctrl]);

    BaseFormComponent.validateControlArray(controls, invalid, required, desired);
  }

  private static validateControlArray(controls, invalid, required, desired) {
    controls.forEach(control => {
      if (control instanceof FormGroup) {
        BaseFormComponent.validate(control.controls, invalid, required, desired);
      }
      else {
        BaseFormComponent.validateControl(control, invalid, required, desired);
      }
    });
  }

  private static validateControl(control: AbstractWarnControl, invalid: any[], required: any[], desired: any[]){
    control.updateValueAndValidity();

    if (control.errors) invalid.push(control.errors);
    else if (control.warnings?.required) required.push(control.warnings.required);
    else if (control.warnings?.desired) desired.push(control.warnings.desired);
  }

  public static getControl({field, value = undefined, validators = [], skipValidation = false, disabled = false}): AbstractWarnControl {
    const control: AbstractWarnControl = new FormControl({ value, disabled }, [CustomRequirementsValidator(field), ...validators]);
    control.SkipValidation = skipValidation || !field.isVisible;
    control.warnings = null;
    control.setErrors(null);
    control.updateValueAndValidity();
    return control;
  }

  // abstract static method workaround
  // when you update the TS version maybe you should refactor this to abstract static method
  public static initForms(state: TabState, caseData: any){ throw new Error("Not implemented!"); }

  public isValid(control: AbstractWarnControl) {
    return BaseFormComponent.isControlValid(control);
  }

  public isInvalid(control: AbstractWarnControl) {
    return control.touched && (control.invalid || control.withWarnings);
  }

  public static isControlValid(control: AbstractWarnControl) {
    return control.untouched || (control.valid && !control.withWarnings);
  }
}
