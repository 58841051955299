<div class="content-wrapper">
  <div class="header-wrapper">
    <h1>Upload photo</h1>
    <mat-icon class="icon" (click)="close()">close</mat-icon>
  </div>
  <div class="body-wrapper">
    <div class="drop-area" appDropFile (onFileDropped)="selectPhoto($event)"
      (click)="openChoosingPhotoDialog()" [allowedFiles]="allowedFiles" (onError)="handleError($event)">
      <div class="no-photo-container" *ngIf="!imageFile">
        <img class="icon" src="assets/icons/icon-material-upload.svg"/>
        <div class="caption">Drag and drop here or <span class="clickable">Browse file</span></div>
        <input #fileInput [accept]="allowedFiles.join(',')" style="display: none" type="file" (change)="selectPhoto($event.target.files[0])">
      </div>

      <div class="photo-container" *ngIf="imageFile">
        <image-cropper 
          class="image-cropper"
          [imageFile]="imageFile" 
          [maintainAspectRatio]="true"
          [transform]="transform"
          format="png"
          [roundCropper]="true"
          [alignImage]="'center'"
          (imageCropped)="imageCropped($event)">
        </image-cropper>
        <div class="controls-wrapper">
          <mat-icon (click)="zoomOut()">remove</mat-icon>
          <mat-slider (input)="changeZoom($event)" [min]="minZoom" [max]="maxZoom" step="0.01" [ngModel]="scale"></mat-slider>
          <mat-icon  (click)="zoomIn()">add</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-wrapper">
    <button class="cancel-button" (click)="close()">Cancel</button>
    <button [disabled]="!this.croppedImage" class="save-button" (click)="save()">Save</button>
  </div>
</div>

<div *ngIf="errorMessage" class="error-container">{{errorMessage}}</div>