import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './submit-delete.component.html',
  styleUrls: ['./submit-delete.component.scss']
})
export class SubmitDeleteComponent {

  constructor(private dialogRef: MatDialogRef<SubmitDeleteComponent>) { }

  submit() { 
    this.dialogRef.close(true);
  }
}
