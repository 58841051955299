import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';
import { deepCopy } from '@shared/helpers/utils';
import { ITaskField } from 'src/app/features/manager/components/case/interfaces/task-field.interface';
import { defaultToggleOptions } from './constants/defaultToggleOptions';
import { IToggleOption } from './interfaces/toggle-option.interface';

@Component({
  selector: 'app-task-toggle-group',
  templateUrl: './task-toggle-group.component.html',
  styleUrls: ['./task-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskToggleGroupComponent),
      multi: true,
    }
  ],
})
export class TaskToggleGroupComponent extends InputControlAccessorDirective {
  private _field: ITaskField;

  get field(): ITaskField {
    return this._field;
  }

  additionalStyles = '';

  @Input() set field(value: ITaskField) {
    this._field = value;

    if (this._field.additionalStyles?.length) { 
      this.additionalStyles = this._field.additionalStyles.join(' ');
    }

    const options = this._field.options;

    this.options = options?.length ? options : deepCopy(defaultToggleOptions);
    this.styleClass = options?.length ? 'buttons custom' : 'buttons';

    const optionCaptions = this._field.optionCaptions;

    if (!optionCaptions?.length) {
      return;
    }

    for (let i = 0; i < this._field.optionCaptions?.length; i++) {
      this.options[i].name = this._field.optionCaptions[i];
    }
  }

  @Input() isHistory: boolean;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  styleClass: string;
  options: IToggleOption[] = [];
}
