import { CaseTypes, CaseActions } from './case.actions';

export let initialState = null;

export function reducer(state = initialState, action: CaseActions): object {
  switch (action.type) {
    case CaseTypes.SET_CASE:
      return {...action.payload};
    case CaseTypes.REMOVE_CASE:
      return null;
    default:
      return state;
  }
}
