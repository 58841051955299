import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-breadcrumbs',
  templateUrl: './page-breadcrumbs.component.html',
  styleUrls: ['./page-breadcrumbs.component.scss'],
})
export class PageBreadcrumbsComponent {
  @Input() currentPage = '';

  constructor() { }

}
