export enum PhysicianFormFields {
  hasPhysician = 'Physician',
  officeName = 'Office Contact Name',
  officeEmail = 'Contact Email',
  facilityName = 'Facility Name',
  tax = 'Group Tax ID',
  address = 'Address',
  zipCode = 'ZIP Code',
  officePhone = 'Office Phone',
  officeFax = 'Office fax',
}
