import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITaskSection } from '../../../../../../interfaces/task-section.interface';

@Component({
  selector: 'app-task-section',
  templateUrl: './task-section.component.html',
  styleUrls: ['./task-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSectionComponent {
  @Input() section: ITaskSection;
}
