export enum TaskType {
  'Follow-up with HCP, E' = 1,
  'Welcome call to Patient' = 2,
  'Call Payer' = 3,
  'Call PBM' = 4,
  'Send SOB to Physician' = 5,
  'Follow-up with HCP, BV' = 6,
  'Follow-up with HCP, PA' = 7,
  'Call payer' = 8,
  'Triage to SP' = 9,
  'Complete' = 997,
  'Pending' = 998,
  'Canceled' = 999,
}
