import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactMethod } from 'src/app/shared/enums/contact-method.enum';
import { Matcher } from 'src/app/shared/utilities/string-interpolation/matcher';
import { InterpolatedString } from 'src/app/shared/utilities/string-interpolation/interpolated-string';
import { ITaskHeader } from '../../../../interfaces/task-header.interface';
import { NameResolver } from '@shared/utilities/name-resolver';

@Component({
  selector: 'app-task-header',
  templateUrl: './task-header.component.html',
  styleUrls: ['./task-header.component.scss'],
})
export class TaskHeaderComponent implements OnInit {
  @Input() case: any;
  @Input() headerData: ITaskHeader;
  @Input() selectedTask: any;

  @Output() minimizeTask: EventEmitter<boolean> = new EventEmitter();
  @Output() setSelectedTask: EventEmitter<any> = new EventEmitter();

  date: Date;
  description: string;
  taskName: string;
  attemptNumber: number;

  private readonly interpolatedString: InterpolatedString = new InterpolatedString();

  ngOnInit(): void {
    this.interpolatedString
      .setInitialString(this.selectedTask.description)
      .setMatcher(this.getMatcher())
      .setModel(this.case);

    this.description = this.interpolatedString.toString();
    this.date = this.selectedTask.due ? new Date(this.selectedTask.due) : undefined;
    this.taskName = this.selectedTask.task;
    this.attemptNumber = this.selectedTask.attemptNumber;
  }

  private getMatcher(): Matcher {
    return {
      $PatientPhone: this.case?.patient?.contactInfos?.find(e => e.contactMethod === ContactMethod.phone)?.contactString || ' ',
      '$EscalatedByFullName': this.selectedTask.updatedBy ? NameResolver.getNameString(this.selectedTask.updatedBy) : '',
      '$EscalatedTaskName': this.selectedTask.task || '',
    };
  }
}
