import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { caseTaskFilterData } from '../../../../../../shared/data/caseTaskFilterData';
import { ManagerQueueTaskColumns } from '../../../../../../shared/enums/manager-queue-task-columns.enum';
import { Roles } from '../../../../../../shared/enums/roles.enum';
import { TargetNames } from '../../../../../../shared/enums/target-names.enum';
import { TaskContactMethodNames } from '../../../../../../shared/enums/task-contact-method-names.enum';
import { enumToArray, toSearchString, deepCopy, interpolateTemplate } from '../../../../../../shared/helpers/utils';
import { QueueService } from '../../services/queue.service';
import { throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { QueueStatus } from '@shared/enums/queue-status.enum';
import { QueueItemsMonitoringService } from '@core/signalR/interface/queue-items-monitoring.service';
import { UsersService } from '@shared/services/users.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['../../queue.component.scss', './task.component.scss'],
  providers: [QueueItemsMonitoringService]
})
export class ManagerQueueTaskComponent implements OnInit {
  private queueId: number = undefined;

  QueueStatus = QueueStatus;

  columnsToDisplay = enumToArray(ManagerQueueTaskColumns);
  phases = [];
  tasks = [];
  data = [];
  taskType;
  usersAssigned = [];
  dataSource;
  isLoaded = false;
  isFiltersNotUsed = true;
  userRole: string;
  previousValue = caseTaskFilterData;
  queueConfig = {
    id: '',
    name: '',
    userCanPull: false,
  };

  settings = {
    itemPerPage: 20,
    paginationPageCount: null,
    activePage: 0,
    filter: {
      show: {
        phase: false,
        task: false,
        patient: false,
        physician: false,
        payer: false,
        pharmacy: false,
        dueDate: false,
      },
      value: caseTaskFilterData,
      initialValue: caseTaskFilterData,
    },
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      let shownFilter;
      for (const [key, value] of Object.entries(this.settings.filter.show)) {
        if (this.settings.filter.show[key]) {
          shownFilter = key;
        }
      }
      if (shownFilter) {
        this.applyFilter(shownFilter);
      }
    }
  }

  constructor(
    public queueService: QueueService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<any>,
    private ref: ChangeDetectorRef,
    public usersService: UsersService,
    private queueItemsMonitoringService: QueueItemsMonitoringService,
  ) {
    this.store.select('user').subscribe((state) => {
      this.userRole = state.role;
    });
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.queueId = Number(queryParams.get('id'));

      if (!this.queueId) {
        return this.router.navigate(['/']);
      }

      this.getQueue(this.queueId);
      this.getAllItemsInQueue(this.queueId);

      this.queueItemsMonitoringService.onLockQueueItem(this.queueId).subscribe(
        res => {
          this.updateItemStatus(res, QueueStatus.locked);
        },
        err => {
          console.error(err);
        }
      );

      this.queueItemsMonitoringService.onUnlockQueueItem(this.queueId).subscribe(
        res => {
          this.updateItemStatus(res, QueueStatus.ready);
        },
        err => {
          console.error(err);
        }
      );
    });
  }

  private updateItemStatus(data, status) {
    if(!data.itemId || !this.data) return;

    const lockedItem = this.data.find(e => e.id === data.itemId);

    if(!lockedItem) return;

    lockedItem.queueStatus = status;

    this.loadUserImage(lockedItem, data.userId); // Load image async

    this.ref.detectChanges();
  }

  private loadUserImage(item, userId) {
    item.userImageS3Url = undefined; // Mark as no photo while image is loading

    if(!userId) return;

    this.usersService.getPhotoById(userId).subscribe(
      async photo => {
        item.userImageS3Url = await photo;

        this.ref.detectChanges();
      }
    )
  }

  isSystemAdminOrProgramAdmin(): boolean {
    return this.userRole === Roles.SystemAdmin || this.userRole === Roles.ProgramAdmin;
  }

  isOverdue(date): boolean {
    const taskDate = new Date(date);
    taskDate.setHours(0, 0, 0, 0);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return taskDate < currentDate ? true : false;
  }

  isToday(date): boolean {
    const taskDate = new Date(date);
    const today = new Date();

    return (
      taskDate.getDate() === today.getDate() &&
      taskDate.getMonth() === today.getMonth() &&
      taskDate.getFullYear() === today.getFullYear()
    );
  }

  setActivePage(index): void {
    this.settings.activePage = index;
    this.paginator.pageIndex = index;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  getQueue(id): void {
    this.queueService.getConfigurationFromQueueId(id).subscribe(
      (response) => {
        this.queueConfig = {
          id: response.value.id,
          name: response.value.name,
          userCanPull: response.value.userCanPull,
        };

        this.tasks = [...new Set(response.value.tasks.map(x => x.name))];
        this.phases = [...new Set(response.value.tasks.map(x => x.phase))];
      },
      (error) => throwError(error)
    );
  }

  getAllItemsInQueue(id): void {
    const data = {queueId: id, take: 10000};
    this.queueService.getAllItemsInQueue(data).subscribe(
      (response) => {
        this.data = response.map((queue) => {
          return {
            id: queue.id,
            phase: queue.phase,
            taskName: queue.taskName,
            patient: queue.patient,
            physician: queue.physician,
            payer: queue.payer,
            pharmacy: queue.pharmacy,
            dueDate: queue.due,
            queueStatus: queue.queueStatus,
            lockedByUserId: queue.lockedByUserId,
            caseId: queue.caseId,
            target: queue.target,
          };
        });
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.setPagination();
        this.settings.filter.initialValue = {...this.settings.filter.value};
        this.isLoaded = true;
      },
      (error) => {
        const userIsNotAuthorized = error?.statusCode === 403;

        if (userIsNotAuthorized)
          this.router.navigate(['/']);  //TODO: Some message to user that they are not authorized?
        else
          throwError(error);
      }
    );
  }

  openFilter(filter): void {
    this.previousValue = deepCopy(this.settings.filter.value);
    this.closeAllFilters();
    this.settings.filter.show[filter] = true;
  }

  closeFilter(filter): void {
    if (filter) {
      this.settings.filter.show[filter] = false;
      this.settings.filter.value = this.previousValue;
    }
  }

  closeAllFilters(): void {
    const shownFilters = this.settings.filter.show;
    Object.keys(shownFilters).forEach((k) => {
      shownFilters[k] = false;
    });
    this.settings.filter.value = deepCopy(this.previousValue);
  }

  clearFilter(filter): void {
    switch (filter) {
      case 'phase':
        this.resetFilterPhases();
        break;
      case 'task':
        this.resetFilterTasks();
        break;
      case 'dueDate':
        this.settings.filter.value[filter] = {start: '', end: ''};
        break;
      default:
        this.settings.filter.value[filter] = this.settings.filter.initialValue[filter];
    }
    this.applyFilter(filter);
  }

  clearAllFilters(): void {
    this.settings.filter.value = {
      ...this.settings.filter.initialValue,
      dueDate: {start: '', end: ''},
    };
    this.resetFilterPhases();
    this.resetFilterTasks();
    this.applyFilter('');
  }

  resetFilterPhases(): void {
    const phase = this.settings.filter.value.phase;
    for (const key in phase) {
      if (phase.hasOwnProperty(key)) {
        this.settings.filter.value.phase[key] = false;
      }
    }
  }

  resetFilterTasks(): void {
    const task = this.settings.filter.value.task;
    for (const key in task) {
      if (task.hasOwnProperty(key)) {
        this.settings.filter.value.task[key] = false;
      }
    }
  }

  checkIsFilterUsed(): void {
    this.isFiltersNotUsed =
      !this.isCheckBoxFilterSelected('phase') &&
      !this.isCheckBoxFilterSelected('task') &&
      !!this.settings.filter.value.patient === !!this.settings.filter.initialValue.patient &&
      !!this.settings.filter.value.physician === !!this.settings.filter.initialValue.physician &&
      !!this.settings.filter.value.payer === !!this.settings.filter.initialValue.payer &&
      !!this.settings.filter.value.pharmacy === !!this.settings.filter.initialValue.pharmacy &&
      !this.settings.filter.value.dueDate.start &&
      !this.settings.filter.value.dueDate.end;
  }

  isCheckBoxFilterSelected(filter): boolean {
    return Object.values(this.settings.filter.value[filter]).includes(true);
  }

  setPagination(): void {
    this.settings.paginationPageCount = Math.ceil(this.dataSource.filteredData.length / this.settings.itemPerPage);
  }

  applyFilter(filter): void {
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = this.settings.filter.value;
    this.settings.filter.show[filter] = false;
    this.checkIsFilterUsed();
    this.setPagination();
  }

  openItem(task): void {
    if (this.queueConfig.userCanPull || this.isSystemAdminOrProgramAdmin()) {
      this.router.navigate(['/case'], {queryParams: {id: task.caseId}, state: {taskId: task.id}});
    }
  }

  customFilterPredicate(): (data, filter) => boolean {
    const myFilterPredicate = (data, filter): boolean => {
      let typeFilter = false;
      if (this.taskType !== undefined) {
        typeFilter = typeFilter || this.taskType === data.target;
      } else {
        typeFilter = true;
      }

      let phaseFilter = false;
      if (Object.values(filter.phase).includes(true)) {
        phaseFilter = phaseFilter || filter.phase[data.phase];
      } else {
        phaseFilter = true;
      }

      let taskFilter = false;
      if (Object.values(filter.task).includes(true)) {
        taskFilter = taskFilter || filter.task[data.task];
      } else {
        taskFilter = true;
      }

      const patientFilter = filter.patient
        ? toSearchString(data.patient).includes(toSearchString(filter.patient))
        : true;

      const physicianFilter = filter.physician
        ? toSearchString(data.physician).includes(toSearchString(filter.physician))
        : true;

      const payerFilter = filter.payer ? toSearchString(data.payer).includes(toSearchString(filter.payer)) : true;

      const pharmacyFilter = filter.pharmacy
        ? toSearchString(data.pharmacy).includes(toSearchString(filter.pharmacy))
        : true;

      const fullEndDate = new Date(filter.dueDate.end);
      let dueDateFilter;
      if (filter.dueDate.start && filter.dueDate.end) {
        dueDateFilter =
          new Date(data.dueDate) >= new Date(filter.dueDate.start) &&
          new Date(data.dueDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
      } else if (filter.dueDate.start && !filter.dueDate.end) {
        dueDateFilter = new Date(data.dueDate) >= new Date(filter.dueDate.start);
      } else if (!filter.dueDate.start && filter.dueDate.end) {
        dueDateFilter = new Date(data.dueDate) <= new Date(fullEndDate.setHours(fullEndDate.getHours() + 24));
      } else {
        dueDateFilter = true;
      }

      return (
        typeFilter &&
        phaseFilter &&
        taskFilter &&
        patientFilter &&
        physicianFilter &&
        payerFilter &&
        pharmacyFilter &&
        dueDateFilter
      );
    };
    return myFilterPredicate;
  }
}
