import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractRestService } from '@core/services/abstract-rest.service';
import { IResponse } from '@shared/interfaces/response.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root', })
export class WorkflowService extends AbstractRestService {
  constructor( protected http: HttpClient, ) { super(http); }

  getPhases(caseId: number): Observable<IResponse> {
    this.url = this.apiUrl + `/Workflow/get-phases/${caseId}`;
    
    return this.get<IResponse>();
  }

  getPhasesEstimate(caseId: number): Observable<IResponse> {
    this.url = this.apiUrl + `/Workflow/get-phases-estimate/${caseId}`;
    
    return this.get<IResponse>();
  }

  getAllowedContactMethods(caseId: number, taskId: number): Observable<IResponse> {
    this.url = this.apiUrl + `/Workflow/get-allowed-contact-methods/${caseId}/${taskId}`;
    
    return this.get<IResponse>();
  }
}