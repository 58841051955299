<div class="component-container">
  <button (clickOutside)="close($event)"
          (click)="toggle()"
          (focus)="isFocused = true"
          (blur)="isFocused = false"
          class="options-btn toggleMenuButton"
          [ngClass]="{opened: opened}"
  >
    <img *ngIf="!opened" class="ellipses-icon" src="assets/icons/ellipsis.svg" alt="ellipsis" />
    <img *ngIf="opened" src="assets/icons/close.svg" alt="close" />

    <div class="options" [style.minWidth.px]="minWidth" [hidden]="!opened" [style]="menuStyle">
      <ng-content></ng-content>
    </div>
  </button>
</div>
