import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-queue',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyQueueComponent {
  @Input() type: string;

  constructor() {
  }
}
