import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interpolateTemplate } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-email-task',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailTaskComponent {
  patientFirstName;
  patientLastName;
  createDate;
  subject;
  body;
  case;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.case = data.case;
    this.patientFirstName = data.case.patient.firstName;
    this.patientLastName = data.case.patient.lastName;
    this.createDate = data.sendDate;
    this.subject = data.taskData.subject;
    this.body = interpolateTemplate(data.taskData.body, this.getBodyTemplateObject());
  }

  getBodyTemplateObject(): any {
    const patientName = `${this.case.patient.firstName || ''} ${this.case.patient.lastName || ''}`;
    const physicianLastName = `${this.case.physician?.lastName || ''}`;
    return {
      patientName,
      caseManagerName: 'Heather',
      physicianLastName,
    };
  }
}
