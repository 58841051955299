import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from '../../material/material.module';
// @ts-ignore
import { SharedModule } from '@shared/shared.module';
import { ManagerDashboardCaseComponent } from './components/case/case.component';
import { SectionPatientComponent } from './components/case/components/aside-sections/components/section-patient/section-patient.component';
import { EditableAsideFieldComponent } from './components/case/components/aside-sections/components/editable-aside-field/editable-aside-field.component';
import { HistoryComponent } from './components/case/components/history/history.component';
import { NavigationComponent } from './components/case/components/navigation/navigation.component';
import { BenefitCaseComponent } from './components/case/components/phases/components/benefit/benefit.component';
import { EnrollmentComponent } from './components/case/components/phases/components/enrollment/enrollment.component';
import { FulfillmentComponent } from './components/case/components/phases/components/fulfillment/fulfillment.component';
import { NewOptInFormComponent } from './components/case/components/phases/components/new-opt-in-form/new-opt-in-form.component';
import { OptOutFormComponent } from './components/case/components/phases/components/opt-out-form/opt-out-form.component';
import { SectionPhysicianComponent } from './components/case/components/aside-sections/components/section-physician/section-physician.component';
import { PhysicianEditModalComponent } from './components/case/components/modals/physician-edit-modal/physician-edit-modal.component';
import {
  CreateActivityDialogComponent
} from './components/case/components/modals/create-activity-dialog/create-activity-dialog.component';
import { SectionNotesComponent } from './components/case/components/aside-sections/components/section-notes/section-notes.component';
import { NewAddressFormComponent } from './components/case/components/phases/components/new-address-form/new-address-form.component';
import {
  ConfirmAddressDialogComponent
} from './components/case/components/modals/confirm-address-dialog/confirm-address-dialog.component';
import {
  AddressHistoryDialogComponent
} from './components/case/components/modals/address-history-dialog/address-history-dialog.component';
import { NewCaregiverFormComponent } from './components/case/components/phases/components/new-caregiver-form/new-caregiver-form.component';
import { ConfirmCaregiverDialogComponent } from './components/case/components/modals/confirm-caregiver-dialog/confirm-caregiver-dialog.component';
import { SectionAttachmentsComponent } from './components/case/components/aside-sections/components/section-attachments/section-attachments.component';
import { AdherenceComplianceTaskComponent } from './components/case/components/task/components/adherence/components/compliance/compliance.component';
import { AdherenceWelcomeTaskComponent } from './components/case/components/task/components/adherence/components/welcome/welcome.component';
import { BenefitTaskComponent } from './components/case/components/task/components/benefit/benefit.component';
import {
  BenefitCompletionComponent
} from './components/case/components/task/components/benefit/components/completion/completion.component';
import { BenefitMedicalAOBComponent } from './components/case/components/task/components/benefit/components/medical-aob/medical-aob.component';
import { BenefitMedicalBBComponent } from './components/case/components/task/components/benefit/components/medical-bb/medical-bb.component';
import { BenefitPharmacyComponent } from './components/case/components/task/components/benefit/components/pharmacy/pharmacy.component';
import { BenefitPlanComponent } from './components/case/components/task/components/benefit/components/plan/plan.component';
import { FollowupBenefitTaskComponent } from './components/case/components/task/components/followup-benefit/followup-benefit.component';
import { FollowupEnrollmentTaskComponent } from './components/case/components/task/components/followup-enrollment/followup-enrollment.component';
import { FollowupPriorTaskComponent } from './components/case/components/task/components/followup-prior/followup-prior.component';
import { TriageTaskComponent } from './components/case/components/task/components/triage/triage.component';
import { WelcomeTaskComponent } from './components/case/components/task/components/welcome/welcome.component';
import { EmailTaskComponent } from './components/case/components/task/modals/email/email.component';
import { ExhaustedTaskComponent } from './components/case/components/dynamic-tasks-renderer/dialogs/exhausted/exhausted.component';
import { RescheduleTaskComponent } from './components/case/components/dynamic-tasks-renderer/dialogs/reschedule/reschedule.component';
import { TextTaskComponent } from './components/case/components/task/modals/text/text.component';
import { TaskCaseComponent } from './components/case/components/task/task.component';
import { TimelineComponent } from './components/case/components/timeline/timeline.component';
import { ManagerDashboardCasesComponent } from './components/dashboard/components/cases/cases.component';
import { ManagerDashboardComponent } from './components/dashboard/dashboard.component';
import { ManagerDashboardFilterComponent } from './components/dashboard/components/filter/filter.component';
import { ManagerDashboardQueuesComponent } from './components/dashboard/components/queues/queues.component';
import { ManagerQueueDataComponent } from './components/queue/components/data/data.component';
import { EmptyQueueComponent } from './components/queue/components/empty/empty.component';
import { ManagerQueueIdentifyComponent } from './components/queue/components/identify/identify.component';
import { ManagerQueueComponent } from './components/queue/queue.component';
import { ManagerQueueTaskComponent } from './components/queue/components/task/task.component';
import { TaskHeaderComponent } from './components/case/components/dynamic-tasks-renderer/components/task-header/task-header.component';
import { DynamicTasksRendererComponent } from './components/case/components/dynamic-tasks-renderer/dynamic-tasks-renderer.component';
import { TaskFooterComponent } from './components/case/components/dynamic-tasks-renderer/components/task-footer/task-footer.component';
import { TaskBodyComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/task-body.component';
import { TaskElementsParserComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-elements-parser/task-elements-parser.component';
import { TaskDropdownComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-dropdown/task-dropdown.component';
import { TaskInputComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-input/task-input.component';
import { TaskToggleGroupComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-toggle-group/task-toggle-group.component';
import { TaskDatepickerComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-datepicker/task-datepicker.component';
import { TaskDatepickerRangeComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-datepicker-range/task-datepicker-range.component';
import { RoleGuard } from '../../core/guards/role.guard';
import { Roles } from '@shared/enums/roles.enum';
import { TaskAddButtonComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-add-button/task-add-button.component';
import { TaskTextareaComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-textarea/task-textarea.component';
import { TaskStaticTextComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-static-text/task-static-text.component';
import { TaskContactItemComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-contact-item/task-contact-item.component';
import { TaskSelectContactComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-select-contact/task-select-contact.component';
import { ContactOptionComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-select-contact/contact-option/contact-option.component';
import { TaskPreviewDocumentComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-preview-document/task-preview-document.component';
import { TaskSelectAttachmentsComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-select-attachments/task-select-attachments.component';
import { TaskMessageComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-message/task-message.component';
import { TaskCheckboxesAdvancedComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-checkboxes-advanced/task-checkboxes-advanced.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TaskToggleListComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-toggle-list/task-toggle-list.component';
import { TaskSectionComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-section/task-section.component';
import { AttachmentsListDialogComponent } from './components/case/components/dynamic-tasks-renderer/dialogs/attachments-list-dialog/attachments-list-dialog.component';
import { EscalateTaskComponent } from './components/case/components/dynamic-tasks-renderer/dialogs/escalate-task/escalate-task.component';
import { CheckBoxListComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-checkboxes-advanced/check-box-list/check-box-list.component';
import { HistoryDetailsDialogComponent } from './components/case/components/modals/history-details-dialog/history-details-dialog.component';
import { TaskCreateActivityComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-create-activity/task-create-activity.component';
import { TaskProgressComponent } from './components/case/components/dynamic-tasks-renderer/components/task-body/components/task-progress/task-progress.component';
import { CaseAuthorizationComponent } from './components/case/components/phases/components/case-authorization/case-authorization.component';
import { SubmitCloseCaseComponent } from './components/case/components/dynamic-tasks-renderer/dialogs/submit-close-case/submit-close-case.component';
import { SectionInsurancesComponent } from './components/case/components/aside-sections/components/section-insurances/section-insurances.component';
import { EditMedicalPlanDialogComponent } from './components/case/components/aside-sections/components/dialogs/edit-medical-plan-dialog/edit-medical-plan-dialog.component';
import { ConsentItemComponent } from './components/case/components/phases/components/enrollment/consent-item/consent-item.component';
import { InsurancesComponent } from './components/case/components/phases/components/insurances/insurances.component';
import { InsurancesTableComponent } from './components/case/components/phases/components/insurances-table/insurances-table.component';
import { InsuranceDetailsDialogComponent } from './components/case/components/modals/insurance-details-dialog/insurance-details-dialog.component';

export const routes: Routes = [
  {
    path: '',
    component: ManagerQueueComponent,
    children: [
      {
        path: 'queue-identify',
        canActivate: [RoleGuard],
        data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin, Roles.AgentRestricted, Roles.AgentAll]},
        component: ManagerQueueIdentifyComponent,
      },
      {
        path: 'queue-data-entry',
        canActivate: [RoleGuard],
        data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin, Roles.AgentRestricted, Roles.AgentAll]},
        component: ManagerQueueDataComponent,
      },
      {
        path: 'queue-manager-task',
        canActivate: [RoleGuard],
        data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin, Roles.AgentRestricted, Roles.AgentAll]},
        component: ManagerQueueTaskComponent,
      },
    ],
  },
  {
    path: 'case',
    canActivate: [RoleGuard],
    data: {roles: [Roles.SystemAdmin, Roles.ProgramAdmin, Roles.AgentRestricted, Roles.AgentAll]},
    component: ManagerDashboardCaseComponent,
  },
];

const components = [
  ManagerDashboardCaseComponent,
  SectionPatientComponent,
  EditableAsideFieldComponent,
  NewOptInFormComponent,
  OptOutFormComponent,
  SectionPhysicianComponent,
  PhysicianEditModalComponent,
  CreateActivityDialogComponent,
  NewAddressFormComponent,
  SectionNotesComponent,
  SectionAttachmentsComponent,
  ConfirmAddressDialogComponent,
  AddressHistoryDialogComponent,
  NewCaregiverFormComponent,
  ConfirmCaregiverDialogComponent,
  ManagerDashboardComponent,
  ManagerDashboardFilterComponent,
  ManagerDashboardCasesComponent,
  ManagerDashboardQueuesComponent,
  ManagerQueueComponent,
  ManagerQueueDataComponent,
  ManagerQueueIdentifyComponent,
  ManagerQueueTaskComponent,
  TimelineComponent,
  TaskCaseComponent,
  TextTaskComponent,
  RescheduleTaskComponent,
  ExhaustedTaskComponent,
  EmailTaskComponent,
  WelcomeTaskComponent,
  TriageTaskComponent,
  FollowupPriorTaskComponent,
  FollowupEnrollmentTaskComponent,
  FollowupBenefitTaskComponent,
  BenefitTaskComponent,
  BenefitPlanComponent,
  BenefitMedicalBBComponent,
  BenefitMedicalAOBComponent,
  BenefitPharmacyComponent,
  BenefitCompletionComponent,
  AdherenceWelcomeTaskComponent,
  AdherenceComplianceTaskComponent,
  EmptyQueueComponent,
  HistoryComponent,
  NavigationComponent,
  EnrollmentComponent,
  BenefitCaseComponent,
  CaseAuthorizationComponent,
  FulfillmentComponent,
  DynamicTasksRendererComponent,
  TaskHeaderComponent,
  TaskFooterComponent,
  TaskBodyComponent,
  TaskElementsParserComponent,
  TaskDropdownComponent,
  TaskInputComponent,
  TaskToggleGroupComponent,
  TaskDatepickerComponent,
  TaskDatepickerRangeComponent,
  TaskAddButtonComponent,
  TaskTextareaComponent,
  TaskStaticTextComponent,
  TaskContactItemComponent,
  TaskSelectContactComponent,
  ContactOptionComponent,
  TaskPreviewDocumentComponent,
  TaskSelectAttachmentsComponent,
  TaskMessageComponent,
  TaskCheckboxesAdvancedComponent,
  AttachmentsListDialogComponent,
  TaskSectionComponent,
  TaskToggleListComponent,
  EscalateTaskComponent,
  CheckBoxListComponent,
  HistoryDetailsDialogComponent,
  TaskCreateActivityComponent,
  TaskProgressComponent,
  SubmitCloseCaseComponent,
  SectionInsurancesComponent,
  EditMedicalPlanDialogComponent,
  SubmitCloseCaseComponent,
  ConsentItemComponent,
  InsurancesComponent,
  InsurancesTableComponent,
];

@NgModule({
  declarations: [
    ...components,
    InsuranceDetailsDialogComponent,
  ],
  exports: [
    ...components,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule,
    NgScrollbarModule,
    ClickOutsideModule,
    NgxMaskModule,
    NgxMaterialTimepickerModule,
    NouisliderModule,
    DragDropModule,
  ],
})
export class ManagerModule {
}
