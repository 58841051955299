import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { TaskOutcome } from '@shared/enums/task-outcome.enum';
import { CaseService } from '../../../../services/case.service';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-task-triage',
  templateUrl: './triage.component.html',
  styleUrls: ['../../task.component.scss', './triage.component.scss'],
})
export class TriageTaskComponent implements OnInit {
  @Input() selectedTask;
  @Input() case;

  @Output() minimizeTaskHandler = new EventEmitter();
  @Output() setSelectedTaskHandler = new EventEmitter();
  @Output() rescheduleTaskHandler = new EventEmitter();
  @Output() submitTaskHandler = new EventEmitter();

  triageForm: FormGroup;
  selectedPharmacy = null;
  pharmacies;

  constructor(public caseService: CaseService) {
    this.triageForm = new FormGroup({
      note: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.getSpecialtyPharmacies();
    this.selectedPharmacy = this.selectedTask.selectedPharmacy;
    if (this.selectedTask.formData) {
      this.triageForm.patchValue(this.selectedTask.formData);
    }
  }

  minimizeTask(val: boolean): void {
    this.selectedTask.formData = this.triageForm.getRawValue();
    this.selectedTask.selectedPharmacy = this.selectedPharmacy;
    this.minimizeTaskHandler.emit(val);
  }

  setSelectedTask(task): void {
    this.setSelectedTaskHandler.emit(task);
  }

  selectPharmacy(pharmacy): void {
    this.selectedPharmacy = pharmacy;
  }

  submitTask(): void {
    const data = {
      queueItemId: this.case.id,
      queueTaskId: this.selectedTask.id,
      taskOutcome: TaskOutcome.success,
      outcomeNote: this.triageForm.value.note,
    };
    this.submitTaskHandler.emit(data);
    this.setFulfillments();
  }

  getSpecialtyPharmacies(): void {
    const data = {
      take: 0,
    };
    this.caseService.getSpecialtyPharmacies(data).subscribe(
      (response) => {
        this.pharmacies = response.value.map((pharmacy) => {
          return {
            id: pharmacy.id,
            name: pharmacy.name,
            phone: pharmacy.contactInfos.find((contact) => contact.contactMethod === ContactMethod.phone)
              .contactString,
            fax: pharmacy.contactInfos.find((contact) => contact.contactMethod === ContactMethod.fax)
              .contactString,
          };
        });
      },
      (error) => throwError(error)
    );
  }

  setFulfillments(): void {
    const data = {
      fulfillment: {
        specialtyPharmacyId: this.selectedPharmacy.id,
        caseId: this.case.id,
        productId: this.case.prescription.product?.id
      },
    };
    this.caseService.putFulfillments(data).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => throwError(error)
    );
  }
}
