<div class="checkboxes-advanced-container">
  <p class="label">{{field.name}}</p>
  <p *ngIf="!isHistory" class="description">{{field.description}}</p>

  <app-check-box-list [(ngModel)]="value"
                      (changed)="updateValue($event)"
                      [field]="field"
                      [options]="field.options"
                      [isHistory]="isHistory"
                      [disabled]="disabled"
  >
  </app-check-box-list>
</div>
