import { Component, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AddressType } from '../../../../../../../../../../shared/enums/address-type.enum';
import { ConsentTypeId } from '../../../../../../../../../../shared/enums/consent-type-id.enum';
import { ContactType } from '../../../../../../../../../../shared/enums/contact-type.enum';
import { HIPAAConsent } from '../../../../../../../../../../shared/enums/HIPAA-consent.enum';
import { getConsentValue, phoneValidator, ssnValidator } from '../../../../../../../../../../shared/helpers/utils';
import { CaseService } from '../../../../../../../../../manager/components/case/services/case.service';
import { DataEntryFormService } from '../../services/form.service';
import { throwError } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import * as moment from 'moment';
import { TabState } from '../../../../configuration/tab.state';
import { BaseFormComponent } from '../base-form.component';
import { AbstractWarnControl } from 'src/app/shared/models/abstract-warning.control';
import { enumResolver, EnumRule } from 'src/app/shared/utilities/enum-resolver';
import { Gender } from 'src/app/shared/enums/gender.enum';
import { BestTimeToContact } from 'src/app/shared/enums/best-time-to-contact.enum';
import { ContactMethod } from 'src/app/shared/enums/contact-method.enum';
import { AddressResolver } from 'src/app/shared/utilities/address-resolver';
import { SupportingDocumentation } from 'src/app/shared/enums/supporting-documentation.enum';
import { SupportingDocumentationUtils } from 'src/app/shared/utilities/supporting-documentation.utils';
import { Emptiness } from 'src/app/shared/utilities/empty-checks';
import { NumberValidator } from '@shared/validators/number.validator';
import { NameResolver } from '@shared/utilities/name-resolver';
import { RegexPattern } from '@shared/utilities/regex-patterns';
import { PickListService } from '@shared/services/pick-list.service';

@Component({
  selector: 'app-intake-document-patient-form',
  templateUrl: './patient.component.html',
  styleUrls: ['../../form.component.scss', './patient.component.scss', '../physician/physician.component.scss'],
})
export class PatientComponent extends BaseFormComponent implements OnInit, AfterViewInit {
  SupportingDocumentation = SupportingDocumentation;

  @Input('state') public state: TabState;
  @Input('isLoading') public isFormLoading: boolean;
  @Input('newPatientName') public newPatientName: string;
  @Input() get case(): any { return this._case; }
           set case(newCase: any) { this.setNewCase(newCase); }

  @Output() submitForm = new EventEmitter();
  @Output() setPatient = new EventEmitter();

  @ViewChild('addressField') addressField: ElementRef;

  private _case;

  public patientForm: FormGroup;
  public relationships = [];
  public disabledDate = new Date();
  public focusOnAddress = false;
  public showAdditionalDocumentationText = true;

  constructor(
    private readonly pickListService: PickListService,
    private readonly caseService: CaseService,
    private readonly mapsAPILoader: MapsAPILoader,
    private readonly dataEntryService: DataEntryFormService,
  ) { super(); }

  ngOnInit(): void {
    this.state.SaveState = () => this.saveState();
    this.patientForm = this.state.forms.patientForm;

    if(this.newPatientName) this.setNameOfNewPatient(this.newPatientName);

    this.updateForm();

    this.loadCollections();
  }

  ngAfterViewInit(): void {
    if (this.state.config.fieldsConfig.address.isReadOnly === false) { 
      this.initPlaceAutocomplete();
    }
  }

  private loadCollections() { 
    if (this.state.config.fieldsConfig.caregiverRelationship.isVisible) { 
      this.loadRelationships();
    }
  }

  private loadRelationships() { 
    this.pickListService
      .getAllRelationships()
      .subscribe(
        res => {
          if (res.success) this.relationships = res.value;
        }
      );
  }

  private setNewCase(newCase){
    if (this._case || !newCase) return

    this._case = newCase;
    this.selectHipaaConsent();
  }

  public static initForms(state: TabState, caseData: any) {
    state.forms.patientForm =  PatientComponent.getPatientForm(state.config.fieldsConfig);
    PatientComponent.populateForm(state.forms.patientForm, caseData, state.autofillData);
    state.forms.patientForm.updateValueAndValidity();
  }

  private setNameOfNewPatient(name) {
    const nameObj = NameResolver.parse(name);

    if(nameObj.firstName && !this.patientForm.value.firstName) this.patientForm.controls['firstName'].setValue(nameObj.firstName);
    if(nameObj.middleName && !this.patientForm.value.middleName) this.patientForm.controls['middleName'].setValue(nameObj.middleName);
    if(nameObj.lastName &&!this.patientForm.value.lastName) this.patientForm.controls['lastName'].setValue(nameObj.lastName);
  }

  private static getPatientForm(field) {
    return new FormGroup({
      firstName: this.getControl({ field: field.firstName }),
      lastName: this.getControl({ field: field.lastName }),
      middleName: this.getControl({ field: field.middleName }),

      caregiverName: this.getControl({ field: field.caregiverName }),
      caregiverPhone: this.getControl({ field: field.caregiverPhone, validators: [phoneValidator] }),
      caregiverRelationship: this.getControl({ field: field.caregiverRelationship }),

      dateOfBirth: this.getControl({field: field.dateOfBirth, disabled: field.dateOfBirth.isReadOnly}),

      gender: this.getControl({field: field.gender, disabled: field.gender.isReadOnly}),
      email: this.getControl({field: field.email, validators: [Validators.email]}),

      bestTimeToContact: this.getControl({field: field.bestTimeToContact, disabled: field.bestTimeToContact.isReadOnly}),
      bestMethodToContact: this.getControl({field: field.bestMethodToContact, disabled: field.bestMethodToContact.isReadOnly}),

      hipaaConsent: this.getControl({field: field.hipaaConsent, disabled: field.hipaaConsent.isReadOnly}),
      hipaaConsentSignatureDate: this.getControl({field: field.hipaaConsentSignatureDate}),
      socialSecurityNumber:  this.getControl({field: field.socialSecurityNumber, validators: [ssnValidator]}),

      legalUSResident: this.getControl({field: field.legalUSResident, disabled: field.legalUSResident.isReadOnly}),
      householdPeople: this.getControl({field: field.householdPeople, validators: [NumberValidator()]}),
      householdIncome: this.getControl({field: field.householdIncome, validators: [NumberValidator()]}),

      supportingDocumentation: this.getControl({field: field.supportingDocumentation, disabled: field.supportingDocumentation.isReadOnly}),
      supportingDocumentationText: this.getControl({field: field.supportingDocumentationText, skipValidation: true}),
      
      signature: this.getControl({field: field.signature}),
      signatureDate: this.getControl({field: field.signatureDate, disabled: field.signatureDate.isReadOnly, skipValidation: true}),
      
      address: new FormGroup({
        city: this.getControl({ field: field.city }),
        state: this.getControl({ field: field.state }),
        zipCode: this.getControl({ field: field.zipCode }),
        streetAddress: this.getControl({field: field.streetAddress}),
        addressExtension: this.getControl({field: field.addressExtension})
      }),

      phoneInfo: new FormGroup({
        contactType: this.getControl({field: field.contactType, disabled: field.contactType.isReadOnly}),
        contactString: this.getControl({field: field.contactString, validators: [phoneValidator]}),
      }),
    });
  }

  private static populateForm(form, caseData, json) {
    if(!caseData?.patient && !json?.patient) return;

    const jsonPatient: any = json?.patient || {};
    const casePatient: any = caseData?.patient || {};

    const controls = form.controls;
    const phoneControls = controls['phoneInfo']['controls'];
    const addressControls = controls['address']['controls'];

    const emp = new Emptiness();
    const addressResolver = new AddressResolver();

    const contactInfos = casePatient.contactInfos;
    const hipaaConsent = casePatient.currentConsents?.find(x => x.consentTypeId === ConsentTypeId.hipaa);
    const casePatientAddress = casePatient?.currentMailingAddress?.address || {};
    const address = casePatientAddress.city || casePatientAddress.zipCode
      ? addressResolver.resolve(casePatientAddress)
      : addressResolver.resolve(jsonPatient.addresses, 'Mailing');
    let dateOfBirth = casePatient.dateOfBirth || jsonPatient.dateOfBirth;

    if (!!dateOfBirth) {
      dateOfBirth = new Date(dateOfBirth);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (dateOfBirth > today) 
        dateOfBirth = today;
    }

    const gender = enumResolver(emp.getValueOrDefault(casePatient.gender, jsonPatient.gender), Gender, EnumRule.firstLetterLow);
    const bestTimeToContact = enumResolver(emp.getValueOrDefault(casePatient.bestTimeToContact, jsonPatient.bestTimeToContact), BestTimeToContact);
    const bestMethodToContact = enumResolver(emp.getValueOrDefault(casePatient.bestMethodToContact, jsonPatient.contactMethod), ContactMethod, EnumRule.firstLetterLow);
    const email = contactInfos?.find(e => e.contactMethod === ContactMethod.email)?.contactString || jsonPatient.emailAddress;
    const documentation = caseData.supportingDocumentation
      ? SupportingDocumentationUtils.getArray(JSON.parse(caseData.supportingDocumentation))
      : SupportingDocumentationUtils.getArray({ mostRecentTaxReturn: false, w2Form: false, other: false, });
    let phoneInfo = contactInfos?.find(e => e.contactMethod === ContactMethod.phone);

    // if phoneInfo from case is empty - check json phoneNumbers and when exist set this as phoneInfo
    if(!phoneInfo?.contactString && jsonPatient?.phoneNumbers?.length) {
      const contact = jsonPatient?.phoneNumbers[0];
      if(contact) phoneInfo = {
        contactType: enumResolver(contact.type, ContactType, EnumRule.firstLetterLow),
        contactString: contact.number
      };
    }

    controls.firstName.patchValue(casePatient.firstName || jsonPatient.firstName);
    controls.middleName.patchValue(casePatient.middleName || jsonPatient.middleInitial);
    controls.lastName.patchValue(casePatient.lastName || jsonPatient.lastName);
    controls.dateOfBirth.patchValue(dateOfBirth);
    controls.gender.patchValue(gender);
    controls.bestTimeToContact.patchValue(bestTimeToContact);
    controls.bestMethodToContact.patchValue(bestMethodToContact);
    controls.email.patchValue(email);
    controls.hipaaConsent.patchValue(getConsentValue(hipaaConsent));
    controls.hipaaConsentSignatureDate.patchValue(hipaaConsent?.dateReceived);
    controls.socialSecurityNumber.patchValue(casePatient.socialSecurityNumber || jsonPatient.ssn);
    phoneControls.contactType.patchValue(phoneInfo?.contactType);
    phoneControls.contactString.patchValue(phoneInfo?.contactString);

    if(address) {
      addressControls.city.patchValue(address.city);
      addressControls.state.patchValue(address.state);
      addressControls.zipCode.patchValue(address.zipCode);
      addressControls.streetAddress.patchValue(address.streetAddress);
      addressControls.addressExtension.patchValue(address.addressExtension);
    }

    // these fields is not present in json data for now
    controls.legalUSResident.patchValue(casePatient.legalUSResident);
    controls.householdIncome.patchValue(caseData.totalHouseholdIncome);
    controls.householdPeople.patchValue(caseData.numberPeopleInHousehold);
    controls.supportingDocumentationText.patchValue(caseData.otherSupportingDocumentationText);
    controls.supportingDocumentation.patchValue(documentation);
    controls.signature.patchValue(caseData.patientSignature);
    controls.signatureDate.patchValue(caseData.patientSignatureDate);

    const caregiver = casePatient.caregivers?.find(e => e.current);

    if (caregiver) { 
      const caregiverName = NameResolver.getNameString(caregiver);
      const caregiverPhone = caregiver.contactInfos?.find(e => e.contactMethod === ContactMethod.phone)?.contactString;

      controls.caregiverName.patchValue(caregiverName);
      controls.caregiverPhone.patchValue(caregiverPhone);
      controls.caregiverRelationship.patchValue(caregiver.relationshipId);
    }

    form.updateValueAndValidity();

    PatientComponent.updateValidations(form);
  }

  private async initPlaceAutocomplete() {
    if(!this.addressField) return;

    await this.mapsAPILoader.load();

    const autocomplete = new google.maps.places.Autocomplete(
      this.addressField.nativeElement,
      {
        componentRestrictions: { country: 'US' },
        fields: ['address_components', 'formatted_address']
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();

      const city = place.address_components
        ? place.address_components.find(x => x.types.includes('locality'))
        : null;
      
      const state = place.address_components
        ? place.address_components.find(x => x.types.includes('administrative_area_level_1'))
        : null;
      
      const zipCode = place.address_components
        ? place.address_components.find(x => x.types.includes('postal_code'))
        : null;

      const splittedAddress = place.formatted_address
        ? place.formatted_address.split(', ')
        : [];
      
      const address = splittedAddress.length > 0
        ? splittedAddress[0]
        : '';

      this.patientForm.get('address.zipCode').setValue(zipCode ? zipCode.long_name : '');
      this.patientForm.get('address.streetAddress').setValue(address);
      this.patientForm.get('address.city').setValue(city.long_name);
      this.patientForm.get('address.state').setValue(state.short_name);
    });
  }

  static updateValidations(form) {
    setTimeout(() => {
      (form.controls.signatureDate as AbstractWarnControl).SkipValidation = form.controls.signature.value !== 0;
      (form.controls.supportingDocumentationText as AbstractWarnControl).SkipValidation = form.controls.supportingDocumentation.value !== -1;
    }, 2);
  }

  selectHipaaConsent(): void {
    setTimeout(() => {
      const formValue = this.patientForm.getRawValue();

      if (formValue.hipaaConsent === null ||
        formValue.hipaaConsent === 1 ||
        formValue.hipaaConsent === 2) {
        this.patientForm.patchValue({
          hipaaConsentSignatureDate: null
        });
      }
      (this.patientForm.controls['hipaaConsentSignatureDate'] as AbstractWarnControl).SkipValidation = +formValue.hipaaConsent === 0;
      this.patientForm.get('hipaaConsentSignatureDate').updateValueAndValidity();
    });
  }

  updateForm() {
    PatientComponent.updateValidations(this.patientForm);

    setTimeout(() => {
      const doc = this.patientForm.controls.supportingDocumentation.value;
      this.showAdditionalDocumentationText = doc?.some(e => e === SupportingDocumentation.other);
    }, 1);
  }

  toggleAddressFocus(value): void {
    this.focusOnAddress = value;
  }

  updatePatient(data): void {
    this.caseService.updatePatient(data).subscribe(
      (response) => {  /* Magic 💩 */  },
      (error) => throwError(error)
    );
  }

  saveCaseData() {
    return new Promise(resolve => {
      const formValue = this.patientForm.getRawValue();

      this.case.patientSignature = formValue.signature;
      this.case.patientSignatureDate = formValue.signatureDate;
      this.case.totalHouseholdIncome = +formValue.householdIncome;
      this.case.numberPeopleInHousehold = +formValue.householdPeople;
      this.case.supportingDocumentation = JSON.stringify(SupportingDocumentationUtils.getObject(formValue.supportingDocumentation));
      this.case.otherSupportingDocumentationText = formValue.supportingDocumentationText;

      this.caseService.updateCase(this.case).subscribe(
        (res) => {resolve(res);},
        (error) => { console.error(error) },
      );
    })
  }

  setPatientHandler(patient): void {
    this.setPatient.emit(patient);
  }

  saveAddress(): Promise<any> {
    const address = this.getAddressObject();
    const data = { patientAddress: address };

    if (address.id) {
      return new Promise((resolve) => this.caseService.updateAddress(data).subscribe(
        (response) => {
          this._case.patient.currentMailingAddress = address;
          resolve(response);
        },
        (error) => throwError(error))
      );
    }

    return new Promise((resolve) => this.caseService.createAddress(data).subscribe(
      (response) => {
        address.id = response.value;
        this._case.patient.currentMailingAddressId = address.id;
        this._case.patient.currentMailingAddress = address;
        resolve(response);
      },
      (error) => throwError(error))
    );
  }

  saveConsent(): Promise<any> {
    if (!this.state.config.fieldsConfig.hipaaConsent.isVisible) { 
      return Promise.resolve();
    }

    const consent = this.getConsentObject();

    if (!consent) return;

    const data = { consent };

    if (consent.id) {
      return new Promise((resolve) => this.dataEntryService.updateConsent(data).subscribe(
        (response) => {
          const index = this._case.patient.currentConsents.findIndex(x => x.id === consent.id);
          this._case.patient.currentConsents[index] = consent;
          resolve(response);
        },
        (error) => throwError(error))
      );
    }

    return new Promise((resolve) => this.caseService.createEnrollmentConsent(data).subscribe(
      (response) => {
        consent.id = response.value;
        this._case.patient.currentConsents.push(consent);
        resolve(response);
      },
      (error) => throwError(error))
    );
  }

  savePatient(): void {
    this._case.patient = this.getPatientCaseObject();

    const data = { patient: this._case.patient };

    this.updatePatient(data);
    this.setPatientHandler(data.patient);
  }

  submitHandler(): any {
    if (this.focusOnAddress) return;

    this.saveState(() => this.goNext());
  }

  private saveState(nextFunc: () => void = undefined) {
    BaseFormComponent.validateForms(this.state);

    if(!this.state.isValid) return;

    Promise.all([
      this.saveAddress(),
      this.saveConsent(),
      this.saveCaseData()])
    .then(() => { 
      this.savePatient(); 

      this.state.isSaved = true;

      if(nextFunc) nextFunc();
    });
  }

  private goNext() {
    this.submitForm.emit();
  }

  getAddressObject(): any {
    const formValue = this.patientForm.getRawValue();

    return {
      id: this._case.patient?.currentMailingAddressId || 0,
      current: true,
      patientId: this._case.patient.id || null,
      addressType: AddressType.mailing,
      address: { ...formValue.address }
    };
  }

  getConsentObject(): any {
    const formValue = this.patientForm.getRawValue();

    const hippaConsentDate = formValue.hipaaConsentSignatureDate
      ? moment(formValue.hipaaConsentSignatureDate).format() : null;
    
    return hippaConsentDate
      ? {
        id: this._case.patient.currentConsents?.find(x => +x.consentTypeId === ConsentTypeId.hipaa)?.id || 0,
        consentTypeId: ConsentTypeId.hipaa,
        consentIsOnFile: +formValue.hipaaConsent === HIPAAConsent.Yes,
        dateReceived: hippaConsentDate,
        patientId: this._case.patient.id
      }
      : undefined;
  }

  getPatientCaseObject(): any {
    const formValue = this.patientForm.getRawValue();

    const patient = { ...this._case.patient, ...formValue };
    
    const caregiver = patient.caregivers?.find(e => e.current);

    if (caregiver) {
      this.mapCaregiver(caregiver, formValue);
    }
    else { 
      patient.caregivers ??= [];

      const newCaregiver = this.getNewCaregiver(formValue);

      patient.caregivers.push(newCaregiver);
    }

    patient.dateOfBirth = patient.dateOfBirth ? moment(patient.dateOfBirth).format() : null;
    patient.contactInfos = [
      {
        ...formValue.phoneInfo,
        contactMethod: ContactMethod.phone,
        primary: true,
      },
      {
        contactString: formValue.email,
        contactMethod: ContactMethod.email,
        contactType: ContactType.work,
        primary: true,
      }
    ];

    if (!this.state.config.fieldsConfig.hipaaConsent.isVisible) {
      const hipaaConsent = patient.currentConsents?.find(x => +x.consentTypeId === ConsentTypeId.hipaa);
      const hippaConsentDate = formValue.hipaaConsentSignatureDate
        ? moment(formValue.hipaaConsentSignatureDate).format() : null;

      if (hipaaConsent) {
        hipaaConsent.consentIsOnFile = +formValue.hipaaConsent === HIPAAConsent.Yes;
        hipaaConsent.dateReceived = hippaConsentDate;
      } else {
        patient.currentConsents = patient.currentConsents || [];
        patient.currentConsents.push({
          id: 0,
          consentTypeId: ConsentTypeId.hipaa,
          consentIsOnFile: +formValue.hipaaConsent === HIPAAConsent.Yes,
          dateReceived: hippaConsentDate,
          patientId: patient.id
        });
      }
    }
    
    delete patient.phoneInfo;
    delete patient.email;
    delete patient.supportingDocumentation;
    delete patient.supportingDocumentationText;
    delete patient.householdPeople;
    delete patient.signature;
    delete patient.signatureDate;
    delete patient.householdIncome;

    return patient;
  }

  private mapCaregiver(caregiver, formValue) {
    const model = this.getNewCaregiver(formValue);

    delete model.id;
    delete model.createDate;

    Object.assign(caregiver, model);
  }

  private getNewCaregiver(formValue) {
    const name = NameResolver.parse(formValue.caregiverName);

    const contactInfos = [];

    if (formValue.caregiverPhone) { 
      contactInfos.push({
        contactString: formValue.caregiverPhone,
        contactMethod: ContactMethod.phone,
      });
    }

    return {
      id: 0,
      firstName: name?.firstName,
      middleName: name?.middleName,
      lastName: name?.lastName,
      current: true,
      relationshipId: formValue.caregiverRelationship,
      createDate: new Date(),
      contactInfos: contactInfos
    };
  }
}
