import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICaseData } from '../../interfaces/case-data.interface';
import { QueueType } from '../../enums/queue-type.enum';
import { getQueueTypeFromUrlSuffix } from '../../helpers/utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  case: ICaseData;
  queueType: QueueType;
  documentData = {
    count: null,
    type: null,
  };
  queueTypes = QueueType;

  constructor(private store: Store<any>) {
  }

  async ngOnInit(): Promise<void> {
    this.store.select('case').subscribe((state) => (this.case = state));
    this.store.select('document').subscribe((state) => {
      this.documentData.count = state.documentsCount;
      const queueTypeFromUrl = window.location.pathname.replace('/document-', '');
      this.queueType = getQueueTypeFromUrlSuffix(queueTypeFromUrl);
    });
  }
}
