<app-queue-adjustment-state *ngIf="adjustmentState"
                            (cancel)="cancelAdjustment()"
                            (submit)="submitAdjustment()"
                            [itemState]="adjustmentState">
</app-queue-adjustment-state>
<app-supervisor-manage-queue-top #manageQueueTop [activePage]="activePage" [name]="queueConfiguration.name"
                                 [queueId]="queueConfiguration.queueId" [description]="queueConfiguration.description"
                                 [queueConfigurationId]="queueConfiguration.id" [queueType]="queueConfiguration.queueType"
                                 (infoSaved)="updateQueue($event,'infoSaved')"
                                 (setActivePageHandler)="setActivePage($event)">
</app-supervisor-manage-queue-top>
<div id="page-content">
  <div class="container">
    <app-supervisor-users *ngIf="isLoaded && activePage === 'Users'" [users]="queueConfiguration.users"
                          [queueId]="queueConfiguration.queueId" (userRemoved)="updateQueue($event,'userRemoved')"
                          (usersSaved)="updateQueue($event,'usersSaved')">
    </app-supervisor-users>
    <app-supervisor-tasks *ngIf="isLoaded && activePage === 'Tasks'" [tasks]="queueConfiguration.tasks"
                          (taskRemoved)="updateQueue($event,'taskRemoved')"
                          (tasksSaved)="updateQueue($event,'tasksSaved')">
    </app-supervisor-tasks>
    <app-supervisor-territories *ngIf="isLoaded && activePage === 'Territories'"
                                [territories]="queueConfiguration.territories"
                                (territoryRemoved)="updateQueue($event,'territoryRemoved')"
                                (territoriesSaved)="updateQueue($event,'territoriesSaved')">
    </app-supervisor-territories>
    <app-supervisor-settings *ngIf="isLoaded && activePage === 'Settings'"
                             [userCanPull]="queueConfiguration.userCanPull" [active]="queueConfiguration.active"
                             (settingsSaved)="updateQueue($event,'settingsSaved')">
    </app-supervisor-settings>
  </div>
</div>
