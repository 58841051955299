<div class="container">
  <app-search-field></app-search-field>
</div>

<hr/>

<div class="container">
  <app-supervisor-dashboard-queues [queueList]="queueList"
                                   [intakeQueueList]="intakeQueueList"
                                   [supervisorQueueList]="supervisorQueueList"
  >
  </app-supervisor-dashboard-queues>
</div>
