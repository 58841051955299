<div class="content-wrapper">
  <div class="header-wrapper">
    <h1>Profile</h1>
    <app-message-bar *ngIf="displayedMessage?.message"
                     (hideMessage)="displayedMessage = undefined"
                     [displayedMessage]="displayedMessage?.message"
                     [isError]="isError"
    ></app-message-bar>
  </div>
  <div class="body-wrapper">
    <div class="profile-wrapper">
      <span class="caption">Photo</span>
      <div class="avatar">
        <img *ngIf="user.img" class="image" [src]="user.img"/>
        <span *ngIf="!user.img" class="initials">{{user.name | nameInitials}}</span>
      </div>
      <button *ngIf="user.img" class="change-button" (click)="changePhoto()">Change</button>
      <button *ngIf="user.img" class="remove-button" (click)="removePhoto()">Remove</button>
      <button *ngIf="!user.img" class="upload-button" (click)="changePhoto()">Upload</button>
    </div>
    <form class="form" [formGroup]="userData">
      <div class="input-item field-wrapper">
        <label for="name" class="field-caption">Full Name</label>
        <input formControlName="name"
               matInput
               id="name"
               class="field"
               placeholder="Full Name"
               [ngClass]="{'field-input-invalid': userData.controls.name.invalid}"
        />
      </div>

      <div class="input-item field-wrapper">
        <label for="email" class="field-caption">Email</label>
        <input formControlName="email"
               type="email"
               matInput
               id="email"
               class="field"
               placeholder="Email Address"
               [ngClass]="{'field-input-invalid': userData.controls.email.invalid}"
        />
        <span *ngIf="userData.controls.email.getError('error')"
              class="tooltip"
        >
          {{userData.controls.email.getError('error')}}
        </span>
      </div>

      <div class="input-item field-wrapper">
        <label for="password" class="field-caption">Password</label>
        <input formControlName="password"
               type="password"
               matInput
               id="password"
               class="field"
               readonly
               [ngClass]="{'field-input-invalid': userData.controls.password.invalid}"
        />
        <button (click)="resetPassword()" class="field-button">Reset</button>
      </div>
    </form>
  </div>
  <div class="footer-wrapper">
    <button [disabled]="this.userData.invalid || !this.userData.dirty || isUserDataInitial$.getValue()"
            (click)="saveProfileData()"
            class="save-button"
    >
      Save
    </button>
  </div>
</div>
