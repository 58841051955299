import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';
import { IDropdownOption } from './interfaces/dropdown-option.interface';
import { defaultTaskDropdownOptions } from './constants/defaultTaskDropdownOptions';

@Component({
  selector: 'app-task-dropdown',
  templateUrl: './task-dropdown.component.html',
  styleUrls: ['./task-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskDropdownComponent),
      multi: true,
    }
  ],
})
export class TaskDropdownComponent extends InputControlAccessorDirective {
  @Input() placeholder = 'Select';
  @Input() disableOptionCentering = true;

  @Input() set options(value: IDropdownOption[]) {
    this.optionInner = value && value.length ? value : defaultTaskDropdownOptions;
  }

  get options(): IDropdownOption[] {
    return this.optionInner;
  }

  @Output() optionSelected: EventEmitter<IDropdownOption> = new EventEmitter();

  optionInner: IDropdownOption[] = [];
}
