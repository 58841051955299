<div class="contact-item">
  <div class="contact-header">
    Contact Method
  </div>

  <div class="contact-body">
    <a *ngIf="value" href="tel:+{{value}}" (click)="contactEvent.emit()" class="contact-ref">
      <div class="contact-label">
        <mat-icon *ngIf="iconName">{{iconName}}</mat-icon>
        {{name}}
      </div>

      <div class="value">{{value | phoneNumber}}</div>
    </a>

    <div class="description" *ngIf="description">
      {{description}}
    </div>
  </div>
</div>
