import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { IManagerUser } from '../../../../shared/interfaces/manager-user.interface';
import { ISupervisorTask } from '../../../../shared/interfaces/supervisor-task.interface';
import { ITerritory } from '../../../../shared/interfaces/territory.interface';
import { QueueService } from '../../../manager/components/queue/services/queue.service';
import { QueueUpdateService } from './services/update.service';
import { IUpdateData } from './interfaces/update.interface';
import { InterpolatedString } from '@shared/utilities/string-interpolation/interpolated-string';
import { MockSelector } from '@shared/utilities/string-interpolation/mock-selector';
import { IItemAdjustmentState } from './interfaces/item-adjustment-state.interface';

@Component({
  selector: 'app-supervisor-manage-queue',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class SupervisorManageQueueComponent implements OnInit {
  activePage = 'Users';
  queueConfiguration = {
    id: '',
    users: [],
    territories: [],
    tasks: [],
    name: '',
    description: '',
    queueId: '',
    userCanPull: 'pull',
    active: 'disable',
    programs: [],
    queueType: ''
  };
  isLoaded = true;

  actions = {
    infoSaved: this.onInfoSaved,
    userRemoved: this.onUserRemoved,
    usersSaved: this.onUsersSaved,
    territoryRemoved: this.onTerritoryRemoved,
    territoriesSaved: this.onTerritoriesSaved,
    taskRemoved: this.onTaskRemoved,
    tasksSaved: this.onTasksSaved,
    settingsSaved: this.onSettingsSaved,
  };

  adjustmentState: IItemAdjustmentState;

  private itemToMoveId: number;
  private supervisorIdRedirect: number;

  @ViewChild('manageQueueTop') manageQueueTop;

  constructor(
    private queueService: QueueService,
    public queueUpdateService: QueueUpdateService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      const id: number = Number(queryParams.get('id'));
      const itemId: number = Number(queryParams.get('itemId'));
      const supervisorQueueId: number = Number(queryParams.get('supervisorQueueId'));

      this.itemToMoveId = itemId;
      this.supervisorIdRedirect = supervisorQueueId;

      this.getQueueConfiguration(id, itemId);
    });
  }

  private initAdjustmentHeader(itemId) {
    this.queueService.GetItemAdjustment(itemId)
      .subscribe(
        res => {
          if (!res.success) return;

          this.setAdjustmentState(res.value);
        },
        err => {
          console.error(err);
        }
      );
  }

  private setAdjustmentState(adjustmentState: IItemAdjustmentState) { 
    const zipCode = adjustmentState.territory.id.toString();

    adjustmentState.program.isCorrect = this.queueConfiguration.programs.some(e => e.id === adjustmentState.program.id);
    adjustmentState.territory.isCorrect = this.queueConfiguration.territories.some(e => e.zipCodes.includes(zipCode));
    adjustmentState.task.isCorrect = this.queueConfiguration.tasks.some(e => e.id === adjustmentState.task.id);

    this.adjustmentState = adjustmentState;
  }

  cancelAdjustment() {
    if (this.supervisorIdRedirect) {
      this.router.navigateByUrl(`/queue-supervisor-task/${this.supervisorIdRedirect}`);
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  submitAdjustment() {
    if (this.itemToMoveId && this.queueConfiguration.queueId) { 
      this.queueService.moveItemIntoQueue(this.itemToMoveId, +this.queueConfiguration.queueId)
        .subscribe(
          res => {
            if (res.success) {
              this.router.navigateByUrl(`/queue-supervisor-task/${this.supervisorIdRedirect}`);
            }
            else { 
              console.error(res);
            }
          },
          err => {
            console.error(err);
          },
        )
    }
  }

  setActivePage(active: string): string {
    return (this.activePage = active);
  }

  updateQueue(arg, functionName): void {
    this.actions[functionName].call(this, arg);
    this.saveQueue();
  }

  onInfoSaved(model): void {
    this.queueConfiguration.name = model.name;
    this.queueConfiguration.description = model.description;
  }

  onUserRemoved(id: number): void {
    this.queueConfiguration.users = this.queueConfiguration.users.filter(x => x.id !== id);
  }

  onUsersSaved(users: IManagerUser[]): void {
    this.queueConfiguration.users = [...users];
  }

  onTerritoryRemoved(id: number): void {
    this.queueConfiguration.territories = this.queueConfiguration.territories.filter(x => x.id !== id);

    if (this.adjustmentState) {
      const zipCode = this.adjustmentState.territory.id.toString();
      this.adjustmentState.territory.isCorrect = this.queueConfiguration.territories.some(e => e.zipCodes.includes(zipCode));
    };
  }

  onTerritoriesSaved(territories: ITerritory[]): void {
    this.queueConfiguration.territories = [...territories];

    if (this.adjustmentState) { 
      const zipCode = this.adjustmentState.territory.id.toString();
      this.adjustmentState.territory.isCorrect = this.queueConfiguration.territories.some(e => e.zipCodes.includes(zipCode));
    };
  }

  onTaskRemoved(id: number): void {
    this.queueConfiguration.tasks = this.queueConfiguration.tasks.filter(x => x.id !== id);

    if (this.adjustmentState) {
      this.adjustmentState.task.isCorrect = this.queueConfiguration.tasks.some(e => e.id === this.adjustmentState.task.id);
    };
  }

  onTasksSaved(tasks: ISupervisorTask[]): void {
    this.queueConfiguration.tasks = [...tasks];

    if (this.adjustmentState) {
      this.adjustmentState.task.isCorrect = this.queueConfiguration.tasks.some(e => e.id === this.adjustmentState.task.id);
    };
  }

  onSettingsSaved(model): void {
    this.queueConfiguration.userCanPull = model.userCanPull;
    this.queueConfiguration.active = model.active;
  }

  saveQueue(): void {
    const updateModel: IUpdateData = {
      queueConfiguration: {
        id: this.queueConfiguration.id,
        name: this.queueConfiguration.name,
        description: this.queueConfiguration.description,
        userCanPull: this.queueConfiguration.userCanPull === 'pull',
        active: this.queueConfiguration.active === 'enable',
        queueId: this.queueConfiguration.queueId,
        userIDs: this.queueConfiguration.users.map(item => item.id),
        territoryIDs: this.queueConfiguration.territories.map(item => item.id),
        queueItemTaskIDs: this.queueConfiguration.tasks.map(item => item.id),
        programIDs: this.queueConfiguration.programs.map(item => item.id)
      }
    };

    this.queueUpdateService.updateQueue(updateModel).subscribe(
      (response) => {
        this.manageQueueTop?.getTrendingReport();
        console.log(response);
      },
      (error) => throwError(error)
    );
  }

  getQueueConfiguration(id: number, itemId: number): void {
    this.isLoaded = false;
    this.queueService.getQueue(id).subscribe(
      (response) => {
        this.queueConfiguration = {
          ...response.value,
          users: response.value.users != null ? response.value.users.map((user) => {
            return {
              id: user.id,
              userName: `${user.firstName} ${user.lastName}`,
              active: user.active
            };
          }) : null,
          userCanPull: response.value.userCanPull ? 'pull' : 'push',
          active: response.value.active ? 'enable' : 'disable'
        };

        this.interpolateTasksText(this.queueConfiguration.tasks);

        if (itemId) this.initAdjustmentHeader(itemId);
      },
      (error) => throwError(error),
      () => this.isLoaded = true
    );
  }

  private interpolateTasksText(tasks: any[]) {
    const interpolatedString = new InterpolatedString();

    interpolatedString.setSelector(new MockSelector());

    tasks.forEach(task => {
      task.name = interpolatedString.setInitialString(task.name).toString();
      task.description = interpolatedString.setInitialString(task.description).toString();
    });
  }
}
