<div class="search-form"
     *ngIf="!showPlan">
  <div class="input-item">
    <label>Select Medical Insurance Plan</label>
  </div>

  <div class="input-item search">
    <img src="assets/icons/icon-search.svg"
         alt="search" />
    <input matInput
           type="text"
           [formControl]="searchControl"
           placeholder="Search by Plan Name, Payer Name, or Phone Number"
           (input)="searchPlans()"
           (keydown)="searchNavigate($event)" />
    <button mat-button
            *ngIf="searchControl.value"
            aria-label="Clear"
            matSuffix
            mat-icon-button
            (click)="clearSearch()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <ul class="physician-results facility-results"
      *ngIf="searchControl.value?.length >= 3">
    <ng-scrollbar #scrollbar
                  *ngIf="!isPlansLoading && medicalPlans.length"
                  class="custom-scrollbar">
      <div class="patient-list"
           #searchList>
        <li *ngFor="let plan of medicalPlans; index as i"
            [class.focused]="focusedOnSearch === i">
          <div *ngIf="!plan.planId"
               (click)="selectPlan(plan)"
               class="add-plan">
            <div class="add-plan-title">
              <div class="physician-title">
                <h5>
                  {{ plan.payerName }}
                </h5>
              </div>
              <i>Plan Unknown</i>
            </div>
            <button class="primary-btn"
                    type="button">
              <mat-icon>add_circle</mat-icon>
              Add Plan
            </button>
          </div>
          <div *ngIf="plan.planId"
               (click)="selectPlan(plan)">
            <div class="physician-title">
              <h5>
                {{ plan.planName }}
              </h5>
            </div>
            <div class="physician-wrapper">
              <div class="physician-item">
                <h6>Payer</h6>
                <p>{{ plan.payerName | empty}}</p>
              </div>
              <div class="physician-item phone-item">
                <h6>Payer Phone</h6>
                <p>{{ plan.payerPhone | phoneNumber | empty}}</p>
              </div>
            </div>
          </div>
        </li>
      </div>
    </ng-scrollbar>

    <li *ngIf="isPlansLoading">
      <mat-spinner></mat-spinner>
    </li>

    <li *ngIf="!medicalPlans.length && !isPlansLoading"
        class="not-found">
      <h5>{{ searchControl.value }}</h5>
      <p>Insurance Not Found</p>
    </li>
  </ul>
</div>

<div class="selected-physician selected-plan"
     *ngIf="!searchControl.value && showPlan">
  <div *ngIf="insuranceForm.controls.planId.value || insuranceForm.controls.externalSourceId.value">
    <div class="physician-title">
      <img src="assets/icons/icon-check.svg"
           alt="check" />
      <h5>{{ insuranceForm.controls.planName.value }}</h5>
      <button (click)="removePlan()"
              tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="physician-wrapper">
      <div class="physician-item">
        <h6>Payer</h6>
        <p>{{ insuranceForm.controls.payerName.value | empty}}</p>
      </div>
      <div class="physician-item">
        <h6>Payer Phone</h6>
        <p>{{ insuranceForm.controls.payerPhone.value | phoneNumber | empty}}</p>
      </div>
    </div>
  </div>

  <div *ngIf="!insuranceForm.controls.planId.value && !insuranceForm.controls.externalSourceId.value">
    <div class="physician-title">
      <img src="assets/icons/icon-check.svg"
           alt="check" />
      <h5>
        {{ insuranceForm.controls.payerName.value }}
      </h5>
      <button (click)="removePlan()"
              tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <i>New Plan</i>
  </div>
</div>

<div class="new-plan"
     [formGroup]="insuranceForm"
     *ngIf="showPlan">
  <div class="form-row"
       *ngIf="state.config.fieldsConfig.planName.isVisible">
    <div *ngIf="state.config.fieldsConfig.planName.isVisible"
         style="width: 267px"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['planName']),
            'input-success' : isValid(insuranceForm.controls['planName'])
          }">
      <label for="planName">{{ state.config.fieldsConfig.planName.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.planName.isReadOnly"
             name="planName"
             id="planName"
             formControlName="planName" />
    </div>
  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.planPhone.isVisible">
    <div style="width: 142px"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['planPhone']),
            'input-success' : isValid(insuranceForm.controls['planPhone'])
          }">
      <label for="planPhone">{{ state.config.fieldsConfig.planPhone.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.planPhone.isReadOnly"
             name="planPhone"
             id="planPhone"
             formControlName="planPhone"
             mask="(000) 000-0000"
             placeholder="(_ _ _) _ _ _ - _ _ _ _" />
    </div>
  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.planId.isVisible">
    <div *ngIf="state.config.fieldsConfig.planId.isVisible"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['planId']),
            'input-success' : isValid(insuranceForm.controls['planId'])
          }">
      <label for="planId">{{ state.config.fieldsConfig.planId.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.planId.isReadOnly"
             name="planId"
             id="planId"
             formControlName="planId"
             maxlength="50" />
    </div>

  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.memberId.isVisible || state.config.fieldsConfig.group.isVisible"
  >
    <div class="input-item"
         *ngIf="state.config.fieldsConfig.memberId.isVisible"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['memberId']),
            'input-success' : isValid(insuranceForm.controls['memberId'])
          }">
      <label for="memberId">{{ state.config.fieldsConfig.memberId.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.memberId.isReadOnly"
             name="memberId"
             id="memberId"
             formControlName="memberId"
      />
    </div>

    <div *ngIf="state.config.fieldsConfig.group.isVisible"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['group']),
            'input-success' : isValid(insuranceForm.controls['group'])
          }">
      <label for="group">{{ state.config.fieldsConfig.group.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.group.isReadOnly"
             name="group"
             id="group"
             formControlName="group"
             maxlength="50" />
    </div>
  </div>

  <div class="form-row">
    <div *ngIf="state.config.fieldsConfig.policyHolderName.isVisible"
         style="width: 267px; flex-shrink: 0"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['policyHolderName']),
            'input-success' : isValid(insuranceForm.controls['policyHolderName'])
          }">
      <label for="policyHolderName">{{ state.config.fieldsConfig.policyHolderName.displayName }}</label>
      <input [readonly]="state.config.fieldsConfig.policyHolderName.isReadOnly"
             name="policyHolderName"
             id="policyHolderName"
             formControlName="policyHolderName"
             maxlength="50" />
    </div>

    <div *ngIf="state.config.fieldsConfig.policyHolderDOB.isVisible"
         class="input-item"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['policyHolderDOB']),
            'input-success' : isValid(insuranceForm.controls['policyHolderDOB'])
          }">
      <label for="policyHolderDOB">{{ state.config.fieldsConfig.policyHolderDOB.displayName }}</label>
      <div class="date-input">
        <input matInput
               [matDatepicker]="policyHolderDOB"
               id="policyHolderDOB"
               formControlName="policyHolderDOB"
               placeholder="MM/DD/YY"
               [max]="disabledDate"
               [readonly]="state.config.fieldsConfig.policyHolderDOB.isReadOnly"
        />
        <mat-datepicker-toggle matSuffix
                               [for]="policyHolderDOB"
                               tabindex="-1"
        ></mat-datepicker-toggle>
        <mat-datepicker #policyHolderDOB></mat-datepicker>
      </div>
    </div>
  </div>

  <div *ngIf="state.config.fieldsConfig.policyHolderRelationship.isVisible"
       class="form-row">
    <div class="input-item select"
         [ngClass]="{
          'input-error' : isInvalid(insuranceForm.controls['policyHolderRelationship']),
          'input-success' : isValid(insuranceForm.controls['policyHolderRelationship'])
        }">
      <label for="policyHolderRelationship">{{ state.config.fieldsConfig.policyHolderRelationship.displayName }}</label>
      <mat-form-field appearance="fill">
        <mat-select formControlName="policyHolderRelationship"
                    name="policyHolderRelationship"
                    placeholder="Select"
                    id="policyHolderRelationship"
                    disableOptionCentering
                    panelClass="dropdown-item">
          <mat-option>--</mat-option>
          <mat-option [value]="relationship.id"
                      *ngFor="let relationship of relationships">{{relationship.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.planType.isVisible">
    <div style="width: 142px"
         class="input-item select"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['planType']),
            'input-success' : isValid(insuranceForm.controls['planType'])
          }">
      <label>{{ state.config.fieldsConfig.planType.displayName }}</label>
      <mat-form-field appearance="fill">
        <mat-select formControlName="planType"
                    name="planType"
                    placeholder="Select"
                    id="planType"
                    disableOptionCentering
                    panelClass="dropdown-item">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let type of planTypes; index as i"
                      [value]="i">{{type}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="form-row"
       *ngIf="state.config.fieldsConfig.planOrder.isVisible">
    <div style="width: 142px"
         class="input-item select"
         [ngClass]="{
            'input-error' : isInvalid(insuranceForm.controls['planOrder']),
            'input-success' : isValid(insuranceForm.controls['planOrder'])
          }">
      <label>{{ state.config.fieldsConfig.planOrder.displayName }}</label>
      <mat-form-field appearance="fill">
        <mat-select formControlName="planOrder"
                    name="planOrder"
                    placeholder="Select"
                    id="planOrder"
                    disableOptionCentering
                    panelClass="dropdown-item">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let option of binaryOptions; index as i"
                      [value]="binaryOptions.length - i">{{option}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
