import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../../../../../../../core/services/abstract-rest.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { IPrescription } from '../../../../../../../../../shared/interfaces/prescription.interface';
import { IResponse } from '../../../../../../../../../shared/interfaces/response.interface';
import { getWithoutEmptyValues } from '@shared/utilities/empty-checks';

@Injectable({
  providedIn: 'root',
})
export class DataEntryFormService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  getPatient(id: number): Observable<IResponse> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`Patients/${id}`);
  }

  createPatient(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Patients`;
    return this.putItem<any, IResponse>(data);
  }

  updateConsent(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Consents`;
    return this.updateItem<any, IResponse>(data);
  }

  getInsurancePlans(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/PatientInsurance`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updatePlan(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/PatientInsurance`;
    return this.updateItem<any, IResponse>(data);
  }

  getPlan(id: number): Observable<IResponse> {
    this.url = this.apiUrl;
    return this.getItems<IResponse>(`PatientInsurance/${id}`);
  }

  searchCases(data: any): Observable<any> {
    this.url = this.apiUrl + `/CaseSearch/find-cases-for-data-entry`;

    return this.postItem(data);
  }

  createCase(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseManagementQueueItem`;
    return this.putItem<any, IResponse>(data);
  }

  updateCase(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseManagementQueueItem`;
    return this.updateItem<any, IResponse>(data);
  }

  getCase(id: number): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseSearch`;

    return this.getItem<IResponse>(id);
  }

  updatePrescription(data: IPrescription): Observable<IResponse> {
    this.url = this.apiUrl + `/Prescriptions`;
    return this.updateItem<IPrescription, IResponse>(data);
  }

  getProducts(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Product`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  searchDiagnosis(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Diagnosis`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getSuggestedPhysicians(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Physicians/Suggested`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getSuggestedFacilities(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Facilities/Suggested`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  searchPhysicians(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Physicians`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  searchFacilities(data: any): Observable<any[]> {
    this.url = this.apiUrl + `/Facilities`;
    return this.postItem<any, IResponse>(data).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updateFacility(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Facilities`;
    return this.updateItem<any, IResponse>(data);
  }

  createFacility(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Facilities`;
    return this.putItem<any, IResponse>(data);
  }

  updatePhysician(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Physicians`;
    return this.updateItem<any, IResponse>(data);
  }

  createPhysician(data: any): Observable<IResponse> {
    this.url = this.apiUrl + `/Physicians`;
    return this.putItem<any, IResponse>(data);
  }

  setDocumentBatchCompleted(id: number, caseId: number): Observable<IResponse> {
    this.url = this.apiUrl + `/DataEntryQueue/CompleteDocumentBatch/${id}?caseId=${caseId}`;
    return this.postItem<any, IResponse>();
  }

  updateCaseInsurances(updateCommand): Observable<IResponse> {
    this.url = this.apiUrl + `/CaseInsurance/update-case-insurances`;

    return this.postItem<[], IResponse>(updateCommand);
  }

  addOrUpdateConsents(command): Observable<IResponse> {
    this.url = this.apiUrl + `/Consents/add-or-update-consents`;

    return this.postItem<[], IResponse>(command);
  }
}
