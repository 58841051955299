import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-case-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() phaseList;
  @Input() shownPhase;

  @Output() showPhase = new EventEmitter();

  showPhaseHandler(phase): void {
    this.showPhase.emit(phase);
  }

  constructor() {
  }
}
