import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
// @ts-ignore
import { InputControlAccessorDirective } from '@shared/directives/input-control-accessor.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-task-textarea',
  templateUrl: './task-textarea.component.html',
  styleUrls: ['./task-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskTextareaComponent),
      multi: true,
    }
  ],
})
export class TaskTextareaComponent extends InputControlAccessorDirective {
}
