import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from './shared/models/user.model';
import { UserProfileService } from './features/profile/services/user-profile.service';
import * as UserAction from './store/user/user.actions';
import { take } from 'rxjs/operators';
import { ConfigurationService } from '@core/services/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private user: User;
  title = 'app';

  constructor(
    private configurationService: ConfigurationService,
    private userProfileService: UserProfileService,
    private store: Store<any>,
  ) { }

  ngOnInit(): void {
    const sub = this.store.select('user').subscribe((user) => {
      if (!user) return;

      this.user = {...user};
      this.getUserPhoto();

      sub.unsubscribe();

      this.configurationService.load();
    });
  }

  getUserPhoto(): void {
    this.userProfileService
    .getUserPhoto(this.user.id)
    .pipe(take(1))
    .subscribe((photoLoading: any) => {
      if (!photoLoading) return;

      photoLoading.then((receivedPhoto) => {
        this.user.img = receivedPhoto;
        this.store.dispatch(new UserAction.SetUser(this.user));
      });
    },
    (error) => {
      this.user.img = undefined;
      this.store.dispatch(new UserAction.SetUser(this.user));
    });
  }
}
