import { Component, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './desired.component.html',
  styleUrls: ['../required/required.component.scss', './desired.component.scss'],
})
export class DesiredMissedDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly fields,
    public dialog: MatDialogRef<DesiredMissedDialog>) { }

  public close(isApproved: boolean){
    this.dialog.close(isApproved);
  }
}
