import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncapsulatedRestService } from '@core/services/encapsulated-rest.service';
import { IResponse } from '@shared/interfaces/response.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SupervisorDocumentService extends EncapsulatedRestService {
  constructor(http: HttpClient) { super(http); }

  getQueueItem(id: string): Observable<IResponse> {
    return this.get<IResponse>(`SupervisorDocumentIdentification/GetSupervisorDocumentBatch/${id}`);
  }

  deleteDocumentImage(id): Observable<IResponse> {
    return this.delete<IResponse>('SupervisorDocumentIdentification/DeleteDocumentImage', id);
  }

  completeDocumentBatch(id: number): Observable<IResponse> {
    return this.postItem<any, IResponse>(`SupervisorDocumentIdentification/CompleteSupervisorDocumentBatch/${id}`);
  }
}
