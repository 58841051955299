import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from '@core/services/configuration.service';

@Component({
  selector: 'app-supervisor-footer',
  templateUrl: './supervisor-footer.component.html',
  styleUrls: ['./supervisor-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupervisorFooterComponent {
  @Input() submitIsDisabled: boolean = true;
  @Output() submitBatch = new EventEmitter();

  constructor(
    private router: Router,
    private configurationService: ConfigurationService
  ) { }

  cancelBatch() {
    const queueId = this.configurationService.config.supervisorQueueId;

    this.router.navigateByUrl(`/queue-supervisor-task/${queueId}`);
  }
}
