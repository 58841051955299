import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractRestService } from '../../../../../core/services/abstract-rest.service';
import { Observable } from 'rxjs';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { IQueueParameters } from '../interfaces/queue-parameters.interface';
import { IQueueItemResponse } from '../interfaces/queue-item-response.interface'

@Injectable({
  providedIn: 'root',
})
export class QueueService extends AbstractRestService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getAllItemsInQueue(data: IQueueParameters): Observable<IQueueItemResponse[]> {
    this.url = this.apiUrl + '/Queue/GetAllItemsInQueueFromQuery';
    return this.postItem<IQueueParameters, IQueueItemResponse[]>(data);
  }

  public getQueue(id: number): Observable<IResponse> {
    this.url = this.apiUrl + `/QueueConfiguration`;
    return this.getItems<IResponse>(id);
  }

  public getConfigurationFromQueueId(id: number): Observable<IResponse> {
    this.url = this.apiUrl + `/QueueConfiguration/getConfigurationFromQueueId`;
    return this.getItems<IResponse>(id);
  }

  public GetItemAdjustment(itemId): Observable<IResponse> {
    this.url = this.apiUrl + `/QueueConfiguration/get-item-adjustment/${itemId}`;

    return this.get<IResponse>();
  }

  public moveItemIntoQueue(itemId: number, queueId: number) {
    this.url = `${this.apiUrl}/Queue/move-item-into-queue`;

    return this.postItem<any, IResponse>({ itemId, queueId });
  }
}
