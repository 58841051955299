import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs/internal/observable/throwError';
import { UserInviteStatus } from 'src/app/features/management/account-management/enums/user-invite-status.enum';
import { UserProfileService } from 'src/app/features/profile/services/user-profile.service';
import { OktaService } from 'src/app/shared/services/okta.service';
import { getAdvancedEmailValidator } from '@shared/helpers/utils';

@Component({
  selector: 'app-login',
  templateUrl: './forgot.password.component.html',
  styleUrls: ['./forgot.password.scss'],
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {
  forgotPasswordForm: FormGroup;
  isMessageSent = false;

  @ViewChild('email') emailInput: ElementRef;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.forgotPassword();
    }
  }

  constructor(
    private oktaService: OktaService,
    private readonly userProfileService: UserProfileService
  ) { }

  ngOnInit(): void {
    this.isMessageSent = history.state?.isMessageSent;

    this.initForm();
  }

  ngAfterViewInit(): void {
    if (!this.isMessageSent) {
      this.emailInput.nativeElement.focus();
      this.forgotPasswordForm.updateValueAndValidity();
    }
  }

  private initForm(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        getAdvancedEmailValidator,
      ]),
    });
  }

  forgotPassword(): void {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    const email = this.forgotPasswordForm.controls.email.value;

    this.userProfileService.getUserStatusByEmail(email).subscribe(
      (response) => {
        if(response.value != null && response.value !== UserInviteStatus.pendingInvite){
          const data = { username: email, factorType: 'EMAIL' };
          this.oktaService.forgotPassword(data).subscribe(result => {
            if (result.factorResult === 'WAITING' && result.status === 'RECOVERY_CHALLENGE') {
              this.isMessageSent = true;
            }
          }, err => throwError(err));
        } else {
          this.isMessageSent = true;
        }
      },
      (error) => {
        throw error;
      }
    );
  }
}


