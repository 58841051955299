<form [formGroup]="physicianForm"
      novalidate
      (ngSubmit)="submitHandler()"
      (keydown.enter)="$event.preventDefault()"
      class="document-sidebar-form"
>
  <div class="form-wrapper">
    <h2>{{state.config.tabDisplayName}}</h2>

    <div class="search-form" *ngIf="state.config.fieldsConfig.useNPISearch">
      <div class="input-item search">
        <img src="assets/icons/icon-search.svg" alt="search"/>
        <input matInput type="text" formControlName="searchPhysician"
               placeholder="Search by Physician Name or NPI" #searchPhysician
               autofocus
               (input)="searchPhysicians()"
               (keydown)="searchNavigate($event, 'physician')"/>
        <button mat-button *ngIf="physicianForm.value.searchPhysician" matSuffix mat-icon-button
                aria-label="Clear" (click)="clearSearchPhysician()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <ul class="physician-results physician-search"
          *ngIf="physicianForm.value.searchPhysician?.length >= 3"
          #physicianSearchList>
        <li *ngFor="let physician of physicians; index as i" (click)="selectPhysician(physician)"
            [class.focused]="focusedSearch === i">
          <div class="physician-title">
            <h5>{{ physician.firstName | capitalize }} {{ physician.lastName | capitalize }}</h5>
          </div>
          <div class="physician-wrapper">
            <div class="physician-item">
              <h6>NPI</h6>
              <p>{{ physician.npi | empty}}</p>
            </div>
            <div class="physician-item">
              <h6>Licenses</h6>
              <p *ngIf="!physician.taxonomies || !physician.taxonomies.length">{{'' | empty}}</p>
              <p *ngFor="let taxonomy of physician.taxonomies">{{ taxonomy.license | empty}}</p>
            </div>
            <div class="physician-item">
              <h6>Specialty</h6>
              <p *ngIf="!physician.taxonomies || !physician.taxonomies.length">{{'' | empty}}</p>
              <p *ngFor="let taxonomy of physician.taxonomies">{{ taxonomy.desc | empty}}</p>
            </div>
          </div>
        </li>

        <li *ngIf="!physicians.length" class="not-found">
          <h5>{{ physicianForm.value.searchPhysician }}</h5>
          <p>Physician Not Found</p>
        </li>
      </ul>
    </div>

    <ng-container *ngIf="state.config.fieldsConfig.useNPISearch && !options.selectedPhysician">
      <div class="suggest-info"
           *ngIf="contact">
        <b>Suggested from Source:</b> <i>{{ contact }}</i>
      </div>
      <div class="suggest-wrapper">
        <button class="suggest-item" type="button" *ngFor="let physician of suggestedPhysicians"
                (click)="selectPhysician(physician)" (keydown.enter)="selectPhysician(physician)">
          {{ physician.firstName | capitalize }} {{ physician.lastName | capitalize }}
        </button>
      </div>
    </ng-container>

    <div class="new-physician" *ngIf="!state.config.fieldsConfig.useNPISearch">
      <div *ngIf="state.config.fieldsConfig.physicianName.isVisible" class="form-row">
        <div class="input-item" [ngClass]="{
                      'input-error' : isInvalid(physicianForm.controls['physicianName']),
                      'input-success' : isValid(physicianForm.controls['physicianName'])
                    }">
          <label for="physicianName">{{state.config.fieldsConfig.physicianName.displayName}}</label>
          <input [readonly]="state.config.fieldsConfig.physicianName.isReadOnly" name="physicianName" id="physicianName" formControlName="physicianName"/>
        </div>
      </div>

      <div *ngIf="state.config.fieldsConfig.npi.isVisible" class="form-row">
        <div class="input-item half-width" [ngClass]="{
                      'input-error' : isInvalid(physicianForm.controls['npi']),
                      'input-success' : isValid(physicianForm.controls['npi'])
                    }">
          <label for="npi">{{state.config.fieldsConfig.npi.displayName}}</label>
          <input [readonly]="state.config.fieldsConfig.npi.isReadOnly" name="npi" id="npi" formControlName="npi" mask="000000"/>
        </div>
      </div>

      <div *ngIf="state.config.fieldsConfig.specialty.isVisible" class="form-row">
        <div class="input-item half-width select" [ngClass]="{
                      'input-error' : isInvalid(physicianForm.controls['specialty']),
                      'input-success' : isValid(physicianForm.controls['specialty'])
                    }">
          <label>{{state.config.fieldsConfig.specialty.displayName}}</label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="specialty" name="specialty" placeholder="Select" id="specialty">
              <!-- TODO: need to fix in future -->
              <mat-option value="Allergy and Immunology">Allergy and Immunology</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="selected-physician" *ngIf="options.selectedPhysician && state.config.fieldsConfig.useNPISearch">
      <div class="physician-title">
        <img src="assets/icons/icon-check.svg" alt="check"/>
        <h5> {{ options.selectedPhysician | fullName : false | capitalize }} </h5>
        <button (click)="selectPhysician(null)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="physician-wrapper">
        <div class="physician-item">
          <h6>NPI</h6>
          <p>{{ options.selectedPhysician.npi | empty}}</p>
        </div>
        <div class="physician-item"
             *ngIf="options.selectedPhysician.taxonomies?.length">
          <h6>Licenses</h6>
          <p *ngFor="let taxonomy of options.selectedPhysician.taxonomies">{{ taxonomy.license |
            empty}}</p>
        </div>
        <div class="physician-item"
             *ngIf="options.selectedPhysician.taxonomies?.length">
          <h6>Specialty</h6>
          <p *ngFor="let taxonomy of options.selectedPhysician.taxonomies">{{ taxonomy.desc | empty}}
          </p>
        </div>
      </div>
    </div>

    <div class="physician-facilities search-form" 
         *ngIf="physicianFacilities && options.selectedPhysician && !options.selectedFacility && state.config.fieldsConfig.useNPISearch">
      <h5>Select Facility</h5>

      <ul class="physician-results facility-results">
        <div #physicianFacility>
          <li *ngFor="let facility of options.selectedPhysician.addresses; index as i"
              (click)="selectFacility(facility, true)" [class.focused]="focusedSearch === i">
            <div class="physician-title">
              <h5>
                {{ facility.name }}
              </h5>
              <span *ngIf="facility.mostLikely">
                  <mat-icon>grade</mat-icon>Most Likely
              </span>
            </div>

            <div class="physician-wrapper">
              <div class="physician-item">
                <h6>Address</h6>
                <p>{{ facility.address1 }}, {{ facility.state }} {{ facility.postalCode }}</p>
              </div>
              <div class="physician-item">
                <h6>Group Tax</h6>
                <p>{{ facility.groupTaxId | empty}}</p>
              </div>
              <div class="physician-item">
                <h6>Phone</h6>
                <p>{{ facility.telephoneNumber | phoneNumber | empty}}</p>
              </div>
              <div class="physician-item">
                <h6>Fax</h6>
                <p>{{ facility.faxNumber | phoneNumber | empty}}</p>
              </div>
            </div>
          </li>
        </div>
        <li class="add">
          <button class="primary-btn" type="button" (click)="showFacilitiesSearch()">
            Search Facilities
          </button>
        </li>
      </ul>
    </div>

    <div class="facility-block" *ngIf="options.selectedPhysician || !state.config.fieldsConfig.useNPISearch">
      <div class="search-form" *ngIf="!options.selectedFacility && facilitiesSearch && state.config.fieldsConfig.useNPISearch">
        <div class="input-item search">
          <img src="assets/icons/icon-search.svg" alt="search"/>
          <input matInput type="text" formControlName="searchFacility"
                 placeholder="Search Existing Facilities" #searchFacility (input)="searchFacilities()"
                 (keydown)="searchNavigate($event, 'facility')"/>
          <button mat-button *ngIf="physicianForm.value.searchFacility" matSuffix mat-icon-button
                  aria-label="Clear" (click)="clearSearchFacility()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <ul class="physician-results facility-results"
            *ngIf="physicianForm.value.searchFacility && physicianForm.value.searchFacility.length >= 3">
          <div #facilitySearchList>
            <li *ngFor="let facility of facilities; index as i" (click)="selectFacility(facility, false)"
                [class.focused]="focusedSearch === i">
              <div class="physician-title">
                <h5>
                  {{ facility.name }}
                </h5>
                <span *ngIf="facility.mostLikely">
                                    <mat-icon>grade</mat-icon>Most Likely
                                </span>
              </div>
              <div class="physician-wrapper">
                <div class="physician-item">
                  <h6>Address</h6>
                  <p>{{ facility.address | fulladdress | empty}}</p>
                </div>
                <div class="physician-item">
                  <h6>Group Tax</h6>
                  <p>{{ facility.groupTaxId }}</p>
                </div>
                <div class="physician-item">
                  <h6>Phone</h6>
                  <p>{{ facility.contactInfos | phone | empty}}</p>
                </div>
                <div class="physician-item">
                  <h6>Fax</h6>
                  <p>{{ facility.contactInfos | phone | empty}}</p>
                </div>
              </div>
            </li>
            <li *ngIf="!facilities.length" class="not-found">
              <h5>{{ physicianForm.value.searchFacility }}</h5>
              <p>Facility Not Found</p>
            </li>
          </div>
          <li class="add">
            <button class="primary-btn" type="button" (click)="selectNewFacility()">
              <mat-icon>add_circle</mat-icon>
              New Facility
            </button>
          </li>
        </ul>
      </div>

      <ng-container *ngIf="!options.selectedFacility && facilitiesSearch && suggestedFacilities.length && state.config.fieldsConfig.useNPISearch">
        <div class="suggest-info"><b>Suggested</b></div>
        <div class="suggest-wrapper">
          <button class="suggest-item" type="button" *ngFor="let facility of suggestedFacilities"
                  (click)="selectFacility(facility, false)" (keydown.enter)="selectFacility(facility, false)">
            {{ facility.name }}
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="options.selectedFacility?.id && state.config.fieldsConfig.useNPISearch">
        <div class="selected-physician selected-facility" [formGroup]="facilityForm">
          <div class="physician-title">
            <div>
              <img src="../../../../../../../../../../../assets/icons/icon-check.svg" alt="check"/>
              <h5>{{ options.selectedFacility.name }}</h5>
            </div>
            <div>
              <button type="button" *ngIf="!options.isEditingFacility" (click)="editFacility()"
                      class="edit">
                <mat-icon>edit</mat-icon>
              </button>
              <button type="button" (click)="selectFacility(null, false)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <div class="physician-wrapper" *ngIf="!options.isEditingFacility">
            <div class="physician-item">
              <h6>Address</h6>
              <p>{{ options.selectedFacility.address | fulladdress | empty}}</p>
            </div>
            <div class="physician-item"
                 *ngIf="state.config.fieldsConfig.tax.isVisible">
              <h6>Group Tax</h6>
              <p>{{ options.selectedFacility.groupTaxId | empty}}</p>
            </div>
            <div class="physician-item">
              <h6>Phone</h6>
              <p>{{ options.selectedFacility.contactInfos | phone | empty}}</p>
            </div>
            <div class="physician-item">
              <h6>Fax</h6>
              <p>{{ options.selectedFacility.contactInfos | phone : ContactMethod.fax | empty}}</p>
            </div>
          </div>

          <div class="physician-wrapper edited" *ngIf="options.isEditingFacility">
            <div class="physician-item address">
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input type="text" matInput formControlName="address" #addressField/>
              </mat-form-field>
            </div>
            <div class="physician-item"
                 *ngIf="state.config.fieldsConfig.tax.isVisible">
              <mat-form-field>
                <mat-label>Group Tax</mat-label>
                <input type="text" matInput formControlName="tax"/>
              </mat-form-field>
            </div>
            <div class="zip-wrapper">
              <div class="physician-item">
                <mat-form-field>
                  <mat-label>City</mat-label>
                  <input type="text"
                         matInput
                         formControlName="city" />
                </mat-form-field>
              </div>
              <div class="physician-item">
                <mat-form-field>
                  <mat-label>State</mat-label>
                  <input type="text"
                         matInput
                         formControlName="state" />
                </mat-form-field>
              </div>
              <div class="physician-item">
                <mat-form-field>
                  <mat-label>Zip</mat-label>
                  <input type="text"
                         matInput
                         formControlName="zipCode" />
                </mat-form-field>
              </div>
            </div>
            <div class="physician-item">
              <mat-form-field>
                <mat-label>Phone</mat-label>
                <input type="text"
                       matInput
                       formControlName="officePhone"
                       mask="(000) 000-0000"
                       placeholder="(_ _ _) _ _ _ - _ _ _ _"
                />
              </mat-form-field>
            </div>
            <div class="physician-item">
              <mat-form-field>
                <mat-label>Fax</mat-label>
                <input type="text"
                       matInput
                       formControlName="officeFax"
                       mask="(000) 000-0000"
                       placeholder="(_ _ _) _ _ _ - _ _ _ _"
                />
              </mat-form-field>
            </div>
            <div class="action-buttons">
              <button class="cancel" (click)="exitEditFacility()">Exit</button>
              <div class="primary-btn" (click)="saveFacility(); editFacility()">Save</div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="new-facility" *ngIf="options.selectedFacility?.id === 0 || !state.config.fieldsConfig.useNPISearch" [formGroup]="facilityForm">
        <div class="form-row" *ngIf="state.config.fieldsConfig.facilityName.isVisible">
          <div class="input-item" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['facilityName']),
                            'input-success' : isValid(facilityForm.controls['facilityName'])
                        }">
            <label for="facilityName">{{ state.config.fieldsConfig.facilityName.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.facilityName.isReadOnly" name="facilityName" id="facilityName" formControlName="facilityName" #facilityName/>
          </div>
        </div>

        <div class="form-row" *ngIf="state.config.fieldsConfig.tax.isVisible">
          <div class="input-item short" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['tax']),
                            'input-success' : isValid(facilityForm.controls['tax'])
                        }">
            <label for="tax">{{ state.config.fieldsConfig.tax.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.tax.isReadOnly" name="tax" id="tax" formControlName="tax"/>
          </div>
        </div>

        <div class="form-row address-row" *ngIf="state.config.fieldsConfig.address.isVisible">
          <div class="input-item" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['address']),
                            'input-success' : isValid(facilityForm.controls['address'])
                        }">
            <label for="address">{{ state.config.fieldsConfig.address.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.address.isReadOnly" name="address" id="address" formControlName="address"/>
          </div>
        </div>

        <div class="form-row" *ngIf="state.config.fieldsConfig.city.isVisible">
          <div class="input-item" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['city']),
                            'input-success' : isValid(facilityForm.controls['city'])
                        }">
            <label for="city">{{ state.config.fieldsConfig.city.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.city.isReadOnly"
                   name="city" id="city" formControlName="city"/>
          </div>
        </div>

        <div class="form-row" *ngIf="state.config.fieldsConfig.state.isVisible">
          <div class="input-item" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['state']),
                            'input-success' : isValid(facilityForm.controls['state'])
                        }">
            <label for="state">{{ state.config.fieldsConfig.state.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.state.isReadOnly"
                   name="state" id="state" formControlName="state"/>
          </div>
        </div>

        <div class="form-row" *ngIf="state.config.fieldsConfig.zipCode.isVisible">
          <div class="input-item short" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['zipCode']),
                            'input-success' : isValid(facilityForm.controls['zipCode'])
                        }">
            <label for="zipCode">{{ state.config.fieldsConfig.zipCode.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.zipCode.isReadOnly"
                   name="zipCode" id="zipCode" formControlName="zipCode"/>
          </div>
        </div>

        <div class="form-row" *ngIf="state.config.fieldsConfig.phone.isVisible">
          <div class="input-item short" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['phone']),
                            'input-success' : isValid(facilityForm.controls['phone'])
                        }">
            <label for="phone">{{ state.config.fieldsConfig.phone.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.phone.isReadOnly"
                   name="phone"
                   id="phone"
                   formControlName="phone"
                   mask="(000) 000-0000"
                   placeholder="(_ _ _) _ _ _ - _ _ _ _"
            />
          </div>
        </div>

        <div class="form-row" *ngIf="state.config.fieldsConfig.officeFax.isVisible">
          <div class="input-item short" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['officeFax']),
                            'input-success' : isValid(facilityForm.controls['officeFax'])
                        }">
            <label for="officeFax">{{ state.config.fieldsConfig.officeFax.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.officeFax.isReadOnly"
                   name="officeFax"
                   id="officeFax"
                   formControlName="officeFax"
                   mask="(000) 000-0000"
                   placeholder="(_ _ _) _ _ _ - _ _ _ _"
            />
          </div>
        </div>
      </div>

      <ng-container *ngIf="options.selectedFacility?.id === 0 || options.selectedFacility"
                    [formGroup]="facilityForm">
        <div class="form-row"
             *ngIf="state.config.fieldsConfig.officePhone.isVisible">
          <div class="input-item half-width"
               [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['officePhone']),
                            'input-success' : isValid(facilityForm.controls['officePhone'])
                        }">
            <label for="officePhone">{{ state.config.fieldsConfig.officePhone.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.officePhone.isReadOnly"
                   name="officePhone"
                   id="officePhone"
                   formControlName="officePhone"
                   mask="(000) 000-0000"
                   placeholder="(_ _ _) _ _ _ - _ _ _ _"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="input-item half-width" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['officeName']),
                            'input-success' : isValid(facilityForm.controls['officeName'])
                        }">
            <label for="officeName">{{ state.config.fieldsConfig.officeName.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.officeName.isReadOnly"
                   name="officeName" id="officeName" formControlName="officeName" #officeName/>
          </div>
        </div>

        <div class="form-row">
          <div class="input-item half-width" [ngClass]="{
                            'input-error' : isInvalid(facilityForm.controls['officeEmail']),
                            'input-success' : isValid(facilityForm.controls['officeEmail'])
                        }">
            <label for="officeEmail">{{ state.config.fieldsConfig.officeEmail.displayName }}</label>
            <input [readonly]="state.config.fieldsConfig.officeEmail.isReadOnly"
                   name="officeEmail" id="officeEmail" formControlName="officeEmail"/>
          </div>
        </div>

        <div *ngIf="state.config.fieldsConfig.bestMethodToContact.isVisible"
             class="form-row">
          <div class="input-item select"
               [ngClass]="{
          'input-error' : isInvalid(facilityForm.controls['bestMethodToContact']),
          'input-success' : isValid(facilityForm.controls['bestMethodToContact'])
        }">
            <label>{{ state.config.fieldsConfig.bestMethodToContact.displayName }}</label>
            <mat-form-field appearance="fill">
              <mat-select formControlName="bestMethodToContact"
                          name="bestMethodToContact"
                          placeholder="Select"
                          id="bestMethodToContact"
                          disableOptionCentering
                          panelClass="dropdown-item">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let contactMethod of displayedContactMethods"
                            [value]="contactMethod">{{ContactMethodName[contactMethod]}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <button *ngIf="state.config.nextTab" type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()" [disabled]="isFormLoading || formInvalid">
    <b>Next:</b> {{ state.config.nextTab.config.tabDisplayName }}
    <mat-icon>east</mat-icon>
  </button>

  <button *ngIf="!state.config.nextTab" type="submit" class="next-button next-stack" (keydown.enter)="submitHandler()" [disabled]="isFormLoading || formInvalid">
    <b>Complete</b>
  </button>
</form>
