<aside *ngIf="(user$ | async) as user">
  <div *ngIf="showCaseDetailsTabs$ | async" class="side-menu">
    <div class="side-icon" *ngFor="let detail of caseDetails$ | async"
         [class.active]="caseService.details$.getValue() && caseService.details$.getValue().id === detail.id"
         (click)="setDetails(detail.id)">
      <img *ngIf="caseService.details$.getValue() && caseService.details$.getValue().id === detail.id; else active"
           [src]="'assets/icons/' + detail.icon + '-active.svg'"/>
      <ng-template #active>
        <img [src]="'assets/icons/' + detail.icon + '-default.svg'"/>
      </ng-template>
    </div>
  </div>

  <div *ngIf="(user$ | async)?.role === Roles.SystemAdmin"
       class="users-submenu menu-item"
       (mouseover)="usersHovered=true"
       (mouseleave)="usersHovered=false"
  >
    <a class="menu-btn">
      <img class="aside-icon"
           [src]="usersHovered ? 'assets/icons/icon-gear-active.svg' : 'assets/icons/icon-gear.svg'"
           alt="icon_users"
      />
    </a>

    <div *ngIf="usersHovered" class="menu-wrapper">
      <div class="menu-dropdown">
        <a routerLink="account-management" routerLinkActive="active" class="menu-item">
          Account management
        </a>
        <a routerLink="payer-management" routerLinkActive="active" class="menu-item">
          Payer/Plan management
        </a>
        <a routerLink="pharmacy-plan-management" routerLinkActive="active" class="menu-item">
          Pharmacy Benefit Plan management
        </a>
        <a routerLink="physician-office-management" routerLinkActive="active" class="menu-item">
          Physicians/Physician Office management
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="(inquiryTypes$ | async)?.length" class="general-inquiry menu-item" (click)="openGeneralInquiryDialog()">
    <a [class.active]="isInquiryTypesModalOpen$ | async">
      <img class="aside-icon" (mouseover)="generalInquiryActive=true" (mouseout)="generalInquiryActive=false"
           [src]="generalInquiryActive ? 'assets/icons/icon-info-active.svg' : 'assets/icons/icon-info.svg'"
           alt="icon_general_inquiry"/>
      <img class="active" src="assets/icons/icon-info-active.svg" alt="icon_general_inquiry_active"/>
    </a>
  </div>

  <div class="search menu-item" (click)="openSearchDialog()">
    <a [class.active]="isSearchModalOpen$ | async">
      <mat-icon>search</mat-icon>
    </a>
  </div>

  <div
    *ngIf="(user$ | async)?.role === Roles.SystemAdmin || (user$ | async)?.role === Roles.ProgramAdmin || (user$ | async)?.role === Roles.AgentAll || (user$ | async)?.role === Roles.AgentRestricted;"
    class="reports menu-item">
    <a routerLink="reports" routerLinkActive="active">
      <img class="aside-icon" (mouseover)="reportHovered=true" (mouseout)="reportHovered=false"
           [src]="reportHovered ? 'assets/icons/icon_chart_active.svg':'assets/icons/icon_chart.svg'" alt="icon_chart"/>
      <img class="active" src="assets/icons/icon_chart_active.svg" alt="icon_chart"/>
    </a>
  </div>

  <div class="home menu-item">
    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <mat-icon>home</mat-icon>
    </a>
  </div>

  <div class="user">
    <button mat-icon-button [matMenuTriggerFor]="userMenu" class="user-button">
      <div class="avatar">
        <img *ngIf="user.img" class="image" [src]="user.img"/>
        <span *ngIf="!user.img" class="initials">{{user.name | nameInitials}}</span>
      </div>
    </button>

    <mat-menu classList="user-dropdown-menu" #userMenu="matMenu" xPosition="after" yPosition="above">
      <a mat-menu-item routerLink="/profile">Profile</a>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div>
</aside>
