/// Please use interpolation.configurator.ts file for configure behavior of interpolation
/// Make changes to this file only in exceptional cases

import { InterpolationConfig } from './interpolation.configurator';

export class SplittedSelector {
  public readonly properties: string[] = [];
  public readonly styles: string[] = [];

  public get property() { return this.properties[0]; }

  constructor(selector: string) {
    const splitted = selector.split(InterpolationConfig.GeneralSplitter);
    
    this.properties = splitted[0].split(InterpolationConfig.PropertySplitter);
    
    if(splitted.length === 2) this.styles = splitted[1].split(InterpolationConfig.StyleSplitter);
  }
}
