import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';
import { OktaService } from 'src/app/shared/services/okta.service';
import { PasswordStrength, PasswordValidationService } from 'src/app/shared/services/password-validation.service';

@Component({
  selector: 'app-login',
  templateUrl: './reset.password.component.html',
  styleUrls: ['./reset.password.scss'],
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
  resetPasswordForm: FormGroup;
  islinkExpired = false;
  isPasswordReset = false;
  requestedEmail = '';
  oktaAccountId = '';
  passwordStrength: PasswordStrength;
  strengths = PasswordStrength;
  stateToken = '';

  errorMessage: string;

  @ViewChild('password') password: ElementRef;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.resetPassword();
    }
  }

  constructor(
    private oktaService: OktaService,
    private router: Router,
    private route: ActivatedRoute,
    private passwordValidationService: PasswordValidationService,
  ) {
  }

  ngOnInit(): void {
    this.requestedEmail = this.route.snapshot.queryParams.email;

    this.oktaService.checkRecoveryToken({recoveryToken: this.route.snapshot.queryParams.recoveryToken}).subscribe((response) => {
        this.oktaAccountId = response._embedded.user.id;
        this.stateToken = response.stateToken;
      },
      (error) => {
        this.islinkExpired = true;
      }
    );

    this.initForm();
  }

  ngAfterViewInit(): void {
    if (!this.islinkExpired) {
      this.password.nativeElement.focus();
      this.resetPasswordForm.updateValueAndValidity();
    }
  }

  private initForm(): void {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl(undefined, [Validators.required])
    });
  }

  canResetPassword(): boolean {
    return this.resetPasswordForm.controls.password.invalid
      || !this.passwordStrength
      || this.passwordStrength === this.strengths.veryWeak
      || this.passwordStrength === this.strengths.weak;
  }

  resetPassword(): void {
    this.errorMessage = undefined;
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.oktaService.resetPassword({
      stateToken: this.stateToken,
      newPassword: this.resetPasswordForm.controls.password.value
    }).subscribe((response) => {
        this.isPasswordReset = true;
      },
      (error) => {
        switch (error.statusCode) {
          case 403: {
            this.errorMessage = 'Password cannot be your current password.';
            break;
          }
          default: {
            this.errorMessage = 'Something went wrong.';
            break;
          }
        }
      }
    );
  }

  forgotPassword(): void {
    if (!this.requestedEmail && this.requestedEmail === '') {
      return;
    }

    const data = {username: this.requestedEmail, factorType: 'EMAIL'};

    this.oktaService.forgotPassword(data).subscribe(result => {
      if (result.factorResult === 'WAITING' && result.status === 'RECOVERY_CHALLENGE') {
        this.islinkExpired = false;
        this.router.navigateByUrl('login/forgot-password', {state: {isMessageSent: true}});
      }
    }, err => throwError(err));
  }

  validatePassword(): void {
    const password: string = this.resetPasswordForm.controls.password.value;

    const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?])[A-Za-z\\d@$!%*?&]{7,}$');

    if (!password?.length || !pattern.test(password)) {
      this.passwordStrength = PasswordStrength.weak;
      return;
    }

    this.passwordStrength = this.passwordValidationService.validatePassword(password);
  }
}


