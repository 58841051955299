import { FieldsRequirements } from '../parameters/field-requirements';

export class FieldsConfig {
  public isVisible: boolean;
  public isReadOnly: boolean;
  public displayName: string;
  public requirements: FieldsRequirements;

  public followUps: any[];
  public isMissedWhen: any[];
  public fieldNameInMissingList: string;

  constructor({
    displayName,
    isVisible = false,
    isReadOnly = false,
    requirements = FieldsRequirements.optional,
  }) { 
    this.displayName = displayName;
    this.isVisible = isVisible;
    this.isReadOnly = isReadOnly;
    this.requirements = requirements;

    this.followUps = undefined;
    this.isMissedWhen = undefined;
    this.fieldNameInMissingList = undefined;
  }

  public UpdateState() {
    const pref = this.displayName.slice(0, 2);
    if(this.requirements === FieldsRequirements.mandatory && pref !== '* ') this.displayName = '* ' + this.displayName;
    else if(this.requirements !== FieldsRequirements.mandatory && pref === '* ') this.displayName = this.displayName.slice(2);
  }
}