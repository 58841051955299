import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-task-static-text',
  templateUrl: './task-static-text.component.html',
  styleUrls: ['./task-static-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskStaticTextComponent {
  @Input() staticTemplate: string;
}
