import { Pipe, PipeTransform } from '@angular/core';
import { ContactMethod } from '../enums/contact-method.enum';
import { IContactData } from '../interfaces/contact-data.interface';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(contactInfos: IContactData[], method = ContactMethod.phone): string {
    const contact = contactInfos.find(x => x.primary && x.contactMethod === method && x.contactString);
    if (contact) {
      const phone = contact.contactString;
      const firstPart = phone.substring(0, 3);
      const secondPart = phone.substring(3, 6);
      const thirdPart = phone.substring(6);

      return `(${firstPart}) ${secondPart}-${thirdPart}`;
    }

    return '';
  }
}
