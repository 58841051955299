<div class="content-wrapper">
  <div class="header-wrapper">
    <h1>Verify your email</h1>
  </div>
  <div class="verification-wrapper">
    <span class="description">
      Enter the six-digit code we sent to your email address to verify your account
    </span>
    <div class="inputs-wrapper">
      <input *ngFor="let input of inputs; let i = index"
             maxlength="1"
             (keypress)="numberOnly($event)"
             (keyup)="setValue($event, i)"
             [ngClass]="{'error-input': isError, 'filled-input': input}"
             (paste)="pasteCode($event)"
             #codeInputs
      />
    </div>
    <button class="submit-button"
            [disabled]="isEmpty"
            (click)="verify()"
            #verifyButton
    >
      Verify & Continue
    </button>
  </div>
</div>

<div *ngIf="isError" class="error-container">
  <div class="error-content">
    <span
      *ngIf="!isVerificationCodeInvalid">Verification code has expired, please login again to receive a new code</span>
    <span *ngIf="isVerificationCodeInvalid">Verification code is invalid</span>
  </div>
  <button *ngIf="!isVerificationCodeInvalid" class="login-again-button" routerLink="/login">Login again</button>
</div>
