<div class="task-datepicker-range">
  <div class="input-item">
    <label for="dateStart-{{id}}">{{field.name}}</label>

    <div class="date-input">
      <input
        matInput
        [matDatepicker]="taskInputDatepickerStart"
        id="dateStart-{{id}}"
        [(ngModel)]="dateStart"
        placeholder="Start"
        [max]="dateEnd"
        (dateChange)="updateDateStart($event)"
        (focus)="taskInputDatepickerStart.open()"
        (click)="taskInputDatepickerStart.open()"
        [disabled]="disabled"
        class="task-input"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="taskInputDatepickerStart"
        tabindex="-1"
      ></mat-datepicker-toggle>
      <mat-datepicker #taskInputDatepickerStart></mat-datepicker>
    </div>
  </div>

  <div class="devider">&mdash;</div>

  <div class="input-item">
    <div class="date-input">
      <input
        matInput
        [matDatepicker]="taskInputDatepickerEnd"
        id="dateEnd-{{id}}"
        [(ngModel)]="dateEnd"
        placeholder="End"
        [min]="dateStart"
        (dateChange)="updateDateEnd($event)"
        (focus)="taskInputDatepickerEnd.open()"
        (click)="taskInputDatepickerEnd.open()"
        [disabled]="disabled"
        class="task-input"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="taskInputDatepickerEnd"
        tabindex="-1"
      ></mat-datepicker-toggle>
      <mat-datepicker #taskInputDatepickerEnd></mat-datepicker>
    </div>
  </div>
</div>
