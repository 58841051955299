import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ITaskFooter } from '../../../../interfaces/task-footer.interface';

@Component({
  selector: 'app-task-footer',
  templateUrl: './task-footer.component.html',
  styleUrls: ['./task-footer.component.scss'],
})
export class TaskFooterComponent {
  private _footerData: ITaskFooter;

  @Input() formData: FormGroup;
  @Input() set footerData(value: ITaskFooter) {
    this._footerData ??= value;
  }
  get footerData(): ITaskFooter {
    return this._footerData;
  }

  @Output() submitTask: EventEmitter<any> = new EventEmitter();
  @Output() escalateTask: EventEmitter<any> = new EventEmitter();
  @Output() rescheduleTask: EventEmitter<any> = new EventEmitter();

  setSubmitBtnState(state: boolean): void {
    if (this._footerData.submitButton) {
      this._footerData.submitButton.isDisabled = state;
    }
  }

  setRescheduleBtnState(state: boolean): void {
    if (this._footerData.rescheduleButton) {
      this._footerData.rescheduleButton.isDisabled = state;
    }
  }

  setEscalateBtnState(state: boolean): void {
    if (this._footerData.escalateButton) {
      this._footerData.escalateButton.isDisabled = state;
    }
  }

  setEscalateBtnVisibleState(state: boolean): void {
    if (this._footerData.escalateButton) {
      this._footerData.escalateButton.isVisible = state;
    }
  }
}
