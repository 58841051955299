// TODO: http://localhost:4200/manage-queue?id=5
export const supervisorChartDataMock = [
  {
    itemsCount: 8,
    usersCount: 10,
    y: 18,
    baseline: '20%',
  },
  {
    itemsCount: 1,
    usersCount: 7,
    y: 8,
    baseline: '10%',
  },
  {
    itemsCount: 5,
    usersCount: 10,
    y: 15,
    baseline: '30%',
  },
  {
    itemsCount: 3,
    usersCount: 5,
    y: 8,
    baseline: '14%',
  },
  {
    itemsCount: 6,
    usersCount: 14,
    y: 20,
    baseline: '4%',
  },
  {
    itemsCount: 2,
    usersCount: 5,
    y: 7,
    baseline: '25%',
  },
  {
    itemsCount: 1,
    usersCount: 3,
    y: 4,
    baseline: '15%',
  },
  {
    itemsCount: 5,
    usersCount: 15,
    y: 20,
    baseline: '13%',
  },
];
