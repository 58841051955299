import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AccessService } from '@core/services/access.service';
import { ContactMethod } from '@shared/enums/contact-method.enum';
import { Roles } from '@shared/enums/roles.enum';
import { TargetNames } from '../../../../../../shared/enums/target-names.enum';
import { TaskColumns } from '../../../../../../shared/enums/task-columns.enum';
import { TaskContactMethodNames } from '../../../../../../shared/enums/task-contact-method-names.enum';
import { enumToArray, interpolateTemplate } from '../../../../../../shared/helpers/utils';
import { ConfigsContract } from '../dynamic-tasks-renderer/contract/configs.contract';

@Component({
  selector: 'app-manager-case-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  ConfigsContract = ConfigsContract;
  ContactMethod = ContactMethod;

  @Input() case;

  @Input()
  get activeTasks(): any[] {
    return this.activeTasksInner;
  }

  set activeTasks(activeTasks: any[]) {
    if (this.activeTasksInner !== activeTasks) {
      this.activeTasksInner = activeTasks.map(item => {
        return {
          ...item,
          task: interpolateTemplate(item.task, {
            reason: item.followUpReason,
            contactMethod: TaskContactMethodNames[item.contactMethod] || '',
            entity: TargetNames[item.target] || ''
          })
        };
      });
      this.data = this.activeTasksInner;
      this.dataSource = new MatTableDataSource(this.data);
    }
  }

  @Output() setSelectedTaskHandler = new EventEmitter();
  @Output() createActivity: EventEmitter<void> = new EventEmitter();

  columnsToDisplay = enumToArray(TaskColumns);
  data = [];
  dataSource;
  activeTasksInner = [];

  hasAccessToSupervisorTasks = false;

  constructor(private accessService: AccessService) { }

  ngOnInit(): void {
    this.accessService.userHasRole(Roles.SystemAdmin, Roles.ProgramAdmin)
      .then(res => { 
        this.hasAccessToSupervisorTasks = res;
      }
    );
  }

  setSelectedTask(task): void {
    this.setSelectedTaskHandler.emit(task);
  }

  setBackground(status, time): string {
    if (status === 'Complete' && time === 'On Time') {
      return 'complete-on-time';
    }
    if (status === 'Complete' && time === 'Late') {
      return 'complete-late';
    }
    if (status === 'Current') {
      return 'current';
    }

    return '';
  }
}
