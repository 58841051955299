<h2>Attachments</h2>
<div class="attachment-item" *ngFor="let attachment of attachments" (click)="downloadAttachment(attachment)">
  <div class="attachment-img">
    <img [src]="attachment.thumbnail" alt="attachment" *ngIf="attachment.thumbnail">
  </div>
  <div class="attachment-description">
    <h5>{{attachment.s3Key}}</h5>
    <p>Rcv'd {{attachment.createDate | date:"MM-dd-yyyy"}} at {{attachment.createDate | date: "h:mm a"}}
    </p>
    <p>{{attachment.numberOfPages}} {{attachment.numberOfPages === 1 ? 'Page' : 'Pages'}}</p>
  </div>
</div>
